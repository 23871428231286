import React from "react";
import ReactDOM from "react-dom";
// prettier-ignore
import { Pane, Text, Paragraph, Heading, Button, Tooltip, Position, toaster, IconButton, Code, ExpandAllIcon, CollapseAllIcon, CloudUploadIcon, ChevronRightIcon, ChevronDownIcon, EditIcon, StyleIcon, SettingsIcon, InfoSignIcon, IssueIcon } from "evergreen-ui";
import relativeDate from "tiny-relative-date";

import { firebase, db, storage } from "../../fire.js";
import APIClient from "../../helpers/api_client";
import { FireHelpers } from "../../../api/app_shared/fire_helpers.js";
const API = new APIClient(firebase);
const FH = new FireHelpers(db, firebase);

import generate_json from "../../helpers/generate_json.js";

// import template_builder_defaults from "../../../api/app_shared/defaults/template_builder_defaults.json";
// const static_template_map = require("../../../api/app_shared/defaults/static_book_style_templates.json");
import default_basic_config from "../../../api/app_shared/defaults/basic_project_defaults.json";
// prettier-ignore
import { ExpandablePane, ExpandablePaneTinted, ExpandablePaneUnderline, CloudImage, Loader, ImageList, IngestMessages, Summary, TitleOnly, Messages} from "../shared/common.js";
import { make_snapshot } from "../../helpers/make_snapshot.js";

import { SectionSelect } from "./view_options.js";
import { BillingStatus } from "./billing_status.js";

import CoverImageView from "../shared/cover_image.js";
import UploadPrompt from "../shared/upload_prompt.js";
import UploadWrapper from "../shared/upload_wrap.js"; // todo maybe make this a HOC? for now it's hacky but works
import EpubCheckView from "../shared/epubcheck_view.js";
import FontWarningsView from "../shared/font_warnings_view.js";

import BasicOptions from "../basic/basic_options.js";
import TemplatePicker from "../basic/template_picker.js";
import AdvancedTemplatePicker from "../advanced/template_picker.js";
import StructurePrompt from "../hederis_comp_structure_editor/structure_prompt.js";
import TemplateBuilderContainer from "../basic/template_builder_container.js";
import SnapshotCreator from "../advanced/snapshot_creator.js";
import ConfigureExportSettings from "../shared/configure_export_settings.js";
import OutputButtons from "../shared/output_buttons.js";

import HedButton from "../shared/HedButton.js";

// import TemplateBuilder from "./template_builder.js";

import Dropzone from "react-dropzone";

import { get_unique_filename, slugify } from "../../helpers/util";

export default class AdvancedDashboard extends React.Component {
  constructor(props) {
    super(props);
    // window.dash_debug = this;
    // console.log(HEDERIS_API_PATH_WITH_STAGE);
    // not rec'd react style but works well, so we can use it in the upload prompts
    this.upload_wrapper_ref = React.createRef();
    this.toggleStep = this.toggleStep.bind(this);
    this.handleUpdateImageMeta = this.handleUpdateImageMeta.bind(this);

    let { last_input_docx_path } = props.project;

    let steps_expanded = [false, !last_input_docx_path, false, false, false, false];
    this.state = {
      steps_expanded,
      project_templates: false
    };
  }
  // this expects the full obj the comp was passed, template, export_settings_style, options,
  onChange(change) {
    let basic_config = this.props.project.basic_config;
    basic_config = { ...basic_config, ...change };
    this.props.updateProjectMeta({ basic_config });
  }
  async componentDidMount() {
    await this.loadProjectTemplates();
  }
  async loadProjectTemplates() {
    let props = this.props;
    let storageRef = storage.ref();
    // prettier-ignore
    let project_path = `${props.project.group}/${props.id}/advanced_config/`;
    // console.log(project_path);
    let ref = storageRef.child(project_path);
    let project_templates = await ref.listAll();
    // console.log(project_templates.items);
    let p_templates = project_templates.items.map(({ fullPath, name }) => {
      return { fullPath, name };
    });
    this.setState({ project_templates: p_templates });
  }
  hacky_image_refresh() {
    this.toggleStep(0);
    this.toggleStep(1);
    this.setState({ hide_cover_for_hacky_refresh: true });
    setTimeout(() => {
      this.toggleStep(0);
      this.toggleStep(1);
      this.setState({ hide_cover_for_hacky_refresh: false });
    }, 10);
  }
  afterImageUpload(image_path) {
    toaster.closeAll();
    let { images } = this.props.project;
    if (images.includes(image_path)) {
      toaster.notify("Replacing existing image with the same name.");
      this.hacky_image_refresh();
    } else {
      images.push(image_path);
    }
    if (this.props.project.cover_image) {
      toaster.success("Image Upload Complete!");
      this.props.updateProjectMeta({ images });
    } else {
      toaster.success("Image Upload Complete! Making it the cover image");
      this.props.updateProjectMeta({ images, cover_image: image_path });
    }
  }
  // expects an object like this:
  // {"image.jpg": {"alt_text": "text here", "isDecorative": false }}
  handleUpdateImageMeta(image_meta) {
    let metadata = { ...this.props.alt_text, ...image_meta };
    this.props.updateImageMeta(metadata);
    toaster.success("Image ALT Text Saved!");
  }
  async afterDocxUpload(docx_path) {
    // check for locked sections, make a snapshot if found
    let should_make_snapshot = false;

    if (this.props.project.section_meta) {
      for (const key in this.props.project.section_meta) {
        if (
          this.props.project.section_meta[key].hasOwnProperty("is_locked") &&
          this.props.project.section_meta[key]["is_locked"] === true
        ) {
          should_make_snapshot = true;
        }
      }
    }

    if (should_make_snapshot) {
      let snapshot_name = `AUTO-BACKUP-${Date.now()}`;
      let res = make_snapshot(snapshot_name, this.props);
    }

    let project_id = this.props.id;
    // let { uid, displayName } = this.props.hederis_user;
    let { project, hederis_user } = this.props;
    toaster.closeAll();
    toaster.notify("Preparing to Convert", { duration: 900 });

    let alt_text = this.props.alt_text ? this.props.alt_text : false;

    // TODO stop sending entire user, but need to clean out lambdas of that. just send display name, we get the id from the token
    let payload = {
      docx_path,
      hederis_user,
      project_id,
      project,
      alt_text,
    };

    let res = await API.trigger_convert(payload);
    if (!res) {
      // if (res.status && res.status !== 200) {
      toaster.closeAll();
      // console.log(res.statusText);
      toaster.warning("There was an error converting", { duration: 10 });
      return;
    }
    if (res["message"] == "billing-error") {
      toaster.closeAll();
      toaster.danger("There was an error billing the credit card we have on file.",{ duration: 30 });
      return;
    } else if (res["message"] == "billing-not-setup") {
      toaster.closeAll();
      toaster.danger("Sorry, you or (your organization) need to enable billing to convert more. Go to your Account page to set it up, and then re-upload your manuscript.",{ duration: 30 });
      return;
    } else if (res) {
      // for the ui, shouldn't use project.last_input_docx_path in lambdas etc
      this.props.updateProjectMeta({ last_input_docx_path: docx_path });
    }

    toaster.closeAll();
    toaster.notify("Converting document", { duration: 900 });
  }
  async trigger_rebuild() {
    this.props.updateProjectMeta({
      last_updated: firebase.firestore.FieldValue.serverTimestamp(),
    });
    this.props.trigger_rebuild();
  }
  // helper/handler for toggling which steps are open
  toggleStep(index) {
    let { steps_expanded } = this.state;
    steps_expanded[index] = !steps_expanded[index];
    this.setState({ steps_expanded });
    // console.log(index);
  }
  render() {
    let { project } = this.props;
    let { basic_config } = this.props.project;
    let { last_input_docx_path } = this.props.project;
    // durr template
    // let { template, options, export_settings_style } = basic_config;
    let { options, export_settings_style } = basic_config;

    // hacky but i don't know if there was a react way to do this without repeating code otherwise
    let onFileDrop = files => this.upload_wrapper_ref.current.onFileDrop(files);

    let { steps_expanded, show_template_builder } = this.state;

    let not_has_template_code = this.props.project.project_discount_code == undefined || this.props.project.project_discount_code != "TEMPLATE";
    let is_not_template = (this.props.project.is_template == undefined || this.props.project.is_template == false) && not_has_template_code == true;

    // prettier-ignore
    let should_display_font_warnings = this.props.font_warnings && this.props.font_warnings.length && is_not_template
      ? true
      : false;

    return (
      <Pane display="flex" flexDirection="row" justifyContent="space-between" minHeight="100vh">
        <Pane 
          width="25vw" 
          minWidth="300px" 
          backgroundColor="#373550" 
          padding={16}
        >
          <TitleOnly {...this.props} />
          {this.state.hide_cover_for_hacky_refresh ? (
            ``
          ) : (
            <CoverImageView
              onFileDrop={onFileDrop}
              project={this.props.project}
              is_not_template={is_not_template}
              remove={e =>
                this.props.updateProjectMeta({ cover_image: "" })
              }
            />
          )}
          <Summary {...this.props} hideTitle={true} />
          <BillingStatus {...this.props} />
        </Pane>
        <UploadWrapper 
          ref={this.upload_wrapper_ref}
          {...this.props}
          afterDocxUpload={this.afterDocxUpload.bind(this)}
          afterImageUpload={this.afterImageUpload.bind(this)}
        >
          <Pane 
            display="flex" 
            flexDirection="row" 
            justifyContent="center" 
            paddingX={16}
          >
            <Pane display="flex" flexDirection="column">
              {this.props.project.group === "public" &&
              this.props.project.draft ? (
                <Button
                  height={40}
                  appearance="primary"
                  intent="warning"
                  marginTop={8}
                  disabled={false}
                  onClick={e => {
                    this.props.updateProjectMeta({ draft: false });
                  }}
                >
                  Publish
                </Button>
              ) : (
                ``
              )}
              {this.props.project.group === "public" &&
              !this.props.project.draft ? (
                <Button
                  height={40}
                  appearance="primary"
                  intent="success"
                  marginTop={8}
                  disabled={true}
                >
                  Published
                </Button>
              ) : (
                ``
              )}
              
              <Messages
                project={project}
                clearMessages={() => {
                  this.props.updateProjectMeta({
                    ingest_messages: [],
                    build_messages: [],
                  });
                }}
                sendReportEmail={() => {
                  let details = {
                    message: this.props.project.ingest_messages[0]["message"],
                    project: this.props.id,
                  };
                  toaster.closeAll();
                  API.send_email("error", "help@hederis.com", details);
                  toaster.notify("Help is on the way!", { duration: 30 });
                }}
              />
              
              {/* prettier-ignore*/}
              <Pane 
                backgroundColor="#E8E7EE" 
                padding={16}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Pane paddingRight={32}>
                  <Heading>
                    {last_input_docx_path ? `Current .docx file: ${get_docx_fname(last_input_docx_path, true)}`:`Upload DOCX`}
                  </Heading>
                  {last_input_docx_path ? (
                    <StructurePrompt
                      last_input_docx_path={last_input_docx_path}
                      projectid={this.props.id}
                    />
                  ) : (
                    <HedButton 
                      appearance="hedsecondary" 
                      onClick={()=>{ this.afterDocxUpload(METAMORPHOSIS_EXAMPLE_PATH) }} 
                      marginTop={16}
                      marginBottom={16} 
                    >
                      No DOCX handy? Use sample text
                    </HedButton>
                  )}
                </Pane>
                <Pane maxWidth="200px">
                  <UploadPrompt
                    onFileDrop={onFileDrop}
                    label="Upload a DOCX"
                    accept=".docx"
                  >
                    <Pane paddingTop={8} paddingBottom={8} textAlign="center">
                      <Text size={300}>Drag a file from your computer or click above to upload</Text>
                    </Pane>
                  </UploadPrompt>
                  <BasicOptions
                    options={options}
                    onChange={this.onChange.bind(this)}
                  />
                  <Tooltip
                    content="For cleaner results, add a pagebreak before each chapter in your manuscript."
                    position={Position.LEFT}
                  >
                    <Pane textAlign="center">
                      <Text size={300} color="blue">Upload Tips</Text>
                    </Pane>
                  </Tooltip>
                </Pane>
              </Pane>

              <Pane 
                backgroundColor="#E8E7EE" 
                padding={16}
                paddingBottom={8}
                display="flex"
                flexDirection="column"
                marginTop={16}
              >
                <Heading>
                   Book Design
                </Heading>
                <AdvancedTemplatePicker {...this.props} />
              </Pane>

              {is_not_template && (
                <Pane
                  backgroundColor="#E8E7EE" 
                  padding={16}
                  paddingBottom={8}
                  display="flex"
                  flexDirection="column"
                  marginTop={16}
                >
                  <Pane
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Pane 
                      display="flex" 
                      flexDirection="column" 
                      alignItems="flex-start" 
                      justifyContent="flex-start"
                    >
                      <Heading marginBottom={48}>Cover and Images</Heading>
                      <HedButton 
                        appearance="hedflatdark" 
                        padding={0}
                        onClick={e => this.toggleStep(0)}
                      >
                        {steps_expanded[0] ? (
                          <ChevronDownIcon />
                        ) : (
                          <ChevronRightIcon />
                        )}
                        View all images
                      </HedButton>
                    </Pane>
                    <Pane maxWidth="250px">
                      <UploadPrompt
                        onFileDrop={onFileDrop}
                        label={`Upload An${
                          this.props.project.images.length ? "other" : ""
                        } Image`}
                        accept=".jpg, .jpeg, .png"
                      >
                        <Pane paddingTop={8} paddingBottom={8} textAlign="center">
                          <Text size={300}>Drag a file from your computer or click above to upload</Text>
                        </Pane>
                      </UploadPrompt>
                    </Pane>
                  </Pane>
                  {steps_expanded[0] && (
                    <ImageList
                      project={this.props.project}
                      setCoverImage={path => {
                        this.props.updateProjectMeta({ cover_image: path });
                      }}
                      handleUpdateImageMeta={this.handleUpdateImageMeta}
                      alt_text={this.props.alt_text}
                    />
                  )}
                </Pane>
              )}

              {is_not_template && (
                <ExpandablePaneTinted
                  label={`Configure Export Settings`}
                  onClick={this.toggleStep}
                  index={4}
                  expanded={steps_expanded[4]}
                  icon={SettingsIcon}
                >
                  <ConfigureExportSettings
                    project={this.props.project}
                    section_list = {this.props.section_list}
                    export_settings_style={export_settings_style}
                    is_advanced={this.props.project.is_advanced}
                    onChange={this.onChange.bind(this)}
                  />
                </ExpandablePaneTinted>
              )}
              {/*if we do add progress for this one XXX*/}
              {/* complete={progress[5]} */}
            </Pane>
          </Pane>
        </UploadWrapper>
        <Pane width="25vw" minWidth="300px" borderLeft="2px solid #373550" padding={16}>
          {is_not_template ? (
            <Pane>
              <Heading size={700}>
                Exports
              </Heading>
              <Pane display="flex" flexDirection="column" marginBottom={16}>
                <OutputButtons
                  {...this.props}
                  output={this.props.project.output}
                  title={this.props.project.title}
                  advanced_config_path={this.props.project.advanced_config_path}
                  trigger_rebuild={this.trigger_rebuild.bind(this)}
                  font_warnings={this.props.font_warnings}
                  can_rebuild={this.props.section_list && this.props.section_list.length ? true : false}
                  project_templates={this.state.project_templates}
                />
              </Pane>
              <SnapshotCreator {...this.props} />
              <ExpandablePaneUnderline
                label={`EPUB Validation`}
                onClick={this.toggleStep}
                index={5}
                expanded={steps_expanded[5]}
              >
                <EpubCheckView project={this.props.project} />
              </ExpandablePaneUnderline>
            {should_display_font_warnings ? (
              <ExpandablePaneUnderline
                label={`PDF Warnings`}
                onClick={this.toggleStep}
                index={6}
                expanded={steps_expanded[6]}
                icon={IssueIcon}
              >
                <FontWarningsView
                  font_warnings={this.props.font_warnings}
                  section_meta={this.props.project.section_meta}
                />
              </ExpandablePaneUnderline>
            ) : (
              ""
            )}
            </Pane>
          ) : (
            <Pane>
              <Heading size={700}>
                This Is a Design Template
              </Heading>
              <Paragraph marginTop={32}>Exports are disabled for template projects.</Paragraph>
              <Paragraph marginTop={32} fontWeight="bold">Some template design tips:</Paragraph>
              <Paragraph marginTop={32}>
                Avoid using "Only This" selectors when setting up your design. 
                These selectors are tied to specific paragraphs in a book's text; 
                Since this template will be applied to many different books, 
                your settings on specific paragraphs in this sample project won't 
                carry over to those other books.
              </Paragraph>
            </Pane>
          )}
        </Pane>
      </Pane>
    );
  }
}

function get_docx_fname(p, title) {
  // input_docx\/(.*)\.docx
  // numerals!!!
  // _(\d+).docx
  let fname = p.split(/(\\|\/)/g).pop();
  // console.log (fname.length);
  if (fname.length > 50 && title) {
    fname = fname.substring(0, 50) + "..." + ".docx";
  }
  // get rid of ts
  return fname.replace(/_(\d+)\.docx/, ".docx");
}

const METAMORPHOSIS_EXAMPLE_PATH = `public/examples/metamorphosis-placeholder-text.docx`;
