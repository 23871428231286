// NOTE: Functions from this file are shared in other repos; 
// If you make updates here, be sure to run `npm run distro-shared` 
// which will distribute your changes as appropriate 
// (be sure to clone any missing repos as prompted).

import { defaultTheme, ThemeProvider, Button, mergeTheme } from "evergreen-ui";

export default function HedButton(props) {
  const theme = mergeTheme(defaultTheme, {
    components: {
      Button: {
        ...defaultTheme.components.Button,
        appearances: {
          hedprimary: {
            color: '#ffffff',
            paddingX: 12,
            paddingY: 8,
            borderRadius: 5,
            backgroundColor: '#07C896',
            _hover: {
              backgroundColor: '#07C896',
            },
            _active: {
              backgroundColor: '#07C896',
            },
            _focus: {
              boxShadow: '0 0 0 2px lightcoral',
            },
          },
          hedunfocus: {
            color: '#5D597A',
            paddingX: 12,
            paddingY: 8,
            borderRadius: 5,
            borderColor: '#5D597A',
            backgroundColor: 'transparent',
            _hover: {
              color: '#ffffff',
              backgroundColor: '#5D597A',
            },
            _active: {
              color: '#ffffff',
              backgroundColor: '#5D597A',
            },
            _focus: {
              boxShadow: '0 0 0 2px lightcoral',
            },
          },
          hedsecondary: {
            color: '#ffffff',
            paddingX: 12,
            paddingY: 8,
            borderRadius: 5,
            borderColor: '#5D597A',
            backgroundColor: '#5D597A',
            _hover: {
              color: '#ffffff',
              borderColor: '#373550',
              backgroundColor: '#373550',
            },
            _active: {
              color: '#ffffff',
              borderColor: '#373550',
              backgroundColor: '#373550',
            },
            _focus: {
              boxShadow: '0 0 0 2px lightcoral',
            },
          },
          heddisabled: {
            color: '#ffffff',
            paddingX: 12,
            paddingY: 8,
            borderRadius: 5,
            borderColor: '#B3B0C6',
            backgroundColor: '#B3B0C6',
            _hover: {
              color: '#ffffff',
              borderColor: '#B3B0C6',
              backgroundColor: '#B3B0C6',
            },
            _active: {
              color: '#ffffff',
              borderColor: '#B3B0C6',
              backgroundColor: '#B3B0C6',
            },
            _focus: {
              boxShadow: '0 0 0 2px lightcoral',
            },
          },
          hedflat: {
            color: '#ffffff',
            paddingX: 12,
            paddingY: 8,
            borderRadius: 5,
            borderColor: 'transparent',
            backgroundColor: 'transparent',
            _hover: {
              color: '#07C896',
              backgroundColor: 'transparent',
            },
            _active: {
              color: '#07C896',
              backgroundColor: 'transparent',
            },
            _focus: {
              boxShadow: '0 0 0 0px transparent',
            },
          },
          hedflatdark: {
            color: '#373550',
            paddingX: 12,
            paddingY: 8,
            borderRadius: 5,
            borderColor: 'transparent',
            backgroundColor: 'transparent',
            _hover: {
              color: '#07C896',
              backgroundColor: 'transparent',
            },
            _active: {
              color: '#07C896',
              backgroundColor: 'transparent',
            },
            _focus: {
              boxShadow: '0 0 0 0px transparent',
            },
          },
        },
      },
    },
  })

  return (
    <ThemeProvider value={theme}>
      <Button {...props} appearance={props.appearance}>{props.children}</Button>
    </ThemeProvider>
  )
}