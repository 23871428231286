import React from "react";
import ReactDOM from "react-dom";
import { Pane, Text, Link, IconButton, Heading, InfoSignIcon, ComparisonIcon, StyleIcon, HomeIcon, IssueIcon, CogIcon, EditIcon, toaster } from "evergreen-ui";
import { Router } from "react-enroute";
// import {
//   BrowserRouter as Router,
//   Switch,
//   Route
// } from "react-router-dom";
import { firebase, db } from "../fire.js";

import TopNav from "./shared/top_nav.js";
import Account from "./shared/account.js";
import Fonts from "./shared/fonts.js";
import Newsletter from "./shared/newsletter.js";

import ProjectsList from "./shared/projects_list.js";
import ProjectInfoView from "./shared/info.js";

import ProjectSettingsView from "./shared/settings.js";
import GroupSettingsView from "./shared/group_settings.js";
import CreateGroupView from "./shared/create_group.js";
import InviteAcceptor from "./shared/invite_acceptor.js";

import { NotFound } from "./shared/common.js";
// prettier-ignore
import { NewGalleyProjectContainer, NewAdvancedProjectContainer, NewTemplateProjectContainer} from "./shared/containers.js";
// data layer, that picks basic_main vs advanced, which has the subroutes TODO move them
import MainContainer from "./main_container.js";
import "./app.less";

import { DateTime } from "luxon";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.force = e => {
      this.forceUpdate();
    };
    window.addEventListener("popstate", this.force);
    window.addEventListener("hashchange", this.force);
    // console.log(props);
  }
  componentDidMount() {}
  componentWillUnmount() {
    // console.log("unmounting app");
    window.removeEventListener("popstate", this.force);
    window.removeEventListener("hashchange", this.force);
  }

  track() {
    gtag("config", process.env.GA_TRACKING_ID, {
      page_location: window.location.toString(),
    });
  }
  render() {
    this.track();
    let hash = window.location.hash || `#/`;

    return (
      <div>
        <TopNav 
          {...this.props} 
        />
        {this.props.hederis_user.uid && !this.props.hederis_user.newsletter_optin && (
          <Newsletter email={this.props.hederis_user.email} uid={this.props.hederis_user.uid} />
        )}
        <Router location={hash}>
          {/*<Route path="#/" component={Home} />*/}
          {/* for now, just default to project list view */}
          <ProjectsList path="#/" {...this.props} />

          <NewAdvancedProjectContainer
            path="#/new/typeset/"
            {...this.props}
          />
          <NewAdvancedProjectContainer
            path="#/new/typeset/:discount_code"
            {...this.props}
          />
          <NewTemplateProjectContainer
            path="#/new/template/:discount_code"
            {...this.props}
          />
          <NewGalleyProjectContainer
            path="#/new/galley/"
            {...this.props}
          />
          <NewGalleyProjectContainer
            path="#/new/galley/:discount_code"
            {...this.props}
          />
          <MainContainer
            path="#/projects/:id"
            {...this.props}
          />
          <InviteAcceptor
            path="#/projects/:id/invited/:invite_code"
            {...this.props}
          />
          <MainContainer
            path="#/projects/:id/:area"
            {...this.props}
          />
          <MainContainer
            path="#/projects/:id/:area/:sub"
            {...this.props}
          />
          <InviteAcceptor
            path="#/group/:group_id/invited/:invite_code"
            {...this.props}
          />
          {/* reload kludge*/}
          <InviteAcceptor
            path="#/group/:group_id/invited/:invite_code/:reload_flag"
            {...this.props}
          />
          {/* group owners only */}
          <GroupSettingsView
            path="#/group/:group_id"
            {...this.props}
          />
          <CreateGroupViewBetaWrap
            path="#/create_group/"
            {...this.props}
          />
          {/* a kludge to reload the page after group creation */}
          <CreateGroupViewBetaWrap
            path="#/create_group/:group_id_flag"
            {...this.props}
          />

          <GroupsSalesInfo path="#/groups" {...this.props} />
          <Fonts path="#/fonts" {...this.props} />
          <Account path="#/account" {...this.props} />
          <LogOut path="#/logout" />

          <NotFound path="*" />
        </Router>
      </div>
    );
  }
}

// XXX Just for beta, eventually we'll make this dependent on billing being set up
// for now, we can just set beta_can_create_group for the user in the console
function CreateGroupViewBetaWrap(props) {
  return <CreateGroupView {...props} />;
}

function GroupsSalesInfo(props) {
  return (
    <Pane
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginTop={32}
    >
      <Heading size={800} marginTop={8}>
        Do more with Hederis Organizations
      </Heading>
      <Heading size={500} marginTop={32}>
        Collaboration Tools, Access Control
      </Heading>
      <Heading size={500} marginTop={32}>
        Custom Designed Templates for Print and Digital
      </Heading>
      <Pane marginTop={48}>
        <Text>
          Please contact us for more information:{" "}
          <Link href="mailto:info@hederis.com?subject=Hederis Organizations">
            info@hederis.com
          </Link>
        </Text>
      </Pane>
    </Pane>
  );
}
function Home(props) {
  return (
    <Pane display="flex" flexDirection="column" alignItems="center">
      <Heading>
        Welcome! Check out your <a href="#/projects/">projects</a>
      </Heading>
    </Pane>
  );
}

function LogOut(props) {
  firebase
    .auth()
    .signOut()
    .then(() => {
      let url = window.location.toString().split("#")[0];
      window.location = url;
    });

  return <p>Logging you out.</p>;
}
