import React from "react";

// prettier-ignore
import {Pane, Text, Heading, Button, TextInputField, TabNavigation, Tab, SidebarTab, Tablist, Link, toaster, IconButton, Icon, Small, TextInput, RadioGroup, Select, Strong, Checkbox, Combobox, TagInput, Portal} from "evergreen-ui";
import relativeDate from "tiny-relative-date";

import { firebase, db, storage } from "../../fire.js";
import APIClient from "../../helpers/api_client";
import { FireHelpers } from "../../../api/app_shared/fire_helpers.js";
import TemplateBuilder from "./template_builder.js";

// import template_builder_defaults from "../../../api/app_shared/defaults/template_builder_defaults.json";

const API = new APIClient(firebase);
const FH = new FireHelpers(db, firebase);

export default class TemplateBuilderContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { template_builder_config: false, display_name: false };
  }

  async componentDidMount() {
    let template = await FH.get_basic_template_by_id(
      this.props.editing_template_builder_id
    );
    let { template_builder_config, display_name } = template;

    this.setState({ template_builder_config, display_name });
  }
  onConfigChange(template_builder_config) {
    this.setState({ template_builder_config });
  }
  onNameChange(display_name) {
    this.setState({ display_name });
  }
  async onSaveAndSelect() {
    // this.props.
    let template_id = this.props.editing_template_builder_id;
    // TODO , FH, and then call a prop to select it, same as picker
    // this.setState({ display_name });
    await FH.update_template_builder(
      template_id,
      this.state.template_builder_config,
      this.state.display_name
    );
    // async update_template_builder(template_id, template_builder_config) {

    this.props.onSelectDone(template_id);
  }
  // todo saveasnew and select
  render() {
    let { template_builder_config } = this.state;
    // let template_builder_config = this.state.template
    //   ? this.state.template.template_builder_config
    //   : false;
    return (
      <Pane>
        <Controls
          onCancel={this.props.onCancel}
          {...this.state}
          onNameChange={s => this.onNameChange(s)}
          onSaveAndSelect={s => this.onSaveAndSelect()}
        />
        {template_builder_config ? (
          <TemplateBuilder
            config={template_builder_config}
            onChange={config => this.onConfigChange(config)}
          />
        ) : (
          ``
        )}
      </Pane>
    );
  }
}

function Controls(props) {
  return (
    <Pane
      background="tint2"
      padding={8}
      borderTop
      borderBottom
      position="sticky"
      top={"0px"}
      zIndex={101}
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      height={45}
    >
      <Text size={500} marginRight={8}>
        Template Builder
      </Text>
      <TextInput
        placeholder="Untitled Template Builder"
        value={props.display_name}
        onChange={e => props.onNameChange(e.target.value)}
        marginRight={16}
      />
      <Button appearance="primary" onClick={() => props.onSaveAndSelect()}>
        Save Template & Select
      </Button>
      {/*<Button appearance="primary" onClick={() => props.onSaveAsNew()}>
        Save As New Template & Select
      </Button>*/}
      <Button onClick={() => props.onCancel()}>Cancel</Button>
    </Pane>
  );
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// // this is not a great way to do this
// let pub_template_ids = ["default", "number_two", "staff_test"];
// let group_template_id = !pub_template_ids.includes(template) ? template : false;
// // TODO, group should be based on if they're in the group, and the Project.group
// // i.e. only group memebers can edit group templates XXX
// console.log("group_template_id is", group_template_id);
// // todo, generalize this so it can be used outside project
// return (
//   <Pane>
//     <TemplateBuilder
//       template_config={custom_project_template}
//       group_template_id={group_template_id}
//       project={project}
//       onCancel={() => this.setState({ show_template_builder: false })}
//       // todo, redo this generalize, remove save_to_project .
//       onSaveToGroup={async (
//         display_name,
//         custom_group_template_config,
//         group_template_id = false
//       ) => {
//         // save existing
//         if (group_template_id) {
//           await db
//             .collection("style_templates")
//             .doc(group_template_id)
//             .update({
//               display_name,
//               config: custom_group_template_config,
//               ts: firebase.firestore.FieldValue.serverTimestamp(),
//             });
//         } else {
//           let data = {
//             group: this.props.project.group,
//             display_name,
//             config: custom_group_template_config,
//             ts: firebase.firestore.FieldValue.serverTimestamp(),
//           };
//           let docRef = await db.collection("style_templates").add(data);
//           group_template_id = docRef["id"];
//         }
//         // TODO, do this..blarg a quick hacky version because of the security branch
//         this.onChange({ template: group_template_id });
//         toaster.success("Template Saved to Group and Set for this Project!");
//         this.setState({ show_template_builder: false });
//       }}
//       // XXX remove this
//       onSaveToProject={async custom_project_template => {
//         // save it to the project
//         this.onChange({ custom_project_template, template: "custom" });
//         toaster.success("Template Saved to Project!");
//         this.setState({ show_template_builder: false });
//       }}
//       // group={this.props.project.group}
//       // project_id={this.props.params.id}
//       // template={template}
//       // user={hederis_user}
//       // initial_template_builder_config={
//       //   template_builder_defaults
//       //   // template_config || template_builder_defaults
//       // }
//     />
//   </Pane>
// );
