function strip_dashes(html_string) {
  // prettier-ignore
  return html_string.replace(/(data-hederis-type=)("|')(h)(blk|sec|wpr)([a-zA-Z0-9]*)(-)([a-zA-Z0-9]*)(-*)/g, '$1$2$3$4$5$7');
}

function createTitlepageSubsel(myhtml) {
   var titlepage = myhtml.querySelectorAll("*[data-hederis-type='hsectitlepage'] > *, *[data-hederis-type='hsechalftitlepage'] > *");
   for (var i = 0; i < titlepage.length; i++) {
      var mypartype = titlepage[i].parentNode.getAttribute("data-hederis-type");
      if (titlepage[i].getAttribute("data-hederis-type")) {
         var mytype = titlepage[i].getAttribute("data-hederis-type");
         var mysubsel = "PARENT_" + mypartype + "_" + mytype;
         titlepage[i].setAttribute("data-selector-type",mysubsel);
      }
   }
   return myhtml;
}

function getStyleList(myhtml, loadedjson) {
  var allTypes = [];

  var allElements = myhtml.querySelectorAll('*');

  for (var i = 0; i < allElements.length; i++) {
    if (allElements[i].getAttribute("data-hederis-type") !== null && allElements[i].getAttribute("data-hederis-type") !== "") {
      var mytype = allElements[i].getAttribute("data-hederis-type")
      if (mytype && allTypes.indexOf(mytype) === -1) {
        allTypes.push(mytype);
      }
      var mysel = allElements[i].getAttribute("data-selector-type")
      if (mysel && allTypes.indexOf(mysel) === -1) {
        allTypes.push(mysel);
      }
    }
  }
  // migrate style names to hyphenless versions
  for (var k in loadedjson) {
    if (loadedjson.hasOwnProperty(k) && k.match("^h[a-zA-Z0-9]+-")) {
      var kk = k.replace(/-/g,"");
      loadedjson[kk] = loadedjson[k];
      if (k.match(/_subSelectors$/)) {
        for (var j in loadedjson[k]) {
          if (loadedjson[k].hasOwnProperty(j)) {
            var jj = j.replace(/-/g,"");
            loadedjson[kk][jj] = loadedjson[k][j];
            for (var l in loadedjson[k][j]) {
              if (loadedjson[k][j].hasOwnProperty(l)) {
                var ll = l.replace(/-/g,"");
                loadedjson[kk][jj][ll] = loadedjson[k][j][l];
              }
            }
          }
        }
      }
      allTypes.push(kk);
      allTypes = allTypes.filter(function(value, index, arr){
        return value != k;
      });
    }
  }
  // add subselectors to our typelist
  for (var k in loadedjson) {
    if (loadedjson.hasOwnProperty(k)) {
      if (k.match(/_subSelectors$/)) {
        for (var j in loadedjson[k]) {
          if (loadedjson[k].hasOwnProperty(j)) {
            if (allTypes.indexOf(j) === -1) {
              allTypes.push(j);
            }
          }
        }
      }
    }
  }

  return [allTypes,loadedjson];
}

function makeJSON(myjson,loadedjson,k,v,subsel=false) {
  var jsondefaults = {"hblkoli_listStyleType": "decimal",
                      "hblkoli_display": "list-item",
                      "hblkoli_textIndent": "0pt",
                      "hwprnumlist_margins": ["0pt","0pt","0pt","14pt"],
                      "hblkuli_listStyleType": "disc",
                      "hblkuli_display": "list-item",
                      "hblkuli_textIndent": "0pt",
                      "hwprbulletlist_margins": ["0pt","0pt","0pt","14pt"],
                      "hblklip_textIndent": "0pt",
                      "hblktocFM_listStyleType": "none",
                      "hblktocFM_textIndent": "0pt",
                      "hblktocChap_listStyleType": "none",
                      "hblktocChap_textIndent": "0pt",
                      "hblktocPart_listStyleType": "none",
                      "hblktocPart_textIndent": "0pt",
                      "hblktocBM_listStyleType": "none",
                      "hblktocBM_textIndent": "0pt",
                      "hblkcode_font": "Letter Gothic Std",
                      "hblkchaptitle_fontSize": "22pt",
                      "hblkchaptitle_textAlign": "center",
                      "hblkchaptitle_textAlignLast": "center",
                      "hblkchaptitle_textIndent": "0pt",
                      "hblktitle_fontSize": "16pt",
                      "hblktitle_textAlign": "center",
                      "hblktitle_textAlignLast": "center",
                      "hblktitle_textIndent": "0pt",
                      "hblkfootnote_fontSize": "8pt",
                      "hwprbox_margins": ["14pt","14pt","14pt","14pt"],
                      "hwprtable_display": "table",
                      "hwprtr_display": "table-row",
                      "hwprtd_display": "table-cell",
                      "hwprfootnote_textIndent": "0px",
                      "hspanem_fontStyle": "italic",
                      "hspanstrong_fontWeight": "bold",
                      "hspansup_verticalAlign": "super",
                      "hspansub_verticalAlign": "sub",
                      "hspanunderline_underlineType": "underline",
                      "hspanunderline_underlineStyle": "solid"};
  if (loadedjson && loadedjson.hasOwnProperty(k)) {
    myjson[k] = loadedjson[k];
    // console.log(k, loadedjson[k]);
    // confirmed: loaded JSON is getting passed through to the subselector round
  } else if (subsel === false) {
    if (jsondefaults && jsondefaults.hasOwnProperty(k)) {
      myjson[k] = jsondefaults[k];
    } else {
      myjson[k] = v;
    }
  }
  return myjson;
}

function makeMasterKeys(master, keys) {
  var key;
  var pages = ["recto","verso","first","blank"];
  var margins = ["TopLeftCorner","TopLeft","TopCenter","TopRight","TopRightCorner",
                 "BottomLeftCorner","BottomLeft","BottomCenter","BottomRight","BottomRightCorner",
                 "LeftTop","LeftMiddle","LeftBottom",
                 "RightTop","RightMiddle","RightBottom"];
  pages.forEach(function(page) {
    margins.forEach(function(margin) {
      key = `master_${master}_${page}_margin${margin}`
      keys.push(key);
    });
  });
  return keys;
}

module.exports = function(myhtml,origjson = undefined,advanced_config_version = 0) {
  myhtml = strip_dashes(myhtml);
  var src = document.createElement( 'html' );
  src.innerHTML = myhtml;
  //src = createTitlepageSubsel(src);
  var [typelist,loadedjson] = getStyleList(src, origjson);
  if (
    typelist.includes("hblkfootnote")
  ) {
    typelist.push("hspannoteref");
    typelist.push("hspannotemarker");
    typelist.push("hspannotecall");
  }
  // TOC styles
  var toc_styles = [
    "hblktocFM",
    "hblktocChap",
    "hblktocPart",
    "hblktocBM",
    "hblklip",
    "hspana",
    "hwprnumlist"
  ];
  if (typelist.includes("hsectoc")) {
    for (var i = 0; toc_styles.length > i; i++) {
      if (!typelist.includes(toc_styles[i])) {
        typelist.push(toc_styles[i]);
      }
    }
  }

  var initialjson = {};
  var liststyles = [
    "hblktocFM",
    "hblktocChap",
    "hblktocPart",
    "hblktocBM",
    "hblkoli",
    "hblkuli",
    "hwprbulletlist",
    "hwprnumlist"
  ];

  var defaults_global = {
    "page_width": "6in",
    "page_height": "9in",
    "body_bodyFont": "Minion Pro",
    "body_fallbackFont": "Minion Pro",
    "body_bodySize": "11pt",
    "body_lineHeight": "14pt",
    "hyphens_hyphenationLanguage": "en-us",
    "hyphens_minLeft": "2",
    "hyphens_minRight": "3",
    "hyphens_exceptions": "",
    "master_chapter_verso_margins": ["56pt", "0.65in", "0.5in", "0.5in"],
    "master_chapter_recto_margins": ["56pt", "0.5in", "0.5in", "0.65in"],
    "master_chapter_footnoteMargin": "12pt",
    "master_frontmatter_verso_margins": ["56pt", "0.65in", "0.5in", "0.5in"],
    "master_frontmatter_recto_margins": ["56pt", "0.5in", "0.5in", "0.65in"],
    "master_frontmatter_footnoteMargin": "12pt",
    "master_backmatter_verso_margins": ["56pt", "0.65in", "0.5in", "0.5in"],
    "master_backmatter_recto_margins": ["56pt", "0.5in", "0.5in", "0.65in"],
    "master_backmatter_footnoteMargin": "12pt",
    "master_part_verso_margins": ["56pt", "0.65in", "0.5in", "0.5in"],
    "master_part_recto_margins": ["56pt", "0.5in", "0.5in", "0.65in"],
    "master_part_footnoteMargin": "12pt",
    "master_clear_verso_margins": ["56pt", "0.65in", "0.5in", "0.5in"],
    "master_clear_recto_margins": ["56pt", "0.5in", "0.5in", "0.65in"],
    "master_clear_footnoteMargin": "12pt",
  };

  var defaults_original_template = {
    "master_chapter_runhead_font": "Minion Pro",
    "master_chapter_runhead_fontSize": "11pt",
    "master_chapter_runhead_fontWeight": "normal",
    "master_chapter_runhead_fontStyle": "normal",
    "master_chapter_runhead_textColor": "#000000",
    "master_chapter_runhead_spaceBelow": "14pt",
    "master_chapter_runfoot_font": "Minion Pro",
    "master_chapter_runfoot_fontSize": "11pt",
    "master_chapter_runfoot_fontWeight": "normal",
    "master_chapter_runfoot_fontStyle": "normal",
    "master_chapter_runfoot_textColor": "#000000",
    "master_chapter_runfoot_spaceAbove": "0pt",
    "master_chapter_folio_font": "Minion Pro",
    "master_chapter_folio_fontSize": "11pt",
    "master_chapter_folio_fontWeight": "normal",
    "master_chapter_folio_fontStyle": "normal",
    "master_chapter_folio_textColor": "black",
    "master_chapter_verso_runheadContent": ["normal"],
    "master_chapter_verso_runheadAlignment": "center",
    "master_chapter_verso_runfootContent": ["normal"],
    "master_chapter_verso_runfootAlignment": "center",
    "master_chapter_recto_runheadContent": ["normal"],
    "master_chapter_recto_runheadAlignment": "center",
    "master_chapter_recto_runfootContent": ["normal"],
    "master_chapter_recto_runfootAlignment": "center",
    "master_chapter_first_marginTop": "112pt",
    "master_chapter_first_marginBottom": "0.5in",
    "master_chapter_first_runfootContent": ["normal"],
    "master_chapter_first_runheadContent": ["normal"],
    "master_chapter_blank_runfootContent": ["normal"],
    "master_chapter_blank_runheadContent": ["normal"],
    "master_frontmatter_runhead_font": "Minion Pro",
    "master_frontmatter_runhead_fontSize": "11pt",
    "master_frontmatter_runhead_fontWeight": "normal",
    "master_frontmatter_runhead_fontStyle": "normal",
    "master_frontmatter_runhead_textColor": "#000000",
    "master_frontmatter_runhead_spaceBelow": "14pt",
    "master_frontmatter_runfoot_font": "Minion Pro",
    "master_frontmatter_runfoot_fontSize": "11pt",
    "master_frontmatter_runfoot_fontWeight": "normal",
    "master_frontmatter_runfoot_fontStyle": "normal",
    "master_frontmatter_runfoot_textColor": "#000000",
    "master_frontmatter_runfoot_spaceAbove": "0pt",
    "master_frontmatter_folio_font": "Minion Pro",
    "master_frontmatter_folio_fontSize": "11pt",
    "master_frontmatter_folio_fontWeight": "normal",
    "master_frontmatter_folio_fontStyle": "normal",
    "master_frontmatter_folio_textColor": "black",
    "master_frontmatter_verso_runheadContent": ["normal"],
    "master_frontmatter_verso_runheadAlignment": "center",
    "master_frontmatter_verso_runfootContent": ["normal"],
    "master_frontmatter_verso_runfootAlignment": "center",
    "master_frontmatter_recto_runheadContent": ["normal"],
    "master_frontmatter_recto_runheadAlignment": "center",
    "master_frontmatter_recto_runfootContent": ["normal"],
    "master_frontmatter_recto_runfootAlignment": "center",
    "master_frontmatter_first_marginTop": "112pt",
    "master_frontmatter_first_marginBottom": "0.5in",
    "master_frontmatter_first_runfootContent": ["normal"],
    "master_frontmatter_first_runheadContent": ["normal"],
    "master_frontmatter_blank_runfootContent": ["normal"],
    "master_frontmatter_blank_runheadContent": ["normal"],
    "master_backmatter_runhead_font": "Minion Pro",
    "master_backmatter_runhead_fontSize": "11pt",
    "master_backmatter_runhead_fontWeight": "normal",
    "master_backmatter_runhead_fontStyle": "normal",
    "master_backmatter_runhead_textColor": "#000000",
    "master_backmatter_runhead_spaceBelow": "14pt",
    "master_backmatter_runfoot_font": "Minion Pro",
    "master_backmatter_runfoot_fontSize": "11pt",
    "master_backmatter_runfoot_fontWeight": "normal",
    "master_backmatter_runfoot_fontStyle": "normal",
    "master_backmatter_runfoot_textColor": "#000000",
    "master_backmatter_runfoot_spaceAbove": "0pt",
    "master_backmatter_folio_font": "Minion Pro",
    "master_backmatter_folio_fontSize": "11pt",
    "master_backmatter_folio_fontWeight": "normal",
    "master_backmatter_folio_fontStyle": "normal",
    "master_backmatter_folio_textColor": "black",
    "master_backmatter_verso_runheadContent": ["normal"],
    "master_backmatter_verso_runheadAlignment": "center",
    "master_backmatter_verso_runfootContent": ["normal"],
    "master_backmatter_verso_runfootAlignment": "center",
    "master_backmatter_recto_runheadContent": ["normal"],
    "master_backmatter_recto_runheadAlignment": "center",
    "master_backmatter_recto_runfootContent": ["normal"],
    "master_backmatter_recto_runfootAlignment": "center",
    "master_backmatter_first_marginTop": "112pt",
    "master_backmatter_first_marginBottom": "0.5in",
    "master_backmatter_first_runfootContent": ["normal"],
    "master_backmatter_first_runheadContent": ["normal"],
    "master_backmatter_blank_runfootContent": ["normal"],
    "master_backmatter_blank_runheadContent": ["normal"],
    "master_part_runhead_font": "Minion Pro",
    "master_part_runhead_fontSize": "11pt",
    "master_part_runhead_fontWeight": "normal",
    "master_part_runhead_fontStyle": "normal",
    "master_part_runhead_textColor": "#000000",
    "master_part_runhead_spaceBelow": "14pt",
    "master_part_runfoot_font": "Minion Pro",
    "master_part_runfoot_fontSize": "11pt",
    "master_part_runfoot_fontWeight": "normal",
    "master_part_runfoot_fontStyle": "normal",
    "master_part_runfoot_textColor": "#000000",
    "master_part_runfoot_spaceAbove": "0pt",
    "master_part_folio_font": "Minion Pro",
    "master_part_folio_fontSize": "11pt",
    "master_part_folio_fontWeight": "normal",
    "master_part_folio_fontStyle": "normal",
    "master_part_folio_textColor": "black",
    "master_part_verso_runheadContent": ["normal"],
    "master_part_verso_runheadAlignment": "center",
    "master_part_verso_runfootContent": ["normal"],
    "master_part_verso_runfootAlignment": "center",
    "master_part_recto_runheadContent": ["normal"],
    "master_part_recto_runheadAlignment": "center",
    "master_part_recto_runfootContent": ["normal"],
    "master_part_recto_runfootAlignment": "center",
    "master_part_first_marginTop": "112pt",
    "master_part_first_marginBottom": "0.5in",
    "master_part_first_runfootContent": ["normal"],
    "master_part_first_runheadContent": ["normal"],
    "master_part_blank_runfootContent": ["normal"],
    "master_part_blank_runheadContent": ["normal"],
    "master_clear_runhead_font": "Minion Pro",
    "master_clear_runhead_fontSize": "11pt",
    "master_clear_runhead_fontWeight": "normal",
    "master_clear_runhead_fontStyle": "normal",
    "master_clear_runhead_textColor": "#000000",
    "master_clear_runhead_spaceBelow": "14pt",
    "master_clear_runfoot_font": "Minion Pro",
    "master_clear_runfoot_fontSize": "11pt",
    "master_clear_runfoot_fontWeight": "normal",
    "master_clear_runfoot_fontStyle": "normal",
    "master_clear_runfoot_textColor": "#000000",
    "master_clear_runfoot_spaceAbove": "0pt",
    "master_clear_folio_font": "Minion Pro",
    "master_clear_folio_fontSize": "11pt",
    "master_clear_folio_fontWeight": "normal",
    "master_clear_folio_fontStyle": "normal",
    "master_clear_folio_textColor": "black",
    "master_clear_verso_runheadContent": ["normal"],
    "master_clear_verso_runheadAlignment": "center",
    "master_clear_verso_runfootContent": ["normal"],
    "master_clear_verso_runfootAlignment": "center",
    "master_clear_recto_runheadContent": ["normal"],
    "master_clear_recto_runheadAlignment": "center",
    "master_clear_recto_runfootContent": ["normal"],
    "master_clear_recto_runfootAlignment": "center",
    "master_clear_first_marginTop": "112pt",
    "master_clear_first_marginBottom": "0.5in",
    "master_clear_first_runfootContent": ["normal"],
    "master_clear_first_runheadContent": ["normal"],
    "master_clear_blank_runfootContent": ["normal"],
    "master_clear_blank_runheadContent": ["normal"]
  };
  // create master page JSON
  var masters = ["chapter","part","frontmatter","clear","backmatter"];

  var master_defaults = {
    "_font": "Minion Pro",
    "_fontSize": "11pt",
    "_fontWeight": "normal",
    "_fontStyle": "normal",
    "_textColor": "black",
    "_padding": ["0pt","0pt","0pt","0pt"],
    "_backgroundColor": "transparent",
    "_borderTop": ["0px","solid","black"],
    "_borderRight": ["0px","solid","black"],
    "_borderBottom": ["0px","solid","black"],
    "_borderLeft": ["0px","solid","black"],
    "_content": ["normal"],
    "_folioStyle": "decimal",
    "_textAlign": "center",
    "_width": "auto",
    "_openType": ["normal"]
  };
  // make all the margin area prefixes programatically,
  // since there's so many of them:
  var keys = [];
  masters.forEach(function(master) {
    keys = makeMasterKeys(master, keys);
  });

  keys.forEach(function(prefix) {
    // see if the prefix exists in the loaded json
    run = false;
    for (var k in master_defaults) {
      if (loadedjson.hasOwnProperty(prefix + k)) {
        run = true;
        break;
      }
    }
    // if the prefix has been defined, create the json
    if (run) {
      for (var k in master_defaults) {
        initialjson = makeJSON(initialjson,loadedjson,prefix + k,master_defaults[k]);
      }
    }
  });

  for (var k in defaults_global) {
    if (defaults_global.hasOwnProperty(k)) {
      initialjson = makeJSON(initialjson,loadedjson,k,defaults_global[k]);
    }
  }

  if (!advanced_config_version || parseFloat(advanced_config_version) < 1 ) {
    for (var k in defaults_original_template) {
      if (defaults_original_template.hasOwnProperty(k)) {
        initialjson = makeJSON(initialjson,loadedjson,k,defaults_original_template[k]);
      }
    }
  }

  typelist.forEach(function(el) {
    el = el.replace(/-/g,"");
    // processing sections
    if (el.match(/^hsec/g)) {
      var clearsects = ["hsechalftitlepage",
                        "hsectitlepage",
                        "hseccopyrightpage",
                        "hsecdedication",
                        "hsecepigraph",
                        "hsecadcard"];
      var fmsects = ["hsecforeword",
                     "hsecpreface",
                     "hsectoc"];
      var bmsects = ["hsecappendix",
                     "hseccolophon",
                     "hsecacknowledgments",
                     "hsecafterword",
                     "hsecconclusion",
                     "hsecglossary",
                     "hsecbibliography",
                     "hsecabouttheauthor",
                     "hsecindex",
                     "hsecendnotes"];
      var partsects = ["hsecpart"];
      if (clearsects.indexOf(el) >= 0) {
        initialjson = makeJSON(initialjson,loadedjson,el + '_page',"clear");
      } else if (fmsects.indexOf(el) >= 0) {
        initialjson = makeJSON(initialjson,loadedjson,el + '_page',"frontmatter");
      } else if (bmsects.indexOf(el) >= 0) {
        initialjson = makeJSON(initialjson,loadedjson,el + '_page',"backmatter");
      } else if (partsects.indexOf(el) >= 0) {
        initialjson = makeJSON(initialjson,loadedjson,el + '_page',"part");
      } else {
        initialjson = makeJSON(initialjson,loadedjson,el + '_page',"chapter");
      }
      initialjson = makeJSON(initialjson,loadedjson,el + '_pageStart',"right");
      initialjson = makeJSON(initialjson,loadedjson,el + '_folioStart',"none");
      initialjson = makeJSON(initialjson,loadedjson,el + '_margins',["0pt","0pt","0pt","0pt"]);
      initialjson = makeJSON(initialjson,loadedjson,el + '_outsideMargin',"0pt");
      initialjson = makeJSON(initialjson,loadedjson,el + '_insideMargin',"0pt");
      initialjson = makeJSON(initialjson,loadedjson,el + '_padding',["0pt","0pt","0pt","0pt"]);
      initialjson = makeJSON(initialjson,loadedjson,el + '_outsidePadding',"0pt");
      initialjson = makeJSON(initialjson,loadedjson,el + '_insidePadding',"0pt");
      initialjson = makeJSON(initialjson,loadedjson,el + '_subSelectors',{});
      // processing wrappers
    } else if (el.match(/^hwpr/g) || el.match(/^hblk/g)) {
      initialjson = makeJSON(initialjson,loadedjson,el + '_font',"inherit");
      initialjson = makeJSON(initialjson,loadedjson,el + '_fontSize',"inherit");
      initialjson = makeJSON(initialjson,loadedjson,el + '_fontWeight',"normal");
      initialjson = makeJSON(initialjson,loadedjson,el + '_fontStyle',"normal");
      initialjson = makeJSON(initialjson,loadedjson,el + '_textColor',"#000000");
      initialjson = makeJSON(initialjson,loadedjson,el + '_changeCase',"none");
      initialjson = makeJSON(initialjson,loadedjson,el + '_textIndent',"14pt");
      initialjson = makeJSON(initialjson,loadedjson,el + '_lineHeight',"inherit");
      initialjson = makeJSON(initialjson,loadedjson,el + '_letterSpacing',"normal");
      initialjson = makeJSON(initialjson,loadedjson,el + '_textAlign',"justify");
      initialjson = makeJSON(initialjson,loadedjson,el + '_textAlignLast',"left");
      initialjson = makeJSON(initialjson,loadedjson,el + '_floatBottom',"none");
      initialjson = makeJSON(initialjson,loadedjson,el + '_allowHyphens',"manual");
      initialjson = makeJSON(initialjson,loadedjson,el + '_preserveWS',"initial");
      initialjson = makeJSON(initialjson,loadedjson,el + '_OTfontVariant',"normal");
      initialjson = makeJSON(initialjson,loadedjson,el + '_backgroundColor',"transparent");
      initialjson = makeJSON(initialjson,loadedjson,el + '_backgroundImage',"none");
      initialjson = makeJSON(initialjson,loadedjson,el + '_backgroundRepeat',"no-repeat");
      initialjson = makeJSON(initialjson,loadedjson,el + '_backgroundWidth',"auto");
      initialjson = makeJSON(initialjson,loadedjson,el + '_backgroundHeight',"auto");
      initialjson = makeJSON(initialjson,loadedjson,el + '_backgroundPositionX',"left");
      initialjson = makeJSON(initialjson,loadedjson,el + '_backgroundPositionY',"top");
      initialjson = makeJSON(initialjson,loadedjson,el + '_center',"false");
      initialjson = makeJSON(initialjson,loadedjson,el + '_margins',["0pt","0pt","0pt","0pt"]);
      initialjson = makeJSON(initialjson,loadedjson,el + '_outsideMargin',"0pt");
      initialjson = makeJSON(initialjson,loadedjson,el + '_insideMargin',"0pt");
      initialjson = makeJSON(initialjson,loadedjson,el + '_padding',["0pt","0pt","0pt","0pt"]);
      initialjson = makeJSON(initialjson,loadedjson,el + '_underlineType',"none");
      initialjson = makeJSON(initialjson,loadedjson,el + '_underlineColor',"initial");
      initialjson = makeJSON(initialjson,loadedjson,el + '_underlineStyle',"initial");
      initialjson = makeJSON(initialjson,loadedjson,el + '_borderTop',["0pt","solid","#000000"]);
      initialjson = makeJSON(initialjson,loadedjson,el + '_borderRight',["0pt","solid","#000000"]);
      initialjson = makeJSON(initialjson,loadedjson,el + '_borderBottom',["0pt","solid","#000000"]);
      initialjson = makeJSON(initialjson,loadedjson,el + '_borderLeft',["0pt","solid","#000000"]);
      initialjson = makeJSON(initialjson,loadedjson,el + '_borderRadius',["0pt","0pt","0pt","0pt"]);
      initialjson = makeJSON(initialjson,loadedjson,el + '_avoidBreaks',"auto");
      initialjson = makeJSON(initialjson,loadedjson,el + '_width',"auto");
      initialjson = makeJSON(initialjson,loadedjson,el + '_height',"auto");
      initialjson = makeJSON(initialjson,loadedjson,el + '_display',"block");
      initialjson = makeJSON(initialjson,loadedjson,el + '_position',"relative");
      initialjson = makeJSON(initialjson,loadedjson,el + '_marginExtent',"0pt");
      initialjson = makeJSON(initialjson,loadedjson,el + '_float',"none");
      initialjson = makeJSON(initialjson,loadedjson,el + '_stringSet',"none");
      initialjson = makeJSON(initialjson,loadedjson,el + '_before_content',[]);
      initialjson = makeJSON(initialjson,loadedjson,el + '_beforeFontFamily',"inherit");
      initialjson = makeJSON(initialjson,loadedjson,el + '_beforeFontSize',"inherit");
      initialjson = makeJSON(initialjson,loadedjson,el + '_after_content',[]);
      initialjson = makeJSON(initialjson,loadedjson,el + '_afterFontFamily',"inherit");
      initialjson = makeJSON(initialjson,loadedjson,el + '_afterFontSize',"inherit");
      initialjson = makeJSON(initialjson,loadedjson,el + '_dropcap',"false");
      initialjson = makeJSON(initialjson,loadedjson,el + '_dropcapFont',"inherit");
      initialjson = makeJSON(initialjson,loadedjson,el + '_dropcapSize',"inherit");
      initialjson = makeJSON(initialjson,loadedjson,el + '_dropcapLineHeight',"inherit");
      initialjson = makeJSON(initialjson,loadedjson,el + '_dropcapWeight',"normal");
      initialjson = makeJSON(initialjson,loadedjson,el + '_dropcapStyle',"normal");
      initialjson = makeJSON(initialjson,loadedjson,el + '_dropcapPadding',["0pt","0pt","0pt","0pt"]);
      initialjson = makeJSON(initialjson,loadedjson,el + '_openType',["normal"]);
      // if (el.match(/^hwpr/g)) {
      //   initialjson = makeJSON(initialjson,loadedjson,el + '_columnCount',"1");
      //   initialjson = makeJSON(initialjson,loadedjson,el + '_columnWidth',"auto");
      //   initialjson = makeJSON(initialjson,loadedjson,el + '_columnGap',"normal");
      //   initialjson = makeJSON(initialjson,loadedjson,el + '_columnDivider',"none");
      //   initialjson = makeJSON(initialjson,loadedjson,el + '_columnDividerWidth',"0pt");
      //   initialjson = makeJSON(initialjson,loadedjson,el + '_columnDividerColor',"#000000");
      //   initialjson = makeJSON(initialjson,loadedjson,el + '_columnSpan',"all");
      // }
      initialjson = makeJSON(initialjson,loadedjson,el + '_importance',"false");
      initialjson = makeJSON(initialjson,loadedjson,el + '_subSelectors',{});
      if (liststyles.indexOf(el) >= 0) {
        initialjson = makeJSON(initialjson,loadedjson,el + '_listStyleType','initial');
      }
    } else if (el.match(/^hspn/g) || el.match(/^hspan/g)) {
      initialjson = makeJSON(initialjson,loadedjson,el + '_font',"inherit");
      initialjson = makeJSON(initialjson,loadedjson,el + '_fontSize',"inherit");
      initialjson = makeJSON(initialjson,loadedjson,el + '_fontWeight',"inherit");
      initialjson = makeJSON(initialjson,loadedjson,el + '_fontStyle',"inherit");
      initialjson = makeJSON(initialjson,loadedjson,el + '_textColor',"#000000");
      initialjson = makeJSON(initialjson,loadedjson,el + '_changeCase',"none");
      initialjson = makeJSON(initialjson,loadedjson,el + '_lineHeight',"6pt");
      initialjson = makeJSON(initialjson,loadedjson,el + '_letterSpacing',"normal");
      initialjson = makeJSON(initialjson,loadedjson,el + '_verticalAlign',"baseline");
      initialjson = makeJSON(initialjson,loadedjson,el + '_baselineShift',"auto");
      initialjson = makeJSON(initialjson,loadedjson,el + '_allowHyphens',"manual");
      initialjson = makeJSON(initialjson,loadedjson,el + '_OTfontVariant',"normal");
      initialjson = makeJSON(initialjson,loadedjson,el + '_underlineType',"none");
      initialjson = makeJSON(initialjson,loadedjson,el + '_underlineColor',"initial");
      initialjson = makeJSON(initialjson,loadedjson,el + '_underlineStyle',"initial");
      initialjson = makeJSON(initialjson,loadedjson,el + '_margins',["0pt","0pt","0pt","0pt"]);
      initialjson = makeJSON(initialjson,loadedjson,el + '_padding',["0pt","0pt","0pt","0pt"]);
      initialjson = makeJSON(initialjson,loadedjson,el + '_backgroundColor',"transparent");
      initialjson = makeJSON(initialjson,loadedjson,el + '_backgroundImage',"none");
      initialjson = makeJSON(initialjson,loadedjson,el + '_backgroundRepeat',"no-repeat");
      initialjson = makeJSON(initialjson,loadedjson,el + '_backgroundWidth',"auto");
      initialjson = makeJSON(initialjson,loadedjson,el + '_backgroundHeight',"auto");
      initialjson = makeJSON(initialjson,loadedjson,el + '_backgroundPositionX',"left");
      initialjson = makeJSON(initialjson,loadedjson,el + '_backgroundPositionY',"top");
      initialjson = makeJSON(initialjson,loadedjson,el + '_width',"auto");
      initialjson = makeJSON(initialjson,loadedjson,el + '_height',"auto");
      initialjson = makeJSON(initialjson,loadedjson,el + '_display',"inline");
      initialjson = makeJSON(initialjson,loadedjson,el + '_float',"none");
      initialjson = makeJSON(initialjson,loadedjson,el + '_preserveWS',"initial");
      initialjson = makeJSON(initialjson,loadedjson,el + '_before_content',[]);
      if (el != "hspannotecall") {
        initialjson = makeJSON(initialjson,loadedjson,el + '_after_content',[]);
      }
      initialjson = makeJSON(initialjson,loadedjson,el + '_openType',["normal"]);
      initialjson = makeJSON(initialjson,loadedjson,el + '_importance',"false");
      initialjson = makeJSON(initialjson,loadedjson,el + '_subSelectors',{});
    }
  });
  typelist.forEach(function(el) {
    el = el.replace(/-/g,"");
    if (el.match(/^ID_/g) || el.match(/^PREV_/g) || el.match(/^CHILD_/g) || el.match(/^PARENT_/g) || el.match(/^FIRSTOFTYPE/g)) {
      var mypar = el.split("_")[2];
      var parobj = initialjson[mypar + '_subSelectors'];
      var myloaded = {};
      if (parobj && parobj.hasOwnProperty(el)) {
        myloaded = parobj[el];
      } else {
        if (!parobj) {
          initialjson[mypar + '_subSelectors'] = {};
          parobj = initialjson[mypar + '_subSelectors'];
        }
        parobj[el] = {};
        myloaded = undefined;
      }
      if (mypar.match(/^hblk/g) || mypar.match(/^hwpr/g)) {
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_subSelectors',{},true);
      } else if (mypar.match(/^hblk/g) || mypar.match(/^hwpr/g)) {
      // if (mypar.match(/^hblk/g) || mypar.match(/^hwpr/g)) {
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_font',"inherit",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_fontSize',"inherit",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_fontWeight',"normal",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_fontStyle',"normal",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_textColor',"#000000",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_changeCase',"none",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_lineHeight',"inherit",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_letterSpacing',"normal",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_floatBottom',"none",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_allowHyphens',"manual",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_preserveWS',"initial",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_textAlign',"justify",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_textAlignLast',"left",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_textIndent',"14pt",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_OTfontVariant',"normal",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_backgroundColor',"transparent",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_backgroundImage',"none",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_backgroundRepeat',"no-repeat",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_backgroundWidth',"auto",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_backgroundHeight',"auto",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_backgroundPositionX',"left",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_backgroundPositionY',"top",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_center','false',true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_margins',["0pt","0pt","0pt","0pt"],true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_outsideMargin',"0pt", true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_insideMargin',"0pt", true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_padding',["0pt","0pt","0pt","0pt"],true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_underlineType',"none",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_underlineColor',"initial",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_underlineStyle',"initial",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_borderTop',["0pt","solid","#000000"],true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_borderRight',["0pt","solid","#000000"],true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_borderBottom',["0pt","solid","#000000"],true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_borderLeft',["0pt","solid","#000000"],true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_borderRadius',["0pt","0pt","0pt","0pt"],true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_avoidBreaks',"auto",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_width',"auto",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_height',"auto",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_display',"block",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_position',"relative",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_marginExtent',"0pt",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_float',"none",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_stringSet',"none",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_before_content',[],true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_beforeFontFamily',"inherit",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_beforeFontSize',"inherit",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_after_content',[],true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_beforeFontFamily',"inherit",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_beforeFontSize',"inherit",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_dropcap','false',true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_dropcapFont',"inherit",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_dropcapSize',"inherit",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_dropcapLineHeight',"inherit",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_dropcapWeight',"normal",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_dropcapStyle',"normal",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_dropcapPadding',["0pt","0pt","0pt","0pt"],true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_subSelectors',{},true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_openType',["normal"],true);
        // if (mypar.match(/^hwpr/g)) {
        //   parobj[el] = makeJSON(parobj[el],myloaded,el + '_columnCount',"1",true);
        //   parobj[el] = makeJSON(parobj[el],myloaded,el + '_columnWidth',"auto",true);
        //   parobj[el] = makeJSON(parobj[el],myloaded,el + '_columnGap',"normal",true);
        //   parobj[el] = makeJSON(parobj[el],myloaded,el + '_columnDivider',"none",true);
        //   parobj[el] = makeJSON(parobj[el],myloaded,el + '_columnDividerWidth',"0pt",true);
        //   parobj[el] = makeJSON(parobj[el],myloaded,el + '_columnDividerColor',"#000000",true);
        //   parobj[el] = makeJSON(parobj[el],myloaded,el + '_columnSpan',"all",true);
        // }
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_importance','false',true);
        if (liststyles.indexOf(mypar) >= 0) {
          parobj[el] = makeJSON(parobj[el],myloaded,el + '_listStyleType','initial',true);
        }
      } else if (mypar.match(/^hspn/g) || mypar.match(/^hspan/g)) {
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_font',"inherit",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_fontSize',"inherit",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_fontWeight',"inherit",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_fontStyle',"inherit",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_textColor',"#000000",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_changeCase',"none",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_lineHeight',"6pt",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_letterSpacing',"normal",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_verticalAlign',"baseline",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_baselineShift',"auto",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_allowHyphens',"manual",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_OTfontVariant',"normal",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_underlineType',"none",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_underlineColor',"initial",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_underlineStyle',"initial",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_margins',["0pt","0pt","0pt","0pt"],true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_padding',["0pt","0pt","0pt","0pt"],true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_backgroundColor',"transparent",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_backgroundImage',"none",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_backgroundRepeat',"no-repeat",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_backgroundWidth',"auto",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_backgroundHeight',"auto",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_backgroundPositionX',"left",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_backgroundPositionY',"top",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_width',"auto",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_height',"auto",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_display',"inline",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_float',"none",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_before_content',[],true);
        if (mypar != "hspannotecall") {
          parobj[el] = makeJSON(parobj[el],myloaded,el + '_after_content',[],true);
        }
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_preserveWS',"initial",true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_openType',["normal"],true);
        parobj[el] = makeJSON(parobj[el],myloaded,el + '_importance','false',true);
      }
    }
  });
  return [initialjson,typelist];
}
