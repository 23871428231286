import React, { useState, useEffect, useCallback } from "react";
import ReactDOM from "react-dom";
// prettier-ignore
import { Pane, Paragraph, Text, Heading, Button, TextInputField, TabNavigation, Tab, Link, toaster, IconButton, Icon, TextInput, Select, Checkbox, Tooltip, Combobox, SegmentedControl, InfoSignIcon, AddIcon, DeleteIcon } from "evergreen-ui";
import { NumericalUnitsInputInner } from "./tb_inputs.js";

import { firebase, db, storage } from "../../fire.js";
import { parse_dim } from "../../helpers/util.js";
import { get_watermark_settings, validate_watermark_size } from "../../../api/app_shared/process/watermark.js";

import {
  CloudImage,
  Loader,
  ImageList,
  IngestMessages,
} from "../shared/common.js";

export default class ConfigureExportSettings extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }
  componentDidMount() {
    // console.log(this.props);
  }
  onChange(change_data) {
    // console.log("onChange", change_data);
    let { value, name, pos } = change_data;
    // console.log(this.props);
    let { export_settings_style } = this.props;
    if (name === "page") {
      export_settings_style["page"][pos] = value;
    } else if (name === "margin_verso") {
      export_settings_style["margins"]["verso"][pos] = value;
    } else if (name === "margin_recto") {
      export_settings_style["margins"]["recto"][pos] = value;
    } else if (name === "bleed_verso") {
      export_settings_style["bleed"]["verso"][pos] = value;
    } else if (name === "bleed_recto") {
      export_settings_style["bleed"]["recto"][pos] = value;
    } else if (name === "marks") {
      export_settings_style["marks"] = value;
    } else if (name === "grayscale_images") {
      export_settings_style["grayscale_images"] = value;
    } else if (name === "post_process_cmyk_gray") {
      export_settings_style["post_process_cmyk_gray"] = value;
    } else if (name === "show_gridlines") {
      export_settings_style["show_gridlines"] = value;
    } else if (name === "flashing") {
      export_settings_style["flashing"] = value;
    } else if (name === "motionSimulation") {
      export_settings_style["motionSimulation"] = value;
    } else if (name === "sound") {
      export_settings_style["sound"] = value;
    } else if (name === "strip_epub_ws") {
      export_settings_style["strip_epub_ws"] = value;
    } else if (name === "centered") {
      export_settings_style["centered"] = value;
    } else if (name === "ebook_rights") {
      export_settings_style["ebook_rights"] = value;
    } else if (name === "text_watermark") {
      export_settings_style["text_watermark"] = value;
    } else if (name === "image_watermark") {
      export_settings_style["image_watermark"] = value;
    } else if (name === "text_watermark_align") {
      export_settings_style["text_watermark_align"] = value;
    } else if (name === "text_watermark_header") {
      export_settings_style["text_watermark_header"] = value;
    } else if (name === "text_watermark_footer") {
      export_settings_style["text_watermark_footer"] = value;
    } else if (name === "watermark_font_size") {
      export_settings_style["watermark_font_size"] = value;
    } else if (name === "subset") {
      export_settings_style["subset"] = value;
    } else if (name === "selected_chapters") {
      export_settings_style["selected_chapters"] = value;
    }
    this.props.onChange({ export_settings_style });
  }
  render() {
    let [width, height] = this.props.export_settings_style.page;
    let { verso, recto } = this.props.export_settings_style.margins;
    let bleed = this.props.export_settings_style.bleed;
    let rights = this.props.export_settings_style.ebook_rights;
    let { nontypeset_mode, images } = this.props.project;
    // console.log(this.props.export_settings_style.marks);
    return (
      <Pane padding={8} marginTop={8}>
        {this.props.section_list && this.props.section_list.length && (
          <Checkbox
            label="Only export certain sections..."
            height={30}
            checked={
              this.props.export_settings_style.subset == true
            }
            onChange={e => {
              let value = e.target.checked ? true : false;
              this.onChange({ value, name: "subset" });
            }}
          />
        )}
        {this.props.export_settings_style.subset && this.props.section_list && this.props.section_list.length && (
          <SectionPicker 
            section_list={this.props.section_list} 
            selected={this.props.export_settings_style.selected_chapters}
            updateSettings={this.onChange} 
            keyPrefix="export"
          />
        )}
        <Heading>PDF Export Settings</Heading>
        <Pane
          marginTop={16}
          display="flex"
          flexDirection="row"
          justifyContent="left"
        >
          <Pane width={250} padding={8}>
            {!this.props.is_advanced && (
              <Heading marginBottom={8}>Trim Size</Heading>
            )}
            {!this.props.is_advanced && (
              <Dimension
                label="width"
                name="page"
                pos={0}
                onChange={this.onChange}
                value={width}
              />
            )}
            {!this.props.is_advanced && (
              <Dimension
                label="height"
                pos={1}
                name="page"
                onChange={this.onChange}
                value={height}
              />
            )}
            <Checkbox
              label="Grayscale Images"
              height={30}
              checked={
                this.props.export_settings_style.grayscale_images == true
              }
              onChange={e => {
                let value = e.target.checked ? true : false;
                this.onChange({ value, name: "grayscale_images" });
              }}
            />
            <Checkbox
              label="Export PDF for 1-color (black/gray) printing"
              height={30}
              checked={
                this.props.export_settings_style.post_process_cmyk_gray == true
              }
              onChange={e => {
                let value = e.target.checked ? true : false;
                this.onChange({ value, name: "post_process_cmyk_gray" });
              }}
            />
            <Checkbox
              label="Show Gridlines"
              height={30}
              checked={this.props.export_settings_style.show_gridlines == true}
              onChange={e => {
                let value = e.target.checked ? true : false;
                this.onChange({ value, name: "show_gridlines" });
              }}
            />
            <Checkbox
              label="Trim Marks"
              height={30}
              checked={this.props.export_settings_style.marks === "crop"}
              onChange={e => {
                let value = e.target.checked ? "crop" : "none";
                this.onChange({ value, name: "marks" });
              }}
            />
          </Pane>
          {!this.props.is_advanced && (
            <Pane display="flex" flexDirection="row">
              <PageMarginsInput
                label="verso"
                onChange={this.onChange}
                values={verso}
              />
              <PageMarginsInput
                label="recto"
                onChange={this.onChange}
                values={recto}
              />
            </Pane>
          )}
        </Pane>
        <Pane>
          <BleedInput
            label="verso"
            onChange={this.onChange}
            values={bleed["verso"]}
          />
        </Pane>
        <Pane>
          <BleedInput
            label="recto"
            onChange={this.onChange}
            values={bleed["recto"]}
          />
        </Pane>
        <Heading marginTop={32}>PDF Watermark</Heading>
        <WatermarkInput
          onChange={this.onChange}
          export_settings_style={this.props.export_settings_style}
          nontypeset_mode={nontypeset_mode}
          images={images}
        />
        <Heading marginTop={32}>EPUB Export Settings</Heading>
        <Pane
          marginTop={16}
          display="flex"
          flexDirection="column"
          justifyContent="left"
        >
          <Pane width={350} padding={8}>
            <Pane
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              padding={0}
            >
              <Checkbox
                label="Delete blank paragraphs"
                height={30}
                margin={0}
                marginRight={8}
                checked={this.props.export_settings_style.strip_epub_ws == true}
                onChange={e => {
                  let value = e.target.checked ? true : false;
                  this.onChange({ value, name: "strip_epub_ws" });
                }}
              />
            </Pane>
            <Pane
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              padding={0}
            >
              <Checkbox
                label="Book contains flashing images?"
                height={30}
                margin={0}
                marginRight={8}
                checked={this.props.export_settings_style.flashing == true}
                onChange={e => {
                  let value = e.target.checked ? true : false;
                  this.onChange({ value, name: "flashing" });
                }}
              />
              <Tooltip
                content="Flashes can cause seizures in some people. If your readers have adverse
                reactions to flashes, this will let them know to be cautious while reading. Unless
                your book contains animated gifs or video, you can probably leave this unchecked."
              >
                <Icon icon={InfoSignIcon} size={16} />
              </Tooltip>
            </Pane>
            <Pane
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              padding={0}
            >
              <Checkbox
                label="Book contains graphics that simulate motion?"
                height={30}
                margin={0}
                marginRight={8}
                checked={
                  this.props.export_settings_style.motionSimulation == true
                }
                onChange={e => {
                  let value = e.target.checked ? true : false;
                  this.onChange({ value, name: "motionSimulation" });
                }}
              />
              <Tooltip
                content="If your readers have adverse reactions to motion simulation, this will let
                them know to be cautious while reading. Unless your book contains animated gifs or video,
                you can probably leave this unchecked."
              >
                <Icon icon={InfoSignIcon} size={16} />
              </Tooltip>
            </Pane>
            <Pane
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              padding={0}
            >
              <Checkbox
                label="Book contains sound or audio clips?"
                height={30}
                margin={0}
                marginRight={8}
                checked={this.props.export_settings_style.sound == true}
                onChange={e => {
                  let value = e.target.checked ? true : false;
                  this.onChange({ value, name: "sound" });
                }}
              />
              <Tooltip
                content="Some readers can have adverse reactions to sound, or may be hearing-impaired.
                This will let them know if your book contains content that is inaccessible to them.
                Unless your book contains video or audio clips, you can probably leave this unchecked."
              >
                <Icon icon={InfoSignIcon} size={16} />
              </Tooltip>
            </Pane>
          </Pane>
          <Pane
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            padding={0}
            width="100%"
          >
            <TextInputField
              label="Ebook Rights"
              description="This will be used in the dc:rights field in your EPUB file."
              name="ebook_rights"
              key="ebook_rights"
              width="100%"
              placeholder={`E.g.: Copyright © ${new Date().getFullYear()} ${this.props.project.author}`}
              value={rights ? rights : ""}
              onChange={e => {
                this.onChange({ value: e.target.value, name: e.target.name });
              }}
            />
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

// text watermark in margin: alignment, top/bottom, font size (minimum 6pt for galleys)
// image watermark behind text
export function WatermarkInput(props) {
  let { onChange, images, export_settings_style } = props;
  let img_names = ["none"];
  images.map(img => {
    img_names.push(img.split("/").pop());
  })

  let {
    has_text_watermark, 
    has_image_watermark, 
    default_include_in_header, 
    default_text, 
    watermark_font_size
  } = get_watermark_settings(export_settings_style, props.nontypeset_mode);

  return (
    <Pane margin={16}>
      {props.nontypeset_mode === "galley" ? (
        <Text>
          You must include at least one watermark on your PDF pages.
        </Text>
      ) : (
        ``
      )}
      <Pane marginTop={16}>
        <Heading size={400}>Image watermark:</Heading>
        <Text>This will appear behind the body text on every page.</Text>
        <Combobox
          label="Image watermark"
          description="This will appear in the running header and/or footer on every page."
          items={img_names || []}
          onChange={selected => onChange({ value: selected, name: "image_watermark" })}
          selectedItem={export_settings_style.image_watermark ? export_settings_style.image_watermark : "none"}
        />
      </Pane>
      <Pane marginTop={16}>
        <Heading size={400}>Text watermark:</Heading>
        <Text>This will appear in the running header and/or footer on every page.</Text>
        <TextInputField
          marginTop={16}
          label="Text:"
          name="text_watermark"
          key="text_watermark"
          width="100%"
          placeholder={`E.g.: Advanced Copy For Review Only`}
          value={export_settings_style.text_watermark ? export_settings_style.text_watermark : default_text}
          onChange={e => {
            onChange({ value: e.target.value, name: e.target.name });
          }}
        />
        <Checkbox
          label="Include in header"
          height={30}
          margin={0}
          marginRight={8}
          checked={default_include_in_header}
          onChange={e => {
            let value = e.target.checked ? true : false;
            onChange({ value, name: "text_watermark_header" });
          }}
        />
        <Checkbox
          label="Include in footer"
          height={30}
          margin={0}
          marginRight={8}
          checked={
            export_settings_style.text_watermark_footer == true
          }
          onChange={e => {
            let value = e.target.checked ? true : false;
            onChange({ value, name: "text_watermark_footer" });
          }}
        />
        <Pane>
          <Text>Font size:</Text>
          <NumericalUnitsInputInner
            name="watermark_font_size" 
            value={watermark_font_size}
            onChange={onChange}
            def={"6pt"}
          />
        </Pane>
        <Pane marginTop={16}>
          <Text>Text alignment:</Text>
          <SegmentedControl
            marginTop={8}
            width={240}
            options={
              [
                {label: 'Inside', value: 'inside'},
                {label: 'Center', value: 'center'},
                {label: 'Outside', value: 'outside'}]
            }
            value={export_settings_style.text_watermark_align ? export_settings_style.text_watermark_align : "center"}
            onChange={value => onChange({ value, name: "text_watermark_align" })}
          />
        </Pane>
      </Pane>
    </Pane>
  );
}

function ImageSelect(props) {
  let { value, images, onChange } = props;
  
  return (
    <Pane marginTop={8} display="flex" flexDirection="row" alignItems="center">
      <Pane width={150} display="flex" marginRight={8}>
        <Text color="muted" textTransform="capitalize">
          {label}
        </Text>
      </Pane>

      
    </Pane>
  );
}

// XXX this is also used in the template builder !
export function PageMarginsInput(props) {
  let { label, onChange, values } = props;
  return (
    <Pane margin={16} className={props.myClass ? props.myClass : "standard"}>
      <Heading 
        size={400} 
        textAlign="center" 
        textTransform="capitalize" 
        marginBottom={props.is_block ? 8 : 0}
      >
        <Text color="#ffffff">
          {label}
        </Text>
      </Heading>
      <Pane display="flex" alignItems="center" justifyContent="center">
        {/* top margin */}
        <Dimension
          pos={0}
          name={`margin_${label}`}
          onChange={onChange}
          value={values[0]}
        />
      </Pane>
      <Pane display="flex" alignItems="center" justifyContent="center">
        {/* left */}
        <Dimension
          pos={3}
          name={`margin_${label}`}
          onChange={onChange}
          value={values[3]}
        />
        {/* the preview page */}
        {props.is_block ? FakeBlock(props) : fake_page}
        <Dimension
          pos={1}
          name={`margin_${label}`}
          onChange={onChange}
          value={values[1]}
        />
      </Pane>
      <Pane display="flex" alignItems="center" justifyContent="center">
        <Dimension
          pos={2}
          name={`margin_${label}`}
          onChange={onChange}
          value={values[2]}
        />
      </Pane>
    </Pane>
  );
}

export function BleedInput(props) {
  let { label, onChange, values } = props;
  return (
    <Pane margin={16}>
      <Heading size={400} textAlign="left" textTransform="capitalize">
        {label} Bleed
      </Heading>
      <Pane display="flex" alignItems="left">
        <Pane display="flex" alignItems="left" marginRight={8}>
          <Dimension
            label="Top:"
            pos={0}
            name={`bleed_${label}`}
            onChange={onChange}
            value={values[0]}
          />
        </Pane>
        <Pane display="flex" alignItems="left" marginRight={8}>
          <Dimension
            label="Right:"
            pos={1}
            name={`bleed_${label}`}
            onChange={onChange}
            value={values[1]}
            marginRight={8}
          />
        </Pane>
        <Pane display="flex" alignItems="left" marginRight={8}>
          <Dimension
            label="Bottom:"
            pos={2}
            name={`bleed_${label}`}
            onChange={onChange}
            value={values[2]}
            marginRight={8}
          />
        </Pane>
        <Pane display="flex" alignItems="left" marginRight={8}>
          <Dimension
            label="Left:"
            pos={3}
            name={`bleed_${label}`}
            onChange={onChange}
            value={values[3]}
            marginRight={8}
          />
        </Pane>
      </Pane>
    </Pane>
  );
}

const fake_page = (
  <Pane
    position="relative"
    width={200}
    height={300}
    background="greenTint"
    border
    flexShrink={0}
    margin={2}
  >
    <div style={{ borderTop: "1px solid orange", marginTop: "20px" }} />
    <div style={{ borderBottom: "1px solid orange", marginTop: "260px" }} />
    <div
      style={{
        position: "absolute",
        borderLeft: "1px solid orange",
        height: "300px",
        top: "0px",
        left: "20px",
      }}
    />
    <div
      style={{
        position: "absolute",
        borderRight: "1px solid orange",
        height: "300px",
        top: "0px",
        right: "20px",
      }}
    />
  </Pane>
);

function FakeBlock(props) {
  let blockType = props.blockType ? props.blockType : "margins";
  let marginColor = blockType && blockType == "margins" ? "#07C896" : "#E8E7EE";
  let paddingColor = blockType && blockType == "padding" ? "#07C896" : "#E8E7EE";
  return (
    <Pane
      position="relative"
      width={200}
      height={100}
      flexShrink={0}
      margin={2}
      boxSizing="border-box"
    >
      <div style={{ border: "1px solid #5D597A", backgroundColor: marginColor, padding: "10px", height: "100px", boxSizing: "border-box" }}>
        <div style={{ border: "2px solid black", backgroundColor: paddingColor, padding: "10px", height: "78px", boxSizing: "border-box" }}>
          <div style={{ backgroundColor: "#E8E7EE", height: "54px", boxSizing: "border-box" }}>
            <div style={{ borderBottom: "1px solid black", width: "100%", marginBottom: "10px" }} />
            <div style={{ borderBottom: "1px solid black", width: "90%", marginBottom: "10px" }} />
            <div style={{ borderBottom: "1px solid black", width: "95%", marginBottom: "10px" }} />
            <div style={{ borderBottom: "1px solid black", width: "97%", marginBottom: "10px" }} />
            <div style={{ borderBottom: "1px solid black", width: "75%" }} />
          </div>
        </div>
      </div>
    </Pane>
  );
}

const fake_block = (
  <Pane
    position="relative"
    width={200}
    height={100}
    background="greenTint"
    border
    flexShrink={0}
    margin={2}
  >
    <div style={{ borderTop: "1px solid orange", marginTop: "20px" }} />
    <div style={{ borderBottom: "1px solid orange", marginTop: "60px" }} />
    <div
      style={{
        position: "absolute",
        borderLeft: "1px solid orange",
        height: "100px",
        top: "0px",
        left: "20px",
      }}
    />
    <div
      style={{
        position: "absolute",
        borderRight: "1px solid orange",
        height: "100px",
        top: "0px",
        right: "20px",
      }}
    />
  </Pane>
);

function Dimension(props) {
  let possible_units = ["pt", "in", "cm", "mm", "px", "auto"];
  let { value, onChange, pos, name, label } = props;
  name = name || label;
  let { numeric_value, unit } = parse_dim(value);
  // console.log(props.name);
  // console.log(name);
  return (
    <Pane textAlign={pos == 3 ? "right" : "left"}>
      {label ? (
        <Pane width={50} display="inline-block" textTransform="capitalize">
          <Text>{label}</Text>
        </Pane>
      ) : (
        ``
      )}
      <TextInput
        name={`${name}_${pos}`}
        key={`${name}_${pos}`}
        placeholder="0"
        type="number"
        width={"70px"}
        value={unit === "auto" ? 0 : numeric_value}
        onChange={(e) => {
          console.log(e.target.value);
          console.log(unit);
          let val = `${e.target.value || 0}${unit}`;
          if (unit === "auto") {
            val = "auto";
          }
          console.log(val);
          onChange({ value: val, pos, name })
        }}
      />
      <Select
        value={unit}
        key={`${name}_${pos}_u`}
        name={`${name}_${pos}_u`}
        onChange={(event) => {
          console.log(event.target.value);
          console.log(unit);
          let val = `${numeric_value}${event.target.value}`;
          if (event.target.value === "auto") {
            val = "auto";
          }
          console.log(val);
          onChange({
            value: val,
            pos,
            name,
          })
        }}
      >
        {possible_units.map((u, i) => {
          if (unit === u) {
            return (
              <option value={u} key={i} checked>
                {u}
              </option>
            );
          } else {
            return (
              <option value={u} key={i}>
                {u}
              </option>
            );
          }
        })}
      </Select>
    </Pane>
  );
}

function SectionPicker(props) {
  const [newitem, setNewitem] = useState(false);
  let sections = props.section_list.map((item, idx) => {
    return {label: item.title, index: idx, id: item.id, key: `export-${idx}`};
  });
  // add new or update existing
  const addOrUpdateSelection = (item, index) => {
    // append to the current array, then set new value as state
    let updated = props.selected ? [].concat(props.selected) : [];
    if ((index || index === 0) && props.selected && props.selected.length >= (index+1)) {
      updated[index] = item;
    } else {
      updated.push(item);
    }
    props.updateSettings({ value: updated, name: "selected_chapters" });
    setNewitem(false);
  }
  // delete existing
  const deleteSelection = (item, index) => {
    let updated = props.selected ? [].concat(props.selected) : [];
    if (index) {
      updated.splice(index, 1);
      props.updateSettings({ value: updated, name: "selected_chapters" });
    } else if (index === 0) {
      updated.shift();
      props.updateSettings({ value: updated, name: "selected_chapters" });
    }
    setNewitem(false);
  }
  return (
    <Pane marginBottom={16}>
      {props.selected && props.selected.length > 0 ? (
        <SelectedSections sections={sections} selected={props.selected} addOrUpdateSelection={addOrUpdateSelection} deleteSelection={deleteSelection} />
      ) : (
        <SectionItem item={false} sections={sections} index={0} addOrUpdateSelection={addOrUpdateSelection} deleteSelection={deleteSelection} />
      )}
      {newitem && (
        <SectionItem item={false} sections={sections} index={false} addOrUpdateSelection={addOrUpdateSelection} deleteSelection={deleteSelection} />
      )}
      <Button iconBefore={AddIcon} onClick={() => setNewitem(true)}>Add another</Button>
    </Pane>
  );
}

function SelectedSections(props) {
  return (
    <Pane>
      {props.selected.map((item, idx) => {
        return <SectionItem key={`selected$idx`} item={item} sections={props.sections} index={idx} addOrUpdateSelection={props.addOrUpdateSelection} deleteSelection={props.deleteSelection} />;
      })}
    </Pane>
  );
}

function SectionItem(props) {
  let key = props.item ? `${props.item.index}${props.item.id}` : "newitem";
  console.log(key);
  return (
    <Pane display="flex" flexDirection="row" justifyContent="flex-start"  alignItems="flex-start" marginBottom={8}>
      <IconButton icon={DeleteIcon} onClick={() => props.deleteSelection(props.item, props.index)} marginRight={8} intent="danger" />
      <Combobox
        key={key}
        initialSelectedItem={props.item ? props.item : false}
        items={props.sections}
        itemToString={item => (item ? item.label : '')}
        onChange={selected => props.addOrUpdateSelection(selected, props.index)}
        placeholder="Choose a section..."
      />
    </Pane>
  );
}
