import React from "react";
import ReactDOM from "react-dom";
import { useState, useEffect, useRef } from "react";
import { firebase, db } from "../../fire.js";
import { FireHelpers } from "../../../api/app_shared/fire_helpers.js";

// prettier-ignore
import { Pane, Button, Paragraph, CornerDialog, Position } from "evergreen-ui";

const FH = new FireHelpers(db, firebase);

export default class Newsletter extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isShown: true };
    this.onNewsletterSignup = this.onNewsletterSignup.bind(this);
  }

  async onNewsletterSignup() {
    this.setState({ isShown: false });
    let newsletter_data = await FH.set_user_newsletter_status(this.props.uid, true);
    document.getElementById("mc-embedded-subscribe-form").submit();
  }

  async onNewsletterOptOut() {
    this.setState({ isShown: false });
    let newsletter_data = await FH.set_user_newsletter_status(this.props.uid, false);
  }

  render() {
    let hiddenStyle = {
      position: 'absolute', 
      left: '-5000px',
      display: 'block',
    };
    return (
      <Pane>
        <CornerDialog
          title="Stay in the know!"
          hasCancel={true}
          cancelLabel="No"
          onCancel={() => {
            this.onNewsletterOptOut();
          }}
          onConfirm={() => {
            this.onNewsletterSignup();
          }}
          confirmLabel="Yes"
          isShown={this.state.isShown}
          onCloseComplete={() => this.setState({ isShown: false })}
          position={Position.BOTTOM_LEFT}
        >
          Would you like to receive updates about new features and improvements?
        </CornerDialog>
        <form 
          action="https://hederis.us17.list-manage.com/subscribe/post?u=c5bc6d6d58d3a6ca885eea897&amp;id=5ba347e4f2" 
          method="post" 
          id="mc-embedded-subscribe-form" 
          name="mc-embedded-subscribe-form" 
          className="validate" 
          target="_blank" 
          noValidate
          style={hiddenStyle}
        >
          <div id="mc_embed_signup_scroll">
            <input type="email" name="EMAIL" className="email" id="mce-EMAIL" placeholder={this.props.email} value={this.props.email} required readOnly />
            <div aria-hidden="true">
              <input type="text" name="b_c5bc6d6d58d3a6ca885eea897_5ba347e4f2" tabIndex="-1" value="" readOnly />
            </div>
            <div>
              <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" readOnly />
            </div>
          </div>
        </form>
      </Pane>
    );
  }
}
