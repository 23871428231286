export function get_font_lookup(all_fonts_arr) {
  return function(name) {
    // special case
    if (name === "Inherit") {
      return "";
    }
    let res = [];
    if (name) {
      res = all_fonts_arr.filter(x => x["display-name"] == name.replace(/\s?\(?(USER FONT)?\)?\s?\(?[OT]*\)?$/,""));
    }
    if (res.length == 1) {
      return res[0]["url"];
    } else {
      if (name) {
        // console.log("there was a problem with font", name);
      }
      return false;
    }
  };
}
// console.log("---->", get_font_url("Minion Pro"));

// prettier-ignore
export function patch_fontnames_to_urls(original_advanced_config, get_font_url) {
  let advanced_config = {...original_advanced_config}
  advanced_config["body_bodyFontUrl"] =    get_font_url(   advanced_config["body_bodyFont"]  );
  advanced_config["body_headingFontUrl"] = get_font_url(   advanced_config["body_headingFont"]  );
  advanced_config["runhead_fontUrl"] =     get_font_url(   advanced_config["runhead_font"]  );
  advanced_config["runfoot_fontUrl"] =     get_font_url(   advanced_config["runfoot_font"]  );
  return advanced_config;
}

// prettier-ignore
export function patch_imgnames_to_urls(original_advanced_config, image_lookup) {
  // XXX this is a bad practice, but because of how many levels/recursion, easier to just do this for now
  let advanced_config = JSON.parse(JSON.stringify(original_advanced_config))
  // let advanced_config = {...original_advanced_config}
  // console.log('patch_imgnames_to_urls', original_advanced_config)
  for (let k in advanced_config) {
    if (
      k.match(/^\S*_backgroundImage$/) &&
      (typeof advanced_config[k] === 'string' || advanced_config[k] instanceof String) &&
      advanced_config[k] != "none"
    ) {
      if (advanced_config[k] in image_lookup) {
        let img_name = advanced_config[k];
        advanced_config[k] = image_lookup[img_name];
      } else {
        advanced_config[k] = "none";
      }
    } else if (k.match(/^\S*_subSelectors$/)) {
      // let sub_config = {...advanced_config[k]}
      for (let subk in advanced_config[k]) {
        // XXX this is what's causing the problem with fiebase image urls in subsels
        advanced_config[k][subk] = patch_imgnames_to_urls(advanced_config[k][subk], image_lookup);
      }
    }
  }
  return advanced_config;
}
