"use strict";

// https://regexr.com/492gd
// with a self closing slash:  /<img .*?src="(.*?)".*?\/>/g,
// below doesn't have the self closing slash
module.exports = function(html, lookup, placeholder_image_url = "") {
  return html.replace(
    /<img .*?src="(.*?)".*?>/g,
    (match, captured_src, offset) => {
      // console.log(match, captured_src, offset);
      // console.log(captured_src);
      // for now, don't bother trying to replace it if it's already been replaced with a url
      // prettier-ignore
      if (captured_src.startsWith("https://") || captured_src.startsWith("http://")){
        return match
      }
      let replacement_src = lookup[captured_src.toLowerCase().trim()];
      if (!replacement_src) {
        // console.log(captured_src, "NOT FOUND");
        replacement_src = placeholder_image_url;
      }
      // replacing it directly, scoped just to the src attribute
      return match.replace(' src="' + captured_src, ' src="' + replacement_src);
    }
  );
};