import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
// prettier-ignore
import { Combobox, Text, Icon } from "evergreen-ui";

import Foco from "react-foco";
// prettier-ignore
import { AttributePopoverInline } from './structure_attribute_editors.js'

const uuid = require("uuid/v4");
// prettier-ignore
import { SPAN_TYPE_ARR  } from "./structure_controls.js";

// NOTE: This group of imports come from files that
// are created automatically from the hederis core project;
// if you need to make any edits, please edit in that repo 
// and then redistribute as appropriate.
// BEGINGROUP--------
// prettier-ignore
import { content_to_el } from "./shared/util";
// ENDGROUP--------

// what we actually export
export function ImagePicker(props) {
  // prettier-ignore
  const { item, isSelected, operations, section_id, setSelection, images } = props;

  let img_names = ["none"];
  if (images) {
    images.map(img => {
      img_names.push(img.split("/").pop());
    });
  }

  const onDoUpdate = ({item, el, value}) => {
    let html = set_image_src(el, value);
    props.operations.elementReplace(section_id, item.id, html);
  };
  // console.log(section);
  // const initialAttrArr = get_attr_array_from_html(content);
  const el = content_to_el(item.content).children[0];
  let src = "";
  if (el) {
    src = el.getAttribute("src");
  }

  return (
    <Combobox
      id={`structure-editor-image-${item.id}`}
      items={img_names || []}
      onChange={selected => onDoUpdate({ item, el, value: selected })}
      selectedItem={src}
    />
  );
}

function set_image_src(el, value) {
  el.setAttribute("src", value);
  el.setAttribute("data-img-src", value);
  return el.outerHTML;
}

// prevent pasting of html
const onPaste = event => {
  event.preventDefault();
  // grab the text content even if the clipboard contains html
  var text = (event.originalEvent || event).clipboardData.getData("text/plain");
  // insert text where the cursor/selection is
  document.execCommand("insertHTML", false, text);
};
