// NOTE: Functions from this file are shared in other repos; 
// If you make updates here, be sure to run `npm run distro-shared` 
// which will distribute your changes as appropriate 
// (be sure to clone any missing repos as prompted).

// these are helpers for parsing a section from an already wrapped html file, i.e. it has <section>s
// into an array of nodes, serializing

function make_arr(arr) {
  return Array.prototype.slice.call(arr);
}

function all_nodes_from_sections(sections) {
  // flatten, assigning them an index so we can get sections back
  let reducer = (acc, cur, i) => {
    cur.nodes.map(n => {
      // console.log(n.nodeName);
      // write which section it's in directly to the node :/
      n["section_index"] = i;
      return n;
    });
    return acc.concat(cur["nodes"]);
  };
  let all_nodes = sections.reduce(reducer, []);
  // and give them an overall index for simplicty
  all_nodes.map((n, i) => {
    n["all_node_index"] = i;
  });
  return all_nodes;
}

function nest_inlines(frag) {
  try {
    let inlines = make_arr(
      frag.querySelectorAll("*[data-hederis-type^=hsec] > *[data-hederis-type^=hsp], *[data-hederis-type^=hwpr] > *[data-hederis-type^=hsp]")
    );
    for (var i = 0; i < inlines.length; ++i) {
      var span = inlines[i];
      var newp = document.createElement("p");
      newp.setAttribute("data-hederis-type", "hblkp");
      newp.setAttribute("class", "hblkp");
      newp.setAttribute("id", `pui${Math.random().toString(36).slice(2, 10)}`);
      span.parentNode.insertBefore(newp, span);
      newp.appendChild(span);
    }
  } catch (error) {
    console.error(error);
  }
  return frag;
}

function section_parse(content, doc=document) {
  let frag = doc.createDocumentFragment();
  // we can't set innerHTML directly on a document frag, hence the wrap
  let wrap = doc.createElement("div");
  wrap.id = "wrap";
  wrap.innerHTML = content;
  frag.appendChild(wrap);
  // catch any untagged elements
  let untagged = make_arr(
    frag.querySelectorAll("*:not([data-hederis-type])")
  );
  untagged.map(item => {
    if (!item.dataset.hasOwnProperty("hederisType")) {
      let eltype = get_type(item);
      item.setAttribute("data-hederis-type",eltype);
    }
  });
  // do any necessary html cleanup
  frag = nest_inlines(frag);
  return get_book_sections(frag);
}

// XXX this doesn't deal with nesting, it's not truly flat
// not used
function get_book_sections(frag) {
  // get all the section els
  // simpler version used "section" as selector but
  let section_els = make_arr(
    frag.querySelectorAll("*[data-hederis-type^=hsec]")
  );
  // make them into our friendly objs and return that array
  return section_els.map((section, index) => {
    // prettier-ignore
    // let short = section.textContent.replace(/\n/g, "").split(" ").filter(x => x.length).join(" ").slice(0, 200);
    let title = section.title;
    let id = section.id;
    let hederis_type = section.dataset["hederisType"];
    // console.log(section);

    // using below (outerHTML) to perseve sect or whatever the wrapper is
    let content = section.outerHTML;

    // and ideally remove nodes too
    return {
      title,
      content,
      id,
      hederis_type,
    };
  });
}

function get_type(el) {
  if (typeof window !== 'undefined') {
    let tagmap = {
      "p": "hblkp",
      "br": "hspanforcedbreak",
      "em": "hspanem",
      "strong": "hspanstrong",
      "i": "hspanem",
      "b": "hspanstrong",
      "sup": "hspansup",
      "sub": "hspansub",
      "span": "hspnspan",
      "a": "hspana",
      "img": "hblkimg"
    }

    let cstyle = window.getComputedStyle(el);
    if (tagmap.hasOwnProperty(el.tagName.toLowerCase())) {
      return tagmap[el.tagName.toLowerCase()];
    } else if (cstyle.display === "inline") {
      return "hspnspan";
    }
  }
  return "hblkp";
}

// similar to above but with a different sel
function editor_parse_content(content) {
  let frag = document.createDocumentFragment();
  let wrap = document.createElement("div");
  wrap.id = "wrap";
  wrap.innerHTML = content;
  frag.appendChild(wrap);
  let elementArray = make_arr(frag.querySelectorAll("#wrap  > * > *"));
  // make them into our friendly objs and return that array
  return (
    elementArray
      .map((item, index) => {
        let title = item.title;
        let id = item.id;
        let tagName = item.tagName;
        let hederis_type = item.dataset["hederisType"];

        let content = item.outerHTML;
        let innerHTML = item.innerHTML;
        return {
          content,
          id,
          hederis_type,
          tagName,
          // TODO get rid of inner html and just use content to avoid any possibility of mismatch
          // it's only used in the html inline editor I think
          innerHTML,
        };
      })
      // ok to filter like this? XXX
      .filter(item => {
        // prettier-ignore
        // if (!item.id){ console.log(item, 'had no id') }
        return item.id;
      })
  );
}

function cleanup_content(content, id_arr) {
  let frag = document.createDocumentFragment();
  // we can't set innerHTML directly on a document frag, hence the wrap
  let wrap = document.createElement("div");
  wrap.id = "wrap";
  wrap.innerHTML = content;
  frag.appendChild(wrap);

  frag = add_ids(frag, id_arr);
  frag = fix_img_src(frag);

  frag = stripBlanksAfterImages(frag);
  frag = stripTBody(frag);

  let res = frag.querySelector("div#wrap").innerHTML;

  return res;
}

function add_ids(frag, id_arr) {
  id_arr.forEach(function(myid) {
    let el = frag.querySelector("#" + myid);
    if (el) {
      let pis = el.getAttribute("data-pi-attrs");
      if (!pis) {
        el.setAttribute("data-pi-attrs", "id: " + myid + ";");
      } else if (pis.indexOf(myid) < 0) {
        el.setAttribute("data-pi-attrs", "id: " + myid + ";" + pis);
      }
    }
  });
  return frag;
}

function fix_img_src(frag) {
  let imgs = frag.querySelectorAll("img");
  for (var i = 0; i < imgs.length; i++) {
    let replace = false;
    let mysrc = imgs[i].getAttribute("src");
    let myaltsrc = imgs[i].getAttribute("data-img-src");
    if (mysrc && mysrc === "undefined") {
      replace = true;
    } else if (!mysrc) {
      replace = true;
    }
    if (replace === true && myaltsrc) {
      imgs[i].setAttribute("src", myaltsrc);
    }
  }
  return frag;
}

function stripBlanksAfterImages(frag) {
  let blanks = frag.querySelectorAll("p[data-hederis-type=hblkp]:not([id]):empty");
  for (var i = 0; i < blanks.length; ++i) {
    var blank = blanks[i];
    if (!blank.getAttribute("class") || blank.getAttribute("class") === "") {
      blank.remove();
    }
  }
  return frag;
}

function stripTBody(frag) {
  let table_bodies = frag.querySelectorAll("table tbody");
  for (var i = 0; i < table_bodies.length; ++i) {
    var tbody = table_bodies[i];
    tbody.replaceWith(...tbody.childNodes);
  }
  return frag;
}

function fix_self_closing_tags(html_string) {
  // prettier-ignore
  return html_string.replace(/(<)(p)(\s)(.*?)(\/>)/g, '$1$2$3$4></$2>');
}

module.exports = {
  all_nodes_from_sections,
  section_parse,
  editor_parse_content,
  cleanup_content,
  fix_self_closing_tags
};
