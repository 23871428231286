function mapWeight(weightClass) {
  let weightMap = {
    100: "Ultra-light",
    200: "Extra-light",
    300: "Light",
    400: "Regular",
    500: "Medium",
    600: "Semi-bold",
    700: "Bold",
    800: "Extra-Bold",
    900: "Ultra-bold"
  }
  if (weightClass in weightMap) {
    return weightMap[weightClass];
  } else {
    return weightClass;
  }
}

function mapWidth(widthClass) {
  let widthMap = {
    1: " Ultra-condensed",
    2: " Extra-condensed",
    3: " Condensed",
    4: " Semi-condensed",
    5: "",
    6: " Semi-expanded",
    7: " Expanded",
    8: " Extra-expanded",
    9: " Ultra-expanded"
  };
  if (widthClass in widthMap) {
    return widthMap[widthClass];
  } else {
    return widthClass;
  }
}

function compile_fonts(ALL_FONTS_ARR, user_fonts) {
  let compiled_fonts = [];

  user_fonts.forEach(function(item) {
    let guid = " " + item.id.toString();

    let weights = {};
    let all_feats = [];
    let widths = {};

    item.files.forEach(function(myfile) {
      let myWidth = mapWidth(myfile.widthClass);
      if (!(myfile.widthClass in widths)) {
        widths[myfile.widthClass] = {
          "display-name": `${item.displayName}${myWidth}`,
          "font-family": `'${item.displayName.toLowerCase()}${myWidth.toLowerCase()}${guid}'`,
          "weights": {},
          "opentype": [],
          "type": myfile["f_name"].split(".").pop().replace(/[fF]$/,"").toUpperCase(),
          "user_font": true
        };
      }
      let myWeight = mapWeight(myfile.weightClass);
      if (!(myWeight in widths[myfile.widthClass]["weights"])) {
        widths[myfile.widthClass]["weights"][myWeight] = {"weight": myfile.weightClass};
      }
      // add fontSubfamily to styles arr
      let myStyle = myfile.fontSubfamily.toLowerCase();
      if (!("styles" in widths[myfile.widthClass]["weights"][myWeight])) {
        widths[myfile.widthClass]["weights"][myWeight]["styles"] = [myStyle];
      } else {
        widths[myfile.widthClass]["weights"][myWeight]["styles"].push(myStyle);
      }
      // add path to paths (w/style)
      if (!("paths" in widths[myfile.widthClass]["weights"][myWeight])) {
        widths[myfile.widthClass]["weights"][myWeight]["paths"] = {};
      }
      widths[myfile.widthClass]["weights"][myWeight]["paths"][myStyle] = myfile.path;

      let all_feats = widths[myfile.widthClass]["opentype"].concat(myfile.feats);
      widths[myfile.widthClass]["opentype"] = [...new Set(all_feats)];
    });

    for (let f in widths) {
      compiled_fonts.push(widths[f]);
    }
  });
  // add it to the compiled_fonts
  let all_fonts = compiled_fonts.concat(ALL_FONTS_ARR);
  return [all_fonts, compiled_fonts];
}

// get pertinent font details from the fonts arr
function FL(name, ALL_FONTS_ARR) {
  let url = "";
  let fam = name.replace(/\s?\(?(USER FONT)?\)?\s?\(?[OT]*\)?$/,"");
  let user_font = false;
  let res = false;
  if (ALL_FONTS_ARR !== undefined && ALL_FONTS_ARR !== false) {
    res = ALL_FONTS_ARR.find(x => x["display-name"] === fam);
    if (res !== undefined) {
      url = res["url"];
      fam = res["font-family"];
      if ("user_font" in res) {
        user_font = res["user_font"];
      }
    }
  }
  return [url, fam, user_font, res];
}

function parseFont(config,allurls,makeDeclarations,ALL_FONTS_ARR) {
  for (var k in config) {
    if (config.hasOwnProperty(k) && k.match("[fF]ont$")) {
      if (config[k]) {
        var [myurl, myfam, user_font, res] = FL(config[k], ALL_FONTS_ARR);
        // if this is a user font, we'll need to make a declaration for it
        if (res && user_font) {
          makeDeclarations.push([user_font, res]);
        } else {
          // otherwise, we can just use the import url
          if (myurl && allurls.indexOf(myurl) === -1) {
            allurls.push(myurl);
          }
        }
      }
    } else if (config.hasOwnProperty(k) && k.match("_subSelectors$")) {
      for (var j in config[k]) {
        if (config[k].hasOwnProperty(j)) {
          [allurls,makeDeclarations] = parseFont(config[k][j],allurls,makeDeclarations,ALL_FONTS_ARR);
        }
      }
    }
  }
  return [allurls,makeDeclarations];
}

function parseAllFonts(ALL_FONTS_ARR) {
  let allurls = [];
  let makeDeclarations = [];
  var fonts_arr = ALL_FONTS_ARR.map(font => {
    var [myurl, myfam, user_font, res] = FL(font['display-name'], ALL_FONTS_ARR);
    if (res && user_font) {
      makeDeclarations.push([user_font, res]);
    } else {
      // otherwise, we can just use the import url
      if (myurl && allurls.indexOf(myurl) === -1) {
        allurls.push(myurl);
      }
    }
  });
  return [allurls, makeDeclarations];
}

// typekit fonts need imports
function getFontImports(ALL_FONTS_ARR, allurls, makeDeclarations) {
  // if weights/paths exists, create the font-face rule instead
  var myfont;
  var myimport = "";
  var fontFaces = [];
  allurls.forEach(function(el) {
    myimport = myimport + "\n@import url(" + el + ");";
  });
  if (makeDeclarations.length > 0) {
    makeDeclarations.forEach(function(x) {
      let f = x[1];
      for(var w in f.weights) {
        f.weights[w]["styles"].forEach(function(s) {
          myfont = makeFontFaceDeclaration({
            "font-family": f["font-family"].split(",")[0],
            "path":f.weights[w]["paths"][s],
            "style":s,
            "weight":f.weights[w]["weight"],
            "type":f.type
          });
          fontFaces.push(myfont);
        });
      };
    });
  }
  return [myimport, fontFaces];
}

// non-typekit fonts get an actual font-face declaration
// expects a hash: {font-family,path,style,weight}
function makeFontFaceDeclaration(font) {
  var types = {"TT": "truetype","OT": "opentype"};
  var type = font['type'].toUpperCase();
  var myFontFace = `@font-face{
    font-family:${font['font-family']};
    src:url("${font['path']}") format("${types[type]}");
    font-display:auto;
    font-style:${font["style"]};
    font-weight:${font["weight"]};
  }`;
  return myFontFace;
}

function get_book_fonts(css, fonts_arr) {
  let usedFontsArr = [];
  let myFonts = [];
  let fontsUsed = [...css.matchAll(/font-family:\s*.*?;/g)];
  for (let i=0; i < fontsUsed.length; i++) {
    let thisFont = fontsUsed[i][0];
    thisFont = thisFont.replaceAll(/font-family:\s*/g, "").replaceAll(/\s*;/g, "").replaceAll(/['"]/g, "");
    let thisFontArr = thisFont.split(",").map(x => x.trim());
    usedFontsArr = usedFontsArr.concat(thisFontArr);
  }
  let unique = [...new Set(usedFontsArr)];
  let toDelete = ["inherit", "serif", "sans-serif", "monospace"];
  for (let j=0; j < toDelete.length; j++) {
    let val = toDelete[j];
    let myIndex = unique.indexOf(val);
    if (myIndex > -1) {
      unique.splice(myIndex, 1);
    }
  }
  for (let k=0; k < unique.length; k++) {
    for (let l=0; l < fonts_arr.length; l++) {
      let re = new RegExp(String.raw`^['"]${unique[k]}['"]`, "g");
      if (fonts_arr[l]["font-family"].match(re)) {
        myFonts.push(fonts_arr[l]);
      }
    }
  }
  return myFonts;
}

function font_loader(fonts_arr) {
  let [allurls,makeDeclarations] = parseAllFonts(fonts_arr);
  let [importstr,fontFaces] = getFontImports(fonts_arr, allurls, makeDeclarations);
  let fontFaceStr = fontFaces.join("\n");
  return importstr + fontFaceStr;
}

function validate_font_style(checked) {
  if (checked) {
    data = "Italic";
  } else {
    data = "Regular";
  }
  return data;
}

module.exports = {mapWeight, compile_fonts, font_loader, FL, parseFont, getFontImports, validate_font_style, get_book_fonts}
