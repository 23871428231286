import React from "react";
import ReactDOM from "react-dom";
// prettier-ignore
import {Pane, Text, Heading, Button, TextInputField, TabNavigation, Tab, SidebarTab, Tablist, Link, toaster, IconButton, Icon, Small, TextInput, RadioGroup, Select, Strong, Checkbox, Combobox, TagInput, Portal, ChevronRightIcon, ChevronLeftIcon, PanelStatsIcon} from "evergreen-ui";
import Frame from "./frame.js";
// import { FontUploadView } from "./font_upload.js";
import ExternalPreview from "./external.js";
import HedTab from "../shared/HedTab.js";
import { LoadingPane, ProjectTabs, NotFound } from "../shared/common.js";
// prettier-ignore
import { human_adv_template_label, get_node_obj_sel, get_label_from_key, create_img_url_cache, sanitizeHtml, digestMessage } from "../../helpers/util.js";

import { prep_html_for_digest_hash } from "./app_advanced.js";
// import { section_parse } from "../../helpers/section_parse.js";
// prettier-ignore
import {  make_html_page, strip_html_bh, strip_dashes, wrap_with_simple_html_body } from "../../../api/app_shared/process/html.js";

import SaveDesignWidget from "./save_design_widget.js";
import { EditSaveAndCancel } from "./confirm_widget.js";
import PagesWidget from "./pages_widget.js";
import TemplateCreator from "./template_creator.js";
import DesignRightPanel from "./design_right_panel.js";
import { ViewOptionsControl, SectionSelect } from "./view_options.js";
import AdvancedTemplatePicker from "./template_picker.js";
// import config_defaults from "../../../api/app_shared/defaults/general_config_defaults.json";
import generate_json from "../../helpers/generate_json.js";

import { compile_fonts } from "../../../api/app_shared/process/fonts";
import ALL_FONTS_ARR from "../../../api/app_shared/fonts/fonts.json";

import { firebase, db, storage } from "../../fire.js";

function GetHierarchy(project) {
  // the only thing we care about from the project
  const { nontypeset_mode } = project;

  let tab_hierarchy = {
    design: {
      label: "Design",
      data: {
        general: {
          label: "General Design Settings",
          top: ["page_", "body_", "hyphens_", "runhead_", "runfoot_", "folio_"],
          sub: ["master__"],
        },
        master_pages: {
          label: "Page templates",
          sub: [
            "master_frontmatter_",
            "master_clear_",
            "master_chapter_",
            "master_part_",
            "master_backmatter_",
          ],
        },
        sections_and_text: { label: "Sections & Text" },
      },
    },
    page_layout: {
      label: "Page Layout",
      data: { general: {}, master_pages: {}, sections_and_text: {} },
    },
    styles: {
      label: "Styles",
      data: { general: {}, master_pages: {}, sections_and_text: {} },
    },
  };
  // for price discrim #870
  if (nontypeset_mode === "galley") {
    delete tab_hierarchy.page_layout;
  }
  return tab_hierarchy;
}

export default class DesignEditor extends React.Component {
  constructor(props) {
    super(props);
    this.setViewOpt = this.setViewOpt.bind(this);
    // this.setLockedState = this.setLockedState.bind(this);
    // this.getLegacyLockState = this.getLegacyLockState.bind(this);
    // XXX see note with callFrameFunc
    this.frame_ref = React.createRef();
    this.callFrameFunc = this.callFrameFunc.bind(this);
    let section_index = parseInt(props.sub);
    section_index = isNaN(section_index) ? 0 : section_index;
    // prettier-ignore
    let view_options = {spread_view:false, show_grid:false, show_guides:false, section_index, show_manip_spans:false, para_locked:true, close_external_preview:false, epub_mode: false, show_overrides: false}
    // let page_layout
    let top_level_ui = this.get_lock_status(section_index)
      ? "page_layout"
      : "design";
    let second_level_ui = "general";
    // let second_level_ui = "master_pages";
    this.state = {
      current_range: false,
      range_locked: false,
      view_options,
      top_level_ui,
      second_level_ui,
      prev_sibling_type_sel: false,
      selection_mode: "all",
      selection_path: [],
      content_editing: false,
      // will be an object when it loads
      image_lookup: false,
      // only to be used when a section is locked
      line_editor_mode: false,
      line_selected: "",
      loading: true,
      page_count: 0,
      unpaging: false,
      show_right_panel: false,
    };
  }
  // componentDidCatch will prevent it from getting up to the main error boundry for the app
  // without this function here it'll crash out on to our error screen
  // we should also use getDerivedStateFromError to tell the user... XXX TODO
  componentDidCatch(error, errorInfo) {
    console.error("There was an error in a child of the design editor");
  }
  componentWillUnmount() {
    // console.log("design editor unmount");
  }
  // static getDerivedStateFromError(error) {
  //   // Update state so the next render will show the fallback UI.
  //   // return { has_error: true };
  //   // we could ask them to reload?
  // }
  //
  // using a ref to directly call our page breaking methods on the frame
  // this is an anti-pattern but fine for right now.
  // when we eventually want to add an undo stack, we'll use a more functional approach and stop using this
  async callFrameFunc(command, data) {
    // run the command in any case
    this.frame_ref.current.receiveCommand(command, data);
    // we set content state twice here if it's an insert command
    this.props.onDesignHtmlEdit(this.getCurrentHtml(), async () => {
      if (command.includes("insert") || command.includes("remove")) {
        await this.frame_ref.current.onRunLayoutClick();
        this.props.onDesignHtmlEdit(this.getCurrentHtml());
      }
    });
  }
  // only handles one change at a time {k:v} ! XXX
  setViewOpt(change) {
    // hacky but flexible for now - just assuming there is one key and one value
    let [k, v] = Object.entries(change)[0];
    // if we're changing sections, grab the current html to update state in app_advanced!
    // XXX importantly, before we change the section_index !
    if (k === "section_index") {
      // this.frame_ref.current.rebake();
      this.props.onDesignHtmlEdit(this.getCurrentHtml());
      // this simply sets the view to page layout if the section we're changing to is locked
      // prettier-ignore
      if (this.get_lock_status(v)) {  this.setState({top_level_ui:"page_layout"}) }
      else { this.setState({top_level_ui:"design"}) }
    }

    let { view_options } = this.state;

    view_options[k] = v;

    if (k == "section_index") {
      this.setHash(v);
    }
    // changes to the section index and epub_mode are a special case, which we run after setting the state below
    let should_rerun_after = k == "section_index" || k == "epub_mode";
    // prettier-ignore
    let after_set = !should_rerun_after ? ()=>{} : ()=>{
      // for now just reset this on section change though some stickyness would probs be nice from a ux perspective it'll cause errors without this
      this.setState({selection_mode: "all", selection_path:[], content_editing:false, prev_sibling_type_sel: false, line_editor_mode:false, line_selected:""})
      // we pass true to prevent scrollY persist
      this.frame_ref.current.run(true)
    }
    // finally set the state, running the above if it's a change to section findIndex
    this.setState({ view_options }, after_set);
  }
  setHash(section_index) {
    let loc = `#/projects/${this.props.id}/design/${section_index}`;
    // replaceState triggers nothing for the hash
    history.replaceState(null, null, loc);
  }
  async componentDidMount() {
    if (!this.props.project.output.html) {
      toaster.warning("You need to upload a docx file first!");
    }

    // create the image lookup cache
    let image_lookup = await create_img_url_cache(
      this.props.project.images,
      storage
    );
    // console.log(image_lookup);
    this.setState({ image_lookup });
  }
  // could use saveAll here maybe? avoid yet another additional run
  // also for UX purposes we're showing the success toasters before we're truly done
  async onBake() {
    this.frame_ref.current.rebake();
    let { section_index } = this.state.view_options;
    let { section_list } = this.props;
    let sect_id = section_list[section_index]["id"];
    this.props.update_section_lock_status(sect_id, section_index, true, () => {
      toaster.success(`Text Saved & Section Locked 🔒`);
      this.props.onDesignHtmlEdit(this.getCurrentHtml(), async () => {
        await this.frame_ref.current.run();
        await this.props.onDesignHtmlSave(this.getCurrentHtml());
      });
    });
  }

  async onUnBake() {
    let { section_list } = this.props;
    let { section_index } = this.state.view_options;
    let sect_id = section_list[section_index]["id"];
    this.props.update_section_lock_status(sect_id, section_index, false, () => {
      toaster.success(`Text Saved & Section Unlocked`);
      let change = this.getCurrentHtml();
      // XXX instead of the baked html that will be in the change object, use the original prop
      change["sect_html"] = section_list[section_index]["content"];
      this.props.onDesignHtmlEdit(change, async () => {
        await this.frame_ref.current.run();
        await this.props.onDesignHtmlSave(this.getCurrentHtml());
      });
    });
  }
  async onSaveConfig() {
    this.props.onDesignConfigSave(this.getCurrentHtml());
  }
  async onSaveHtml() {
    // description: 'Connect your source to a destination to receive data.'
    let { section_index } = this.state.view_options;

    this.props.onDesignHtmlSave(this.getCurrentHtml());

    if (this.get_lock_status(section_index)) {
      toaster.success(`Locked Text Saved!`, {
        description:
          "These changes only apply as long as this chapter is locked",
      });
    }
  }
  async onCancel() {
    toaster.notify("Discarding changes...");
    await this.props.load_config_and_content(this.props.project);

    await this.frame_ref.current.run();
    toaster.closeAll();
    toaster.notify("Changes discarded!");
  }
  getCurrentHtml() {
    // console.log('getCurrentHtml')
    // disable all buttons that could interfere with unpaging the content
    this.setState({unpaging: true});
    let { section_index } = this.state.view_options;
    // prettier-ignore
    let { html_content, baked_content, is_loading } = this.frame_ref.current.get_content();
    // re-enable buttons
    this.setState({unpaging: false});

    return {
      section_index,
      sect_html: html_content,
      baked_sect_html: baked_content,
      is_loading,
    };
  }
  get_font_names() {
    let [fonts_arr, user_fonts_arr] = compile_fonts(
      ALL_FONTS_ARR,
      this.props.user_fonts
    );
    let all_font_names = ALL_FONTS_ARR.filter(x => {
      if (!x["group"] || x["group"] === "") {
        return true;
      } else if (x["group"] === this.props.project.group) {
        return true;
      } else if (x["group"] === this.props.project.creator) {
        return true;
      } else {
        return false;
      }
    }).map(x => `${x["display-name"]} (${x["type"]})`);
    // to do: move user fonts to top of list
    user_fonts_arr.map(x => {
      all_font_names.unshift(`${x["display-name"]} (USER FONT) (${x["type"]})`);
    });
    all_font_names.unshift("inherit");
    //console.log(all_font_names);
    return all_font_names;
  }
  get_lock_status(section_index) {
    let { section_list } = this.props;
    if (!section_list.length) {
      return false;
    }
    return section_list[section_index]["is_locked"] || false;
    // let { section_meta } = this.props.project;
    // let sect_id = section_list[section_index]["id"];
    // let is_current_section_locked = section_meta[sect_id]["is_locked"] || false;
    // return is_current_section_locked;
  }
  getTopLevelNav() {
    return (
      <Pane display="flex" flexDirection="row" justifyContent="space-between">
        <Pane marginTop={12}>
          {this.props.project.advanced_config_path ? (
            <Text size={300} color="#ffffff" textDecoration="underline" paddingX={16}>
              {human_adv_template_label(
                this.props.project.advanced_config_path.split("/").pop()
              )}
            </Text>
          ) : (
            ``
          )}
        </Pane>
      </Pane>
    );
  }

  render() {
    let { view_options } = this.state;
    let { section_index } = view_options;
    let { section_list, full_config } = this.props;
    // console.log("section_list", section_list);
    // prettier-ignore
    if (!section_list.length) {  return <Pane padding={32} ><Heading>Please upload a docx file and allow it to convert before using the design tools.</Heading></Pane>; }

    let sect = section_list[view_options.section_index];
    // console.log(sect);

    let is_current_section_locked = this.get_lock_status(section_index);

    let should_show_unbaked =
      !is_current_section_locked || view_options.epub_mode;

    let sect_html_content = should_show_unbaked
      ? sect["content"]
      : sect["baked_sect_html"];

    // a quick fix for https://github.com/Hederis/hederis/pull/662#issuecomment-613191128
    if (!sect_html_content) {
      console.log("WARNING: expected baked_sect_html, using UNbaked content");
      sect_html_content = sect["content"];
    }

    //|| sect["content"];
    // console.log({ is_current_section_locked });
    // console.log({ sect_html_content });
    let advanced_config_version = 0;

    if (this.props.project.advanced_config_version) {
      advanced_config_version = this.props.project.advanced_config_version;
    }

    let config_and_style_list = generate_json(sect_html_content, full_config, advanced_config_version);
    // just for ease, using same names from pre-sectioning
    let advanced_config = config_and_style_list[0];
    let style_list = this.props.project.style_list;
    if (style_list == undefined) {
      style_list = config_and_style_list[1];
    }
    let all_font_names = this.get_font_names();
    let fonts_arr = compile_fonts(ALL_FONTS_ARR, this.props.user_fonts)[0];

    let loading_status_for_layout_control = true;
    if (
      this.frame_ref.current && 
      this.frame_ref.current.iframe.contentWindow.HEDERIS_RENDER_FINISH
    ) {
      loading_status_for_layout_control = false;
    }

    // get the diff hash
    const { sanitized_config, sanitized_html_content } = prep_html_for_digest_hash({section_list: this.props.section_list, full_config: this.props.full_config, project: this.props.project});
    // prettier-ignore
    if (!advanced_config || !this.state.image_lookup) {  return BigLoadingPane; }
    return (
      <Pane>
        <Pane display="flex" justifyContent="flex-start" marginBottom={0} width="100%">
          <Pane width="33vw" minWidth={450} backgroundColor="#373550" paddingTop={8}>
            <Tablist>
              {Object.entries(GetHierarchy(this.props.project)).map(([k, v]) => {
                return (
                  <HedTab
                    height={40}
                    key={k}
                    textTransform="capitalize"
                    isSelected={k === this.state.top_level_ui}
                    appearance={k === this.state.top_level_ui ? "heddark" : "hedmedium"}
                    onSelect={() => {
                      if (k == "page_layout" || k == "styles") {
                        // we set all these because these lack subviews
                        // and we want to reset the ui
                        this.setState({
                          top_level_ui: k,
                          second_level_ui: "general",
                          // and deselect
                          selection_path: [],
                          prev_sibling_type_sel: false,
                          content_editing: false,
                        });
                        this.frame_ref.current.iframe.contentWindow.getSelection().collapse(this.frame_ref.current.iframe.contentDocument.body, 0);
                      } else {
                        this.setState({
                          top_level_ui: k,
                          current_range: false,
                        });
                      }
                    }}
                  >
                    {v.label}
                  </HedTab>
                );
              })}
            </Tablist>
          </Pane>
          {!this.state.loading && !this.state.unpaging && !this.props.saving && (
            <Pane 
              width="67vw" 
              display="flex" 
              flexDirection="row" 
              justifyContent="space-between" 
              backgroundColor="#373550"
            >
              <SaveDesignWidget
                {...this.props}
                {...this.state}
                sanitized_config={sanitized_config}
                sanitized_html_content={sanitized_html_content}
                line_editor_mode={this.state.line_editor_mode}
                onDesignHtmlSave={this.onSaveHtml.bind(this)}
                onDesignConfigSave={this.onSaveConfig.bind(this)}
                onCancel={this.onCancel.bind(this)}
                onEditDiscard={stateChanges => {
                  console.log(stateChanges);
                  this.setState(stateChanges, async () => {
                    await this.frame_ref.current.run();
                  });
                }}
                onRunLayoutClick={() => {
                  this.frame_ref.current.onRunLayoutClick()
                }}
                is_loading={this.state.loading} 
                is_saving={this.props.is_saving} 
                show_spinner={true}
              />
              <Pane display="flex" flexDirection="row">
                <EditSaveAndCancel 
                  {...this.props} 
                  is_loading={this.state.loading} 
                  is_saving={this.props.is_saving} 
                  isLocked={is_current_section_locked}
                  content_editing={this.state.content_editing}
                  line_editor_mode={this.state.line_editor_mode}
                  range_locked={this.state.range_locked}
                  current_range={this.state.current_range}
                  onEditEnter={stateChanges => {
                    this.setState(stateChanges);
                  }}
                  onEditLeave={stateChanges => {
                    this.setState(stateChanges, () => {
                      this.props.onDesignHtmlEdit(this.getCurrentHtml());
                    });
                  }}
                />
                <ViewOptionsControl
                  opt={view_options}
                  section_list={section_list}
                  isLocked={is_current_section_locked}
                  section_meta={this.props.project.section_meta}
                  setOpt={this.setViewOpt}
                  onBake={this.onBake.bind(this)}
                  onUnBake={this.onUnBake.bind(this)}
                  saving={this.props.saving}
                > 
                </ViewOptionsControl>
                <Pane marginY={12} borderRight="1px solid #ffffff"></Pane>
                <AdvancedTemplatePicker {...this.props} useSlimPicker={true} customFunction={this.frame_ref.current.onRunLayoutClick} />
              </Pane>
            </Pane>
          )}
        </Pane>
        <Pane display="flex">
          <Pane width="34vw" minWidth={450} backgroundColor="#5D597A">
            {/* config filename is just used for ui*/}
            <TemplateCreator
              config={advanced_config}
              custom_css={this.props.custom_css}
              style_list={style_list}
              group={this.props.project.group}
              user_groups={this.props.groups.concat(this.props.owned_groups)}
              fonts_arr={fonts_arr}
              project={this.props.project}
              hederis_user={this.props.hederis_user}
              fontnames={all_font_names}
              viewOpt={view_options}
              params={this.props}
              setViewOpt={this.setViewOpt}
              load_fonts={this.props.load_fonts}
              onBake={this.onBake.bind(this)}
              onUnBake={this.onUnBake.bind(this)}
              onSaveCustomCss={this.props.onSaveCustomCss}
              isLocked={is_current_section_locked}
              is_loading={loading_status_for_layout_control}
              is_saving={this.props.saving}
              rangeLocked={range_locked => {
                // only do this in page_layout view
                // prettier-ignore
                if (this.state.top_level_ui !== "page_layout") { return }
                // and if we're not in line_editor mode
                // prettier-ignore
                if (is_current_section_locked && this.state.line_editor_mode) { return  }
                this.setState({ range_locked });
              }}
              bookContainsLock={section_list.some(s => s.is_locked)}
              config_path={this.props.project.advanced_config_path}
              {...this.state}
              onLayoutButtonClick={(command, data) => {
                this.callFrameFunc(command, data);
              }}
              onViewChange={change => {
                this.setState(change);
              }}
              deselectRange={() => {
                // hacky way to deselect for consistent ux when we switch to page layout view
                // prettier-ignore
                this.frame_ref.current.iframe.contentWindow.getSelection().collapse(this.frame_ref.current.iframe.contentDocument.body, 0);
              }}
              onChange={(input_key, value) => {
                // this used to be a lot more complicated with more workarounds
                // which was commented out. deleted on sept 5, if it turns out we needed some of that
                this.props.onDesignConfigEdit({ input_key, value });
              }}
              onUpdateProjectMeta={(item) => {
                this.props.updateProjectMeta(item);
              }}
            />
          </Pane>
          {sect_html_content ? (
            <Frame
              html_content={sect_html_content}
              section_list={this.props.section_list}
              advanced_config_version={this.props.project.advanced_config_version}
              view_options={view_options}
              advanced_config={advanced_config}
              custom_css={this.props.custom_css}
              style_list={style_list}
              user_fonts={this.props.user_fonts}
              fonts_arr={fonts_arr}
              ref={this.frame_ref}
              hederis_user={this.props.hederis_user}
              isLocked={is_current_section_locked}
              is_saving={this.props.saving}
              changes={this.props.changes}
              update_change_list={this.props.update_change_list.bind(this)}
              content_editing={this.state.content_editing}
              params={this.props}
              onViewChange={change => {
                this.setState(change);
              }}
              images={this.props.project.images}
              image_lookup={this.state.image_lookup}
              set_loading={val => this.setState({ loading: val })}
              set_page_count={val => this.setState({ page_count: val })}
              total_page_count={this.props.project.page_count}
              onRange={current_range => {
                // only do this in page_layout view
                // prettier-ignore
                if (this.state.top_level_ui !== "page_layout") { return }
                // and if we're not in line_editor mode
                // prettier-ignore
                if (is_current_section_locked && this.state.line_editor_mode) { return  }
                this.setState({ current_range });
              }}
              rangeLocked={range_locked => {
                // only do this in page_layout view
                // prettier-ignore
                if (this.state.top_level_ui !== "page_layout") { return }
                // and if we're not in line_editor mode
                // prettier-ignore
                if (is_current_section_locked && this.state.line_editor_mode) { return  }
                this.setState({ range_locked });
              }}
              range_locked={this.state.range_locked}
              current_range={this.state.current_range}
              selection_path={this.state.selection_path}
              selection_mode={this.state.selection_mode}
              line_editor_mode={this.state.line_editor_mode}
              line_selected={this.state.line_selected}
              prev_sibling_type_sel={this.state.prev_sibling_type_sel}
              onInitialLoad={(cb = () => {}) => {
                // XXX possibly remove this ?
                // console.log("getting html on frame initial load, ");
                // this.props.onDesignHtmlEdit(this.getCurrentHtml());
                this.props.onDesignHtmlEdit(this.getCurrentHtml(), cb);
              }}
              onPreviewClick={e => {
                // convert each element in the path to a string of it's selector
                let selection_path = process_click_path(e.composedPath());

                // deal with line editor mode first
                if (is_current_section_locked && this.state.line_editor_mode) {
                  //XXX not dealing with if line_selected already, but seems to be ok
                  // get the first in path that's a line, if there is one, otherwise use the el they clicked
                  let line_dr =
                    e.composedPath().find(x => x.className == "line") || e.target;

                  let line_selected = line_dr.dataset["ref"]
                    ? `[data-ref="${line_dr.dataset["ref"]}"]`
                    : "";
                  this.setState({ line_selected });
                  // and then get out of here
                  return;
                }

                if (!selection_path.length) {
                  // console.log("no selection path, return");
                  return;
                }

                // this isn't going to be sufficient in the long term because of textmanipulation spans,  it needs to recurse TODO, sometimes it's a text node?
                let prev_sibling_type_sel = get_prev_sibling_type(e.target);

                // new content editing behavior
                if (this.state.content_editing) {
                  this.setState({
                    selection_path,
                    prev_sibling_type_sel,
                    current_range: false,
                    selection_mode: "only",
                  });
                  // and get out
                  return;
                }
                // make sure we're not in the page layout view, to not conflict with range behaviors
                // prettier-ignore
                if (this.state.top_level_ui !== "design") { return }
                // console.log(selection_path);
                // this deals with designing a locked chapter, #722
                // just drop any span.line in path
                selection_path = selection_path.filter(p => p !== `span.line`);
                // and finally, our standard behavior for designing
                this.setState({
                  selection_path,
                  prev_sibling_type_sel,
                  current_range: false,
                  second_level_ui: "sections_and_text",
                  selection_mode: "all",
                });
              }}
            />
          ) : (
            BigLoadingPane
          )}
          {this.state.show_right_panel ? (
            <DesignRightPanel
              {...this.props}
              {...this.state}
              total_page_count={this.props.project.page_count}
              view_options={view_options}
              section_list={section_list}
              fonts_arr={fonts_arr}
              keep_open={!view_options.close_external_preview}
              is_loading={loading_status_for_layout_control}
              scrollToPage={pageNum => {
                this.frame_ref.current.scrollToPage(pageNum)
              }}
              onExternalDone={this.props.onExternalDone}
              isLocked={is_current_section_locked}
              setOpt={this.setViewOpt}
              onBake={this.onBake.bind(this)}
              onUnBake={this.onUnBake.bind(this)}
              showRightPanel={e => this.setState({show_right_panel: !this.state.show_right_panel})}
              navToPrevSection={() => {
                props.setOpt({ section_index: view_options.section_index - 1 });
              }}
              navToNextSection={() => {
                props.setOpt({ section_index: view_options.section_index + 1 });
              }}
            />
          ) : (
            <IconButton 
              icon={PanelStatsIcon} 
              title="Show Book Structure" 
              height={56} 
              appearance="minimal"
              onClick={e => this.setState({show_right_panel: !this.state.show_right_panel})} 
            />
          )}
        </Pane>
      </Pane>
    );
  }
}

// some setup for process_click_path
let not_selectable_classes = ["pagedjs_page_content", "pagedjs_area"];
// // not sure about including these
not_selectable_classes.push("pagedjs_margin-");
not_selectable_classes.push("pagedjs");
// // it appears pagedjs adds a div within .pagedjs_area, this detects it
// prettier-ignore
let  is_plain_div = (p)=> p.nodeName=="DIV" && Object.keys(p.dataset).length === 0 && !p.id && !p.className
let is_node_selectable = p =>
  !not_selectable_classes.some(s => {
    return p.className.includes(s) || is_plain_div(p);
  });

// a helper for Frame's onPreviewClick
// if it's a valid preview click, return the selectified path, otherwise an empty arr
function process_click_path(event_path) {
  // console.log("original:", event_path);
  let first_index = event_path.findIndex(is_node_selectable);

  // prettier-ignore
  let top_pagejs_div_index = event_path.findIndex(x => x.className !== undefined ? x.className.includes("pagedjs_pages") : true)
  // console.log(first_index, top_pagejs_div_index);
  // console.log(top_pagejs_div_index);

  // let truncated_sel_path = event_path.slice(0, top_pagejs_div_index);
  let truncated_sel_path = event_path.slice(first_index, top_pagejs_div_index);
  // console.log(truncated_sel_path);
  // filter out the ones we don't want to deal with
  let selection_path = truncated_sel_path.map(get_node_obj_sel).filter(s => {
    return (
      !s.includes(".textmanipulation") && s !== "div" && !s.includes(".pagedjs")
    );
  });
  // console.log("mine", selection_path);
  return selection_path;
  // unselectables, that plain div, etc
}

const BigLoadingPane = (
  <Pane
    width="auto"
    justifyContent="center"
    height="30vh"
    display="flex"
    alignItems="center"
  >
    <LoadingPane pad={32} />
  </Pane>
);

function get_prev_sibling_type(target) {
  // prettier-ignore
  if (!target || !target.previousSibling) { return false }
  // XXX maybe we want to check if it actually has it?
  else if (target.previousSibling.getAttribute) {
    return target.previousSibling.getAttribute("data-hederis-type");
  } else {
    return get_prev_sibling_type(target.parentElement)

  }
}

function get_sect_id(html_content) {
  let myRegexp = /(^<[^>]*id=["'])(\S+)/g;
  let match = myRegexp.exec(html_content);
  return match[2].replace(/["']*/g, "");
}
