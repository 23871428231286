import React from "react";

import { Pane, Text, Heading, Button, toaster, IconButton, ExpandAllIcon, CollapseAllIcon, InfoSignIcon } from "evergreen-ui";
import relativeDate from "tiny-relative-date";

import { firebase, db, storage } from "../../fire.js";
import APIClient from "../../helpers/api_client";
import { FireHelpers } from "../../../api/app_shared/fire_helpers.js";
const API = new APIClient(firebase);
const FH = new FireHelpers(db, firebase);

import template_builder_defaults from "../../../api/app_shared/defaults/template_builder_defaults.json";
const static_template_map = require("../../../api/app_shared/defaults/static_book_style_templates.json");
import default_basic_config from "../../../api/app_shared/defaults/basic_project_defaults.json";
// prettier-ignore
import { ExpandablePane, CloudImage, Loader, ImageList, IngestMessages, Summary} from "../shared/common.js";

import EpubCheckView from "../shared/epubcheck_view.js";
import CoverImageView from "../shared/cover_image.js";
import UploadPrompt from "../shared/upload_prompt.js";
import UploadWrapper from "../shared/upload_wrap.js"; // todo maybe make this a HOC? for now it's hacky but works

import BasicOptions from "../basic/basic_options.js";
import TemplatePicker from "../basic/template_picker.js";
import AdvancedTemplatePicker from "../advanced/template_picker.js";
import TemplateBuilderContainer from "../basic/template_builder_container.js";
import ConfigureExportSettings from "../shared/configure_export_settings.js";
import OutputButtons from "../shared/output_buttons.js";

// import TemplateBuilder from "./template_builder.js";

import Dropzone from "react-dropzone";

import { get_unique_filename, slugify } from "../../helpers/util";

export default class BasicDashboard extends React.Component {
  constructor(props) {
    super(props);
    window.basic_dash_debug = this;
    // console.log(HEDERIS_API_PATH_WITH_STAGE);
    // not rec'd react style but works well, so we can use it in the upload prompts
    this.upload_wrapper_ref = React.createRef();
    this.toggleStep = this.toggleStep.bind(this);
    let steps_expanded = [false, false, false, false, false, false];

    // for now, before I merge in the security stuff which restructures above, just putting this here as state, but it should be it's own route(s)
    let show_template_builder = false;
    let editing_template_builder_id = false;
    this.state = {
      steps_expanded,
      show_template_builder,
      editing_template_builder_id,
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log("DASH DID UPDATE");
    // just using this as a hacky way to notify that builds are done
    // this is an inelegant way to check if it's changed and exists,
    if (
      this.props.project.output.html &&
      JSON.stringify(prevProps.project.output.html) !==
        JSON.stringify(this.props.project.output.html)
    ) {
      toaster.closeAll();
      toaster.success("Conversion Complete!", { duration: 30 });
      // every convert will have a build, and we want to keep a notification up while we're working
      toaster.success("Building the book...", { duration: 900 });
    }
    if (
      this.props.project.output.pdf &&
      JSON.stringify(prevProps.project.output.pdf) !==
        JSON.stringify(this.props.project.output.pdf)
    ) {
      toaster.closeAll();
      toaster.success("Build Complete!", { duration: 30 });
    }
  }

  async componentDidMount() {
    //
    // Object.keys(static_template_map)
  }
  // this expects the full obj the comp was passed, template, export_settings_style, options,
  onChange(change) {
    let basic_config = this.props.project.basic_config;
    basic_config = { ...basic_config, ...change };
    this.props.updateProjectMeta({ basic_config });
  }
  hacky_image_refresh() {
    this.toggleStep(0);
    this.toggleStep(1);
    this.setState({ hide_cover_for_hacky_refresh: true });
    setTimeout(() => {
      this.toggleStep(0);
      this.toggleStep(1);
      this.setState({ hide_cover_for_hacky_refresh: false });
    }, 10);
  }
  afterImageUpload(image_path) {
    toaster.closeAll();
    let { images } = this.props.project;
    if (images.includes(image_path)) {
      toaster.notify("Replacing existing image with the same name.");
      this.hacky_image_refresh();
    } else {
      images.push(image_path);
    }
    if (this.props.project.cover_image) {
      toaster.success("Image Upload Complete!");
      this.props.updateProjectMeta({ images });
    } else {
      toaster.success("Image Upload Complete! Making it the cover image");
      this.props.updateProjectMeta({ images, cover_image: image_path });
    }
  }
  async maybe_get_config(template_id) {
    // deal with advanced design
    // TODO REFACTOR XXX ZZZ
    if (this.props.project.advanced_config_path) {
      let url = await storage
        .ref(this.props.project.advanced_config_path)
        .getDownloadURL();
      let res = await fetch(url);
      let data = await res.text();
      let advanced_config = JSON.parse(data);
      advanced_config["is_advanced"] = true;
      return advanced_config;
      // deal with static templates
    } else if (Object.keys(static_template_map).includes(template_id)) {
      return false;
    } else {
      // deal with basic template builder
      let template = await FH.get_basic_template_by_id(template_id);
      return template["template_builder_config"];
    }
  }
  async afterDocxUpload(docx_path) {
    // console.log("afterDocxUpload", docx_path);

    // let template_builder_config
    let project_id = this.props.params.id;
    // let { uid, displayName } = this.props.hederis_user;
    let { project, hederis_user } = this.props;

    toaster.closeAll();
    toaster.notify("Preparing to Convert", { duration: 900 });
    let template_builder_config = await this.maybe_get_config(
      project.basic_config.template
    );
    // TODO stop sending entire user, but need to clean out lambdas of that. just send display name, we get the id from the token
    let payload = {
      docx_path,
      hederis_user,
      project_id,
      project,
      template_builder_config,
    };

    let res = await API.trigger_convert(payload);
    if (!res) {
      // if (res.status && res.status !== 200) {
      toaster.closeAll();
      // console.log(res.statusText);
      toaster.warning("There was an error converting", { duration: 10 });
      return;
    }
    if (res["message"] == "billing-error") {
      toaster.closeAll();
      toaster.danger(
        "There was an error billing the credit card we have on file.",
        { duration: 30 }
      );
      return;
    } else if (res["message"] == "billing-not-setup") {
      toaster.closeAll();
      toaster.danger(
        "Sorry, you or (your organization) need to enable billing to convert more. Go to your Account page to set it up.",
        { duration: 30 }
      );
      return;
    }

    toaster.closeAll();
    toaster.notify("Converting document", { duration: 900 });
    // we'll deal with this in
    // though we actually want to use gfb, not respond directly, in case it's huge
    // console.log("ingest result", ingest_result);
    // })
    // .catch(err => {
    //
    // });
  }
  async trigger_rebuild() {
    let project_id = this.props.params.id;
    // TODO HERE and for afterDocxUpload, stop passing all this, we want to have the trigger
    // or possibly the receivers get the project from the db.
    // we really only need to pass project_id, user displayname and uid, and docx path above
    let { project, hederis_user } = this.props;
    let html_path = this.props.project.output.html.path;
    let template_builder_config = await this.maybe_get_config(
      project.basic_config.template
    );
    // let gfb_id_token = await firebase.auth().currentUser.getIdToken();
    let payload = {
      html_path,
      hederis_user,
      project_id,
      project,
      template_builder_config,
    };

    let res = await API.trigger_rebuild(payload);
    // console.log(res);
    if (!res) {
      toaster.closeAll();
      toaster.warning("There was an error rebuilding", { duration: 10 });
      return;
    }
    toaster.closeAll();
    toaster.notify("Rebuilding Book!", { duration: 900 });
  }
  // helper/handler for toggling which steps are open
  toggleStep(index) {
    let { steps_expanded } = this.state;
    steps_expanded[index] = !steps_expanded[index];
    this.setState({ steps_expanded });
    // console.log(index);
  }
  render() {
    let { project } = this.props;
    let { basic_config } = this.props.project;

    let { template, options, export_settings_style } = basic_config;
    if (!export_settings_style["bleed"]) {
      export_settings_style["bleed"] =
        default_basic_config["export_settings_style"]["bleed"];
    }
    // hacky but i don't know if there was a react way to do this without repeating code otherwise
    let onFileDrop = files => this.upload_wrapper_ref.current.onFileDrop(files);

    let { steps_expanded, show_template_builder } = this.state;
    // console.log(this.props.project.group);
    let progress = [
      !!project.cover_image,
      !!project.images.length,
      1,
      1,
      !!project.output.html, // todo have they uploaded a docx
    ];
    // never for advanced
    if (show_template_builder) {
      return (
        <TemplateBuilderContainer
          {...this.props}
          {...this.state}
          onSelectDone={id => {
            this.onChange({ template: id });
            this.setState({
              show_template_builder: false,
              editing_template_builder_id: false,
            });
          }}
          onCancel={() =>
            this.setState({
              show_template_builder: false,
              editing_template_builder_id: false,
            })
          }
        />
      );
    }

    return (
      <Pane>
        <UploadWrapper
          ref={this.upload_wrapper_ref}
          {...this.props}
          afterDocxUpload={this.afterDocxUpload.bind(this)}
          afterImageUpload={this.afterImageUpload.bind(this)}
        >
          <Pane display="flex" flexDirection="row" justifyContent="center">
            <Pane display="flex" flexDirection="column" marginRight={32}>
              {this.state.hide_cover_for_hacky_refresh ? (
                ``
              ) : (
                <CoverImageView
                  onFileDrop={onFileDrop}
                  project={this.props.project}
                  remove={e =>
                    this.props.updateProjectMeta({ cover_image: "" })
                  }
                />
              )}
              <OutputButtons
                output={this.props.project.output}
                title={this.props.project.title}
                trigger_rebuild={this.trigger_rebuild.bind(this)}
              />
            </Pane>
            <Pane display="flex" flexDirection="column">
              <Summary {...this.props} />
              <Pane display="flex" justifyContent="flex-end">
                <IconButton
                  icon={ExpandAllIcon}
                  title="Expand All Steps"
                  disabled={!!steps_expanded.filter(a => a).length}
                  onClick={() =>
                    this.setState({
                      steps_expanded: [true, true, true, true, true],
                    })
                  }
                />
                <IconButton
                  icon={CollapseAllIcon}
                  disabled={!steps_expanded.filter(a => a).length}
                  title="Collapse all steps"
                  onClick={() =>
                    this.setState({
                      steps_expanded: [false, false, false, false, false],
                    })
                  }
                />
              </Pane>
              <ExpandablePane
                label={`Step 1: Upload Cover`}
                onClick={this.toggleStep}
                index={0}
                expanded={steps_expanded[0]}
                complete={progress[0]}
              >
                {!this.props.project.cover_image ? (
                  <UploadPrompt
                    onFileDrop={onFileDrop}
                    label="Upload Cover Image"
                    accept=".jpg, .jpeg, .png"
                  />
                ) : (
                  <Pane>
                    <ImageList
                      project={this.props.project}
                      setCoverImage={path => {
                        this.props.updateProjectMeta({ cover_image: path });
                      }}
                    />
                  </Pane>
                )}
              </ExpandablePane>
              <ExpandablePane
                label={`Step 2: Upload Other Images`}
                onClick={this.toggleStep}
                index={1}
                expanded={steps_expanded[1]}
                complete={progress[1]}
              >
                <ImageList
                  project={this.props.project}
                  setCoverImage={path => {
                    this.props.updateProjectMeta({ cover_image: path });
                  }}
                />
                <UploadPrompt
                  onFileDrop={onFileDrop}
                  label={`Upload An${
                    this.props.project.images.length ? "other" : ""
                  } Image`}
                  accept=".jpg, .jpeg, .png"
                />
              </ExpandablePane>
              <ExpandablePane
                label={
                  !this.props.project.is_advanced
                    ? `Step 3: Pick or Create a Template`
                    : "Step 3: Design"
                }
                onClick={this.toggleStep}
                index={2}
                expanded={steps_expanded[2]}
                complete={progress[2]}
              >
                {!this.props.project.is_advanced ? (
                  <TemplatePicker
                    template={template}
                    group={this.props.project.group}
                    onChange={this.onChange.bind(this)}
                    onEditSelect={async id => {
                      // console.log("onEditSelect", id);
                      if (!id) {
                        id = await FH.create_template_builder(
                          this.props.project.group,
                          template_builder_defaults,
                          this.props.params.id
                        );
                        console.log("created with id", id);
                      }
                      this.setState({
                        show_template_builder: true,
                        editing_template_builder_id: id,
                      });
                    }}
                  />
                ) : (
                  <AdvancedTemplatePicker
                    template={template}
                    advanced_config_path={
                      this.props.project.advanced_config_path
                    }
                    group={this.props.project.group}
                    params={this.props.params}
                    onChange={this.onChange.bind(this)}
                    onEditSelect={id => {
                      // console.log("advanced select id", id);
                    }}
                  />
                )}
              </ExpandablePane>
              <ExpandablePane
                label={`Step 4: Configure Export Settings`}
                onClick={this.toggleStep}
                index={3}
                expanded={steps_expanded[3]}
                complete={progress[3]}
              >
                <ConfigureExportSettings
                  export_settings_style={export_settings_style}
                  onChange={this.onChange.bind(this)}
                />
              </ExpandablePane>
              <ExpandablePane
                label={`Step 5: Upload DOCX`}
                onClick={this.toggleStep}
                index={4}
                expanded={steps_expanded[4]}
                complete={progress[4]}
              >
                <UploadPrompt
                  onFileDrop={onFileDrop}
                  label="Upload a DOCX"
                  accept=".docx"
                />
                <Text>
                  Tip: For cleaner results, add a pagebreak before each chapter
                  in your manuscript.
                </Text>
                <BasicOptions
                  options={options}
                  onChange={this.onChange.bind(this)}
                />
              </ExpandablePane>
              <ExpandablePane
                label={`Step 6: Validate your files`}
                onClick={this.toggleStep}
                index={5}
                expanded={steps_expanded[5]}
                icon={InfoSignIcon}
              >
                <EpubCheckView project={this.props.project} />
              </ExpandablePane>
            </Pane>
          </Pane>
        </UploadWrapper>
      </Pane>
    );
  }
}
