import React from "react";
import ReactDOM from "react-dom";
// prettier-ignore
import {Pane, Text, Heading, Button, TextInputField, Spinner, TabNavigation, Tab, SidebarTab, Tablist, Link, toaster, IconButton, Icon, Small, TextInput, RadioGroup, Select, Strong, Checkbox, Combobox, TagInput, Portal, Position, Tooltip} from "evergreen-ui";
import {
  Debouncer,
  parse_dims,
  writeStylesDoc,
  parse_hederis_type,
  parse_id,
} from "../../helpers/util";
import { unpage_html } from "../../../api/app_shared/process/unpage.js";
import { view_options_css } from "../../helpers/preview_view_options_css.js";

import { storage } from "../../fire.js";

// import PREVIEW_JS from "!raw-loader!./preview.js";
// import dev_css_template1 from "!raw-loader!../../../api/app_shared/book_styles/template1-print-pagedjs.css";
// import template_builder_defaults from "../../../api/app_shared/defaults/template_builder_defaults.json";
import placeholder_image_url from "../../../api/app_shared/assets/fpo.png";

import RUN_PAGED from "!raw-loader!../../../api/app_shared/process/run_paged.js";
import PAGE_BREAKING from "!raw-loader!../../helpers/pageBreaking.js";


// console.log(PREVIEW_JS);
import { hypherHyphenate } from "../../../api/app_shared/process/hyphenate.js";
// prettier-ignore
import {  make_html_page, strip_html_bh, strip_dashes, wrap_with_simple_html_body } from "../../../api/app_shared/process/html.js";
const {
  replace_page_nums,
  replace_css_image_urls,
} = require("../../../api/app_shared/process/css.js");
// import {  make_html_page, strip_html_bh, wrap_with_simple_html_body, replace_img_url } from "../../../api/app_shared/process/html.js";
const { asyncForEach } = require("../../../api/app_shared/process/async.js");
const replace_img_urls = require("../../../api/bake_helpers/replace_img_urls");

// import advanced_template from "!raw-loader!../../../api/bake_helpers/css_advanced_template.js";
import template_builder_advanced_defaults from "../../../api/app_shared/defaults/template_builder_advanced.json";
// this is babel ignored XXX
import advanced_template from "../../../api/bake_helpers/css_advanced_template.js";

import { generate_css } from "../../../api/bake_helpers/generate_css.js";
import generate_json from "../../helpers/generate_json.js";

import ALL_FONTS_ARR from "../../../api/app_shared/fonts/fonts.json";

import {
  get_font_lookup,
  patch_fontnames_to_urls,
  patch_imgnames_to_urls,
} from "../../../api/app_shared/process/config.js";

const font_lookup = get_font_lookup(ALL_FONTS_ARR);

// note, not the using the polyfill
const PAGED_MAIN_CDN = `https://unpkg.com/${process.env.PAGED_NPM_NAMESPACE}@${
  process.env.PAGED_NPM_VERSION
}/dist/paged.js`;

// totally based on frame.js
export default class ExternalPreview extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {}

  font_lookup() {
    return get_font_lookup(this.props.fonts_arr);
  }

  get_css(config, style_list) {
    let advanced_config_version = 0;

    if (this.props.project.advanced_config_version) {
      advanced_config_version = this.props.project.advanced_config_version;
    }

    let advanced_config = patch_fontnames_to_urls(config, this.font_lookup());
    advanced_config = patch_imgnames_to_urls(advanced_config, this.props.image_lookup);

    let [css_content, epub_css_content, uioverridecss] = generate_css(
      // patch_fontnames_to_urls(this.props.advanced_config, font_lookup),
      advanced_config,
      style_list,
      this.props.fonts_arr,
      false,
      false,
      advanced_config_version
    );
    // console.log(css_content);
    return css_content;
  }

  get_config() {
    let advanced_config_version = 0;

    if (this.props.project.advanced_config_version) {
      advanced_config_version = this.props.project.advanced_config_version;
    }

    let baked_html = this.props.section_list
      .map(x => x.baked_sect_html || x.content)
      .join("");
    let config_and_style_list = generate_json(
      baked_html,
      this.props.full_config,
      advanced_config_version
    );
    // console.log(config_and_style_list);
    let advanced_config = config_and_style_list[0];
    let style_list = config_and_style_list[1];
    return { advanced_config, style_list };
  }
  run_full_preview() {
    let debug_start = new Date();
    if (this.external_preview) {
      this.external_preview.close();
    }

    let { advanced_config, style_list } = this.get_config();
    this.external_preview = window.open(
      "",
      "_blank",
      "toolbar=0,location=0,menubar=0"
    );
    let [paged_css, non_paged_css] = this.get_css(
      advanced_config,
      style_list
    ).split(`/* *******HEDERIS_SEPARATOR_NON_PAGED_BELOW******* */`);
    let view_css = view_options_css(this.props.view_options);
    let adjusted_custom_css = replace_css_image_urls(
      this.props.custom_css, 
      this.props.image_lookup
    );
    let b64_css = Buffer.from(paged_css + adjusted_custom_css + view_css).toString("base64");
    //
    let baked_html = this.props.section_list
      .map(x => x.baked_sect_html || x.content)
      .join("");

    let html_content = strip_dashes(baked_html);
    html_content = hypherHyphenate(html_content,advanced_config);

    let html_content_with_img_replaced = replace_img_urls(
      html_content,
      this.props.image_lookup,
      placeholder_image_url
    );

    let content = make_html_page({
      js_cdn_arr: [PAGED_MAIN_CDN],
      js: `window.EXTERNAL_HEDERIS_PREVIEW=true;` + RUN_PAGED + PAGE_BREAKING,
      css: non_paged_css,
      html: !this.props.keep_open
        ? `<div style="position: fixed; width: 100vw; height: 100vh; background-color: rgba(0,0,0,0.7); z-index: 3; display: flex; justify-content: center; align-items: center;"><h1 style="font-size: 2em; background-color: rgb(200,200,200); padding: 20px; border-radius: 5px;">This window will automatically close once the book is done loading</h1></div>`
        : "",
      render_env: "frontend",
    });

    let advanced_config_version = undefined;

    if (this.props.project.advanced_config_version) {
      advanced_config_version = this.props.project.advanced_config_version;
    }

    this.external_preview.HederisDOMContentLoaded = async () => {
      // console.log("loaded");
      let page_count = await this.external_preview.HEDERIS_RUN_PREVIEW(
        html_content_with_img_replaced,
        b64_css,
        advanced_config_version
      );
      let debug_end = new Date();
      let debug_secs = ((debug_end - debug_start) / 1000).toFixed(3);
      console.log(`it took ${debug_secs} seconds to render the whole book`);
      this.props.onExternalDone({ page_count });
      // console.log(page_count);
      if (!this.props.keep_open) {
        this.external_preview.close();
      }
    };
    this.external_preview.document.write(content);
  }
  render() {
    let text_btn_props = {
      textDecoration: "underline",
      cursor: "pointer",
      fontWeight: "600",
      onClick: () => this.run_full_preview(),
    };
    return (
      <Pane display="inline-flex" marginLeft={12}>
        {this.props.external_preview_page_count === false ? (
          <Text>
            Last Preview: <Text {...text_btn_props}>Preview Full Book</Text>
          </Text>
        ) : (
          <Text>
            Last Preview: {this.props.external_preview_page_count} Pages{" "}
            <Text {...text_btn_props}>Re-Run</Text>
          </Text>
        )}
      </Pane>
    );
  }
}

// this just loads the grid image so we don't have to load it on demand, which looks a lot like it not working
const grid_image_loader = (
  <div
    style={{
      width: "1px",
      height: "1px",
      backgroundImage:
        'url("https://s3-us-west-2.amazonaws.com/hederis-assets-extra/grid.jpg")',
    }}
  />
);

 
