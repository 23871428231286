import "@babel/polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { Pane } from "evergreen-ui";
import * as Sentry from "@sentry/react";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";

// set up sentry UNLESS we're doing dev on localhost
if (!window.location.origin.startsWith("http://localhost")) {
  Sentry.init({
    dsn: process.env.SENTRY_FRONTEND_DSN,
    environment: process.env.NODE_ENV,
    integrations: [new CaptureConsoleIntegration(
      {
        // array of methods that should be captured
        // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
        levels: ['error']
      }
    )],
  });
}

import App from "./components/app.js";
import withAuth from "./components/with_auth.js";
import { firebase, db } from "./fire.js";
import { Footer } from "./components/shared/common.js";
import "../web_assets/img/favicon.ico";

let AppWithAuth = withAuth(App);

function AppWithAuthAndFooter() {
  return (
    <Pane display="flex" minHeight="100vh" flexDirection="column">
      <Pane flex={1}>
        <AppWithAuth />
      </Pane>
      <Footer />
    </Pane>
  );
}

ReactDOM.render(<AppWithAuthAndFooter />, document.getElementById("root"));

module.hot.accept();
