import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
// prettier-ignore
import { Pane, Code, Pill, Button, Text, IconButton, Icon, Heading, toaster, Small, Strong, Tooltip, UndoIcon, RedoIcon, FloppyDiskIcon, ChevronDownIcon, ChevronRightIcon, SelectionIcon, ComparisonIcon } from "evergreen-ui";

// prettier-ignore
import Foco from "react-foco";

// NOTE: This group of imports come from files that
// are created automatically from the hederis core project;
// if you need to make any edits, please edit in that repo 
// and then redistribute as appropriate.
// BEGINGROUP--------
import HedButton from "./shared/HedButton.js";
import ALL_TYPES from "./shared/types.json";
import srules from "./shared/structure.json";
// prettier-ignore
import { section_parse, editor_parse_content} from "./shared/section_parse.js";
// prettier-ignore
import { content_to_el } from "./shared/util";
// ENDGROUP--------

// prettier-ignore
import { hederis_type_to_user, SectionControl, InsertSectionAbove, InsertSectionBelow, InsertItemAbove, InsertItemBelow, ItemControl, ItemChangeTypeMenu, get_srule_kind, h_struct_option_helper } from "./structure_controls.js";
import { HtmlInlineEditor } from "./structure_html_inline.js";
import { ImagePicker } from "./structure_image_picker.js";
// prettier-ignore
import { html_add_attributes, el_add_attributes, create_placeholder_element, create_clone_element, create_placeholder_section } from "./structure_helpers.js"

const UI_COLORS = {
  bg: "#fff",
  blue: "rgb(16, 112, 202)",
  lightgreen: "rgb(162, 255, 202)",
  lightblue: "rgb(220,225,255)",
  lightgray: "rgb(228,231,235)",
  lightyellow: "rgb(228,231,135)",
  darkgray: "rgb(148,151,155)",
};

// kind of like a reducer
function Make_Operations(section_list, onStructureHtmlEdit, project) {
  return {
    sectionChangeType: (id, hederis_type) => {
      const index = section_list.findIndex(sec => sec.id === id);
      section_list[index]["hederis_type"] = hederis_type;
      const content = section_list[index]["content"];

      const rule = srules.sections[hederis_type] || srules.sections["default"];
      const standard = {
        "data-hederis-type": hederis_type,
        class: hederis_type,
      };
      const attrs = { ...rule.attributes, ...standard };
      section_list[index]["content"] = html_add_attributes(content, attrs);
      // console.log(section_list[index]["content"]);

      onStructureHtmlEdit(section_list);
    },
    sectionChangeTitle: (id, new_title) => {
      const index = section_list.findIndex(sec => sec.id === id);
      const content = section_list[index]["content"];
      const section = content_to_el(content).children[0];
      const elAttrs = {};
      [...section.attributes].map(({ name, value }) => {
        elAttrs[name] = value;
      });
      const standard = { title: new_title };
      const attrs = { ...elAttrs, ...standard };
      section_list[index]["content"] = html_add_attributes(content, attrs);
      section_list[index]["title"] = new_title;

      onStructureHtmlEdit(section_list);
    },
    // this one is used by the attribute popover
    sectionReplace: (id, content) => {
      const index = section_list.findIndex(sec => sec.id === id);
      // console.log("sectionReplace", id);
      section_list[index] = section_parse(content)[0];
      onStructureHtmlEdit(section_list);
    },
    sectionRemove: id => {
      onStructureHtmlEdit(section_list.filter(sec => sec.id !== id));
    },
    sectionMoveUp: id => {
      const index = section_list.findIndex(sec => sec.id === id);
      if (index != 0) {
        const new_index = index-1;
        section_list.splice(new_index, 0, section_list.splice(index, 1)[0]);
        onStructureHtmlEdit(section_list);
      }
    },
    sectionMoveDown: id => {
      const index = section_list.findIndex(sec => sec.id === id);
      if (index != (section_list.length-1)) {
        const new_index = index+1;
        section_list.splice(new_index, 0, section_list.splice(index, 1)[0]);
        onStructureHtmlEdit(section_list);
      }
    },
    sectionInsert: (id, hederis_type, direction) => {
      const index = section_list.findIndex(sec => sec.id === id) + direction;
      const section = create_placeholder_section(hederis_type, project);
      const res = [
        ...section_list.slice(0, index),
        section,
        ...section_list.slice(index, section_list.length),
      ];
      onStructureHtmlEdit(res);
    },
    elementDelete: (section_id, el_id) => {
      const index = section_list.findIndex(sec => sec.id === section_id);
      const content = section_list[index]["content"];
      const section = content_to_el(content).children[0];
      section.querySelector(`#${el_id}`).remove();
      section_list[index]["content"] = section.outerHTML;
      onStructureHtmlEdit(section_list);
    },
    elementDeleteWrapOnly: (section_id, el_id) => {
      const index = section_list.findIndex(sec => sec.id === section_id);
      const content = section_list[index]["content"];
      const section = content_to_el(content).children[0];
      const element = section.querySelector(`#${el_id}`);
      // console.log(element.children);
      element.replaceWith(...element.children);
      // section.querySelector(`#${el_id}`).remove();
      section_list[index]["content"] = section.outerHTML;
      onStructureHtmlEdit(section_list);
    },
    // hederis_type_or_frag will contain either the string for a hederis type OR
    // else a fragment from the split down inline command, which clones the parent and fills it with the selected text
    elementInsert: (section_id, el_id, hederis_type_or_frag, direction) => {
      const index = section_list.findIndex(sec => sec.id === section_id);
      const content = section_list[index]["content"];
      const section = content_to_el(content).children[0];
      const selectedEl = section.querySelector(`#${el_id}`);
      const referenceNode =
        direction === 0 ? selectedEl : selectedEl.nextSibling;
      const el =
        typeof hederis_type_or_frag === "string"
          ? create_placeholder_element(hederis_type_or_frag)
          : create_clone_element(selectedEl, hederis_type_or_frag);
      // if referenceNode is null it appends to the parent

      // console.log(el.outerHTML);
      selectedEl.parentNode.insertBefore(el, referenceNode);
      section_list[index]["content"] = section.outerHTML;
      onStructureHtmlEdit(section_list);
    },
    elementChangeType: (section_id, el_id, hederis_type) => {
      const index = section_list.findIndex(sec => sec.id === section_id);
      const content = section_list[index]["content"];
      // deals with completeletly different tag above element change type
      // and copying over the attributes of the original
      const rule =
        srules[get_srule_kind(hederis_type)][hederis_type] ||
        srules[get_srule_kind(hederis_type)]["default"];
      const standard = {
        "data-hederis-type": hederis_type,
        class: hederis_type,
      };
      // console.log(attrs);
      const section = content_to_el(content).children[0];
      const original_el = section.querySelector(`#${el_id}`);
      // console.log(original_el.outerHTML);
      const original_inner_html = original_el.innerHTML;
      const elAttrs = {};
      [...original_el.attributes].map(({ name, value }) => {
        if (name !== "role") {
          elAttrs[name] = value;
        }
      });
      // might want to adjust this order of presidence
      const attrs = { ...elAttrs, ...rule.attributes, ...standard };

      const html_string = `<${rule.element}></${rule.element}>`;
      const updated_el = el_add_attributes(
        content_to_el(html_string).children[0],
        attrs
      );
      updated_el.innerHTML = original_inner_html;
      // console.log(updated_el.outerHTML);
      original_el.replaceWith(updated_el);

      section_list[index]["content"] = section.outerHTML;
      onStructureHtmlEdit(section_list);
    },
    // this is for the attribute editor
    elementReplace: (section_id, el_id, changed_html) => {
      const index = section_list.findIndex(sec => sec.id === section_id);
      // console.log("elementReplace", section_id, el_id);
      const section = content_to_el(section_list[index]["content"]).children[0];
      const element = section.querySelector(`#${el_id}`);

      element.replaceWith(content_to_el(changed_html).children[0]);
      section_list[index]["content"] = section.outerHTML;
      onStructureHtmlEdit(section_list);
    },
    elementUpdateInnerHTML: (section_id, el_id, innerHTML) => {
      const index = section_list.findIndex(sec => sec.id === section_id);
      // console.log("elementUpdateInnerHTML", section_id, el_id, innerHTML);
      const content = section_list[index]["content"];
      const section = content_to_el(content).children[0];
      section.querySelector(`#${el_id}`).innerHTML = innerHTML;
      section_list[index]["content"] = section.outerHTML;
      onStructureHtmlEdit(section_list);
    },
    elementMove: (section_id, origin_id, destination_id) => {
      const index = section_list.findIndex(sec => sec.id === section_id);
      const section = content_to_el(section_list[index]["content"]).children[0];
      const origin_el = section.querySelector(`#${origin_id}`);
      const dest_el = section.querySelector(`#${destination_id}`);
      // do some checks to see if we're actually allowed to move
      // XXX - these doesn't prevent but don't deal with cross section moves, we have that in the handler for now
      if (origin_id === destination_id) {
        // console.log('nomove: same origin_id as destination_id:', origin_id)
        toaster.notify(`Not moving: origin is the same as destination`);
        return;
      }
      // deal with new child contains the parent error i.e. Failed to execute 'replaceWith' on 'Element': The new child element contains the parent.
      const does_origin_contain_parent = dest_el.closest(`#${origin_id}`);
      if (does_origin_contain_parent) {
        toaster.warning(`Not moving: origin contains destination`);
        return;
      }

      const dest_type = dest_el.parentNode.getAttribute("data-hederis-type");
      const parent_type = dest_el.parentNode.getAttribute("data-hederis-type");
      const hederis_type = origin_el.getAttribute("data-hederis-type");
      const parent_rule = srules.special_handling[parent_type];
      // console.log({origin_id, destination_id, hederis_type, parent_type, dest_type})
      // console.log(parent_rule)
      // do we have a special rule for the parent/dest ?
      if (parent_rule) {
        // what's allowed by the rule
        const allowed = h_struct_option_helper(
          parent_rule.allowed_children
        ).map(a => a[0]);
        // console.log(allowed)
        if (!allowed.includes(hederis_type)) {
          toaster.danger(
            `Sorry, you can't move a ${hederis_type} into a ${parent_type}`
          );
          return;
        }
      }
      // finally, if we are in fact eligible to move
      origin_el.remove();
      dest_el.replaceWith(origin_el, dest_el);
      // this additional check for actual changes prevents yet another case that should be ineligible - basically moving it to where it is already, nested
      if (section_list[index]["content"] !== section.outerHTML) {
        // finally actually write it
        section_list[index]["content"] = section.outerHTML;
        onStructureHtmlEdit(section_list);
      }
    },
  };
}

const MAX_UNDO_LENGTH = 15;
function createUndoStack(onStructureHtmlEdit, undos, redos) {
  const [undoStack, setUndoStack] = undos;
  const [redoStack, setRedoStack] = redos;
  // the function we return, which get's passed the updated section_list
  // and we must call onStructureHtmlEdit from it to update section_list in app_advanced.js
  return section_list => {
    const present = JSON.stringify(section_list);
    const over_max_adj = undoStack.length >= MAX_UNDO_LENGTH ? -1 : 0;
    // unshift the present, and, if over max, pop
    setUndoStack([
      present,
      ...undoStack.slice(0, undoStack.length + over_max_adj),
    ]);
    // because we're invalidating the future
    setRedoStack([]);
    onStructureHtmlEdit(section_list);
  };
}

function UndoPane(props) {
  const { section_list, onStructureHtmlEdit, undos, redos } = props;
  const [undoStack, setUndoStack] = undos;
  const [redoStack, setRedoStack] = redos;
  return (
    <Pane id="undo_pane">
      <HedButton
        iconBefore={UndoIcon}
        appearance={undos[0].length <= 1 ? "hedunfocus" : "hedsecondary"}
        disabled={undos[0].length <= 1}
        onClick={() => {
          onStructureHtmlEdit(JSON.parse(undoStack[1]));
          setRedoStack([undoStack[0], ...redoStack]);
          setUndoStack(undoStack.slice(1));
        }}
      >
        Undo
        {0 === undoStack.length - 1 ? (
          ``
        ) : (
          <Pill display="inline-flex" margin={4} color="green" appearance="subtle">
            {undoStack.length - 1}
          </Pill>
        )}
      </HedButton>
      <HedButton
        marginLeft={8}
        iconAfter={RedoIcon}
        appearance={redos[0].length < 1 ? "hedunfocus" : "hedsecondary"}
        disabled={redos[0].length < 1}
        onClick={() => {
          const [undoStack, setUndoStack] = undos;
          const [redoStack, setRedoStack] = redos;
          onStructureHtmlEdit(JSON.parse(redoStack[0]));
          setUndoStack([redoStack[0], ...undoStack]);
          setRedoStack(redoStack.slice(1));
        }}
      >
        Redo
        {0 === redoStack.length ? (
          ``
        ) : (
          <Pill display="inline-flex" margin={4} color="orange" appearance="subtle">
            {redoStack.length}
          </Pill>
        )}
      </HedButton>
    </Pane>
  );
}

export default function StructureEditorHistoryContainer(props) {
  const { section_list, onStructureHtmlEdit, project } = props;
  // sets our initial undo stack to contain the inital book state, so we have something to undo back to
  const undos = useState([JSON.stringify(section_list)]);
  const redos = useState([]);
  return <StructureEditorView {...props} undos={undos} redos={redos} />;
}

function MainStructureTopMenu(props) {
  const [parallelMode, setParallelMode] = useState(false);

  if (props.section_list.length === 0) {
    return (
      <Pane>
        <Heading>
          Please upload a <code>docx</code> file on the dashboard first!
        </Heading>
      </Pane>
    );
  }
  const onSaveClick = () => {
    props.onStructureHtmlSave();
  };

  const onParallelModeClick = () => {
    if (!props.docxHtml || props.docxHtml == "") {
      props.onFetchDocxHtml();
    }
    setParallelMode(!parallelMode);
    props.toggleParallelView();
  };

  return (
    <Pane
      flexDirection="row"
      display="flex"
      alignItems="center"
      position="sticky"
      top={0}
      zIndex={2}
      height="49px"
      paddingX={16}
      backgroundColor="#373550"
      justifyContent="space-between"
    >
      <Pane>
        <HedButton
          iconBefore={FloppyDiskIcon}
          appearance="hedprimary"
          onClick={onSaveClick}
        >
          Save Text
        </HedButton>
      </Pane>
      <Pane display="flex" flexDirection="row" justifyContent="flex-end">
        <UndoPane {...props} />
        {props.project.output.docx_html && (
          <Pane marginLeft={8}>
            <HedButton
              iconBefore={ComparisonIcon}
              appearance="hedprimary"
              onClick={onParallelModeClick}
            >
              {parallelMode ? "Hide" : "View"} Source File
            </HedButton>
          </Pane>
        )}
      </Pane>
    </Pane>
  );
}

function StructureEditorView(props) {
  const [selection, setSelection] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [parallelMode, setParallelMode] = useState(false);
  // const [htmlFileString, setHtmlFileString] = useState();
  const [docxHtml, setDocxHtml] = useState(false);
  const { section_list, onStructureHtmlEdit, undos, redos } = props;
  const { project } = props;
  const source = useRef();
  // console.log("StructureEditorView (inner)");
  const operations = Make_Operations(
    section_list,
    createUndoStack(onStructureHtmlEdit, undos, redos),
    project
  );

  const toggleParallelView = () => {
    setParallelMode(!parallelMode);
  }

  const jumpToSourceElement = (targetId, editorPos) => {
    const targetElement = document.querySelector('#sourceContent #'+targetId);
    if (targetElement) {
      const cPos = targetElement.getBoundingClientRect();
      source.current?.scrollTo({top: cPos.top-editorPos, behavior: 'smooth'});
    }
  };

  const onFetchDocxHtml = async () => {
    let myDocxHtml = await props.onFetchDocxHtml(props.project);
    console.log(myDocxHtml);
  }

// TO DO: Reconvert Word file if raw html is missing: docx_html
// TO DO: Support PDF files
  return (
    <Pane id="structure" marginBottom={128}>
      <MainStructureTopMenu {...props} toggleParallelView={toggleParallelView} docxHtml={docxHtml} onFetchDocxHtml={onFetchDocxHtml} />
      <Pane display="flex" flexDirection="row">
        <Pane paddingX={16} paddingTop="20px" width={parallelMode ? "50%" : "100%"} height={parallelMode ? "100vh" : "auto"} overflowY="scroll">
          {section_list.map((section, i) => {
            return (
              <SectionItem
                section={section}
                images={project.images}
                editMode={editMode}
                setEditMode={setEditMode}
                key={section.id}
                index={i}
                selection={selection}
                setSelection={setSelection}
                operations={operations}
                parallelMode={parallelMode}
                jumpToSourceElement={jumpToSourceElement}
              />
            );
          })}
        </Pane>
        {parallelMode && (
          <Pane 
            width="50%" 
            borderLeft="1px solid #E6E8F0"
            padding={16}
            backgroundColor="#f9f9f9"
            height="100vh"
            overflowY="scroll"
            ref={source}
          >
            <div className="innerSourceContent" id="sourceContent" dangerouslySetInnerHTML={{ __html: props.docx_html ? props.docx_html.replaceAll("data-style", "style") : "Loading..." }}></div>
          </Pane>
        )}
      </Pane>
    </Pane>
  );
}

function SectionItem(props) {
  // prettier-ignore
  const { section, images, index, selection, setSelection, operations, editMode, setEditMode } = props;
  // console.log(section);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isAbove, setIsAbove] = useState(false);
  const [isBelow, setIsBelow] = useState(false);

  // const [isExpanded, setIsExpanded] = useState(true);
  // XXX "baked_sect_html" vs "content" - punting for now
  const child_content = editor_parse_content(section["content"]);
  // console.log(child_content);
  const isSelected = selection === section.id;

  const onClick = () => {
    setSelection(section.id);
    setIsExpanded(!isExpanded);
  };

  const setHoveringAbove = hoverState => {
    setIsAbove(hoverState);
  }

  const setHoveringBelow = hoverState => {
    setIsBelow(hoverState);
  }

  return (
    <Pane>
      {isSelected && (
        <InsertSectionAbove section={section} operations={operations} setHoveringAbove={setHoveringAbove} />
      )}
      <Pane
        marginY={isSelected ? 4 : 16}
        backgroundColor={isSelected ? UI_COLORS.lightblue : UI_COLORS.bg}
        border
        borderColor={isSelected ? UI_COLORS.blue : UI_COLORS.darkgray}
        borderTopWidth={isAbove ? "3px" : "1px"}
        borderBottomWidth={isBelow ? "3px" : "1px"}
        borderTopColor={isAbove ? "#A020F0" : isSelected ? UI_COLORS.blue : UI_COLORS.darkgray}
        borderBottomColor={isBelow ? "#A020F0" : isSelected ? UI_COLORS.blue : UI_COLORS.darkgray}
      >
        <Pane
          flexDirection="row"
          display="flex"
          alignItems="center"
          position="sticky"
          top={props.parallelMode ? 0 : 50}
          zIndex={1}
          paddingY={8}
          backgroundColor={isSelected ? UI_COLORS.lightblue : UI_COLORS.bg}
        >
          <Pane
            onClick={onClick}
            flexDirection="row"
            display="flex"
            flex={1}
            cursor="pointer"
            alignItems="center"
            paddingLeft={4}
          >
            <IconButton
              icon={isExpanded ? ChevronDownIcon : ChevronRightIcon}
              intent={isExpanded ? "none" : "none"}
              marginRight={8}
              appearance="minimal"
              height={32}
            />
            <Icon
              icon={SelectionIcon}
              marginRight={8}
              size={16}
              color={isSelected ? "selected" : "muted"}
            />
            <Heading display="flex" flex={1} alignItems="center">
              <Text color="muted" marginRight={8} size={500}>
                Section {index + 1}:{" "}
              </Text>
              <Text textDecoration="underline" size={500}>
                {section.title}
              </Text>
            </Heading>
          </Pane>
          {isSelected ? (
            <SectionControl section={section} operations={operations} />
          ) : (
            <Text
              color="muted"
              marginRight={8}
              onClick={onClick}
              cursor="pointer"
            >
              {hederis_type_to_user(section.hederis_type)}
            </Text>
          )}
        </Pane>
        {isExpanded
          ? child_content.map((item, i) => {
              return (
                <GenericItem
                  item={item}
                  key={item.id}
                  section_id={section.id}
                  setIsExpanded={setIsExpanded}
                  level={1}
                  selection={selection}
                  setSelection={setSelection}
                  operations={operations}
                  editMode={editMode}
                  setEditMode={setEditMode}
                  parent_type={section.hederis_type}
                  images={props.images}
                  parallelMode={props.parallelMode}
                  jumpToSourceElement={props.jumpToSourceElement}
                />
              );
            })
          : ``}
      </Pane>
      {isSelected && (
        <InsertSectionBelow section={section} operations={operations} setHoveringBelow={setHoveringBelow} />
      )}
    </Pane>
  );
}

function GenericItem(props) {
  const { hederis_type } = props.item;
  const child_content = editor_parse_content(props.item["content"]);

  const contains_non_inlines = child_content.some(el => {
    return el.hederis_type && !el.hederis_type.startsWith(`hsp`);
  });

  if (hederis_type.startsWith(`hwpr`) || contains_non_inlines) {
    return <WrapperItem {...props} child_content={child_content} />;
  } else {
    return <ChildItem {...props} />;
  }
}

function WrapperItem(props) {
  const { item, setIsExpanded, child_content, selection, setSelection } = props;
  const { section_id, operations, editMode, setEditMode, parent_type } = props;
  const isSelected = selection === item.id;
  const { hederis_type } = item;

  const [isAbove, setIsAbove] = useState(false);
  const [isBelow, setIsBelow] = useState(false);

  const setHoveringAbove = hoverState => {
    setIsAbove(hoverState);
  }

  const setHoveringBelow = hoverState => {
    setIsBelow(hoverState);
  }
  // the margins below are what makes the wrapped items nest XXX
  return (
    <>
      {isSelected && (
        <InsertItemAbove 
          item={item}
          isSelected={isSelected}
          operations={operations}
          section_id={section_id}
          parent_type={parent_type}
          setHoveringAbove={setHoveringAbove}
        />
      )}
      <Pane
        border
        borderColor={isSelected ? UI_COLORS.blue : UI_COLORS.darkgray}
        marginX={8}
        marginY={4}
        backgroundColor={isSelected ? UI_COLORS.lightblue : "none"}
        borderTopWidth={isAbove ? "3px" : "1px"}
        borderBottomWidth={isBelow ? "3px" : "1px"}
        borderTopColor={isAbove ? "#A020F0" : isSelected ? UI_COLORS.blue : UI_COLORS.darkgray}
        borderBottomColor={isBelow ? "#A020F0" : isSelected ? UI_COLORS.blue : UI_COLORS.darkgray}
      >
        <ChildItem
          item={item}
          images={props.images}
          key={item.id}
          setIsExpanded={setIsExpanded}
          wrapperMode={true}
          level={props.level}
          selection={selection}
          setSelection={setSelection}
          section_id={section_id}
          operations={operations}
          parent_type={parent_type}
          parallelMode={props.parallelMode}
          jumpToSourceElement={props.jumpToSourceElement}
        />
        <Pane>
          {child_content.map((kid, i) => {
            return (
              <GenericItem
                item={kid}
                images={props.images}
                key={kid.id}
                level={props.level + 1}
                setIsExpanded={setIsExpanded}
                selection={selection}
                setSelection={setSelection}
                section_id={section_id}
                operations={operations}
                editMode={editMode}
                setEditMode={setEditMode}
                parent_type={hederis_type}
                parallelMode={props.parallelMode}
                jumpToSourceElement={props.jumpToSourceElement}
              />
            );
          })}
        </Pane>
      </Pane>
      {isSelected && (
        <InsertItemBelow 
          item={item}
          isSelected={isSelected}
          operations={operations}
          section_id={section_id}
          parent_type={parent_type}
          setHoveringBelow={setHoveringBelow}
        />
      )}
    </>
  );
}

function ChildItem(props) {
  const { item, wrapperMode, level, selection, setSelection } = props;
  const { section_id, operations, editMode, setEditMode, parent_type } = props;
  // for move
  const [isOver, setIsOver] = useState(false);
  const [isAbove, setIsAbove] = useState(false);
  const [isBelow, setIsBelow] = useState(false);

  // is this node selected ?
  const isSelected = selection === item.id;

  const backgroundColor = !isSelected
    ? "none"
    : !editMode
    ? UI_COLORS.lightblue
    : UI_COLORS.lightgreen;

  // console.log(parent_type);

  const onClick = () => {
    if (!isSelected) {
      setSelection(item.id);
    }
  };
  const onDragStart = e => {
    e.dataTransfer.effectAllowed = "move";
    // store the info we need in the event which we can access at the drop
    e.dataTransfer.setData("text/plain", `${section_id}/${item.id}`);
  };
  const onDragOver = e => {
    // XXX this allows the below onDrop event
    e.preventDefault();
  };
  const onDrop = e => {
    setIsOver(false);
    const [origin_section_id, origin_id] = e.dataTransfer
      .getData("text")
      .split("/");
    const destination_id = item.id;
    // prevent cross section movement for now XXX
    if (origin_section_id === section_id) {
      operations.elementMove(section_id, origin_id, destination_id);
    } else {
      alert("Sorry, no cross section moves allowed!");
    }
  };

  const setHoveringAbove = hoverState => {
    setIsAbove(hoverState);
  }

  const setHoveringBelow = hoverState => {
    setIsBelow(hoverState);
  }

  return (
    <>
      <Pane
        height={18}
        paddingX={8}
        borderWidth={1}
        borderColor={isOver ? "orange" : "none"}
        borderStyle={isOver ? "dotted" : "none"}
        backgroundColor={isOver ? UI_COLORS.lightyellow : "none"}
        onDragOver={onDragOver}
        onDrop={onDrop}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        onDragEnter={e => {
          setIsOver(true);
        }}
        onDragLeave={e => {
          setIsOver(false);
        }}
      >
        <Strong pointerEvents="none">
          {isOver ? `Drop & Move Here!` : ` `}
        </Strong>
        <Strong pointerEvents="none">
          {isOver ? `Drop & Move Here!` : ` `}
        </Strong>
      </Pane>
      {isSelected && !wrapperMode && (
        <InsertItemAbove 
          item={item}
          isSelected={isSelected}
          operations={operations}
          section_id={section_id}
          parent_type={parent_type}
          setHoveringAbove={setHoveringAbove}
        />
      )}
      <Pane
        draggable={isSelected && !editMode}
        onDragStart={onDragStart}
        display="flex"
        flexDirection="row"
        alignItems="center"
        backgroundColor={backgroundColor}
        paddingLeft={40}
        paddingY={16}
        position="relative"
        onClick={onClick}
        cursor={"pointer"}
        marginY={isSelected ? 0 : 0}
        borderTopWidth={isAbove ? "3px" : "0px"}
        borderTopStyle={isAbove ? "solid" : "none"}
        borderBottomWidth={isBelow ? "3px" : "0px"}
        borderBottomStyle={isBelow ? "solid" : "none"}
        borderColor="#A020F0"
      >
        <Pane display="flex" flexDirection={props.parallelMode ? "column" : "row"} alignItems={props.parallelMode ? "flex-start" : "center"}>
          <Pane display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">
            {props.parallelMode && (
              <Tooltip content="Jump to source paragraph →">
                <IconButton
                  icon={ComparisonIcon}
                  onClick={(e) => {
                    console.log(e.target.getBoundingClientRect());
                    props.jumpToSourceElement(item.id, e.target.getBoundingClientRect().top);
                  }}
                  height={24}
                  marginLeft={4}
                  marginRight={4}
                />
              </Tooltip>
            )}
            <Icon
              icon={SelectionIcon}
              marginRight={8}
              size={16}
              color={isSelected ? "selected" : "muted"}
              cursor="pointer"
            />
            <Pane
              width="15vw"
              display="flex"
              minWidth="200px"
              flexDirection="row"
              marginRight={8}
              cursor="pointer"
            >
              <ItemChangeTypeMenu
                item={item}
                wrapperMode={wrapperMode}
                isSelected={isSelected}
                operations={operations}
                section_id={section_id}
                parent_type={parent_type}
              />
              {/* just for debug */}
              {/*<Small>{parent_type ? `(${parent_type})` : ``}</Small>*/}
              {/*<Small>{ `@@${item.hederis_type}` : ``} </Small>*/}
            </Pane>
          </Pane>
          <Pane display="flex" flex={props.parallelMode ? 2 : 1} cursor="pointer" marginRight={16} maxWidth={props.parallelMode ? "none" : "650px"}>
            {item.hederis_type && item.hederis_type === "hblkimg" ? (
              <ImagePicker 
                item={item} 
                isSelected={isSelected} 
                operations={operations}
                section_id={section_id}
                setSelection={setSelection} 
                images={props.images} 
              />
            ) : (
              <HtmlInlineEditor
                item={item}
                isSelected={isSelected}
                operations={operations}
                section_id={section_id}
                editMode={editMode}
                setEditMode={setEditMode}
                setSelection={setSelection}
                wrapperMode={wrapperMode}
              />
            )}
          </Pane>
        </Pane>
        <ItemControl
          item={item}
          isSelected={isSelected}
          operations={operations}
          section_id={section_id}
          parent_type={parent_type}
        />
      </Pane>
      {isSelected && !wrapperMode && (
        <InsertItemBelow 
          item={item}
          isSelected={isSelected}
          operations={operations}
          section_id={section_id}
          parent_type={parent_type}
          setHoveringBelow={setHoveringBelow}
        />
      )}
    </>
  );
}
