import React from "react";
import { Pane, Text, Link, IconButton, Heading, toaster } from "evergreen-ui";
import { Route, Router } from "react-enroute";

import { ProjectInfoView, ProjectMetaView } from "../shared/info.js";

import ProjectSettingsView from "../shared/settings.js";
// import InviteAcceptor from "../shared/invite_acceptor.js";

import { NotFound } from "../shared/common.js";

import BasicDashboard from "./dash.js";

const AreaCompMap = {
  dash: BasicDashboard,
  settings: ProjectSettingsView,
  meta: ProjectMetaView,
};

export default class BasicApp extends React.Component {
  componentDidMount() {
    //   console.log("BasicApp mount");
    //   let area = get_loc();
    if (!this.props.params.area) {
      let { id } = this.props.params;
      console.log("redirecting main -> dash");
      let loc = `#/projects/${id}/dash`;
      let url = window.location.toString().split("#")[0];
      // using replace prevents this from going on the history stack, preventing a bug when the user hits back
      window.location.replace(`${url}${loc}`);
    }
  }
  render() {
    // basically a simple router
    let Component = AreaCompMap[this.props.params.area] || BasicDashboard;
    return <Component {...this.props} />;
  }
}
