import React from "react";
import ReactDOM from "react-dom";
import { useState, useEffect } from "react";
// import { Send_Email } from "../helpers/api";
import { firebase, db } from "../fire.js";
import APIClient from "../helpers/api_client";
const API = new APIClient(firebase);
// prettier-ignore
import { Pane, Text, Heading, Button, Small, Link, Paragraph, TextInput, TextInputField, Dialog, toaster, Spinner, Group, Checkbox } from "evergreen-ui";

import Unsubscribe from "./shared/unsubscribe.js";
import { LoadingPane } from "./shared/common.js";
import { validate_email } from "../helpers/util.js";
import TopNav from "./shared/top_nav.js";

function check_if_invalid_signup(email, pw, name) {
  if (!name || !name.length) {
    return "name";
  }
  if (!validate_email(email)) {
    return "email";
  }
  if (!pw || pw.length < 6) {
    return "password";
  }
  return false;
}
function check_if_invalid_signin(email, pw) {
  if (!validate_email(email)) {
    return "email";
  }
  if (!pw || pw.length < 6) {
    return "password";
  }
  return false;
}

const actionCodeSettings = {
  url: "https://app.hederis.com/",
};

// https://reactjs.org/docs/higher-order-components.html
export default function withAuth(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.onAuthStateChanged = this.onAuthStateChanged.bind(this);
      // not the most thorough check
      let has_invite = window.location.hash.includes("/invited/");
      let is_unsubscribe = window.location.pathname.startsWith("/unsubscribe");
      this.state = {
        auth_loading: true,
        has_invite,
        is_unsubscribe,
        show_email_login: false,
        signup_mode: false,
        signin_mode: true,
        code_mode: false,
        accepted_terms: false,
      };

      // check if the user is logged in and trigger this.onAuthStateChanged
      firebase.auth().onAuthStateChanged(this.onAuthStateChanged);
    }
    async checkAuthedUser(auth_user) {
      let auth_loading = false;
      if (!auth_user["emailVerified"]) {
        toaster.warning(
          "You need to verify your email address by clicking on the link we sent",
          { duration: 25 }
        );
        this.setState({ auth_loading, needs_email_verify: true });
        // and then get out of here!
        return;
      }
      // if their email is verified
      // what groups are they in?
      let groups = await get_groups_by_invite(auth_user.uid);
      // and which do they own
      let owned_groups = await get_groups_by_owner(auth_user.uid);
      // for now, create or update a user in our user collection based, on the auth user_options
      // a better way to do this would be use google cloud functions to do it on auth user create, we'll want to send emails and such there too
      let hederis_user = await get_hederis_user(auth_user.uid);
      if (!hederis_user) {
        hederis_user = await create_hederis_user(auth_user);
      }
      if (process.env.NODE_ENV === "production") {
        heap.identify(hederis_user.uid);
        heap.addUserProperties({"email": hederis_user.email});
      }
      // great,  set state to show our actual app
      this.setState({
        auth_user,
        auth_loading,
        groups,
        hederis_user,
        owned_groups,
      });
    }
    // triggered by page load, or by result of signInWithPopup from user
    async onAuthStateChanged(auth_user) {
      // console.log("onAuthStateChanged", !!auth_user);
      // are they logged in?
      if (auth_user) {
        this.checkAuthedUser(auth_user);
      } else {
        // done loading, they are not logged in
        this.setState({ auth_loading: false });
      }
    }
    // trigged by the user clicking sign in button
    signInWithEmail(email, password) {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .catch(error => {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          if (errorCode == "auth/user-not-found") {
            //prettier-ignore
            toaster.danger("An account with that email does not exist or the password does not match!",  { duration: 30, description:   "Please try again or create an account by signing up.",});
          } else {
            toaster.danger(errorMessage, { duration: 30 });
          }
        })
        .then((a, b) => {
          console.log("done with signing in with email and pw");
        });
    }
    createUserWithEmailAndPassword(email, password, name) {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .catch(function(error) {
          // console.log("catch in create");
          // Handle Errors here.
          console.log(error.code);
          if (error.code == "auth/email-already-in-use") {
            toaster.warning(
              "It looks like you're already using this email address with a different account. Try logging in with google instead.",
              { duration: 20 }
            );
          } else {
            toaster.warning(error.message, { duration: 20 });
          }
        })
        .then(a => {
          // console.log("done with create", a);
          var user = firebase.auth().currentUser;
          user.updateProfile({
            displayName: name,
            // photoURL: "https://example.com/jane-q-user/profile.jpg",
          });
          // .then(function() {
          //   console.log("update success");
          //   // Update successful.
          // })
          // .catch(function(error) {
          //   console.log(error);
          //   // An error happened.
          // });
          user
            .sendEmailVerification(actionCodeSettings)
            .then(function() {
              toaster.notify(
                "Email verification sent! Please check your inbox and follow that link to finish signing up!",
                { duration: 60 }
              );
              // Email sent.
            })
            .catch(function(error) {
              console.log(error);
              // An error happened.
            });
        });
    }
    // trigged by the user
    signInWithPopup() {
      let provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(function(result) {
          // console.log("result", result);
          // This gives you a Google Access Token. You can use it to access the Google API.
          var token = result.credential.accessToken;
          // The signed-in user info.
          var user = result.user;
          // this.setState({auth_user:})
          // ...
        })
        .catch(function(error) {
          console.log("auth error", error);
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          // The email of the user's account used.
          var email = error.email;
          // The firebase.auth.AuthCredential type that was used.
          var credential = error.credential;
          // ...
        });
    }

    // check for a valid signup code
    async checkForValidCode(code) {
      let doc = await db
        .collection("signup_codes")
        .doc(code)
        .get();
      if (doc.data() != undefined) {
        this.setState({ signup_mode: false, code_mode: true, signin_mode: false })
      } else {
        toaster.notify(
          "Sorry, that code isn't valid",
          { duration: 60 }
        );
      }
      return doc.data();
    }
    // check for a valid signup code
    async addToWaitlist(email) {
      if (!validate_email(email)) {
        toaster.notify(
          "Please enter a valid email address",
          { duration: 30 }
        );
      } else {
        let timestamp = firebase.firestore.Timestamp.fromDate(new Date());
        let waitlist_data = { email, timestamp };
        let doc = await db
          .collection("waitlist")
          .doc(email)
          .set(waitlist_data);
        toaster.notify(
          "You've been added to our waitlist!",
          { duration: 30 }
        );
        return;
      }
    }

    render() {
      if (this.state.is_unsubscribe) {
        return <Unsubscribe />;
      } else if (!this.state.auth_loading && !this.state.auth_user) {
        if (this.state.needs_email_verify) {
          let { email } = firebase.auth().currentUser;
          return <NeedsEmailVerify email={email} />;
        }
        let mode_text = this.state.signup_mode ? "Up" : "In";
        return (
          <div>
            <TopNav />
            <Pane
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="row"
              paddingTop={96}
            >
              <Pane
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                width={600}
                textAlign="center"
                marginRight={32}
              >
                <Heading size={800}>Powerful book-making tools <br />are just a click away...</Heading>
                <div id="sign-in-hero"></div>
              </Pane>
              <Pane
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <Pane
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                  height={400}
                >
                  <Heading size={600} marginBottom={16}>
                    To get started
                    {this.state.has_invite
                      ? " and accept the invitation to collaborate"
                      : ""}
                    , sign in or sign up below.
                  </Heading>

                  <Paragraph marginBottom={32}>No credit card required, and your first project is free :)</Paragraph>

                  <Pane display="flex" flexDirection="row" marginBottom={32}>
                    <Button 
                      appearance={this.state.signin_mode ? "default" : "minimal"} 
                      onClick={e => this.setState({ signin_mode: true, signup_mode: false, code_mode: false })}
                    >
                      Sign In
                    </Button>
                    <Button 
                      appearance={this.state.signup_mode || this.state.code_mode ? "default" : "minimal"} 
                      onClick={e => this.state.has_invite ? this.setState({ signin_mode: false, signup_mode: false, code_mode: true }) : this.setState({ signin_mode: false, code_mode: false, signup_mode: true })}
                    >
                      Sign Up
                    </Button>
                  </Pane>

                  {this.state.signup_mode && (
                    <CodeLogin checkForValidCode={this.checkForValidCode.bind(this)} addToWaitlist={this.addToWaitlist.bind(this)} />
                  )}

                  {this.state.code_mode && (
                    <Pane
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="center"
                      marginTop={0}
                      marginBottom={16}
                    >
                      <Checkbox
                        label=""
                        checked={this.state.accepted_terms}
                        onChange={e => this.setState({ accepted_terms: e.target.checked })}
                      />
                      <Text marginLeft={8}>
                        I agree to the{" "}
                        <Link
                          color="neutral"
                          size={400}
                          href="https://www.hederis.com/terms.html"
                          target="_blank"
                        >
                          Terms of Service
                        </Link>{" "}
                        &{" "}
                        <Link
                          size={400}
                          color="neutral"
                          href="https://www.hederis.com/privacy.html"
                          target="_blank"
                        >
                          Privacy Policy
                        </Link>
                      </Text>
                    </Pane>
                  )}

                  {this.state.signin_mode && (
                    <Pane display="flex" flexDirection="column">
                      <Button
                        height={48}
                        appearance="primary"
                        onClick={this.signInWithPopup.bind(this)}
                        id="GoogleSigninorup"
                      >
                        Sign In with Google
                      </Button>
                      <Button
                        marginTop={24}
                        height={48}
                        onClick={() => this.setState({ show_email_login: true })}
                        id="EmailSignin"
                      >
                        Sign In with Email
                      </Button>
                    </Pane>
                  )}

                  {this.state.code_mode && (
                    <Pane display="flex" flexDirection="column">
                      <Button
                        height={48}
                        appearance="primary"
                        disabled={!this.state.accepted_terms}
                        onClick={this.signInWithPopup.bind(this)}
                        id="GoogleSigninorup"
                      >
                        Sign Up with Google
                      </Button>
                      <Button
                        marginTop={24}
                        height={48}
                        disabled={!this.state.accepted_terms}
                        onClick={() => this.setState({ show_email_login: "signup" })}
                        id="EmailSignup"
                      >
                        Sign Up with Email
                      </Button>
                    </Pane>
                  )}
                  
                  <EmailLogin
                    showEmailLogin={this.state.show_email_login}
                    createUserWithEmailAndPassword={this.createUserWithEmailAndPassword.bind(
                      this
                    )}
                    signInWithEmail={this.signInWithEmail.bind(this)}
                    resetShowEmail={() =>
                      this.setState({ show_email_login: false })
                    }
                  />
                </Pane>
              </Pane>
            </Pane>
          </div>
        );
      } else if (this.state.auth_user) {
        return <WrappedComponent {...this.state} />;
      } else {
        // prettier-ignore
        return (<Pane display="flex" justifyContent="center" alignItems="center" height="70vh" ><Spinner /></Pane>
        );
      }
    }
  };
} // end withAuth

function CodeLogin(props) {
  let [code, setCode] = useState("");
  let [waitlist, setWaitlist] = useState("");
  return (
    <Pane
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      marginTop={0}
      marginBottom={16}
    >
      <Paragraph>Hederis is currently invitation-only.</Paragraph>
      <Pane 
        display="flex" 
        flexDirection="row" 
        alignItems="flex-end" 
        marginTop={16} 
        marginBottom={16} 
        border="3px solid #5D597A" 
        borderRadius="5px" 
        padding={8}
      >
        <TextInputField
          label="Enter your code:"
          value={code}
          placeholder="1234abcd"
          onChange={e => setCode(e.target.value)}
          marginBottom={0}
        />
        <Button onClick={() => props.checkForValidCode(code)}>Sign Up</Button>
      </Pane>
      <Paragraph>No code? Enter your email to join the waitlist:</Paragraph>
      <Pane 
        display="flex" 
        flexDirection="row" 
        alignItems="flex-end" 
        marginTop={0} 
        marginBottom={16} 
        padding={0}
      >
        <TextInput
          value={waitlist}
          placeholder="Your email address"
          onChange={e => setWaitlist(e.target.value)}
          marginTop={0}
          marginBottom={0}
        />
        <Button onClick={() => props.addToWaitlist(waitlist)}>Send</Button>
      </Pane>
    </Pane>
  );
}

function resendVerificationEmail() {
  let user = firebase.auth().currentUser;
  user.sendEmailVerification(actionCodeSettings).then(function() {
    toaster.notify(
      "Email verification sent! Please check your inbox and follow that link to finish signing up!",
      { duration: 60 }
    );
  });
}

// The email login / log up component
function EmailLogin(props) {
  let is_signup = props.showEmailLogin == "signup";
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [name, setName] = useState("");
  return (
    <Dialog
      isShown={!!props.showEmailLogin}
      title={is_signup ? "Create Account" : "Login With Email"}
      confirmLabel={is_signup ? "Create Account" : "Login"}
      containerProps={{"id": is_signup ? "CreateDialog" : "SigninDialog"}}
      onCloseComplete={() => {
        props.resetShowEmail();
        // console.log("and closed");
      }}
      onConfirm={close => {
        // todo validation, min 6 char pw,
        if (is_signup) {
          let is_valid = check_if_invalid_signup(email, password, name);
          if (!is_valid) {
            // props.signUpWithEmail(email, password, name);
            props.createUserWithEmailAndPassword(email, password, name);
          } else {
            toaster.warning(
              `There's something wrong with your signup form. Check the ${is_valid} field`
            );
          }
        } else {
          props.signInWithEmail(email, password);
        }
        // console.log("confirm", email, password);
      }}
    >
      {is_signup ? (
        <TextInputField
          label="Name"
          value={name}
          required
          placeholder="Your name"
          onChange={e => setName(e.target.value)}
        />
      ) : (
        ``
      )}
      <TextInputField
        label="Email address"
        value={email}
        required
        placeholder="your@email.com"
        hint={
          is_signup
            ? "You'll need to verify this by clicking a link sent to this address to finish signing up"
            : ""
        }
        onChange={e => setEmail(e.target.value)}
      />
      <TextInputField
        label="Password"
        value={password}
        required
        type="password"
        hint={is_signup ? "At least 6 characters" : ""}
        onChange={e => setPassword(e.target.value)}
        placeholder="Your password"
      />
    </Dialog>
  );
}

function NeedsEmailVerify(props) {
  return (
    <div>
      <TopNav />
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        height={400}
      >
        <Heading size={800} marginBottom={20}>
          Finish Signing Up
        </Heading>
        <Paragraph>
          It looks like you need to verify your email address by clicking on the
          link we sent to finish signing up.
        </Paragraph>
        <Button
          appearance="primary"
          marginTop={32}
          onClick={() => {
            // this.forceUpdate();
            window.location.reload(false);
          }}
        >
          I've Clicked the Verification Link
        </Button>
        <Button marginTop={32} onClick={resendVerificationEmail}>
          Resend Verification Email to {props.email}
        </Button>
      </Pane>
    </div>
  );
}

// for possible use in checkAuthedUser
// hacky, just refresh the page so we're basically polling
// setTimeout(async () => {
//   await firebase.auth().currentUser.reload();
//   toaster.closeAll();
//   firebase.auth().onAuthStateChanged(this.onAuthStateChanged);
// }, 5 * 1000);

// helpers for above
async function get_hederis_user(uid) {
  let doc = await db
    .collection("users")
    .doc(uid)
    .get();
  if (doc.exists) {
    return doc.data();
  } else {
    return false;
  }
}

async function create_hederis_user(auth_user) {
  // console.log("create_hederis_user");
  toaster.notify("Setting up your account...");
  let { uid, email, displayName, photoURL } = auth_user;
  // send the welcome email!
  // Send_Email("welcome", email, { displayName });
  await API.send_email("welcome", email, { displayName });

  await API.set_default_claim();
  console.log("done with set claim!");
  let created = firebase.firestore.FieldValue.serverTimestamp();
  let hederis_user = { uid, email, displayName, photoURL, created };
  await db
    .collection("users")
    .doc(uid)
    .set(hederis_user, { merge: true });
  return hederis_user;
}

async function get_groups_by_invite(uid) {
  let querySnapshot = await db
    .collection("invites")
    .where("kind", "==", "group_access")
    .where("to_uid", "==", uid)
    .get();
  let groups = [];
  // console.log(groups);
  querySnapshot.forEach(doc => {
    let doc_data = doc.data();
    // the group id
    groups.push(doc_data["target"]);
    // groups.push(doc.id);
    // console.log();
  });
  // console.log(groups);
  return groups;
}

async function get_groups_by_owner(uid) {
  let querySnapshot = await db
    .collection("groups")
    .where("owners", "array-contains", uid)
    .get();
  let groups = [];
  querySnapshot.forEach(doc => {
    groups.push(doc.id);
  });
  return groups;
}
