// NOTE: Functions from this file are shared in other repos; 
// If you make updates here, be sure to run `npm run distro-shared` 
// which will distribute your changes as appropriate 
// (be sure to clone any missing repos as prompted).

import ALL_TYPES from "./types.json";

const { asyncForEach } = require("./async.js");

// just a helper to turn a query snapshot from fb into an array
export function get_arr(qs, include_id = true) {
  let res = [];
  qs.forEach(doc => {
    let p = doc.data();
    if (include_id) {
      p["id"] = doc.id;
    }
    res.push(p);
  });
  return res;
}

for (let [k, v] of Object.entries(ALL_TYPES)) {
  ALL_TYPES[k] = v.replace("HED ", "");
}

export function get_human_h_type(h_type) {
  return ALL_TYPES[h_type];
}
// hacky helper for making more human readable labels for adv template builder
export function get_label_from_key(input_key) {
  let overrides = {
    hyphens_minLeft: "Min characters before hyphen",
    hyphens_minRight: "Min characters after hyphen",
    hyphens_exceptions: "Custom hyphenation patterns",
    margins: "Space Around",
    padding: "Space Inside"
  };
  let label = input_key.split("_").slice(-2);
  if (overrides.hasOwnProperty(input_key)) {
    label = overrides[input_key];
  } else {
    if (label[0] == "body") {
      label.shift();
    }
    if (ALL_TYPES[label[0]]) {
      label[0] = ALL_TYPES[label[0]];
    }
    if (
      overrides.hasOwnProperty(label[label.length-1]) &&
      !input_key.endsWith("verso_margins") && 
      !input_key.endsWith("recto_margins")
    ) {
      let overrideKey = label.pop();
      label.push(overrides[overrideKey]);
    }
    // console.log(label[0]);
    label = label.join(" ");
    // label = label.replace("hblk", "");
    // label = label.replace("chaptitle", "Chapter Title"); // ??
    label = label.replace("SECT", "Section");

    // and then for the dashed ones
    label = camelCaseToDash(label)
      .split("-")
      .join(" ");
    label = label.replace("toc", "TOC");
  }
  return label;
}

export function get_helper_text(input_key) {
  let vals = {
    hyphens_exceptions: "Separate patterns with a comma"
  }
  if (vals.hasOwnProperty(input_key)) {
    return vals[input_key];
  } else {
    return undefined;
  }
}

export function get_subselector_label(sel_path, sel_mode, h_type, parent_h_type, prev_sibling_h_type) {
  let sp_len = sel_path.length;
  let human_h_type = ALL_TYPES[h_type];
  let parent_human_h_type = ALL_TYPES[parent_h_type];
  let prev_human_h_type = ALL_TYPES[prev_sibling_h_type];
  switch(sel_mode) {
    case "only":
      return `Only this ${human_h_type}`;
    case "within_parent":
      return `${human_h_type}s anywhere inside ${parent_human_h_type}`;
    case "direct_child":
      return `${human_h_type}s directly inside ${parent_human_h_type}`;
    case "first_within_parent":
      return `First ${human_h_type}s inside ${parent_human_h_type}`;
    case "following":
      return `${human_h_type}s following ${prev_human_h_type}`;
    default:
      return sp_len < 1 ? "All" : `All ${human_h_type}s`;
  }
}

// for the actual template name
export function human_adv_template_label(fname) {
  let label =
    fname === "config.json"
      ? "initial"
      : fname.replace(/_/g, " ").replace(".json", "");
  // redundant?
  label += " template";
  return label;
}

// let regex_to_increment_ = /_\d*$/;
// this just appends the datetime in ms to the end of the filename while keeping the extension
export function get_unique_filename(filename) {
  let d = +new Date();
  let parts = filename.split(".");
  let ext = parts.pop();
  let last = parts.pop();
  last += "_" + d;
  parts.push(last);
  parts.push(ext);
  return parts.join(".");
}

export function split_filename_from_ext(filename) {
  let parts = filename.split(".");
  let ext = parts.pop();
  let last = parts.pop();
  parts.push(last);
  // parts.push(ext);
  return [parts.join("."), ext];
}

export function parseQueryString(queryString) {
  var query = {};
  var pairs = (queryString[0] === "?"
    ? queryString.substr(1)
    : queryString
  ).split("&");
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  }
  return query;
}

export function make_arr(arr) {
  return Array.prototype.slice.call(arr);
}

export function content_to_el(content) {
  let frag = document.createDocumentFragment();
  // we can't set innerHTML directly on a document frag, hence the wrap
  let wrap = document.createElement("div");
  wrap.id = "wrap";
  wrap.innerHTML = content;
  frag.appendChild(wrap);
  return frag.getElementById("wrap");
}

export function get_title_from_el(el) {
  // for now, just grab the first two el and get their text
  let first = el.children[0]["textContent"];
  let second = el.children.length > 1 ? el.children[1]["textContent"] : "";
  let title = `${first} ${second}`;
  // with a fallback, just get the first 50 char, removing extra whitespace
  // prettier-ignore
  return title ? title.slice(0, 50) :   el.textContent.replace(/\n/g, "").split(" ").filter(x => x.length).join(" ").slice(0, 50);
}

export function get_flat_nodes(content) {
  let el = content_to_el(content);
  return make_arr(el.children);
}

// xxx turns out this is actually slower, but this may be useful for storage
// we don't need to represent the entire dom node, this is lighter weight
export function get_simple_node(node) {
  // console.log("get_simple_node");
  let dataset = Object.assign({}, node.dataset);
  let { nodeName, className, id } = node;
  return { nodeName, className, id, dataset };
}
// same as above, but with innerHTML
export function get_simple_node_with_html(node) {
  console.log("get_simple_node_with_html");
  let dataset = Object.assign({}, node.dataset);
  let { nodeName, className, id, innerHTML } = node;
  return { nodeName, className, id, dataset, innerHTML };
}

export function is_between(x, a, b) {
  if (a === false || b === false) {
    return false;
  }
  return (x >= a && x <= b) || (x >= b && x <= a);
}

export class Debouncer {
  constructor() {
    this.lookup = {};
  }
  cancel(identifier) {
    let timer_id = this.lookup[identifier];
    if (timer_id) {
      clearTimeout(timer_id);
      delete this.lookup[timer_id];
    }
  }
  set(identifier, ms, cb) {
    this.cancel(identifier);
    let new_timer_id = setTimeout(cb, ms);
    this.lookup[identifier] = new_timer_id;
  }
}

export function slugify(text, retain_dots = false) {
  let reg_ex = retain_dots ? /[^\w\s-\.]/g : /[^\w\s-]/g;
  return text
    .toString()
    .toLowerCase()
    .trim()
    .replace(reg_ex, "") // except '.' depending on retain_dots, remove non-word [a-z0-9_], non-whitespace, non-hyphen characters
    .replace(/[\s_-]+/g, "_") // swap any length of whitespace, underscore, hyphen characters with a single _
    .replace(/^-+|-+$/g, ""); // remove leading, trailing -
}

export function writeStylesDoc(doc, styleName, cssText) {
  var styleElement = doc.getElementById(styleName);
  if (styleElement) {
    doc.getElementsByTagName("head")[0].removeChild(styleElement);
  }
  styleElement = doc.createElement("style");
  styleElement.type = "text/css";
  styleElement.id = styleName;
  styleElement.innerHTML = cssText;
  doc.getElementsByTagName("head")[0].appendChild(styleElement);
}

export function camelCaseToDash(str) {
  return str.replace(/([a-z])(?=[A-Z])/g, "$1-").toLowerCase();
}
export function dashToCamelCase(str) {
  return str.replace(/-([a-z])/g, function(g) {
    return g[1].toUpperCase();
  });
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
// let blacklist = ["ref"];
// XXX eventually we'll want to use the splits info, but for now this is ok
// because otherwise it breaks selection persistence -  pagedjs generates them on the fly each time
// let blacklist = ["ref", "splitTo", "splitFrom"];

// switching to whitelist
let whitelist = ["hederisType", "id"];

export function get_dataset_sel(p) {
  let result = ``;
  // prettier-ignore
  if (!p.dataset) { return result }
  for (let [k, v] of Object.entries(p.dataset)) {
    if (whitelist.includes(k)) {
      // if (!blacklist.includes(k)) {
      let key_dashed = camelCaseToDash(k);
      result += `[data-${key_dashed}="${v}"]`;
    }
  }
  return result;
}

export function get_node_obj_sel(p) {
  // prettier-ignore
  let normalized;
  if (p.className) {
    normalized = p.className
      .replace(/\bcontinued\b/g, " ")
      .replace(/\bcontinuation\b/g, " ")
      .replace(/\bbaked\b/g, " ")
      .replace(/\s\s/g, " ")
      .trim();
  }
  return `${p.nodeName.toLowerCase()}${
    p.className ? "." + normalized.split(" ").join(".") : ""
  }${get_dataset_sel(p)}`;
}

export function range(start, stop, step) {
  if (typeof stop == "undefined") {
    // one param defined
    stop = start;
    start = 0;
  }

  if (typeof step == "undefined") {
    step = 1;
  }

  if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
    return [];
  }

  var result = [];
  for (var i = start; step > 0 ? i < stop : i > stop; i += step) {
    result.push(i);
  }

  return result;
}

// a pretty basic one
// https://stackoverflow.com/a/1373724/83859
export function validate_email(email) {
  var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return re.test(String(email).toLowerCase());
}

export function download_file(filename, data) {
  // let json = JSON.stringify(data);

  let blob = filename.endsWith("html")
    ? new Blob([data], { type: "text/html" })
    : new Blob([data]);
  let download_link = document.createElement("a");
  download_link.download = `${filename}`;
  let url = URL.createObjectURL(blob);
  download_link.href = url;
  document.body.appendChild(download_link);
  download_link.click();

  setTimeout(function() {
    document.body.removeChild(download_link);
    window.URL.revokeObjectURL(url);
  }, 100);
}

export function parse_dim(dim_string) {
  // console.log(dim_string);
  let cap = /(-?\d+\.?\d*)/g.exec(dim_string);
  if (dim_string === "auto") {
    let unit = "auto";
    let numeric_value = 0;
    return { numeric_value, unit }
  } else if (cap) {
    let value = cap[0];
    let unit = dim_string.substr(value.length);
    let numeric_value = parseFloat(value);
    return { numeric_value, unit };
  } else {
    console.log("dimension parsing error for", dim_string);
    return { numeric_value: null, unit: null };
    // return { numeric_value: 0, unit: "px" };
  }
}

// download_html_file("z.html", `<b>zzzz</b>`);

// https://gist.github.com/darrenmothersele/7cd24da0f35d450babd4745c7f208acf#file-random2-js
export function get_random_string(len = 40) {
  const validChars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let array = new Uint8Array(len);
  // ie 10 may not like this XXX, node doesn't have it
  window.crypto.getRandomValues(array);
  array = array.map(x => validChars.charCodeAt(x % validChars.length));
  const randomState = String.fromCharCode.apply(null, array);
  return randomState;
}

// started from https://stackoverflow.com/a/5624139
function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}
export function rgbToHex(r, g, b) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}
export function rgbStringToHex(rgb_string) {
  let [r, g, b] = rgb_string.slice(4, -1).split(",");
  return rgbToHex(parseInt(r), parseInt(g), parseInt(b));
  // console.log(r, g, b);
}

// test it with round trip
// console.log(hexToRgbString(rgbStringToHex("rgb(233, 233, 3)")));

export function hexToRgbString(hex) {
  let obj = hexToRgb(hex);
  // prettier-ignore
  if (!obj){ return obj }
  let { r, g, b } = obj;
  return `rgb(${r}, ${g}, ${b})`;
}
export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export function parse_hederis_type(sel_string) {
  let cap = /\[data-hederis-type="(.*?)"/g.exec(sel_string);
  if (cap) {
    // console.log(cap[1]);
    return cap[1];
  } else {
    return false;
  }
}
// Note that data-id is added by paged.js,
// based on whatever the source element id is.
export function parse_id(sel_string) {
  let cap = /data-id="(\S*?)"/g.exec(sel_string);
  if (cap) {
    // console.log(cap[1]);
    return cap[1];
  } else {
    return false;
  }
}
// console.log(parse_id(`p#pun7ZuBEy.hblkau[data-hederis-type="hblkau"]`));

export async function create_img_url_cache(project_images, storage) {
  let image_lookup = {};
  await asyncForEach(project_images || [], async img_src => {
    let filename = img_src.split("/").pop();
    let replacement_src = await storage.ref(img_src).getDownloadURL();
    // console.log(replacement_src);
    image_lookup[filename] = replacement_src;
  });

  return image_lookup;
}

export function track_change(selection_path, section, epubMode, locked, user) {
  let obj = {};
  try {
    // create frag of el?
    if (selection_path.length > 1) {
      let id_match = selection_path[0].match(/(data-id=")([-_a-zA-Z0-9]+)/);
      let parent_match = selection_path[1].match(/(data-id=")([-_a-zA-Z0-9]+)/);
      if (id_match && id_match.length > 2 && parent_match && parent_match.length > 2) {
        obj['id'] = selection_path[0].match(/(data-id=")([-_a-zA-Z0-9]+)/)[2];
        obj['parent'] = selection_path[1].match(/(data-id=")([-_a-zA-Z0-9]+)/)[2];
        obj['section'] = section;
        obj['flat'] = {};
        obj['flat']['status'] = "accepted";
        obj['locked'] = {};
        obj['unlocked'] = {};
        if (epubMode) {
          obj['locked']['status'] = "pending";
          obj['unlocked']['status'] = "applied";
          obj['source'] = "unlocked";
        } else {
          obj['locked']['status'] = locked ? "applied" : "pending";
          obj['unlocked']['status'] = locked ? "accepted" : "applied";
          obj['source'] = locked ? "locked" : "unlocked";
        }
        obj['user'] = user;
      }
    }
  } catch (error) {
    console.log(error);
  }
  return obj;
}

function textNodesUnder(el) {
  var n, a=[], walk=document.createTreeWalker(el,NodeFilter.SHOW_TEXT,null,false);
  while(n=walk.nextNode()) a.push(n);
  return a;
}

export function apply_changes(html_content, baked_html, changes) {
  let unlocked_html = content_to_el(html_content);
  let locked_html = content_to_el(baked_html);
  let changes_made = [];
  let changes_not_made = [];
  let unlocked_inner = undefined;
  let locked_inner = undefined;

  console.log(changes.length);
  console.log(changes);

  if (changes.length > 0) {

    changes.map(obj => {
      // get the source
      let src_html = unlocked_html;
      let dest_html = locked_html;
      let status = obj["locked"]["status"];

      if (obj["source"] === "locked") {
        src_html = locked_html;
        dest_html = unlocked_html;
        status = obj["unlocked"]["status"];
      }

      if (status === "accepted") {
        // select the element in the source html
        let src_el = src_html.querySelector("#" + obj["parent"] + " #" + obj["id"]);
        console.log(src_el);
        // select the element in the dest html
        let dest_el = dest_html.querySelector("#" + obj["parent"] + " #" + obj["id"]);

        if (src_el && dest_el) {
          // update our change object for roll-back purposes
          obj["src_html"] = src_el.innerHTML;
          obj["dest_html"] = dest_el.innerHTML;
          // replace el contents in dest with revised source
          dest_el.innerHTML = src_el.innerHTML;

          // if source = baked, remove lines from the destination
          if (obj["source"] === "locked") {
            var lines = dest_el.querySelectorAll("span.line");
            // remove lines from baked HTML
            if (lines.length > 0) {
              Array.from(lines).forEach(el => {

                // remove hard-coded hyphens
                if (el.textContent.match("‑$")) {
                  // find the last text node
                  var allText = textNodesUnder(el).reverse();

                  // replace the hyphen
                  if (allText.length > 0) {
                    var lc = allText[allText.length-1];
                    lc.textContent = lc.textContent.replace(/‑$/,"");
                  }
                }
                el.replaceWith(...el.childNodes);
              });
            }
          }
        }
        changes_made.push(obj);
      } else if (status === "rejected") {
        changes_made.push(obj);
      } else {
        changes_not_made.push(obj);
      }
    });
    unlocked_inner = unlocked_html.innerHTML;
    locked_inner = locked_html.innerHTML;
  }

  return [unlocked_inner, locked_inner, changes_made, changes_not_made];
}

// https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
export async function digestMessage(message) {
  const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, "0")).join(""); // convert bytes to hex string
  return hashHex.toString()
}

export function sanitizeHtml(html_content) {
  return html_content
    .replace(/class=""\s?/g,"")
    .replace(/style=""\s?/g,"")
    .replace(/data-id=["']\S+["']\s?/g,"")
    .replace(/id=["']\S+["']\s?/g,"")
    .replace(/data-height=["']\S+["']\s?/g,"")
    .replace(/data-width=["']\S+["']\s?/g,"")
    .replace(/\n/g,"")
    .replace(/\s+>/g,">")
    .replace(/\s+\/>/g,"\/>")
    .replace(/>\s+</g,"><")
    .replace(/><\/p>/g,"\/>")
    .replace(/<\/p>/g,"");
}
