import React from "react";
import ReactDOM from "react-dom";

import { Pane, Paragraph, Text, Button, Tooltip, Position, InfoSignIcon } from "evergreen-ui";

// NOTE: This group of imports come from files that
// are created automatically from the hederis core project;
// if you need to make any edits, please edit in that repo 
// and then redistribute as appropriate.
// BEGINGROUP--------
import HedButton from "./shared/HedButton.js";
// ENDGROUP--------

export default class StructurePrompt extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let { last_input_docx_path, projectid } = this.props;
    return (
      <Pane 
        display="flex" 
        flexDirection="column" 
        alignItems="flex-start" 
        textAlign="center"
        marginTop={16}
      >
        <Pane display="flex" flexDirection="row">
          <HedButton
            appearance="hedprimary"
            onClick={e => {
              let loc = `#/projects/${projectid}/edit`;
              let url = window.location.toString().split("#")[0];
              window.location = `${url}${loc}`;
            }}
          >
            Review Manuscript Styles
          </HedButton>
          <Tooltip
            content="Manuscript styles tell Hederis how to apply the design. You can review them and make adjustments directly in the app, or download the Hederis DOCX file to the right and edit in Microsoft Word."
            position={Position.RIGHT}
          >
            <Pane>
              <HedButton 
                title="More info" 
                appearance="hedflat"
                marginX={0}
                onClick={() => props.setOpt({ epub_mode: true })}
              >
                <InfoSignIcon color="muted" />
              </HedButton>
            </Pane>
          </Tooltip>
        </Pane>
        <Paragraph textAlign="center">
          <a 
            href="https://docs.hederis.com/docs/semantic-tagging/" 
            target="_blank" 
            id="docs-link"
          >
            <Text size={300} color="selected">Learn more about Styles</Text>
          </a>
        </Paragraph>
      </Pane>
    );
  }
}
