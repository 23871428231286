import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
// prettier-ignore
import { Pane, SelectMenu, Button, Text, Icon, NewTextBoxIcon, TrashIcon, CaretDownIcon, PropertyIcon, AddRowBottomIcon, GraphRemoveIcon, TickCircleIcon } from "evergreen-ui";

import Foco from "react-foco";
// prettier-ignore
import { AttributePopoverInline } from './structure_attribute_editors.js'

import placeholder_image_url from "./shared/fpo.png";

const uuid = require("uuid/v4");
// prettier-ignore
import { hederis_type_to_user, SPAN_TYPE_ARR, get_srule_kind  } from "./structure_controls.js";

// prettier-ignore
import { el_add_attributes } from "./structure_helpers.js"

// NOTE: This group of imports come from files that
// are created automatically from the hederis core project;
// if you need to make any edits, please edit in that repo 
// and then redistribute as appropriate.
// BEGINGROUP--------
// prettier-ignore
import { writeStylesDoc, range, content_to_el } from "./shared/util";
import srules from "./shared/structure.json";
// ENDGROUP--------

// I am hackily using document, but we're only operating on id's, which should be unique anyway
function Editor(props) {
  // prettier-ignore
  const { item, isSelected, operations, editMode, setSelection, setEditMode, wrapperMode, section_id } = props;
  // console.log(section_id);
  const elRef = useRef();
  const [hasFocus, setHasFocus] = useState(false);
  const [activeInline, setActiveInline] = useState(false);
  // console.log(activeInline);

  // prettier-ignore
  writeStylesDoc( document, "structure_inline_active", highlight_style(activeInline));
  useEffect(() => {
    // just using the cleanup function
    return () => {
      // prettier-ignore
      writeStylesDoc( document, "structure_inline_active", highlight_style(false));
    };
  }, []);

  const onClick = e => {
    let id = e.target.id;
    setActiveInline(id || false);
  };

  const onMakeUpdate = () => {
    const html = elRef.current.innerHTML;
    const html_text = elRef.current.textContent;
    // prevent unnec updates if no change
    const isEdited = html !== item.innerHTML;
    if (isEdited) {
      operations.elementUpdateInnerHTML(section_id, item.id, html);
      if (item.hederis_type === "hblkchaptitle") {
        // set section title
        let new_title = html_text.replace(/["'<>]/g,"");
        operations.sectionChangeTitle(section_id,new_title);
      }
    }
    setHasFocus(false);
    setEditMode(false);
  };

  const onDiscardUpdate = () => {
    setHasFocus(false);
    setEditMode(false);
  };

  let prevent_return_key = e => {
    if (e.which === 13) {
      e.preventDefault();
      onMakeUpdate();
      return false;
    }
    return true;
  };
  // https://github.com/nanot1m/react-foco
  return (
    <Foco
      onClickOutside={() => {
        // console.log("onClickOutside");
        onMakeUpdate();
      }}
      className="editor_content_wrapper"
    >
      <div
        dangerouslySetInnerHTML={{ __html: item.innerHTML }}
        contentEditable={true}
        onPaste={onPaste}
        onKeyPress={prevent_return_key}
        ref={elRef}
        onClick={onClick}
        onFocus={() => setHasFocus(true)}
        className={`editor_content ${isSelected ? "selected" : ""}`}
      />
      <InlineControls
        {...props}
        activeInline={activeInline}
        setActiveInline={setActiveInline}
        onDiscardUpdate={onDiscardUpdate}
        onMakeUpdate={onMakeUpdate}
        hasFocus={hasFocus}
      />
    </Foco>
  );
}

function InlineControls(props) {
  // prettier-ignore
  const { hasFocus, activeInline, setActiveInline, section_id, item, isSelected, operations, editMode, setSelection, setEditMode, wrapperMode, onDiscardUpdate, onMakeUpdate } = props;
  if (!hasFocus) {
    return ``;
  }
  const activeInlineType =
    activeInline && document.querySelector(`#${activeInline}`)
      ? document
          .querySelector(`#${activeInline}`)
          .getAttribute("data-hederis-type")
      : null;

  return (
    <Pane
      position="absolute"
      padding={10}
      marginLeft={0}
      backgroundColor="rgb(255, 255, 255)"
      elevation={2}
      className="inline_controls"
      zIndex={3}
    >
      <Pane paddingBottom={8} display="flex" justifyContent="space-between">
        <Button
          id="add-inline"
          marginX={4}
          iconBefore={NewTextBoxIcon}
          onClick={() => {
            const id = replace_selected_with_span();
            setActiveInline(id);
          }}
        >
          Add Inline
        </Button>
        <Button
          id="remove-inline"
          marginX={4}
          iconBefore={TrashIcon}
          onClick={() => {
            const el = document.querySelector(`#${activeInline}`);
            // el.replaceWith(...el.childNodes);
            if (el) {
            if (el.childNodes) {
                el.replaceWith(...el.childNodes);
              } else {
                el.replaceWith("");
              }
            }
          }}
          disabled={!activeInline}
        >
          Remove Inline Only
        </Button>
        <SelectMenu
          closeOnSelect={true}
          title="Change Inline"
          options={SPAN_TYPE_ARR.map(entry => ({
            label: entry[1],
            value: entry[0],
          }))}
          selected={activeInlineType}
          onSelect={choice => {
            const el = document.querySelector(`#${activeInline}`);
            change_inline_type(el, choice.value);
            // not ideal but it'll be outdated otherwise - could use state and an effect
            setActiveInline(false);
          }}
        >
          <Button id="inline-type" marginX={4} iconBefore={CaretDownIcon} disabled={!activeInline}>
            Inline Type
            {activeInline ? `: ${hederis_type_to_user(activeInlineType)}` : ``}
          </Button>
        </SelectMenu>

        <AttributePopoverInline {...props}>
          <Button id="inline-attributes" marginX={4} iconBefore={PropertyIcon} disabled={!activeInline}>
            Inline Attributes
          </Button>
        </AttributePopoverInline>
      </Pane>

      <Pane display="flex" justifyContent="space-between">
        <Button
          id="add-footnote"
          marginX={4}
          iconBefore="new-text-box"
          onClick={() => {
            const id = replace_selected_with_footnote();
            setActiveInline(id);
          }}
        >
          Add Footnote
        </Button>
        <Button
          id="split-selection-down"
          marginX={4}
          iconBefore={AddRowBottomIcon}
          onClick={() => {
            const selection = window.getSelection().getRangeAt(0);
            // console.log(selection);
            // destructive
            const extracted = selection.extractContents();
            // update from the removal
            onMakeUpdate();
            // and then insert what we removed
            operations.elementInsert(section_id, item.id, extracted);
          }}
        >
          Split Selection Down
        </Button>
        <Button id="discard-changes" marginX={4} iconBefore={GraphRemoveIcon} onClick={onDiscardUpdate}>
          Discard Changes
        </Button>
        <Button
          id="confirm"
          marginX={4}
          intent="success"
          iconBefore={TickCircleIcon}
          onClick={onMakeUpdate}
        >
          Confirm
        </Button>
      </Pane>
    </Pane>
  );
}

// this is very similar to the elementChangeType operation
function change_inline_type(original_el, hederis_type) {
  const rule =
    srules[get_srule_kind(hederis_type)][hederis_type] ||
    srules[get_srule_kind(hederis_type)]["default"];

  const standard = { "data-hederis-type": hederis_type };
  const original_inner_html = original_el.innerHTML;
  const elAttrs = {};
  [...original_el.attributes].map(({ name, value }) => {
    elAttrs[name] = value;
  });
  // placeholder_image_url
  const is_self_closing = ["img"].includes(rule.element);
  const maybe_src =
    rule.element === "img" ? { src: placeholder_image_url } : {};

  // might want to adjust this order of presidence
  const attrs = { ...maybe_src, ...elAttrs, ...rule.attributes, ...standard };

  const html_string = is_self_closing
    ? `<${rule.element}/>`
    : `<${rule.element}></${rule.element}>`;

  const updated_el = el_add_attributes(
    content_to_el(html_string).children[0],
    attrs
  );
  if (!is_self_closing) {
    updated_el.innerHTML = original_inner_html;
  }
  original_el.replaceWith(updated_el);
}

// what we actually export
export function HtmlInlineEditor(props) {
  // prettier-ignore
  const { item, isSelected, editMode, setSelection, setEditMode, wrapperMode } = props;

  // if it's selected and in editMode show the editor with contentEditable on
  if (editMode && isSelected) {
    return <Editor {...props} />;
  }
  // otherwise just the content
  const onClick = () => {
    if (isSelected & !wrapperMode) {
      setEditMode(true);
    }
  };

  const inner_html = wrapperMode ? "" : item.innerHTML;
  return (
    <div className="editor_content_wrapper">
      <div
        dangerouslySetInnerHTML={{ __html: inner_html }}
        onClick={onClick}
        className={`editor_content ${
          isSelected & !wrapperMode ? "selected" : ""
        }`}
      />
    </div>
  );
}

// prevent pasting of html
const onPaste = event => {
  event.preventDefault();
  // grab the text content even if the clipboard contains html
  var text = (event.originalEvent || event).clipboardData.getData("text/plain");
  // insert text where the cursor/selection is
  document.execCommand("insertHTML", false, text);
};

function highlight_style(activeInline) {
  if (!activeInline) {
    return ``;
  }
  return `#structure #${activeInline} { border: rgb(16, 112, 202) 1px dotted !important; background-color: rgb(135, 255, 255) !important; }`;
}

// XXX this operates on the window level, directly on the dom, based on the cursor!
function replace_selected_with_span() {
  var selection = window.getSelection().getRangeAt(0);
  var selectedText = selection.extractContents();

  if (!selectedText.textContent) {
    selectedText.textContent = " Your text here ";
  }
  let id = `client_span_${uuid()}`;
  let html = `<span data-hederis-type="hspnspan" class="hspnspan" id="${id}"></span>`;

  const span = content_to_el(html)["children"][0];
  span.appendChild(selectedText);
  // console.log(span);
  selection.insertNode(span);
  return id;
}

function replace_selected_with_footnote() {
  var selection = window.getSelection().getRangeAt(0);
  var selectedText = selection.extractContents();

  if (!selectedText.textContent) {
    selectedText.textContent = " Your footnote text here ";
  }
  let id = `client_span_${uuid()}`;
  let wprid = `client_span_${uuid()}`;
  let paraid = `client_span_${uuid()}`;
  let html = `<span class="FootnoteReference" data-hederis-type="hspannoteref" id="${id}"><span data-type="footnote" data-hederis-type="hwprfootnote" id="${wprid}"><span class="footnote-para" data-hederis-type="hblkfootnote" id="${paraid}"></span></span></span>`;

  const outerSpan = content_to_el(html)["children"][0];
  const innerSpan = outerSpan["children"][0]["children"][0];
  innerSpan.appendChild(selectedText);
  // console.log(span);
  selection.insertNode(outerSpan);
  return paraid;
}
