import React from "react";
import ReactDOM from "react-dom";
// import { useState, useEffect } from "react";

import { Pane, Paragraph, Dialog, Heading, Button, Select, Text, toaster } from "evergreen-ui";

import { LoadingPane } from "./common.js";

import { firebase, db, storage } from "../../fire.js";
import { validate_email } from "../../helpers/util";
import { FireHelpers } from "../../../api/app_shared/fire_helpers.js";

import APIClient from "../../helpers/api_client";

const API = new APIClient(firebase);
const Diff = require('diff');

export default class FileDifferView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      all_files: false,
      filea_path: false,
      fileb_path: false,
      result: false,
    };
    this.compare_files = this.compare_files.bind(this);
  }
  // get all the latest files from firebase
  // list them as options in a dropdown
  // Send the chosen file paths to the diff lambda

  async componentDidMount() {
    await this.loadAllFiles();
  }

  async loadAllFiles() {
    let props = this.props;
    let project = props.project;
    let storageRef = storage.ref();
    let options = [];

    if (project.output.html && project.output.html.path) {
      options.push({
        "name":"Hederis Converted Text",
        "fullPath":project.output.html.path,
        "date": Date.now()
      });
    }
    if (project.output.baked_html && project.output.baked_html.path) {
      options.push({
        "name":"Hederis Print-formatted Text",
        "fullPath":project.output.baked_html.path,
        "date": Date.now()
      })
    }
    if (project.output.epub_html && project.output.epub_html.path) {
      options.push({
        "name":"Hederis EPUB Text",
        "fullPath":project.output.epub_html.path,
        "date": Date.now()
      })
    }
    if (project.output.docx && project.output.docx.path) {
      options.push({
        "name":"Hederis Output DOCX File",
        "fullPath":project.output.docx.path,
        "date": Date.now()
      })
    }

    // get all the input Word files, ever.
    // prettier-ignore
    let project_path = `${props.project.group}/${props.id}/input_docx/`;
    // console.log(project_path);
    let ref = storageRef.child(project_path);
    let docx_files = await ref.listAll();
    let docx_with_metadata = [];
    
    for (let docx of docx_files.items) {
      let {fullPath, name} = docx;
      let file_ref = await storageRef.child(fullPath);
      await file_ref.getMetadata().then(function(metadata) {
        // add a hash to the array
        let orig = name.replace(/_[a-zA-Z0-9]+\.docx$/g,".docx");
        let myname = `Input DOCX File: ${orig} (created on ${metadata.timeCreated})`;
        options.push({
          'name': myname, 
          'fullPath': fullPath,
          'date': new Date(metadata.timeCreated)
        });
      }).catch(function(error) {
        // Uh-oh, an error occurred!
        console.log("Couldn't get metadata for" + fullPath);
      });
    }
    options.sort(function(a, b) {
      return b.date - a.date;
    });
    this.setState({ all_files: options });

    if (this.state.filea_path === false) {
      this.setState({ filea_path: options[0]["fullPath"] });
    }

    if (this.state.fileb_path === false) {
      this.setState({ fileb_path: options[0]["fullPath"] });
    }
  }

  async compare_files() {
    // toaster.closeAll();
    toaster.notify("Comparing your files...", { duration: 900 });
    console.log("running diff");

    let project_id = this.props.id;
    let { project, hederis_user } = this.props;
    let filepaths = [ this.state.filea_path, this.state.fileb_path ];

    let payload = {
      filepaths,
      hederis_user,
      project_id,
      project,
    };

    let res = await API.run_diff_text(payload);

    if (!res) {
      toaster.closeAll();
      toaster.warning("There was an error", { duration: 10 });
      return;
    } else {
      toaster.closeAll();
      this.setState({ result: res });
      console.log("got result");
    }
  }

  // let res = await API.diff_text(payload);

  // if (!res) {
  //   toaster.closeAll();
  //   toaster.warning("There was an error rebuilding", { duration: 10 });
  //   return;
  // }
  render() {
    return (
      <Pane padding={32}>
        <Text>You may need to refresh your browser window to see all available files.</Text>
        <Heading margin="4">Compare Text</Heading>
        <Heading margin="4">Choose base file:</Heading>
        <PickerInnerWidget
          filelist={this.state.all_files}
          onChange={new_file_path => {
            this.setState({ filea_path: new_file_path });
          }}
          value={this.state.filea_path}
        />
        <Heading margin="4">Choose comparison file:</Heading>
        <PickerInnerWidget
          filelist={this.state.all_files}
          onChange={new_file_path => {
            this.setState({ fileb_path: new_file_path });
          }}
          value={this.state.fileb_path}
        />
        <Button
          onClick={this.compare_files}
          marginTop={16}
        >
          Compare
        </Button>
        {this.state.result ? (
          <ResultWidget
            res = {this.state.result}
          />
        ) : (
          ``
        )}
      </Pane>
    );
  }
}

function PickerInnerWidget(props) {
  let { filelist, value, onChange } = props;
  if (filelist === false) {
    // we're still loading, if there aren't any it'll be an empty array when it's loaded
    return ``;
  }

  return (
    <Pane>
      <Select
        value={value}
        onChange={event => onChange(event.target.value)}
      >
        {filelist.map(t => {
          return (
            <option key={t.name} value={t.fullPath}>
              {t.name}
            </option>
          );
        })}
      </Select>
    </Pane>
  );
}

function ResultWidget(props) {
  let { res } = props;

  let diff = false;
  let parsed = [];
  res.forEach((item, index) => {
    if (item.text) {
      // is this for file 1?
      if (item.file === "1") {
        let comp_text = "";
        let secttype = item.secttype;
        let secttitle = item.secttitle;
        // paragraphs that only exist in both files,
        // but have differences
        if (res[index+1] && res[index+1].file === "2") {
          res[index+1]["handled"] = true;
          comp_text = res[index+1].text;
          diff = Diff.diffChars(item.text, comp_text);
          let item_diff = [];
          diff.forEach((part) => {
            let className = part.added ? 'diffAdded' : part.removed ? 'diffRemoved' : 'diffShared';
            item_diff.push({ className: className, text: part.value });
          });
          if (res[index+1].secttype != null) {
            secttype = res[index+1].secttype;
            secttitle = res[index+1].secttitle;
          }
          item_diff.push({ secttype: secttype, secttitle: secttitle });
          parsed.push(item_diff);
        } else {
          // paragraphs that only exist in file 1
          diff = Diff.diffChars(item.text, comp_text);
          let item_diff = [];
          diff.forEach((part) => {
            let className = part.added ? 'diffAdded' : part.removed ? 'diffRemoved' : 'diffShared';
            item_diff.push({ className: className, text: part.value });
          });
          item_diff.push({ secttype: item.secttype, secttitle: item.secttitle });
          parsed.push(item_diff);
        }
      } else {
        // paragraphs that only exist in file 2
        if (!item.hasOwnProperty("handled")) {
          let comp_text = "";
          diff = Diff.diffChars(comp_text, item.text);
          let item_diff = [];
          diff.forEach((part) => {
            let className = part.added ? 'diffAdded' : part.removed ? 'diffRemoved' : 'diffShared';
            item_diff.push({ className: className, text: part.value });
          });
          item_diff.push({ secttype: item.secttype, secttitle: item.secttitle });
          parsed.push(item_diff);
        }
      }
    }
  })

  let msg = res.length > 0 
    ? "I found the following differences:" 
    : "No differences found!";

  return (
    <Pane marginTop={16}>
      <Paragraph>Key:</Paragraph>
      <Paragraph>
        <span className="diffRemoved">sample text</span> = 
        Existed in the base file, but deleted in the comparison file
      </Paragraph>
      <Paragraph>
        <span className="diffAdded">sample text</span> = Added in the comparison file
      </Paragraph>
      <Heading marginTop={16} marginBottom={16}>
        {msg}
      </Heading>
      {parsed.map(val => {
        return (
          <Pane
            marginBottom={16}
            key={Math.random().toString(15)}
          >
            <ResultItem val={val} />
          </Pane>
        );
      })}
    </Pane>
  );
}

function ResultItem(props) {
  let { val } = props;
  let meta = val.pop();
  return (
    <Pane>
      {meta.secttype ? (
        <Paragraph className="diffSection">
          In section "{meta.secttitle}":
        </Paragraph>
      ) : (
        <Paragraph className="diffSection">
          (Source section unknown)
        </Paragraph>
      )}
      <Paragraph>
      {val.map(item => {
        return (
          <span className={item.className} key={Math.random().toString(15)}>
            {item.text}
          </span>          
        );
      })}
      </Paragraph>
    </Pane>
  )
}
