import React from "react";
import ReactDOM from "react-dom";

// prettier-ignore
import { Pane, Paragraph, Dialog, Heading, Button, toaster, Text, Badge } from "evergreen-ui";

import { LoadingPane } from "./common.js";
import { ProjectListItem } from "./project_list_item.js";
import InviteManager from "./invite_manager.js";

import { firebase, db, storage } from "../../fire.js";
import { validate_email } from "../../helpers/util";
import APIClient from "../../helpers/api_client";
import { FireHelpers } from "../../../api/app_shared/fire_helpers.js";

const API = new APIClient(firebase);
const FH = new FireHelpers(db, firebase);

// console.log(FireHelpers);
export default class GroupSettingsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = { group_id: false, group_data: false, not_found: false, all_projects: [] };
  }
  async componentDidMount() {
    console.log(this.props);
    let group_id = this.props.params ? this.props.params.group_id : this.props.group_id;
    this.setState({ group_id: group_id });
    let group_data = await FH.get_group(group_id);
    if (group_data && group_data.owners.includes(this.props.hederis_user.uid)) {
      let all_projects = await FH.list_group_projects(group_id);
      this.setState({ group_data, all_projects });
    } else {
      toaster.warning("You must own the group to view it!");
      this.setState({ not_found: true });
    }
    // console.log();
  }

  async updateProjectOwner(uid, project_id) {
    let storageRef = storage.ref();
    let change = {creator: uid};
    if (!change.hasOwnProperty("last_updated")) {
      change["last_updated"] = firebase.firestore.FieldValue.serverTimestamp();
    }
    db.collection("projects")
      .doc(project_id)
      .update(change)
      .then(e => console.log("done setting new owner!"));
    return;
  }

  render() {
    if (this.state.not_found) {
      // prettier-ignore
      return (<Pane><Heading marginY={32} textAlign="center">Group Not Found / No Access</Heading></Pane>);
    } else if (!this.state.group_data) {
      // loading state
      return ``;
    }
    let { group_data } = this.state;
    return (
      <Pane>
        <Pane padding={16} display="flex" flexDirection="row">
          <Heading textTransform="capitalize">
            {group_data.display_name}
          </Heading>
          <Badge color="blue" marginLeft={8}>
            Owned by You
          </Badge>
        </Pane>
        <InviteManager
          kind="group_access"
          target={this.state.group_id}
          hederis_user={this.props.hederis_user}
          target_name={this.state.group_data.display_name}
          group_billing_user={this.state.group_data.billing_user}
          passed_props={this.props}
          group_owners={group_data.owners}
          self_join={group_data.hasOwnProperty("self_join") ? group_data.self_join : false}
          onGroupBillingUserChange={async billing_user => {
            await FH.set_group_billing_user(
              this.state.group_id,
              billing_user
            );
            // reload the group now that this change has made to update the ui
            const group_data = await FH.get_group(this.state.group_id);
            this.setState({ group_data });
          }}
          onGroupOwnersChange={async owners => {
            // setting claims here shouldn't be nec because they were already a group member so already have the group claim
            await FH.set_group_owners(this.state.group_id, owners);
            // reload the group now that this change has made to update the ui
            const group_data = await FH.get_group(this.state.group_id);
            this.setState({ group_data });
          }}
        />
        <AllProjectsList projects={this.state.all_projects} currentUser={this.props.hederis_user.uid} updateProjectOwner={this.updateProjectOwner} />
      </Pane>
    );
  }
}

function AllProjectsList(props) {
  return (
    <Pane padding={16} display="flex" flexDirection="column" width="100%" maxWidth="1200px">
      <Heading size={700} marginBottom={32}>
        All Group Projects
      </Heading>
      {props.projects.map(item => <ProjectListItem item={item} mode="group" currentUser={props.currentUser} key={item.id} updateProjectOwner={props.updateProjectOwner} />)}
    </Pane>
  );
}
