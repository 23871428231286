import React, { useState, useEffect, useRef } from "react";
const uuid = require("uuid/v4");
// prettier-ignore
import { get_srule_kind } from "./structure_controls.js";

// NOTE: This group of imports come from files that
// are created automatically from the hederis core project;
// if you need to make any edits, please edit in that repo 
// and then redistribute as appropriate.
// BEGINGROUP--------
import { content_to_el } from "./shared/util";
import srules from "./shared/structure.json";
// ENDGROUP--------

export function html_add_attributes(html, attributes) {
  const el = content_to_el(html).children[0];
  return el_add_attributes(el, attributes)["outerHTML"];
}

export function el_add_attributes(el, attributes) {
  for (let k in attributes) {
    // console.log(k, attributes[k]);
    el.setAttribute(k, attributes[k]);
  }
  return el;
}

export function create_placeholder_element(hederis_type) {
  // console.log("create_placeholder_element");
  const id = `client_${uuid()}`;
  const rule =
    srules[get_srule_kind(hederis_type)][hederis_type] ||
    srules[get_srule_kind(hederis_type)]["default"];
  const special_rule = srules.special_handling[hederis_type];

  // console.log(hederis_type, rule, special_rule);

  const standard = {
    "data-hederis-type": hederis_type,
  };
  const attrs = { ...rule.attributes, ...standard };

  const element_tag = special_rule ? special_rule["element"] : rule["element"];

  let child_type = special_rule ? special_rule["default_child"] : false;
  // if it's a box, put a paragraph in it for now, #1150
  child_type = child_type ? child_type : !child_type && hederis_type.startsWith(`hwpr`) ? `hblkp` : false;

  // XXX if we have a special_handling rule with a default child, or it's a hwpr (box)
  // create it, recursively (if that also has a special_handling default child, and so on)
  const placeholder = child_type
    ? create_placeholder_element(child_type)["outerHTML"]
    : ``;

  const html = `<${element_tag} id="${id}">${placeholder}</${element_tag}>`;

  const el = content_to_el(html).children[0];
  el_add_attributes(el, attrs);

  return el;
}

export function create_clone_element(selected_el, frag) {
  const id = `client_${uuid()}`;
  const clone = selected_el.cloneNode();
  clone.id = id;
  clone.appendChild(frag);
  return clone;
}

export function create_placeholder_section(hederis_type, project) {
  const placeholder_text = ``;
  // just a placeholder for them to type in
  //prettier-ignore
  const inner_ph =`<p class="hblkp" data-hederis-type="hblkp" id="client_${uuid()}">${placeholder_text}</p>`
  // the appropriate rule, from structure.json
  const rule = srules.sections[hederis_type] || srules.sections["default"];
  // console.log(rule);
  // for the actual section element
  const id = `client_${uuid()}`;
  // the actual section
  const html = `<${rule.element} id="${id}">${inner_ph}</${rule.element}>`;
  // prettier-ignore
  const top_level_attributes = {"data-book-title":project.title, "data-author-name":project.author}
  // prettier-ignore
  const standard = {
    "data-hederis-type":hederis_type, 
    class:hederis_type, 
    "title":rule["title"]
  }
  const attrs = { ...rule.attributes, ...top_level_attributes, ...standard };
  // console.log(attrs);
  const content = html_add_attributes(html, attrs);
  // console.log(content);

  const title = rule["title"];
  const baked_sect_html = "";

  return { content, id, hederis_type, title, baked_sect_html };
}
