import React from "react";
import ReactDOM from "react-dom";
// prettier-ignore
import { Pane, Text, Heading, Popover, Dialog, Position, RadioGroup, Button, Select, Menu, TextInputField, TabNavigation, Tab, Link, toaster, IconButton, Icon, Checkbox, AnnotationIcon, PlusIcon, ChevronDownIcon } from "evergreen-ui";

import { firebase, db, storage } from "../../fire.js";
import APIClient from "../../helpers/api_client";
import { FireHelpers } from "../../../api/app_shared/fire_helpers.js";
const API = new APIClient(firebase);
const FH = new FireHelpers(db, firebase);
import { TemplateBox } from "../shared/common.js";
import { SectionSelect } from "./view_options.js";
import HedButton from "../shared/HedButton.js";
import { human_adv_template_label } from "../../helpers/util.js";

// import static_book_style_templates from "../../../api/app_shared/defaults/static_book_style_templates.json";
import template_builder_advanced_defaults from "../../../api/app_shared/defaults/template_builder_advanced.json";

export default class AdvancedTemplatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // where we load the templates that exist in this project
      project_templates: false,
      // when the user picks something from the clone button, this controls if the clonemodal is shown
      clone_kind: false,
      clone_path: false,
      // flag for working state - when we're cloning
      isLoading: false,
    };
  }
  async componentDidMount() {
    await this.loadProjectTemplates();
  }
  async loadProjectTemplates() {
    let props = this.props;
    let storageRef = storage.ref();
    // prettier-ignore
    let project_path = `${props.project.group}/${props.id}/advanced_config/`;
    let ref = storageRef.child(project_path);
    let project_templates = await ref.listAll();
    // console.log(project_templates.items);
    let p_templates = project_templates.items.map(({ fullPath, name }) => {
      return { fullPath, name };
    });
    this.setState({ project_templates: p_templates });
  }
  // clone path will be undefined for clone_kind import, default, and public
  // ie. only for project
  onCloneSelect(clone_kind, clone_path) {
    // console.log("onCloneSelect");
    // set state for our next step
    this.setState({ clone_kind, clone_path });
  }
  onCloneCancel() {
    // console.log("setting cancel state");
    this.setState({ clone_kind: false, clone_path: false });
  }
  // we have all the details - lets actually do the cloning here
  // arg XXX we need to run generate json etc on this
  async onCloneSubmit(new_name) {
    let storageRef = storage.ref();
    let props = this.props;
    let { clone_path, clone_kind } = this.state;
    // console.log("onCloneSubmit", new_name, clone_path, clone_kind);
    this.setState({ isLoading: true });
    let clone_data;
    if (clone_kind !== "default") {
      let url = await storage.ref(clone_path).getDownloadURL();
      let res = await fetch(url);
      clone_data = await res.json();
      // clone_data = await res.text();
    } else {
      clone_data = {};
    }

    let clone_config = this.props.processClone(clone_data);

    let project_path = `${props.project.group}/${props.id}/`;
    let new_file_path = `${project_path}advanced_config/${new_name}.json`;
    await storage
      .ref()
      .child(new_file_path)
      .putString(JSON.stringify(clone_config));

    props.updateProjectMeta({ advanced_config_path: new_file_path });
    // we do this separately to ensure there's no race condition
    // i.e. this doesn't set the path, it just loads the config
    // whereas updateProjectmeta will update the state, but we don't know exactly when
    await this.props.reloadConfig(new_file_path);
    // reload the templates so we include this new one
    await this.loadProjectTemplates();
    toaster.success("Template Cloned & Selected");
    // this is pdf specific, we want a different property for epub XXX TODO
    // props.updateProjectMeta({ advanced_config_path: new_file_path });
    this.setState({ clone_kind: false, clone_path: false, isLoading: false });
  }
  render() {
    // console.log("template picker render props:");
    // console.log(this.props);
    let { project } = this.props;

    return (
      <Pane className={this.props.useSlimPicker ? "templatePickerOuter slim" : "templatePickerOuter"}>
        { !this.props.useSlimPicker ? (
        <Pane>
          <Pane 
            display="flex" 
            flexDirection="row" 
            alignItems="center" 
            paddingY={8} 
            borderBottom="1px solid #ffffff"
            className="dashOptions"
          >
            <Heading size={300} marginRight={16}>Choose a template to design: </Heading>
            <PickerInnerWidget
              config_path={project.advanced_config_path}
              templates={this.state.project_templates}
              onChange={new_file_path => {
                this.props.updateProjectMeta({
                  advanced_config_path: new_file_path,
                });
                this.props.reloadConfig(new_file_path);
              }}
            />
            <HedButton
              iconBefore={AnnotationIcon}
              appearance="hedprimary"
              marginX={8}
              onClick={e => {
                let loc = `#/projects/${this.props.id}/design`;
                let url = window.location.toString().split("#")[0];
                // console.log(`${url}${loc}`);
                window.location = `${url}${loc}`;
              }}
            >
              Edit
            </HedButton>
            {this.props.section_list && this.props.section_list.length ? (
              <Pane
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                marginTop={8}
                marginBottom={8}
              >
                <SectionSelect
                  {...this.props}
                  section_meta={this.props.project.section_meta}
                  section_index={false}
                  setOpt={({ section_index }) => {
                    // prettier-ignore
                    let loc = `#/projects/${this.props.id}/design/${section_index}`;
                    let url = window.location.toString().split("#")[0];
                    window.location = `${url}${loc}`;
                  }}
                />
              </Pane>
            ) : (
              ``
            )}
          </Pane>
          <Pane display="flex" flexDirection="row" alignItems="center">
            <Heading size={300}>Create or import a new template:</Heading>
            <CloneTemplateButton
              {...this.state}
              {...this.props}
              onCloneSelect={this.onCloneSelect.bind(this)}
            />
            <NameTemplateModal
              {...this.props}
              {...this.state}
              onCloneSubmit={this.onCloneSubmit.bind(this)}
              onCloneCancel={this.onCloneCancel.bind(this)}
            />
            {this.state.clone_kind !== "import" || this.state.clone_kind !== "public" || this.state.clone_path && (
              ``
            )}
            {this.state.clone_kind === "import" && (
              <ImportCloneFromOtherProjectModal
                {...this.state}
                {...this.props}
                public={false}
                onExternalTemplateChoose={e => {
                  this.setState({ clone_path: e.target.value });
                }}
                onCloneCancel={this.onCloneCancel.bind(this)}
              />
            )}
            {this.state.clone_kind === "public" && (
              <ImportCloneFromOtherProjectModal
                {...this.state}
                {...this.props}
                public={true}
                onExternalTemplateChoose={e => {
                  this.setState({ clone_path: e.target.value });
                }}
                onCloneCancel={this.onCloneCancel.bind(this)}
              />
            )}
            {/*<Text>advanced_config_path: </Text>*/}
            {/*<Text>{project.advanced_config_path}</Text>*/}
          </Pane>
        </Pane>
        ) : (
          <PickerInnerWidget
            config_path={project.advanced_config_path}
            templates={this.state.project_templates}
            onChange={async new_file_path => {
              this.props.updateProjectMeta({
                advanced_config_path: new_file_path,
              });
              await this.props.reloadConfig(new_file_path);
              if (this.props.customFunction) {
                console.log("reloading pages");
                this.props.customFunction();
              };
            }}
          />
        )}
      </Pane>
    );
  }
}

export function PickerInnerWidget(props) {
  let { templates, config_path, onChange, alt_config_path } = props;
  if (templates === false) {
    // we're still loading, if there aren't any it'll be an empty array when it's loaded
    return ``;
  } else if (!config_path && !alt_config_path) {
    // they haven't used the design view or imported any yet
    return (
      <Pane>
        <Text>(Using default static template)</Text>
      </Pane>
    );
  }

  return (
    <Pane display="inline" className="templatePickerInner">
      <Select
        value={config_path || alt_config_path}
        onChange={event => onChange(event.target.value)}
      >
        {templates.map(t => {
          if (t.name === "custom.css") {
            return;
          }
          return (
            <option key={t.name} value={t.fullPath}>
              {human_adv_template_label(t.name)}
            </option>
          );
        })}
      </Select>
    </Pane>
  );
}

export function PdfTemplateWidget(props) {
  return (
    <Pane margin={4}>
      <PickerInnerWidget
        config_path={props.project.advanced_config_pdf_path}
        alt_config_path={props.project.advanced_config_path}
        templates={props.project_templates}
        onChange={new_file_path => {
          props.updateProjectMeta({
            advanced_config_pdf_path: new_file_path,
          });
          props.reloadConfig(new_file_path);
        }}
      />
    </Pane>
  );
}

export function EpubTemplateWidget(props) {
  return (
    <Pane margin={4}>
      <PickerInnerWidget
        config_path={props.project.advanced_config_epub_path}
        alt_config_path={props.project.advanced_config_path}
        templates={props.project_templates}
        onChange={new_file_path => {
          props.updateProjectMeta({
            advanced_config_epub_path: new_file_path,
          });
          props.reloadConfig(new_file_path);
        }}
      />
    </Pane>
  );
}

class ImportCloneFromOtherProjectModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { group_templates: false };
  }
  async componentDidMount() {
    let storageRef = storage.ref();
    // prettier-ignore

    let {group} = this.props.project
    if (this.props.public === true) {
      group = "public"
    }
    let projects = await FH.list_group_projects(group);
    // console.log(projects);
    let prom_arr = projects.map(async p => {
      // could exclude THIS project to avoid confusion - but need to prevent radiogroup render
      // prettier-ignore
      // if (this.props.params.id === p.id) { return []}
      // otherwise
      let proj_path = `${group}/${p.id}/advanced_config/`;
      let draft = p.draft ? p.draft : false;
      // console.log(proj_path);
      let ref = storageRef.child(proj_path);
      let res = await ref.listAll();
      // console.log(res);
      return res.items.filter(function(item) {
        if (draft) {
          return false; // skip
        }
        return true}).map(t => {
          return {
            name: t.name,
            fullPath: t.fullPath,
            project_title: p.title,
            project_id: p.id,
          };
        });
    });
    let group_templates = await Promise.all(prom_arr);
    group_templates = group_templates.flat();
    // console.log(projects);
    // console.log(group_templates);
    this.setState({ group_templates });
    // let group_path = `${this.props.project.group}/`;
    // console.log(project_path);
    // let ref = storageRef.child(group_path);
    // let res = await ref.listAll();
    // console.log(res);
  }
  render() {
    let props = this.props;
    let { group_templates } = this.state;
    let group_template_options = group_templates
      ? group_templates.map(g => {
          let label = `${g.project_title} : ${human_adv_template_label(
            g.name
          )}`;
          return { label, value: g.fullPath };
        })
      : [];

    return (
      <Dialog
        isShown={true}
        title={this.props.public ? "Clone a Public Template" : "Clone a Template from Another Project"}
        onCloseComplete={() => props.onCloneCancel()}
        hasFooter={false}
      >
        <Pane display="flex" flexDirection="column">
          {group_templates === false ? (
            `Loading...`
          ) : (
            <RadioGroup
              label={
                props.project.group.startsWith("user_")
                  ? `Your projects & templates`
                  : `Group: ${props.project.group} projects & templates`
              }
              value={"none"}
              options={group_template_options}
              onChange={value => props.onExternalTemplateChoose(value)}
            />
          )}
        </Pane>
      </Dialog>
    );
  }
}
// for from within and default, not import
// function CloneTemplateModal(props) {
class NameTemplateModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: "" };
  }
  render() {
    let { props } = this;
    let isShown =
      (props.clone_kind && props.clone_path) || props.clone_kind == "default"
        ? true
        : false;
    // console.log(isShown);

    return (
      <Dialog
        isShown={isShown}
        isConfirmLoading={props.isLoading}
        title="Choose a Name"
        onCloseComplete={() => props.onCloneCancel()}
        confirmLabel={!props.isLoading ? "Clone & Select" : "Cloning..."}
        onConfirm={() => {
          // TODO validation and regex
          let val = this.state.value;
          val = val.replace(/\W/g, "_");
          if (val.length < 3) {
            toaster.warning("Need a longer name");
            return;
          } else if (val.length > 250) {
            toaster.warning("Need a shorter name");
            return;
          }
          props.onCloneSubmit(val);
        }}
      >
        <TextInputField
          label="Cloned template name"
          value={this.state.value}
          hint="Non-alphanumeric characters will be replaced with underscores"
          placeholder="Your Cloned Template"
          onChange={e => this.setState({ value: e.target.value })}
        />
      </Dialog>
    );
  }
}
//
// The menu widget
//
function CloneTemplateButton(props) {
  let project_templates = props.project_templates || [];
  let make_menu_el = ({ close }) => {
    // secondaryText="⌘R"
    return (
      <Menu>
        <Menu.Group title="From This Project">
          {project_templates.map(t => {
            return (
              <Menu.Item
                onSelect={() => {
                  props.onCloneSelect("self", t.fullPath);
                  close();
                }}
                key={t.fullPath}
              >
                {human_adv_template_label(t.name)}
              </Menu.Item>
            );
          })}
        </Menu.Group>
        <Menu.Divider />
        <Menu.Group title="From Elsewhere">
          <Menu.Item
            onSelect={() => {
              props.onCloneSelect("import");
              close();
            }}
          >
            From Another Project...
          </Menu.Item>
          <Menu.Item
            onSelect={() => {
              props.onCloneSelect("public");
              close();
            }}
          >
            From Public Templates...
          </Menu.Item>
          {/* for some reason I couldn't get this to work XXX */}
          {/**/}
          <Menu.Item
            onSelect={() => {
              props.onCloneSelect("default");
              close();
            }}
          >
            From Defaults
          </Menu.Item>
        </Menu.Group>
      </Menu>
    );
  };

  return (
    <Pane display="inline" marginLeft={16}>
      <Popover position={Position.BOTTOM_LEFT} content={make_menu_el}>
        <Button marginY={8} iconBefore={PlusIcon} iconAfter={ChevronDownIcon}>
          Clone a Template From...
        </Button>
      </Popover>
    </Pane>
  );
}
