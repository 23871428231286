import React from "react";
import ReactDOM from "react-dom";
// prettier-ignore
import {Pane, Text, Heading, Code, Button, TextInputField, TabNavigation, Tab, SidebarTab, Tablist, Link, toaster, IconButton, Icon, Small, TextInput, RadioGroup, Select, Strong, Checkbox, Combobox, TagInput, Portal, Tooltip, InlineAlert, CrossIcon, TickCircleIcon, EditIcon} from "evergreen-ui";
import HedButton from "../shared/HedButton.js";

export function EditSaveAndCancel(props) {
  // console.log(props);
  let is_edit_mode = isEditMode(props);
  let e_or_e = is_edit_mode ? props.onEditLeave : props.onEditEnter;
  let msg = `The print layout for this section is locked. Any text edits you 
             make in Digital mode will NOT be applied to the Print mode.`
  return (
    <Pane display="flex" flexDirection="row" alignItems="center" marginLeft={4}>
      {props.isLocked && props.view_options.epub_mode ? (
        <Tooltip content={msg}>
          <InlineAlert
            intent="warning"
            children="Text edits will not be synced."
          />
        </Tooltip>
      ) : (``)}
      <HedButton 
        title="Text Edit Tool" 
        appearance="hedflat"
        marginLeft={4}
        disabled={props.is_loading || props.is_saving}
        isActive={is_edit_mode}
        onClick={() => e_or_e(edit_mode_helper(props))}
      >
        <EditIcon color={is_edit_mode ? "success" : "disabled"} />
      </HedButton>
    </Pane>
  );
}

function isEditMode(props) {
  // prettier-ignore
  let { isLocked, content_editing, line_editor_mode, range_locked, current_range } = props

  // the simple case, we're not even locked
  if (!isLocked) {
    return content_editing;
  } else {
    return line_editor_mode;
  }
}

function edit_mode_helper(props) {
  let is_edit_mode = isEditMode(props);

  // prettier-ignore
  let { isLocked, content_editing, line_editor_mode, range_locked, current_range } = props;
  // XXX might need current_range / range_locked?
  let stateChanges;
  if (is_edit_mode) {
    stateChanges = {
      line_editor_mode: false,
      line_selected: "",
      content_editing: false,
      selection_path: [],
      selection_mode: "all",
    };
  } else {
    if (isLocked) {
      stateChanges = {
        line_editor_mode: true,
        line_selected: "",
        content_editing: false,
        selection_path: [],
        selection_mode: "only",
      };
    } else {
      stateChanges = {
        line_editor_mode: false,
        line_selected: "",
        content_editing: true,
        selection_path: [],
        selection_mode: "only",
      };
    }
  }
  return stateChanges;
}
