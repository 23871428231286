import React from "react";
import ReactDOM from "react-dom";

import { Pane, Text, Heading, Button, IconButton, TrashIcon, CloudUploadIcon } from "evergreen-ui";

// import { firebase, db, storage } from "../../fire.js";
import { CloudImage, Loader } from "../shared/common.js";

import template_cover from "../../../api/app_shared/assets/template-cover.png";

export default class CoverImageView extends React.Component {
  constructor(props) {
    super(props);
    this.hiddenInputRef = React.createRef();
  }
  onChangeFile(event) {
    event.stopPropagation();
    event.preventDefault();
    let files = event.target.files;
    this.props.onFileDrop(files);
  }
  render() {
    let { cover_image } = this.props.project;
    let is_not_template = this.props.is_not_template;
    // justifyContent="center"
    // justifyContent="flex-end"
    if (is_not_template) {
      return (
        <Pane
          minHeight={300}
          elevation={1}
          background="tint2"
          marginTop={16}
        >
          <Pane display="flex" flexDirection="row" justifyContent="flex-end">
            {cover_image ? (
              <Button
                iconBefore={TrashIcon}
                height={20}
                onClick={e => this.props.remove()}
              >
                Remove as Cover
              </Button>
            ) : (
              ``
            )}
          </Pane>
          <Pane
            alignItems="center"
            justifyContent="center"
            display="flex"
            minHeight={300}
            flexDirection="column"
            id="cover_image_view"
            onClick={
              cover_image ? null : e => this.hiddenInputRef.current.click()
            }
          >
            <input
              type="file"
              ref={this.hiddenInputRef}
              style={{ display: "none" }}
              onChange={this.onChangeFile.bind(this)}
            />
            <CloudImage path={cover_image} coverPane={true} />
            {cover_image ? null : (
              <Button
                appearance="default"
                height={48}
                marginBottom={16}
                iconBefore={CloudUploadIcon}
              >
                Upload an Image
              </Button>
            )}

            {cover_image ? null : (
              <Text margin={8} textAlign="center">
                Click here or drag an image from your computer
              </Text>
            )}
          </Pane>
        </Pane>
      );
    } else {
      return (
        <Pane
          minHeight={300}
          elevation={1}
          background="tint2"
          marginTop={16}
        >
          <Pane
            alignItems="center"
            justifyContent="center"
            display="flex"
            minHeight={300}
            flexDirection="column"
            id="template cover_image_view"
          >
            <img src={template_cover} width="100%" />
          </Pane>
        </Pane>
      );
    }
  }
}
