import React from "react";
import ReactDOM from "react-dom";
// prettier-ignore
import {Pane, Text, Heading, Code, Button, Dialog, Alert, TextInputField, TabNavigation, Tab, SidebarTab, Tablist, Link, toaster, IconButton, Icon, Small, TextInput, RadioGroup, Select, Strong, Checkbox, Combobox, TagInput, Portal, SelectMenu, PlusIcon, CloudUploadIcon} from "evergreen-ui";

import opentype from "opentype.js";

import { useState, useEffect, useRef } from "react";
import { firebase, db, storage } from "../../fire.js";
import { asyncForEach } from "../../../api/app_shared/process/async.js";

import APIClient from "../../helpers/api_client";
import { FireHelpers } from "../../../api/app_shared/fire_helpers.js";

import { validate_font_style } from "../../../api/app_shared/process/fonts.js";
import HedButton from "../shared/HedButton.js";

const API = new APIClient(firebase);
const FH = new FireHelpers(db, firebase);

export function AddFontButton(props) {
  let [show, setShow] = useState(false);
  // let [show, setShow] = useState(true);
  return (
    <Pane textAlign="right">
      <HedButton height={24} onClick={() => setShow(!show)} appearance="hedflat">
        <Text textDecoration="underline" size={300}>+ Upload</Text>
      </HedButton>
      {show ? (
        <FontUploadView
          show={show}
          setShow={setShow}
          group={props.group}
          user_groups={props.user_groups}
          hederis_user={props.hederis_user}
          params={props.params}
          fontUploadFinished={props.fontUploadFinished}
        />
      ) : (
        ``
      )}
    </Pane>
  );
}

export function FontUploadView(props) {
  // P for project-only, G for group-only, L for list of specific users
  let [kind, setKind] = useState("P");
  let [loading, setLoading] = useState(false);
  let [agreed, setAgreed] = useState(false);
  let [files, setFiles] = useState([]);
  let [displayName, setDisplayName] = useState("");
  const [selectedMembers, setSelectedMembers] = useState([]);

  let restrict_options = get_restricts(props);
  let up_path = get_up_path(props, kind);
  // console.log(files);
  return (
    <Pane>
      <Dialog
        isShown={props.show}
        title={`Upload a Font`}
        onCloseComplete={() => {
          // console.log("cancel");
          props.setShow(false);
          setFiles([]);
          // console.log("onCloseComplete");
        }}
        isConfirmDisabled={!files.length || !displayName || !agreed}
        isConfirmLoading={loading}
        confirmLabel="Upload Font!"
        preventBodyScrolling
        onConfirm={async close => {
          setLoading(true);
          // works!
          // console.log(files);
          // this mutates files XXX, but we're done with it after this
          await do_font_files_upload(up_path, files);
          // console.log("files");
          // console.log(files);
          await FH.create_user_font({
            kind,
            displayName,
            files,
            props,
            selectedMembers,
          });
          toaster.success(`Font Upload Successful!`);
          props.fontUploadFinished();
          props.setShow(false);
        }}
      >
        <Pane>
          <UploadFontWidget
            onFileSelect={async sel_files => {
              let sel_files_arr = await Promise.all(
                Array.from(sel_files).map(async f => {
                  return await get_font_file_meta(f);
                })
              );
              // remove the falses, if there was an error
              sel_files_arr = sel_files_arr.filter(x => x);
              // this commented out line is just to auto set the name if it's not set, but that may be more confusing
              // if (sel_files_arr.length && displayName == "") {if (sel_files_arr[0]["fontFamily"]) { setDisplayName(sel_files_arr[0]["fontFamily"][lang]); }}
              // console.log(sel_files_arr);
              setFiles(files.concat(sel_files_arr));
            }}
          />
          <TextInputField
            label="Font Name"
            value={displayName}
            height={40}
            hint="You can upload multiple font files (e.g. different weights & sub-families) under one name"
            placeholder="Your Font Name"
            onChange={e => setDisplayName(e.target.value)}
            marginBottom={48}
          />
          <Checkbox
            checked={agreed}
            paddingY={16}
            label="I certify that I am licensed to use this font in the ways configured above, and I agree to the terms and conditions."
            onChange={e => setAgreed(e.target.checked)}
          />
          <Heading size={400}>Restrictions</Heading>
          <RadioGroup
            label="Who can use this font?"
            value={kind}
            options={restrict_options}
            onChange={e => {
              setKind(e.target.value);
            }}
            size={16}
          />

          {kind === "L" ? (
            <SpecificMemberPicker
              group={props.group}
              selectedMembers={selectedMembers}
              setSelectedMembers={setSelectedMembers}
            />
          ) : (
            ``
          )}
        </Pane>
        <FontFileList files={files} />
      </Dialog>
    </Pane>
  );
}

function SpecificMemberPicker(props) {
  const [members, setMembers] = useState([]);
  const { selectedMembers, setSelectedMembers } = props;
  let unmounted = false;
  function handleLoad(data) {
    // console.log(data);
    // prettier-ignore
    if (unmounted){ return }
    setMembers(data);
  }

  // takes two arguments, the effect, and what should determine if we rerun the effect (ie path)
  useEffect(
    () => {
      FH.get_invite_list(props.group, "group_access", props.group).then(
        handleLoad
      );
      return () => {
        unmounted = true;
      };
    },
    [props.group]
  );

  return (
    <SelectMenu
      isMultiSelect
      options={members.map(({ email, to_uid }) => {
        return { label: email, value: to_uid || email, disabled: !to_uid };
      })}
      selected={selectedMembers}
      onSelect={item => {
        setSelectedMembers([...selectedMembers, item.value]);
      }}
      onDeselect={item => {
        setSelectedMembers(selectedMembers.filter(x => x !== item.value));
      }}
    >
      <Button>{get_add_member_button_label(selectedMembers.length)}</Button>
    </SelectMenu>
  );
}

function get_add_member_button_label(len) {
  if (len === 0) {
    return `Select Users...`;
  } else if (len === 1) {
    return `1 User Selected...`;
  } else {
    return `${len} Users Selected...`;
  }
}

function UploadFontWidget(props) {
  const [kind, setKind] = useState("P");
  const { HiddenInput, onClick } = useFileSelect(props.onFileSelect);
  return (
    <Pane>
      <Pane display="flex" justifyContent="center">
        <HiddenInput />
        <Button
          margin={24}
          appearance="default"
          height={56}
          appearance="primary"
          intent="success"
          onClick={e => onClick()}
          iconBefore={CloudUploadIcon}
        >
          Select Font Files for Upload!
        </Button>
      </Pane>
    </Pane>
  );
}

function FontFileList(props) {
  return (
    <Pane>
      {props.files.length ? (
        <Heading size={600}>Files for Upload:</Heading>
      ) : (
        ``
      )}
      {props.files.length ? (
        <Pane
          key={0}
          paddingY={8}
          display="flex"
          margin={4}
          justifyContent="space-around"
        >
          <Pane width={80}>
            <Strong>Filename</Strong>
          </Pane>
          <Pane width={80}>
            <Strong>Font Family</Strong>
          </Pane>
          <Pane width={80}>
            <Strong>Italic?</Strong>
          </Pane>
          <Pane width={80}>
            <Strong>Weight</Strong>
          </Pane>
          <Pane width={40}>
            <Strong>OpenType Features</Strong>
          </Pane>
        </Pane>
      ) : (
        ``
      )}
      {props.files.map((f, i) => (
        <SingleFontFile 
          f={f}
          index={i}
          key={i}
        />
      ))}
    </Pane>
  );
}

function SingleFontFile(props) {
  const {f, index} = props;
  const [subFamily, setSubFamily] = useState(f.fontSubfamily);

  function handleSetSubFamily(checked) {
    let data = validate_font_style(checked);
    setSubFamily(data);
    return data;
  }

  return (
    <Pane
      key={index}
      paddingY={8}
      display="flex"
      margin={4}
      border
      justifyContent="space-around"
    >
      <Pane width={80} wordBreak="break-all">
        <Text>{f.file ? f.file.name : ``}</Text>
      </Pane>
      <Pane width={80}>
        <Text>{f.fontFamily} </Text>
      </Pane>
      <Pane width={80}>
        <Checkbox
          label=""
          checked={subFamily === "Italic" ? true : false}
          onChange={e => {
            f.fontSubfamily = handleSetSubFamily(e.target.checked);
          }}
        />
      </Pane>
      <Pane width={80}>
        <Text>{f.weightClass}</Text>
      </Pane>
      <Pane width={40}>
        <Text>{f.feats.length}</Text>
      </Pane>
    </Pane>
  );
}

// Hook
export const useFileSelect = onFileSelect => {
  const ref = useRef(null);

  // Our invisible file input field
  const HiddenInput = () => (
    <input
      type="file"
      ref={ref}
      onChange={onChange}
      style={{ display: "none" }}
      multiple
    />
  );

  // Allow users to click on the hidden input.
  const onClick = () => {
    ref.current.click();
  };

  const onChange = e => {
    const files = e.target.files;
    if (files.length > 0) {
      // onFileSelect(files[0])
      onFileSelect(files);
    }
  };

  return {
    HiddenInput,
    onClick,
  };
};

async function get_font_file_meta(file) {
  const LANG = "en";
  try {
    let font = await opentype.load(window.URL.createObjectURL(file));
    // console.log(font);
    // XXX this is all pretty hacky, I don't really know how brittle this is across font files
    // note "name" vs "names" XXX
    // let fontFamily = font.tables.name.fontFamily;
    let fontFamily = font.names.fontFamily[LANG];
    let fontSubfamily = font.names.fontSubfamily[LANG];
    // subFamily should only be ital or regular
    if (
      fontSubfamily.toLowerCase() != "regular" &&
      fontSubfamily.toLowerCase() != "italic"
    ) {
      if (fontFamily.match(/[Ii]tali?c?/)) {
        fontSubfamily = "Italic";
      } else {
        fontSubfamily = "Regular";
      }
    } 

    let weightClass = font.tables.os2.usWeightClass;
    let widthClass = font.tables.os2.usWidthClass;
    // get the unique tags from gsub.features, if that exists
    // XXX not sure if that's the right place / other non gsub feats?
    let feats = [];
    if (font.tables.gsub) {
      feats = Array.from(
        new Set(font.tables.gsub.features.map(({ tag }) => tag))
      );
    }
    return { fontFamily, fontSubfamily, file, feats, weightClass, widthClass };
  } catch (e) {
    console.log(e);
    toaster.danger("There was an error parsing that font file!");
    return false;
  }
}

async function do_font_files_upload(up_path, files) {
  return await asyncForEach(files, async wrap_obj => {
    // added wrap, because of the meta stuff, we want the ["file"]
    let a_file = wrap_obj["file"];
    let f_name = a_file["name"];
    let f_path = up_path + f_name;

    let storageRef = storage.ref();
    let fontRef = storageRef.child(f_path);
    await fontRef.put(a_file);

    // replaced below with what is essentially the public url
    wrap_obj["path"] = await fontRef.getDownloadURL();
    // prev called "cloud_path" "path"
    wrap_obj["cloud_path"] = f_path;
    wrap_obj["f_name"] = f_name;
    // delete the actual file object!
    delete wrap_obj["file"];
  });
}

function get_restricts(props) {
  let poss_indi_g = `user_${props.hederis_user.uid}`;
  // prettier-ignore
  let g_label = props.group === poss_indi_g ? "Your Account" : `Everyone in this Organization (${props.group})`
  let restrict_options = [
    { label: "This Project", value: "P" },
    { label: g_label, value: "G" },
  ];
  if (props.user_groups.includes(props.group)) {
    restrict_options.push({ label: "Specific Users", value: "L" });
  }
  return restrict_options;
}

function get_up_path(props, kind) {
  let dir_name = "font_files";
  if (kind === "P") {
    return `${props.group}/${props.params.id}/${dir_name}/`;
    return;
  } else if (kind === "G" || kind === "L") {
    return `${props.group}/${dir_name}/`;
  } else {
    throw new Error("Wrong Kind");
  }
}
