import React from "react";
import ReactDOM from "react-dom";
// import { useState, useEffect } from "react";

import { Pane, Paragraph, Dialog, Heading, Button, Badge } from "evergreen-ui";

import { LoadingPane } from "./common.js";
import InviteManager from "./invite_manager.js";
import SnapshotRestorer from "../advanced/snapshot_restorer.js";

import { firebase, db } from "../../fire.js";
import { validate_email } from "../../helpers/util";

import { FireHelpers } from "../../../api/app_shared/fire_helpers.js";

// console.log(FireHelpers);
export default class ProjectSettingsView extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Pane>
        <Heading margin={16}>Project Settings</Heading>
        <InviteManager
          kind="project_access"
          target={this.props.id}
          project={this.props.project}
          hederis_user={this.props.hederis_user}
          target_name={this.props.project.title}
        />
        <Badge color="red" margin={16}>Danger Zone</Badge>
        <Heading size={700} marginBottom={32} marginLeft={16}>
          Restore from Snapshot
        </Heading>
        <Paragraph margin={16} maxWidth={700}>
          A "Snapshot" is a saved version of your files from a specific point 
          in time. By restoring from a saved snapshot, you will overwrite the 
          current files (text, PDF, EPUB, etc.) with the versions of the files
          that existed at the time the snapshot was created.
        </Paragraph>
        <SnapshotRestorer {...this.props} />
      </Pane>
    );
  }
}
