function toPx(s) {
  try {
    if (s) {
      if (Array.isArray(s)) {
        s.forEach(function(item, index) {
          s[index] = toPx(s[index]);
        });
        return s;
      } else if (
        typeof s === 'string' || 
        s instanceof String
      ) {
        if (s.match(/^auto/g)) {
          return "auto";
        } else if (!s.match(/^\d/g)) {
          return s;
        } else if (s.match(/%$/g) || s.match(/em$/g) || s.match(/\s/g)) {
          return s;
        } else {
          if (s.endsWith("pt")) {
            let points = parseFloat(s.replace("pt", ""));
            // console.log(points);
            var val = points * (96 / 72);
            val = Math.round(val);
            return val.toString() + "px";
          } else if (s.endsWith("in")) {
            let inches = parseFloat(s.replace("in", ""));
            var val = inches * 96;
            val = Math.round(val);
            return val.toString() + "px";
            // 96px
          } else if (s.endsWith("mm")) {
            let mms = parseFloat(s.replace("mm", ""));
            var val = mms * 3.779528;
            val = Math.round(val);
            return val.toString() + "px";
          } else if (s.endsWith("cm")) {
            let cms = parseFloat(s.replace("cm", ""));
            var val = cms * 37.79528;
            val = Math.round(val);
            return val.toString() + "px";
          } else if (s.endsWith("px")) {
            return s;
            // 96px
          } else if (s.endsWith("null")) {
            s = s.replace("null", "") + "pt";
            s = toPx(s);
            return s
            // 96px
          } else {
            // console.log("❌", s);
          }
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function font_size_less_than_min(watermark_font_size) {
    let processed_size = 0;
    if (watermark_font_size && watermark_font_size.match(/^\d/)) {
      processed_size = parseFloat(toPx(watermark_font_size).replace("px",""));
    }
    return processed_size >= 8 ? false : true;
  };

function validate_watermark_size(watermark_font_size, mode, has_image_watermark) {
  if (
    mode === "galley" && 
    !has_image_watermark && 
    font_size_less_than_min(watermark_font_size)
  ) {
    watermark_font_size = "6pt";
  }
  return watermark_font_size;
}

function get_watermark_settings(export_settings_style, mode) {
  let has_text_watermark = export_settings_style.text_watermark 
    ? export_settings_style.text_watermark.match(/\S/) 
    : false;

  let has_image_watermark = export_settings_style.image_watermark 
    ? export_settings_style.image_watermark != "none" 
    : false;

  let default_include_in_header = mode === "galley" && !export_settings_style.text_watermark_footer
    ? true
    : export_settings_style.text_watermark_header && export_settings_style.text_watermark_header == true

  let default_text = mode === "galley" ? "For Review Only | Made with Hederis" : "";

  let watermark_font_size = export_settings_style.watermark_font_size
    ? export_settings_style.watermark_font_size
    : "0pt";

  watermark_font_size = validate_watermark_size(watermark_font_size, mode, has_image_watermark);

  return {
    has_text_watermark, 
    has_image_watermark, 
    default_include_in_header, 
    default_text, 
    watermark_font_size
  };
}

module.exports = {
  get_watermark_settings,
  validate_watermark_size
};