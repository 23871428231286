import { defaultTheme, ThemeProvider, Tab, mergeTheme } from "evergreen-ui";

export default function HedTab(props) {
  const colors = {
    PrimaryDark: "#5D597A",
    PrimaryMedium: "#373550",
    PrimaryLight: "#B3B0C6",
    PrimaryLightest: "#E8E7EE",
    SecondaryMedium: "#07C896",
    textBlack: "#000000"
  };
  const theme = mergeTheme(defaultTheme, {
    components: {
      Tab: {
        ...defaultTheme.components.Tab,
        appearances: {
          heddark: {
            color: '#ffffff',
            paddingX: 12,
            paddingY: 8,
            borderRadius: 2,
            borderBottomWidth: 2,
            borderBottomColor: '#373550',
            borderBottomStyle: "solid",
            backgroundColor: '#5D597A',
            _hover: {
              backgroundColor: '#5D597A',
            },
            _active: {
              backgroundColor: '#5D597A',
            },
            _focus: {
              boxShadow: '0 0 0 0px #5D597A',
            },
          },
          hedmedium: {
            color: '#ffffff',
            paddingX: 12,
            paddingY: 8,
            borderRadius: 2,
            backgroundColor: '#373550',
            _hover: {
              color: '#ffffff',
              backgroundColor: '#373550',
            },
            _active: {
              color: '#ffffff',
              backgroundColor: '#373550',
            },
            _focus: {
              boxShadow: '0 0 0 0px #373550',
            },
          },
          hedlight: {
            color: '#5D597A',
            paddingX: 12,
            paddingY: 8,
            borderRadius: 2,
            backgroundColor: '#B3B0C6',
            _hover: {
              color: '#ffffff',
              backgroundColor: '#B3B0C6',
            },
            _active: {
              color: '#ffffff',
              backgroundColor: '#B3B0C6',
            },
            _focus: {
              boxShadow: '0 0 0 0px #B3B0C6',
            },
          },
          hedlightest: {
            color: '#5D597A',
            paddingX: 12,
            paddingY: 8,
            borderRadius: 2,
            backgroundColor: '#E8E7EE',
            _hover: {
              color: '#ffffff',
              backgroundColor: '#E8E7EE',
            },
            _active: {
              color: '#ffffff',
              backgroundColor: '#E8E7EE',
            },
            _focus: {
              boxShadow: '0 0 0 0px #E8E7EE',
            },
          },
        },
      },
    },
  })

  return (
    <ThemeProvider value={theme}>
      <Tab {...props} appearance={props.appearance}>{props.children}</Tab>
    </ThemeProvider>
  )
}