import React from "react";
import ReactDOM from "react-dom";
// prettier-ignore
import { Pane, Text, Heading, Popover, Dialog, Button, Select, Menu, TextInputField, toaster, Combobox, Paragraph } from "evergreen-ui";

import { firebase, db, storage } from "../../fire.js";
import APIClient from "../../helpers/api_client";
import { FireHelpers } from "../../../api/app_shared/fire_helpers.js";
const API = new APIClient(firebase);
const FH = new FireHelpers(db, firebase);

import HedButton from "../shared/HedButton.js";

export default class SnapshotRestorer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      project_snapshots: [],
      // when the user clicks the createSnapshot button, this controls if the modal is shown
      restore_snapshot: false,
      snapshot_name: false,
      // flag for working state - when we're snapshotting
      isLoading: false,
    };
  }
  async componentDidMount() {
    await this.loadSnapshots();
  }

  async loadSnapshots() {
    let props = this.props;
    let storageRef = storage.ref();
    // prettier-ignore
    let project_path = `${props.project.group}/${props.id}/snapshots/`;
    let ref = storageRef.child(project_path);
    let all_snapshots = await ref.listAll();
    let p_snapshots = all_snapshots.prefixes.map(({ name }) => {
      return name;
    });
    this.setState({ project_snapshots: p_snapshots });
  }

  onRestoreCancel() {
    this.setState({ snapshot_name: false, restore_snapshot: false });
  }

  async onRestoreSubmit(s_name) {
    let storageRef = storage.ref();
    let props = this.props;
    let { snapshot_name, restore_snapshot } = this.state;
    this.setState({ isLoading: true });

    // copy all html, config, pdf, epubs, output_docx to a new snapshot folder

    let child_data;
    let snapshot_path = snapshot_name;

    let sources = [
      "advanced_config",
      "baked_html",
      "html",
      "output_docx",
      "pdf",
      "epub"
    ];

    let project_path = `${props.project.group}/${props.id}/`;

    sources.forEach(async function(src) {
      let subfolder = `${project_path}${src}/`;
      // let snapshot_path = `snapshots/${snapshot_path}/${src}/`;
      // get a list of all the files in the folder
      let myref = storageRef.child(subfolder);
      let all_files = await myref.listAll();

      // copy all the files to the snapshot folder
      all_files.items.forEach(async function({ fullPath, name }) {
        let main_path = `${project_path}${src}/${name}`;
        let old_path = `${project_path}snapshots/${s_name}/${src}/${name}`;

        let url = await storage.ref(old_path).getDownloadURL();
        let res = await fetch(url);
        if (name.match(/.+\.pdf$/)) {
          child_data = await res.blob();
          await storage
            .ref()
            .child(main_path)
            .put(child_data, {contentType: "application/pdf"});
        } else if (name.match(/.+\.epub$/) || name.match(/.+\.docx$/)) {
          child_data = await res.blob();
          await storage
            .ref()
            .child(main_path)
            .put(child_data, {contentType: "application/octet-stream"});
        } else {
          child_data = await res.text();
          await storage
            .ref()
            .child(main_path)
            .putString(child_data);
        }

      });
    });

    // restore the section_meta
    let meta_path = `${project_path}snapshots/${s_name}/section_meta.json`;
    let meta_url = await storage.ref(meta_path).getDownloadURL();
    let meta_res = await fetch(meta_url);
    let section_meta = await meta_res.json();
    this.props.updateProjectMeta({ section_meta });

    // restore the style_list
    let style_path = `${project_path}snapshots/${s_name}/style_list.json`;
    let style_url = await storage.ref(style_path).getDownloadURL();
    let style_res = await fetch(style_url);
    let style_list = await style_res.json();
    this.props.updateProjectMeta({ style_list });

    await this.loadSnapshots();

    toaster.success("Snapshot restored! Your files have been reverted.");
    toaster.success("Please refresh your browser window to see the restored files.");

    this.setState({ restore_snapshot: false, snapshot_name: false, isLoading: false });
  }

  render() {
    return (
      <Pane
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        paddingX={16}
        width="100%"
      >
        <HedButton
          appearance="hedsecondary"
          onClick={e => {
            this.setState({ restore_snapshot: true });
          }}
          width="200px"
          marginRight={16}
        >
          Restore from Snapshot: 
        </HedButton>
        <Combobox
          items={this.state.project_snapshots}
          onChange={selected => this.setState({ snapshot_name: selected })}
        />
        <RestoreSnapshotModal
          s_name={this.state.snapshot_name}
          {...this.props}
          {...this.state}
          onRestoreSubmit={this.onRestoreSubmit.bind(this)}
          onRestoreCancel={this.onRestoreCancel.bind(this)}
        />
      </Pane>
    );
  }
}

// for from within and default, not import
// function CloneTemplateModal(props) {
class RestoreSnapshotModal extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let { props } = this;
    let isShown =
      props.restore_snapshot && props.snapshot_name
        ? true
        : false;

    return (
      <Dialog
        isShown={isShown}
        isConfirmLoading={props.isLoading}
        title="Are You Sure?"
        onCloseComplete={() => props.onRestoreCancel()}
        confirmLabel={!props.isLoading ? "Restore from Snapshot" : "Restoring snapshot..."}
        onConfirm={() => {
          props.onRestoreSubmit(this.props.s_name);
        }}
      >
        <Paragraph marginBottom={8}>
          This will overwrite the current text, PDF, and EPUB files with the snapshot versions.
          If you think you might want to undo this action, click Cancel and take a new snapshot
          of the current files first before proceeding.
        </Paragraph>
      </Dialog>
    );
  }
}