import React from "react";
import ReactDOM from "react-dom";
// prettier-ignore
import { Pane, Text, Icon, Heading, Button, IconButton, Popover, Position, Menu, Avatar, Alert, HelpIcon} from "evergreen-ui";

import TopWidget from "./top_widget.js";
import { LoadingPane, ProjectTabs } from "./common.js";
import { firebase, db } from "../../fire.js";

let logOut = () => {
  firebase
    .auth()
    .signOut()
    .then(() => {
      let url = window.location.toString().split("#")[0];
      window.location = url;
    });
};

export default class TopNav extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const tab_list = [
      { key: "meta",   label: [<Text key="b" size={400}>Edit Info</Text>]  },
      { key: "settings",   label: [<Text key="b" size={400}>Settings & Invites</Text>]  },
      { key: "edit",   label: [<Text key="b" size={400}>Edit Text</Text>]  },
      { key: "compare",   label: [<Text key="b" size={400}>Compare Text</Text>]  },
      { key: "design",   label: [<Text key="b" size={400}>Design</Text>]  },
      { key: "dash",   label: [<Text key="b" size={400}>Dashboard</Text>]  },
    ];

    // use this directly so people can log out even if they're not fully logged in, ie email not verified
    let auth_user = firebase.auth().currentUser;

    let is_chrome = true;
    let show_alert = false;

    if (navigator.userAgent.indexOf("Chrome") === -1) {
      is_chrome = false;
    }

    let top_control_pane = this.props.auth_user ? (
      <Pane display="flex" flexDirection="row" alignItems="center">
        <a href="https://docs.hederis.com/" target="_blank">
          <Text>User Guide</Text>
        </a>
        <TopWidget {...this.props} />
      </Pane>
    ) : (
      <Pane>
        {auth_user ? (
          <Text cursor="pointer" onClick={logOut}>
            Log out
          </Text>
        ) : (
          ""
        )}
      </Pane>
    );
    let chrome_warning = !is_chrome ? (
      <Pane
        display="flex"
        marginBottom={0}
        padding={16}
        backgroundColor="#FF4C4C"
      >
        <Text marginRight={8} color="white" fontWeight="bold">
          It looks like you are not using the Chrome browser. 
          Hederis currently only supports Chrome - download it here: 
        </Text>
        <a href="https://www.google.com/chrome/" target="_blank">
          <Text color="white" textDecoration="underline" fontWeight="bold"> 
            https://www.google.com/chrome/
          </Text>
        </a>
      </Pane>
    ) : (
      ""
    );
    let current_alert = show_alert ? (
      <Pane
        display="flex"
        marginBottom={0}
        padding={0}
        alignItems="center"
        justifyContent="center"
      >
        <Alert
          intent="warning"
          title="Issues with exporting"
          marginBottom={32}
        >
          We are currently experiencing issues with exporting/building output files. Our engineers are hard at work and should have it fixed shortly.
        </Alert>
      </Pane>
    ) : (
      ""
    );

    let hash = window.location.hash || `#/`;
    console.log(hash);

    return (
      <Pane width="100%">
        <Pane
          display="flex"
          marginBottom={0}
          padding={8}
          background="tint2"
          borderRadius={3}
        >
          <Pane flex={1} alignItems="center" display="flex">
            <Heading size={600} marginRight={16}>
              <a href="#/" id="logo" />
            </Heading>
            {hash.indexOf("/projects/") > -1 && (
              <ProjectTabs
                tab_list={tab_list}
                {...this.props}
                // slug={props.params.id}
                // area={props.params.area}
              />
            )}
          </Pane>
          {top_control_pane}
        </Pane>
        {chrome_warning}
        {current_alert}
      </Pane>
    );
  }
}
