// HYPHENATION

function addHyphens(node, opt={}) {
  var hyp = opt["hyp"];
  if (node.nodeType === 3 && node.textContent != undefined) {
    node.textContent = hyp.hyphenateText(node.textContent);
  }
}

function stripHyphens(node, opt={}) {
  if (node.nodeType === 3 && node.textContent != undefined) {
    node.textContent = node.textContent.replace(/\u00ad/g,"");
  }
}

function walkTheDOM(node, func, opt={}) {
  // skip baked lines
  func(node, opt);
  node = node.firstChild;
  while (node)
  {
    if ( 
      !(
        opt.hyphenate &&
        node.nodeType === 1 && 
        node.tagName.toLowerCase() === "span" && 
        node.className.match(/\bline\b/)
      )
    ) {
      walkTheDOM(node, func, opt);
    }
    node = node.nextSibling;
  }
}

function hypherHyphenate(html_content, template_builder_advanced, doc=document) {
  let res = html_content;
  try {
    let frag = doc.createDocumentFragment();
    // we can't set innerHTML directly on a document frag, hence the wrap
    let wrap = doc.createElement("div");
    wrap.id = "wrap";
    wrap.innerHTML = html_content;
    frag.appendChild(wrap);

    var minleft = template_builder_advanced['hyphens_minLeft'];
    var minright = template_builder_advanced['hyphens_minRight'];
    var exceptions = template_builder_advanced['hyphens_exceptions'];
    if (exceptions) {
      exceptions = exceptions.replace(/-/g,"‧");
    } else {
      exceptions = "";
    }

    var Hypher = require('hypher');

    var lang;

    if (template_builder_advanced.hasOwnProperty("hyphens_hyphenationLanguage")) {
      if (template_builder_advanced["hyphens_hyphenationLanguage"] === "be") {
        lang = require("hyphenation.be");
      } else if (template_builder_advanced["hyphens_hyphenationLanguage"] === "bn") {
        lang = require("hyphenation.bn");
      } else if (template_builder_advanced["hyphens_hyphenationLanguage"] === "ca") {
        lang = require("hyphenation.ca");
      } else if (template_builder_advanced["hyphens_hyphenationLanguage"] === "cs") {
        lang = require("hyphenation.cs");
      } else if (template_builder_advanced["hyphens_hyphenationLanguage"] === "da") {
        lang = require("hyphenation.da");
      } else if (template_builder_advanced["hyphens_hyphenationLanguage"] === "de") {
        lang = require("hyphenation.de");
      } else if (template_builder_advanced["hyphens_hyphenationLanguage"] === "el-monoton") {
        lang = require("hyphenation.el-monoton");
      } else if (template_builder_advanced["hyphens_hyphenationLanguage"] === "el-polyton") {
        lang = require("hyphenation.el-polyton");
      } else if (template_builder_advanced["hyphens_hyphenationLanguage"] === "en-gb") {
        lang = require("hyphenation.en-gb");
      } else if (template_builder_advanced["hyphens_hyphenationLanguage"] === "es") {
        lang = require("hyphenation.es");
      } else if (template_builder_advanced["hyphens_hyphenationLanguage"] === "fi") {
        lang = require("hyphenation.fi");
      } else if (template_builder_advanced["hyphens_hyphenationLanguage"] === "fr") {
        lang = require("hyphenation.fr");
      } else if (template_builder_advanced["hyphens_hyphenationLanguage"] === "grc") {
        lang = require("hyphenation.grc");
      } else if (template_builder_advanced["hyphens_hyphenationLanguage"] === "gu") {
        lang = require("hyphenation.gu");
      } else if (template_builder_advanced["hyphens_hyphenationLanguage"] === "hi") {
        lang = require("hyphenation.hi");
      } else if (template_builder_advanced["hyphens_hyphenationLanguage"] === "hu") {
        lang = require("hyphenation.hu");
      } else if (template_builder_advanced["hyphens_hyphenationLanguage"] === "hy") {
        lang = require("hyphenation.hy");
      } else if (template_builder_advanced["hyphens_hyphenationLanguage"] === "it") {
        lang = require("hyphenation.it");
      } else if (template_builder_advanced["hyphens_hyphenationLanguage"] === "kn") {
        lang = require("hyphenation.kn");
      } else if (template_builder_advanced["hyphens_hyphenationLanguage"] === "la") {
        lang = require("hyphenation.la");
      } else if (template_builder_advanced["hyphens_hyphenationLanguage"] === "lt") {
        lang = require("hyphenation.lt");
      } else if (template_builder_advanced["hyphens_hyphenationLanguage"] === "lv") {
        lang = require("hyphenation.lv");
      } else if (template_builder_advanced["hyphens_hyphenationLanguage"] === "ml") {
        lang = require("hyphenation.ml");
      } else if (template_builder_advanced["hyphens_hyphenationLanguage"] === "nb-no") {
        lang = require("hyphenation.nb-no");
      } else if (template_builder_advanced["hyphens_hyphenationLanguage"] === "nl") {
        lang = require("hyphenation.nl");
      } else if (template_builder_advanced["hyphens_hyphenationLanguage"] === "or") {
        lang = require("hyphenation.or");
      } else if (template_builder_advanced["hyphens_hyphenationLanguage"] === "pa") {
        lang = require("hyphenation.pa");
      } else if (template_builder_advanced["hyphens_hyphenationLanguage"] === "pl") {
        lang = require("hyphenation.pl");
      } else if (template_builder_advanced["hyphens_hyphenationLanguage"] === "pt") {
        lang = require("hyphenation.pt");
      } else if (template_builder_advanced["hyphens_hyphenationLanguage"] === "ru") {
        lang = require("hyphenation.ru");
      } else if (template_builder_advanced["hyphens_hyphenationLanguage"] === "sk") {
        lang = require("hyphenation.sk");
      } else if (template_builder_advanced["hyphens_hyphenationLanguage"] === "sl") {
        lang = require("hyphenation.sl");
      } else if (template_builder_advanced["hyphens_hyphenationLanguage"] === "sv") {
        lang = require("hyphenation.sv");
      } else if (template_builder_advanced["hyphens_hyphenationLanguage"] === "ta") {
        lang = require("hyphenation.ta");
      } else if (template_builder_advanced["hyphens_hyphenationLanguage"] === "te") {
        lang = require("hyphenation.te");
      } else if (template_builder_advanced["hyphens_hyphenationLanguage"] === "tr") {
        lang = require("hyphenation.tr");
      } else if (template_builder_advanced["hyphens_hyphenationLanguage"] === "uk") {
        lang = require("hyphenation.uk");
      } else {
        lang = require("hyphenation.en-us");
      } 
    } else {
      lang = require("hyphenation.en-us");
    }

    lang['exceptions'] = exceptions;

    if (minleft) {
      lang.leftmin = parseInt(minleft);
    }
    if (minright) {
      lang.rightmin = parseInt(minright);
    }

    var h = new Hypher(lang);

    walkTheDOM(frag, addHyphens, {"hyp":h, "hyphenate":true});
    res = wrap.innerHTML;
  } catch (error) {
    console.error(error);
  }
  return res;
}

function hypherDehyphenate(frag) {
  try {
    walkTheDOM(frag, stripHyphens);
  } catch (error) {
    console.error(error);
  }
  return frag;
}

module.exports = {
  hypherHyphenate,
  hypherDehyphenate
};