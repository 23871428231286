import React from "react";
import ReactDOM from "react-dom";
// prettier-ignore
import {Pane, Text, Heading, Code, Button, Spinner, Paragraph, IconButton, Icon, RefreshIcon, AlignCenterIcon} from "evergreen-ui";

// props.scrollToPage = call the frame function scrollToPage

export default function PagesWidget(props) {
  return (
    <Pane display="flex" flexDirection="row" flexWrap="wrap" width="70px">
      <Pane width="30px">&nbsp;</Pane>
      {(props.is_loading || props.is_saving) ? `` : get_pages(props.page_count, props.scrollToPage)}
    </Pane>
  );
}

function PageItem(props) {
  return (
    <Pane marginBottom={4}>
      <Pane className="pageiconitem" onClick={e => props.scrollToPage(props.counter)}>&nbsp;</Pane>
      <Paragraph size={300} textAlign="center">{props.counter}</Paragraph>
    </Pane>
  );
}

function get_pages(page_count, scrollToPage) {
  return [...Array(page_count).keys()].map(n => {
    return (
      <PageItem counter={n+1} scrollToPage={scrollToPage} key={`pageitem-${n}`} />
    );
  });
}