// does not include `user_${props.hederis_user.uid}` logic !
async function check_discount({ project_discount_code, group, use_gte, FH }) {
  const discount_obj = await FH.get_disco(project_discount_code);
  // now do some tests, if it fails, return false
  if (!discount_obj) {
    return { is_alowed: false, kind: "not-found" };
  }

  if (discount_obj.use_by) {
    let use_by = discount_obj.use_by.toDate();
    // console.log(use_by);
    let now = new Date();
    if (now > use_by) {
      // console.log("expired");
      // toaster.warning("Discount Code Expired", { duration: 20 });
      // return false;
      return { is_alowed: false, kind: "expired" };
    }
  }
  // prettier-ignore
  const existing_arr = await FH.get_projects_by_disco_group(project_discount_code, group);
  // console.log(existing_arr);
  const max_uses = discount_obj.max_uses || 1;

  // first case is for project creation
  // second (use_gte=false) is for that first charge/ bill step
  if (use_gte === true) {
    if (existing_arr.length >= max_uses) {
      return { is_alowed: false, kind: "used" };
    }
  } else if (use_gte === false) {
    if (existing_arr.length > max_uses) {
      return { is_alowed: false, kind: "used" };
    }
  }

  return { is_allowed: true, discount_obj };
}

async function get_billing_user_stripe_id(db, { project }) {
  let is_real_group = !project["group"].startsWith("user_");
  let billing_user = is_real_group
    ? await get_group_billing_user(db, project["group"])
    : (await db
        .collection("users")
        .doc(project["creator"])
        .get()).data();

  // log(project.group, is_real_group, billing_user);
  // log(project.group, is_real_group);

  let billing_user_stripe_id = false;
  try {
    billing_user_stripe_id = billing_user["billing_info"]["id"];
  } catch (e) {
    // log("No billing user setup!");
  }
  return billing_user_stripe_id;
}

// const log =

// simply looks up a given group_id and
// then looks up that groups billing user details
// prettier-ignore
async function get_group_billing_user(db, group_id) {
  let group = await db.collection("groups").doc(group_id).get();
  if (!group.exists) { return }
  let group_data = group.data();
  if (!group_data['billing_user']) { return }
  let billing_user = await db.collection("users").doc(group_data['billing_user']).get()
  if (billing_user.exists) {
    return billing_user.data();
  }
}

module.exports = {
  check_discount,
  get_group_billing_user,
  get_billing_user_stripe_id,
};
