import React from "react";
import ReactDOM from "react-dom";
// prettier-ignore
import { Pane, Text, Heading, Popover, Dialog, Button, Select, Menu, TextInputField, toaster, Combobox, Paragraph } from "evergreen-ui";

import { make_snapshot } from "../../helpers/make_snapshot.js";
import HedButton from "../shared/HedButton.js";

import { firebase, db, storage } from "../../fire.js";
import APIClient from "../../helpers/api_client";
import { FireHelpers } from "../../../api/app_shared/fire_helpers.js";
const API = new APIClient(firebase);
const FH = new FireHelpers(db, firebase);

export default class SnapshotCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      project_snapshots: false,
      // when the user clicks the createSnapshot button, this controls if the modal is shown
      create_snapshot: false,
      snapshot_name: false,
      // flag for working state - when we're snapshotting
      isLoading: false,
    };
  }
  async componentDidMount() {
    await this.loadSnapshots();
  }

  async loadSnapshots() {
    let props = this.props;
    let storageRef = storage.ref();
    // prettier-ignore
    let project_path = `${props.project.group}/${props.id}/snapshots/`;
    let ref = storageRef.child(project_path);
    let all_snapshots = await ref.listAll();
    let p_snapshots = all_snapshots.prefixes.map(({ name }) => {
      return name;
    });
    this.setState({ project_snapshots: p_snapshots });
  }

  onCreateSnapshot() {
    this.setState({ create_snapshot: true });
  }

  onSnapshotCancel() {
    this.setState({ snapshot_name: false, create_snapshot: false });
  }

  async onSnapshotSubmit(new_name) {

    this.setState({ isLoading: true });

    let res = make_snapshot(new_name, this.props);

    await this.loadSnapshots();

    toaster.success("Snapshot created! Find it in your project settings.");

    this.setState({ create_snapshot: false, snapshot_name: false, isLoading: false });
  }

  render() {
    return (
      <Pane width="100%" display="flex" flexDirection="column" marginBottom={16}>
        <HedButton
          height={40}
          marginTop={8}
          marginLeft={0}
          appearance="hedprimary"
          onClick={e => {
            this.setState({ create_snapshot: true });
          }}
        >
          Create a Snapshot
        </HedButton>
        <CreateSnapshotModal
          {...this.props}
          {...this.state}
          onSnapshotSubmit={this.onSnapshotSubmit.bind(this)}
          onSnapshotCancel={this.onSnapshotCancel.bind(this)}
        />
      </Pane>
    );
  }
}

// for from within and default, not import
// function CloneTemplateModal(props) {
class CreateSnapshotModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: "" };
  }
  render() {
    let { props } = this;
    let isShown =
      props.create_snapshot && !props.snapshot_name
        ? true
        : false;

    return (
      <Dialog
        isShown={isShown}
        isConfirmLoading={props.isLoading}
        title="Create a Snapshot"
        onCloseComplete={() => props.onSnapshotCancel()}
        confirmLabel={!props.isLoading ? "Create a Snapshot" : "Creating snapshot..."}
        onConfirm={() => {
          // TODO validation and regex
          let val = this.state.value;
          val = val.replace(/\W/g, "_");
          if (props.project_snapshots.indexOf(val) > -1) {
            toaster.warning("There is already a snapshot with this name.");
            return;
          } else {
            props.onSnapshotSubmit(val);
          }
        }}
      >
        <Paragraph marginBottom={8}>
          A "Snapshot" is a copy of the current state of your project files. 
          You can restore your project files to any snapshot state in the Project Settings.
        </Paragraph>
        <TextInputField
          label="Snapshot name"
          value={this.state.value}
          hint="Non-alphanumeric characters will be replaced with underscores"
          placeholder="Your_Snapshot_Name"
          onChange={e => this.setState({ value: e.target.value })}
        />
      </Dialog>
    );
  }
}