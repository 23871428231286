import React from "react";
import ReactDOM from "react-dom";
import { useState, useEffect } from "react";

// prettier-ignore
import { Pane, Code, Text, Paragraph, Strong, Dialog, Heading, Button, IconButton, TextInputField, TabNavigation, Tab, Badge, Textarea, Avatar, Autocomplete, toaster, TextInput, Switch, ClipboardIcon, PlusIcon, MinusIcon, CrossIcon, DollarIcon} from "evergreen-ui";

import { LoadingPane } from "./common.js";
import HedButton from "./HedButton.js";

import { firebase, db } from "../../fire.js";
import { validate_email, get_random_string, get_arr } from "../../helpers/util";
// import { Send_Email } from "../../helpers/api";

import APIClient from "../../helpers/api_client";
import { FireHelpers } from "../../../api/app_shared/fire_helpers.js";

const API = new APIClient(firebase);
const FH = new FireHelpers(db, firebase);

export default class InviteManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invite_list: false,
      confirming: false,
      email: "",
      post_add: "",
      self_join: false,
    };
  }
  async componentDidMount() {
    // console.log(this.props);
    // prettier-ignore
    let group = this.props.kind === "project_access" ? this.props.project.group : this.props.target;
    let invite_list = await FH.get_invite_list(
      this.props.target,
      this.props.kind,
      group
    );
    this.setState({ invite_list });
    this.setState({ self_join: this.props.self_join });
    // console.log(invite_list);
  }
  async remove_collaborator(invite) {
    let { invite_code } = invite;
    let res = await API.set_claims(invite_code, true);
    if (res.message !== "ok") {
      toaster.closeAll();
      toaster.danger("There was an error removing this collaborator", {
        duration: 15,
      });
      return;
    } else {
      if (invite.kind === "group_access") {
        let owners = this.props.group_owners;
        if (owners.includes(invite.to_uid)) {
          await this.props.onGroupOwnersChange(
            owners.filter(a => a !== invite.to_uid)
          );
        }
      }
      this.delete_invite(invite);
    }
  }
  async delete_invite(invite) {
    let { invite_code } = invite;
    await FH.delete_invite(invite_code);
    toaster.notify("Deleted invite!");
    // prettier-ignore
    let group = this.props.kind === "project_access" ? this.props.project.group : this.props.target

    // lazy - just reload it from server
    let invite_list = await FH.get_invite_list(
      this.props.target,
      this.props.kind,
      group
    );
    this.setState({ invite_list });
  }
  // allow group members to add themselves to projects
  async set_auto_join() {
    let res = await FH.set_group_self_join(
      this.props.target,
      !this.state.self_join
    );
    this.setState({ self_join: !this.state.self_join });
  }
  // confusingly, this is where we actually create the invite
  async onClose(did_confirm) {
    toaster.closeAll();
    let { email, invite_list } = this.state;
    let { kind, target, hederis_user, project } = this.props;
    // let { title, group } = project;
    // prettier-ignore
    let group = kind === "project_access" ? this.props.project.group : this.props.target

    // let assoc_group = group.startsWith("user_") ? null : group;
    let assoc_group = group;
    if (did_confirm) {
      this.setState({ is_loading: true });
      toaster.notify("Inviting user!");
      let invite_code = get_random_string(40);
      let from = hederis_user["displayName"];

      let invite_data = {
        invite_code,
        email,
        from,
        kind,
        target,
        assoc_group,
      };
      // console.log(invite_data);
      await FH.create_invite(invite_data);
      let invite_url =
        kind == "project_access"
          ? get_project_invite_url(target, invite_code)
          : get_group_invite_url(target, invite_code);
      let details = {
        from_user: from,
        invite_url,
      };
      if (kind === "project_access") {
        details["project_title"] = this.props.target_name;
      } else if (kind == "group_access") {
        details["group_display_name"] = this.props.target_name;
      }
      // project_title: title,

      // group_display_name
      invite_list.push(invite_data);
      toaster.notify("Sending invite email");
      let email_kind =
        this.props.kind === "project_access"
          ? "project_invite"
          : "group_invite";
      await API.send_email(email_kind, email, details);
      toaster.closeAll();
      toaster.success("Invite Email Sent!");
      this.setState({
        email: "",
        confirming: false,
        invite_list,
        post_add: invite_url,
        is_loading: false,
      });
      // await console.log(invite_data);
    } else {
      // this actually gets called no matter what ugh
      // console.log("canceled ");
      this.setState({ email: "", confirming: false });
    }
  }
  render_invite_list() {
    let kind_text = this.props.kind.replace("_access", "");
    return this.state.invite_list === false ? (
      <LoadingPane />
    ) : this.state.invite_list.length == 0 ? (
      <Pane padding="8" margin="8" background="tint1">
        <Text>You haven't invited anyone to the {kind_text} yet.</Text>
      </Pane>
    ) : (
      this.state.invite_list.map((invite, i) => {
        // prettier-ignore
        let onCpClick = ()=> copy_to_clipboard(invite.kind == "project_access" ? get_project_invite_url(invite.target, invite.invite_code) : get_group_invite_url(invite.target, invite.invite_code))
        let link_cp_btn = (
          <HedButton appearance="hedsecondary" height={24} iconBefore={ClipboardIcon} onClick={onCpClick}>
            Copy Invite Link
          </HedButton>
        );
        const maybe_group_ownership_btn =
          this.props.kind === "group_access" ? (
            <HedButton 
              appearance="hedsecondary"
              iconBefore={
                !this.props.group_owners.includes(invite.to_uid)
                  ? PlusIcon
                  : MinusIcon
              }
              height={24}
              onClick={async () => {
                const owners = this.props.group_owners;
                if (!owners.includes(invite.to_uid)) {
                  await this.props.onGroupOwnersChange([
                    ...owners,
                    invite.to_uid,
                  ]);
                  toaster.success("Added Group Co-Owner");
                } else {
                  await this.props.onGroupOwnersChange(
                    owners.filter(a => a !== invite.to_uid)
                  );
                  toaster.success("Removed Group Co-Owner");
                }
              }}
              appearance="primary"
              intent={
                !this.props.group_owners.includes(invite.to_uid)
                  ? "warning"
                  : "danger"
              }
            >
              {!this.props.group_owners.includes(invite.to_uid)
                ? `Make Group Co-Owner`
                : `Remove Group Co-Owner `}
            </HedButton>
          ) : (
            ``
          );

        return (
          <Pane
            key={i}
            padding={20}
            margin={8}
            background="tint1"
            display="flex"
            justifyContent="space-around"
          >
            <Text width={150}>{invite.email}</Text>
            <Text width={150}>{invite.displayName}</Text>
            <Text width={200}>
              {invite.has_accepted ? maybe_group_ownership_btn : link_cp_btn}
            </Text>
            <Text width={200}>
              {invite.has_accepted
                ? `Accepted: ${get_date_string(invite.accepted_on)}`
                : `Invited: ${
                    invite.created && invite.created.nanoseconds
                      ? get_date_string(invite.created)
                      : "Just Now"
                  }`}
            </Text>
            <Button
              iconBefore={CrossIcon}
              height={24}
              onClick={() => invite.has_accepted ? this.remove_collaborator(invite) : this.delete_invite(invite)}
            >
              Remove
            </Button>
          </Pane>
        );
      })
    );
  }
  render() {
    // console.log(this.props);
    return (
      <Pane padding={16} display="flex" flexDirection="column">
        {this.props.kind === "project_access" ? null : (
          <GroupBillingUserSetting {...this.props} {...this.state} />
        )}
        <Heading size={700} marginBottom={this.props.kind === "project_access" ? 32 : 16}>
          {this.props.kind === "project_access"
            ? "Project Collaborators"
            : "Group Members"}
        </Heading>

        {this.props.kind === "group_access" && (
          <Pane display="flex" flexDirection="row" alignItems="flex-start" justifyContent="flex-start" marginBottom={32}>
            <Switch checked={this.state.self_join} onChange={(e) => this.set_auto_join()} />
            <Text marginLeft={8}>Allow group members to add themselves to projects</Text>
          </Pane>
        )}

        <Pane display="flex" flexDirection="row">
          <Heading size={500} whiteSpace="nowrap" marginTop={8} marginRight={8}>
            Add Collaborators:
          </Heading>
          <TextInput
            placeholder="some@example.com"
            height={32}
            value={this.state.email}
            onChange={e => this.setState({ email: e.target.value })}
            onKeyDown={e => {
              if (e.which == 13) {
                this.setState({ confirming: true });
                // console.log("enter pressed");
              }
            }}
          />
          <HedButton
            disabled={!validate_email(this.state.email)}
            appearance="hedprimary"
            marginLeft={4}
            onClick={() => this.setState({ confirming: true })}
          >
            Send Invite
          </HedButton>
        </Pane>
        <Pane>{this.render_invite_list()}</Pane>
        <ConfirmDialog
          {...this.state}
          {...this.props}
          onClose={this.onClose.bind(this)}
        />
        <PostAddDialog
          {...this.state}
          {...this.props}
          onClose={() => this.setState({ post_add: "" })}
        />
      </Pane>
    );
  }
}

function GroupBillingUserSetting(props) {
  // the current hederis_user
  const cur_user = props.passed_props.hederis_user;

  const { group_billing_user, invite_list } = props;
  if (invite_list === false) {
    return ``;
  }
  if (props.passed_props.hederis_user.uid === group_billing_user) {
    return (
      <Pane>
        <Heading size={700}>Group Billing User</Heading>
        <Pane marginY={20}>
          <Text>
            <Strong>You</Strong> ({cur_user.displayName},{" "}
            <Code>{cur_user.email}</Code>) are the current billing user for this
            group and your billing info is{" "}
            {cur_user.billing_info ? null : <Strong>not</Strong>} setup.
          </Text>
        </Pane>
      </Pane>
    );
  }
  // the current user is not the billing user, sooo find them
  // the invite representing the billing user
  const b_invite = invite_list.find(inv => inv["to_uid"] == group_billing_user);

  return (
    <Pane>
      <Heading size={700}>Group Billing User</Heading>
      <Pane marginY={20}>
        <Text>
          {!b_invite ? (
            "The group's creator"
          ) : (
            <Strong>{b_invite.email}</Strong>
          )}{" "}
          is the current billing user for this group. As one of the owners of
          this group, <Strong> you </Strong> can:{" "}
          <Button
            iconBefore={DollarIcon}
            height={24}
            onClick={() => {
              props.onGroupBillingUserChange(cur_user.uid);
            }}
          >
            Make yourself billing user
          </Button>
        </Text>
      </Pane>
    </Pane>
  );
}

function PostAddDialog(props) {
  return (
    <Dialog
      isShown={!!props.post_add}
      title="Collaborator Added"
      onCloseComplete={props.onClose}
      confirmLabel="Done"
      hasCancel={false}
    >
      <Paragraph>
        We've sent an email inviting them. You can also give them the link
        below:
      </Paragraph>
      <Pane margin={4} padding={8} display="flex" justifyContent="center">
        <TextInput value={props.post_add} readOnly />
        <Button
          iconBefore={ClipboardIcon}
          appearance="primary"
          intent="success"
          onClick={async e => {
            copy_to_clipboard(props.post_add);
          }}
        >
          Copy Link
        </Button>
      </Pane>
      <Paragraph color="muted" size={300} textAlign="center">
        Anyone with this link can accept the invite, and links can only be
        accepted once.
      </Paragraph>
      <Paragraph color="muted" size={300} textAlign="center">
        Be careful who you share it with!
      </Paragraph>
    </Dialog>
  );
}
function ConfirmDialog(props) {
  return (
    <Dialog
      isShown={props.confirming}
      title="Add Collaborator?"
      isConfirmLoading={props.is_loading}
      onCloseComplete={() => {
        props.onClose(false);
        // console.log("onCloseComplete");
        // happens if they cancel or when it closes due to state
      }}
      onConfirm={close => {
        // they said yes
        props.onClose(true);
        // console.log("onConfirm ");
        // console.log("onConfirm, should to set state to close");
      }}
      confirmLabel={`Add them to the ${props.kind.replace("_access", "")}`}
    >
      <Pane
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Paragraph>Are you sure you want to add:</Paragraph>

        <Pane marginTop={16}>
          <Code padding={8}>{props.email}</Code>
        </Pane>

        <Paragraph marginTop={16}>
          to the {props.kind.replace("_access", "")}{" "}
          <em>{props.target_name}</em>?
        </Paragraph>
      </Pane>
    </Dialog>
  );
}

// helper for human readable dates from ts
function get_date_string(obj) {
  let d = obj;
  // return obj.toString();
  if (obj.seconds && obj.nanoseconds) {
    d = new Date(obj.seconds * 1000);
  }
  let options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return d.toLocaleDateString("en-US", options);
}

async function copy_to_clipboard(text) {
  try {
    await navigator.clipboard.writeText(text);
    toaster.success("Copied Link to Clipboard");
  } catch (err) {
    toaster.warning(
      "Your browser doesn't support copying like this. Sorry, you'll have to do it manually."
    );
    console.error("Failed to copy: ", err);
  }
}

function get_project_invite_url(target, invite_code) {
  return `${location.origin}/#/projects/${target}/invited/${invite_code}`;
}
function get_group_invite_url(target, invite_code) {
  return `${location.origin}/#/group/${target}/invited/${invite_code}`;
}
