const firebase = require('firebase/app');
require('firebase/firestore');
require('firebase/storage');
require('firebase/auth');

console.log(process.env.NODE_ENV);

const IS_DEV = process.env.NODE_ENV === "development";

let firebase_config = IS_DEV
  ? {
      apiKey: process.env.GFB_API_KEY_DEV,
      authDomain: process.env.GFB_AUTH_DOMAIN_DEV,
      databaseURL: process.env.GFB_DATABASE_URL_DEV,
      projectId: process.env.GFB_PROJECT_ID_DEV,
      storageBucket: process.env.GFB_STORAGE_BUCKET_DEV,
      messagingSenderId: process.env.GFB_MESSAGING_SENDER_ID_DEV,
    }
  : {
      apiKey: process.env.GFB_API_KEY,
      authDomain: process.env.GFB_AUTH_DOMAIN,
      databaseURL: process.env.GFB_DATABASE_URL,
      projectId: process.env.GFB_PROJECT_ID,
      storageBucket: process.env.GFB_STORAGE_BUCKET,
      messagingSenderId: process.env.GFB_MESSAGING_SENDER_ID,
    };

firebase.initializeApp(firebase_config);

let db = firebase.firestore();
// db.settings({
//   timestampsInSnapshots: true,
// });

let storage = firebase.storage();

export { firebase, db, storage, IS_DEV };