"use strict";

// https://stripe.com/docs/api/charges/object#charge_object-amount
// "100 cents to charge $1.00"
// 200 dollars
// const PRODUCT_COST = 229 * 100;

const product_prices = {
  typeset: 199 * 100,
  galley: 119 * 100,
  galley_upgrade_to_standard: 99 * 100,
};

module.exports = product_prices;
