import React from "react";
import ReactDOM from "react-dom";
// import { useState, useEffect } from "react";

// prettier-ignore
import { Pane, Text, Icon, Heading, Button, IconButton, Popover, Position, Menu, Paragraph, Avatar, toaster, Alert, Strong, Code, CreditCardIcon} from "evergreen-ui";

import { firebase, db } from "../../fire.js";
import APIClient from "../../helpers/api_client";
const API = new APIClient(firebase);

let stripe = Stripe(
  process.env.NODE_ENV === "production"
    ? process.env.STRIPE_LIVE_PUBLIC_KEY
    : process.env.STRIPE_TESTING_PUBLIC_KEY
);
let elements = stripe.elements();

export default class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = { show_update_card: false };
  }
  componentWillUnmount() {
    // console.log("will unmount");
    this.card.unmount();
    this.card.destroy();
  }
  componentDidMount() {
    var style = {
      base: {
        // Add your base input styles here. For example:
        fontSize: "16px",
        color: "#32325d",
      },
    };
    this.card = elements.create("card", { style: style });
    this.card.mount("#stripe-card-element");
    // TODO, convert this to actual react as much as possible, put errors in state here, disable button until it's valid, etc
    this.card.addEventListener("change", function(event) {
      var displayError = document.getElementById("stripe-card-errors");
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = "";
      }
    });
  }
  async handleSubmit(e) {
    e.preventDefault();
    let { hederis_user } = this.props;
    let result = await stripe.createToken(this.card);
    if (result.error) {
      var errorElement = document.getElementById("stripe-card-errors");
      errorElement.textContent = result.error.message;
    } else {
      // prettier-ignore
      let response = await API.create_stripe_customer(result.token, hederis_user.uid, hederis_user.email)
      toaster.success("Billing Information Added!");
      console.log(response);
      // super hacky for now, just reload the page
      window.location.reload(false);
    }
  }
  // https://stripe.com/docs/saving-cards
  // https://stripe.com/docs/stripe-js/elements/quickstart
  // https://github.com/firebase/functions-samples/blob/master/stripe/functions/index.js
  render() {
    // 2023-07-22: SUPPRESS BILLING DUE TO BUGS
    let canBill = ["nellie+7@hederis.com", "elanor_m@hotmail.com"];
    let HIDE_BILLING = canBill.indexOf(this.props.hederis_user.email) > -1 ? false : true;

    let props = this.props;
    let stripe_el_style = HIDE_BILLING ? { display: "none" } : {};
    return (
      <Pane display="flex" flexDirection="column" alignItems="center">
        <Pane width={600} marginTop={16}>
          <Heading>Your Account</Heading>
          <Paragraph>Name: {props.hederis_user.displayName}</Paragraph>
          <Paragraph>Email: {props.hederis_user.email}</Paragraph>
          {props.groups.length ? (
            <Paragraph>
              Groups You Belong To: {props.groups.join(", ")}
            </Paragraph>
          ) : (
            ``
          )}
          {props.owned_groups.length ? (
            <Paragraph>
              Groups You Own: {props.owned_groups.join(", ")}
            </Paragraph>
          ) : (
            ``
          )}
          {this.props.hederis_user.billing_info &&
          !this.state.show_update_card ? (
            <Pane padding={16} marginTop={16} border>
              <Heading marginBottom={16} size={500}>
                Billing Information
              </Heading>

              <Alert
                intent="success"
                title="Your billing info is all set up"
                marginBottom={32}
              >
                <Icon size={16} icon={CreditCardIcon} color="muted" marginRight={8} />

                <Text>
                  <Strong>{this.props.hederis_user.billing_info.brand}</Strong>{" "}
                  Ending In{" "}
                </Text>
                <Code>{this.props.hederis_user.billing_info.last4}</Code>
              </Alert>

              <Button
                onClick={e => {
                  this.setState({ show_update_card: true });
                }}
                id="ChangeCard"
              >
                Add a different credit card
              </Button>
            </Pane>
          ) : (
            ``
          )}
          <Pane
            padding={16}
            marginTop={16}
            border
            style={{
              display:
                this.props.hederis_user.billing_info &&
                !this.state.show_update_card
                  ? "none"
                  : "",
            }}
          >
            <Heading marginBottom={16} size={500}>
              Billing Information
            </Heading>
            <form
              action="/charge"
              method="post"
              id="payment-form"
              onSubmit={this.handleSubmit.bind(this)}
            >
              <Pane background="tint2">
                <input type="submit" style={{ display: "none" }} />
                <div id="stripe-card-element" style={stripe_el_style} />
              </Pane>
              <Pane>
                <Text>
                  <div id="stripe-card-errors" role="alert" />
                </Text>
              </Pane>

              <Pane
                display="flex"
                flexDirection="column"
                alignItems="center"
                marginTop={8}
              >
                <Button
                  height={40}
                  appearance="primary"
                  disabled={HIDE_BILLING}
                  id="AddBi11ing"
                >
                  Add Billing Info
                </Button>
              </Pane>
            </form>
          </Pane>
          <Pane marginTop={16} marginBottom={16}>
            <Paragraph>
              Projects will be billed the first time you upload a
              .docx file.
            </Paragraph>
          </Pane>
        </Pane>
      </Pane>
    );
  }
  // use this directly so people can log out even if they're not fully logged in, ie email not verified
}
