// our interface firestore db

class FireHelpers {
  constructor(db, firebase) {
    // console.log("need to replace / fix the uses of this from the indi to APIclient" );
    this.db = db;
    // this stub may act differently in tests! really only using it for the timestamps!
    this.firebase = firebase;
  }
  async create_new_project(project, uid) {
    // if there's no group, set it to the fake user_uid one
    project["group"] = project["group"] || `user_${uid}`;
    // this is just used for record keeping, group is the important one (and invites)
    project["creator"] = uid;
    project["ingest_messages"] = [];
    project["build_messages"] = [];
    project["images"] = [];
    project["output"] = {};
    project["created"] = this.firebase.firestore.FieldValue.serverTimestamp();
    // console.log("attempting project create with:");
    // console.log(project);
    let docRef = await this.db.collection("projects").add(project);
    return docRef["id"];
  }
  async create_group({ group_id, group_name, user_uid }) {
    let ts = this.firebase.firestore.FieldValue.serverTimestamp();
    let group_data = {
      group_id,
      display_name: group_name,
      created: ts,
      creator: user_uid,
      billing_user: user_uid,
      owners: [user_uid],
    };

    return await this.db
      .collection("groups")
      .doc(group_data.group_id)
      .set(group_data);
  }
  async get_group(group_id) {
    let doc = await this.db
      .collection("groups")
      .doc(group_id)
      .get();
    return doc.data();
  }
  async set_group_owners(group_id, owners) {
    return await this.db
      .collection("groups")
      .doc(group_id)
      .set({ owners }, { merge: true });
  }
  async set_group_billing_user(group_id, billing_user) {
    return await this.db
      .collection("groups")
      .doc(group_id)
      .set({ billing_user }, { merge: true });
  }
  async set_group_self_join(group_id, self_join) {
    return await this.db
      .collection("groups")
      .doc(group_id)
      .set({ self_join }, { merge: true });
  }
  // uses the same id as the project
  async get_bill_for_project(project_id) {
    let doc = await this.db
      .collection("bills")
      .doc(project_id)
      .get();
    return doc.data();
  }
  //discount codes, they're public
  async get_disco(code) {
    if (!code) {
      return;
    }
    let doc = await this.db
      .collection("discount_codes")
      .doc(code)
      .get();
    return doc.data();
  }
  async get_projects_by_disco_group(code, group) {
    // easy way to limit discount_code use, to this length
    return get_arr(
      await this.db
        .collection("projects")
        .where("project_discount_code", "==", code)
        .where("group", "==", group)
        .get()
    );
  }
  // for basic only XXX
  async get_basic_templates_by_group(group) {
    // this one is either `user_${uid}` or group id
    let templates = get_arr(
      await this.db
        .collection("style_templates")
        .where("group", "==", group)
        .get()
    );
    return templates;
  }
  // JUST FOR BASIC
  async get_basic_template_by_id(id) {
    let doc = await this.db
      .collection("style_templates")
      .doc(id)
      .get();
    return doc.data();
  }
  // JUST FOR BASIC XXX
  async create_template_builder(
    group,
    template_builder_config,
    assoc_project = ""
  ) {
    let created = this.firebase.firestore.FieldValue.serverTimestamp();
    let display_name = "Untitled";
    // prettier-ignore
    let template = { created, template_builder_config, group, assoc_project, display_name};
    let docRef = await this.db.collection("style_templates").add(template);
    return docRef["id"];
  }
  async update_template_builder(
    template_id,
    template_builder_config,
    display_name = ""
  ) {
    return await this.db
      .collection("style_templates")
      .doc(template_id)
      .update({ template_builder_config, display_name });
  }
  async list_personal_projects(uid) {
    let projects = get_arr(
      await this.db
        .collection("projects")
        .where("group", "==", `user_${uid}`)
        .get()
    ).sort(this.sort_by_created);
    return projects;
  }
  sort_by_created(a, b) {
    return b.created.seconds - a.created.seconds;
  }
  async list_group_projects_created_by(uid, group) {
    return get_arr(
      await this.db
        .collection("projects")
        .where("creator", "==", uid)
        // have to restrict by group for security rules
        .where("group", "==", group)
        .get()
    );
  }
  async list_group_projects(group) {
    return get_arr(
      await this.db
        .collection("projects")
        .where("group", "==", group)
        .get()
    ).sort(this.sort_by_created);
  }
  async get_user_data(user_uid) {
    let doc = await this.db
      .collection("users")
      .doc(user_uid)
      .get();
    let data = doc.data();
    return data;
  }
  async get_user_display_name(user_uid) {
    let doc = await this.db
      .collection("users")
      .doc(user_uid)
      .get();
    let data = doc.data();
    return data.displayName;
  }

  async create_invite(invite_data) {
    let required_fields = ["invite_code", "email", "from", "kind", "target"];
    for (let k of required_fields) {
      if (!Object.keys(invite_data).includes(k)) {
        // console.log("Error: Missing invite fields, won't create.");
        return Promise.reject(Error("Missing invite fields, won't create."));
        // return Promise;
      }
    }
    invite_data[
      "created"
    ] = this.firebase.firestore.FieldValue.serverTimestamp();
    invite_data["has_accepted"] = false;
    return await this.db
      .collection("invites")
      .doc(invite_data["invite_code"])
      .set(invite_data);
  }
  async delete_invite(invite_code) {
    return await this.db
      .collection("invites")
      .doc(invite_code)
      .delete();
  }
  // for invite manager
  async get_invite_list(target, kind, group) {
    // console.log(target, kind, group);
    return get_arr(
      await this.db
        .collection("invites")
        .where("target", "==", target)
        .where("kind", "==", kind)
        .where("assoc_group", "==", group)
        .get()
    );
  }

  async accept_invite(to_uid, invite_code) {
    let accepted_on = this.firebase.firestore.FieldValue.serverTimestamp();
    let has_accepted = true;
    return await this.db
      .collection("invites")
      .doc(invite_code)
      .update({ to_uid, has_accepted, accepted_on });
  }
  // just gets the invites
  async get_project_invites_to(to_uid) {
    return get_arr(
      await this.db
        .collection("invites")
        .where("kind", "==", "project_access")
        .where("to_uid", "==", to_uid)
        .get()
    );
  }
  // an array of projects, via invites
  // this is inefficient, so use sparingly
  async get_projects_shared_with(to_uid) {
    let invites = await this.get_project_invites_to(to_uid);
    let projects = await Promise.all(
      invites.map(async inv => {
        let project_id = inv["target"];
        let p = await this.db
          .collection("projects")
          .doc(project_id)
          .get();
        let data = p.data();
        if (data) {
          data["id"] = project_id;
        }
        return data;
      })
    );
    // this filter deals with manually deleted projects where the invite still exits
    return projects.filter(p => p).filter(p => p.creator != to_uid).sort(this.sort_by_created);
  }
  async get_appropriate_user_fonts({ project_id, group, user_uid }) {
    return [
      ...(await this.get_project_fonts(project_id)),
      ...(await this.get_group_fonts(group)),
      ...(await this.get_target_list_fonts(user_uid)),
    ];
  }
  async get_all_user_fonts({ user_uid }) {
    // EVENTUALLY we should add more controls,
    // so group owners/admins can manage
    // all fonts related to their groups
    // or projects within those groups.
    return [...(await this.get_user_created_fonts(user_uid))];
  }
  async get_project_fonts(id) {
    return get_arr(
      await this.db
        .collection("user_fonts")
        .where("kind", "==", "P")
        .where("target", "==", id)
        .get()
    );
  }
  async get_group_fonts(group) {
    return get_arr(
      await this.db
        .collection("user_fonts")
        .where("kind", "==", "G")
        .where("target", "==", group)
        .get()
    );
  }
  async get_target_list_fonts(user_uid) {
    // https://firebase.google.com/docs/firestore/query-data/queries#array_membership
    return get_arr(
      await this.db
        .collection("user_fonts")
        .where("kind", "==", "L")
        .where("targets_list", "array-contains", user_uid)
        .get()
    );
  }
  async get_user_created_fonts(user_uid) {
    return get_arr(
      await this.db
        .collection("user_fonts")
        .where("creator", "==", user_uid)
        .get()
    );
  }
  async create_user_font({ kind, displayName, files, props, selectedMembers }) {
    let { group, params, hederis_user } = props;
    let { id } = params;

    let data = { kind, displayName, files, creator: hederis_user.uid };
    // project, group, list-o-users
    if (kind === "P") {
      data["target"] = id;
    } else if (kind === "G") {
      data["target"] = group;
    } else if (kind === "L") {
      data["targets_list"] = selectedMembers;
    } else {
      throw new Error("Kind not found");
    }

    let docRef = await this.db.collection("user_fonts").add(data);
  }
  async set_user_newsletter_status(user_uid, signup_status) {
    return await this.db
      .collection("users")
      .doc(user_uid)
      .set({ newsletter_optin: signup_status }, { merge: true });
  }
}

// helper
function get_arr(qs, include_id = true) {
  let res = [];
  qs.forEach(doc => {
    let p = doc.data();
    // prettier-ignore
    if (include_id) { p["id"] = doc.id; }
    res.push(p);
  });
  return res;
}

exports.FireHelpers = FireHelpers;
