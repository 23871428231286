export async function get_resized_if_needed(file, max_px) {
  // console.log(file);

  let img = await load_image(window.URL.createObjectURL(file));
  // img.src = window.URL.createObjectURL(file);
  // console.log(img);
  // console.log(img.width, img.height);
  if (img.width <= max_px && img.height <= max_px) {
    return false;
  }
  // possibly want dims not to be the same in the future
  let max_width = max_px;
  let max_height = max_px;
  let width = img.width;
  let height = img.height;

  if (width > height) {
    if (width > max_width) {
      height *= max_width / width;
      width = max_width;
    }
  } else {
    if (height > max_height) {
      width *= max_height / height;
      height = max_height;
    }
  }
  let canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  let ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, width, height);
  // console.log(file.name);
  return new Promise((resolve, reject) => {
    canvas.toBlob(resolve, file.type);
  });
}

async function load_image(src) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    // ie?
    // let img = document.createElement("img");
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
}
