import React from "react";
import ReactDOM from "react-dom";
import { useState, useEffect } from "react";

// prettier-ignore
import { Pane, Text, Heading, Button, TextInputField, Link, Tooltip, Icon, Small, Position, Popover, Portal, RefreshIcon, InfoSignIcon, IssueIcon, CloudDownloadIcon, TimeIcon, LabTestIcon} from "evergreen-ui";

import relativeDate from "tiny-relative-date";
import { firebase, db, storage } from "../../fire.js";
import { download_file } from "../../helpers/util.js";
import { PdfTemplateWidget, EpubTemplateWidget } from "../advanced/template_picker.js";
import HedButton from "./HedButton.js";

// TODO make sure to deal with unbuilt case!
export default function OutputButtons(props) {
  const { output, title } = props;

  // this state and the effect below simply cause this comp to rerender ever two min to update the relative dates

  const update_interval = 120;
  const [now, setNow] = useState(new Date());
  // console.log("render");
  useEffect(
    () => {
      let updater = () => setNow(new Date());
      let cancel_code = window.setInterval(updater, update_interval * 1000);
      return () => {
        window.clearInterval(cancel_code);
      };
    },
    [output]
  );

  return (
    <Pane marginTop={8} display="flex" flexDirection="column">
      <Pane display="flex" flexDirection="row" alignItems="flex-start">
        <FileButton 
          extension="pdf" 
          output={output} 
          title={title} 
          now={now} 
          font_warnings={props.font_warnings} 
        />
        <PdfTemplateWidget {...props} />
      </Pane>
      <Pane display="flex" flexDirection="row" alignItems="flex-start">
        <FileButton extension="epub" output={output} title={title} now={now} />
        <EpubTemplateWidget {...props} />
      </Pane>
      <FileButton extension="kindle" output={output} title={title} now={now} />
      <FileButton extension="docx" output={output} title={title} now={now} />
      <FileButton extension="html" output={output} title={title} now={now} />
      <FileButton extension="idml" output={output} title={title} now={now} info="beta" />
      <HedButton
        height={40}
        appearance="hedprimary"
        marginTop={8}
        marginLeft={0}
        iconBefore={RefreshIcon}
        disabled={!output.html || !props.can_rebuild}
        onClick={async () => {
          props.trigger_rebuild();
        }}
      >
        Export
      </HedButton>
      {!output.html ? get_started_message : ``}
    </Pane>
  );
}

const get_started_message = (
  <Pane
    marginY={8}
    padding={6}
    justifyContent="center"
    alignItems="center"
    display="flex"
  >
    <Icon icon={InfoSignIcon} color="default" marginRight={4} size={14} />
    <Text size={300}>Upload a docx to build your book & get started</Text>
  </Pane>
);

function FileButton(props) {
  const { extension, output, title, now, info } = props;
  const escaped_title = title.replace(/\W/g, "_");
  // prettier-ignore
  const file_name_no_ext = `${escaped_title}_${(new Date()).toLocaleDateString().replace(/\W/g, "_")}`;

  // how many seconds to consider it new
  // let second_cutoff = 30;
  const second_cutoff = 90;
  // which output are we about
  const indicator_output = output[extension];

  const is_recent = indicator_output
    ? (+new Date() - indicator_output.ts.seconds * 1000) / 1000 < second_cutoff
    : false;

  const label =
    extension !== "kindle" ? extension.toUpperCase() : "Kindle EPUB";

  const date_string = indicator_output
    ? relativeDate(indicator_output.ts.seconds * 1000)
    : ``;
  // prettier-ignore
  const should_display_warning = label === "PDF" && props.font_warnings && props.font_warnings.length
    ? true
    : false;
  return (
    <Pane
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="center"
      marginY={4}
      background={is_recent ? "greenTint" : ``}
      border={is_recent ? "muted" : ""}
      marginLeft={0}
    >
      <Pane
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
      >
        <Pane
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
        >
          <HedButton
            id={`download${label.replace(/[^a-zA-Z0-9]/g,"")}`}
            width={130}
            textAlign="left"
            justifyContent="flex-start"
            appearance={!indicator_output ? "heddisabled" : "hedsecondary"}
            disabled={!indicator_output}
            iconBefore={CloudDownloadIcon}
            onClick={async () => {
              const url = await storage.ref(indicator_output.path).getDownloadURL();
              const res = await fetch(url);
              const data = await res.blob();
              // prettier-ignore
              const fname =  extension !== 'kindle' ? `${file_name_no_ext}.${extension}` : `${file_name_no_ext}_kindle.epub`
              download_file(fname, data);
            }}
          >
            {label}
          </HedButton>
          {info && info === "beta" && (
            <Tooltip
              content="This feature is experimental; please let us know how we can improve it!"
              position={Position.BOTTOM}
            >
              <Pane
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
                marginLeft={8}
              >
                <LabTestIcon />
              </Pane>
            </Tooltip>
          )}          
        </Pane>
        <Text weight={is_recent ? 500 : 300}>{date_string}</Text>
      </Pane>
    </Pane>
  );
}
