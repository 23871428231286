import React from "react";
import ReactDOM from "react-dom";

// prettier-ignore
import { Pane, Paragraph, Dialog, Heading, Button, TextInputField, toaster } from "evergreen-ui";

import { LoadingPane } from "./common.js";
import InviteManager from "./invite_manager.js";

import { firebase, db } from "../../fire.js";
import APIClient from "../../helpers/api_client";
import { FireHelpers } from "../../../api/app_shared/fire_helpers.js";

const API = new APIClient(firebase);
const FH = new FireHelpers(db, firebase);

// console.log(FireHelpers);
export default class CreateGroupView extends React.Component {
  constructor(props) {
    super(props);
    this.state = { name: "", is_loading: false };
  }
  shouldComponentUpdate(nextProps, nextState) {
    // work around, below we trigger this on success by adding the group flag url
    if (nextProps.group_id_flag) {
      // which lets us redirect to a new page AND reload safely at the same time
      let hash = `#/group/${nextProps.group_id_flag}`;
      window.location.replace(`${window.location.origin}/${hash}`);
      window.location.reload();
    }
    return true;
  }
  componentDidMount() {
    // console.log("CreateGroupView mounted", this.props.params);
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }
  async submitClicked() {
    this.setState({ is_loading: true });
    let { name } = this.state;
    // replace every non alphanumeric char with an _ for the id
    let group_id = name.replace(/\W/g, "_");
    if (group_id.length < 3) {
      toaster.warning("Need a longer group name");
      this.setState({ is_loading: false });
      return;
    } else if (group_id.length > 250) {
      toaster.warning("Need a shorter group name");
      this.setState({ is_loading: false });
      return;
    } else if (group_id.startsWith("user_") || group_id.startsWith("USER_")) {
      toaster.warning("Need a different group name");
      this.setState({ is_loading: false });
      return;
    }
    let is_all_underscores = group_id.split("").every(z => z === "_");
    let existing_group = await FH.get_group(group_id);
    if (existing_group || is_all_underscores) {
      // prettier-ignore
      toaster.warning("A group with a similar name already exists, please try another name");
      this.setState({ is_loading: false });
      return;
    } else {
      console.log("creating group");
      await FH.create_group({
        group_id,
        group_name: name,
        user_uid: this.props.hederis_user.uid,
      });
      console.log("created group", group_id);
      await API.set_new_group_claim(group_id);

      // TODO SET CLAIM
      console.log("set set_new_group_claim done ");
      // this.setState({ is_loading: false });
      toaster.closeAll();
      toaster.success("New Group Created!");
      // this timeout is just for ui
      setTimeout(() => {
        console.log("redirect");
        // we redirect to this which - when we get back, reloads and redirects again, confusingly
        window.location = `${location.origin}/#/create_group/${group_id}`;
      }, 2000);
      // we'll actually need a full page refresh for everything to work correctly because of owned_groups in with_auth
      // or some other crap, ugh
      // window.location = `${location.origin}/#/group/${group_id}`;
    }

    // console.log(existing_group);
    // ugh need a check that it's not taken.
    // console.log("submitClicked");
  }

  // \W
  render() {
    return (
      <Pane width={600} marginLeft="auto" marginRight="auto">
        <Heading textAlign="center" size={600} marginBottom={24}>
          Create a Group
        </Heading>
        <TextInputField
          label="Group Name"
          name="name"
          required
          inputHeight={48}
          placeholder="My Group"
          value={this.state.name}
          hint="Non-alphanumeric characters will be replaced with underscores"
          onChange={this.handleInputChange.bind(this)}
        />

        <Pane textAlign="center">
          <Button
            onClick={this.submitClicked.bind(this)}
            height={48}
            appearance="primary"
            marginTop={16}
            isLoading={this.state.is_loading}
          >
            Create Group
          </Button>
        </Pane>
      </Pane>
    );
  }
}
