function replace_page_nums(css_string) {
  // prettier-ignore
  var vals = {
    "decimal": "000",
    "decimal-leading-zero": "0.00",
    "lower-alpha" : "aaa",
    "lower-greek": "ααα",
    "lower-roman": "iii",
    "upper-alpha": "AAA",
    "upper-greek": "AAA",
    "upper-roman": "III",
  }

  function replacer(match, p1, p2, p3, offset, string) {
    var val = '"000"';
    if (p2 != "" && vals[p2]) {
      val = `'${vals[p2]}'`;
    }
    return val;
  }

  css_string = css_string.replace(/(counter\(page,?)(\s*[a-zA-Z-]*)(\))/g, replacer);
  return css_string
}

function replace_book_title(css_string) {
  // prettier-ignore
  return css_string.replace(/string\(booktitle\)/g, '"BOOK TITLE WILL GO HERE"')
}

function replace_author_name(css_string) {
  // prettier-ignore
  return css_string.replace(/string\(authorname\)/g, '"AUTHOR NAME WILL GO HERE"')
}

function replace_css_image_urls(css_string, lookup) {
  if (css_string) {
    return css_string.replace(
      /url\(([^)]*)\)/g,
      (match, captured_src, offset) => {
        if (lookup.hasOwnProperty(captured_src.trim())) {
          let replacement_src = lookup[captured_src.trim()]; 
          return match.replace(captured_src,replacement_src)
        } else {
          return match
        }
      }
    );
  } else {
    return css_string;
  }
}

module.exports = {
  replace_page_nums,
  replace_book_title,
  replace_author_name,
  replace_css_image_urls
};