import { useState, useEffect } from "react";
// prettier-ignore
import {Pane, Position, Tooltip, Text, Code, Popover, Heading, Button, TextInputField, TabNavigation, Tab, SidebarTab, Tablist, Link, IconButton, Icon, Small, TextInput, RadioGroup, Select, Strong, Checkbox, Combobox, TagInput, Portal, InfoSignIcon, PlusIcon} from "evergreen-ui";

import { ChromePicker } from "react-color";

import { PageMarginsInput } from "./configure_export_settings.js";
// prettier-ignore
import { parse_dim, camelCaseToDash, get_label_from_key, get_helper_text, rgbStringToHex, hexToRgbString } from "../../helpers/util.js";

import INPUTS_INFO from "../../../api/app_shared/defaults/inputs.json";
import ALL_FONTS_ARR from "../../../api/app_shared/fonts/fonts.json";

import { AddFontButton } from "../advanced/font_upload.js";

// <AddFontButton
//   group={this.props.project.group}
//   hederis_user={this.props.hederis_user}
//   user_groups={this.props.user_groups}
//   params={this.props.params}
// />

export function PageMarginsInputWrap(props) {
  let myClass = props.thisClass ? props.thisClass : "standard";
  console.log(props.input_key);
  if (
    (props.input_key.includes("verso") || props.input_key.includes("recto")) &&
    props.input_key.endsWith("margins")
  ) {
    myClass = "pageTemplates";
  }
  console.log(myClass);
  return (
    <PageMarginsInput
      key={props.input_key}
      label={get_label_from_key(props.input_key)}
      onChange={({ value, pos, name }) => {
        // console.log(input_key);
        let v = JSON.parse(JSON.stringify(props.value));
        v[pos] = value;
        props.onChange({ value: v, input_key: props.input_key });
      }}
      values={props.value}
      myClass={myClass}
    />
  );
}

export function BlockSpacingInputWrap(props) {
  let myClass = props.thisClass ? props.thisClass : "standard";
  let blockType = props.input_key.includes("padding") ? "padding" : "margins";
  console.log(props.input_key);
  console.log(blockType);
  return (
    <PageMarginsInput
      key={props.input_key}
      label={get_label_from_key(props.input_key)}
      is_block={true}
      onChange={({ value, pos, name }) => {
        // console.log(input_key);
        // console.log("BLOCK SPACING");
        // console.log(value, pos, name);
        let v = JSON.parse(JSON.stringify(props.value));
        v[pos] = value;
        props.onChange({ value: v, input_key: props.input_key });
      }}
      values={typeof props.value[0] === "undefined" ? props.def : props.value}
      blockType={blockType}
      myClass={myClass}
    />
  );
}

export function CheckWrap(props) {
  let { value, input_key, label, checked_value, unchecked_value } = props;
  let textColor = props.textColor ? props.textColor : "muted";
  let myClass = props.thisClass ? props.thisClass : "standard";
  return (
    <Checkbox
      id={`design-option-${label.replace(/[^a-zA-Z0-9]/g,"")}`}
      className={myClass}
      color={textColor}
      checked={value === checked_value}
      onChange={e => {
        let value = e.target.checked ? checked_value : unchecked_value;
        props.onChange({ input_key, value });
      }}
      label={label}
    />
  );
}

// this is supposed to work with objects not just strings but the filter wouldn't work so
// defaultSelectedItem={fonts[0]["display-name"]}
export function FontFamilyInput(props) {
  let { input_key, value, font_family, onChange } = props;
  // let value = get_val_by_name(name, config)
  // width="100%"
  let label = get_label_from_key(input_key);
  let last = input_key.split("_").pop();
  let textColor = props.textColor ? props.textColor : "muted";
  let myClass = props.thisClass ? props.thisClass : "standard";

  return (
    <Pane 
      marginTop={8} 
      display="flex" 
      flexDirection="row" 
      alignItems="flex-start" 
      justifyContent="space-between"
      paddingX={8} 
      className={myClass}
    >
      <Pane 
        width={150} 
        display="flex" 
        flexDirection="row" 
        alignItems="center" 
        marginRight={8}
      >
        <Text color={textColor} textTransform="capitalize">
          {label}
        </Text>
        {INPUTS_INFO.hasOwnProperty(last) && (
          <Tooltip content={`${INPUTS_INFO[last].tooltip}`}>
            <IconButton className="white-icon" icon={InfoSignIcon} appearance="minimal" />
          </Tooltip>
        )}
      </Pane>

      <Pane {...props.GW} width="250px" maxWidth="250px">
        {/* the extra wrap and width addresses a bug with combobox width*/}
        <Combobox
          id={`design-option-${label.replace(/[^a-zA-Z0-9]/g,"")}`}
          items={props.fontnames}
          onChange={selected => onChange({ input_key, value: selected })}
          selectedItem={value}
          width="100%"
        />
        <Pane display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
          {value === "inherit" ? (
            <Pane>
              <Text color={textColor} size={300}>
                (inheriting: {font_family})
              </Text>
            </Pane>
          ) : (
            ``
          )}
          <AddFontButton
            id={`design-option-upload-custom-font`}
            group={props.group}
            hederis_user={props.hederis_user}
            user_groups={props.user_groups}
            params={props.params}
            fontUploadFinished={props.fontUploadFinished}
          />
        </Pane>
      </Pane>
    </Pane>
  );
}

function get_weights(font_family, fonts_arr) {
  let weights = [];
  let myfont = "";
  myfont = fonts_arr.filter(x => {
    if (
      font_family &&
      x["display-name"] ===
        font_family.replace(/\s?\(?(USER FONT)?\)?\s?\(?[OT]*\)?$/g, "")
    ) {
      for (const k in x["weights"]) {
        weights.push(k);
      }
    }
  });
  weights.push("[normal]");
  weights.push("[bold imitation]");
  weights.push("inherit");
  return weights;
}

export function WeightInput(props) {
  let { input_key, value, font_family, fonts_arr, onChange } = props;
  // console.log(value);
  let label = get_label_from_key(input_key);
  let weights = get_weights(font_family, fonts_arr);
  if (value === "bold") {
    // see if there is a bold option in the weights
    if (weights.indexOf("bold") < 0) {
      value = "[bold imitation]";
    }
  } else if (value === "normal") {
    if (weights.indexOf("regular") > -1) {
      value = "regular";
    } else {
      value = "[normal]";
    }
  }
  let textColor = props.textColor ? props.textColor : "muted";
  let myClass = props.thisClass ? props.thisClass : "standard";
  return (
    <Pane 
      marginTop={8} 
      display="flex" 
      flexDirection="row" 
      alignItems="center" 
      justifyContent="space-between"
      paddingX={8} 
      className={myClass}
    >
      <Pane width={150} marginRight={8}>
        <Text color={textColor} textTransform="capitalize">
          {label}
        </Text>
      </Pane>
      <Pane width="250px" maxWidth="250px" display="flex" flexDirection="row" alignItems="center">
        <Pane {...props.GW} marginRight={12}>
          <Combobox
            id={`design-option-${label.replace(/[^a-zA-Z0-9]/g,"")}`}
            items={weights}
            onChange={selected => onChange({ input_key, value: selected })}
            selectedItem={value}
            width={props.GW.maxWidth}
          />
        </Pane>
        <Tooltip
          content="These are all the weights available in this font. [normal] is the default weight for this font. If this font does not have a bold face, the browser can attempt to imitate bold via [bold imitation]."
          position={Position.RIGHT}
        >
          <Icon icon={InfoSignIcon} color="disabled" size={16} />
        </Tooltip>
      </Pane>
    </Pane>
  );
}

function get_OT_options(font_family, fonts_arr) {
  let ot = [];
  let map = {
    liga: "common-ligatures",
    calt: "contextual",
    dlig: "discretionary-ligatures",
    smcp: "small-caps",
    c2sc: "all-small-caps",
    swsh: "swashes",
    salt: "stylistic-alternates",
    lnum: "lining-nums",
    onum: "oldstyle-nums",
    pnum: "proportional-nums",
    tnum: "tabular-nums",
    frac: "diagonal-fractions",
    ordn: "ordinal",
    subs: "subscripts",
    sups: "superscripts",
    aalt: "all-alternates",
    "ss01": "stylistic-sets-1",
    "ss02": "stylistic-sets-2",
    "ss03": "stylistic-sets-3",
    "ss04": "stylistic-sets-4",
    "ss05": "stylistic-sets-5",
    "ss06": "stylistic-sets-6",
    "ss07": "stylistic-sets-7",
    "ss08": "stylistic-sets-8",
    "ss09": "stylistic-sets-9",
    "ss10": "stylistic-sets-10"
  };
  let active_font = font_family
    .replace(/\s?\([OT]+\)$/g, "")
    .replace(/\s*\(USER FONT\)\s*/g,"");
  let myfont = fonts_arr.filter(x => {
    if (x["display-name"] === active_font) {
      if (x.hasOwnProperty("opentype")) {
        x["opentype"].forEach(function(item) {
          ot.push([item, map[item]]);
        });
      }
    }
  });
  return ot;
}

export function OTInput(props) {
  let { input_key, value, font_family, fonts_arr, onChange } = props;
  // console.log(value);
  let label = get_label_from_key(input_key);
  let options = get_OT_options(font_family, fonts_arr);
  let textColor = props.textColor ? props.textColor : "muted";
  let myClass = props.thisClass ? props.thisClass : "standard";
  return (
    <Pane 
      marginTop={8} 
      display="flex" 
      flexDirection="column" 
      justifyContent="space-between"
      paddingX={8} 
      className={myClass}
    >
      <Pane marginRight={8}>
        <Text color={textColor} textTransform="capitalize">
          {label} Options
        </Text>
      </Pane>
      <Pane {...props.GW} width="100%" maxWidth="none" style={{columns: 2}} paddingTop={16}>
        {options.map((o, i) => {
          console.log(o);
          return <OTItem {...props} item={o} key={`ot-${i}`} />;
        })}
      </Pane>
    </Pane>
  );
}

function addVal(arr, val) {
  arr.push(val);
  return arr;
}

function removeVal(arr, val) {
  const index = arr.indexOf(val);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}

// each item that is checked should be added to the final array on the input key

function OTItem(props) {
  let { input_key, item, onChange } = props;
  let textColor = props.textColor ? props.textColor : "muted";
  let myClass = props.thisClass ? props.thisClass : "standard";
  let val = props.value;
  let label = item[1];
  let checked = val.indexOf(item[0]) > -1;
  if (!label) {
    label = "opentype";
  }
  return (
    <Pane key={val} className={myClass}>
      <Checkbox
        id={`design-option-${label.replace(/[^a-zA-Z0-9]/g,"")}`}
        className={myClass}
        color={textColor}
        marginTop={0}
        checked={checked}
        onChange={e => {
          let value = e.target.checked
            ? addVal(val, item[0])
            : removeVal(val, item[0]);
          onChange({ input_key, value });
        }}
        label={label}
      />
    </Pane>
  );
}

function has_italic(weight, font_family) {
  let italic = false;
  let myfont = ALL_FONTS_ARR.filter(x => {
    if (x["display-name"] === font_family) {
      console.log(x["display-name"]);
      if (x["weights"][weight]["styles"].indexOf("italic") > -1) {
        italic = true;
      }
    }
  });
  console.log(italic);
  return italic;
}

export function ItalicStyleInput(props) {
  let { input_key, value, onChange } = props;
  let label = get_label_from_key(input_key);
  let checked = value === "italic";
  let textColor = props.textColor ? props.textColor : "muted";
  let myClass = props.thisClass ? props.thisClass : "standard";
  //let italic = has_italic(weight, font_family);
  return (
    <Pane 
      marginTop={8} 
      display="flex" 
      flexDirection="row" 
      alignItems="center" 
      justifyContent="space-between"
      paddingX={8} 
      className={myClass}
    >
      <Pane width={150} marginRight={8}>
        <Text color={textColor} textTransform="capitalize">
          {label}
        </Text>
      </Pane>
      <Pane width="250px" maxWidth="250px" display="flex" flexDirection="row" alignItems="center">
        <Checkbox
          id={`design-option-${label.replace(/[^a-zA-Z0-9]/g,"")}`}
          className={myClass}
          color={textColor}
          checked={checked}
          onChange={e => {
            let value = e.target.checked ? "italic" : "normal";
            onChange({ input_key, value });
          }}
          label="Italic"
          marginRight={12}
        />
        <Tooltip
          content="If this font family does not have an italic face, italics will be simulated if possible."
          position={Position.RIGHT}
        >
          <Icon icon={InfoSignIcon} color="disabled" size={16} />
        </Tooltip>
      </Pane>
    </Pane>
  );
}

export function TextTransformInput(props) {
  // TODO impl this logic for others
  // let { name, config, onChange } = props;
  let { input_key, value, onChange } = props;
  // let value = get_val_by_name(name, config)
  // width="100%"
  let label = get_label_from_key(input_key);
  let textColor = props.textColor ? props.textColor : "muted";
  let myClass = props.thisClass ? props.thisClass : "standard";
  return (
    <Pane 
      marginTop={8} 
      display="flex" 
      flexDirection="row" 
      alignItems="center" 
      justifyContent="space-between" 
      paddingX={8} 
      className={myClass}
    >
      <Pane width={150} display="flex" marginRight={8}>
        <Text color={textColor} textTransform="capitalize">
          {label}
        </Text>
      </Pane>
      <Pane {...props.GW} width="250px" maxWidth="250px" display="flex" flexDirection="row" alignItems="center">
        {/* the extra wrap and width addresses a bug with combobox width*/}
        <Combobox
          id={`design-option-${label.replace(/[^a-zA-Z0-9]/g,"")}`}
          items={["none", "uppercase", "lowercase", "capitalize"]}
          onChange={selected => onChange({ input_key, value: selected })}
          selectedItem={value}
          width={props.GW.maxWidth}
        />
      </Pane>
    </Pane>
  );
}

export function PositionInput(props) {
  let { input_key, value, onChange } = props;
  let label = get_label_from_key(input_key);
  let textColor = props.textColor ? props.textColor : "muted";
  let myClass = props.thisClass ? props.thisClass : "standard";
  return (
    <Pane 
      marginTop={8} 
      display="flex" 
      flexDirection="row" 
      alignItems="center" 
      justifyContent="space-between" 
      paddingX={8} 
      className={myClass}
    >
      <Pane width={150} display="flex" marginRight={8}>
        <Text color={textColor} textTransform="capitalize">
          {label}
        </Text>
      </Pane>
      <Pane {...props.GW} width="250px" maxWidth="250px" display="flex" flexDirection="row" alignItems="center">
        {/* the extra wrap and width addresses a bug with combobox width*/}
        <Combobox
          id={`design-option-${label.replace(/[^a-zA-Z0-9]/g,"")}`}
          items={["relative", "absolute", "fixed", "outside", "inside"]}
          onChange={selected => onChange({ input_key, value: selected })}
          selectedItem={value}
          width={props.GW.maxWidth}
        />
      </Pane>
    </Pane>
  );
}

export function LanguageInput(props) {
  let { input_key, value, onChange } = props;
  let label = get_label_from_key(input_key);
  let textColor = props.textColor ? props.textColor : "muted";
  let myClass = props.thisClass ? props.thisClass : "standard";
  let langs = [
    "en-us",
    "be",
    "bn",
    "ca",
    "cs",
    "da",
    "de",
    "el-monoton",
    "el-polyton",
    "en-gb",
    "es",
    "fi",
    "fr",
    "grc",
    "gu",
    "hi",
    "hu",
    "hy",
    "it",
    "kn",
    "la",
    "lt",
    "lv",
    "ml",
    "nb-no",
    "nl",
    "or",
    "pa",
    "pl",
    "pt",
    "ru",
    "sk",
    "sl",
    "sv",
    "ta",
    "te",
    "tr",
    "uk"
  ];
  return (
    <Pane 
      marginTop={8} 
      display="flex" 
      flexDirection="row" 
      alignItems="center" 
      justifyContent="space-between" 
      paddingX={8} 
      className={myClass}
    >
      <Pane width={150} display="flex" marginRight={8}>
        <Text color={textColor} textTransform="capitalize">
          {label}
        </Text>
      </Pane>

      <Pane {...props.GW} width="250px" maxWidth="250px" display="flex" flexDirection="row" alignItems="center">
        {/* the extra wrap and width addresses a bug with combobox width*/}
        <Combobox
          id={`design-option-${label.replace(/[^a-zA-Z0-9]/g,"")}`}
          items={langs}
          onChange={selected => onChange({ input_key, value: selected })}
          selectedItem={value}
          width={props.GW.maxWidth}
        />
      </Pane>
    </Pane>
  );
}

export function KeepOptionsInput(props) {
  // TODO impl this logic for others
  // let { name, config, onChange } = props;
  let { input_key, value, onChange } = props;
  // let value = get_val_by_name(name, config)
  // width="100%"
  let label = get_label_from_key(input_key);
  let textColor = props.textColor ? props.textColor : "muted";
  let myClass = props.thisClass ? props.thisClass : "standard";
  return (
    <Pane 
      marginTop={8} 
      display="flex" 
      flexDirection="row" 
      alignItems="center" 
      justifyContent="space-between" 
      paddingX={8} 
      className={myClass}
    >
      <Pane width={150} display="flex" marginRight={8}>
        <Text color={textColor} textTransform="capitalize">
          {label}
        </Text>
      </Pane>

      <Pane {...props.GW} width="250px" maxWidth="250px" display="flex" flexDirection="row" alignItems="center">
        {/* the extra wrap and width addresses a bug with combobox width*/}
        <Combobox
          id={`design-option-${label.replace(/[^a-zA-Z0-9]/g,"")}`}
          items={["auto", "before", "inside", "after"]}
          onChange={selected => onChange({ input_key, value: selected })}
          selectedItem={value}
          width={props.GW.maxWidth}
        />
      </Pane>
    </Pane>
  );
}

// background-image
// background-positionx, positiony
// background-repeat (checkboxes)
// background-size: with, height

export function BackgroundImgInput(props) {
  let { input_key, value, images, onChange } = props;
  let label = get_label_from_key(input_key);
  let textColor = props.textColor ? props.textColor : "muted";
  let myClass = props.thisClass ? props.thisClass : "standard";
  let img_names = ["none"];
  images.map(img => {
    img_names.push(img.split("/").pop());
  });
  return (
    <Pane 
      marginTop={8} 
      display="flex" 
      flexDirection="row" 
      alignItems="center" 
      justifyContent="space-between" 
      paddingX={8} 
      className={myClass}
    >
      <Pane width={150} display="flex" marginRight={8}>
        <Text color={textColor} textTransform="capitalize">
          {label}
        </Text>
      </Pane>

      <Pane {...props.GW} width="250px" maxWidth="250px" display="flex" flexDirection="row" alignItems="center">
        {/* the extra wrap and width addresses a bug with combobox width*/}
        <Combobox
          id={`design-option-${label.replace(/[^a-zA-Z0-9]/g,"")}`}
          items={img_names || []}
          onChange={selected => onChange({ input_key, value: selected })}
          selectedItem={value}
          width={props.GW.maxWidth}
        />
      </Pane>
    </Pane>
  );
}

export function BackgroundRepeatInput(props) {
  let { input_key, value, onChange } = props;
  let label = get_label_from_key(input_key);
  let textColor = props.textColor ? props.textColor : "muted";
  let myClass = props.thisClass ? props.thisClass : "standard";
  return (
    <Pane 
      marginTop={8} 
      display="flex" 
      flexDirection="row" 
      alignItems="center" 
      justifyContent="space-between" 
      paddingX={8} 
      className={myClass}
    >
      <Pane width={150} marginRight={8}>
        <Text color={textColor} textTransform="capitalize">
          {label}
        </Text>
      </Pane>
      <Pane width="250px" maxWidth="250px" display="flex" flexDirection="row" alignItems="center">
        <Checkbox
          id={`design-option-no-repeat`}
          className={myClass}
          onChange={e => onChange({ input_key, value: "no-repeat" })}
          checked={value === "no-repeat"}
          label="None"
          marginRight={8}
        />
        <Checkbox
          id={`design-option-repeat-x`}
          className={myClass}
          onChange={e => onChange({ input_key, value: "repeat-x" })}
          checked={value === "repeat-x"}
          label="Horizontal"
          marginRight={8}
        />
        <Checkbox
          id={`design-option-repeat-y`}
          className={myClass}
          onChange={e => onChange({ input_key, value: "repeat-y" })}
          checked={value === "repeat-y"}
          label="Vertical"
          marginRight={8}
        />
      </Pane>
    </Pane>
  );
}

export function DisplayInput(props) {
  // TODO impl this logic for others
  // let { name, config, onChange } = props;
  let { input_key, value, onChange } = props;
  let textColor = props.textColor ? props.textColor : "muted";
  let myClass = props.thisClass ? props.thisClass : "standard";
  // let value = get_val_by_name(name, config)
  // width="100%"
  let label = get_label_from_key(input_key);
  return (
    <Pane 
      marginTop={8} 
      display="flex" 
      flexDirection="row" 
      alignItems="center" 
      justifyContent="space-between" 
      paddingX={8} 
      className={myClass}
    >
      <Pane width={150} display="flex" marginRight={8}>
        <Text color={textColor} textTransform="capitalize">
          {label}
        </Text>
      </Pane>

      <Pane {...props.GW} width="250px" maxWidth="250px" display="flex" flexDirection="row" alignItems="center">
        {/* the extra wrap and width addresses a bug with combobox width*/}
        <Combobox
          id={`design-option-${label.replace(/[^a-zA-Z0-9]/g,"")}`}
          items={[
            "initial",
            "none",
            "block",
            "inline",
            "inline-block",
            "list-item",
            "table",
            "table-row",
            "table-cell",
          ]}
          onChange={selected => onChange({ input_key, value: selected })}
          selectedItem={value}
          width={props.GW.maxWidth}
        />
      </Pane>
    </Pane>
  );
}

export function UnderlineTypeInput(props) {
  // TODO impl this logic for others
  // let { name, config, onChange } = props;
  let { input_key, value, onChange } = props;
  // let value = get_val_by_name(name, config)
  // width="100%"
  let label = get_label_from_key(input_key);
  let textColor = props.textColor ? props.textColor : "muted";
  let myClass = props.thisClass ? props.thisClass : "standard";
  return (
    <Pane 
      marginTop={8} 
      display="flex" 
      flexDirection="row" 
      alignItems="center" 
      justifyContent="space-between" 
      paddingX={8} 
      className={myClass}
    >
      <Pane width={150} display="flex" marginRight={8}>
        <Text color={textColor} textTransform="capitalize">
          {label}
        </Text>
      </Pane>

      <Pane {...props.GW} width="250px" maxWidth="250px" display="flex" flexDirection="row" alignItems="center">
        {/* the extra wrap and width addresses a bug with combobox width*/}
        <Combobox
          id={`design-option-${label.replace(/[^a-zA-Z0-9]/g,"")}`}
          items={[
            "none",
            "underline",
            "overline",
            "line-through",
            "initial",
            "inherit",
          ]}
          onChange={selected => onChange({ input_key, value: selected })}
          selectedItem={value}
          width={props.GW.maxWidth}
        />
      </Pane>
    </Pane>
  );
}

export function UnderlineStyleInput(props) {
  // TODO impl this logic for others
  // let { name, config, onChange } = props;
  let { input_key, value, onChange } = props;
  // let value = get_val_by_name(name, config)
  // width="100%"
  let label = get_label_from_key(input_key);
  let textColor = props.textColor ? props.textColor : "muted";
  let myClass = props.thisClass ? props.thisClass : "standard";
  return (
    <Pane 
      marginTop={8} 
      display="flex" 
      flexDirection="row" 
      alignItems="center" 
      justifyContent="space-between" 
      paddingX={8} 
      className={myClass}
    >
      <Pane width={150} display="flex" marginRight={8}>
        <Text color={textColor} textTransform="capitalize">
          {label}
        </Text>
      </Pane>

      <Pane {...props.GW} width="250px" maxWidth="250px" display="flex" flexDirection="row" alignItems="center">
        {/* the extra wrap and width addresses a bug with combobox width*/}
        <Combobox
          id={`design-option-${label.replace(/[^a-zA-Z0-9]/g,"")}`}
          items={[
            "solid",
            "double",
            "dotted",
            "dashed",
            "wavy",
            "initial",
            "inherit",
          ]}
          onChange={selected => onChange({ input_key, value: selected })}
          selectedItem={value}
          width={props.GW.maxWidth}
        />
      </Pane>
    </Pane>
  );
}

export function ListStyleInput(props) {
  // TODO impl this logic for others
  // let { name, config, onChange } = props;
  let { input_key, value, onChange } = props;
  // let value = get_val_by_name(name, config)
  // width="100%"
  let label = get_label_from_key(input_key);
  let textColor = props.textColor ? props.textColor : "muted";
  let myClass = props.thisClass ? props.thisClass : "standard";
  return (
    <Pane 
      marginTop={8} 
      display="flex" 
      flexDirection="row" 
      alignItems="center" 
      justifyContent="space-between" 
      paddingX={8} 
      className={myClass}
    >
      <Pane width={150} display="flex" marginRight={8}>
        <Text color={textColor} textTransform="capitalize">
          {label}
        </Text>
      </Pane>

      <Pane {...props.GW} width="250px" maxWidth="250px" display="flex" flexDirection="row" alignItems="center">
        {/* the extra wrap and width addresses a bug with combobox width*/}
        <Combobox
          id={`design-option-${label.replace(/[^a-zA-Z0-9]/g,"")}`}
          items={[
            "none",
            "initial",
            "disc",
            "circle",
            "square",
            "decimal",
            "decimal-leading-zero",
            "lower-alpha",
            "lower-greek",
            "lower-latin",
            "lower-roman",
            "upper-alpha",
            "upper-greek",
            "upper-latin",
            "upper-roman",
            "armenian",
            "cjk-ideographic",
            "georgian",
            "hebrew",
            "hiragana",
            "hiragana-iroha",
            "katakana",
            "katakana-iroha",
          ]}
          onChange={selected => onChange({ input_key, value: selected })}
          selectedItem={value}
          width={props.GW.maxWidth}
        />
      </Pane>
    </Pane>
  );
}

export function VerticalAlignInput(props) {
  // TODO impl this logic for others
  // let { name, config, onChange } = props;
  let { input_key, value, onChange } = props;
  // let value = get_val_by_name(name, config)
  // width="100%"
  let label = get_label_from_key(input_key);
  let textColor = props.textColor ? props.textColor : "muted";
  let myClass = props.thisClass ? props.thisClass : "standard";
  return (
    <Pane 
      marginTop={8} 
      display="flex" 
      flexDirection="row" 
      alignItems="center" 
      justifyContent="space-between" 
      paddingX={8} 
      className={myClass}
    >
      <Pane width={150} display="flex" marginRight={8}>
        <Text color={textColor} textTransform="capitalize">
          {label}
        </Text>
      </Pane>

      <Pane {...props.GW} width="250px" maxWidth="250px" display="flex" flexDirection="row" alignItems="center">
        {/* the extra wrap and width addresses a bug with combobox width*/}
        <Combobox
          id={`design-option-${label.replace(/[^a-zA-Z0-9]/g,"")}`}
          items={[
            "baseline",
            "sub",
            "super",
            "text-top",
            "text-bottom",
            "middle",
            "top",
            "bottom",
          ]}
          onChange={selected => onChange({ input_key, value: selected })}
          selectedItem={value}
          width={props.GW.maxWidth}
        />
      </Pane>
    </Pane>
  );
}

export function FloatInput(props) {
  let { input_key, value, onChange } = props;
  let label = get_label_from_key(input_key);
  let textColor = props.textColor ? props.textColor : "muted";
  let myClass = props.thisClass ? props.thisClass : "standard";
  return (
    <Pane 
      marginTop={8} 
      display="flex" 
      flexDirection="row" 
      alignItems="center" 
      justifyContent="space-between" 
      paddingX={8} 
      className={myClass}
    >
      <Pane width={150} display="flex" marginRight={8}>
        <Text color={textColor} textTransform="capitalize">
          {label}
        </Text>
      </Pane>

      <Pane {...props.GW} width="250px" maxWidth="250px" display="flex" flexDirection="row" alignItems="center">
        {/* the extra wrap and width addresses a bug with combobox width*/}
        <Combobox
          id={`design-option-${label.replace(/[^a-zA-Z0-9]/g,"")}`}
          items={[
            "left",
            "right",
            "none",
          ]}
          onChange={selected => onChange({ input_key, value: selected })}
          selectedItem={value}
          width={props.GW.maxWidth}
        />
      </Pane>
    </Pane>
  );
}

export function ImportanceInput(props) {
  let { input_key, value, onChange } = props;
  let label = get_label_from_key(input_key);
  let myClass = props.thisClass ? props.thisClass : "standard";
  let checked = value === "true";
  return (
    <Pane 
      marginTop={8} 
      display="flex" 
      flexDirection="row" 
      alignItems="center" 
      justifyContent="space-between" 
      paddingX={8} 
      className={myClass}
    >
      <Checkbox
        id={`design-option-${label.replace(/[^a-zA-Z0-9]/g,"")}`}
        className={myClass}
        checked={checked}
        onChange={e => {
          let value = e.target.checked ? "true" : "false";
          onChange({ input_key, value });
        }}
        label="These design settings should take precedence over any other overrides or settings?"
        marginRight={12}
      />
    </Pane>
  );
}

export function DropCapInput(props) {
  let { input_key, value, onChange } = props;
  let label = get_label_from_key(input_key);
  let checked = value === "true";
  let textColor = props.textColor ? props.textColor : "muted";
  let myClass = props.thisClass ? props.thisClass : "standard";
  return (
    <Pane 
      marginTop={8} 
      display="flex" 
      flexDirection="row" 
      alignItems="center" 
      justifyContent="space-between" 
      paddingX={8} 
      className={myClass}
    >
      <Checkbox
        id={`design-option-${label.replace(/[^a-zA-Z0-9]/g,"")}`}
        className={myClass}
        checked={checked}
        onChange={e => {
          let value = e.target.checked ? "true" : "false";
          onChange({ input_key, value });
        }}
        label="Add Drop Cap?"
        marginRight={12}
      />
    </Pane>
  );
}

export function AllowHyphensInput(props) {
  let { input_key, value, onChange } = props;
  let label = get_label_from_key(input_key);
  let checked = value === "manual";
  let textColor = props.textColor ? props.textColor : "muted";
  let myClass = props.thisClass ? props.thisClass : "standard";
  return (
    <Pane 
      marginTop={8} 
      display="flex" 
      flexDirection="row" 
      alignItems="center" 
      justifyContent="space-between" 
      paddingX={8} 
      className={myClass}
    >
      <Checkbox
        id={`design-option-${label.replace(/[^a-zA-Z0-9]/g,"")}`}
        className={myClass}
        checked={checked}
        onChange={e => {
          let value = e.target.checked ? "manual" : "none";
          onChange({ input_key, value });
        }}
        label="Allow hyphens?"
        marginRight={12}
      />
    </Pane>
  );
}

export function FloatBottomInput(props) {
  let { input_key, value, onChange } = props;
  let label = get_label_from_key(input_key);
  let checked = value === "bottom";
  let textColor = props.textColor ? props.textColor : "muted";
  let myClass = props.thisClass ? props.thisClass : "standard";
  return (
    <Pane 
      marginTop={8} 
      display="flex" 
      flexDirection="row" 
      alignItems="center" 
      justifyContent="space-between" 
      paddingX={8} 
      className={myClass}
    >
      <Checkbox
        id={`design-option-${label.replace(/[^a-zA-Z0-9]/g,"")}`}
        className={myClass}
        checked={checked}
        onChange={e => {
          let value = e.target.checked ? "bottom" : "none";
          onChange({ input_key, value });
        }}
        label="Float to Bottom?"
        marginRight={12}
      />
    </Pane>
  );
}

export function PreserveWSInput(props) {
  let { input_key, value, onChange } = props;
  let label = get_label_from_key(input_key);
  let checked = value === "pre-wrap";
  let textColor = props.textColor ? props.textColor : "muted";
  let myClass = props.thisClass ? props.thisClass : "standard";
  return (
    <Pane 
      marginTop={8} 
      display="flex" 
      flexDirection="row" 
      alignItems="center" 
      justifyContent="space-between" 
      paddingX={8} 
      className={myClass}
    >
      <Checkbox
        id={`design-option-${label.replace(/[^a-zA-Z0-9]/g,"")}`}
        className={myClass}
        checked={checked}
        onChange={e => {
          let value = e.target.checked ? "pre-wrap" : "initial";
          onChange({ input_key, value });
        }}
        label="Preserve white space?"
        marginRight={12}
      />
    </Pane>
  );
}

export function ColorPickerInner(props) {
  let { input_key, value, onChange } = props;
  let label = input_key ? get_label_from_key(input_key) : "";
  let textColor = props.textColor ? props.textColor : "muted";
  let myClass = props.thisClass ? props.thisClass : "standard";
  let color_sample = (
    <div
      style={{
        padding: "1px",
        backgroundColor: value,
        width: "32px",
        height: "16px",
      }}
    />
  );
  let tmp_state = value;
  return (
    <Popover
      position={Position.LEFT}
      content={({ close }) => (
        <Pane
          padding={16}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          className={myClass}
        >
          {/* this doesn't actually wait for mouseup so we need the button */}
          <ChromePicker
            color={value}
            onChangeComplete={c => {
              tmp_state = c.hex;
            }}
          />
          <Button
            id={`design-option-${label.replace(/[^a-zA-Z0-9]/g,"")}`}
            width="100%"
            height={40}
            textAlign="center"
            marginTop={8}
            appearance="primary"
            onClick={() => {
              onChange({ input_key, value: tmp_state });

              close();
            }}
          >
            OK
          </Button>
        </Pane>
      )}
    >
      <Button disabled={props.disabled}>{color_sample}</Button>
    </Popover>
  );
}

// <Button
//   width="100%"
//   height={40}
//   textAlign="center"
//   marginTop={8}
//   appearance="primary"
//   onClick={close}
// >
//   OK
// </Button>

// console.log("ColorPicker", value, input_key);
// let hex_value = rgbStringToHex(value);
// return (
//   <Pane marginTop={8} display="flex" flexDirection="row" alignItems="center">
//     {!label ? (
//       ``
//     ) : (
//       <Pane width={150} marginRight={8}>
//         <Text color="muted" textTransform="capitalize">
//           {label}
//         </Text>
//       </Pane>
//     )}
//     <Checkbox
//       checked={value === "initial"}
//       marginX={2}
//       onChange={e => {
//         if (value === "initial") {
//           let v = props.def === "initial" ? "#000000" : props.def;
//           onChange({ input_key, value: v });
//         } else {
//           onChange({ input_key, value: "initial" });
//         }
//       }}
//       label="None"
//     />
//     {/* this will just go black on "initial" */}
//     <input
//       value={hex_value}
//       type="color"
//       onChange={e => {
//         let v = hexToRgbString(e.target.value);
//         // console.log(e.target.value);
//         onChange({ input_key, value: v });
//       }}
//     />
//   </Pane>
// );

export function FolioStyleInput(props) {
  let { input_key, value, onChange } = props;
  let label = get_label_from_key(input_key);
  let textColor = props.textColor ? props.textColor : "muted";
  let myClass = props.thisClass ? props.thisClass : "standard";
  return (
    <Pane 
      marginTop={8} 
      display="flex" 
      flexDirection="row" 
      alignItems="center" 
      justifyContent="space-between" 
      paddingX={8} 
      className={myClass}
    >
      <Pane width={150} display="flex" marginRight={8}>
        <Text color={textColor} textTransform="capitalize">
          {label}
        </Text>
      </Pane>

      <Pane {...props.GW} width="250px" maxWidth="250px" display="flex" flexDirection="row" alignItems="center">
        {/* the extra wrap and width addresses a bug with combobox width*/}
        <Combobox
          id={`design-option-${label.replace(/[^a-zA-Z0-9]/g,"")}`}
          items={[
            "decimal",
            "decimal-leading-zero",
            "lower-alpha",
            "lower-greek",
            "lower-roman",
            "upper-alpha",
            "upper-greek",
            "upper-roman",
          ]}
          onChange={selected => onChange({ input_key, value: selected })}
          selectedItem={value}
          width={props.GW.maxWidth}
        />
      </Pane>
    </Pane>
  );
}

export function FolioStartInputAdvanced(props) {
  let myClass = props.thisClass ? props.thisClass : "standard";
  return (
    <CheckWrap
      {...props}
      checked_value="page 1"
      unchecked_value=""
      label="Restart page numbering at 1 for this section?"
    />
  );
}
export function PageStartInput(props) {
  let { onChange, value, input_key } = props;
  let textColor = props.textColor ? props.textColor : "muted";
  let myClass = props.thisClass ? props.thisClass : "standard";
  return (
    <Checkbox
      id={`design-option-${input_key.replace(/[^a-zA-Z0-9]/g,"")}`}
      className={myClass}
      checked={value === "right"}
      onChange={e => {
        let value = e.target.checked ? "right" : "always";
        onChange({ input_key, value });
      }}
      label="Always start new chapters on a recto?"
    />
  );
}
export function AlignmentInput(props) {
  let { input_key, value, onChange } = props;
  // console.log(value);
  let label = get_label_from_key(input_key);
  let textColor = props.textColor ? props.textColor : "muted";
  let myClass = props.thisClass ? props.thisClass : "standard";
  // let checked = value === "italic";
  return (
    <Pane 
      marginTop={8} 
      display="flex" 
      flexDirection="row" 
      alignItems="center" 
      justifyContent="space-between" 
      paddingX={8} 
      className={myClass}
    >
      <Pane width={150} marginRight={8}>
        <Text color={textColor} textTransform="capitalize">
          {label}
        </Text>
      </Pane>
      <Pane width="250px" maxWidth="250px" display="flex" flexDirection="row" alignItems="center">
        <Checkbox
          id={`design-option-align-left`}
          className={myClass}
          onChange={e => onChange({ input_key, value: "left" })}
          checked={value === "left"}
          label="Left"
          marginRight={8}
        />
        <Checkbox
          id={`design-option-align-center`}
          className={myClass}
          onChange={e => onChange({ input_key, value: "center" })}
          checked={value === "center"}
          label="Center"
          marginRight={8}
        />
        <Checkbox
          id={`design-option-align-last`}
          className={myClass}
          onChange={e => onChange({ input_key, value: "right" })}
          checked={value === "right"}
          label="Right"
          marginRight={8}
        />
        <Checkbox
          id={`design-option-align-justify`}
          className={myClass}
          onChange={e => onChange({ input_key, value: "justify" })}
          checked={value === "justify"}
          label="Justify"
          marginRight={8}
        />
      </Pane>
    </Pane>
  );
}

// this wraps the below
export function NumericalUnitsInput(props) {
  let { input_key } = props;
  let label = input_key ? get_label_from_key(input_key) : "";
  let textColor = props.textColor ? props.textColor : "muted";
  let myClass = props.thisClass ? props.thisClass : "standard";

  return (
    <Pane 
      marginTop={8} 
      display="flex" 
      flexDirection="row" 
      alignItems="center" 
      justifyContent="space-between" 
      paddingX={8} 
      className={myClass}
    >
      <Pane width={150} marginRight={8}>
        <Text color={textColor} textTransform="capitalize">
          {label}
        </Text>
      </Pane>

      <Pane width="250px" maxWidth="250px" display="flex" flexDirection="row" alignItems="center">
        <NumericalUnitsInputInner {...props} />
      </Pane>
    </Pane>
  );
}

// this doesn't include a  label and is intened for use with a HOC or the above NumericalUnitsInput
// XXX there's also a similar comp in configure_export_settings.js for the margins /padding
export function NumericalUnitsInputInner(props) {
  let possible_units = ["pt", "in", "cm", "mm", "px", "%"];
  let { input_key, value, onChange, def } = props;
  let textColor = props.textColor ? props.textColor : "muted";
  let myClass = props.thisClass ? props.thisClass : "standard";

  let name = props.name === "watermark_font_size" ? props.name : undefined;

  // console.log("nui", input_key, value, props.disabled);
  let { numeric_value, unit } = props.disabled
    ? parse_dim(def)
    : parse_dim(value);

  let label = input_key ? input_key : "genericinput";
  let is_border = props.is_border ? props.is_border : false;

  // numeric_value = numeric_value === null ? "" : numeric_value;
  // let def = parse_dim(props.def);
  // unit = unit === null ? def.unit : unit;
  return (
    <Pane className={myClass} display={is_border ? "flex" : "block"} flexDirection={is_border ? "row" : "column"}>
      <TextInput
        id={`design-option-${label.replace(/[^a-zA-Z0-9]/g,"")}-val`}
        placeholder={`${numeric_value}`}
        type="number"
        {...props.GW2}
        disabled={props.disabled}
        value={numeric_value}
        width={60}
        minWidth={60}
        paddingX={6}
        marginRight={4}
        onChange={e => {
          // console.log("zz:", e.target.value);
          onChange({ value: `${e.target.value || 0}${unit}`, input_key, name });
        }}
      />
      <Select
        id={`design-option-${label.replace(/[^a-zA-Z0-9]/g,"")}-unit`}
        value={unit}
        {...props.GW2}
        width={60}
        maxWidth={60}
        disabled={props.disabled}
        onChange={event => {
          onChange({
            value: `${numeric_value}${event.target.value}`,
            input_key,
            name,
          });
        }}
      >
        {possible_units.map((u, i) => {
          if (unit === u) {
            return (
              <option value={u} key={i} checked>
                {u}
              </option>
            );
          } else {
            return (
              <option value={u} key={i}>
                {u}
              </option>
            );
          }
        })}
      </Select>
    </Pane>
  );
}

export function NumericalInput(props) {
  let { input_key, value, onChange } = props;
  let label = get_label_from_key(input_key);
  let def = props.def;
  value = value === null ? "" : value;
  let textColor = props.textColor ? props.textColor : "muted";
  let myClass = props.thisClass ? props.thisClass : "standard";
  return (
    <Pane 
      marginTop={8} 
      display="flex" 
      flexDirection="row" 
      alignItems="center" 
      justifyContent="space-between" 
      paddingX={8} 
      className={`${myClass} NumericalInput`}
    >
      <Pane width="130px">
        <Text color={textColor} textTransform="capitalize">
          {label}
        </Text>
      </Pane>

      <Pane width="250px" maxWidth="250px" display="flex" flexDirection="row" alignItems="center">
        <TextInput
          id={`design-option-${label.replace(/[^a-zA-Z0-9]/g,"")}`}
          placeholder={def}
          type="number"
          {...props.GW2}
          value={value}
          onChange={e => onChange({ value: e.target.value, input_key })}
        />
      </Pane>
    </Pane>
  );
}

const ContentVarMap = {
  "counter(page)": "PAGE NUMBER",
  "string(booktitle)": "BOOK TITLE",
  "string(authorname)": "AUTHOR NAME",
  "string(chaptertitle)": "CHAPTER TITLE",
};

const ContentVarMapReverse = {};
Object.keys(ContentVarMap).forEach(key => {
  ContentVarMapReverse[ContentVarMap[key]] = key;
});

function to_css_values(values) {
  return values.map(x => ContentVarMapReverse[x] || x);
}

export function RunContentInputInner(props) {
  let { input_key, value, onChange } = props;
  let label = get_label_from_key(input_key);
  let textColor = props.textColor ? props.textColor : "muted";
  let myClass = props.thisClass ? props.thisClass : "standard";
  // fallback
  value = typeof value === "undefined" ? [] : value;
  // compensate for legacy books
  value = typeof value === "string" ? [value] : value;
  let human_values = value.map(x => {
    return ContentVarMap[x] || x;
  });
  return (
    <Pane className={myClass}>
      <Pane
        marginTop={0}
        marginBottom={16}
        display="flex"
        flexDirection="row"
        alignItems="flex-start"
      >
        <Pane 
          width="250px" 
          maxWidth="250px" 
          display="flex" 
          flexDirection="column" 
          alignItems="flex-start" 
          justifyContent="flex-start"
        >
          <TagInput
            id={`design-option-${label.replace(/[^a-zA-Z0-9]/g,"")}`}
            disabled={props.disabled}
            {...props.GW}
            width="250px"
            maxWidth="250px"
            values={human_values}
            separator={/[\n\r]/}
            tagProps={(value) => {
              if (ContentVarMapReverse.hasOwnProperty(value)) {
                return { color: "green", whiteSpace: "pre" };
              }
              return { whiteSpace: "pre" };
            }}
            onChange={values => {
              let value = to_css_values(values);
              onChange({ value, input_key });
            }}
          />
          <Text color={textColor} fontStyle="italic">
            (Press enter after typing text)
          </Text>
        </Pane>
        <Pane display="flex" flexDirection="column">
          {Object.keys(ContentVarMapReverse).map(human_val => {
            return (
              <Button
                key={human_val}
                iconBefore={PlusIcon}
                height={20}
                margin={4}
                padding={4}
                justifyContent="left"
                disabled={props.disabled}
                onClick={() => {
                  value.push(ContentVarMapReverse[human_val]);
                  onChange({ value, input_key });
                }}
              >
                {human_val}
              </Button>
            );
          })}
        </Pane>
      </Pane>
    </Pane>
  );
}

export function SpaceBreakContentInput(props) {
  let { input_key, value, onChange } = props;

  let label = get_label_from_key(input_key);
  let textColor = props.textColor ? props.textColor : "muted";
  let myClass = props.thisClass ? props.thisClass : "standard";
  return (
    <Pane 
      marginTop={8} 
      display="flex" 
      flexDirection="row" 
      alignItems="center" 
      justifyContent="space-between" 
      paddingX={8} 
      className={myClass}
    >
      <Pane width={150} marginRight={8}>
        <Text color={textColor} textTransform="capitalize">
          {label}
        </Text>
      </Pane>
      <Pane width="250px" maxWidth="250px" display="flex" flexDirection="row" alignItems="center">
        <Checkbox checked={!value.startsWith("http")} className={myClass} />
        <Text marginX={4} color={textColor}>
          Text:
        </Text>
        <TextInput
          id={`design-option-${label.replace(/[^a-zA-Z0-9]/g,"")}`}
          {...props.GW2}
          placeholder="***"
          value={value.startsWith("http") ? `` : value}
          disabled={value.startsWith("http")}
          onChange={e => onChange({ value: e.target.value, input_key })}
          marginRight={16}
        />
        <Checkbox disabled checked={value.startsWith("http")} className={myClass} />
        <Text marginX={4} color={textColor}>
          Image:
        </Text>
        <Button disabled> Pick or Upload an Image</Button>
        {/*<TextInput
          placeholder="0"
          type="number"
          {...GW2}
          value={value}
          onChange={e => onChange({ value: e.target.value, input_key })}
        />*/}
      </Pane>
    </Pane>
  );
}

export function ColumnRuleStyleInput(props) {
  // TODO impl this logic for others
  // let { name, config, onChange } = props;
  let { input_key, value, onChange } = props;
  // let value = get_val_by_name(name, config)
  // width="100%"
  let label = get_label_from_key(input_key);
  let textColor = props.textColor ? props.textColor : "muted";
  let myClass = props.thisClass ? props.thisClass : "standard";
  return (
    <Pane 
      marginTop={8} 
      display="flex" 
      flexDirection="row" 
      alignItems="center" 
      justifyContent="space-between" 
      paddingX={8} 
      className={myClass}
    >
      <Pane width={150} display="flex" marginRight={8}>
        <Text color={textColor} textTransform="capitalize">
          {label}
        </Text>
      </Pane>

      <Pane {...props.GW} width="250px" maxWidth="250px" display="flex" flexDirection="row" alignItems="center">
        {/* the extra wrap and width addresses a bug with combobox width*/}
        <Combobox
          id={`design-option-${label.replace(/[^a-zA-Z0-9]/g,"")}`}
          items={[
            "none",
            "hidden",
            "dotted", 
            "dashed",
            "solid",
            "double",
            "groove",
            "ridge",
            "inset",
            "outset"
          ]}
          onChange={selected => onChange({ input_key, value: selected })}
          selectedItem={value}
          width={props.GW.maxWidth}
        />
      </Pane>
    </Pane>
  );
}

export function ColumnSpanInput(props) {
  let { input_key, value, onChange } = props;
  let label = get_label_from_key(input_key);
  let checked = value === "all";
  let textColor = props.textColor ? props.textColor : "muted";
  let myClass = props.thisClass ? props.thisClass : "standard";
  return (
    <Pane 
      marginTop={8} 
      display="flex" 
      flexDirection="row" 
      alignItems="center" 
      justifyContent="space-between" 
      paddingX={8} 
      className={myClass}
    >
      <Checkbox
        id={`design-option-${label.replace(/[^a-zA-Z0-9]/g,"")}`}
        className={myClass}
        checked={checked}
        onChange={e => {
          let value = e.target.checked ? "all" : "none";
          onChange({ input_key, value });
        }}
        label="Span all columns"
        marginRight={12}
      />
    </Pane>
  );
}

/* top-left | top-right | bottom-right | bottom-left */
export function BorderRadiusInput(props) {
  let { input_key, value, onChange, def, GW, GW2 } = props;
  let label = get_label_from_key(input_key);
  let textColor = props.textColor ? props.textColor : "muted";
  let myClass = props.thisClass ? props.thisClass : "standard";
  // console.log(def);
  // prettier-ignore
  let changeHelper = (val, index)=>{
    // value =
    let v = JSON.parse(JSON.stringify(value))
    v[index]=val
    // console.log(v)
    onChange({input_key, value:v})
  }
  return (
    <Pane 
      marginTop={16} 
      display="flex" 
      flexDirection="column" 
      alignItems="center" 
      justifyContent="space-between" 
      paddingX={8} 
      className={myClass}
    >
      <Pane marginRight={8}>
        <Text color={textColor} textTransform="capitalize">
          {label}
        </Text>
      </Pane>
      <Pane position="relative" paddingTop="50px" paddingBottom="40px">
        <Pane position="absolute" right="0" top="0">
          <NumericalUnitsInput
            value={value[0]}
            def={def[0]}
            onChange={ev => changeHelper(ev.value, 0)}
            GW2={GW2}
          />
        </Pane>
        <Pane position="absolute" left="-60px" top="0">
          <NumericalUnitsInput
            value={value[1]}
            def={def[1]}
            onChange={ev => changeHelper(ev.value, 1)}
            GW2={GW2}
          />
        </Pane>
        <Pane 
          className="radius-box" 
          height="75px"
          width="150px"
          border="2px solid #07C896"
          style={{ borderRadius: value.join(" ") }}
        />
        <Pane position="absolute" right="0" bottom="0">
          <NumericalUnitsInput
            value={value[3]}
            def={def[3]}
            onChange={ev => changeHelper(ev.value, 3)}
            GW2={GW2}
          />
        </Pane>
        <Pane position="absolute" left="-60px" bottom="0">
          <NumericalUnitsInput
            value={value[2]}
            def={def[2]}
            onChange={ev => changeHelper(ev.value, 2)}
            GW2={GW2}
          />
        </Pane>
      </Pane>
    </Pane>
  );
}

// export function BorderInput(props) {
export function BorderInputInner(props) {
  let { input_key, value, onChange, def, disabled } = props;
  // console.log(value);
  let label = get_label_from_key(input_key);
  // console.log(input_key, value);
  let [dims, kind, color] = value;
  let textColor = props.textColor ? props.textColor : "muted";
  let myClass = props.thisClass ? props.thisClass : "standard";
  // console.log(dims);
  // console.log(input_key, value);
  // console.log(def);
  // todo def!
  // return <Pane>BorderInput</Pane>;
  // prettier-ignore
  let possible_kinds = ["none","hidden","dotted","dashed","solid","double","groove","ridge","inset","outset"]
  return (
    <Pane 
      display="flex" 
      flexDirection="row" 
      marginTop={8} 
      alignItems="center" 
      className={myClass}
      justifyContent="space-between"
      width="100%"
    >
      <NumericalUnitsInputInner
        value={dims}
        def={def[0]}
        disabled={disabled}
        onChange={ev => onChange({ value: [ev.value, kind, color], input_key })}
        GW2={{
          minWidth: props.GW2.minWidth / 2,
          maxWidth: props.GW2.maxWidth / 2,
        }}
        is_border={true}
      />
      <Select
        value={kind}
        disabled={disabled}
        {...props.GW2}
        onChange={event =>
          onChange({
            value: [dims, event.target.value, color],
            input_key,
          })
        }
      >
        {possible_kinds.map((u, i) => {
          // prettier-ignore
          if (value === u) { return ( <option value={u} key={i} checked> {u} </option> );
        } else { return (  <option value={u} key={i}> {u} </option>    ); }
        })}
      </Select>
      <ColorPickerInner
        value={color}
        GW2={props.GW2}
        def={def[2]}
        disabled={disabled}
        onChange={ev => {
          onChange({
            value: [dims, kind, ev.value],
            input_key,
          });
        }}
      />
    </Pane>
  );
}
//
export function PageChoiceInput(props) {
  // prettier-ignore
  let possibles = ["none","chapter","part","frontmatter","backmatter","clear" ];
  return <ChoiceInput {...props} possibles={possibles} />;
}
export function ChoiceInput(props) {
  // prettier-ignore
  let { input_key, value, onChange, possibles } = props;
  let label = get_label_from_key(input_key);
  let textColor = props.textColor ? props.textColor : "muted";
  let myClass = props.thisClass ? props.thisClass : "standard";

  return (
    <Pane 
      marginTop={8} 
      display="flex" 
      flexDirection="row" 
      alignItems="center" 
      justifyContent="space-between" 
      paddingX={8} 
      className={myClass}
    >
      <Pane width={150} marginRight={8}>
        <Text color={textColor} textTransform="capitalize">
          {label}
        </Text>
      </Pane>

      <Select
        id={`design-option-${label.replace(/[^a-zA-Z0-9]/g,"")}`}
        value={value}
        {...props.GW2}
        onChange={event =>
          onChange({
            value: event.target.value,
            input_key,
          })
        }
      >
        {possibles.map((u, i) => {
          // prettier-ignore
          if (value === u) { return ( <option value={u} key={i} checked> {u} </option> );
        } else { return (  <option value={u} key={i}> {u} </option>    ); }
        })}
      </Select>
    </Pane>
  );
}

// helper to address string vs array
function join_if_not_string(o) {
  if (typeof o === "object") {
    return o.join();
  }
  return o;
}

// string set, vs content ?
export function SimpleTextInput(props) {
  let { input_key, value, onChange } = props;
  let label = get_label_from_key(input_key);
  let helperText = get_helper_text(input_key);
  let def = props.def;
  def = join_if_not_string(def);
  value = join_if_not_string(value);
  let textColor = props.textColor ? props.textColor : "muted";
  let myClass = props.thisClass ? props.thisClass : "standard";
  // console.log(def, input_key, value);
  // console.log(typeof def);
  // console.log(typeof value);
  // todo there was an issue about this sometimes it's an array, somtimes a func
  // value = value.join === null ? "" : value;
  return (
    <Pane 
      marginTop={8} 
      display="flex" 
      flexDirection="row" 
      alignItems="center" 
      justifyContent="space-between" 
      paddingX={8} 
      className={`${myClass} SimpleTextInput`}
    >
      <Pane width="130px">
        <Text color={textColor} textTransform="capitalize">
          {label}
        </Text>
      </Pane>

      <Pane width="250px" maxWidth="250px" display="flex" flexDirection="row" alignItems="center">
        <Pane>
          <TextInput
            id={`design-option-${label.replace(/[^a-zA-Z0-9]/g,"")}`}
            placeholder={def}
            {...props.GW}
            value={value}
            onChange={e => onChange({ value: e.target.value, input_key })}
          />
          {helperText && (
            <Text size={300}>{helperText}</Text>
          )}
        </Pane>
      </Pane>
    </Pane>
  );
}

// XXX HOC to wrap a basic component with label and a toggle for a string value,
// more info https://reactjs.org/docs/higher-order-components.html
// prettier-ignore
function CheckToggleHOC(WrappedComponent, { toggleTrueVal, toggleFalseVal, checkLabel }) {
  return function(props) {
    let { input_key, def, value, onChange } = props;
    let direction = input_key.includes("content") ? "column" : "row";
    let label = input_key ? get_label_from_key(input_key) : "";
    let textColor = props.textColor ? props.textColor : "muted";
    let myClass = props.thisClass ? props.thisClass : "standard";
    // stringify for quick array equality
    let is_check_active = JSON.stringify(value) === JSON.stringify(toggleTrueVal)
    // a sort of fallback behavior, we can't switch use the default here in some cases because it could be the truevalue, which would basically create an infinite loop
    let to_val = def !== toggleTrueVal ? def : toggleFalseVal;
    // and another fallback
    // to_val = typeof to_val ==='undefined' ? toggleFalseVal : to_val
    // console.log(to_val, def)
    return (
      <Pane
        marginTop={8}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between" 
        paddingX={8} 
        className={`${myClass} CheckToggleHOC`}
      >
        <Pane width="130px">
          <Text color={textColor} textTransform="capitalize">
            {label}
          </Text>
        </Pane>

        <Pane width="250px" maxWidth="250px" display="flex" flexDirection={direction} alignItems="center">
          <Checkbox
            id={`design-option-${label.replace(/[^a-zA-Z0-9]/g,"")}`}
            className={myClass}
            onChange={e => {
              if (is_check_active) {
                // it's currently active, so we're switching to false, populate the actual input
                onChange({ input_key, value: to_val });
              } else {
                // it's not active, switching to true, switching to auto or none or w/e
                onChange({ input_key, value: toggleTrueVal });
              }
            }}
            checked={is_check_active}
            label={checkLabel}
            marginRight={12}
          />
          <WrappedComponent {...props} def={to_val} disabled={is_check_active} />
        </Pane>
      </Pane>
    );
  };
}

function ContentWrap(WrappedComponent, { toggleTrueVal, toggleFalseVal, checkLabel }) {
  return function(props) {
    let { input_key, def, value, onChange } = props;
    console.log(input_key);
    let direction = input_key.includes("content") ? "column" : "row";
    let label = input_key ? get_label_from_key(input_key) : "";
    let textColor = props.textColor ? props.textColor : "muted";
    let myClass = props.thisClass ? props.thisClass : "standard";
    // stringify for quick array equality
    let is_check_active = JSON.stringify(value) === JSON.stringify(toggleTrueVal)
    // a sort of fallback behavior, we can't switch use the default here in some cases because it could be the truevalue, which would basically create an infinite loop
    let to_val = def !== toggleTrueVal ? def : toggleFalseVal;
    // and another fallback
    // to_val = typeof to_val ==='undefined' ? toggleFalseVal : to_val
    // console.log(to_val, def)
    return (
      <Pane
        marginTop={8}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="space-between" 
        paddingX={8} 
        className={`${myClass} CheckToggleHOC`}
      >
        <Pane
          marginTop={0}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between" 
          paddingX={0} 
          width="100%"
        >
          <Pane width="130px">
            <Text color={textColor} textTransform="capitalize">
              {label}
            </Text>
          </Pane>

          <Pane 
            width="250px" 
            maxWidth="250px" 
            display="flex" 
            flexDirection={direction} 
            alignItems={direction === "column" ? "flex-start" : "center"}
          >
            <Checkbox
              id={`design-option-${label.replace(/[^a-zA-Z0-9]/g,"")}`}
              className={myClass}
              onChange={e => {
                if (is_check_active) {
                  // it's currently active, so we're switching to false, populate the actual input
                  onChange({ input_key, value: to_val });
                } else {
                  // it's not active, switching to true, switching to auto or none or w/e
                  onChange({ input_key, value: toggleTrueVal });
                }
              }}
              checked={is_check_active}
              label={checkLabel}
              marginRight={12}
            />
          </Pane>
        </Pane>
        <WrappedComponent {...props} def={to_val} disabled={is_check_active} />
      </Pane>
    );
  };
}

// diverging from CheckToggleHOC, 2022-12-02
function BorderWrap(WrappedComponent, { toggleTrueVal, toggleFalseVal, checkLabel }) {
  return function(props) {
    let { input_key, def, value, onChange } = props;
    let label = input_key ? get_label_from_key(input_key) : "";
    let textColor = props.textColor ? props.textColor : "muted";
    let myClass = props.thisClass ? props.thisClass : "standard";
    // stringify for quick array equality
    let is_check_active = JSON.stringify(value) === JSON.stringify(toggleTrueVal)
    // a sort of fallback behavior, we can't switch use the default here in some cases because it could be the truevalue, which would basically create an infinite loop
    let to_val = def !== toggleTrueVal ? def : toggleFalseVal;

    let pos = "Left";
    if (input_key.includes("border")) {
      let posArr = input_key.split("_");
      pos = posArr[posArr.length - 1].replace("border", "");
       // flexDirection={pos == "Top" || pos == "Bottom" ? "column": "row"}
    }
    // and another fallback
    // to_val = typeof to_val ==='undefined' ? toggleFalseVal : to_val
    // console.log(to_val, def)
    return (
      <Pane
        marginTop={16}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between" 
        padding={8} 
        width="350px"
        boxSizing="content-box"
        marginLeft="auto"
        marginRight="auto"
        className={`${myClass} CheckToggleHOC border${pos}`}
      >
        <Pane width="320px" display="flex" flexDirection="row">
          <Text color={textColor} textTransform="capitalize">
            {label}
          </Text>
          <Checkbox
            id={`design-option-${label.replace(/[^a-zA-Z0-9]/g,"")}`}
            className={myClass}
            onChange={e => {
              if (is_check_active) {
                // it's currently active, so we're switching to false, populate the actual input
                onChange({ input_key, value: to_val });
              } else {
                // it's not active, switching to true, switching to auto or none or w/e
                onChange({ input_key, value: toggleTrueVal });
              }
            }}
            checked={is_check_active}
            label={checkLabel}
            marginLeft={12}
            marginBottom={0}
            marginTop={0}
          />
        </Pane>

        <Pane 
          width="320px" 
          maxWidth="320px" 
          display="flex" 
          flexDirection="row" 
          alignItems="center" 
        >
          <WrappedComponent {...props} def={to_val} disabled={is_check_active} />
        </Pane>
      </Pane>
    );
  };
}

// toggleTrueValue should be the string line inherit or none or auto
// toggleFalse should be the value we a) use as the placeholder when grayed out / true, b) the value we switch to when they uncheck the check

export const NumericalUnitsInputWithAuto = CheckToggleHOC(
  NumericalUnitsInputInner,
  {
    toggleTrueVal: "auto",
    toggleFalseVal: "0pt",
    checkLabel: "auto",
  }
);
export const NumericalUnitsInputWithNormal = CheckToggleHOC(
  NumericalUnitsInputInner,
  {
    toggleTrueVal: "normal",
    toggleFalseVal: "0pt",
    checkLabel: "normal",
  }
);
export const NumericalUnitsInputWithInherit = CheckToggleHOC(
  NumericalUnitsInputInner,
  {
    toggleTrueVal: "inherit",
    toggleFalseVal: "0pt",
    checkLabel: "inherit",
  }
);
export const ColorPicker = CheckToggleHOC(ColorPickerInner, {
  toggleTrueVal: "transparent",
  toggleFalseVal: "#000000",
  // dur, what were we going to label this instead?
  // TODO
  checkLabel: "none",
});
export const BorderInput = BorderWrap(BorderInputInner, {
  toggleTrueVal: ["0pt", "solid", "#000000"],
  toggleFalseVal: ["0pt", "solid", "#000000"],
  // dur, what were we going to label this instead?
  // TODO
  checkLabel: "none",
});

export const RunContentInput = ContentWrap(RunContentInputInner, {
  toggleTrueVal: ["normal"],
  toggleFalseVal: [],
  checkLabel: "normal",
});
