import React from "react";
import ReactDOM from "react-dom";
// prettier-ignore
import {Pane, Popover, Menu, Position, Tooltip, Text, Heading, Button, Switch, TextInputField, TabNavigation, Tab, SidebarTab, Tablist, Link, toaster, IconButton, Icon, Small, TextInput, RadioGroup, Select, Strong, Checkbox, Combobox, TagInput, Portal, LockIcon, UnlockIcon, ChevronLeftIcon, ChevronRightIcon, ManualIcon, GridViewIcon, ListIcon, DeleteIcon, DisableIcon, EyeOpenIcon, MobilePhoneIcon, BookIcon, SettingsIcon } from "evergreen-ui";
import Frame from "./frame.js";
import HedButton from "../shared/HedButton.js";
import { LoadingPane, ProjectTabs, NotFound } from "../shared/common.js";
import { human_adv_template_label } from "../../helpers/util.js";
import ExternalPreview from "./external.js";

export function ViewOptionsControl(props) {
  // let sect_id = props.section_list[props.opt.section_index]["id"];
  // let isLocked = props.section_meta[sect_id]["is_locked"];
  let isLocked = props.isLocked;
  let tooltip_content = isLocked
    ? "The print layout for this chapter is locked. See Page Layout for more info."
    : "The print layout for this chapter is unlocked. See Page Layout for more info.";

  return (
    <Pane display="flex" flexDirection="row" alignItems="center">
      {/* props.children is special, see the parent XXX */}
      <Pane>{props.children}</Pane>
      <Pane marginRight={4} display="flex" flexDirection="row" alignItems="center">
        <FrameDisplayOpts {...props} />
        <DigitalPrintToggle {...props} />
      </Pane>
    </Pane>
  );
}

function DigitalPrintToggle(props) {
  const [checked, setChecked] = React.useState(props.opt.epub_mode);
  return (
    <Pane display="flex" flexDirection="row" alignItems="center">
      <Pane marginRight={4} lineHeight="25px" borderRight="1px solid #ffffff">&nbsp;</Pane>
      <HedButton 
        title="Print View" 
        appearance="hedflat"
        marginX={4}
        onClick={() => props.setOpt({ epub_mode: false })}
      >
        <BookIcon color={checked ? "disabled" : "success"} />
      </HedButton>
      <Switch 
        checked={checked} 
        onChange={(e) => {
          props.setOpt({ epub_mode: !props.opt.epub_mode });
          // setChecked(e.target.checked);
        }}
      />
      <HedButton 
        title="Digital View" 
        appearance="hedflat"
        marginX={4}
        onClick={() => props.setOpt({ epub_mode: true })}
      >
        <MobilePhoneIcon color={checked ? "success" : "disabled"} />
      </HedButton>
    </Pane>
  );
}
function FrameDisplayOpts(props) {
  return (
    <Popover
      position={Position.BOTTOM_LEFT}
      content={
        <Pane>
        <Menu>
          <Menu.Group>
            <Menu.Item
              intent={props.opt.spread_view ? "success" : "none"}
              icon={ManualIcon}
              onSelect={() =>
                props.setOpt({ spread_view: !props.opt.spread_view })
              }
            >
              {props.opt.spread_view
                ? "View as Single Page"
                : "View as Spreads"}
            </Menu.Item>
            <Menu.Item
              icon={GridViewIcon}
              intent={props.opt.show_guides ? "success" : "none"}
              onSelect={() =>
                props.setOpt({ show_guides: !props.opt.show_guides })
              }
            >
              {props.opt.show_guides ? "Hide Guides" : "Show Guides"}
            </Menu.Item>
            <Menu.Item
              icon={ListIcon}
              intent={props.opt.show_grid ? "success" : "none"}
              onSelect={() => props.setOpt({ show_grid: !props.opt.show_grid })}
            >
              {props.opt.show_grid ? "Hide Grid" : "Show Grid"}
            </Menu.Item>
          </Menu.Group>
          <Menu.Divider />
          <Menu.Group>
            <Menu.Item
              icon={props.opt.close_external_preview ? DeleteIcon : DisableIcon}
              intent={props.opt.close_external_preview ? "success" : "none"}
              onSelect={() =>
                props.setOpt({
                  close_external_preview: !props.opt.close_external_preview,
                })
              }
            >
              {props.opt.close_external_preview
                ? "Leave Preview Open"
                : "Auto Close Preview"}
            </Menu.Item>
          </Menu.Group>
        </Menu>
        </Pane>
      }
    >
      <Pane marginRight={8}>
        <HedButton 
          title="View Options" 
          appearance="hedflat"
        >
          <SettingsIcon color="disabled" />
        </HedButton>
      </Pane>
    </Popover>
  );
}

export function SectionSelect(props) {
  let { section_list, section_meta } = props;
  // if we passed false, it's just for jumping from dash

  return (
    <Pane display="inline">
      <Select
        maxWidth="30vw"
        disabled={props.saving || props.loading}
        value={props.section_index}
        onChange={
          event => props.setOpt({ section_index: parseInt(event.target.value) })
          // this.setState({ value: parseInt(event.target.value) })
        }
      >
        {/* if we passed false, it's acting sorta uncontrolled, in dash*/}
        {props.section_index === false ? (
          <option value={-1} key={"jump"}>
            🚀 Jump To Section:
          </option>
        ) : (
          ``
        )}
        {section_list.map((s, i) => {
          // let isLocked = getLockedStatus(section_meta, i);
          return (
            <option value={i} key={i}>
              {i+1}: {s.title} {s["is_locked"] ? "(LOCKED) " : ""}
            </option>
          );
        })}
      </Select>
    </Pane>
  );
}
