import React from "react";
import ReactDOM from "react-dom";
import { useState, useEffect } from "react";
import { firebase, db, storage } from "./../../fire.js";
// prettier-ignore
import { Pane, Text, Heading, Paragraph, Button, Spinner, Badge, Alert, Code, IconButton, Icon, TabNavigation, Tab, Link, Popover, Position, Label, Textarea, Checkbox, TickCircleIcon, ChevronRightIcon, ChevronDownIcon, CogIcon, CrossIcon, WarningSignIcon} from "evergreen-ui";

import HedButton from "./HedButton.js";

export function ExpandablePane(props) {
  let myid = props.label
    ? props.label.replace(/[^a-zA-Z0-9]/g,"")
    : "step";
  return (
    <Pane border marginTop={8} marginBottom={8}>
      <Pane
        id={myid}
        padding={8}
        display="flex"
        flexDirection="row"
        alignItems="center"
        maxHeight="none"
        // background="blueTint"
        // F7F9FD
        css={{ ":hover": { backgroundColor: "#F7F9FD" } }}
        cursor="pointer"
        onClick={() => props.onClick(props.index)}
      >
        <IconButton icon={props.expanded ? ChevronDownIcon : ChevronRightIcon} marginRight={8} />
        <Icon
          icon={
            props.icon ? props.icon : props.complete ? TickCircleIcon : "blank"
          }
          size={16}
          marginRight={8}
          color={props.icon ? "muted" : "green"}
        />
        <Heading>{props.label || "Step"}</Heading>
      </Pane>
      <Pane
        marginLeft={8}
        marginRight={8}
        transition="all 0.6s ease"
        maxHeight={props.expanded ? "none" : 0}
      >
        {props.expanded ? props.children : ``}
      </Pane>
    </Pane>
  );
}

export function ExpandablePaneTinted(props) {
  let myid = props.label
    ? props.label.replace(/[^a-zA-Z0-9]/g,"")
    : "step";
  return (
    <Pane marginY={16} backgroundColor="#E8E7EE">
      <Pane
        id={myid}
        padding={8}
        display="flex"
        flexDirection="row"
        alignItems="center"
        maxHeight="none"
        // background="blueTint"
        // F7F9FD
        css={{ ":hover": { backgroundColor: "#F7F9FD" } }}
        cursor="pointer"
        onClick={() => props.onClick(props.index)}
      >
        <HedButton appearance="hedflatdark" marginRight={0}>
          {props.expanded ? (
            <ChevronDownIcon />
          ) : (
            <ChevronRightIcon />
          )} 
        </HedButton>
        <Heading>{props.label || "Step"}</Heading>
      </Pane>
      <Pane
        marginLeft={8}
        marginRight={8}
        transition="all 0.6s ease"
        maxHeight={props.expanded ? "none" : 0}
      >
        {props.expanded ? props.children : ``}
      </Pane>
    </Pane>
  );
}

export function ExpandablePaneUnderline(props) {
  let myid = props.label
    ? props.label.replace(/[^a-zA-Z0-9]/g,"")
    : "step";
  return (
    <Pane marginY={16}>
      <Pane
        id={myid}
        display="flex"
        flexDirection="row"
        alignItems="center"
        maxHeight="none"
        borderBottom="2px solid #373550"
        // background="blueTint"
        // F7F9FD
        css={{ ":hover": { backgroundColor: "#F7F9FD" } }}
        cursor="pointer"
        onClick={() => props.onClick(props.index)}
      >
        <HedButton appearance="hedflatdark" marginRight={0}>
          {props.expanded ? (
            <ChevronDownIcon />
          ) : (
            <ChevronRightIcon />
          )} 
        </HedButton>
        <Heading>{props.label || "Step"}</Heading>
      </Pane>
      <Pane
        marginLeft={8}
        marginRight={8}
        transition="all 0.6s ease"
        maxHeight={props.expanded ? "none" : 0}
      >
        {props.expanded ? props.children : ``}
      </Pane>
    </Pane>
  );
}

export function LoadingPane(props) {
  return (
    <Pane
      display="flex"
      padding={props.pad || 8}
      marginTop={props.pad || 8}
      marginBottom={props.pad || 8}
      alignItems="center"
      justifyContent="center"
      background="tint2"
      borderRadius={3}
      border="muted"
    >
      <Spinner size={16} />
      <Heading size={600} marginLeft={8}>
        {props.message || "Loading"}
      </Heading>
      <Pane />
    </Pane>
  );
}

export function CloudImage(props) {
  let { path, isCover, onClick, handleUpdateImageMeta, alt_text, coverPane, ...passProps } = props;
  // prettier-ignore
  if (!path){ return <Text marginBottom={32}>No Image!</Text>}
  let unmounted = false;
  let filename = path.split("/").pop();

  // Declare a new state variable, which we'll call src
  const [src, setSrc] = useState("");

  function handleLoad(url) {
    // prettier-ignore
    if (unmounted){ return }
    setSrc(url);
  }

  const [imgalttext, setImgalttext] = useState(
    alt_text && alt_text[filename] && alt_text[filename]["alt_text"]
    ? alt_text[filename]["alt_text"]
    : false
  );

  const [decorative, setDecorative] = useState(
    alt_text && alt_text[filename] && alt_text[filename]["isDecorative"]
    ? alt_text[filename]["isDecorative"]
    : false
  );

  // takes two arguments, the effect, and what should determine if we rerun the effect (ie path)
  useEffect(
    () => {
      let storageRef = storage.ref();
      let imageRef = storageRef
        .child(path)
        .getDownloadURL()
        .then(handleLoad);
      return () => {
        unmounted = true;
      };
    },
    [path]
  );

  let tmp_meta = {};
  tmp_meta[filename] = {};
  // prettier-ignore
  let AltTextPrompt="If the image is purely decorative, you can check the box below. Otherwise, describe the image...";

  let placeholder = imgalttext
      ? imgalttext 
      : AltTextPrompt;

  return (
    <Pane>
      <Pane
        display="flex"
        flexDirection="column"
        background="tint2"
        alignItems="center"
        onClick={onClick}
        border={props.coverPane ? "none" : "muted"}
        padding={8}
        margin={8}
        maxWidth={200}
        cursor={onClick ? "pointer" : null}
      >
        {isCover ? (
          <Badge position="absolute" marginLeft={0} marginTop={55} color="green">
            COVER
          </Badge>
        ) : (
          ``
        )}
        <img src={src} {...passProps} />
        <Code style={{ overflowWrap: "break-word" }} maxWidth={200}>
          {path.replace(/^.*[\\\/]/, "")}
        </Code>
      </Pane>
      {!coverPane && (
        <Pane
          display="flex"
          flexDirection="row"
          justifyContent="center"
        >
          <Popover
            content={({ close }) => (
              <Pane
                width={400}
                height={200}
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                padding={8}
              >
                <Label
                  htmlFor="alt-text-input"
                  marginBottom={4}
                  display="block"
                >
                  Image ALT Text:
                </Label>
                <Textarea
                  id="alt-text-input"
                  placeholder={placeholder}
                  onChange={e => setImgalttext(e.target.value)}
                  value={imgalttext ? imgalttext : ""}
                />
                <Checkbox 
                  label="Image is purely decorative?"
                  checked={decorative}
                  onChange={e => {
                    let value = e.target.checked ? true : false;
                    setDecorative(value);
                  }}
                />
                <Pane 
                  display="flex" 
                  flexDirection="row" 
                  justifyContent="center"
                >
                  <Button 
                    appearance="primary"
                    marginRight={8}
                    onClick={e => {
                      tmp_meta[filename]["alt_text"] = imgalttext;
                      tmp_meta[filename]["isDecorative"] = decorative;
                      handleUpdateImageMeta(tmp_meta);
                    }}
                  >
                    Save
                  </Button>
                  <Button onClick={close}>
                    Close
                  </Button>
                </Pane>
              </Pane>
            )}
            position={Position.BOTTOM}
          >
            <Button appearance="minimal" padding={0} margin={0}>
              <Icon
                icon={CogIcon}
                color={"muted"}
                size={24}
              />
            </Button>
          </Popover>
        </Pane>
      )}
    </Pane>
  );
}

export function NoContent(props) {
  return (
    <Pane marginTop={64} padding={16}>
      <Text>
        You need to upload some content first,{" "}
        <a href={`#/projects/${props.id}/dash`}>
          go back to the dashboard
        </a>{" "}
        and drag a docx file on to it.
      </Text>
    </Pane>
  );
}

export function ImageList({ project, setCoverImage, handleUpdateImageMeta, alt_text }) {
  let images = project.images || [];
  return (
    <Pane paddingTop={16}>
      <Text>Click an image to make it the cover image.</Text>
      <Pane display="flex" flexWrap="wrap" maxWidth={900}>
        {images.map((path, i) => (
          <CloudImage
            path={path}
            key={i}
            width={"130px"}
            onClick={e => setCoverImage(path)}
            handleUpdateImageMeta={handleUpdateImageMeta}
            isCover={path === project.cover_image}
            alt_text={alt_text}
            coverPane={false}
          />
        ))}
      </Pane>
    </Pane>
  );
}

export function IngestMessages(props) {
  // first lets make the messages
  let messages = props.items.map((item, i) => {
    let at_time = new Date(item["at"]);
    let item_type = item["type"];
    // console.log();
    return (
      <Alert
        intent={item_type}
        title={item_type.charAt(0).toUpperCase() + item_type.slice(1)}
        marginTop={8}
        marginBottom={8}
        key={i}
        textAlign="left"
      >
        <Text>{item["message"]} </Text>
      </Alert>
    );
  });
  // then the wrap and clear button and return it all
  return (
    <Pane marginTop={16} marginBottom={16} textAlign="right">
      <Button height={24} iconAfter={CrossIcon} onClick={props.clear}>
        Clear Conversion Messages
      </Button>
      {messages}
    </Pane>
  );
}
// the tabbed navigation for project_main
export function ProjectTabs(props) {
  let loc = window.location;
  let proj_id = loc.hash.replace(/^.*projects\//,"").replace(/\/.*$/,"");
  loc = `${loc.origin}${loc.pathname}#/projects/${proj_id}`;

  return (
    <TabNavigation>
      {props.tab_list.map(tab => {
        let href = `${loc}/${tab.key}`;
        return (
          <Tab
            id={`navtab-${tab.key}`}
            key={tab.key}
            is="a"
            height={40}
            href={href}
            isSelected={tab.key === props.area}
          >
            {tab.label}
          </Tab>
        );
      })}
    </TabNavigation>
  );
}

function MCForm(props) {
  let hiddenStyle = {
    position: 'absolute', 
    left: '-5000px',
    display: 'inline-block',
  };
  let formElStyle = {
    display: 'inline-block',
    marginLeft: '8px',
  };
  let mcFormButton = {
    backgroundColor: '#7B8B9A',
    color: 'white',
    borderStyle: 'solid',
    borderColor: '#7B8B9A',
    borderRadius: '5px',
  }
  return (
    <Pane id="mc_embed_signup" marginTop={16}>
      <form 
        action="https://hederis.us17.list-manage.com/subscribe/post?u=c5bc6d6d58d3a6ca885eea897&amp;id=5ba347e4f2" 
        method="post" 
        id="mc-embedded-subscribe-form" 
        name="mc-embedded-subscribe-form" 
        className="validate" 
        target="_blank" 
        noValidate
      >
        <div id="mc_embed_signup_scroll">
          <Text>
            Get updates about new features and bug fixes:
            </Text>
          <input style={formElStyle} type="email" name="EMAIL" className="email" id="mce-EMAIL" placeholder="email address" required />
          <div style={hiddenStyle} aria-hidden="true">
            <input type="text" name="b_c5bc6d6d58d3a6ca885eea897_5ba347e4f2" tabIndex="-1" value="" readOnly />
          </div>
          <div style={formElStyle}>
            <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" style={mcFormButton} readOnly />
          </div>
        </div>
      </form>
    </Pane>
  );
}

export function Footer(props) {
  return (
    <Pane background="tint2" paddingTop={16}>
      <Pane justifyContent="center" display="flex" flexDirection="row">
        <Text>Something not working right? Let us know! </Text>
        <Link
          marginLeft={4}
          color="neutral"
          href="mailto:help@hederis.com?subject=Hederis Issue"
        >
          help@hederis.com
        </Link>
      </Pane>
      <Pane display="flex" flexDirection="row" justifyContent="center">
        <MCForm />
      </Pane>
      <div className="small_dark_logo" />

      <Pane display="flex" flexDirection="row" justifyContent="center">
        <Text margin={8}>© 2019 Hederis </Text>
        <Link
          margin={8}
          color="neutral"
          href="https://www.hederis.com/privacy.html"
          target="_blank"
        >
          Privacy Policy
        </Link>
        <Link
          margin={8}
          color="neutral"
          href="https://www.hederis.com/terms.html"
          target="_blank"
        >
          Terms of Service
        </Link>
        <Link
          margin={8}
          color="neutral"
          href="mailto:help@hederis.com?subject=Hederis Feedback"
        >
          Feedback
        </Link>
        <Link
          margin={8}
          color="neutral"
          href="https://docs.hederis.com/"
          target="_blank"
        >
          Help & Documentation
        </Link>
      </Pane>
    </Pane>
  );
}

export function NotFound(props) {
  return (
    <Pane>
      <Heading textAlign="center" size={700} marginTop={64}>
        Sorry, {props.kind || "Page"} Not Found!
      </Heading>
      <Pane textAlign="center" marginTop={32}>
        <Icon icon={WarningSignIcon} color="muted" size={48} />
      </Pane>
      <Heading textAlign="center" size={500} marginTop={32}>
        Find your existing <a href="/#/">projects here</a>
      </Heading>
    </Pane>
  );
}

export function TitleOnly(props) {
  // prettier-ignore
  if (!props.project) { return <Pane display="flex" flexDirection="column" marginY={16}/> }
  return (
    <Pane display="flex" flexDirection="column" marginBottom={16} borderBottom="1px solid #ffffff">
      <Heading size={300} marginTop={8} color="#ffffff">
        <Text color="#ffffff">Title:</Text>
      </Heading>
      <Heading color="#ffffff" size={700} maxWidth={800} marginTop={0} marginBottom={8}>
        {props.project.title}
      </Heading>
    </Pane>
  );
}

export function Summary(props) {
  // prettier-ignore
  if (!props.project) { return <Pane display="flex" flexDirection="column" marginY={16}/> }
  return (
    <Pane display="flex" flexDirection="column" marginBottom={16}>
      {!props.hideTitle && (
        <Pane>
          <Heading size={300} marginTop={8} color="#ffffff">
            <Text color="#ffffff">Title:</Text>
          </Heading>
          <Heading size={700} maxWidth={800} marginTop={0} marginBottom={8} color="#ffffff">
            {props.project.title}
          </Heading>
        </Pane>
      )}
      <Heading size={300} marginTop={8} color="#ffffff">
        <Text color="#ffffff">Author:</Text> {props.project.author}
      </Heading>
      {props.project.pisbn && (
        <Heading size={300} marginTop={8} color="#ffffff">
          <Text color="#ffffff">PISBN:</Text> {props.project.pisbn}
        </Heading>
      )}
      {props.project.eisbn && (
        <Heading size={300} marginTop={8} color="#ffffff">
          <Text color="#ffffff">EISBN:</Text> {props.project.eisbn}
        </Heading>
      )}
      {props.project.page_count ? (
        <Heading size={300} marginTop={8} color="#ffffff">
          <Text color="#ffffff">Length:</Text> {props.project.page_count} pages
        </Heading>
      ) : (
        ``
      )}
      {props.project.group.startsWith("user_") ? (
        ``
      ) : (
        <Heading size={300} marginTop={8} color="#ffffff">
          <Text color="#ffffff">Group:</Text> {props.project.group}
        </Heading>
      )}
    </Pane>
  );
}

export function TemplateBox(props) {
  return (
    <Pane
      border
      display="flex"
      alignItems="center"
      justifyContent="center"
      background="greenTint"
      width={150}
      height={250}
      onClick={props.onClick}
      cursor="pointer"
    >
      <Text size={400} textTransform="capitalize">
        {props.name}
      </Text>
    </Pane>
  );
}

export function Messages(props) {
  let { project } = props;
  // console.log(project);
  if (!project.build_messages.length && !project.ingest_messages.length) {
    return ``;
  }
  if (project.ingest_messages[0]["type"] === "error")
    return (
      <Pane marginY={8}>
        <Alert
          isRemoveable={true}
          onRemove={props.clearMessages}
          intent="danger"
          title="There was an error during conversion"
        >
          <Text>Need Help?</Text>
          <Button marginLeft={16} onClick={props.sendReportEmail}>
            {" "}
            Let us know
          </Button>
        </Alert>
      </Pane>
    );

  return ``;
}
