"use strict";

function makeInitalCSSPaged(fontimports, fontFaceStr, template_builder_advanced, ALL_FONTS_ARR, advanced_config_version) {
  let defaults_original_template = "";

  if (!advanced_config_version || parseFloat(advanced_config_version) < 1) {
    defaults_original_template = `
      @page chapter:first {
        margin-top: ${toPx(template_builder_advanced.master_chapter_first_marginTop)};
        margin-bottom: ${toPx(template_builder_advanced.master_chapter_first_marginBottom)};
      }

      @page frontmatter:first {
        margin-top: ${toPx(template_builder_advanced.master_frontmatter_first_marginTop)};
        margin-bottom: ${toPx(template_builder_advanced.master_frontmatter_first_marginBottom)};
      }

      @page backmatter:first {
        margin-top: ${toPx(template_builder_advanced.master_backmatter_first_marginTop)};
        margin-bottom: ${toPx(template_builder_advanced.master_backmatter_first_marginBottom)};
      }

      @page part:first {
        margin-top: ${toPx(template_builder_advanced.master_part_first_marginTop)};
        margin-bottom: ${toPx(template_builder_advanced.master_part_first_marginBottom)};
      }

      @page clear:first {
        margin-top: ${toPx(template_builder_advanced.master_clear_first_marginTop)};
        margin-bottom: ${toPx(template_builder_advanced.master_clear_first_marginBottom)};
      }
    `;
  }

  return `${fontimports}

  ${fontFaceStr}

  @font-face {
    font-family: "FreeMono";
    font-style: normal;
    font-weight: 100 400;
    src: url("https://s3-us-west-2.amazonaws.com/hederis-assets-extra/FreeMono.ttf");
  }

  @font-face {
    font-family: "FreeMono";
    font-style: italic;
    font-weight: 100 400;
    src: url("https://s3-us-west-2.amazonaws.com/hederis-assets-extra/FreeMonoOblique.ttf");
  }

  @font-face {
    font-family: "FreeMono";
    font-weight: bold;
    font-style: normal;
    src: url("https://s3-us-west-2.amazonaws.com/hederis-assets-extra/FreeMonoBold.ttf");
  }

  @font-face {
    font-family: "FreeMono";
    font-weight: bold;
    font-style: italic;
    src: url("https://s3-us-west-2.amazonaws.com/hederis-assets-extra/FreeMonoBoldOblique.ttf");
  }

  @font-face {
    font-family: "FreeMono";
    font-weight: 500 900;
    font-style: normal;
    src: url("https://s3-us-west-2.amazonaws.com/hederis-assets-extra/FreeMonoBold.ttf");
  }

  @font-face {
    font-family: "FreeMono";
    font-weight: 500 900;
    font-style: italic;
    src: url("https://s3-us-west-2.amazonaws.com/hederis-assets-extra/FreeMonoBoldOblique.ttf");
  }

  @font-face {
    font-family: "FreeSans";
    font-weight: 100 400;
    font-style: normal;
    src: url("https://s3-us-west-2.amazonaws.com/hederis-assets-extra/FreeSans.ttf");
  }

  @font-face {
    font-family: "FreeSans";
    font-weight: 100 400;
    font-style: italic;
    src: url("https://s3-us-west-2.amazonaws.com/hederis-assets-extra/FreeSansOblique.ttf");
  }

  @font-face {
    font-family: "FreeSans";
    font-weight: bold;
    font-style: normal;
    src: url("https://s3-us-west-2.amazonaws.com/hederis-assets-extra/FreeSansBold.ttf");
  }

  @font-face {
    font-family: "FreeSans";
    font-weight: bold;
    font-style: italic;
    src: url("https://s3-us-west-2.amazonaws.com/hederis-assets-extra/FreeSansBoldOblique.ttf");
  }

  @font-face {
    font-family: "FreeSans";
    font-weight: 500 900;
    font-style: normal;
    src: url("https://s3-us-west-2.amazonaws.com/hederis-assets-extra/FreeSansBold.ttf");
  }

  @font-face {
    font-family: "FreeSans";
    font-weight: 500 900;
    font-style: italic;
    src: url("https://s3-us-west-2.amazonaws.com/hederis-assets-extra/FreeSansBoldOblique.ttf");
  }

  @font-face {
    font-family: "FreeSerif";
    font-weight: 100 400;
    font-style: normal;
    src: url("https://s3-us-west-2.amazonaws.com/hederis-assets-extra/FreeSerif.ttf");
  }

  @font-face {
    font-family: "FreeSerif";
    font-weight: 100 400;
    font-style: italic;
    src: url("https://s3-us-west-2.amazonaws.com/hederis-assets-extra/FreeSerifItalic.ttf");
  }

  @font-face {
    font-family: "FreeSerif";
    font-weight: bold;
    font-style: normal;
    src: url("https://s3-us-west-2.amazonaws.com/hederis-assets-extra/FreeSerifBold.ttf");
  }

  @font-face {
    font-family: "FreeSerif";
    font-weight: bold;
    font-style: italic;
    src: url("https://s3-us-west-2.amazonaws.com/hederis-assets-extra/FreeSerifBoldItalic.ttf");
  }

  @font-face {
    font-family: "FreeSerif";
    font-weight: 500 900;
    font-style: normal;
    src: url("https://s3-us-west-2.amazonaws.com/hederis-assets-extra/FreeSerifBold.ttf");
  }

  @font-face {
    font-family: "FreeSerif";
    font-weight: 500 900;
    font-style: italic;
    src: url("https://s3-us-west-2.amazonaws.com/hederis-assets-extra/FreeSerifBoldItalic.ttf");
  }

  @font-face {
    font-family: "HederisPlaceholder";
    src: url("https://hederis-assets-extra.s3-us-west-2.amazonaws.com/HederisPlaceholder.ttf");
  }

  @page {
    size: ${template_builder_advanced.page_width} ${template_builder_advanced.page_height};
    padding: 0in;
    @top-right-corner {
      vertical-align: bottom;
    }
    @top-right {
      vertical-align: bottom;
    }
    @top-center {
      vertical-align: bottom;
    }
    @top-left {
      vertical-align: bottom;
    }
    @top-left-corner {
      vertical-align: bottom;
    }
    @bottom-right-corner {
      vertical-align: top;
    }
    @bottom-right {
      vertical-align: top;
    }
    @bottom-center {
      vertical-align: top;
    }
    @bottom-left {
      vertical-align: top;
    }
    @bottom-left-corner {
      vertical-align: top;
    }
    @left-top {
      vertical-align: middle;
    }
    @left-middle {
      vertical-align: middle;
    }
    @left-bottom {
      vertical-align: middle;
    }
    @right-top {
      vertical-align: middle;
    }
    @right-middle {
      vertical-align: middle;
    }
    @right-bottom {
      vertical-align: middle;
    }
    @footnote {
      float: bottom;
    }
  }

  @page chapter:right {
    margin: ${toPx(template_builder_advanced.master_chapter_recto_margins).join(" ")};
  }
  @page chapter:left {
    margin: ${toPx(template_builder_advanced.master_chapter_verso_margins).join(" ")};
  }

  @page frontmatter:right {
    margin: ${toPx(template_builder_advanced.master_frontmatter_recto_margins).join(" ")};
  }
  @page frontmatter:left {
    margin: ${toPx(template_builder_advanced.master_frontmatter_verso_margins).join(" ")};
  }

  @page backmatter:right {
    margin: ${toPx(template_builder_advanced.master_backmatter_recto_margins).join(" ")};
  }
  @page backmatter:left {
    margin: ${toPx(template_builder_advanced.master_backmatter_verso_margins).join(" ")};
  }

  @page part:right {
    margin: ${toPx(template_builder_advanced.master_part_recto_margins).join(" ")};
  }
  @page part:left {
    margin: ${toPx(template_builder_advanced.master_part_verso_margins).join(" ")};
  }

  @page clear:right {
    margin: ${toPx(template_builder_advanced.master_clear_recto_margins).join(" ")};
  }
  @page clear:left {
    margin: ${toPx(template_builder_advanced.master_clear_verso_margins).join(" ")};
  }

  * { -webkit-font-smoothing: antialiased; }

  ${defaults_original_template}
  
  div.pagedjs_pagebox {
    background-size: auto ${toPx(template_builder_advanced.body_lineHeight)};
  }
  body {
    widows: 1;
    orphans: 1;
    hyphens: manual;
    counter-reset: page 0 footnote 0 footnote-marker 0;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  img {
    min-height: ${toPx(template_builder_advanced.body_lineHeight)};
    max-width: 100%;
  }
  img[data-pi-image-size="fullbleed"] {
    page-break-before: always;
    page-break-after: always;
  }
  img[data-pi-image-size="fullbleed"]:first-child {
    page-break-before: avoid;
  }
  *[data-hederis-type=hwprfig] img[data-pi-image-size="fullbleed"]:first-child {
    page-break-before: avoid;
    page-break-after: avoid;
  }
  [data-hederis-type=hblkchaptitle] {
    string-set: chaptertitle content();
  }
  [data-hederis-type=hsectitlepage] [data-hederis-type=hblkchaptitle] {
    string-set: booktitle content();
  }
  [data-hederis-type=hsectitlepage] [data-hederis-type=hblkau] {
    string-set: authorname content();
  }
  *[data-footnote-marker]:not([data-split-from]) {
    list-style-type: decimal;
  }
  span[data-hederis-type=hspannoteref] {
    page-break-before: avoid;
  }
  a[data-hederis-type=hspannoteref] {
    text-decoration: none;
    color: inherit;
  }
  a[data-hederis-type=hspannotemarker] {
    text-decoration: none;
    color: inherit;
  }
  *.pageBreakAfter,
  *.bakedPageBreakAfter {
    page-break-after: always;
  }
  span.line {
    break-inside: avoid;
  }
  *.avoidbreaks {
    page-break-inside: avoid;
  }
  div.page_contents {
    page-break-inside: avoid;
    margin: 0;
    padding: 0;
  }
  `;  
}

function footnoteOverrides(config) {
  return `
  .FootnoteReference, *[data-hederis-type="hspannoteref"] {
    float: footnote;
    footnote-policy: block;
    footnote-display: block;
  }
  *[data-hederis-type=hwprfootnote] {    
    display: block;
  }
  ::footnote-call {
    line-height: 4pt;
  }
  *[data-hederis-type=hblkfootnote] {
    display: block;
  }
  *[data-hederis-type=hblkfootnote]:first-of-type {
    display: inline-block;
    text-indent: 0;
  }
  sup *[data-hederis-type=hblkfootnote]:first-of-type {
    display: block;
  }
  *[data-hederis-type=hblkfootnote]:first-of-type::before {
    content: counter(footnote-marker) ". ";
  }
  .pagedjs_page.pagedjs_named_page.pagedjs_chapter_page *[data-footnote-marker]:first-of-type {
    margin-top: ${config.master_chapter_footnoteMargin ? config.master_chapter_footnoteMargin : "12pt"};
  }
  .pagedjs_page.pagedjs_named_page.pagedjs_frontmatter_page *[data-footnote-marker]:first-of-type {
    margin-top: ${config.master_frontmatter_footnoteMargin ? config.master_frontmatter_footnoteMargin : "12pt"};
  }
  .pagedjs_page.pagedjs_named_page.pagedjs_backmatter_page *[data-footnote-marker]:first-of-type {
    margin-top: ${config.master_backmatter_footnoteMargin ? config.master_backmatter_footnoteMargin : "12pt"};
  }
  .pagedjs_page.pagedjs_named_page.pagedjs_clear_page *[data-footnote-marker]:first-of-type {
    margin-top: ${config.master_clear_footnoteMargin ? config.master_clear_footnoteMargin : "12pt"};
  }
  .pagedjs_page.pagedjs_named_page.pagedjs_part_page *[data-footnote-marker]:first-of-type {
    margin-top: ${config.master_part_footnoteMargin ? config.master_part_footnoteMargin : "12pt"};
  }
  [data-footnote-marker]::marker {
    content: none;
  }
  `;
}

function generateMarginDefaults(config, master, page, area, ALL_FONTS_ARR) {
  var k, v;
  var obj = {};
  var a = "top-" + config["master_" + master + "_recto_runheadAlignment"];
  var b = "top-" + config["master_" + master + "_verso_runheadAlignment"];
  var c = "bottom-" + config["master_" + master + "_recto_runfootAlignment"];
  var d = "bottom-" + config["master_" + master + "_verso_runfootAlignment"];
  if (area === a || area === b || area === c || area === d) {
    if (page === "blank" || page === "first") {
      // figure out which areas are relevant
      if (area === a || area === b) {
        obj["content"] = getContent(config["master_" + master + "_" + page + "_runheadContent"]);
      }

      if (area === c || area === d) {
        obj["content"] = getContent(config["master_" + master + "_" + page + "_runfootContent"]);
      }
    } else {
      obj["font-family"] = FL(config["master_" + master + "_runhead_font"], ALL_FONTS_ARR)[1];
      obj["font-size"] = toPx(config["master_" + master + "_runhead_fontSize"]);
      obj["font-weight"] = parseFontWeight(config["master_" + master + "_runhead_fontWeight"],config["master_" + master + "_runhead_font"],ALL_FONTS_ARR);
      obj["font-style"] = toPx(config["master_" + master + "_runhead_fontStyle"]);
      obj["color"] = config["master_" + master + "_runhead_textColor"];
      if (area.match(/^top/)) {
        obj["padding-bottom"] = toPx(config["master_" + master + "_runhead_spaceBelow"]);
      } else if (area.match(/^bottom/)) {
        obj["padding-top"] = toPx(config["master_" + master + "_runfoot_spaceAbove"]);
      }
      // figure out which areas are relevant
      if (page === "recto" && area === a) {
        obj["content"] = getContent(config["master_" + master + "_recto_runheadContent"]);
        obj["text-align"] = config["master_" + master + "_recto_runheadAlignment"];
        obj["text-align-last"] = "auto";
      }
      if (page === "verso" && area === b) {
        obj["content"] = getContent(config["master_" + master + "_verso_runheadContent"]);
        obj["text-align"] = config["master_" + master + "_verso_runheadAlignment"];
        obj["text-align-last"] = "auto";
      }
      if (page === "recto" && area === c) {
        obj["content"] = getContent(config["master_" + master + "_recto_runfootContent"]);
        obj["text-align"] = config["master_" + master + "_recto_runfootAlignment"];
        obj["text-align-last"] = "auto";
      }
      if (page === "verso" && area === d) {
        obj["content"] = getContent(config["master_" + master + "_verso_runfootContent"]);
        obj["text-align"] = config["master_" + master + "_verso_runfootAlignment"];
        obj["text-align-last"] = "auto";
      }
    }
  }
  return obj;
}

function makePagedOverrides(template_builder_advanced, ALL_FONTS_ARR) {
  return `body {
    -webkit-hyphens: manual;
    -ms-hyphens: manual;
    hyphens: manual;
  }

  :root {
    --pagedjs-crop-offset: 0.125in;
  }

  img[data-pi-image-size="fullbleed"] {
    position: absolute;
    overflow: visible;
    max-width: none;
  }

  .pagedjs_right_page img[data-pi-image-size="fullbleed"] {
    width: calc(var(--pagedjs-pagebox-width) + var(--pagedjs-bleed-right-right) + var(--pagedjs-bleed-right-left));
    bottom: calc(calc(var(--pagedjs-bleed-right-bottom) + var(--pagedjs-margin-bottom)) * -1);
    left: calc(calc(var(--pagedjs-bleed-right-left) + var(--pagedjs-margin-left)) * -1);
  }

  .pagedjs_left_page img[data-pi-image-size="fullbleed"] {
    width: calc(var(--pagedjs-pagebox-width) + var(--pagedjs-bleed-left-right) + var(--pagedjs-bleed-left-left));
    bottom: calc(calc(var(--pagedjs-bleed-left-bottom) + var(--pagedjs-margin-bottom)) * -1);
    left: calc(calc(var(--pagedjs-bleed-left-left) + var(--pagedjs-margin-left)) * -1);
  }

  img[data-pi-image-size="fullwidth"] {
    overflow: visible;
    max-width: none;
  }

  .pagedjs_right_page img[data-pi-image-size="fullwidth"] {
    width: calc(var(--pagedjs-pagebox-width) + var(--pagedjs-bleed-right-right) + var(--pagedjs-bleed-right-left));
  }

  .pagedjs_left_page img[data-pi-image-size="fullwidth"] {
    width: calc(var(--pagedjs-pagebox-width) + var(--pagedjs-bleed-left-right) + var(--pagedjs-bleed-left-left));
  }

  .pagedjs_margin-content {
    white-space: pre;
  }

  div.watermark {
    width: ${template_builder_advanced.page_width};
    position: absolute;
    left: 0;
    z-index: 9999;
  }

  div.watermark.center {
    text-align: center;
  }

  .pagedjs_left_page div.watermark {
    padding-left: ${toPx(template_builder_advanced.master_chapter_verso_margins)[3]};
    padding-right: ${toPx(template_builder_advanced.master_chapter_verso_margins)[1]};
  }

  .pagedjs_right_page div.watermark {
    padding-left: ${toPx(template_builder_advanced.master_chapter_recto_margins)[3]};
    padding-right: ${toPx(template_builder_advanced.master_chapter_recto_margins)[1]};
  }

  .pagedjs_right_page div.watermark.outside,
  .pagedjs_left_page div.watermark.inside {
    text-align: right;
  }

  .pagedjs_right_page div.watermark.inside,
  .pagedjs_left_page div.watermark.outside {
    text-align: left;
  }

  div.watermark.header {
    top: 0;
    padding-top: 10pt;
  }

  div.watermark.footer {
    bottom: 0;
    padding-bottom: 10pt;
  }
  `;
}

function makePagedFolioOverrides(template_builder_advanced, ALL_FONTS_ARR) {
  return `.pagedjs_page.pagedjs_named_page.pagedjs_chapter_page.pagedjs_left_page .pagedjs_margin-top-left>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_chapter_page.pagedjs_right_page .pagedjs_margin-top-left>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_chapter_page.pagedjs_left_page .pagedjs_margin-bottom-left>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_chapter_page.pagedjs_right_page .pagedjs_margin-bottom-left>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_chapter_page.pagedjs_left_page .pagedjs_margin-top-center>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_chapter_page.pagedjs_right_page .pagedjs_margin-top-center>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_chapter_page.pagedjs_left_page .pagedjs_margin-bottom-center>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_chapter_page.pagedjs_right_page .pagedjs_margin-bottom-center>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_chapter_page.pagedjs_left_page .pagedjs_margin-top-right>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_chapter_page.pagedjs_right_page .pagedjs_margin-top-right>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_chapter_page.pagedjs_left_page .pagedjs_margin-bottom-right>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_chapter_page.pagedjs_right_page .pagedjs_margin-bottom-right>.pagedjs_margin-content.hasFolio::after {
    content: none;
  }

  .pagedjs_page.pagedjs_named_page.pagedjs_frontmatter_page.pagedjs_left_page .pagedjs_margin-top-left>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_frontmatter_page.pagedjs_right_page .pagedjs_margin-top-left>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_frontmatter_page.pagedjs_left_page .pagedjs_margin-bottom-left>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_frontmatter_page.pagedjs_right_page .pagedjs_margin-bottom-left>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_frontmatter_page.pagedjs_left_page .pagedjs_margin-top-center>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_frontmatter_page.pagedjs_right_page .pagedjs_margin-top-center>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_frontmatter_page.pagedjs_left_page .pagedjs_margin-bottom-center>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_frontmatter_page.pagedjs_right_page .pagedjs_margin-bottom-center>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_frontmatter_page.pagedjs_left_page .pagedjs_margin-top-right>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_frontmatter_page.pagedjs_right_page .pagedjs_margin-top-right>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_frontmatter_page.pagedjs_left_page .pagedjs_margin-bottom-right>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_frontmatter_page.pagedjs_right_page .pagedjs_margin-bottom-right>.pagedjs_margin-content.hasFolio::after {
    content: none;
  }

  .pagedjs_page.pagedjs_named_page.pagedjs_backmatter_page.pagedjs_left_page .pagedjs_margin-top-left>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_backmatter_page.pagedjs_right_page .pagedjs_margin-top-left>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_backmatter_page.pagedjs_left_page .pagedjs_margin-bottom-left>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_backmatter_page.pagedjs_right_page .pagedjs_margin-bottom-left>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_backmatter_page.pagedjs_left_page .pagedjs_margin-top-center>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_backmatter_page.pagedjs_right_page .pagedjs_margin-top-center>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_backmatter_page.pagedjs_left_page .pagedjs_margin-bottom-center>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_backmatter_page.pagedjs_right_page .pagedjs_margin-bottom-center>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_backmatter_page.pagedjs_left_page .pagedjs_margin-top-right>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_backmatter_page.pagedjs_right_page .pagedjs_margin-top-right>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_backmatter_page.pagedjs_left_page .pagedjs_margin-bottom-right>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_backmatter_page.pagedjs_right_page .pagedjs_margin-bottom-right>.pagedjs_margin-content.hasFolio::after {
    content: none;
  }

  .pagedjs_page.pagedjs_named_page.pagedjs_clear_page.pagedjs_left_page .pagedjs_margin-top-left>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_clear_page.pagedjs_right_page .pagedjs_margin-top-left>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_clear_page.pagedjs_left_page .pagedjs_margin-bottom-left>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_clear_page.pagedjs_right_page .pagedjs_margin-bottom-left>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_clear_page.pagedjs_left_page .pagedjs_margin-top-center>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_clear_page.pagedjs_right_page .pagedjs_margin-top-center>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_clear_page.pagedjs_left_page .pagedjs_margin-bottom-center>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_clear_page.pagedjs_right_page .pagedjs_margin-bottom-center>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_clear_page.pagedjs_left_page .pagedjs_margin-top-right>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_clear_page.pagedjs_right_page .pagedjs_margin-top-right>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_clear_page.pagedjs_left_page .pagedjs_margin-bottom-right>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_clear_page.pagedjs_right_page .pagedjs_margin-bottom-right>.pagedjs_margin-content.hasFolio::after {
    content: none;
  }

  .pagedjs_page.pagedjs_named_page.pagedjs_part_page.pagedjs_left_page .pagedjs_margin-top-left>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_part_page.pagedjs_right_page .pagedjs_margin-top-left>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_part_page.pagedjs_left_page .pagedjs_margin-bottom-left>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_part_page.pagedjs_right_page .pagedjs_margin-bottom-left>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_part_page.pagedjs_left_page .pagedjs_margin-top-center>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_part_page.pagedjs_right_page .pagedjs_margin-top-center>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_part_page.pagedjs_left_page .pagedjs_margin-bottom-center>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_part_page.pagedjs_right_page .pagedjs_margin-bottom-center>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_part_page.pagedjs_left_page .pagedjs_margin-top-right>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_part_page.pagedjs_right_page .pagedjs_margin-top-right>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_part_page.pagedjs_left_page .pagedjs_margin-bottom-right>.pagedjs_margin-content.hasFolio::after,
  .pagedjs_page.pagedjs_named_page.pagedjs_part_page.pagedjs_right_page .pagedjs_margin-bottom-right>.pagedjs_margin-content.hasFolio::after {
    content: none;
  }

  .pagedjs_page.pagedjs_named_page.pagedjs_chapter_page .pagedjs_margin-content.hasFolio span[data-hederis-type=hspanfolio] {
    font-family: ${FL(template_builder_advanced.master_chapter_folio_font, ALL_FONTS_ARR)[1]};
    font-size: ${toPx(template_builder_advanced.master_chapter_folio_fontSize)};
    font-weight: ${parseFontWeight(template_builder_advanced.master_chapter_folio_fontWeight,template_builder_advanced.master_chapter_folio_font,ALL_FONTS_ARR)};
    font-style: ${template_builder_advanced.master_chapter_folio_fontStyle};
    color: ${template_builder_advanced.master_chapter_folio_textColor};
  }

  .pagedjs_page.pagedjs_named_page.pagedjs_frontmatter_page .pagedjs_margin-content.hasFolio span[data-hederis-type=hspanfolio] {
    font-family: ${FL(template_builder_advanced.master_frontmatter_folio_font, ALL_FONTS_ARR)[1]};
    font-size: ${toPx(template_builder_advanced.master_frontmatter_folio_fontSize)};
    font-weight: ${parseFontWeight(template_builder_advanced.master_frontmatter_folio_fontWeight,template_builder_advanced.master_frontmatter_folio_font,ALL_FONTS_ARR)};
    font-style: ${template_builder_advanced.master_frontmatter_folio_fontStyle};
    color: ${template_builder_advanced.master_frontmatter_folio_textColor};
  }

  .pagedjs_page.pagedjs_named_page.pagedjs_backmatter_page .pagedjs_margin-content.hasFolio span[data-hederis-type=hspanfolio] {
    font-family: ${FL(template_builder_advanced.master_backmatter_folio_font, ALL_FONTS_ARR)[1]};
    font-size: ${toPx(template_builder_advanced.master_backmatter_folio_fontSize)};
    font-weight: ${parseFontWeight(template_builder_advanced.master_backmatter_folio_fontWeight,template_builder_advanced.master_backmatter_folio_font,ALL_FONTS_ARR)};
    font-style: ${template_builder_advanced.master_backmatter_folio_fontStyle};
    color: ${template_builder_advanced.master_backmatter_folio_textColor};
  }

  .pagedjs_page.pagedjs_named_page.pagedjs_part_page .pagedjs_margin-content.hasFolio span[data-hederis-type=hspanfolio] {
    font-family: ${FL(template_builder_advanced.master_part_folio_font, ALL_FONTS_ARR)[1]};
    font-size: ${toPx(template_builder_advanced.master_part_folio_fontSize)};
    font-weight: ${parseFontWeight(template_builder_advanced.master_part_folio_fontWeight,template_builder_advanced.master_part_folio_font,ALL_FONTS_ARR)};
    font-style: ${template_builder_advanced.master_part_folio_fontStyle};
    color: ${template_builder_advanced.master_part_folio_textColor};
  }

  .pagedjs_page.pagedjs_named_page.pagedjs_clear_page .pagedjs_margin-content.hasFolio span[data-hederis-type=hspanfolio] {
    font-family: ${FL(template_builder_advanced.master_clear_folio_font, ALL_FONTS_ARR)[1]};
    font-size: ${toPx(template_builder_advanced.master_clear_folio_fontSize)};
    font-weight: ${parseFontWeight(template_builder_advanced.master_clear_folio_fontWeight,template_builder_advanced.master_clear_folio_font,ALL_FONTS_ARR)};
    font-style: ${template_builder_advanced.master_clear_folio_fontStyle};
    color: ${template_builder_advanced.master_clear_folio_textColor};
  }
  `;
}

function makeInitialCSS(template_builder_advanced, ALL_FONTS_ARR) {
  return `html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  blockquote, q {
    quotes: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  * {
    box-sizing: border-box;
  }

  body {
    font-family: ${template_builder_advanced.body_fallbackFont ? makeBodyFont(template_builder_advanced.body_bodyFont, template_builder_advanced.body_fallbackFont, ALL_FONTS_ARR) : FL(template_builder_advanced.body_bodyFont, ALL_FONTS_ARR)[1]}, serif, 'FreeSerif';
    font-size: ${toPx(template_builder_advanced.body_bodySize)};
    line-height: ${toPx(template_builder_advanced.body_lineHeight)};
    background-repeat: repeat;
    background-position: top left;
    background-size: auto ${toPx(template_builder_advanced.body_lineHeight)};
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  p {
    font-family: ${template_builder_advanced.body_fallbackFont ? makeBodyFont(template_builder_advanced.body_bodyFont, template_builder_advanced.body_fallbackFont, ALL_FONTS_ARR) : FL(template_builder_advanced.body_bodyFont, ALL_FONTS_ARR)[1]}, serif, 'FreeSerif';
    font-size: ${toPx(template_builder_advanced.body_bodySize)};
    line-height: ${toPx(template_builder_advanced.body_lineHeight)};
  }

  [data-hederis-type="hblkuli"],
  [data-hederis-type="hblkoli"],
  [data-hederis-type="hblklip"] {
    text-indent: 0;
  }

  sup, sub {
    vertical-align: baseline;
    position: relative;
    top: -0.4em;
  }
  sub {
    top: 0.4em;
  }
  p[data-embedded-html="true"] {
    display: none;
  }
  `;
}

function makeEPUBCSS(template_builder_advanced, ALL_FONTS_ARR) {
  // for each section type, get the current master in use;
  // get that master's top-margin value for the first page;
  // add that as the top margin for the section's first child.
  return `
  img[data-pi-image-size="fullbleed"] {
    position: relative;
    overflow: visible;
    height: auto;
    width: auto;
    bottom: auto;
    left: auto;
    margin-left: auto;
    margin-right: auto;
  }
  img[data-pi-image-size="fullbleed"] {
    page-break-before: always;
    page-break-after: always;
    break-before: always;
    break-after: always;
  }
  table, *[data-hederis-type=hwprtable], .BOX-hwprtable, thead, tbody, tfoot, tr {
    width: 100%;
  }
  table, *[data-hederis-type=hwprtable], .BOX-hwprtable, tr, thead, tbody, tfoot {
    overflow-x: scroll;
  }`;
}

function makePrintOverrideCSS() {
  return `span.hspantighten {
    letter-spacing: -0.2pt;
  }
  span.hspanloosen {
    letter-spacing: 0.2pt;
  }
  *.continued {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  *.continuation,
  p[data-hederis-type="hblkpa"].continuation,
  p[data-hederis-type="hblkp"].continuation,
  div[data-hederis-type^="hwprpoetry"] p.continuation,
  dd p + p.continuation,
  section[data-type="bibliography"] p[data-hederis-type="hblkpa"].continuation,
  section[data-type="bibliography"] p[data-hederis-type="hblkp"].continuation {
    text-indent: 0;
    margin-top: 0;
    padding-top: 0;
  }
  .pagedjs_page_content > div > section > *:first-child,
  .pagedjs_page_content > div > section > *:first-child > *:first-child {
    margin-top: 0;
  }
  div.hederis_area-BottomAndNotes,
  div.hederis_area-floatBottom,
  div.hederis_area-floatNote {
    width: 100%;
  }
  span.line {
    display: block;
    position: relative;
  }
  span.line+span.line {
    text-indent: 0;
  }
  `;
}

function toPx(s) {
  try {
    if (s) {
      if (Array.isArray(s)) {
        s.forEach(function(item, index) {
          s[index] = toPx(s[index]);
        });
        return s;
      } else if (
        typeof s === 'string' || 
        s instanceof String
      ) {
        if (s.match(/^auto/g)) {
          return "auto";
        } else if (!s.match(/^\d/g)) {
          return s;
        } else if (s.match(/%$/g) || s.match(/em$/g) || s.match(/\s/g)) {
          return s;
        } else {
          if (s.endsWith("pt")) {
            let points = parseFloat(s.replace("pt", ""));
            // console.log(points);
            var val = points * (96 / 72);
            val = Math.round(val);
            return val.toString() + "px";
          } else if (s.endsWith("in")) {
            let inches = parseFloat(s.replace("in", ""));
            var val = inches * 96;
            val = Math.round(val);
            return val.toString() + "px";
            // 96px
          } else if (s.endsWith("mm")) {
            let mms = parseFloat(s.replace("mm", ""));
            var val = mms * 3.779528;
            val = Math.round(val);
            return val.toString() + "px";
          } else if (s.endsWith("cm")) {
            let cms = parseFloat(s.replace("cm", ""));
            var val = cms * 37.79528;
            val = Math.round(val);
            return val.toString() + "px";
          } else if (s.endsWith("px")) {
            return s;
            // 96px
          } else if (s.endsWith("null")) {
            s = s.replace("null", "") + "pt";
            s = toPx(s);
            return s
            // 96px
          } else {
            // console.log("❌", s);
          }
        }
      }
    }
  } catch (error) {
    console.error(error);
  }
}

function makeBodyFont(main, fallback, ALL_FONTS_ARR) {
  let finalString = "";
  try {
    let mainArr = FL(main, ALL_FONTS_ARR)[1].split(",");
    let fallbackArr = FL(fallback, ALL_FONTS_ARR)[1].split(",");
    finalString = finalString + mainArr.shift().trim() + "," + fallbackArr.shift().trim() + "," + mainArr.join(",") + "," + fallbackArr.join(",");
  } catch (error) {
    console.error(error);
  }
  return finalString;
}

function FL(name, ALL_FONTS_ARR) {
  let url = "";
  let fam = name;
  let user_font = false;
  let res = false;
  if (name) {
    fam = fam.replace(/\s?\(?(USER FONT)?\)?\s?\(?[OT]*\)?$/,"");
    if (ALL_FONTS_ARR !== undefined && ALL_FONTS_ARR !== false) {
      res = ALL_FONTS_ARR.find(x => x["display-name"] === fam);
      if (res !== undefined) {
        url = res["url"];
        fam = res["font-family"];
        // add fallbacks
        if ("user_font" in res) {
          user_font = res["user_font"];
        }
      } else if (fam != "inherit") {
        fam = `'${fam}'`;
      }
    }
    if (
      fam != "inherit" && 
      !fam.match(/Free[SM][aeo][nr][soi]f?/)
    ) {
      fam = fam + ", 'FreeSans'";
    }
    if (
      fam != "inherit" && 
      !fam.match("HederisPlaceholder")
    ) {
      fam = fam + ", 'HederisPlaceholder'";
    }
  }
  return [url, fam, user_font, res];
}

function getContent(val) {
  if (val === null ||
      val === undefined ||
      val.length === 0) {
    val = "normal";
  } else if (Array.isArray(val)) {
    val = val.join("");
  } else if (!val.match("^[\"'].*[\"']$") &&
    val.indexOf("target-counter(") < 0 &&
    val.indexOf("string(") < 0 &&
    val.indexOf("counter(") < 0 &&
    !val.match("^normal$")) {
    val = '"' + val + '"';
  }
  return val;
}

function parseFont(config,allurls,makeDeclarations,ALL_FONTS_ARR) {
  for (var k in config) {
    if (config.hasOwnProperty(k) && k.match("[fF]ont$")) {
      if (config[k]) {
        var [myurl, myfam, user_font, res] = FL(config[k], ALL_FONTS_ARR);
        if (res && user_font) {
          makeDeclarations.push([user_font, res]);
        } else {
          if (myurl && allurls.indexOf(myurl) === -1) {
            allurls.push(myurl);
          }
        }
      }
    } else if (config.hasOwnProperty(k) && k.match("_subSelectors$")) {
      for (var j in config[k]) {
        if (config[k].hasOwnProperty(j)) {
          [allurls,makeDeclarations] = parseFont(config[k][j],allurls,makeDeclarations,ALL_FONTS_ARR);
        }
      }
    }
  }
  return [allurls,makeDeclarations];
}

function parseFontWeight(val,name,ALL_FONTS_ARR) {
  let weight = "normal";
  try {
    if (val === "inherit") {
      weight = val;
    } else {
      if (name) {
        let fam = name.replace(/\s?\(USER FONT\)\s?\(?[OT]*\)?$/g, "");
        if (ALL_FONTS_ARR !== undefined && ALL_FONTS_ARR !== false) {
          let res = ALL_FONTS_ARR.find(x => x["display-name"] === fam);
          if (res !== undefined) {
            if (res["weights"].hasOwnProperty(val)) {
              weight = res["weights"][val]["weight"];
            } else {
              if (val === "[bold imitation]" || val === "bold") {
                weight = "bold";
              } else if (val === "[italic imitation]") {
                weight = "italic";
              }
            }
          }
        }
      }
    }
    // sledgehammer to deal with imitation values
    if (
      (
        val === "[bold imitation]" || 
        val === "bold" || 
        val === "[italic imitation]"
      ) && 
      weight === "normal"
    ) {
      if (val === "[bold imitation]" || val === "bold") {
        weight = "bold";
      } else if (val === "[italic imitation]") {
        weight = "italic";
      }
    }
  } catch(error) {
    console.log(error);
  }
  weight = parseFontWeightHelper(weight);
  return weight;
}

function parseFontWeightHelper(weight) {
  try {
    // sledgehammer to deal with any errors that might have occurred
    var weight_strings = {
      "thin":100,
      "extralight":200,
      "light":300,
      "medium":500,
      "semibold":600,
      "extrabold":800,
      "heavy":900,
      "black":900,
      "extrablack":950
    };
    if (!weight || weight === undefined) {
      weight = "normal";
    } else if (
      typeof weight === "number" && 
      weight / 10 < 10
    ) {
      weight = weight * 100;
    } else if (
      typeof weight === "string"
    ) {
      var sanitized_weight = weight.toLowerCase().replace(" ","").replace("-","");
      if (weight.match(/^[123456789]$/)) {
        weight = weight + "00";
      } else if (
        weight_strings.hasOwnProperty(sanitized_weight)
      ) {
        weight = weight_strings[sanitized_weight];
      }
    }
  } catch(error) {
    console.log(error);
  }
  return weight;
}

function parseFontStyle(style, family) {
  if (
    style.toLowerCase() != "regular" &&
    style.toLowerCase() != "italic"
  ) {
    if (family.match(/[Ii]tali?c?/)) {
      style = "italic";
    } else {
      style = "regular";
    }
  }
  return style;
}

// typekit fonts need imports
function getFontImports(config, ALL_FONTS_ARR, allurls, makeDeclarations) {
  // if weights/paths exists, create the font-face rule instead
  var myfont;
  var myimport = "";
  var fontFaces = [];
  allurls.forEach(function(el) {
    myimport = myimport + "\n@import url(" + el + ");";
  });
  if (makeDeclarations.length > 0) {
    makeDeclarations.forEach(function(x) {
      let f = x[1];
      for(var w in f.weights) {
        f.weights[w]["styles"].forEach(function(s) {
          myfont = makeFontFaceDeclaration({
            "font-family": f["font-family"].split(",")[0],
            "path":f.weights[w]["paths"][s],
            "style":s.toLowerCase(),
            "weight": parseFontWeightHelper(f.weights[w]["weight"]),
            "type":f.type
          });
          fontFaces.push(myfont);
        });
      };
    });
  }
  return [myimport, fontFaces];
}

// non-typekit fonts get an actual font-face declaration
// expects a hash: {font-family,path,style,weight}
function makeFontFaceDeclaration(font) {
  var types = {"TT": "truetype","OT": "opentype"};
  var type = font['type'].toUpperCase();
  var myFontFace = `@font-face{
    font-family:${font['font-family']};
    src:url("${font['path']}") format("${types[type]}");
    font-display:auto;
    font-style:${parseFontStyle(font["style"],font['path'])};
    font-weight:${parseFontWeightHelper(font["weight"])};
  }`;
  return myFontFace;
}

function isEmpty(obj) {
  for(var key in obj) {
    if(obj.hasOwnProperty(key))
      return false;
  }
  return true;
}

function getOpts(el,srcobj) {
  var keys = ["_font","_fontSize","_fontWeight","_fontStyle","_textColor",
              "_textIndent","_lineHeight","_textAlign","_textAlignLast","_floatBottom",
              "_margins","_padding","_backgroundColor","_borderTop","_changeCase",
              "_borderRight","_borderBottom","_borderLeft","_borderRadius",
              "_width","_height","_OTfontVariant","_OTfontVariantCaps",
              "_OTfontVariantLigatures","_OTfontVariantNumeric","_OTmozFontFeatureSettings",
              "_OTwebkitFontFeatureSettings","_OTfontFeatureSettings","_stringSet","_subSelectors",
              "_before_content","_after_content","_underlineType","_verticalAlign","_positionTop",
              "_listStyleType","_preserveWS", "_display", "_letterSpacing", "_dropcap",
              "_dropcapFont","_dropcapSize","_dropcapWeight","_dropcapStyle","_dropcapPadding",
              "_dropcapLineHeight","_baselineShift","_avoidBreaks", "_page", "_pageStart", 
              "_folioStart","_beforeFontFamily","_beforeFontSize","_afterFontFamily",
              "_afterFontSize", "_backgroundColor", "_underlineColor", "_underlineStyle",
              "_center", "_openType", "_backgroundImage", "_backgroundRepeat", "_backgroundWidth", 
              "_backgroundHeight", "_backgroundPositionX", "_backgroundPositionY", "_allowHyphens",
              "_importance", "_footnoteMargin", "_float", "_position", "_outsideMargin", "_insideMargin",
              "_outsidePadding", "_insidePadding", "_marginExtent", "_columnCount", "_columnWidth",
              "_columnGap", "_columnDivider", "_columnDividerWidth", "_columnDividerColor",
              "_columnSpan"];
  var opts = {}
  keys.forEach(function(k) {
    if (srcobj.hasOwnProperty(el + k)) {
      opts[k] = srcobj[el + k];
    }
  });
  return opts;
}

function getOptions(selpath, mymatch, template_builder_advanced) {
  var opts = {};
  var newel = mymatch.replace(/-/g,"");
  if (
    selpath.match(/^ID_/g) || 
    selpath.match(/^PARENT_/g) || 
    selpath.match(/^CHILD_/g) || 
    selpath.match(/^PREV_/g) || 
    selpath.match(/^FIRSTOFTYPE_/g)
  ) {
    var mypar = mymatch;
    var selector = mypar.replace(/-/g,"") + "_subSelectors";
    if (template_builder_advanced[selector]) {
      var obj = template_builder_advanced[selector][selpath];
      if (obj) {
        opts = getOpts(selpath,obj);
      }
    }
  } else {
    opts = getOpts(newel,template_builder_advanced);
  }
  return opts;
}

function makeOTObj(arr) {
  var res = {};
  // create the ligatures property
  if (arr.indexOf("liga") > -1) {
    res["font-variant-ligatures"] = "common-ligatures";
  } else if (arr.indexOf("dlig") > -1) {
    res["font-variant-ligatures"] = "discretionary-ligatures";
  } else {
    res["font-variant-ligatures"] = "none";
  }
  // create the smallcaps property
  if (arr.indexOf("smcp") > -1) {
    res["font-variant-caps"] = "small-caps";
  } else if (arr.indexOf("c2sc") > -1) {
    res["font-variant-caps"] = "all-small-caps";
  } else {
    res["font-variant-caps"] = "normal";
  }
  // create the numerals property
  var fvn_arr = [];
  if (arr.indexOf("lnum") > -1) {
    fvn_arr.push("lining-nums");
  } 
  if (arr.indexOf("onum") > -1) {
    fvn_arr.push("oldstyle-nums");
  } 
  if (arr.indexOf("pnum") > -1) {
    fvn_arr.push("proportional-nums");
  } 
  if (arr.indexOf("tnum") > -1) {
    fvn_arr.push("tabular-nums");
  } 
  if (arr.indexOf("frac") > -1) {
    fvn_arr.push("diagonal-fractions");
  } 
  if (arr.indexOf("ordn") > -1) {
    fvn_arr.push("ordinal");
  } 
  if (fvn_arr.length === 0) {
    fvn_arr.push("normal");
  }
  res["font-variant-numeric"] = fvn_arr.join(" ");

  var index = arr.indexOf("normal");
  if (index > -1) {
    arr.splice(index, 1);
  }
  
  arr = arr.map(x => `'${x}'`)
  if (arr.indexOf("'liga'") > -1) {
    arr.push("'clig'");
    var lindex = arr.indexOf("'liga' 0");
    if (lindex > -1) {
      arr.splice(lindex, 1);
    }
    var cindex = arr.indexOf("'clig' 0");
    if (cindex > -1) {
      arr.splice(cindex, 1);
    }
  } else {
    arr.push("'clig' 0");
    arr.push("'liga' 0");
  }

  var joined = arr.join(",");

  res["-font-feature-settings"] = joined;
  res["-moz-font-feature-settings"] = joined;
  res["-webkit-font-feature-settings"] = joined;

  return res;
}

function makeOT(arr, importance="") {
  var res = "";
  var obj = makeOTObj(arr);
  for (var key in obj) {
    res = res + key + ": " + obj[key] + importance + ";\n";
  }
  res = res.replace(/\n$/,"");

  return res;
}

function getSectionStyles(myopts, template_builder_advanced) {
  var page, firstmargin, topmargin;
  page = myopts["_page"];
  if (page) {
    firstmargin = `master_${page}_first_marginTop`;
    topmargin = toPx(template_builder_advanced[firstmargin]);
  } else {
    topmargin = undefined;
  }
  return [topmargin];
}

function epubify(key, val, defaults, props, sel=undefined, rem="12px") {
  var em, comp;
  // if key is margin or padding,
  // 
  // if key is font size, get em value based on defaults,
  // ensuring it is not larger than 3em.
  if (key === "_fontSize" || key === "_dropcapSize") {
    // convert to px
    if (val !== "inherit" && val.indexOf("%") < 0) {
      val = toPx(val);
      comp = defaults["_fontSize"];
      if (key === "_dropcapSize") {
        if (defaults.hasOwnProperty("_compSize")) {
          comp = defaults["_compSize"];
        }
      }
      // convert to float and divide by default
      em = parseFloat(val.replace("px","")) / parseFloat(toPx(comp).replace("px",""));
      // compare to 3.
      if (em <= 3) {
        val = em.toFixed(2) + "em";
      } else {
        val = (((em / 3) / 10) * 2) + 3;
        val = val.toFixed(2) + "em";
      }
    }
  }

  if ((key === "_lineHeight" || key === "_dropcapLineHeight") && val.indexOf("%") < 0) {
    if (sel && sel.match(/hspa?n[a-zA-Z0-9-]+\]$/) && (val === "6pt" || val === "8px")) {
      val = "inherit";
    } else if (val !== "inherit") {
      comp = defaults["_fontSize"];
      // prob need to adjust this to account for
      // blks nested in wrappers, inheriting size settings.
      if (defaults.hasOwnProperty("_compSize")) {
        comp = defaults["_compSize"];
      }
      val = parseFloat(toPx(val).replace("px","")) / parseFloat(toPx(comp).replace("px",""));
      if (val < 0.8) {
        val = "80%";
      } else if (val > 3) {
        val = "300%";
      } else {
        val = val * 100;
        val = Math.ceil(val).toString() + "%";
      }
    } else {
      val = "normal";
    }
  }

  if (key === "_backgroundWidth" && val.indexOf("%") < 0) {
    if (!val.match(/^[a-zA-Z]/)) {
      // pagewidth - element margin-x
      var parw = parseFloat(toPx(defaults["pageWidth"]).replace("px",""));
      if (props.hasOwnProperty("_width") && props["_width"] != "auto") {
        parw = parseFloat(toPx(props["_width"]).replace("px",""));
      }
      if (props.hasOwnProperty("_margins") && props["_margins"].length > 0) {
        parw = parw - parseFloat(toPx(props["_margins"][1]).replace("px","")) - parseFloat(toPx(props["_margins"][3]).replace("px",""));
      }
      val = parseFloat(toPx(val).replace("px","")) / parw;
      val = val * 100;
      val = Math.ceil(val).toString() + "%";
    }
  }

  if (key === "_backgroundHeight" && val.indexOf("%") < 0) {
    if (!val.match(/^[a-zA-Z]/)) {
      // element padding-y + element-lineheight or fontsize or element_height (whichever is greater)
      var comp = defaults["_fontSize"];
      var compLH = defaults["_lineHeight"];
      if (props.hasOwnProperty("_height") && props["_height"] != "auto") {
        comp = props["_height"];
      } else if (defaults.hasOwnProperty("_compSize")) {
        comp = defaults["_compSize"];
      }
      if (defaults.hasOwnProperty("_compLineHeight")) {
        compLH = defaults["_compLineHeight"];
      }
      var parh = Math.max(parseFloat(toPx(compLH).replace("px","")), parseFloat(toPx(comp).replace("px","")));
      if (props.hasOwnProperty("_padding") && props["_padding"].length > 0) {
        parh = parh + parseFloat(toPx(props["_padding"][0]).replace("px","")) + parseFloat(toPx(props["_padding"][2]).replace("px",""));
      }
      
      val = parseFloat(toPx(val).replace("px","")) / parh;
      val = val * 100;
      val = Math.ceil(val).toString() + "%";
    }
  }

  if (key === "_textIndent" && val.indexOf("%") < 0) {
    if (!val.match(/^[a-zA-Z]/)) {
      val = parseFloat(toPx(val).replace("px","")) / parseFloat(toPx(defaults["pageWidth"]).replace("px",""));
      val = val * 100;
      val = Math.ceil(val).toString() + "%";
    }
  }

  if ((key === "_margins" || key === "_padding") && val.indexOf("%") < 0) {
    var unit = "%";
    if (sel && sel.match(/hwprtd/)) {
      unit = "rem";
    }
    if (val.match(/\S\s\S/g)) {
      val = val.split(" ");
      val.forEach(function(item, index) {
        if (item !== "auto") {
          if (index === 0 || index === 2) {
            val[index] = parseFloat(toPx(val[index]).replace("px","")) / parseFloat(rem.replace("px",""));
            val[index] = Math.ceil(val[index]);
            if (val[index] > 8) {
              val[index] = 8;
            }
            unit = "rem";
            val[index] = val[index].toString() + unit;
          } else {
            val[index] = parseFloat(toPx(val[index]).replace("px","")) / parseFloat(toPx(defaults["pageWidth"]).replace("px",""));
            unit = "%";
            if (sel && sel.match(/hwprtd/)) {
              unit = "rem";
            }
            val[index] = val[index] * 100;
            if (val[index] <= 30) {
              if (val[index] <= 0) {
                val[index] = 0;
                unit = "";
              } else {
                if (unit === "rem") {
                  val[index] = Math.ceil(val[index]/100).toString() + unit;
                } else {
                  val[index] = Math.ceil(val[index]).toString() + unit;
                }
              }
            } else {
              if (unit === "rem") {
                val[index] = "3" + unit;
              } else {
                val[index] = "30" + unit;
              }
            }
          }
        }
      });
      val = val.join(" ");
    } else {
      if (val.match(/[tT]op/g) || val.match(/[bB]ottom/g)) {
        val = parseFloat(toPx(val).replace("px","")) / parseFloat(rem.replace("px",""));
        val = Math.ceil(val);
        if (val > 8) {
          val = 8;
        }
        unit = "rem";
        val[index] = val[index].toString() + unit;
      } else {
        val = parseFloat(toPx(val).replace("px","")) / parseFloat(toPx(defaults["pageWidth"]).replace("px",""));
        unit = "%";
        val = val * 100;
        if (val <= 30) {
          if (val <= 0) {
            val[index] = 0;
            unit = "";
          }
          val = Math.ceil(val).toString() + unit;
        } else {
          val = "30" + unit;
        }
      }
    }
  }

  if (key === "_allowHyphens") {
    val = "auto";
  }

  if (key === "_width" && val.indexOf("%") < 0) {
    if (!val.match(/^[a-zA-Z]/)) {
      // pagewidth - element margin-x
      var parw = parseFloat(toPx(defaults["pageWidth"]).replace("px",""));
      val = parseFloat(toPx(val).replace("px","")) / parw;
      if (val < 1) {
        val = val * 100;
        val = Math.ceil(val).toString() + "%";
      } else {
        val = "100%";
      }
    }
  }
  return val;
}

function getProps() {
  var props = {"_font":["font-family","font","none",true],
              "_fontSize":["font-size","str","none",true],
              "_fontWeight":["font-weight","FW","none",true],
              "_fontStyle":["font-style","str","none",true],
              "_changeCase":["text-transform","str","none",true],
              "_textColor":["color","str","none",true],
              "_textIndent":["text-indent","str","none",true],
              "_lineHeight":["line-height","str","toPx",true],
              "_letterSpacing":["letter-spacing","str","none",true],
              "_textAlign":["text-align","str","none",true],
              "_textAlignLast":["text-align-last","str","none",true],
              "_floatBottom":["float","str","none",true],
              "_allowHyphens": ["hyphens","str","none",true],
              "_preserveWS": ["white-space","str","none",true],
              "_backgroundColor": ["background-color","str","none",true],
              "_backgroundImage": ["background-image","img","none",true],
              "_backgroundRepeat": ["background-repeat","str","none",true],
              "_backgroundWidth": ["background-size","str","none",true],
              "_backgroundHeight": ["background-size","str","none",true],
              "_backgroundPositionX": ["background-position-x","str","none",true],
              "_backgroundPositionY": ["background-position-y","str","none",true],
              "_margins":["margin","join","toPx",true],
              "_outsideMargin":["margin-left","str","toPx",false],
              "_insideMargin":["margin-right","str","toPx",false],
              "_padding":["padding","join","toPx",true],
              "_outsidePadding":["padding-left","str","toPx",false],
              "_insidePadding":["padding-right","str","toPx",false],
              "_spaceBelow":["padding-bottom","str","toPx",true],
              "_spaceAbove":["padding-top","str","toPx",true],
              "_underlineType":["text-decoration-line","str","none",true],
              "_underlineStyle":["text-decoration-style","str","none",true],
              "_underlineColor":["text-decoration-color","str","none",true],
              "_borderTop":["border-top","join","none",true],
              "_borderRight":["border-right","join","none",true],
              "_borderBottom":["border-bottom","join","none",true],
              "_borderLeft":["border-left","join","none",true],
              "_borderRadius":["border-radius","join","none",true],
              "_avoidBreaks":["page-break","str","none",true],
              "_width":["width","str","none",true],
              "_height":["height","str","toPx",true],
              "_OTfontVariant":["font-variant","str","none",true],
              "_OTfontFeatureSettings":["font-feature-settings","str","none",true],
              "_underline":["text-decoration","str","none",true],
              "_listStyleType":["list-style-type","str","none",true],
              "_display":["display","str","none",true],
              "_position":["position","str","none",false],
              "_marginExtent":["right","str","toPx",false],
              "_float":["float","str","none",true],
              "_content":["content","getContent","none",true],
              "_before_content":["content","getContent","none",true],
              "_beforeFontFamily":["font-family","font","none",true],
              "_beforeFontSize":["font-size","str","none",true],
              "_after_content":["content","getContent","none",true],
              "_afterFontFamily":["font-family","font","none",true],
              "_afterFontSize":["font-size","str","none",true],
              "_dropcap":["none","none","none",true],
              "_dropcapFont":["font-family","font","none",true],
              "_dropcapSize":["font-size","str","none",true],
              "_dropcapWeight":["font-weight","FW","none",true],
              "_dropcapStyle":["font-style","str","none",true],
              "_dropcapPadding":["padding","join","toPx",true],
              "_dropcapLineHeight":["line-height","str","toPx",true],
              "_baselineShift":["top","str","none",true],
              "_verticalAlign":["vertical-align","str","none",true],
              "_center":["none","none","none",true],
              "_openType":["font-feature-settings","none","none",false],
              "_footnoteMargin":["margin-top","none","none",false],
              "_columnCount":["column-count","str","none",false],
              "_columnWidth":["column-width","str","toPx",false],
              "_columnGap":["column-gap","str","toPx",false], 
              "_columnDivider":["column-rule-style","str","none",false], 
              "_columnDividerWidth":["column-rule-width","str","toPx",false], 
              "_columnDividerColor":["column-rule-color","str","none",false],
              "_columnSpan":["column-span","str","none",false]
            };
  return props;
}

function validateVal(val, el) {
  try {
    if (el === "_padding") {
      if (Array.isArray(val)) {
        val.forEach(function(item, index) {
          val[index] = val[index].replace("auto","0px")
        });
      } else {
        val.replace("auto","0px")
      }
    }
  } catch (error) {
    console.error(error);
  }
  return val;
}

function processVal(val, el, props, defaults, ALL_FONTS_ARR, epub=false) {
  if (Array.isArray(val)) {
    val.forEach(function(item, index) {
      if (val[index] && val[index].match(/^\d+null/g)) {
        val[index] = val[index].replace("null", "") + "pt";
      }
    });
  } else if (typeof val === 'string' && val.match(/^\d+null/g)) {
    val = val.replace("null", "") + "pt";
  }
  if (props[el] && props[el][2] === "toPx") {
    val = toPx(val);
  }
  if (props[el] && props[el][1] === "img" && val != "none") {
    val = `url(${val})`;
  }
  if (props[el] && props[el][1] === "font") {
    val = FL(val, ALL_FONTS_ARR)[1];
  }
  if (props[el] && props[el][1] === "FW") {
    if (el.indexOf("dropcap") > -1) {
      val = parseFontWeight(val,defaults["dropcapFont"],ALL_FONTS_ARR);
    } else {
      val = parseFontWeight(val,defaults["bodyFont"],ALL_FONTS_ARR);
    }
  }
  if (props[el] && props[el][1] === "join") {
    val = val.join(' ');
  }
  if (props[el] && props[el][1] === "getContent") {
    val = getContent(val);
  }
  return val;
}

function getDefaults(el, myopts, defaults) {
  try {
    if (el === "_lineHeight" || el === "_dropcapSize" || el === "_backgroundHeight") {
      // get the fontsize as well
      if (myopts.hasOwnProperty("_fontSize") && myopts["_fontSize"] != "inherit") {
        defaults["_compSize"] = myopts["_fontSize"];
      }
      if (myopts.hasOwnProperty("_lineHeight") && myopts["_lineHeight"] != "inherit") {
        defaults["_compLineHeight"] = myopts["_lineHeight"];
      }
    } else if (el === "_dropcapLineHeight") {
      // get the fontsize as well
      if (myopts.hasOwnProperty("_dropcapSize") && myopts["_dropcapSize"] != "inherit") {
        defaults["_compSize"] = myopts["_dropcapSize"];
      }
    }
  } catch (error) {
    console.error(error);
  }
  return defaults;
}

function makeCSS(myopts,sel,ALL_FONTS_ARR,subsel=false,epub=false,defaults={}) {
  var props = getProps();

  var importance,
      cssstring,
      cssobj,
      beforecontent,
      aftercontent,
      splitcontent,
      val,
      dropcap,
      dropcapstring,
      pagedcssstring,
      epubcssstring,
      margincssstring;

  importance = "";
  cssstring = "";
  cssobj = {};
  pagedcssstring = "";
  beforecontent = "";
  aftercontent = "";
  splitcontent = "";
  epubcssstring = "";
  margincssstring = "";
  dropcap = false;

  if (myopts.hasOwnProperty("_importance") && myopts["_importance"] == "true") {
    importance = " !important";
  }

  var bg_size_string = `background-size: WIDTH HEIGHT${importance};`;
  var bg_pos_string = `background-position: POSX POSY${importance};`;

  for (var el in props) {
    if (props.hasOwnProperty(el) && myopts.hasOwnProperty(el)) {
      val = myopts[el];
      let should_process = epub === false || (epub === true && props[el][3] == true);

      if (val && should_process) {
        val = validateVal(val, el)

        val = processVal(val, el, props, defaults, ALL_FONTS_ARR, epub);

        // first, we'll parse the epub value
        if (epub === true) {
          if (
            val !== false && 
            props[el][1] != "font" && 
            props[el][1] != "FW"
          ) {
            val = toPx(val);
          }
          defaults = getDefaults(el, myopts, defaults);
          val = epubify(el, val, defaults, myopts, sel);
          delete defaults["_compSize"];
        }

        // then we'll process each item
        if (el === "_avoidBreaks") {
          if (val !== "auto") {
            pagedcssstring = pagedcssstring + `page-break-${val}: avoid${importance};\n`;
          }
        } else if (el === "_font") {
          pagedcssstring = `${pagedcssstring} 
          ${props[el][0]}:${val}${importance};`;
          epubcssstring = `${epubcssstring} 
          ${props[el][0]}:${val}${importance};`;
          // add to the object
          cssobj[props[el][0]] = val;
        } else if (el.match("_center$")) {
          if (val === "true") {
            cssstring = cssstring + `margin-left:auto${importance};\nmargin-right:auto${importance};\n`;
          }
        } else if (el === "_after_content") {
          aftercontent = `${sel}::after {
            content: ${val}${importance};
          }`;
          if (!epub) {
            aftercontent = aftercontent + `
            ${sel}.baked::after {
              content: normal${importance};
            }
            ${sel} span.line:first-of-type::after {
              content: ${val}${importance};
            }`;
          }
        } else if (el === "_afterFontFamily") {
          aftercontent = `${aftercontent}
          ${sel}::after {
            ${props[el][0]}: ${val}${importance};
          }`;
        } else if (el === "_afterFontSize") {
          aftercontent = `${aftercontent}
          ${sel}::after {
            ${props[el][0]}: ${val}${importance};
          }`;
        } else if (el === "_before_content") {
          beforecontent = `${sel}::before {
            content: ${val}${importance};
          }`;
          if (!epub) {
            beforecontent = beforecontent + `
            ${sel}.baked::before {
              content: normal${importance};
            }
            ${sel} span.line:first-of-type::before {
              content: ${val}${importance};
            }`;
          }
          
        } else if (el === "_beforeFontFamily") {
          beforecontent = `${beforecontent}
          ${sel}::before {
            ${props[el][0]}: ${val}${importance};
          }`;
        } else if (el === "_beforeFontSize") {
          beforecontent = `${beforecontent}
          ${sel}::before {
            ${props[el][0]}: ${val}${importance};
          }`;
        } else if (el === "_dropcap") {
          if (val === "true") {
            dropcap = true;
            dropcapstring = `${sel}::first-letter {\n`;
          } else {
            dropcap = false;
            if (subsel === true) {
              dropcapstring = `${sel}::first-letter {
                float:none${importance};
                font-family: inherit${importance};
                font-size: inherit${importance};
                font-weight: inherit${importance};
                font-style: inherit${importance};
                line-height: inherit${importance};
                padding: 0${importance};
              }`;
            }
          }
        } else if (el.indexOf("dropcap") > -1) {
          if (dropcap) {
            // dropcaps become raised caps in EPUB
            if (epub) {
              if (el != "_dropcapLineHeight" && el != "_dropcapPadding") {
                dropcapstring = `${dropcapstring}
                  ${props[el][0]}: ${val}${importance};`;
              }
            } else {
              dropcapstring = `${dropcapstring}
                ${props[el][0]}: ${val}${importance};`;
            }
          }
        } else if (el === "_floatBottom") {
          if (val === "bottom") {
            pagedcssstring = pagedcssstring + `float: bottom${importance};\n`;
          }
        } else if (el === "_OTmozFontFeatureSettings" || el === "_OTwebkitFontFeatureSettings") {
          pagedcssstring = pagedcssstring + `${props[el][0]}:${val}${importance};\n`;
          // add to the object
          cssobj[props[el][0]] = val;
        } else if (el.match("_underline")) {
          if (el.match("_underlineType")) {
            cssstring = cssstring + `text-decoration:${val}${importance};\n`;
          }
          cssstring = cssstring + `${props[el][0]}:${val}${importance};\n`;
          // add to the object
          cssobj[props[el][0]] = val;
          if (epub !== true) {
            cssstring = cssstring + `-webkit-${props[el][0]}:${val}${importance};\n`;
          }
        } else if (el === "_backgroundWidth") {
          bg_size_string = bg_size_string.replace(/WIDTH/, val);
        } else if (el === "_backgroundHeight") {
          bg_size_string = bg_size_string.replace(/HEIGHT/, val);
        } else if (el === "_backgroundPositionX") {
          bg_pos_string = bg_pos_string.replace(/POSX/, val);
        } else if (el === "_backgroundPositionY") {
          bg_pos_string = bg_pos_string.replace(/POSY/, val);
        } else if (el === "_outsideMargin") {
          if (should_process && !val.match("^0[a-z]")) {
            margincssstring = margincssstring + `  div.pagedjs_page.pagedjs_right_page ${sel} {
              margin-right: ${val};
            }
            div.pagedjs_page.pagedjs_left_page ${sel} {
              margin-left: ${val};
            }`;
          }
        } else if (el === "_outsidePadding") {
          if (!val.match("^0[a-z]")) {
            margincssstring = margincssstring + `  div.pagedjs_page.pagedjs_right_page ${sel} {
              padding-right: ${val};
            }
            div.pagedjs_page.pagedjs_left_page ${sel} {
              padding-left: ${val};
            }`;
          }
        } else if (el === "_insideMargin") {
          if (!val.match("^0[a-z]")) {
            margincssstring = margincssstring + `  div.pagedjs_page.pagedjs_right_page ${sel} {
              margin-left: ${val};
            }
            div.pagedjs_page.pagedjs_left_page ${sel} {
              margin-right: ${val};
            }`;
          }
        } else if (el === "_insidePadding") {
            if (!val.match("^0[a-z]")) {
            margincssstring = margincssstring + `  div.pagedjs_page.pagedjs_right_page ${sel} {
              padding-left: ${val};
            }
            div.pagedjs_page.pagedjs_left_page ${sel} {
              padding-right: ${val};
            }`;
          }
        } else if (el === "_position") {
          if (val === "outside") {
            margincssstring = margincssstring + `  div.pagedjs_page.pagedjs_right_page ${sel} {
              position: absolute;
            }
            div.pagedjs_page.pagedjs_left_page ${sel} {
              position: absolute;
            }`;
          } else if (val === "inside") {
            margincssstring = margincssstring + `  div.pagedjs_page.pagedjs_right_page ${sel} {
              position: absolute;
            }
            div.pagedjs_page.pagedjs_left_page ${sel} {
              position: absolute;
            }`;
          } else {
            cssstring = cssstring + `${props[el][0]}:${val}${importance};\n`;
            // add to the object
            cssobj[props[el][0]] = val;
          }
        } else if (el === "_marginExtent") {
          if (myopts["_position"] === "outside") {
            margincssstring = margincssstring + `  div.pagedjs_page.pagedjs_right_page ${sel} {
              right: -${val};
            }
            div.pagedjs_page.pagedjs_left_page ${sel} {
              left: -${val};
            }`;
          } else if (myopts["_position"] === "inside") {
            margincssstring = margincssstring + `  div.pagedjs_page.pagedjs_right_page ${sel} {
              left: -${val};
            }
            div.pagedjs_page.pagedjs_left_page ${sel} {
              right: -${val};
            }`;
          }
        } else {
          if (!epub || (epub && props[el][3])) {
            if (el === "_openType") {
              cssstring = cssstring + makeOT(val, importance);
            } else {
              cssstring = cssstring + `${props[el][0]}:${val}${importance};\n`;
              // add to the object
              cssobj[props[el][0]] = val;
            }
            if (el === "_textAlign") {
              splitcontent = `${sel}[data-split-to] {
                text-align-last: ${val}${importance};
              }
              ${sel}.continued {
                text-align-last: ${val}${importance};
              }`;
            }
          }
        }
      }
    }
  }

  if (myopts.hasOwnProperty("_backgroundImage") && myopts["_backgroundImage"] != "none") {
    if (myopts.hasOwnProperty("_backgroundHeight") || myopts.hasOwnProperty("_backgroundWidth")) {
      bg_size_string = bg_size_string.replace(/WIDTH/,"auto").replace(/HEIGHT/,"auto");
      cssstring = cssstring + "\n" + bg_size_string;
    }
    if (myopts.hasOwnProperty("_backgroundPositionX") || myopts.hasOwnProperty("_backgroundPositionY")) {
      bg_pos_string = bg_pos_string.replace(/POSX/,"left").replace(/POSY/,"top");
      cssstring = cssstring + "\n" + bg_pos_string;
    }
  }

  if (dropcap === true) {
    if (epub === false) {
      dropcapstring = dropcapstring + `\nfloat:left${importance};\n}\n`;
    } else {
      dropcapstring = dropcapstring + "\n}\n";
    }
  }

  return [cssstring,beforecontent,aftercontent,splitcontent,dropcapstring,pagedcssstring,epubcssstring, cssobj, margincssstring];
}

function makeKindleCSS(myopts,sel,ALL_FONTS_ARR,epub=false,defaults={},kindle=false) {
  var kindleaftercontent = "";
  var position = "after";
  try {
    var props = getProps();
    // add after-content padding for kindle
    if (
      kindle && 
      myopts.hasOwnProperty("_backgroundImage") && 
      myopts["_backgroundImage"] != "none" && 
      myopts.hasOwnProperty("_padding")
    ) {
      var after_width = undefined;
      var processed_pad = processVal(myopts["_padding"], "_padding", props, defaults, ALL_FONTS_ARR, epub);
      var after_pad = epubify("_padding", processed_pad, defaults, myopts, undefined);

      // get the ratio of the original total height vs. total width,
      // then multiply the final height by that ratio
      defaults = getDefaults("_backgroundHeight", myopts, defaults);
      if (!defaults.hasOwnProperty("_compSize")) {
        defaults["_compSize"] = defaults["_fontSize"];
      }
      var ratio_h = parseFloat(toPx(myopts["_padding"][0]).replace("px","")) + parseFloat(toPx(myopts["_padding"][2]).replace("px","")) + parseFloat(toPx(defaults["_compSize"]).replace("px",""));
      var ratio_w = undefined;
      var ratio = 1;

      if (
        myopts.hasOwnProperty("_backgroundPositionX") && 
        myopts["_backgroundPositionX"] === "right" && 
        after_pad.match(/^\S+\s\S+/)
      ) {
        after_width = after_pad.replace(/(^\S+\s)(\S+)(\s\S+\s\S+$)/,"$2");
        ratio_w = parseFloat(toPx(myopts["_padding"][1]).replace("px",""));
      } else if (
        myopts.hasOwnProperty("_backgroundPositionX") && 
        (
          myopts["_backgroundPositionX"] === "left" || 
          (myopts["_backgroundPositionX"] !== "center" && !myopts["_backgroundPositionX"].match(/\d/))
        ) && 
        after_pad.match(/^\S+\s\S+\s\S+\s\S+/)
      ) {
        position = "before";
        after_width = after_pad.replace(/(^\S+\s\S+\s\S+\s)(\S+$)/,"$2");
        ratio_w = parseFloat(toPx(myopts["_padding"][3]).replace("px",""));
      }

      if (after_width) {
        // adjust per original ratio
        if (ratio_w) {
          ratio = ratio_w / ratio_h;
        }
        var final_width = ratio * parseFloat(toPx(defaults["_compSize"]).replace("px",""));

        kindleaftercontent = `@media not amzn-mobi {
          ${sel}::${position} {
            content: " ";
            width: ${final_width + "px"};
            height: 100%;
            display: inline-block;
          }
        }
        @media not amzn-kf8 {
          ${sel}::${position} {
            content: " ";
            width: ${final_width + "px"};
            height: 100%;
            display: inline-block;
          }
        }`;
      }
    }
  } catch (error) {
    console.error(error);
  }
  return kindleaftercontent;
}

function generateSubselStyles(contentType, subsel_styles) {
  if (contentType && contentType.match(/\S/)) {
    subsel_styles = subsel_styles + `
      ${contentType}
    `;
  }
  return subsel_styles;
}

function cleanupCssString(css_string) {
  css_string = css_string.replace(/\s\s+/g," ");
  css_string = css_string.replace(/\n+/g,"");
  return css_string;
}

function generateCSS(template_builder_advanced, typelist, ALL_FONTS_ARR, epub=false, kindle=false, advanced_config_version=0) {
  var topmargin, fontimports, fontFaceDeclarations, allurls, makeDeclarations;
  // get the em vals for epub purposes
  var defaults = {};
  var sup = {};
  var config = JSON.parse(JSON.stringify(template_builder_advanced));

  defaults["bodyFont"] = config.body_bodyFont;

  if (epub === true || kindle === true) {
    if (typelist.indexOf("hblkp") > -1) {
      defaults = getOptions("hblkp", "hblkp", config);
      if (
        (defaults.hasOwnProperty("_fontSize") && defaults["_fontSize"] === "inherit") || 
        !defaults.hasOwnProperty("_fontSize")
      ) {
        defaults["_fontSize"] = config.body_bodySize;
      }
    } else {
      defaults["_fontSize"] = config.body_bodySize;
    }
    defaults["pageWidth"] = config.page_width;
    defaults["pageHeight"] = config.page_height;
    defaults["_lineHeight"] = config.body_lineHeight;
    config.body_bodySize = "1em";
    config.body_lineHeight = epubify("_lineHeight", config.body_lineHeight, defaults, undefined);
  }

  [allurls,makeDeclarations] = parseFont(config,[],[],ALL_FONTS_ARR);
  [fontimports, fontFaceDeclarations] = getFontImports(config, ALL_FONTS_ARR, allurls, makeDeclarations);
  var fontFaceStr = fontFaceDeclarations.join("\n");
  var initialcss_paged = makeInitalCSSPaged(fontimports, fontFaceStr, config, ALL_FONTS_ARR, advanced_config_version);

  var pagedoverrides = makePagedOverrides(config, ALL_FONTS_ARR);
  var pagedAndEpubCss = "";
  var foliooverrides = "";
  var uioverrides = "";
  if (!advanced_config_version || parseFloat(advanced_config_version) < 1) {
    console.log("Overwriting legacy folio content");
    foliooverrides = makePagedFolioOverrides(config, ALL_FONTS_ARR);
  }
  var initialcss = makeInitialCSS(config, ALL_FONTS_ARR);
  var epubcss = makeEPUBCSS(config, ALL_FONTS_ARR);
  var overrides = makePrintOverrideCSS();

  // parse the margin areas
  var master_obj = {};
  for (var k in config) {
    if (k.match(/_margin(Top|Bottom|Left|Right)[A-Z]+/)) {
      if (!k.match(/folioStyle$/)) {
        var this_master = k.split("_")[1];
        if (!master_obj.hasOwnProperty(this_master)) {
          master_obj[this_master] = {};
        }
        var page = k.split("_")[2];
        if (!master_obj[this_master].hasOwnProperty(page)) {
          master_obj[this_master][page] = {};
        }
        var area = k.split("_")[3].replace(/^margin/g,"").replace(/([a-z])([A-Z])/g,"$1-$2").toLowerCase();
        if (!master_obj[this_master][page].hasOwnProperty(area)) {
          master_obj[this_master][page][area] = {};
        }
        var props = getProps();
        var el = "_" + k.split("_")[4];
        var val = config[k];
        val = processVal(val, el, props, defaults, ALL_FONTS_ARR);
        // if its a content field, check for page num and process accordingly
        var folioStyle = ["master", this_master, page, k.split("_")[3], "folioStyle"].join("_");
        if (k.match(/_content$/) && val.match(/counter\(page\)/) && config[folioStyle] != "decimal") {
          val = val.replace(/counter\(page\)/,"counter(page," + config[folioStyle] + ")");
        }
        var cssel = props[el][0];
        if (el === "_openType") {
          var ot_options = makeOTObj(val);
          for (var k in ot_options) {
            master_obj[this_master][page][area][k] = ot_options[k];
          }
        } else {
          master_obj[this_master][page][area][cssel] = val;
        }
      }
    }
    var masters = ["chapter","part","frontmatter","clear","backmatter"];
    var pages = ["recto","verso","first","blank"];
    var default_margins = [
      "top-left-corner",
      "top-left",
      "top-center",
      "top-right",
      "top-right-corner",
      "bottom-left-corner",
      "bottom-left",
      "bottom-center",
      "bottom-right",
      "bottom-right-corner",
      "left-top",
      "left-middle",
      "left-bottom",
      "right-top",
      "right-middle",
      "right-bottom"
    ];
    // fill in any missing defs with the old defaults
    masters.forEach(function(m) {
      if (!master_obj.hasOwnProperty(m)) {
        master_obj[m] = {};
      }
      pages.forEach(function(p) {
        if (!master_obj[m].hasOwnProperty(p)) {
          master_obj[m][p] = {};
        }
        default_margins.forEach(function(a) {
          if (!master_obj[m][p].hasOwnProperty(a)) {
            master_obj[m][p][a] = generateMarginDefaults(config, m, p, a, ALL_FONTS_ARR);
          }
        });
      });
    });
  }
  // Now compile the object into CSS.
  for (var m in master_obj) {
    var corners = [];
    for (var p in master_obj[m]) {
      var myp = p;
      if (p === "verso") {
        myp = "left";
      }
      if (p === "recto") {
        myp = "right";
      }
      initialcss_paged = initialcss_paged + `
  @page ${m}:${myp} {`;
      for (var a in master_obj[m][p]) {
        if (Object.keys(master_obj[m][p][a]).length != 0 && master_obj[m][p][a].constructor === Object) {
          let marginlabel = a;
          if (a.match(/-corner$/)) {
            corners.push([myp, a]);
          }
          initialcss_paged = initialcss_paged + `
      @${marginlabel} {`;
          for (var k in master_obj[m][p][a]) {
            initialcss_paged = initialcss_paged + `
        ${k}:${master_obj[m][p][a][k]};`;
          };
          initialcss_paged = initialcss_paged + `}`;
        };
      };
      initialcss_paged = initialcss_paged + `}`;
    };
    corners.forEach(function (item) {
      initialcss_paged = initialcss_paged + `
      .pagedjs_page.pagedjs_named_page.pagedjs_${m}_page.pagedjs_${item[0]}_page .pagedjs_margin-${item[1]} {
        width: 100%;
      }`;
    });
  };

  let id_subsels = [];
  let prev_subsels = [];
  let parent_subsels = [];
  let child_subsels = [];
  let first_subsels = [];
  let pagedAndEpubCssArr = [];
  let epubArr = [];
  let initialcssArr = [];
  let initialcss_pagedArr = [];
  let paged_id_overrides = [];

  typelist.forEach(function(el) {
    try {
      var include = true;
      // making selectors for each element type
      var subsel = false;
      var idsel = false;
      var sel = "[data-hederis-type=" + el + "]";
      var mymatch = el;
      if (el.match(/^ID_/g)) {
        mymatch = el.match(/h[a-zA-Z0-9-]+$/)[0];
        var idid = el.match(/(^ID_)(\S+)(_h[a-zA-Z0-9-]+$)/)[2];
        sel = "[data-hederis-type=" + mymatch + "][data-id=" + idid + "]";
        if (idid != "false") {
          idsel = `#${idid}`;
        }
        subsel = true;
      } else if (el.match(/^PARENT_/g)) {
        mymatch = el.match(/h[a-zA-Z0-9-]+$/)[0];
        var parenttype = el.match(/(^PARENT_)(\S+)(_h[a-zA-Z0-9-]+$)/)[2];
        if (epub === true && (el.match(/^PARENT_hwprbottomarea_/g) || el.match(/^PARENT_hwprnotesarea_/g))) {
          sel = "*[data-hederis-type=" + mymatch + "][data-ui-moved=true]";
        } else {
          sel = "*[data-hederis-type=" + parenttype + "] *[data-hederis-type=" + mymatch + "]";
        }
        subsel = true;
      } else if (el.match(/^CHILD_/g)) {
        mymatch = el.match(/h[a-zA-Z0-9-]+$/)[0];
        var parenttype = el.match(/(^CHILD_)(\S+)(_h[a-zA-Z0-9-]+$)/)[2];
        if (epub === true && (el.match(/^CHILD_hwprbottomarea_/g) || el.match(/^CHILD_hwprnotesarea_/g))) {
          sel = "*[data-hederis-type=" + mymatch + "][data-ui-moved=true]";
        } else {
          sel = "*[data-hederis-type=" + parenttype + "] > *[data-hederis-type=" + mymatch + "]";
        }
        subsel = true;
      } else if (el.match(/^PREV_/g)) {
        mymatch = el.match(/h[a-zA-Z0-9-]+$/)[0];
        var prevtype = el.match(/(^PREV_)(\S+)(_h[a-zA-Z0-9-]+$)/)[2];
        sel = "*[data-hederis-type=" + prevtype + "] + *[data-hederis-type=" + mymatch + "]";
        subsel = true;
      } else if (el.match(/^FIRSTOFTYPE_/g)) {
        mymatch = el.match(/h[a-zA-Z0-9-]+$/)[0];
        var parenttype = el.match(/(^FIRSTOFTYPE_)(\S+)(_h[a-zA-Z0-9-]+$)/)[2];
        sel = "*[data-hederis-type=" + parenttype + "] > *[data-hederis-type=" + mymatch + "]:nth-of-type(1)";
        subsel = true;
        if (kindle) {
          include = false;
        }
      }

      if (subsel) {
        uioverrides = uioverrides + `${sel} {
          background-color: rgba(255, 165, 0, 0.4) !important;
        }
        `;
      }

      if (include) {
        var myopts = getOptions(el, mymatch, config);

        if (myopts.hasOwnProperty("_font") && myopts["_font"] != "inherit") {
          defaults["bodyFont"] = myopts["_font"];
        } else if (subsel === true && config[mymatch+"_font"] != "inherit") {
          defaults["bodyFont"] = config[mymatch+"_font"];
        } else {
          defaults["bodyFont"] = config.body_bodyFont;
        }

        if (myopts.hasOwnProperty("_dropcapFont") && myopts["_dropcapFont"] != "inherit") {
          defaults["dropcapFont"] = myopts["_dropcapFont"];
        } else if (myopts.hasOwnProperty("_font") && myopts["_font"] != "inherit") {
          defaults["dropcapFont"] = myopts["_font"];
        } else if (subsel === true && config[mymatch+"_dropcapFont"] != "inherit") {
          defaults["dropcapFont"] = config[mymatch+"_dropcapFont"];
        } else if (subsel === true && config[mymatch+"_font"] != "inherit") {
          defaults["dropcapFont"] = myopts["_font"];
        } else {
          defaults["dropcapFont"] = config.body_bodyFont;
        }

        if (mymatch.match(/^hsec/g)) {
          initialcss_paged = initialcss_paged + `  ${sel} {
            page: ${myopts["_page"] ? myopts["_page"] : "chapter"};
            page-break-before: ${myopts["_pageStart"] ? myopts["_pageStart"] : "right"};
            padding: ${myopts["_padding"] ? toPx(myopts["_padding"]).join(" ") : "0 0 0 0"};
            margin: ${myopts["_margin"] ? toPx(myopts["_margin"]).join(" ") : "0 0 0 0"};
          }`;
          if (myopts["_folioStart"] && myopts["_folioStart"] != "none") {
            // patch for old folio start values
            let folio = myopts["_folioStart"];
            if (folio == "page 0") {
              folio = "page 1";
            }
            initialcss_paged = initialcss_paged + `  ${sel} > *:first-child {
              counter-reset: ${folio};
            }`;
          }
          if (myopts["_outsideMargin"] && myopts["_outsideMargin"] != "0pt") {
            initialcss_paged = initialcss_paged + `  div.pagedjs_page.pagedjs_right_page ${sel} {
              margin-right: ${toPx(myopts["_outsideMargin"])};
            }
            div.pagedjs_page.pagedjs_left_page ${sel} {
              margin-left: ${toPx(myopts["_outsideMargin"])};
            }`;
          }
          if (myopts["_insideMargin"] && myopts["_insideMargin"] != "0pt") {
            initialcss_paged = initialcss_paged + `  div.pagedjs_page.pagedjs_right_page ${sel} {
              margin-left: ${toPx(myopts["_insideMargin"])};
            }
            div.pagedjs_page.pagedjs_left_page ${sel} {
              margin-right: ${toPx(myopts["_insideMargin"])};
            }`;
          }
          if (myopts["_outsidePadding"] && myopts["_outsidePadding"] != "0pt") {
            initialcss_paged = initialcss_paged + `  div.pagedjs_page.pagedjs_right_page ${sel} {
              padding-right: ${toPx(myopts["_outsidePadding"])};
            }
            div.pagedjs_page.pagedjs_left_page ${sel} {
              padding-left: ${toPx(myopts["_outsidePadding"])};
            }`;
          }
          if (myopts["_insidePadding"] && myopts["_insidePadding"] != "0pt") {
            initialcss_paged = initialcss_paged + `  div.pagedjs_page.pagedjs_right_page ${sel} {
              padding-left: ${toPx(myopts["_insidePadding"])};
            }
            div.pagedjs_page.pagedjs_left_page ${sel} {
              padding-right: ${toPx(myopts["_insidePadding"])};
            }`;
          }
          // string-set: authorname attr(data-author-name), booktitle attr(data-book-title), chaptertitle attr(title);
          [topmargin] = getSectionStyles(myopts, config);
          if (topmargin && epub === true) {
            topmargin = epubify("_margins",topmargin,defaults,myopts,sel);
            epubcss = epubcss + `
            ${sel} {
              margin-top: ${topmargin};
            }
            `;
          }
          // we have to leave out the attr string-sets for now bc paged.js doesn't seem to support them
        } else if (mymatch.match(/^hwpr/g) || mymatch.match(/^hblk/g)) {
          if (!isEmpty(myopts)) {
            if (myopts["_stringSet"] && myopts["_stringSet"] != "none") {
              initialcss_paged = initialcss_paged + `  ${sel} {
                string-set: ${myopts["_stringSet"]};
              }\n`;
            }

            // get the full css content for this element
            var [cssstring,
                 beforecontent,
                 aftercontent,
                 splitcontent,
                 dropcapstring,
                 pagedcssstring,
                 epubcssstring,
                 cssobj,
                 margincssstring] = makeCSS(myopts,sel,ALL_FONTS_ARR,subsel,epub,defaults);

            let kindle_styles = "";
            let paged_styles = "";
            let epub_styles = "";
            let element_styles = "";

            element_styles = element_styles + `${sel} {
              ${cssstring}
            }
            ${dropcapstring && !kindle ? dropcapstring : ""}`;

            if (!epub) {
              element_styles = element_styles + `
              ${sel.replace(/,/g,".continued,")}.continued {
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: 0pt solid black;
              }
              ${sel.replace(/,/g,".continuation,")}.continuation {
                text-indent: 0;
                margin-top: 0;
                padding-top: 0;
                border-top: 0pt solid black;
              }
              ${splitcontent}`;
            }

            if (!kindle) {
              element_styles = element_styles + `
              ${beforecontent}
              ${aftercontent}
              `;
            } else {
              kindle_styles = `
              ${makeKindleCSS(myopts,sel,ALL_FONTS_ARR,epub,defaults,kindle)}
              `;
              kindle_styles = kindle_styles + `
              ${beforecontent}
              ${aftercontent}
              `;
            }
            // not sure about this ^^

            // add the paged-specific styles
            paged_styles = paged_styles + `${sel.replace(/,/g,".pageBreakAfter,")}.pageBreakAfter,
            ${sel.replace(/,/g,".bakedPageBreakAfter,")}.bakedPageBreakAfter {
              page-break-after: always;
            }`;
            if (el === "hblkfootnote") {
              paged_styles = paged_styles + `${sel + ", *[data-hederis-type=hwprfootnote]"} {
                ${pagedcssstring}
              }`;
            } else {
              paged_styles = paged_styles + `${sel} {
                ${pagedcssstring}
              }`;
              epub_styles = epub_styles + `${sel} {
                ${pagedcssstring}
              }`;
            }

            paged_styles = paged_styles + margincssstring;

            element_styles = cleanupCssString(element_styles);
            kindle_styles = cleanupCssString(kindle_styles);
            paged_styles = cleanupCssString(paged_styles);
            epub_styles = cleanupCssString(epub_styles);

            // Add the styles to the arr of all styles in the book
            if (subsel && el.match(/^ID_/g)) {
              id_subsels.push(element_styles);
              id_subsels.push(kindle_styles);
            } else if (subsel && el.match(/^PARENT_/g)) {
              parent_subsels.push(element_styles);
              parent_subsels.push(kindle_styles);
            } else if (subsel && el.match(/^CHILD_/g)) {
              child_subsels.push(element_styles);
              child_subsels.push(kindle_styles);
            } else if (subsel && el.match(/^PREV_/g)) {
              prev_subsels.push(element_styles);
              prev_subsels.push(kindle_styles);
            } else if (subsel && el.match(/^FIRSTOFTYPE_/g)) {
              first_subsels.push(element_styles);
              first_subsels.push(kindle_styles);
            } else if (subsel) {
              pagedAndEpubCssArr.push(element_styles);
              pagedAndEpubCssArr.push(kindle_styles);
            } else {
              initialcssArr.push(element_styles);
              pagedAndEpubCssArr.push(kindle_styles);
            }
            initialcss_pagedArr.push(paged_styles);
            epubArr.push(epub_styles);

            // get the css for any idsels
            if (idsel && !epub && !kindle) {
              // get the full css content for this element
              var [cssstring,
                   beforecontent,
                   aftercontent,
                   splitcontent,
                   dropcapstring,
                   pagedcssstring,
                   epubcssstring,
                   cssobj,
                   margincssstring] = makeCSS(myopts,idsel,ALL_FONTS_ARR,subsel,epub,defaults);

              let subsel_styles = "";

              if (cssstring && cssstring.match(/\S/)) {
                subsel_styles = `${idsel} {
                  ${cssstring}
                }`;
              }

              subsel_styles = generateSubselStyles(dropcapstring, subsel_styles);
              subsel_styles = generateSubselStyles(splitcontent, subsel_styles);
              subsel_styles = generateSubselStyles(beforecontent, subsel_styles);
              subsel_styles = generateSubselStyles(aftercontent, subsel_styles);

              if (pagedcssstring && pagedcssstring.match(/\S/)) {
                subsel_styles = subsel_styles + `
                  ${idsel} {
                    ${pagedcssstring}
                  }
                `;
              }

              subsel_styles = subsel_styles + margincssstring;

              subsel_styles = cleanupCssString(subsel_styles);

              paged_id_overrides.push(subsel_styles);
            }
          }
        } else if (mymatch.match(/^hspn/g) || mymatch.match(/^hspan/g)) {
          if (!isEmpty(myopts)) {
            var [cssstring,
                 beforecontent,
                 aftercontent,
                 splitcontent,
                 dropcapstring,
                 pagedcssstring,
                 epubcssstring,
                 cssobj,
                 margincssstring] = makeCSS(myopts,sel,ALL_FONTS_ARR,subsel,epub,defaults);

            let element_styles = `${sel} {
                ${cssstring}
              }`;

            if (!epub) {
              element_styles = element_styles + `
                ${splitcontent}
              `;
            }

            // add the before/after content;
            // for kindle, will be converted to inline content as possible
            element_styles = element_styles + `
              ${beforecontent}
              ${aftercontent}
            `;

            let paged_styles = `${sel} {
              ${pagedcssstring}
              position: relative;
            }`;

            let epub_styles = `${sel} {
              ${epubcssstring}
            }`;

            paged_styles = paged_styles + margincssstring;

            element_styles = cleanupCssString(element_styles);
            paged_styles = cleanupCssString(paged_styles);
            epub_styles = cleanupCssString(epub_styles);

            // Add the styles to the arr of all styles in the book
            if (subsel && el.match(/^ID_/g)) {
              id_subsels.push(element_styles);
            } else if (subsel) {
              pagedAndEpubCssArr.push(element_styles);
            } else {
              initialcssArr.push(element_styles);
            }
            initialcss_pagedArr.push(paged_styles);
            epubArr.push(epub_styles);

            if (mymatch === "hspannotecall") {
              let supplemental = `*[data-hederis-type=hspannotecall]::after {
                font-size: ${cssobj["font-size"]};
                color: ${cssobj["color"]};
                font-weight: ${cssobj["font-weight"]};
                font-style: ${cssobj["font-style"]};
                text-transform: ${cssobj["text-transform"]};
                letter-spacing: ${cssobj["letter-spacing"]};
                white-space: ${cssobj["white-space"]};
                text-decoration-line: ${cssobj["text-decoration-line"]};
                text-decoration-style: ${cssobj["text-decoration-style"]};
                text-decoration-color: ${cssobj["text-decoration-color"]};
                font-variant: ${cssobj["font-variant"]};
              }`;
              supplemental = cleanupCssString(supplemental);
              initialcss_pagedArr.push(supplemental);
            }

            // add to the subsel arr
            if (idsel && !epub && !kindle) {
              var [cssstring,
                   beforecontent,
                   aftercontent,
                   splitcontent,
                   dropcapstring,
                   pagedcssstring,
                   epubcssstring,
                   cssobj,
                   margincssstring] = makeCSS(myopts,idsel,ALL_FONTS_ARR,subsel,epub,defaults);

              let subsel_styles = "";

              if (cssstring && cssstring.match(/\S/)) {
                subsel_styles = `${idsel} {
                  ${cssstring}
                }`;
              }

              pagedcssstring = pagedcssstring + margincssstring;

              subsel_styles = generateSubselStyles(splitcontent, subsel_styles);
              subsel_styles = generateSubselStyles(beforecontent, subsel_styles);
              subsel_styles = generateSubselStyles(aftercontent, subsel_styles);

              if (pagedcssstring && pagedcssstring.match(/\S/)) {
                subsel_styles = subsel_styles + `
                  ${idsel} {
                    ${pagedcssstring}
                    position: relative;
                  }`;
              }

              subsel_styles = subsel_styles + margincssstring;

              subsel_styles = cleanupCssString(subsel_styles);

              paged_id_overrides.push(subsel_styles);
            }
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  });

  initialcss_paged = initialcss_paged + initialcss_pagedArr.join("\n");
  pagedAndEpubCss = pagedAndEpubCss + pagedAndEpubCssArr.join("\n");
  initialcss = initialcss + initialcssArr.join("\n");
  epubcss = epubcss + epubArr.join("\n");
  overrides = overrides + footnoteOverrides(config);
  let id_subsels_css = id_subsels.join("\n");

  var sep = "\n/* *******HEDERIS_SEPARATOR_NON_PAGED_BELOW******* */\n";
  var final_paged = pagedAndEpubCss + initialcss + initialcss_paged + parent_subsels.join("\n") + child_subsels.join("\n") + first_subsels.join("\n") + prev_subsels.join("\n") + id_subsels_css + footnoteOverrides(config);
  // controls live preview in the browser, thus there is duplication
  var final_unpaged = paged_id_overrides.join("\n") + pagedoverrides + foliooverrides + overrides;
  var finalcss = final_paged + sep + final_unpaged;
  var final_epub_css = initialcss + pagedAndEpubCss + parent_subsels.join("\n") + child_subsels.join("\n") + first_subsels.join("\n") + prev_subsels.join("\n") + id_subsels_css + epubcss + sep;
  // returns printcss, epubcss
  return [finalcss, final_epub_css, uioverrides];
}

function generate_css(template_builder_advanced, typelist, ALL_FONTS_ARR, epub=false, kindle=false, advanced_config_version=0) {
  // console.log('generate_css typelist - -', typelist)
  var css = generateCSS(template_builder_advanced, typelist, ALL_FONTS_ARR, epub, kindle, advanced_config_version);
  return css;
}

module.exports = {generate_css, epubify, parseFont, getFontImports, makeFontFaceDeclaration, validateVal, getOptions, makeOTObj, makeOT, makeCSS}