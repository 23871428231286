import React from "react";
import ReactDOM from "react-dom";
// prettier-ignore
import {Pane, Text, Heading, Code, Button, TextInputField, TabNavigation, Tab, SidebarTab, Tablist, Link, toaster, IconButton, Icon, Small, TextInput, RadioGroup, Select, Strong, Checkbox, Combobox, TagInput, Portal, TrashIcon} from "evergreen-ui";
import {
  parse_dim,
  camelCaseToDash,
  get_label_from_key,
  parse_hederis_type,
  get_subselector_label,
} from "../../helpers/util.js";
import ALL_TYPES from "../../../api/app_shared/defaults/types.json";

import { LoadingPane, ProjectTabs, NotFound } from "../shared/common.js";

let SELECTION_MODE_ARR = [
  "all",
  "only",
  "within_parent",
  "direct_child",
  "first_within_parent",
  "following",
  "custom",
];

export default function SelectionControl(props) {
  // console.log("SelectionControl", props);
  let sp_len = props.selection_path.length;
  let h_type = sp_len ? parse_hederis_type(props.selection_path[0]) : "";
  let parent_h_type =
    sp_len > 1 ? parse_hederis_type(props.selection_path[1]) : "";
  // let existing = h_type
  let cur_type_subpath = `${h_type}_subSelectors`;
  // console.log(cur_type_subpath);
  // let sub_sel_path = this.get_subsel_path_key();
  let general_sub_sels_obj = props.config[cur_type_subpath];
  // console.log("general_sub_sels_obj", general_sub_sels_obj);
  // console.log(props.sub_sel_path);
  let existing_sub_sel_obj = props.sub_sel_path
    ? general_sub_sels_obj[props.sub_sel_path]
    : false;
  // console.log("EXISTING", existing_sub_sel_obj);

  let clear_subsel_ui = existing_sub_sel_obj ? (
    <Button
      intent="danger"
      marginLeft={8}
      iconBefore={TrashIcon}
      disabled={props.content_editing}
      onClick={() => {
        // delete the subsel object for this specific sub selector
        delete general_sub_sels_obj[props.sub_sel_path];
        // console.log(general_sub_sels_obj);
        props.onChange(cur_type_subpath, general_sub_sels_obj);
        // this would clear all of this type XXX
        // props.onChange(cur_type_subpath, {});
      }}
    >
      Clear Override
    </Button>
  ) : (
    ``
  );
  //
  // just prevent the prev one from showing up if it's not present
  // console.log(SELECTION_MODE_ARR);
  let sel_mode_arr_filter = x => {
    if (sp_len === 1) {
      if (
        props.selection_path.length > 0 &&
        props.selection_path[0].match(/^[a-z]+\.hsec/)
      ) {
        return x == "all" || x == "only";
      } else {
        return x == "all";
      }
    } else if (!props.prev_sibling_type_sel) {
      return x !== "following";
    } else {
      return x;
    };
  }
  let is_mode_input_disabled = sp_len < 1;
  // todo a tooltip about clicking an element to enable
  let mode_input = (
    <Select
      value={props.selection_mode}
      marginY={8}
      width="250px"
      flex="0"
      backgroundColor="#ffffff"
      borderRadius="5px"
      disabled={is_mode_input_disabled}
      onChange={event => {
        props.onViewChange({
          selection_mode: event.target.value,
          content_editing: false,
        });
      }}
    >
      {SELECTION_MODE_ARR.filter(sel_mode_arr_filter).map(k => {
        let label = "";
        if (k == "custom") {
          label = "Custom...";
        } else {
          label = get_subselector_label(
            props.selection_path, 
            k, 
            h_type, 
            parent_h_type, 
            props.prev_sibling_type_sel
          );
        }
        // prettier-ignore
        return (<option key={k} value={k}>{label}</option> );
      })}
    </Select>
  );
  //         intent={props.content_editing ? "warning" : "success"}

  // we only want to show the edit button for block els
  // deal with first case only shows up with baked XXX
  // let is_editable_el =
  //   h_type &&
  //   (h_type.startsWith("hblk") ||
  //     h_type.startsWith("hspan") ||
  //     h_type.startsWith("hspn"));
  return (
    <Pane>
      <Pane 
        className="fixedwidth"
        display="flex" 
        flexDirection="row" 
        alignItems="center" 
        justifyContent="space-between" 
        paddingLeft={8}
        paddingRight={16}
      >
        <Text marginX={8} size={300} color="#ffffff">
          Limit these changes to:{" "}
        </Text>
        {mode_input}
      </Pane>
      {/*{is_editable_el ? edit_save_and_cancel_ui : ``}*/}
      {props.selection_path.length === 0 ? (
        ``
      ) : (
        <Pane
          margin={4}
          padding={4}
          paddingRight={12}
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            disabled={props.content_editing}
            onClick={() => {
              props.onViewChange({
                selection_mode: "all",
                content_editing: false,
                selection_path: [],
              });
            }}
          >
            Deselect
          </Button>
          {props.selection_mode !== "all" ? clear_subsel_ui : ``}
        </Pane>
      )}
    </Pane>
  );
}
