import React from "react";
import ReactDOM from "react-dom";
import { Pane, Text, Link, Heading, toaster } from "evergreen-ui";

import { ProjectInfoView } from "../shared/info.js";

import default_basic_config from "../../../api/app_shared/defaults/basic_project_defaults.json";
import { FireHelpers } from "../../../api/app_shared/fire_helpers.js";
import { firebase, db } from "../../fire.js";

const FH = new FireHelpers(db, firebase);

// these were functional components that I added is_loading state to,
// to turn on the loading state for buttons once they've clicked submit

export class NewAdvancedProjectContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { is_loading: false };
  }
  async createNew(project) {
    let props = this.props;
    // set the flag so we know this is the typeset product (ie not basic/convert)
    project.is_advanced = true;
    project.advanced_config_version = "1.0";
    // this is NOT the advanced_config
    project["basic_config"] = default_basic_config;
    this.setState({ is_loading: true });
    let project_id = await FH.create_new_project(
      project,
      props.hederis_user.uid
    );
    toaster.success("New Project Created!");
    window.location = `#/projects/${project_id}/`;
  }
  render() {
    return (
      <ProjectInfoView
        onSubmit={this.createNew.bind(this)}
        isTemplate={false}
        {...this.props}
        {...this.state}
      />
    );
  }
}
export class NewTemplateProjectContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { is_loading: false };
  }
  async createNew(project) {
    let props = this.props;
    // set the flag so we know this is the typeset product (ie not basic/convert)
    project.is_advanced = true;
    project.is_template = true;
    project.advanced_config_version = "1.0";
    // this is NOT the advanced_config
    project["basic_config"] = default_basic_config;
    this.setState({ is_loading: true });
    let project_id = await FH.create_new_project(
      project,
      props.hederis_user.uid
    );
    toaster.success("New Template Project Created!");
    window.location = `#/projects/${project_id}/`;
  }
  render() {
    return (
      <ProjectInfoView
        onSubmit={this.createNew.bind(this)}
        isTemplate={true}
        {...this.props}
        {...this.state}
      />
    );
  }
}
export class NewGalleyProjectContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { is_loading: false };
  }
  async createNew(project) {
    let props = this.props;
    this.setState({ is_loading: true });
    //basically legacy
    project.is_advanced = true;
    // XXX
    // THIS IS THE KEY DIFFERENCE FROM THE ABOVE
    // XXX
    project["nontypeset_mode"] = "galley";
    // XXX
    project["basic_config"] = default_basic_config;
    let project_id = await FH.create_new_project(
      project,
      props.hederis_user.uid
    );
    toaster.success("New Project Created!");
    window.location = `#/projects/${project_id}/`;
  }
  render() {
    return (
      <ProjectInfoView
        onSubmit={this.createNew.bind(this)}
        isTemplate={false}
        {...this.props}
        {...this.state}
      />
    );
  }
}
