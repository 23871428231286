import React from "react";
import ReactDOM from "react-dom";
// prettier-ignore
import {Pane, Alert, InlineAlert, Popover, Code, Menu, Position, Tooltip, Paragraph, Text, Heading, Button, Badge, TextInputField, TabNavigation, Tab, SidebarTab, Tablist, Link, toaster, IconButton, Icon, Small, TextInput, RadioGroup, Select, Strong, Checkbox, Combobox, TagInput, Portal, TickIcon} from "evergreen-ui";
// this should reflect a combination of if a bill exists for this project and
// import {check_discount, get_billing_user_stripe_id} = require("../../api/app_shared/process/discount_logic.js");
import { firebase, db, storage } from "../../fire.js";

import { useState, useEffect } from "react";
import {
  check_discount,
  get_billing_user_stripe_id,
} from "../../../api/app_shared/process/discount_logic.js";

export function BillingStatus(props) {
  let [needsBillingSetup, setNeedsBillingSetup] = useState(false);

  function handleLoad(is_billing_setup) {
    setNeedsBillingSetup(!is_billing_setup);
  }
  // this will still run in cases like it's free XXX,
  useEffect(
    () => {
      const { project } = props;
      get_billing_user_stripe_id(db, { project }).then(handleLoad);
    },
    [props.project.group]
  );

  return (
    <Pane display="flex" alignItems="center" marginBottom={16}>
      {get_status_text(props, needsBillingSetup)}
    </Pane>
  );
}

function get_status_text(props, needsBillingSetup) {
  // if it's totally free, just say so and return
  if (
    props.discount_obj_for_project &&
    props.discount_obj_for_project.discount === 1
  ) {
    return (
      <Badge color="green">
        Free, using code "{props.project.project_discount_code}"
      </Badge>
    );
  }
  let is_billed_text = props.bill_for_project ? (
    <Badge color="green">
      <Icon icon={TickIcon} size={16} marginRight={4} />
      <Text marginRight={4}>Already Billed </Text>
    </Badge>
  ) : (
    <Pane>
      {needsBillingSetup ? (
        <Alert intent="danger" 
          title="Billing info not found"
        >
          <Paragraph lineHeight="18px" marginTop={8}>
            Billing information is required before you can upload a file. 
            Please enter your billing information{" "}
            <Link href="/#/account">here</Link>.
          </Paragraph>
        </Alert>
      ) : (
        <Badge color="red">Not Yet Billed</Badge>
      )}
    </Pane>
  );

  let discount_text = ``;

  if (
    props.discount_obj_for_project &&
    props.discount_obj_for_project.discount
  ) {
    let percent = (100 * props.discount_obj_for_project.discount).toFixed(0);
    discount_text = (
      <Text>
        (Discounted <Code appearance="minimal">{percent}%</Code> Off, using{" "}
        <Code appearance="minimal">{props.project.project_discount_code}</Code>)
      </Text>
    );
  }

  return (
    <>
      {is_billed_text} {discount_text}
    </>
  );
}
