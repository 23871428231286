import React from "react";
import ReactDOM from "react-dom";
import { useState, useEffect } from "react";
// prettier-ignore
import { Pane, Text, Icon, Heading, Button, toaster, Spinner, Badge, Strong, Paragraph, Popover} from "evergreen-ui";
import { validate_email, get_random_string, get_arr } from "../../helpers/util";
import { FireHelpers } from "../../../api/app_shared/fire_helpers.js";
import { firebase, db } from "../../fire.js";

import APIClient from "../../helpers/api_client";

const API = new APIClient(firebase);
const FH = new FireHelpers(db, firebase);

// Make this a comp
export function ProjectListItem(props) {
  const [creatorName, setCreatorName] = useState(false);
  const [hasAccess, setHasAccess] = useState(false);

  let item = props.item;
  let { id, title, target, creator, author, nontypeset_mode } = item;
  const is_typeset_product = nontypeset_mode !== "galley";
  // const project_kind_badge_text = is_typeset_product ? "Typeset" : "Galley";

  // console.log(item);
  let project_kind_badge_text = is_typeset_product ? "Typeset" : "Galley";
  id = target || id;
  // console.log(item);

  const selfJoin = async () => {
    toaster.notify("Adding you to the project...");
    let user_data = await FH.get_user_data(props.currentUser);
    let invite_code = get_random_string(40);
    let email = user_data.email;
    let from = user_data.displayName;
    let kind = "project_access";
    let target = id;
    let assoc_group = item.group;
    let to_uid = user_data.uid;

    let invite_data = {
      invite_code,
      email,
      from,
      kind,
      target,
      assoc_group,
      to_uid
    };
    let invite_res = await FH.create_invite(invite_data);
    // then set the claim
    let claim_res = await API.set_claims(invite_code);
    // then accept the invite
    let accept_res = await FH.accept_invite(to_uid, invite_code);
    // add the project id to the list of user's projects
    props.onAdd(target);
    setHasAccess(true);
    toaster.closeAll();
    toaster.success("You are now a member of this project!");
  }

  useEffect(() => {
    const getCreatorName = async () => {
      let name = "You";
      if (creator !== props.currentUser) {
        name = await FH.get_user_display_name(creator);
      }
      setCreatorName(name);
    };
    getCreatorName();
  }, [props.item]);

  useEffect(() => {
    let access = props.mode === "personal" ? true : false;
    setHasAccess(access);
  }, [props.mode]);

  // setClaims for the original owner so they can still access the project
  // set the new owner to the selected user

  let aStyle = {flexBasis: "100%"};

  return (
    <Pane display="flex" flexDirection="row" alignItems="center">
      <a href={`#/projects/${id}`} key={id} style={aStyle}>
        <Pane
          flex={1}
          alignItems="center"
          display="flex"
          flexBasis={props.mode === "group" ? "75%" : "100%"}
          padding={8}
          marginTop={8}
          marginBottom={8}
          background="tint2"
          borderRadius={3}
          border="muted"
          textTransform="capitalize"
        >
          <Pane
            flex={1}
            alignItems="baseline"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Pane display="flex" flexDirection="row">
              <Pane width={100} display="flex" flexDirection="column" padding={0} justifyContent="center">
                <Badge
                  alignSelf="center"
                  appearance="subtle"
                  color={is_typeset_product ? "blue" : "green"}
                  title={`Hederis ${project_kind_badge_text} Project`}
                  style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                >
                  {project_kind_badge_text}
                </Badge>
              </Pane>
              <Heading size={600} maxWidth={620}>{title}</Heading>
              <Pane marginX={8}>
                {item.group.startsWith("user_") ? (
                  ``
                ) : (
                  <Badge color="purple">{item.group}</Badge>
                )}
              </Pane>
            </Pane>
            <Pane flexDirection="row">
              <Text size={400}>by: </Text>
              <Strong size={400}>{author}</Strong>
            </Pane>
          </Pane>
        </Pane>
      </a>
      {props.mode == "group" && (
        <ProjectOwner 
          creatorName={creatorName} 
          setCreatorName={setCreatorName}
          mode={props.mode} 
          uid={props.currentUser} 
          updateProjectOwner={props.updateProjectOwner} 
          project_id={id} 
        />
      )}
      {!hasAccess && props.mode != "group" && (
        <Button onClick={e => selfJoin()} marginLeft={16}>Join</Button>
      )}
    </Pane>
  );
}

function ProjectOwner(props) {
  let setNewOwner = async () => {
    // replace the original owner in the db
    props.updateProjectOwner(props.uid, props.project_id);
    // set the new claim
    let new_project_owner = { uid: props.uid, project_id: props.project_id };
    let res = await API.set_new_project_owner_claim(new_project_owner);
    if (res.message && res.message === "ok") {
      props.setCreatorName("You");
      toaster.success("Successfully changed the project owner", {
        duration: 15,
      });
    } else {
      toaster.closeAll();
      toaster.danger("There was an error updating the project owner", {
        duration: 15,
      });
    }
  }

  return (
    <Pane 
      flexBasis="25%"
      flex-grow="1"
      padding={8}
      marginTop={8}
      marginBottom={8}
    > 
      {props.creatorName == "You" ? (
        <Pane flexDirection="row">
          <Text size={400}>Owned by: </Text>
          <Strong size={400}>{props.creatorName}</Strong>
        </Pane>
      ) : (
        <Popover
          content={
            <Pane width={240} paddingY={16} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
              <Button appearance="primary" onClick={e => setNewOwner()}>Make Yourself Owner</Button>
            </Pane>
          }
        >
          <Button>Owned by: {props.creatorName}</Button>
        </Popover>
      )}
    </Pane>
  )
}
