import React from "react";
import ReactDOM from "react-dom";

// prettier-ignore
import { Pane, Text, Icon, Heading, Button, toaster, Spinner, Badge, Strong, Paragraph} from "evergreen-ui";

// it'd be cool to use this for coloring the group badges
// import hashCode from "evergreen-ui/esm/avatar/src/utils/hash.js";

import { LoadingPane, ExpandablePane } from "./common";
import { ProjectListItem } from "./project_list_item.js";

// import { get_arr } from "../../helpers/util";
import { FireHelpers } from "../../../api/app_shared/fire_helpers.js";
import product_prices from "../../../api/app_shared/product_prices.js";

import { firebase, db } from "../../fire.js";

const FH = new FireHelpers(db, firebase);

export default class ProjectsList extends React.Component {
  constructor(props) {
    super(props);
    // let group = get_group(props);
    this.toggle_list = this.toggle_list.bind(this);
    this.add_project_id_to_projects_list = this.add_project_id_to_projects_list.bind(this);
    this.state = { 
      personal_projects: false, 
      shared_projects: false, 
      group_projects: false, 
      all_accessible_project_ids: [],
      uid: false,
      projects_expanded: true,
      shared_expanded: false,
    };
    // this.onChange = this.onChange.bind(this);
  }
  async componentDidMount() {
    // load all the user's projects
    let { uid } = this.props.auth_user;

    // this JUST gets the `user_${uid}` ones
    // list_group_projects_created_by
    // see below for the ones created by you in the group
    let personal_projects = await FH.list_personal_projects(uid);
    let shared_projects = await FH.get_projects_shared_with(uid);
    let { owned_groups, groups } = this.props;
    let all_groups = [...new Set(owned_groups.concat(groups))];
    let group_projects = [];
    for (let g of all_groups) {
      let created_by_user = await FH.list_group_projects_created_by(uid, g);
      personal_projects = personal_projects.concat(created_by_user);
      let group_data = await FH.get_group(g);
      if (group_data.self_join) {
        let all_projects = await FH.list_group_projects(g);
        group_projects.push({id: g, projects: all_projects, expanded: false});
      }
    }
    let all_accessible_project_ids = [].concat(personal_projects).concat(shared_projects).map(item => item.id);
    personal_projects = personal_projects.sort(FH.sort_by_created);
    this.setState({ personal_projects, shared_projects, group_projects, all_accessible_project_ids, uid });
  }
  add_project_id_to_projects_list(pid) {
    let new_list = [pid].concat(this.state.all_accessible_project_ids);
    this.setState({ all_accessible_project_ids: new_list });
  }
  // to include first free project discount code
  get_new_typeset_project_url() {
    let { personal_projects, shared_projects } = this.state;
    // for now the logic is simply - do they have any
    // personal (ie, created, in a real group or as user_uid) projects
    return personal_projects && personal_projects.length
      ? `#/new/typeset/TMP_PROMO`
      : `#/new/typeset/FIRST_FREE`;
  }
  get_new_template_project_url() {
    let { personal_projects, shared_projects } = this.state;
    // for now the logic is simply - do they have any
    // personal (ie, created, in a real group or as user_uid) projects
    return `#/new/template/TEMPLATE`;
  }
  get_new_galley_project_url() {
    let { personal_projects, shared_projects } = this.state;
    return personal_projects && personal_projects.length
      ? `#/new/galley/TMP_PROMO`
      : `#/new/galley/FIRST_FREE`;
  }
  toggle_list(g=false) {
    if (g === "projects") {
      this.setState({ projects_expanded: !this.state.projects_expanded });
    } else if (g === "shared") {
      this.setState({ shared_expanded: !this.state.shared_expanded });
    } else {
      let updated = [].concat(this.state.group_projects);
      updated.map(item => {
        if (item.id === g) {
          item.expanded = !item.expanded
        }
        return item;
      });
      this.setState({ group_projects: updated });
    }
  }
  render_group_project_list(group_item) {
    return(
      <Pane key={group_item.id}>
        <ExpandablePane
          label={`${group_item.id} Projects`}
          onClick={this.toggle_list}
          index={group_item.id}
          expanded={group_item.expanded}
          icon=""
        >
          {group_item.projects.map(item => {
            // see if this project id is in the shared_projects or personal_projects list
            let has_access = this.state.all_accessible_project_ids.indexOf(item.id) > 0 ? true : false;
            // prettier-ignore
            return (<ProjectListItem onAdd={this.add_project_id_to_projects_list} item={item} mode={has_access ? "personal" : "selfJoin"} currentUser={this.state.uid} key={item.id} />);
          })}
        </ExpandablePane>
      </Pane>
    );
  }
  render() {
    let { personal_projects, shared_projects, group_projects, uid } = this.state;
    let goTo = l => (window.location = l);
    // for our first free discount code
    //           onClick={e => goTo("#/new/typeset")}

    let creation_buttons = (
      <React.Fragment>
        {" "}
        <Button
          appearance="primary"
          height={42}
          marginX={8}
          onClick={e => goTo(this.get_new_typeset_project_url())}
          id="NewTypeset"
        >
          Create New Typeset Project
        </Button>
        <Button
          appearance="primary"
          intent="danger"
          height={42}
          marginX={8}
          onClick={e => goTo(this.get_new_template_project_url())}
          id="NewTemplate"
        >
          Create New Template Project
        </Button>
        <Button
          appearance="primary"
          height={42}
          intent="success"
          marginX={8}
          onClick={e => goTo(this.get_new_galley_project_url())}
          id="NewGalley"
        >
          Create New Galley Project
        </Button>
      </React.Fragment>
    );

    // show more creation buttons at the top if there are > 10 projects
    //prettier-ignore
    let show_extra_buttons = personal_projects && shared_projects && personal_projects.length+shared_projects.length > 10

    return (
      <Pane display="flex" flexDirection="column" alignItems="center" marginBottom={128}>
        {/*<Pane alignItems="flex-start" width={700}>*/}
        <Pane alignItems="flex-start" maxWidth={1000} width="90vw">
          <Heading paddingBottom={24} paddingTop={24} size={800}>
            Projects
          </Heading>
        </Pane>
        <Pane>{show_extra_buttons ? creation_buttons : ``}</Pane>

        <Pane alignItems="flex-start" maxWidth={1000} width="90vw">
          {personal_projects === false ? (
            <LoadingPane />
          ) : personal_projects.length ? (
            <ExpandablePane
              label={"Your Projects"}
              onClick={this.toggle_list}
              index={"projects"}
              expanded={this.state.projects_expanded}
              icon=""
            >
              {personal_projects.map(item => <ProjectListItem item={item} mode="personal" currentUser={uid} key={item.id} />)}
            </ExpandablePane>
          ) : (
            <Text>You don't have any projects yet. </Text>
          )}
          {shared_projects === false ? (
            <LoadingPane />
          ) : shared_projects.length ? (
            <ExpandablePane
              label={"Projects Shared With You"}
              onClick={this.toggle_list}
              index={"shared"}
              expanded={this.state.shared_expanded}
              icon=""
            >
              {shared_projects.map(item => <ProjectListItem item={item} mode="personal" currentUser={uid} key={item.id} />)}
            </ExpandablePane>
          ) : (
            ``
          )}
          {group_projects === false ? (
            <LoadingPane />
          ) : (
            group_projects.map(group_item => this.render_group_project_list(group_item))
          )}
        </Pane>
        <hr />
        <Pane marginTop={64}>{creation_buttons}</Pane>
      </Pane>
    );
  }
}
