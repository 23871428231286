export function config_parse(config) {
  let allids = [];
  for (var k in config) {
    if (config.hasOwnProperty(k) && k.match("_subSelectors$")) {
      for (var j in config[k]) {
        if (config[k].hasOwnProperty(j) && j.match("^ID_")) {
          let myid = j.split("_")[1];
          allids.push(myid)
        }
      }
    }
  }
  return allids;
}