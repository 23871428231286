function strip_html_bh(html_string) {
  // prettier-ignore
  return html_string.replace('<html>', '').replace('</html>', '').replace('<body>', '').replace('</body>', '')
}
function strip_dashes(html_string) {
  // prettier-ignore
  return html_string.replace(/(data-hederis-type=)("|')(h)(blk|sec|wpr)([a-zA-Z0-9]*)(-)([a-zA-Z0-9]*)(-*)/g, '$1$2$3$4$5$7');
}
function wrap_with_simple_html_body(html_string) {
  // prettier-ignore
  return `<html><body>${html_string}</body></html>`
}

// prettier-ignore
function make_html_page({ html='', css='', js_cdn_arr=[], js='', advanced_config_version=0, render_env }) {
  if (!["frontend", "backend-pdf", "backend-epub"].includes(render_env)){
    throw Error('No Render Env Specified')
  }

  return `
  <!DOCTYPE html>
  <html>
    <head>
      <meta charset="utf-8">
      <meta name="advanced_config_version" content="${advanced_config_version}">
      <meta name="render_env" content="${render_env}">
      <style type='text/css'>${css}</style>
    </head>

  <body>
    ${html}
    ${js_cdn_arr.map(L => `<script src="${L}"></script>`)}
    <script>
    ${js}
    </script>
  </body>
</html>
  `;
}

module.exports = {
  make_html_page,
  strip_html_bh,
  strip_dashes,
  wrap_with_simple_html_body,
};
