import React from "react";
import ReactDOM from "react-dom";
// prettier-ignore
import {Pane, Text, Heading, Button, TextInputField, Spinner, TabNavigation, Tab, SidebarTab, Tablist, Link, toaster, IconButton, Icon, Small, TextInput, RadioGroup, Select, Strong, Checkbox, Combobox, TagInput, Portal, Position, Tooltip} from "evergreen-ui";
import {
  Debouncer,
  parse_dims,
  writeStylesDoc,
  parse_hederis_type,
  parse_id,
} from "../../helpers/util";

import { view_options_css } from "../../helpers/preview_view_options_css.js";

import { unpage_html } from "../../../api/app_shared/process/unpage.js";

import { storage } from "../../fire.js";
import { EditSaveAndCancel } from "./confirm_widget.js";

// import PREVIEW_JS from "!raw-loader!./preview.js";
// import dev_css_template1 from "!raw-loader!../../../api/app_shared/book_styles/template1-print-pagedjs.css";
// import template_builder_defaults from "../../../api/app_shared/defaults/template_builder_defaults.json";
import placeholder_image_url from "../../../api/app_shared/assets/fpo.png";

import RUN_PAGED from "!raw-loader!../../../api/app_shared/process/run_paged.js";
import PAGE_BREAKING from "!raw-loader!../../helpers/pageBreaking.js";
// import SOME_AFTER_PAGED_SCRIPT from "!raw-loader!../../../api/app_shared/process/after_paged.js";

// console.log(PREVIEW_JS);
import { hypherHyphenate } from "../../../api/app_shared/process/hyphenate.js";
// prettier-ignore
import {  make_html_page, strip_html_bh, strip_dashes, wrap_with_simple_html_body } from "../../../api/app_shared/process/html.js";
const {
  replace_page_nums,
  replace_book_title,
  replace_author_name,
  replace_css_image_urls,
} = require("../../../api/app_shared/process/css.js");
// import {  make_html_page, strip_html_bh, wrap_with_simple_html_body, replace_img_url } from "../../../api/app_shared/process/html.js";
const { asyncForEach } = require("../../../api/app_shared/process/async.js");
const replace_img_urls = require("../../../api/bake_helpers/replace_img_urls");
import { track_change } from "../../helpers/util.js";

// import advanced_template from "!raw-loader!../../../api/bake_helpers/css_advanced_template.js";
import template_builder_advanced_defaults from "../../../api/app_shared/defaults/template_builder_advanced.json";
// this is babel ignored XXX
import advanced_template from "../../../api/bake_helpers/css_advanced_template.js";
// NELLIE'S STUFF
import { generate_css } from "../../../api/bake_helpers/generate_css.js";

import ALL_FONTS_ARR from "../../../api/app_shared/fonts/fonts.json";
import { font_loader } from "../../../api/app_shared/process/fonts";

import {
  get_font_lookup,
  patch_fontnames_to_urls,
  patch_imgnames_to_urls,
} from "../../../api/app_shared/process/config.js";

// note, this is not PAGED_POLY_CDN
const PAGED_MAIN_CDN = `https://unpkg.com/${process.env.PAGED_NPM_NAMESPACE}@${
  process.env.PAGED_NPM_VERSION
}/dist/paged.js`;

export default class Frame extends React.Component {
  constructor(props) {
    super(props);

    this.preview_ref = React.createRef();
    this.paste_adjust = this.paste_adjust.bind(this);
    this.supress_browser_events = this.supress_browser_events.bind(this);
    this.onRunLayoutClick = this.onRunLayoutClick.bind(this);
    this.scrollToPage = this.scrollToPage.bind(this);
    this.state = { is_loading: true, page_count: 0, overflow: 0 };
  }
  componentDidMount() {
    this.setup();
  }
  receiveCommand(command, data) {
    // console.log("frame component got", command, data);
    // should probs check  this.props.current_range
    this.iframe.contentWindow.hederis_layout_command(command, data);
    if (
      this.iframe.contentWindow.getSelection() &&
      this.iframe.contentWindow.getSelection().type === "Range"
    ) {
      let obj = this.getRangeParentInfo(
        this.iframe.contentWindow.getSelection().getRangeAt(0)
      );
      let selection_path = [
        'data-id="' + obj["id"],
        'data-id="' + obj["parent_id"],
      ];
      let new_change = track_change(
        selection_path,
        obj["section_id"],
        this.props.view_options.epub_mode,
        this.props.isLocked,
        this.props.hederis_user.uid
      );
      if (Object.keys(new_change).length > 0) {
        this.props.update_change_list([new_change]);
      }
    }
  }
  // componentWillUnmount() {
  //   console.log(this.iframe);
  //   this.iframe.remove();
  // }

  font_lookup() {
    return get_font_lookup(this.props.fonts_arr);
  }

  get_css() {
    let advanced_config_version = 0;
    let digital_page_specs = `@page {
      size: 5in 7in;
      margin: 0.5in;
      padding: 0in;
    }
    @page :left, @page :right, @page :blank, @page :first {
      @top-left-corner {
        content: normal;
      }
      @top-left {
        content: normal;
      }
      @top-center {
        content: normal;
      }
      @top-right {
        content: normal;
      }
      @top-right-corner {
        content: normal;
      }
      @right-top {
        content: normal;
      }
      @right-middle {
        content: normal;
      }
      @right-bottom {
        content: normal;
      }
      @bottom-right-corner {
        content: normal;
      }
      @bottom-right {
        content: normal;
      }
      @bottom-center {
        content: normal;
      }
      @bottom-left {
        content: normal;
      }
      @bottom-left-corner {
        content: normal;
      }
      @left-bottom {
        content: normal;
      }
      @left-middle {
        content: normal;
      }
      @left-top {
        content: normal;
      }
      @footnote {
        float: bottom;
      }
    }
    .FootnoteReference, *[data-hederis-type="hspannoteref"] {
      float: footnote;
      footnote-policy: block;
      footnote-display: block;
    }
    *[data-hederis-type=hwprfootnote] {    
      display: block;
    }
    ::footnote-call {
      line-height: 4pt;
    }
    *[data-hederis-type=hblkfootnote] {
      display: block;
    }
    *[data-hederis-type=hblkfootnote]:first-of-type {
      display: inline-block;
      text-indent: 0;
    }
    sup *[data-hederis-type=hblkfootnote]:first-of-type {
      display: block;
    }
    *[data-hederis-type=hblkfootnote]:first-of-type::before {
      content: counter(footnote-marker) ". ";
    }
    .pagedjs_page.pagedjs_named_page.pagedjs_chapter_page *[data-footnote-marker]:first-of-type {
      margin-top: 12pt;
    }
    .pagedjs_page.pagedjs_named_page.pagedjs_frontmatter_page *[data-footnote-marker]:first-of-type {
      margin-top: 12pt;
    }
    .pagedjs_page.pagedjs_named_page.pagedjs_backmatter_page *[data-footnote-marker]:first-of-type {
      margin-top: 12pt;
    }
    .pagedjs_page.pagedjs_named_page.pagedjs_clear_page *[data-footnote-marker]:first-of-type {
      margin-top: 12pt;
    }
    .pagedjs_page.pagedjs_named_page.pagedjs_part_page *[data-footnote-marker]:first-of-type {
      margin-top: 12pt;
    }
    [data-footnote-marker]::marker {
      content: none;
    }
    `;

    if (this.props.advanced_config_version) {
      advanced_config_version = this.props.advanced_config_version;
    }

    let advanced_config = patch_fontnames_to_urls(
      this.props.advanced_config,
      this.font_lookup()
    );
    advanced_config = patch_imgnames_to_urls(
      advanced_config,
      this.props.image_lookup
    );

    let [css_content, epub_css_content, uioverridecss] = generate_css(
      advanced_config,
      this.props.style_list,
      this.props.fonts_arr,
      this.props.view_options.epub_mode,
      false,
      advanced_config_version
    );
    if (this.props.view_options.epub_mode == true) {
      css_content = digital_page_specs + epub_css_content;
    }
    css_content = replace_page_nums(css_content);
    css_content = replace_book_title(css_content);
    css_content = replace_author_name(css_content);
    return [css_content, uioverridecss];
  }

  // public method XXX - sort of an anti-pattern for react but not terrible and we need to access the iframe's document content directly at some level, better to do it outside of this comp
  get_content() {
    try {
      let unbaked_html = this.iframe.contentDocument.querySelector(
        ".pagedjs_pages"
      ).innerHTML;
      let css_content = this.iframe.contentDocument.querySelector(
        "head style#non_paged_css"
      ).innerHTML;
      //   unpaging is more complicated, this creates a whole doc fragment to do it.
      // we only need to unpage if there have been any changes of type:
      // text edits, "only this" selectors added, page layout adjustments.
      let { html_content } = unpage_html(unbaked_html, css_content);

      let baked_content = html_content;

      if (this.iframe.contentDocument.querySelector("#baked_wrap") != null) {
        // prettier-ignore
        let baked_html = this.iframe.contentDocument.querySelector("#baked_wrap").innerHTML;
        let result = unpage_html(baked_html, css_content);
        baked_content = result.html_content;
        // console.log("using #baked_wrap");
      }
      // XXX if we're locked, don't let us write to html_content at all !
      if (this.props.isLocked && !this.props.view_options.epub_mode) {
        html_content = "";
      } else if (this.props.view_options.epub_mode) {
        baked_content = "";
      }
      // console.log("frame getContent:");
      // console.log({ baked_content, html_content });
      // console.log(this.props.view_options.epub_mode);
      let { is_loading } = this.state;

      return { html_content, baked_content, is_loading };
    } catch (error) {
      console.error(error);
      toaster.warning("There was an error updating your text.");
    }
  }

  rebake() {
    this.iframe.contentWindow.HEDERIS_REBAKE();
  }
  async run(supress_scroll_persist = false) {
    this.setState({ is_loading: true, overflow: 0 });
    this.props.set_loading(true);
    // console.log("run", supress_scroll_persist);
    // we'll use this after we re-render
    let scrollY = this.iframe.contentWindow.scrollY;

    let debug_start = new Date();

    let [css_content, uioverridecss] = this.get_css();
    let [paged_css, non_paged_css] = css_content.split(
      `/* *******HEDERIS_SEPARATOR_NON_PAGED_BELOW******* */`
    );
    let adjusted_custom_css = replace_css_image_urls(
      this.props.custom_css, 
      this.props.image_lookup
    );
    // let css_content = paged_css + non_paged_css;
    let b64_css = Buffer.from(paged_css + adjusted_custom_css).toString("base64");
    // console.log(this.props.html_content);
    // let html_content = strip_html_bh(this.props.html_content);
    let html_content = strip_dashes(this.props.html_content);
    html_content = hypherHyphenate(html_content,this.props.advanced_config);
    // console.log({ "RUN html_content": this.props.html_content });
    // console.log(this.image_lookup);
    // use the cached image lookup
    let html_content_with_img_replaced = replace_img_urls(
      html_content,
      this.props.image_lookup,
      placeholder_image_url
    );

    // console.log("html_content after image replace");
    // console.log({ html_content_with_img_replaced });
    // console.log(html_content_with_img_replaced === html_content);

    this.writeStylesDoc("non_paged_css", non_paged_css);

    this.iframe.style.visibility = "hidden";
    // wait for it to run
    let advanced_config_version = undefined;

    if (this.props.advanced_config_version) {
      advanced_config_version = this.props.advanced_config_version;
    }

    let page_count = await this.iframe.contentWindow.HEDERIS_RUN_PREVIEW(
      html_content_with_img_replaced,
      b64_css,
      advanced_config_version,
      {}
    );
    this.iframe.style.visibility = "visible";

    // strip blank paragraphs added by the browser
    this.strip_blanks();

    let debug_end = new Date();
    let debug_secs = ((debug_end - debug_start) / 1000).toFixed(3);
    // console.log(`it took ${debug_secs} seconds to render paged`);
    this.setState({ is_loading: false, page_count });
    this.props.set_loading(false);
    this.props.set_page_count(page_count);
    if (!supress_scroll_persist) {
      this.iframe.contentWindow.scrollTo(0, scrollY);
    }

    let overflow = this.get_overflow();
    this.setState({ overflow });

    return page_count;
  }
  // super simple hacky way just to show up in ui
  update_body_lock_ui(is_current_section_locked) {
    if (this.iframe && this.iframe.contentWindow) {
      let className = is_current_section_locked ? "HED_LOCKED_UI" : "";
      this.iframe.contentWindow.document.body.className = className;
    }
  }
  strip_blanks() {
    if (this.iframe && this.iframe.contentWindow) {
      let blanks = this.iframe.contentWindow.document.querySelectorAll("img + p:empty");
      for (let i = 0; i < blanks.length; ++i) {
        blanks[i].parentNode.removeChild(blanks[i]);
      }
    }
  }
  get_overflow() {
    let overflow = 0;
    if (this.iframe && this.iframe.contentWindow) {
      let body = this.iframe.contentWindow.document.body;
      let pages = body.querySelectorAll("div.pagedjs_area");
      for (let i = 0; i < pages.length; ++i) {
        let page = pages[i];
        page.style.backgroundColor = null;
        let page_width = page.getBoundingClientRect().width;
        let page_right = page.getBoundingClientRect().right;
        let blocks = page.querySelectorAll("*[data-hederis-type^=hblk]");
        if (blocks.length > 0) {
          let last_block = blocks[blocks.length - 1];
          let last_block_width = last_block.getBoundingClientRect().width;
          let last_block_left = last_block.getBoundingClientRect().left;
          let my_text = last_block.textContent;
          if (((last_block_width > page_width) || (last_block_left > page_right)) && my_text.match("\S")) {
            overflow += 1;
            page.style.backgroundColor = "#FFE5E5";
          }
        }
      }
    }
    return overflow;
  }
  async componentDidUpdate(prevProps, prevState) {
    // console.log("did update frame css");
    this.update_body_lock_ui(this.props.isLocked);
    let [css_content, uioverridecss] = this.get_css();
    let [paged_css, non_paged_css] = css_content.split(
      `/* *******HEDERIS_SEPARATOR_NON_PAGED_BELOW******* */`
    );
    // this one doesn't exist from run() as we passed it to pagedjs
    // this.writeStylesDoc("paged_css", paged_css);
    // this one does
    let adjusted_custom_css = replace_css_image_urls(
      this.props.custom_css, 
      this.props.image_lookup
    );

    this.writeStylesDoc("non_paged_css", non_paged_css);

    // this.writeStylesDoc("advanced_config", this.get_css());

    let show_overrides = this.props.view_options.show_overrides
      ? uioverridecss
      : false;

    this.writeStylesDoc("view_opts", view_options_css(this.props.view_options, show_overrides));
    this.writeStylesDoc("ui_selection", ui_selection_css(this.props));
    this.writeStylesDoc("edit_ui_selection", edit_ui_selection_css(this.props));
    this.add_or_remove_ce();

    if (
      JSON.stringify(prevProps.advanced_config) != JSON.stringify(this.props.advanced_config)
    ) {
      let overflow = this.get_overflow();
      this.setState({ overflow });
    }
  }
  supress_browser_events(event) {
    // console.log(event);
    // console.log(event.ctrlKey, event.metaKey);
    // Should we supress more stuff? bold? carful not to break copy and paste!
    if (event.keyCode === 13) {
      event.preventDefault();
      // on enter, leave the edit mode for both types of edit mode!
      this.props.onViewChange({
        line_editor_mode: false,
        line_selected: "",
        content_editing: false,
      });
    }
    if (event.ctrlKey || event.metaKey) {
      if (event.keyCode === 66 || event.keyCode == 73) {
        event.preventDefault();
        console.log("preventing");
      }
    }
  }
  paste_adjust(event) {
    // prevent the normal event (which might include html!)
    event.preventDefault();
    // grab the text content even if the clipboard contains html
    var text = (event.originalEvent || event).clipboardData.getData(
      "text/plain"
    );
    // insert text where the cursor/selection is
    this.iframe.contentDocument.execCommand("insertHTML", false, text);
  }
  add_or_remove_ce() {
    // prettier-ignore
    if (!this.iframe || !this.iframe.contentWindow) { return }
    let new_changes = [];
    // here we'll use line selected instead of mode
    let should_remove =
      !this.props.content_editing && !this.props.line_selected;
    if (should_remove) {
      to_arr(
        this.iframe.contentDocument.querySelectorAll(`[contenteditable="true"]`)
      ).map(x => {
        x.removeEventListener("keydown", this.supress_browser_events);
        x.removeEventListener("paste", this.paste_adjust);
        x.removeAttribute("contenteditable");
        // Make the change obj here, for each element or line that was edited.
        let selection_obj = this.getParentInfo(x);
        let selection_path = [
          'data-id="' + selection_obj[0],
          'data-id="' + selection_obj[1],
        ];
        let new_change = track_change(
          selection_path,
          selection_obj[2],
          this.props.view_options.epub_mode,
          this.props.isLocked,
          this.props.hederis_user.uid
        );
        if (Object.keys(new_change).length > 0) {
          new_changes.push(new_change);
        }
      });
      // batching to setState, due to async nature of setState
      this.props.update_change_list(new_changes);
    } else {
      // if we're in line_editor_mode, use line_selected, otherwise use the normal sel
      let target_sel = this.props.line_editor_mode
        ? this.props.line_selected
        : this.props.selection_path[0];
      let targets = this.iframe.contentDocument.querySelectorAll(target_sel);
      to_arr(targets).map(target => {
        target.setAttribute("contenteditable", "true");
        target.addEventListener("keydown", this.supress_browser_events);
        target.addEventListener("paste", this.paste_adjust);
      });
    }
    // console.log("add_or_remove_ce");
    // console.log(this.props);
    // elemText.setAttribute("contenteditable", "true");
    // this.iframe.contentDocument.querySelector(".pagedjs_pages")
  }
  async setup() {
    // console.log("ss");
    // await this.create_img_url_cache();
    // console.log("after image cache setup...");
    if (this.iframe) {
      this.preview_ref.current.removeChild(this.iframe);
    }
    this.iframe = document.createElement("iframe");

    if (!this.preview_ref.current) {
      console.log("something went wrong, there is no iframe ref, returning");
      return;
    }
    this.preview_ref.current.appendChild(this.iframe);

    let doc = this.iframe.contentWindow.document;
    // we annoyingly must use this to fix the fact that the actual native domLoaded event for the iframe only fires the first time it loads
    this.iframe.contentWindow.HederisDOMContentLoaded = async () => {
      // console.log("readdy");
      await this.run();
      // tell the design editor we've loaded
      this.props.onInitialLoad();
    };
    // this.iframe.contentWindow.HederisReRenderOnOverflow = async () => {
    //   if (!this.props.content_editing && !this.props.line_editor_mode) {
    //     console.log('overflow re-run via frame!')
    //     this.run();
    //   } else {
    //     console.log("no doing overflow run because in one of the editor modes");
    //   }
    // };
    // use the our in-frame js to trigger this, this is range only!
    this.iframe.contentWindow.HederisPreviewClickForRange = rangeObject => {
      // right now this is just flagging whether or not we have a range selected to update the ui
      // we keep track of it in pageBreaking js
      // console.log("HederisPreviewClickForRange", rangeObject);
      if (!rangeObject.collapsed) {
        this.props.onRange(true);
        // get the id of the range parentnode
        if (this.getRangeParentInfo(rangeObject)["locked"] === true) {
          this.props.rangeLocked(true);
        } else {
          this.props.rangeLocked(false);
        }
        // this.props.onRange(current_range);
      } else {
        this.props.onRange(false);
      }
    };
    // let html_content = strip_html_bh(this.props.html_content);
    // let {html, css, js_cdn_arr, js}

    let content = make_html_page({
      js_cdn_arr: [PAGED_MAIN_CDN],
      js: RUN_PAGED + PAGE_BREAKING,
      css: font_loader(this.props.fonts_arr),
      render_env: "frontend",
    });

    doc.open();
    doc.write(content);
    doc.close();

    this.iframe.contentWindow.addEventListener("click", e => {
      if (this.props.onPreviewClick) {
        this.props.onPreviewClick(e);
      }
      let parents = to_arr(e.composedPath()).map(x => x.nodeName);
      if (parents.filter(x => x === "A").length) {
        console.log("preventing!");
        e.preventDefault();
      }
    });

    this.writeStylesDoc("view_opts", view_options_css(this.props.view_options));
  }

  getRangeParentInfo(range) {
    let obj = {
      "id": undefined,
      "parent_id": undefined,
      "section_id": undefined,
      "class": undefined,
      "locked": false
    };
    try {
      let startBlk = range.startContainer;
      let run = false;
      if (startBlk && startBlk.nodeType === 3) {
        startBlk = startBlk.parentNode;
      }
      if (
        startBlk &&
        (!startBlk.getAttribute("data-hederis-type") ||
          !startBlk.getAttribute("data-hederis-type").match("^hblk"))
      ) {
        startBlk = startBlk.closest("[data-hederis-type^=hblk]");
      }
      if (startBlk) {
        obj["id"] = startBlk.id;
        if (startBlk.parentNode) {
          obj["parent_id"] = startBlk.parentNode.id;
        }
        if (startBlk.closest("[data-hederis-type^=hsec]")) {
          obj["section_id"] = startBlk.closest("[data-hederis-type^=hsec]").id;
        }
        obj["class"] = startBlk.getAttribute("class");
        if (obj["class"].match(/(\b|\")baked(\b|\")/g)) {
          obj["locked"] = true;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return obj;
  }
  getParentInfo(el) {
    let blk_id = "";
    let parent_id = "";
    let section_id = "";
    let doc = this.iframe.contentDocument;
    let blk = el.closest("[data-hederis-type^=hblk]");
    if (blk) {
      blk_id = blk.getAttribute("data-id");
      let parent = blk.parentNode;
      let section = blk.closest("[data-hederis-type^=hsec]");
      if (parent) {
        parent_id = parent.getAttribute("data-id");
      }
      if (section) {
        section_id = section.getAttribute("data-id");
      }
    }
    return [blk_id, parent_id, section_id];
  }
  writeStylesDoc(id, css) {
    if (this.iframe && this.iframe.contentWindow) {
      writeStylesDoc(this.iframe.contentWindow.document, id, css);
    }
  }
  scrollToPage(n) {
    let num = parseInt(n);
    if (!isNaN(num)) {
      this.iframe.contentDocument
        .querySelector(`div[data-page-number="${n}"]`)
        // .scrollIntoView({ behavior: "smooth" });
        .scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
    }
  }

  async onRunLayoutClick() {
    // console.log(this.props.isLocked);
    // probs want to deal with epub mode too
    // normal case, just run the layout
    if (!this.props.isLocked) {
      await this.run();
    } else {
      // otherwise, if it's locked, per #577
      let doc = this.iframe.contentDocument;
      pre_layout_while_locked_proccess(doc);
      // then call this to load that into state in design editor
      // hacky, but we don't really provide a way to do this otherwise because html_content is a prop
      this.props.onInitialLoad(async () => {
        await this.run();
        post_layout_while_locked_process(doc);
        // this.props.onInitialLoad()
      });
    }
  }
  // toggleApproEdit() {
  //   console.log("toggleApproEdit");
  //   let is_edit_mode = this.isEditMode();

  render() {
    let { page_count, is_loading } = this.state;
    // current_range
    // console.log(this.props.range_locked );
    return (
      <Pane width="100%">
        {this.state.overflow > 0 && (
          <Pane display="flex" justifyContent="center" marginTop={4} marginBottom={4} paddingX={4}>
            <Text color="red">Text is overflowing on the highlighted pages. Press "Reflow Pages" to resolve.</Text>
          </Pane>
        )}
        <div ref={this.preview_ref} className="frameWrap" />
        {grid_image_loader}
      </Pane>
    );
  }
}

// for # 577, these operate directly on the iframe dom document
function pre_layout_while_locked_proccess(doc) {
  let lineswpbr = doc.querySelectorAll(".hspanpagebrafter");
  // IF it contains a hspanpagebrafter child, then leave the tag in place,
  // Otherwise, strip it.
  Array.from(lineswpbr).forEach(el => {
    let brattr = el.getAttribute("data-break-type");
    let linebrs = el.querySelectorAll("[data-break-type=hspanpagebrafter]");
    if (linebrs.length <= 0 && (!brattr || brattr != "hspanpagebrafter")) {
      if (el.getAttribute("data-auto-break") === "true") {
        el.replaceWith(...el.childNodes);
        el.parentNode.normalize();
      } else {
        el.className = el.className
          .replace(/\bhspanpagebrafter\b/g, " ")
          .trim();
      }
    }
  });

  let pagebrs = doc.querySelectorAll(
    "[data-break-type=hspanpagebrafter]:not([data-hederis-type^=hblk])"
  );

  Array.from(pagebrs).forEach(el => {
    // see if the parent is a line with the pagebr class
    let mypar = el.parentNode;
    if (mypar.nodeType === 3) {
      mypar = mypar.parentNode;
    }
    if (
      mypar &&
      (!mypar.getAttribute("data-hederis-type") ||
        !mypar.getAttribute("data-hederis-type").match("^hblk"))
    ) {
      mypar = mypar.closest("[data-hederis-type^=hblk]");
    }
    if (
      mypar &&
      mypar.querySelectorAll("span.line.hspanpagebrafter").length <= 0
    ) {
      if (!el.className.match("hspanpagebrafter")) {
        el.classList.add("hspanpagebrafter");
      }
    }
  });

  let linebrs = doc.querySelectorAll(
    "[data-break-type=hspanbrafter]:not([data-hederis-type^=hblk])"
  );

  Array.from(linebrs).forEach(el => {
    // see if the parent is a line with the pagebr class
    let mypar = el.parentNode;
    if (mypar.nodeType === 3) {
      mypar = mypar.parentNode;
    }
    if (
      mypar &&
      (!mypar.getAttribute("data-hederis-type") ||
        !mypar.getAttribute("data-hederis-type").match("^hblk"))
    ) {
      mypar = mypar.closest("[data-hederis-type^=hblk]");
    }
    if (mypar && mypar.querySelectorAll("span.line.hspanbrafter").length <= 0) {
      if (!el.className.match("hspanbrafter")) {
        el.classList.add("hspanbrafter");
      }
    }
  });

  let paras = doc.querySelectorAll(".bakedPageBreakAfter");

  Array.from(paras).forEach(el => {
    let brtype = el.getAttribute("data-break-type");
    if (!brtype || brtype != "wholepara") {
      el.className = el.className
        .replace(/\bbakedPageBreakAfter\b/g, " ")
        .trim();
    }
  });

  let parasnobr = doc.querySelectorAll(".avoidbreaks");

  Array.from(parasnobr).forEach(el => {
    el.classList.remove("avoidbreaks");
  });
}

function post_layout_while_locked_process(doc) {
  // Get the last blk element on each page.
  // If it's a split paragraph, get its last child.
  // If it's a whole element, return it.
  let pages = doc.querySelectorAll("div.pagedjs_page_content > div");

  Array.from(pages).forEach(el => {
    let paras = el.querySelectorAll("*[data-hederis-type^=hblk]");
    let lc = paras[paras.length - 1];

    if (lc) {
      // see if the blk is split or continued
      if (
        (lc.getAttribute("data-split-to") &&
          lc.getAttribute("data-split-to") != "") ||
        (lc.getAttribute("data-split-from") &&
          lc.getAttribute("data-split-from") != "")
      ) {
        // get the last child
        let kids = lc.childNodes;
        let lastChild = kids[kids.length - 1];
        if (lastChild.nodeType === 1) {
          if (!lastChild.className.match(/hspanpagebrafter/g)) {
            lastChild.classList.add("hspanpagebrafter");
          }
        } else {
          // create a range around the last child
          var r = document.createRange();
          // count backwards to find a space character
          var run = true;
          var pos = lastChild.textContent.length - 1;
          while (run && pos >= 0) {
            if (lastChild.textContent[pos]) {
              if (lastChild.textContent[pos].match(/\s/g)) {
                pos = pos + 1;
                run = false;
              } else {
                pos = pos - 1;
              }
            } else {
              pos = pos - 1;
              run = false;
            }
          }
          if (pos) {
            r.setStart(lastChild, pos);
          } else {
            r.setStart(lastChild, 0);
          }
          r.setEndAfter(lastChild);
          // create the pagebreak span
          let newNode = document.createElement("span");
          let currID = "t" + (((1 + Math.random()) * 0x10000) | 0).toString(16);
          newNode.id = currID;
          newNode.classList.add("hspanpagebrafter");
          newNode.setAttribute("data-auto-break", "true");
          let documentFragment = range.extractContents();
          newNode.appendChild(documentFragment);
          range.insertNode(newNode);
        }
      } else {
        if (!lc.className.match(/bakedPageBreakAfter/g)) {
          lc.classList.add("bakedPageBreakAfter");
        }
        lc.setAttribute("data-auto-break-block", "true");
      }
    }
  });

  // add p.bakedPageBreakAfter
  // This actually happens in run_paged.
  // let paras = doc.querySelectorAll("*[data-hederis-type^=hblk] > .hspanpagebrafter");

  // Array.from(paras).forEach(el => {
  //   el.parentNode.classList.add("bakedPageBreakAfter");
  // });

  // add avoidbreaks
  let nobreaks = doc.querySelectorAll(
    "div.pagedjs_page_content > div > [data-hederis-type^=hsec] > *[data-hederis-type^=hblk]:not(.continued):not([data-split-to])"
  );

  Array.from(nobreaks).forEach(el => {
    el.classList.add("avoidbreaks");
  });
}

// this just loads the grid image so we don't have to load it on demand, which looks a lot like it not working
const grid_image_loader = (
  <div
    style={{
      width: "1px",
      height: "1px",
      backgroundImage:
        'url("https://s3-us-west-2.amazonaws.com/hederis-assets-extra/grid.jpg")',
    }}
  />
);

function edit_ui_selection_css(props) {
  let is_an_edit_mode = props.line_editor_mode || props.content_editing;
  // console.log(is_an_edit_mode);
  let res = "";
  let hover_colors = ["rgba(0,20,210,0.15)", "rgba(0,20,210,0.6)"];
  let colors = ["rgba(70,255,130,0.45)", "rgba(70,255,130,0.9)"];

  if (!is_an_edit_mode) {
    return res;
  }

  if (props.line_editor_mode) {
    res += `
    ${props.line_selected} {
      background-color: ${colors[0]} !important; box-shadow: 0px 0px 3px ${
      colors[1]
    };  }

    .line:hover {  cursor: pointer; background-color: ${
      hover_colors[0]
    } ; box-shadow: 0px 0px 3px ${hover_colors[1]};  }
    `;
  } else if (props.content_editing) {
    // this one is just the hover, the selection state is dealt with
    res += `
    .pagedjs_page_content > div > * > *:hover {  cursor: pointer; background-color: ${
      hover_colors[0]
    } ; box-shadow: 0px 0px 3px ${hover_colors[1]};  }
    `;
  }
  return res;
}
function ui_selection_css(props) {
  if (!props.selection_path.length) {
    return ``;
  }
  let target = props.selection_path[0];
  let parent = props.selection_path[1];
  let sel;
  if (target === "span.line") {
    target = props.selection_path[1];
    parent = props.selection_path[2];
  }
  if (props.selection_mode === "only") {
    // sel = target;
    // console.log(props);
    let target_id = parse_id(target);
    sel = `${target}`;
    // using the actual split-from would be more proper but we're not currently storing that in selection_path array
    // so just using the data-id for now which works
    // sel += target_id ? `, [data-split-from="${target_ref}"]` : ``;
  } else if (props.selection_mode === "all") {
    sel = `[data-hederis-type="${parse_hederis_type(target)}"]`;
  } else if (props.selection_mode === "within_parent") {
    // prettier-ignore
    sel = `[data-hederis-type="${parse_hederis_type(parent)}"] [data-hederis-type="${parse_hederis_type(target)}"]`;
  } else if (props.selection_mode === "direct_child") {
    // prettier-ignore
    sel = `[data-hederis-type="${parse_hederis_type(parent)}"] > [data-hederis-type="${parse_hederis_type(target)}"]`;
  } else if (props.selection_mode == "first_within_parent") {
    // prettier-ignore
    sel = `[data-hederis-type="${parse_hederis_type(parent)}"] [data-hederis-type="${parse_hederis_type(target)}"]:first-of-type`;
    // XXX the below one uses the data type as a cssclass instead because it's pretty bleeding edge of the spec (pseduos on data type)
    // though the above works on chrome the same as the below, ie somewhat
    // prettier-ignore
    // sel = `[data-hederis-type="${parse_hederis_type(parent)}"] .${parse_hederis_type(target)}:first-of-type`;
  } else if (props.selection_mode == "following") {
    // prettier-ignore
    sel = `[data-hederis-type="${props.prev_sibling_type_sel}"] + [data-hederis-type="${parse_hederis_type(target)}"]`;
  }

  // console.log(sel);
  let colors = !props.content_editing
    ? ["rgba(0,20,210,0.15)", "rgba(0,20,210,0.6)"]
    : ["rgba(70,255,130,0.45)", "rgba(70,255,130,0.9)"];
  let res = `
  ${sel} {
      background-color: ${colors[0]} !important;
      box-shadow: 0px 0px 3px ${colors[1]};  }`;
  // and finally for highlighting the node the user clicked in all mode (and we'd use for "only")
  if (!props.content_editing && props.selection_mode === "all") {
    res += ` ${target} {
      background-color: rgba(0,20,210,0.4) !important;
        box-shadow: 0px 0px 3px rgba(0,20,210,0.8);
    }`;
  }
  return res;
}

// .textmanipulation
// span.textmanipulation.hspanbrafter
// .continued .continuation

function to_arr(arr_like) {
  return Array.prototype.slice.call(arr_like);
}
