import React from "react";
import ReactDOM from "react-dom";
// prettier-ignore
import {Pane, Text, Code, Heading, Button, TextInputField, TabNavigation, Tab, SidebarTab, Tablist, Link, toaster, IconButton, Icon, Small, TextInput, RadioGroup, Select, Strong, Checkbox, Combobox, TagInput, Portal} from "evergreen-ui";

// import { firebase, db, storage } from "../../fire.js";
// prettier-ignore
// import { parse_dim, camelCaseToDash, get_label_from_key } from "../../helpers/util.js";

// prettier-ignore
import { 
  PageMarginsInputWrap,
  NumericalUnitsInputWithInherit, 
  NumericalUnitsInputWithNormal, 
  FolioStyleInput, 
  FolioStartInputAdvanced, 
  PageStartInput, 
  BlockSpacingInputWrap, 
  SimpleTextInput, 
  BorderRadiusInput, 
  BorderInput, 
  PageChoiceInput, 
  CheckWrap, 
  FontFamilyInput, 
  WeightInput, 
  ItalicStyleInput, 
  TextTransformInput, 
  PositionInput, 
  KeepOptionsInput, 
  ListStyleInput, 
  VerticalAlignInput, 
  ColorPicker, 
  AlignmentInput, 
  NumericalUnitsInput, 
  NumericalUnitsInputWithAuto, 
  NumericalInput, 
  RunContentInput, 
  SpaceBreakContentInput, 
  FloatBottomInput, 
  DropCapInput, 
  PreserveWSInput, 
  DisplayInput, 
  UnderlineTypeInput, 
  UnderlineStyleInput, 
  OTInput, 
  BackgroundImgInput, 
  BackgroundRepeatInput, 
  LanguageInput, 
  AllowHyphensInput, 
  ImportanceInput, 
  FloatInput,
  ColumnRuleStyleInput,
  ColumnSpanInput
} from "../shared/tb_inputs.js";

// console.log(ALL_TYPES);

// prettier-ignore
export function sub_key_lookup(key){
  if (key.includes('_OT')) { return 'hidden'}
  else if (key.startsWith('master__') && (key.includes("runhead")|| key.includes("runfoot") || key.includes("_folio_") || key.includes("_first"))) { return 'runs'}
  else if (key.includes('dropcap')) { return 'dropcap'}
  else if (key.includes('beforeFont')) { return 'content'}
  else if (key.includes('afterFont')) { return 'content'}
  else if (key.includes('font')) { return 'font'}
  else if (key.includes('Font')) { return 'font'}
  else if (key.includes('textColor')) { return 'font'}
  else if (key.includes('changeCase')) { return 'font'}
  else if (key.includes('verticalAlign')) { return 'font'}
  else if (key.includes('positionTop')) { return 'font'}
  else if (key.includes('baselineShift')) { return 'font'}
  else if (key.includes('Content')) { return 'content'}
  else if (key.includes('content')) { return 'content'}
  else if (key.includes('stringSet')) { return 'content'}
  // maybe these three should be there own?
  else if (key.includes('textAlign')) { return 'font'}
  else if (key.includes('textIndent')) { return 'font'}
  else if (key.includes('letterSpacing')) { return 'font'}
  else if (key.includes('lineHeight')) { return 'font'}
  else if (key.includes('Alignment')) { return 'font'}
  else if (key.includes('_bodySize')) { return 'font'}
  else if (key.includes('_floatBottom')) { return 'hidden'}
  else if (key.includes('_preserveWS')) { return 'font'}
  else if (key.includes('listStyleType')) { return 'font'}
  else if (key.includes('minLeft') || key.includes('minRight')) { return 'font'}
  else if (key.includes('hyphenationLanguage')) { return 'font'}
  else if (key.includes('exceptions')) { return 'font'}
  else if (key.includes('allowHyphens')) {return 'font'}
  else if (key.includes('openType')) { return 'opentype'}

  else if (key.includes('background')) { return 'background'}

  else if (key.includes('width')) { return 'dims'}
  else if (key.includes('height')) { return 'dims'}
  else if (key.endsWith('_float')) { return 'dims'}
  else if (key.includes('_display')) { return 'dims'}
  else if (key.includes('folioStyle')) { return 'dims'}

  else if (key.includes('folioStart')) { return 'general'}
  else if (key.includes('pageStart')) { return 'general'}
  else if (key.includes('_page')) { return 'general'}

  else if (key.includes('backgroundColor')) { return 'border&fill'}
  else if (key.endsWith('center')) { return 'spacing'}
  else if (key.includes('avoidBreaks')) { return 'spacing'}
  else if (key.includes('spaceBelow')) { return 'spacing'}
  else if (key.includes('spaceAbove')) { return 'spacing'}
  else if (key.includes('marginTop')) { return 'spacing'}
  else if (key.includes('marginBottom')) { return 'spacing'}
  else if (key.includes('padding')) { return 'spacing'}
  else if (key.includes('outsidePadding')) { return 'spacing'}
  else if (key.includes('insidePadding')) { return 'spacing'}
  else if (key.includes('margins')) { return 'spacing'}
  else if (key.includes('outsideMargin')) { return 'spacing'}
  else if (key.includes('insideMargin')) { return 'spacing'}
  else if (key.includes('footnoteMargin')) { return 'spacing'}
  else if (key.includes('position')) { return 'dims'}
  else if (key.includes('marginExtent')) { return 'dims'}
  else if (key.includes('underline')) { return 'border&fill'}
  else if (key.includes('backgroundColor')) { return 'border&fill'}
  else if (key.includes('border')) { return 'border&fill'}
  else if (key === "master__verso_margins" || key === "master__recto_margins") { return 'spacing'}

  else if (key.includes('importance')) { return 'importance'}

  else if (key.includes('column')) { return 'columns'}
  
  else { return key }
}

export function get_sub_grouping(active_keys) {
  let res = {};
  active_keys.forEach(key => {
    let sub = sub_key_lookup(key);
    if (res[sub]) {
      res[sub].push(key);
    } else {
      res[sub] = [key];
    }
    // res[sub] = key;
  });
  return res;
  // return Object.keys(res);
  // return Object.keys(res);
}

// pass true as second to drop the first token, the one you're grouping by
export function build_grouping_by_underscore(config_keys, drop = false) {
  let res = {};
  config_keys.forEach(s => {
    let [first, ...others] = s.split("_");
    // XXX not tried
    // first = first || "_";
    // drop=true drops the first
    let to_push = drop ? others.join("_") : s;
    if (res[first]) {
      res[first].push(to_push);
    } else {
      res[first] = [to_push];
    }
  });
  return res;
}

// this is the root logic of the advanced template_creator  XXX
export function get_appropriate_comp(input_key) {
  // console.log("get_appropriate_comp");
  // console.log(input_key);
  let last = input_key.split("_").pop();
  // console.log(last);
  if (
    last.endsWith("Font") ||
    last.endsWith("font") ||
    last.endsWith("FontFamily")
  ) {
    return FontFamilyInput;
  } else if (last.endsWith("changeCase")) {
    return TextTransformInput;
  } else if (last.endsWith("position")) {
    return PositionInput;
  } else if (last.endsWith("avoidBreaks")) {
    return KeepOptionsInput;
  } else if (last.endsWith("listStyleType")) {
    return ListStyleInput;
  } else if (last.endsWith("verticalAlign")) {
    return VerticalAlignInput;
  } else if (last.endsWith("positionTop")) {
    return NumericalUnitsInputWithAuto;
  } else if (last.endsWith("baselineShift")) {
    return NumericalUnitsInputWithAuto;
  } else if (["page_width", "page_height"].includes(input_key)) {
    return NumericalUnitsInput;
  } else if (last.includes("size") || last.includes("Size")) {
    // return NumericalUnitsInput;
    return NumericalUnitsInputWithInherit;
  } else if (last == "backgroundWidth" || last == "backgroundHeight") {
    return NumericalUnitsInputWithAuto;
  } else if (last.includes("Indent") || last.includes("Height")) {
    return NumericalUnitsInputWithInherit;
    // return NumericalUnitsInput;
  } else if (last.includes("letterSpacing")) {
    return NumericalUnitsInputWithNormal;
  } else if (last === "spacebreakContent") {
    return SpaceBreakContentInput;
  } else if (last === "width" || last === "height") {
    // per #214
    return NumericalUnitsInputWithAuto;
  } else if (last.endsWith("minLeft") || last.endsWith("minRight")) {
    return NumericalInput;
  } else if (last.endsWith("hyphenationLanguage")) {
    return LanguageInput;
  } else if (last.endsWith("exceptions")) {
    return SimpleTextInput;
  } else if (last.endsWith("allowHyphens")) {
    return AllowHyphensInput;
  } else if (last.endsWith("Above") || last.endsWith("Below")) {
    return NumericalUnitsInput;
  } else if (last.endsWith("Weight")) {
    return WeightInput;
  } else if (last.endsWith("fontStyle") && last !== "folioStyle") {
    return ItalicStyleInput;
  } else if (last.endsWith("Alignment")) {
    return AlignmentInput;
  } else if (last.endsWith("textAlign") || last.endsWith("textAlignLast")) {
    return AlignmentInput;
    // } else if (last.endsWith("floatBottom")) {
    //   return FloatBottomInput;
  } else if (last.endsWith("preserveWS")) {
    return PreserveWSInput;
  } else if (last.endsWith("headContent") || last.endsWith("footContent")) {
    return RunContentInput;
  } else if (last.endsWith("marginTop") || last.endsWith("marginBottom")) {
    return NumericalUnitsInput;
  } else if (last.endsWith("outsideMargin") || last.endsWith("insideMargin")) {
    return NumericalUnitsInputWithAuto;
  } else if (last.endsWith("outsidePadding") || last.endsWith("insidePadding")) {
    return NumericalUnitsInput;
  } else if (last.endsWith("marginExtent")) {
    return NumericalUnitsInput;
  } else if (last == "width" || last == "height") {
    return NumericalUnitsInput;
  } else if (last.endsWith("display")) {
    return DisplayInput;
  } else if (last.endsWith("float")) {
    return FloatInput;
  } else if (last.endsWith("underlineType")) {
    return UnderlineTypeInput;
  } else if (last.endsWith("underlineStyle")) {
    return UnderlineStyleInput;
  } else if (last.endsWith("stringSet")) {
    return SimpleTextInput;
    // should this one be runcontent input ? XXX
  } else if (last.endsWith("content")) {
    // return SimpleTextInput;
    return RunContentInput;
  } else if (last.endsWith("dropcap")) {
    return DropCapInput;
  } else if (last.endsWith("FontUrl") || last.endsWith("fontUrl")) {
    return false;
  } else if (last === "page") {
    return PageChoiceInput;
  } else if (last === "borderRadius") {
    return BorderRadiusInput;
  } else if (last.startsWith("border")) {
    return BorderInput;
  } else if (last.endsWith("Color")) {
    return ColorPicker;
  } else if (last.endsWith("backgroundImage")) {
    return BackgroundImgInput;
  } else if (last.endsWith("backgroundRepeat")) {
    return BackgroundRepeatInput;
  } else if (last.endsWith("backgroundPositionX") || last.endsWith("backgroundPositionY")) {
    return SimpleTextInput;
  } else if (last.endsWith("openType")) {
    return OTInput;
  } else if (last.endsWith("folioStyle")) {
    return FolioStyleInput;
  } else if (last.endsWith("folioStart")) {
    return FolioStartInputAdvanced;
  } else if (last.endsWith("pageStart")) {
    return PageStartInput;
    // } else if (last.endsWith("margins")) {
  } else if (last.endsWith("importance")) {
    return ImportanceInput;
  } else if (last.endsWith("columnCount")) {
    return NumericalInput;
  } else if (last.endsWith("columnWidth")) {
    return NumericalUnitsInputWithAuto;
  } else if (last.endsWith("columnGap")) {
    return NumericalUnitsInputWithNormal;
  } else if (last.endsWith("columnDivider")) {
    return ColumnRuleStyleInput;
  } else if (last.endsWith("columnDividerWidth")) {
    return NumericalUnitsInput;
  } else if (last.endsWith("columnDividerColor")) {
    return ColorPicker;
  } else if (last.endsWith("columnSpan")) {
    return ColumnSpanInput;
  } else if (last.endsWith("footnoteMargin")) {
    return NumericalUnitsInput;
  } else if (
    (input_key.includes("verso") || input_key.includes("recto")) &&
    last.endsWith("margins")
  ) {
    // (last.endsWith("margins") || last.endsWith("padding"))
    return PageMarginsInputWrap;
  } else if (
    last.endsWith("margins") ||
    last.endsWith("padding") ||
    last.endsWith("Padding")
  ) {
    return BlockSpacingInputWrap;
  } else {
    return () => ``;
    // fallaback for now
    // console.log("FALLBACK! Not found:", input_key);
    return props => (
      <Pane background="redTint">
        <Text>
          <Code>{input_key}</Code>
          {/*{JSON.stringify(props)}*/}
        </Text>
      </Pane>
    );
  }
}
