import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
// prettier-ignore
import {Pane, Position, Tooltip, Text, Heading, Paragraph, Button, Badge, TextInputField, TabNavigation, Tab, SidebarTab, Tablist, Link, toaster, IconButton, Icon, Small, TextInput, RadioGroup, Select, Strong, Checkbox, Combobox, TagInput, Portal} from "evergreen-ui";
import Frame from "./frame.js";
import LayoutControl from "./layout_control.js";
import HedButton from "../shared/HedButton.js";
import { LoadingPane, ProjectTabs, NotFound } from "../shared/common.js";
import { human_adv_template_label } from "../../helpers/util.js";
import { digestMessage, sanitizeHtml } from "../../helpers/util.js";
import { ActivityWidget } from "../shared/activity.js";

import { reconcile_content_and_config, prep_html_for_digest_hash } from "./app_advanced.js";

export default function SaveDesignWidget(props) {
  const [contentEqual, setContentEqual] = useState(true);
  const [configEqual, setConfigEqual] = useState(true);
  const mountedRef = useRef(true);

  const saveFromDesignPreview = async () => {
    if (!contentEqual) {
      await props.onDesignHtmlSave();
    }
    if (!configEqual) {
      await props.onDesignConfigSave();
    }
  }

  useEffect(
    () => {
      if (!mountedRef.current) return null;
      const run = async () => {
        if (props.sanitized_html_content) {
          // console.log(props.sanitized_html_content);
          const cur_html_hash = await digestMessage(props.sanitized_html_content);
          const is_content_equal = props.last_loaded_html_hash ? props.last_loaded_html_hash === cur_html_hash : true;
          setContentEqual(is_content_equal);
        } else {
          setContentEqual(true);
        }

        if (props.sanitized_config) {
          const cur_conf_hash = await digestMessage(
            JSON.stringify(props.sanitized_config)
          );
          const is_conf_equal = props.last_loaded_conf_hash ? props.last_loaded_conf_hash === cur_conf_hash : true;
          setConfigEqual(is_conf_equal);
        } else {
          setConfigEqual(true);
        }
      };
      run();
    },
    [props.sanitized_html_content, props.sanitized_config]
  );

  useEffect(() => {
    return () => { 
      mountedRef.current = false;
    }
  }, []);

  const saveLabel = !contentEqual && !configEqual
    ? ["both","text and design"]
    : contentEqual && !configEqual
    ? ["config","design"]
    : configEqual && !contentEqual
    ? ["content","text"]
    : ["none","none"];

  const editStateChanges = {
    line_editor_mode: false,
    line_selected: "",
    content_editing: false,
    selection_path: [],
    selection_mode: "all",
  };

  return (
    <Pane display="flex" flexDirection="row" alignItems="center">
      <LayoutControl 
        onRunLayoutClick = {props.onRunLayoutClick}
        is_loading={props.is_loading} 
        is_saving={props.is_saving} 
        show_spinner={props.show_spinner}
      />
      <HedButton
        id={`design-save-${saveLabel[0]}`}
        appearance={!contentEqual || !configEqual ? "hedprimary" : "hedunfocus"}
        onClick={() => saveFromDesignPreview()}
        marginX={4}
      >
        Save
      </HedButton>
      <HedButton 
        onClick={() => isEditMode(props) ? props.onEditDiscard(editStateChanges) : props.onCancel()} marginRight={8}
        appearance={!contentEqual || !configEqual ? "hedprimary" : "hedunfocus"}
      >
        Cancel
      </HedButton>
      <ActivityWidget {...props} />
    </Pane>
  );
}

function isEditMode(props) {
  // prettier-ignore
  let { isLocked, content_editing, line_editor_mode, range_locked, current_range } = props;

  // the simple case, we're not even locked
  if (!isLocked) {
    return content_editing;
  } else {
    return line_editor_mode;
  }
}
