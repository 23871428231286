import React from "react";
// prettier-ignore
import {Pane, Text, Heading, Button, TextInputField, TabNavigation, Tab, SidebarTab, Tablist, Link, toaster, IconButton, Icon, Small, TextInput, RadioGroup, Select, Strong, Checkbox, Combobox, TagInput, Portal} from "evergreen-ui";

import { firebase, db, storage } from "../../fire.js";
// prettier-ignore
import { parse_dim, camelCaseToDash, get_label_from_key } from "../../helpers/util.js";

import { ExpandablePane } from "../shared/common.js";
// prettier-ignore
import { PageMarginsInputWrap, CheckWrap, FontFamilyInput, WeightInput, ItalicStyleInput, ColorPicker, AlignmentInput, NumericalUnitsInput, NumericalInput, RunContentInput, SpaceBreakContentInput } from "../shared/tb_inputs.js";

import template_builder_defaults from "../../../api/app_shared/defaults/template_builder_defaults.json";

import ALL_FONTS_ARR from "../../../api/app_shared/fonts/fonts.json";
const ALL_FONT_NAMES = ALL_FONTS_ARR.map(x => x["display-name"]);

// these are just presentational for the forms, to make things line up, and should be refactored out
let GW = { minWidth: 300, maxWidth: 500 };
let GW2 = { minWidth: GW.minWidth / 2, maxWidth: GW.maxWidth / 2 };

let copy = x => JSON.parse(JSON.stringify(x));
// prettier-ignore
const ui_grouping = {
  "General Page Setup": ["page", "pageStart"],
  "Page Margins": ["verso", "recto"],
  "Margin Text Formatting": ["runhead", "runfoot"],
  "Main Text Formatting": ["body", "ebook"],
  "Titlepage Text Formatting": ["halftitlepage","titlepage"],
  "Frontmatter": ["frontmatter"],
  "Part": ["part"],
  "Backmatter": ["backmatter"],
}

export default class TemplateBuilder extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    // let active_ui = this.props.initial_active_ui;
    let active_ui = "General Page Setup";
    // let active_ui = "Page Margins";
    // this.state = { config, active_ui };
    this.state = { active_ui };
  }
  async componentDidMount() {
    //
  }
  onChange({ input_key, value }) {
    // console.log(this.state.config[input_key]);
    // console.log("on change -", input_key, value);
    let { config } = this.props;
    config[input_key] = value;
    this.props.onChange(config);
  }

  render() {
    // console.log("render");
    let { config } = this.props;
    let config_keys = Object.keys(config);
    let top_level_keys = ui_grouping[this.state.active_ui];

    let top_level_panes = Array.from(top_level_keys).map(top_key => {
      let inputs = config_keys
        .filter(x => x.startsWith(`${top_key}_`) || x === top_key)
        .map(input_key => {
          // console.log(input_key, config[input_key]);
          // get the appropriate comp for each input
          let Comp = get_appropriate_comp(input_key);
          return (
            <Pane key={input_key}>
              <Comp
                value={config[input_key]}
                input_key={input_key}
                onChange={this.onChange}
                def={template_builder_defaults[input_key]}
                GW={GW}
                GW2={GW2}
              />
            </Pane>
          );
        });
      // and now return each top levle pane as part of the outer map
      return (
        <Pane key={top_key} marginY={16} border padding={8}>
          <Heading textTransform="capitalize" size={600}>
            {get_label_from_key(top_key)}
          </Heading>
          {inputs}
        </Pane>
      );
    });

    let ui_nav = (
      <Tablist marginY={16} marginLeft={8} flexBasis={240} marginRight={24}>
        {Object.keys(ui_grouping).map(g => {
          return (
            <SidebarTab
              height={40}
              key={g}
              isSelected={g === this.state.active_ui}
              onSelect={() => this.setState({ active_ui: g })}
            >
              {g}
            </SidebarTab>
          );
        })}
      </Tablist>
    );

    // and finally
    return (
      <Pane>
        <Pane display="flex" flexDirection={"row"}>
          {ui_nav}

          <Pane marginX="auto" marginY={8} paddingX={8}>
            {top_level_panes}
          </Pane>
        </Pane>
      </Pane>
    );
  }
}

// width="70vw"
// minWidth="500px"

function get_appropriate_comp(input_key) {
  // console.log("get_appropriate_comp");
  // console.log(input_key);
  let last = input_key.split("_").pop();
  // console.log(last);
  if (last.endsWith("Font") || last.endsWith("font")) {
    return FontFamilyInput;
  } else if (last.includes("size") || last.includes("Size")) {
    return NumericalUnitsInput;
  } else if (last.includes("Indent") || last.includes("Height")) {
    return NumericalUnitsInput;
  } else if (last === "spacebreakContent") {
    return SpaceBreakContentInput;
  } else if (last.endsWith("Above") || last.endsWith("Below")) {
    return NumericalInput;
  } else if (last.endsWith("Weight")) {
    return WeightInput;
  } else if (last.endsWith("Style") && last !== "folioStyle") {
    return ItalicStyleInput;
  } else if (last.endsWith("Alignment")) {
    return AlignmentInput;
  } else if (last.endsWith("headContent") || last.endsWith("footContent")) {
    return RunContentInput;
  } else if (last.endsWith("Color")) {
    return ColorPicker;
  } else if (["page_width", "page_height"].includes(input_key)) {
    return NumericalUnitsInput;
  } else if (last.endsWith("folioStyle")) {
    // folioStart
    return props => {
      return (
        <CheckWrap
          {...props}
          checked_value="lower-roman"
          unchecked_value="decimal"
          label="Use lower-roman pagenumbers in the frontmatter?"
        />
      );
    };
  } else if (last.endsWith("folioStart")) {
    // XXX this defines the element, in the render effectively, so it will lose refs, ie focus
    // which doesn't matter for these for rn, but they should all be switched over as that caused the pagemargininput problem
    return props => {
      return (
        <CheckWrap
          {...props}
          checked_value="page 1"
          unchecked_value=""
          label="Restart page numbering at 1 after the frontmatter?"
        />
      );
    };
  } else if (last.endsWith("pageStart")) {
    return props => {
      let { onChange, value, input_key } = props;
      return (
        <Checkbox
          checked={value === "right"}
          onChange={e => {
            let value = e.target.checked ? "right" : "always";
            onChange({ input_key, value });
          }}
          label="Always start new chapters on a recto?"
        />
      );
    };
  } else if (last.endsWith("margins")) {
    return PageMarginsInputWrap;
  } else {
    // fallaback for now
    console.log("FALLBACK", input_key);
    return props => (
      <Pane background="redTint">
        <Text>
          {input_key} ---- {props.input_key} <br /> {JSON.stringify(props)}
        </Text>
      </Pane>
    );
  }
}
