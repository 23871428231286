import React from "react";
import ReactDOM from "react-dom";
// prettier-ignore
import { Pane, Heading, Paragraph, Text, Strong } from "evergreen-ui";

// import TopWidget from "./top_widget.js";
import { firebase, db, storage } from "../../fire.js";

import { get_human_h_type } from "../../helpers/util.js";

export default class FontWarningsView extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let { font_warnings, section_meta } = this.props;

    // sort the warnings by PDF page
    let sorted = font_warnings.sort(function(p1, p2){
      return p1.page - p2.page;
    });

    let warnings = font_warnings.map(function(obj, index) {
      let hasSection = obj.hasOwnProperty('section') 
        && section_meta.hasOwnProperty(obj['section']);
      let hasIndex = hasSection
        && section_meta[obj['section']].hasOwnProperty("index")

      return (
        <Pane marginBottom={16} key={`${obj["id"]}${index.toString()}`}>
          <Paragraph>
            <Strong>PDF page: {obj['page']}</Strong>
          </Paragraph>
          <Paragraph>
            <Strong>Warning:</Strong> {obj['warning']}{` `}
            {obj.hasOwnProperty("info") ? (
              `(${obj['info']['familyName']})`
            ) : (
              ``
            )}
          </Paragraph>
          <Paragraph>
            <Strong>Location: </Strong>
            Section
            {hasIndex ? (
              ` #${section_meta[obj['section']]['index']}:`
            ) : (
             ""
            )}
            {hasSection ? (
              ` ${section_meta[obj['section']].title}`
            ) : (
              ``
            )}
          </Paragraph>
          <Paragraph>
            Occuring in paragraph or element{` `}
            "{get_human_h_type(obj['dataType'])}"{` `}
            that begins "{obj['text']}"
          </Paragraph>
          {obj.hasOwnProperty("info") ? (
            <Paragraph>
              <Strong>Number of characters affected:{` `}</Strong>
              {obj['info']['glyphCount']}
            </Paragraph>
          ) : (
            ``
          )}
        </Pane>
      );
    });

    return (
      <Pane padding={8} height="60vh" overflowY="scroll">
        <Paragraph>There were some warnings when building 
        your PDF. This could mean several things.</Paragraph>
        <Paragraph marginBottom={16}>
        <a 
          href="https://docs.hederis.com/docs/font-warnings/" 
          target="_blank" 
          id="docs-link"
        >
          <Text color="selected">Learn more here</Text>
        </a>
      </Paragraph>
        {warnings}
      </Pane>
    );
  }
}
