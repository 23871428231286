import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
// prettier-ignore
import { Pane, Code, Popover, TextInput, Button, Text, IconButton, TrashIcon, PlusIcon, TickCircleIcon } from "evergreen-ui";

// NOTE: This group of imports come from files that
// are created automatically from the hederis core project;
// if you need to make any edits, please edit in that repo 
// and then redistribute as appropriate.
// BEGINGROUP--------
// prettier-ignore
import { content_to_el } from "./shared/util";
// ENDGROUP--------

export function AttributePopoverSection(props) {
  const { section } = props;
  const onDoUpdate = html => {
    props.operations.sectionReplace(section.id, html);
  };
  // this is *above* the attrs state we create from it, so we don't create doc frags on every keystroke
  const initialAttrArr = get_attr_array_from_html(section.content);
  return (
    <AttributePopoverInner
      {...props}
      initialAttrArr={initialAttrArr}
      content={section.content}
      onDoUpdate={onDoUpdate}
      isSection={true}
    />
  );
}

export function AttributePopoverElement(props) {
  const { item, section_id } = props;
  const { content } = item;
  const onDoUpdate = html => {
    props.operations.elementReplace(section_id, item.id, html);
  };
  // console.log(section);
  // this is *above* the attrs state we create from it, so we don't create doc frags on every keystroke
  const initialAttrArr = get_attr_array_from_html(content);
  return (
    <AttributePopoverInner
      {...props}
      initialAttrArr={initialAttrArr}
      content={content}
      onDoUpdate={onDoUpdate}
      isSection={false}
    />
  );
}

export function AttributePopoverInline(props) {
  const { activeInline, section_id } = props;

  // XXX this one works differently than the others, modifying inplace in the dom
  const onDoUpdate = html => {
    // console.log(html);
    // this works except for spans we've JUST added
    // props.operations.elementReplace(section_id, activeInline, html);
    // so instead just replace it in the actual dom, and update the state when we close
    document
      .querySelector(`#${activeInline}`)
      .replaceWith(content_to_el(html).children[0]);
  };
  // console.log(section);
  // const initialAttrArr = get_attr_array_from_html(content);
  const el = document.querySelector(`#${activeInline}`);
  const initialAttrArr = el ? get_attr_array_from_el(el) : [];
  const content = el ? el.outerHTML : ``;

  return (
    <AttributePopoverInner
      {...props}
      initialAttrArr={initialAttrArr}
      content={content}
      onDoUpdate={onDoUpdate}
      isSection={false}
      isInline={true}
    />
  );
}

function AttributePopoverInner(props) {
  // the trouble is we end up using an outdated version of initialAttrArr
  const [attrArr, setAttrArr] = useState(props.initialAttrArr);
  // so a quick fix so I don't have to rewrite all of the logic, keep state updated
  useEffect(
    () => {
      setAttrArr(props.initialAttrArr);
    },
    [props.initialAttrArr]
  );

  return (
    <Popover
      bringFocusInside
      onClose={() => {
        // XXX this doesn't seem to fire as reliably as I'd like, some sorta issue with foco ?
        // console.log("close");
        const html = set_attribute_html(props.content, attrArr);
        // prevents an update by the user simply looking at the attributes
        if (html !== props.content) {
          props.onDoUpdate(html);
        } else {
          // console.log("same");
        }
      }}
      content={({ close }) => (
        <AttributeEditor
          close={close}
          isInline={props.isInline}
          attrArr={attrArr}
          setAttrArr={setAttrArr}
          onRemoveClick={name => {
            // console.log(name);
            // update the actual html
            props.onDoUpdate(remove_attribute_html(props.content, name));
            // and update the ui
            setAttrArr(attrArr.filter(x => x.name !== name));
          }}
        />
      )}
    >
      {props.children}
    </Popover>
  );
}

function get_attr_array_from_html(html) {
  const el = content_to_el(html).children[0];
  return get_attr_array_from_el(el);
}

function get_attr_array_from_el(el) {
  const attrArr = [...el.attributes].map(({ name, value }) => {
    return { name, value };
  });
  return attrArr;
}

function set_attribute_html(html, attrArr) {
  const el = content_to_el(html).children[0];
  for (let x of attrArr) {
    if (x.name && x.value) {
      el.setAttribute(x.name, x.value);
    }
  }
  return el.outerHTML;
}

function remove_attribute_html(html, name) {
  const el = content_to_el(html).children[0];
  el.removeAttribute(name);
  return el.outerHTML;
}

// don't want the user messing with these !
const BLOCKED_FROM_DELETE_ATTRS = ["id", "data-hederis-type", "class"];
const BLOCKED_FROM_EDIT_ATTRS = ["id", "data-hederis-type", "class"];

function AttributeEditor(props) {
  const { close, attrArr, setAttrArr } = props;

  return (
    <Pane
      paddingY={20}
      paddingX={40}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      {attrArr.map(({ name, value, userAdded }, arrIndex) => {
        return (
          <Pane
            flexDirection="row"
            display="flex"
            key={arrIndex}
            justifyContent="flex-end"
            alignItems="center"
            marginY={4}
            paddingBottom={8}
            borderBottom
          >
            {!userAdded ? (
              <Code width={150} size={300}>
                {name}
              </Code>
            ) : (
              <TextInput
                id="struct-editor-attr-name"
                value={name}
                height={24}
                width={150}
                placeholder="property-name"
                onChange={e => {
                  attrArr[arrIndex]["name"] = e.target.value;
                  setAttrArr([...attrArr]);
                }}
              />
            )}
            <TextInput
              id="struct-editor-attr-value"
              value={value}
              height={24}
              placeholder="value"
              disabled={BLOCKED_FROM_EDIT_ATTRS.includes(name)}
              onChange={e => {
                if (e.target.value.trim().length) {
                  attrArr[arrIndex]["value"] = name === "title" ? e.target.value : e.target.value.trim();
                  setAttrArr([...attrArr]);
                }
              }}
            />
            <IconButton
              icon={TrashIcon}
              height={24}
              disabled={BLOCKED_FROM_DELETE_ATTRS.includes(name)}
              marginLeft={4}
              onClick={() => {
                props.onRemoveClick(name);
              }}
            />
          </Pane>
        );
      })}
      <Text />
      <Button
        id="struct-editor-new-attr"
        iconBefore={PlusIcon}
        onClick={() => {
          setAttrArr([...attrArr, { name: "", value: "", userAdded: true }]);
        }}
        marginBottom={32}
      >
        Add New Property
      </Button>
      <Button
        id="struct-editor-attr-done"
        intent="success"
        appearance={!props.isInline ? "default" : "primary"}
        width={"100%"}
        iconBefore={TickCircleIcon}
        onClick={() => {
          close();
        }}
        autoFocus
      >
        Done
      </Button>

      {/*<TextInput autoFocus width="100%" />*/}
    </Pane>
  );
}
