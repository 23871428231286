import React from "react";
import ReactDOM from "react-dom";
import { useState, useEffect } from "react";
// prettier-ignore
import {Pane, Text, Paragraph, Position, Tooltip, Code, Heading, Button, TextInputField, Textarea, TabNavigation, Tab, SidebarTab, Tablist, Link, toaster, IconButton, Icon, InfoSignIcon, Small, TextInput, RadioGroup, Select, Strong, Checkbox, Combobox, TagInput, Portal, TrashIcon, LockIcon, MinusIcon, PlusIcon, LocateIcon, CogIcon, UnlockIcon, CrossIcon, EditIcon, CodeBlockIcon, ChevronRightIcon, ChevronDownIcon } from "evergreen-ui";

import { firebase, db, storage } from "../../fire.js";
// prettier-ignore
import { human_adv_template_label, capitalizeFirstLetter, parse_dim, parse_id, dashToCamelCase, camelCaseToDash, get_label_from_key, get_subselector_label, parse_hederis_type } from "../../helpers/util.js";

import { ExpandablePane } from "../shared/common.js";
import HedButton from "../shared/HedButton.js";
import SelectionControl from "./selection_control.js";
import { AddFontButton, FontUploadView } from "./font_upload.js";

// import QuickDesigner from "../hederis_comp_quick_designer/quick_designer.js";

// prettier-ignore
// import { PageMarginsInputWrap, FolioStartInputAdvanced, PageStartInput, BlockSpacingInputWrap, SimpleTextInput, BorderRadiusInput, BorderInput, PageChoiceInput, CheckWrap, FontFamilyInput, WeightInput, ItalicStyleInput, ColorPicker, AlignmentInput, NumericalUnitsInput, NumericalUnitsInputWithAuto, NumericalInput, RunContentInput, SpaceBreakContentInput } from "../shared/tb_inputs.js";
// prettier-ignore
import {sub_key_lookup, get_appropriate_comp, get_sub_grouping, build_grouping_by_underscore} from "./tb_helpers"

import ALL_TYPES from "../../../api/app_shared/defaults/types.json";
import ALL_STRUCTURE from "../../../api/app_shared/defaults/structure.json";
import ALL_FONTS_ARR from "../../../api/app_shared/fonts/fonts.json";
import template_builder_advanced_defaults from "../../../api/app_shared/defaults/template_builder_advanced.json";
import generic_defaults from "../../../api/app_shared/defaults/generic_defaults.json";
// prettier-ignore
import {get_font_lookup, patch_fontnames_to_urls} from "../../../api/app_shared/process/config.js";

// this is babel ignored XXX
// import advanced_template from "../../../api/bake_helpers/css_advanced_template.js";

for (let [k, v] of Object.entries(ALL_TYPES)) {
  ALL_TYPES[k] = v.replace("HED ", "").replace("SPAN ", "");
}

let KIND_LABELS = {
  general: "General",
  dims: "Dimensions & Display",
  font: "Text Styles",
  opentype: "OpenType Options",
  spacing: "Margins & Spacing",
  "border&fill": "Border, Fill, and Stroke",
  content: "Dynamic Content",
  runs: "Running Headers and Footers",
  dropcap: "Drop Cap",
  importance: "Priority"
};

function GetHierarchy(project) {
  // the only thing we care about from the project
  const { nontypeset_mode } = project;

  let tab_hierarchy = {
    design: {
      label: "Design",
      data: {
        general: {
          label: "General Design Settings",
          top: ["page_", "body_", "hyphens_", "runhead_", "runfoot_", "folio_"],
          sub: ["master__"],
        },
        master_pages: {
          label: "Page templates",
          sub: [
            "master_frontmatter_",
            "master_clear_",
            "master_chapter_",
            "master_part_",
            "master_backmatter_",
          ],
        },
        sections_and_text: { label: "Sections & Text" },
      },
    },
    page_layout: {
      label: "Page Layout",
      data: { general: {}, master_pages: {}, sections_and_text: {} },
    },
    styles: {
      label: "Styles",
      data: { general: {}, master_pages: {}, sections_and_text: {} },
    },
  };
  // for price discrim #870
  if (nontypeset_mode === "galley") {
    delete tab_hierarchy.page_layout;
  }
  return tab_hierarchy;
}

// Use this to make sure our flex box inputs line up nicely
let GW = { minWidth: 150, maxWidth: 200 };
let GW2 = { minWidth: GW.minWidth / 2, maxWidth: GW.maxWidth / 2 };

// my new version of the advanced_template_builder
export default class TemplateCreator extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.get_exposed = this.get_exposed.bind(this);
    this.fontUploadFinished = this.fontUploadFinished.bind(this);
    this.make_input = this.make_input.bind(this);
    this.make_subsel_input = this.make_subsel_input.bind(this);
    this.get_all_style_data = this.get_all_style_data.bind(this);
    this.parse_style_definition = this.parse_style_definition.bind(this);
    this.parse_and_return_subsel_label = this.parse_and_return_subsel_label.bind(this);
    this.state = {
      font_family: "Minion Pro",
      custom_css: false,
    };
  }

  componentDidMount() {
    let fam = this.props.config["body_bodyFont"].replace(
      /\s?\(?(USER FONT)?\)?\s?\(?[OT]*\)?$/,
      ""
    );
    this.setState({ font_family: fam, custom_css: this.props.custom_css });
  }
  // a change to the config from an input, pass it up
  onChange({ input_key, value }) {
    // XXX debug
    this.props.onChange(input_key, value);
  }
  getSecondaryNav() {
    const ui_hierarchy = GetHierarchy(this.props.project);
    // prettier-ignore
    if (this.props.top_level_ui !== "design") {return ``}
    return (
      <Select
        className="fixedwidth"
        marginY={8}
        backgroundColor="#ffffff"
        borderRadius="5px"
        value={this.props.second_level_ui}
        width="250px"
        flex="0"
        onChange={event =>
          this.props.onViewChange({
            second_level_ui: event.target.value,
            content_editing: false,
          })
        }
      >
        {Object.entries(ui_hierarchy[this.props.top_level_ui]["data"]).map(
          ([k, v]) => {
            // prettier-ignore
            return (<option key={k} value={k}>{v.label}</option> );
          }
        )}
      </Select>
    );
  }
  // getting default values for tb_inputs
  get_default(input_key) {
    let last = input_key.split("_").pop();
    let first = input_key.split("_").shift();
    let lists = [
      "hblkoli",
      "hblkuli",
      "hblktocFM",
      "hblktocChap",
      "hblktocPart",
      "hblktocBM",
    ];
    let mytype = "block";
    if (lists.includes(first)) {
      mytype = "list";
    } else if (input_key.match(/^hspan/g) || input_key.match(/^hspn/g)) {
      mytype = "inline";
    }
    // if the input key is in advanced defaults, use that value
    if (template_builder_advanced_defaults[input_key]) {
      return template_builder_advanced_defaults[input_key];
    } else if (generic_defaults[mytype][last]) {
      return generic_defaults[mytype][last];
    }
  }
  get_font(input_key, selection_path, config, suffix) {
    let type = input_key.split("_")[0];
    if (type === "master") {
      type = input_key.replace(/_[^_]+$/g,"");
    }
    let fam = config[type + suffix];
    if (suffix.indexOf("dropcap") > -1 && (!fam || fam === "inherit")) {
      fam = config[type + "_font"];
    }
    if (!fam || fam === "inherit") {
      if (selection_path[1]) {
        let re = /(type=")(\S+)(")/g;
        let partype = re.exec(selection_path[1])[2];
        if (config.hasOwnProperty(partype + "_font")) {
          fam = config[partype + "_font"];
        }
      }
      if (!fam || fam === "inherit") {
        fam = this.state.font_family;
      }
    }
    return fam;
  }
  get_font_subsel(input_key, selection_path, fam, cur_type, config, suffix) {
    let type = input_key.split("_")[0];
    if (!fam || fam === undefined || fam === "inherit") {
      fam = config[cur_type + suffix];

      if (suffix.indexOf("dropcap") > -1 && (!fam || fam === "inherit")) {
        fam = config[type + "_font"];
      }
      if (!fam || fam === "inherit") {
        if (selection_path[1]) {
          let re = /(type=")(\S+)(")/g;
          let partype = re.exec(selection_path[1])[2];
          if (config.hasOwnProperty(partype + "_font")) {
            fam = config[partype + "_font"];
          }
        }
        if (!fam || fam === "inherit") {
          fam = this.state.font_family;
        }
      }
    }
    return fam;
  }

  get_font_family(
    input_key,
    config,
    selection_path,
    sub_sel_path,
    existing,
    cur_type,
    is_subselector
  ) {
    // figure out font family being used so that we
    // can offer the correct font_weight options as
    // defined in the fonts config.
    let fam = undefined;
    if (
      input_key.match(/_fontWeight$/g) ||
      input_key.match(/_font$/g) ||
      input_key.match(/_openType$/g)
    ) {
      if (!is_subselector) {
        if (input_key.match(/^master_/g) && !input_key.match(/_openType$/g)) {
          fam = config[input_key.replace("_fontWeight", "_font")];
        } else {
          fam = this.get_font(input_key, selection_path, config, "_font");
        }
      } else {
        fam = existing
          ? existing[sub_sel_path + "_font"]
          : config[cur_type + "_font"];
        fam = this.get_font_subsel(
          input_key,
          selection_path,
          fam,
          cur_type,
          config,
          "_font"
        );
      }
    } else if (input_key.match(/_dropcapWeight$/g)) {
      if (!is_subselector) {
        fam = this.get_font(input_key, selection_path, config, "_dropcapFont");
      } else {
        fam = existing
          ? existing[sub_sel_path + "_dropcapFont"]
          : config[cur_type + "_dropcapFont"];
        fam = this.get_font_subsel(
          input_key,
          selection_path,
          fam,
          cur_type,
          config,
          "_dropcapFont"
        );
      }
    }
    if (fam) {
      fam = fam.replace(/\s?\([OT]+\)$/g, "");
    } else {
      fam = this.state.font_family;
    }
    return fam;
  }

  make_input(input_key) {
    let { config } = this.props;
    let Comp = get_appropriate_comp(input_key);
    let def = this.get_default(input_key);
    let val = config[input_key];
    if (!val) {
      val = def;
    }

    let fam = this.get_font_family(
      input_key,
      config,
      this.props.selection_path,
      false,
      false,
      false,
      false
    );

    if (Array.isArray(val)) {
      val = typeof val[0] === "undefined" && !input_key.includes("content") ? def : val;
    } else {
      val = typeof val === "undefined" ? def : val;
    }
    // console.log(input_key, def);
    // XXX you probably want to update make_subsel_input below as well!
    // prettier-ignore
    return Comp ? (
      <Pane key={input_key} title={input_key}>
        {/*<Code>{input_key}</Code>*/}
        <Comp thisClass="designOptions" value={val} fontnames={this.props.fontnames} images={this.props.project.images} fonts_arr={this.props.fonts_arr} font_family={fam} input_key={input_key} group={this.props.group} onChange={this.onChange} def={def} GW={GW} GW2={GW2} hederis_user={this.props.hederis_user} params={this.props.params} textColor="#ffffff" user_groups={this.props.user_groups} fontUploadFinished={this.fontUploadFinished} />
      </Pane>) : (``);
  }
  // helper for below,
  get_subsel_path_key() {
    let { selection_path, selection_mode, prev_sibling_type_sel } = this.props;
    // don't worry about this if nothing is selected
    if (selection_path.length === 0) {
      return false;
    }
    // let prefix = selection_mode_to_subsel[selection_mode];
    let target = selection_path[0];
    let target_type = parse_hederis_type(target);
    if (selection_mode == "within_parent") {
      let parent_type = parse_hederis_type(selection_path[1]);
      return `PARENT_${parent_type}_${target_type}`;
    } else if (selection_mode === "direct_child") {
      let parent_type = parse_hederis_type(selection_path[1]);
      return `CHILD_${parent_type}_${target_type}`;
    } else if (selection_mode === "first_within_parent") {
      let parent_type = parse_hederis_type(selection_path[1]);
      return `FIRSTOFTYPE_${parent_type}_${target_type}`;
    } else if (selection_mode === "only") {
      // ??? or the data type and id ?
      // return `${prefix}_${target_type}#${parse_id(target)}`;
      return `ID_${parse_id(target)}_${target_type}`;
    } else if (selection_mode === "following") {
      return `PREV_${prev_sibling_type_sel}_${target_type}`;
    } else {
      console.log("There was an issue with selection mode:", selection_mode);
    }
  }
  // for sub selectors inputs only, i.e. non selection_mode 'all'
  // sorta hacky, ideally this logic would come earlier, but it's not too bad
  make_subsel_input(input_key) {
    // return ``
    let { config, selection_mode, selection_path } = this.props;
    let target = selection_path[0];
    let target_type = parse_hederis_type(target);
    // console.log("make_subsel_input, input_key incoming:", input_key);
    let [_, ...parts] = input_key.split("_");
    let key_ending = parts.join("_");
    let cur_type = parse_hederis_type(this.props.selection_path[0]);
    let cur_type_subpath = `${cur_type}_subSelectors`;
    // get the general subsels object for this type
    // console.log(cur_type_subpath);
    let sub_sels_obj = config[cur_type_subpath];
    let sub_sel_path = this.get_subsel_path_key();
    // console.log(sub_sel_path);
    // console.log("path_with_key:", path_with_key);
    let existing = sub_sels_obj[sub_sel_path];
    // console.log("existing", existing);

    // just split off the end of the input key. or rather, split off the first (target) and use the rest
    let path_with_key = `${sub_sel_path}_${key_ending}`;
    // if we have data for the subselector use that,
    // otherwise use what's in the general config for this type
    let val = existing ? existing[path_with_key] : config[input_key];
    // console.log({ val, path_with_key, existing: !!existing });
    // console.log("existing", existing);

    // XXX maybe this should be the latter,
    let active_exposed = this.get_exposed([cur_type]);
    // let active_exposed = this.get_exposed([`${cur_type}_`]);

    let fam = this.get_font_family(
      input_key,
      config,
      selection_path,
      sub_sel_path,
      existing,
      cur_type,
      true
    );

    let onChange = ({ input_key, value }) => {
      // console.log("subsel onChange", input_key, value);
      // this is the object we want to call onchange with, in the end , as it's the value of the top level advanced_config
      let sub_sels_obj = this.props.config[cur_type_subpath];
      let sub_sel_path = this.get_subsel_path_key();
      // console.log(sub_sels_obj)
      let specific_sub_sel = sub_sels_obj[sub_sel_path] || {};
      // console.log("specific_sub_sel", specific_sub_sel);

      specific_sub_sel[path_with_key] = value;
      sub_sels_obj[sub_sel_path] = specific_sub_sel;
      // console.log({ path_with_key, existing, sub_sel_path, cur_type_subpath });
      // console.log(specific_sub_sel);

      this.props.onChange(cur_type_subpath, sub_sels_obj);
    };

    let def = this.get_default(input_key);
    if (!val) {
      if (fam && input_key.match(/_font$/g)) {
        val = fam;
      } else {
        val = def;
      }
    }
    // just use the default if val is undefined
    if (Array.isArray(val)) {
      val = typeof val[0] === "undefined" ? def : val;
    } else {
      val = typeof val === "undefined" ? def : val;
    }
    // console.log(input_key, def);
    // console.log('making subsel')
    // console.log('def is', def)
    // console.log('input_key', input_key)
    // console.log('val is', val)
    // and finally
    let Comp = get_appropriate_comp(input_key);
    // prettier-ignore
    return Comp ? (
      <Pane key={input_key} title={input_key}>
        <Comp thisClass="designOptions"  value={val} fontnames={this.props.fontnames} images={this.props.project.images} font_family={fam} fonts_arr={this.props.fonts_arr} group={this.props.group} input_key={input_key} onChange={onChange} def={def} GW={GW} GW2={GW2} hederis_user={this.props.hederis_user} params={this.props.params} user_groups={this.props.user_groups} fontUploadFinished={this.fontUploadFinished} />
      </Pane>) : (``);
  }

  fontUploadFinished() {
    //  trigger  reload user_fonts() to refresh

    console.log("fontUploadFinished");
    this.props.load_fonts();
  }

  // DESIGN > GENERAL DESIGN SETTINGS
  getGeneralDesign() {
    const ui_hierarchy = GetHierarchy(this.props.project);

    let { top_level_ui, second_level_ui } = this.props;

    // let tops = ui_hierarchy["design"]["data"][second_level_ui]["top"];
    let tops = ui_hierarchy[top_level_ui]["data"][second_level_ui]["top"];
    // prettier-ignore
    if (!tops) { return `` }
    // console.log(tops);
    let exposed_general_keys = this.get_exposed(tops);
    let panes = [];
    Object.entries(get_sub_grouping(exposed_general_keys)).map(
      ([kind, key_arr]) => {
        // console.log(kind);
        let inputs = key_arr.map(this.make_input);
        let hyphen_note = ``;
        if (
          key_arr.indexOf("hyphens_minLeft") > -1 || 
          key_arr.indexOf("hyphens_minRight") > -1
        ) {
          hyphen_note = (
            <Pane marginTop={8} marginBottom={16}>
              <InfoSignIcon size={12} color="disabled" />
              <Text size={300} marginLeft={4} color="#ffffff">
                Press "Reflow Pages" to preview hyphenation settings.
              </Text>
            </Pane>
          );
        }
        let pane = (
          <Pane key={kind} padding={8} marginY={8} backgroundColor="#5D597A">
            <Heading size={400} padding={8} textDecoration="underline" color="#ffffff">{KIND_LABELS[kind] || kind}</Heading>
            {inputs}
            {hyphen_note}
          </Pane>
        );
        panes.push(pane);
      }
    );
    // Add Custom CSS Editor
    let placeholder = this.state.custom_css
      ? this.state.custom_css 
      : "Type CSS here...";
    let css_editor = (
      <Pane marginY={8} key="customcsseditorpane">
        <ExpandableStatePane
          label="Custom CSS Editor (advanced)"
          key="customcsseditorinternal"
        >
          <Paragraph size={300} marginTop={4} marginBottom={4} color="#ffffff">
            Add custom CSS that will be applied to your PDF  
            in addition to your design settings.
          </Paragraph>
          <HedButton
            height={32}
            appearance="hedprimary"
            intent="none"
            marginBottom={8}
            disabled={false}
            onClick={e => this.props.onSaveCustomCss(this.state.custom_css)}
          >
            Save CSS
          </HedButton>
          <Textarea
            key="customcsstexteditor"
            name="css-editor"
            placeholder={placeholder}
            height={128}
            onChange={e => this.setState({ custom_css: e.target.value })}
            value={this.state.custom_css ? this.state.custom_css : ""}
          />
        </ExpandableStatePane>
      </Pane>
    );
    panes.push(css_editor);
    return panes;
  }
  getMarginAreaUI() {
    const ui_hierarchy = GetHierarchy(this.props.project);

    // in marginMode this should resemble getPagesCollaps
    // just filter for 'margins' with the S in that case
    // need to get more creative for other
    // first, move these into it, and use the methods
    let { top_level_ui, second_level_ui } = this.props;
    // console.log(top_level_ui, second_level_ui);
    let subs = ui_hierarchy[top_level_ui]["data"][second_level_ui]["sub"];
    // console.log(subs);
    // prettier-ignore
    if (!subs) { return `` }
    // console.log(build_grouping_by_underscore(this.get_exposed(["hsec"])));
    // console.log(this.get_exposed(["hsec"]));
    // let exposed_keys = this.get_exposed(subs);
    // prettier-ignore
    let active_sect_kind = Object.keys(build_grouping_by_underscore(this.get_exposed(["hsec"])))[0];
    // console.log(active_sect_kind);
    let master_type = this.props.config[`${active_sect_kind}_page`];
    // console.log(master_type);
    // let page_type_groupings = group_it(exposed_keys, lookup_page_type);
    // console.log(page_type_groupings);
    let methods = {
      make_input: this.make_input,
      get_exposed: this.get_exposed,
    };
    return (
      <MarginWidthsAndAreasInput
        {...this.props}
        methods={methods}
        initialPageMasterKind={`master_${master_type}_`}
      />
    );
  }
  // removing this in favor of the above
  // getPagesCollaps() {
  //   console.log("XXX Disabled getPagesCollaps for debug/replacement XXX ");
  //   // XXXXXX
  //   return ``;
  //   // XXXXXX
  //   // XXXXXX
  //
  //   let { top_level_ui, second_level_ui } = this.props;
  //   let subs = ui_hierarchy[top_level_ui]["data"][second_level_ui]["sub"];
  //   // console.log(subs);
  //   // prettier-ignore
  //   if (!subs) { return `` }
  //   // console.log(tops);
  //   let exposed_keys = this.get_exposed(subs);
  //   // console.log(exposed_keys);
  //   let page_type_groupings = group_it(exposed_keys, lookup_page_type);
  //   // console.log(page_type_groupings);
  //   // console.log(groupings);
  //   let panes = [];
  //   Object.entries(page_type_groupings).map(([kind, key_arr]) => {
  //     // Object.entries(get_sub_grouping(exposed_keys)).map(([kind, key_arr]) => {
  //     let inputs = key_arr.map(this.make_input);
  //     let pane = (
  //       <ExpandableStatePane
  //         label={KIND_LABELS[kind] || kind.split("_").join(" ")}
  //         key={kind}
  //       >
  //         {inputs}
  //       </ExpandableStatePane>
  //     );
  //     panes.push(pane);
  //   });
  //   return panes;
  // }
  get_exposed(subs) {
    return Object.keys(this.props.config).filter(x => {
      // ignore sub sel, we surface it differently
      // prettier-ignore
      if (x.endsWith('_subSelectors')) { return false }
      for (let t of subs) {
        if (x.startsWith(t)) {
          return true;
        }
      }
      return false;
    });
  }
  // Get the human-friendly label for the subselector
  parse_and_return_subsel_label(parent_h_type, h_type, key) {
    let get_subsel_type = k => {
      switch(k.split("_").shift()) {
        case "ID":
          return "only";
        case "PARENT":
          return "within_parent";
        case "CHILD":
          return "direct_child";
        case "FIRSTOFTYPE":
          return "first_within_parent";
        case "PREV":
          return "following";
        default:
          return "all";
      }
    };
    let sel_mode = get_subsel_type(key);
    let label = get_subselector_label(
      this.props.selection_path,
      sel_mode,
      h_type,
      parent_h_type,
      this.props.prev_sibling_type_sel
    );
    return label;
  }
  // Parse a property and value into a single component text string
  parse_style_definition(selector, key, val) {
    let label = key.replace(selector,"").replace(/_/,"");
    if (Array.isArray(val)) {
      val = val.join(", ");
    }
    return (
      <Paragraph key={`${selector}${key}styledef`}>
        {label}: {val}
      </Paragraph>
    );
  }
  get_all_style_data(selector, active_exposed) {
    let selection_path = this.props.selection_path;
    let styles_inner = [];
    let sel = selector.replace(/[data-hederis-type="/,"").replace(/"]/,"");
    let parent = undefined;

    // check any configured subselctors for the selector and see if they apply
    let subsel_arr = [];
    let subsel_styles_arr = [];

    // add the ID selector if an element is selected
    let id_subsel = selection_path[0].match(/(data-id=")([a-zA-Z0-9-_]+)/) != null ?
      `ID_${selection_path[0].match(/(data-id=")([a-zA-Z0-9-_]+)/)[2]}_${selector}` :
      undefined;
    subsel_arr.push(id_subsel);

    if (selection_path.length > 1) {
      // get all the possible subselectors
      parent = selection_path[1].match(/(\[data-hederis-type=")([a-zA-Z0-9-_]+)("\])/)[2];
      let parent_subsel = `PARENT_${parent}_${selector}`;
      let child_subsel = `CHILD_${parent}_${selector}`;
      subsel_arr.push(child_subsel);
      let first_subsel = parent_subsel.replace("PARENT_","FIRSTOFTYPE_");
      subsel_arr.push(first_subsel);
      let prev_subsel = this.props.prev_sibling_type_sel ?
        `PREV_${this.props.prev_sibling_type_sel}_${selector}` :
        undefined;
      subsel_arr.push(prev_subsel);
    }

    // get all the existing subselectors
    let subselectors_config = this.props.config[`${selector}_subSelectors`];

    for (let item in subselectors_config) {
      if (item.match("PARENT_")) {
        // check to see if this htype is in the selection_path array
        let this_h_type = item.split("_")[1];
        selection_path.map(sel => {
          if (sel.match(`data-hederis-type="${this_h_type}"`)) {
            let this_parent_subsel = item;
            subsel_arr.push(this_parent_subsel);
          }
        })
      }
    }

    for (let key in subselectors_config) {
      // if the existing subsel is in the list of possible subsels, list the styles:
      if (subsel_arr.indexOf(key) > -1) {
        let this_styles_arr = [];
        for (let prop in subselectors_config[key]) {
          this_styles_arr.push(this.parse_style_definition(key, prop, subselectors_config[key][prop]));
        };
        let this_parent = parent;
        if (key.match("PARENT_")) {
          this_parent = key.split("_")[1];
        }
        let this_heading = (
          <Heading size={400} marginTop={8} key={`${key}stylesheading`}>
            {this.parse_and_return_subsel_label(this_parent, selector, key)}
          </Heading>
        );
        subsel_styles_arr.push(this_styles_arr);
        subsel_styles_arr.push(this_heading);
      }
    }

    // get the global styles for this paragraph type
    let top_level_styles_arr = active_exposed.map(key => {
      return this.parse_style_definition(selector, key, this.props.config[key]);
    });

    // Render all the styles.
    // Below, we reverse the subsel array, so that they are
    // listed in CSS override order.
    let top_level_styles = (
      <Pane
        key={`${selector}allstylesinner`}
        border
        padding={8}
        margin={8}
        background="tint1"
      >
        {subsel_styles_arr.length > 0 ? (
          <Paragraph borderBottom="2px solid #E4E7EB" paddingBottom={4}>
            Your selection has overrides as described below.
            Learn more about overrides
            <a
              href="https://docs.hederis.com/docs/design-settings-and-inheritance/"
              target="_blank"
            >
              &nbsp;in our documentation
            </a>.
          </Paragraph>
          ) : (
            ``
          )
        }
        {subsel_styles_arr.reverse()}
        <Heading size={400} marginTop={8}>
          All {ALL_TYPES[selector]}s
        </Heading>
        {top_level_styles_arr}
      </Pane>
    );
    styles_inner.push(top_level_styles);
    return styles_inner;
  }

  getGeneralCollaps() {
    const ui_hierarchy = GetHierarchy(this.props.project);

    let { top_level_ui, second_level_ui } = this.props;
    let subs = ui_hierarchy[top_level_ui]["data"][second_level_ui]["sub"];
    // console.log(subs);
    // prettier-ignore
    if (!subs) { return `` }
    // console.log(tops);
    let exposed_keys = this.get_exposed(subs);
    let panes = [];
    Object.entries(get_sub_grouping(exposed_keys)).map(([kind, key_arr]) => {
      let inputs = key_arr.map(this.make_input);
      let pane = (
        <ExpandableStatePane
          label={KIND_LABELS[kind] || kind.split("_").join(" ")}
          key={kind}
        >
          {inputs}
        </ExpandableStatePane>
      );
      panes.push(pane);
    });
    return panes;
  }
  makeNavChoices() {
    let exposed = this.get_exposed(["hsec", "hwpr", "hblk", "hspn", "hspan"]);
    // console.log({ exposed });
    let groupings = build_grouping_by_underscore(exposed);
    // console.log("groupings");
    let nav_choices = Object.keys(groupings);
    // a blank one for unselected state
    nav_choices.unshift("");
    return nav_choices;
  }
  getSectAndTextUI() {
    if (this.props.second_level_ui !== "sections_and_text") {
      return { control_frag: "", input_panes: "" };
    }
    let nav_choices = this.makeNavChoices();
    let cur_type = parse_hederis_type(this.props.selection_path[0]);
    // console.log(cur_type);
    // XXX things selected like this won't have parents or siblings, which needs to be taken into account
    let nav = (
      <Select
        marginY={8}
        value={cur_type}
        width="250px"
        flex="0"
        backgroundColor="#ffffff"
        borderRadius="5px"
        onChange={event => {
          let val = event.target.value;
          let selection_path = val ? [`[data-hederis-type="${val}"]`] : [];
          this.props.onViewChange({
            selection_path,
            selection_mode: "all",
            content_editing: false,
          });
        }}
      >
        {nav_choices.map(k => {
          // prettier-ignore
          return (<option key={k} value={k}>{ALL_TYPES[k] || "---"+k}</option> );
        })}
      </Select>
    );
    let active_exposed = this.get_exposed([`${cur_type}_`]);

    let sub_sel_path =
      this.props.selection_mode !== "all" ? this.get_subsel_path_key() : false;
    // console.log({ active_exposed });
    let input_panes = [];

    // console.log(sub_sel_path);
    // use cur_type and subsel path to get the styles and subselector styles
    let all_style_data = cur_type ?
      this.get_all_style_data(cur_type, active_exposed) :
      ``;

    let view_all_styles = (
      <ExpandableStatePane
        label="View all styles applied to selection"
        key={`${cur_type}allstyleswrapper`}
        display={cur_type ? "auto" : "none"}
      >
        {all_style_data}
      </ExpandableStatePane>
    );

    input_panes.push(view_all_styles);

    let heading = (
      <Heading
        key={`${cur_type}heading`}
        size={500}
        padding={8}
        margin={8}
        display={cur_type ? "auto" : "none"}
      >
        Design Options:
      </Heading>
    );

    // input_panes.push(heading);

    Object.entries(get_sub_grouping(active_exposed)).map(([kind, key_arr]) => {
      // console.log(k);
      // console.log(kind);
      // console.log();
      let input_f =
        this.props.selection_mode === "all"
          ? this.make_input
          : this.make_subsel_input;

      let inputs = key_arr.map(input_f);
      let pane = (
        <ExpandableStatePane
          label={KIND_LABELS[kind] || kind.split("_").join(" ")}
          key={kind}
          display={kind == "hidden" ? "none" : "auto"}
        >
          <Pane
            key={kind}
            paddingY={8}
            marginY={8}
            display={kind == "hidden" ? "none" : "auto"}
          >
            {inputs}
          </Pane>
        </ExpandableStatePane>
      );
      input_panes.push(pane);
    });
    // console.log(active_exposed);

    let control_frag = (
      <React.Fragment>
        <Pane 
          className="fixedwidth"
          display="flex" 
          flexDirection="row" 
          alignItems="center" 
          justifyContent="space-between" 
          paddingLeft={8}
          paddingRight={16}
        >
          <Text marginX={8} size={300} color="#ffffff" display="inline-block">
            Type of element:{" "}
          </Text>
          {nav}
        </Pane>
        <SelectionControl {...this.props} sub_sel_path={sub_sel_path} />
      </React.Fragment>
    );
    return { control_frag, input_panes };
    // return (
    //   <Pane>
    //     {nav}
    //     <SelectionControl {...this.props} sub_sel_path={sub_sel_path} />
    //     <Pane>{input_panes} </Pane>
    //   </Pane>
    // );
  }

  // let cur_type = parse_hederis_type(this.props.selection_path[0]);
  // let cur_type_subpath = `${cur_type}_subSelectors`;
  // // get the general subsels object for this type
  // let sub_sels_obj = config[cur_type_subpath];
  // let sub_sel_path = this.get_subsel_path_key();
  // // console.log(sub_sel_path);
  // // console.log("path_with_key:", path_with_key);
  // let existing = sub_sels_obj[sub_sel_path];
  getPageLayoutUI() {
    let is_range_selected = !!this.props.current_range;
    // xxx these are both the label and they key for the command XXX
    // prettier-ignore
    let button_kinds = ["tighten", "loosen", "insert line break after selection", "insert page break after selection", "insert page break after paragraph", "remove layout adjustments from paragraph"]

    let command_buttons = button_kinds.map(x => (
      <HedButton
        id={`page-layout-${x.replace(/\s+/g,"")}`}
        key={x}
        height={40}
        appearance={is_range_selected && !this.props.range_locked ? "hedsecondary" : "hedunfocus"}
        disabled={is_range_selected && !this.props.range_locked ? false : true}
        textTransform="capitalize"
        marginX={16}
        marginY={8}
        onClick={e => this.props.onLayoutButtonClick(x)}
      >
        {x}
      </HedButton>
    ));

    return (
      <Pane display="flex" flexDirection="column" paddingY={16}>
        {this.props.viewOpt.epub_mode ? DigitalModeWarning : ``}

        {!this.props.viewOpt.epub_mode && !is_range_selected && !this.props.isLocked ? (
          <Text color="#ffffff" textAlign="left" size={400} marginX={16} marginY={8}>
            Select some text in the preview to activate the page layout tools.
          </Text>
        ) : (
          ``
        )}

        {!this.props.viewOpt.epub_mode && command_buttons}

        {!this.props.viewOpt.epub_mode ? (
          <Pane>
            <Button
              appearance="primary"
              intent="danger"
              marginX={16}
              marginY={8}
              height={40}
              width="93%"
              iconBefore={TrashIcon}
              onClick={e => this.props.onLayoutButtonClick("remove all")}
            >
              Remove All Layout Adjustments From Section
            </Button>

            <Button
              marginX={16}
              marginY={8}
              height={40}
              iconBefore={CodeBlockIcon}
              intent={this.props.viewOpt.show_manip_spans ? "success" : "none"}
              onClick={() =>
                this.props.setViewOpt({
                  show_manip_spans: !this.props.viewOpt.show_manip_spans,
                })
              }
            >
              Show Adjustments
            </Button>
          </Pane>
        ) : (
          ``
        )}

        {!this.props.viewOpt.epub_mode ? (
          <ExpandableStatePane
            label="Other Tools"
            key="othertools"
            source="templateCreator"
          >
            <Paragraph marginTop={16} color="#ffffff">NOTE: Locking controls will soon be deprecated in 
            favor of a new Print Archive functionality.</Paragraph>

            {this.props.isLocked ? (
              <LockParagraphControl {...this.props} />
            ) : (
              ``
            )}
          </ExpandableStatePane>
        ) : (
          ``
        )}
      </Pane>
    );
  }

  // XXX hacky render method
  // eventually all these getXXX() functions below should be refactored into their own components
  // also confusingly, a lot of them return `` if they're not meant to be shown given this.state
  render() {
    // console.log(this.props);
    // hacky but faster than a complete refactor, will return empty strings in the object if it shouldn't be shown
    let maybe_sect_uis = this.getSectAndTextUI();
    let nav_choices = this.makeNavChoices();
    let cur_type = parse_hederis_type(this.props.selection_path[0]);

    return (
      <Pane backgroundColor="#5D597A">
        <Pane marginTop={4}>
          <Pane 
            display="flex" 
            flexDirection="row" 
            alignItems="center" 
            justifyContent="space-between" 
            paddingLeft={8}
            paddingRight={16}
          >
            {this.props.top_level_ui === "design" && this.props.bookContainsLock
              ? BookHasLockWarning
              : ``}
            {/* will be empty if top leve isn't design */}
            {this.props.top_level_ui === "design" && (
              <Text marginLeft={8} marginRight={16} size={300} color="#ffffff">
                Design settings for:{" "}
              </Text>
            )}
            {this.getSecondaryNav()}
          </Pane>
          {maybe_sect_uis.control_frag}
          {(this.props.top_level_ui === "design" && this.props.second_level_ui === "sections_and_text" && this.props.selection_mode === "custom") && (
            <Pane className="designOptions" padding={16}>
              <Text marginX={8} size={300}>
                Custom selector:{" "}
              </Text>
              <CustomSubselector nav_choices={nav_choices} cur_type={cur_type} onViewChange={this.props.onViewChange} />
            </Pane>
          )}
        </Pane>
        {/* this one holds the inputs */}

        <Pane height="80vh" overflowY="scroll" overflowX="hidden" backgroundColor="#373550">
          {this.props.top_level_ui === "design" ? this.getGeneralDesign() : ``}

          {this.props.top_level_ui === "page_layout"
            ? this.getPageLayoutUI()
            : ``}
          {this.props.top_level_ui === "styles" ? (
            <StylesTab {...this.props} {...this.state} />
          ) : (
            ``
          )}
          {this.props.top_level_ui === "quick_design" ? (
            <QuickDesigner {...this.props} {...this.state} />
          ) : (
            ``
          )}
          {/*got rid of getPagesCollaps*/}
          {this.props.second_level_ui === "general"
            ? this.getGeneralCollaps()
            : this.props.second_level_ui === "master_pages"
            ? this.getMarginAreaUI()
            : maybe_sect_uis.input_panes}
        </Pane>
      </Pane>
    );
  }
}

const BookHasLockWarning = (
  <Pane padding={8} background="tint2" marginY={8}>
    <Text>
      <Icon icon={LockIcon} marginTop={8} marginX={4} size={16} />
      <Strong>Warning:</Strong> At least one chapter of this book is locked!
      Editing the design may have unforeseen effects on the layout of locked
      chapters.{" "}
    </Text>
  </Pane>
);

const DigitalModeWarning = (
  <Pane padding={8} background="tint2" marginY={8}>
    <Text>
      <Strong>You are in Digital mode.</Strong> Switch to Print mode to see and
      use the Page Layout tools.{" "}
    </Text>
  </Pane>
);

class ExpandableStatePane extends React.Component {
  constructor(props) {
    super(props);
    if (props.initialExpand) {
      this.state = { expanded: true };
    } else {
      this.state = { expanded: false };
    }
  }
  render() {
    // console.log(props.complete);
    return (
      <Pane
        borderRadius="8px"
        marginTop={4}
        marginBottom={4}
        backgroundColor="#5D597A"
        display={this.props.display ? this.props.display : "auto"}
      >
        <Pane
          paddingX={4}
          paddingY={8}
          flexDirection="row"
          alignItems="center"
          backgroundColor="#5D597A"
          display="flex"
          // F7F9FD
          css={{ ":hover": { backgroundColor: "#F7F9FD" } }}
          cursor="pointer"
          onClick={() => this.setState({ expanded: !this.state.expanded })}
        >
          <HedButton
            appearance="hedflat"
            marginRight={0}
          >
           {this.state.expanded ? <ChevronDownIcon color="disabled" /> : <ChevronRightIcon color="disabled" />}
          </HedButton>

          <Heading textTransform="capitalize" color="#ffffff">{this.props.label}</Heading>
        </Pane>
        <Pane
          marginLeft={8}
          marginRight={8}
          backgroundColor="#5D597A"
          transition="all 0.6s ease"
          maxHeight={this.state.expanded ? 5000 : 0}
        >
          {this.state.expanded ? this.props.children : ``}
        </Pane>
      </Pane>
    );
  }
}

class StylesTab extends React.Component {
  constructor(props) {
    super(props);
    // this.state = { show: false };
    this.state = { show: true };
  }
  render() {
    return (
      <Pane margin={8} display="flex" flexDirection="column">
        <HedButton
          marginBottom={16}
          iconBefore={LocateIcon}
          appearance={this.props.viewOpt.show_overrides ? "hedprimary" : "hedsecondary"}
          onClick={e =>
            this.props.setViewOpt({
              show_overrides: !this.props.viewOpt.show_overrides,
            })
          }
        >
          {this.props.viewOpt.show_overrides
            ? "Hide design override highlights"
            : "Highlight design overrides"}
        </HedButton>
        <HedButton
          marginBottom={16}
          iconBefore={CogIcon}
          appearance={this.state.show ? "hedprimary" : "hedsecondary"}
          onClick={e =>
            this.setState({
              show: !this.state.show,
            })
          }
        >
          List All {this.props.style_list.length} Style Names in Design Template
        </HedButton>
        <Text color="#B3B0C6" size={300}>* = default style name included in all templates</Text>
        {this.state.show ? <BookStyleList {...this.props} /> : ``}
      </Pane>
    );
  }
}

function LockLayoutControl(props) {
  return (
    <Pane margin={16}>
      {!props.isLocked ? (
        <Text size={300}>
          Click "Prevent Reflow" if you want to ensure that all line breaks and page
          breaks stay exactly the way you see them now.
        </Text>
      ) : (
        <Text size={300}>
          Click "Allow Reflow" to let line breaks and page breaks 
          reflow for <Strong>all</Strong> paragraphs. For a more controlled 
          approach, unlock paragraphs individually.
        </Text>
      )}
      <Pane display="flex" justifyContent="space-between">
        {!props.isLocked ? (
          <Tooltip
            content="Lock the current page breaks and line breaks into place for the PDF"
            position={Position.BOTTOM}
          >
            <Button
              iconBefore={LockIcon}
              onClick={props.onBake}
              appearance="primary"
              intent="warning"
              marginTop={8}
              height={40}
              width={"100%"}
            >
              Prevent Reflow in this Chapter
            </Button>
          </Tooltip>
        ) : (
          <React.Fragment>
            <Tooltip
              content="Let line breaks and page breaks reflow as changes are made"
              position={Position.BOTTOM}
            >
              <Button
                iconBefore={UnlockIcon}
                onClick={props.onUnBake}
                appearance="primary"
                intent="success"
                marginTop={8}
                height={40}
                width={"100%"}
              >
                Allow Reflow in this Chapter
              </Button>
            </Tooltip>
            {/*<Tooltip
              content="Line edits will be lost if you later unlock this chapter"
              position={Position.BOTTOM}
            >
              <Button
                iconBefore={props.line_editor_mode ? CrossIcon : EditIcon}
                onClick={() =>
                  props.onViewChange({
                    line_editor_mode: !props.line_editor_mode,
                    line_selected: "",
                  })
                }
                appearance="primary"
                intent={!props.line_editor_mode ? "none" : "danger"}
                display="block"
                marginTop={8}
              >
                {!props.line_editor_mode
                  ? "Enable Line Editor Mode"
                  : "Turn Line Editor Mode Off"}
              </Button>
            </Tooltip>*/}
          </React.Fragment>
        )}
      </Pane>
    </Pane>
  );
}

function LockParagraphControl(props) {
  if (props.current_range && !props.range_locked) {
    return (
      <Pane display="flex" flexDirection="column">
        <Text
          marginX={16}
          marginBottom={4}
          color={props.current_range ? "default" : "muted"}
          size={300}
        >
          Remember to re-lock this paragraph so that your layout does not reflow
          in the print PDF!
        </Text>
        <Button
          key="lock paragraph"
          iconBefore={LockIcon}
          disabled={!props.current_range}
          onClick={e => {
            props.onLayoutButtonClick("lock paragraph");
            props.rangeLocked(true);
          }}
          appearance="primary"
          intent="warning"
          marginBottom={16}
          marginX={16}
          height={40}
        >
          Re-lock Paragraph
        </Button>
      </Pane>
    );
  } else {
    return (
      <Pane display="flex" flexDirection="column">
        <Text
          marginX={16}
          marginBottom={4}
          color={props.current_range ? "default" : "muted"}
          size={300}
        >
          Select some text and then unlock the paragraph so that you can 
          make layout adjustments. Note that unlocking a paragraph may cause 
          it to reflow slightly.
        </Text>
        <Button
          key="unlock paragraph"
          iconBefore={UnlockIcon}
          disabled={!props.current_range}
          onClick={e => {
            props.onLayoutButtonClick("unlock paragraph");
            props.rangeLocked(false);
          }}
          appearance="primary"
          intent="success"
          marginBottom={16}
          marginX={16}
          height={40}
        >
          Unlock Single Paragraph
        </Button>
      </Pane>
    );
  }
}

function BookStyleList(props) {
  let prefixes = ["PARENT_", "FIRSTOFTYPE_", "ID_", "PREV_"];
  let hasPrefix = s => prefixes.some(p => s.startsWith(p));
  let style_list = props.style_list;
  return style_list.map((key, i) => {
    // getting human readable labels ish
    let res = "";
    let parent_type = "";
    let isOverride = false;
    let isIDOverride = false;
    if (!hasPrefix(key)) {
      res = get_label_from_key(key);
    } else {
      isOverride = true;
      let key_split = key.split("_");
      parent_type = key_split[key_split.length-1];
      // remove the prefix
      let pre = key_split.shift();
      if (pre == "ID") {
        isIDOverride = true;
        // remove the id too
        let id = undefined;
        if (key_split[0] === "client") {
          id = key_split.splice(0, 2).join("_");
        } else {
          id = key_split.shift();
        }
        res = key_split.map(get_label_from_key).join(" ");
        res += ` id#${id}`;
      } else {
        res = key_split.map(get_label_from_key).join(" ");
      }
    }

    return (
      <Pane key={i} marginY={4} padding={2} title={key}>
        <Text textTransform="capitalize" color="#ffffff">• {res}</Text>
        {isIDOverride && (
          <IconButton 
            icon={TrashIcon} 
            intent="danger" 
            marginLeft={4} 
            size="small" 
            onClick={e => {
              // get the overall parent subselector
              if (parent_type != undefined) {
                let cur_type_subpath = parent_type + "_subSelectors";
                // get the full list of subselectors for the above
                let general_sub_sels_obj = props.config[cur_type_subpath];
                // delete from config
                delete general_sub_sels_obj[key];
                props.onChange(cur_type_subpath, general_sub_sels_obj);
                // delete from the style list as well
                style_list.splice(style_list.indexOf(key), 1);
                props.onUpdateProjectMeta({ style_list });
              }
            }} 
          />
        )}
        {!isOverride && (
          <Text color="#ffffff">*</Text>
        )}
      </Pane>
    );
  });
  // return ``;
}

export function group_it(active_keys, lookup) {
  let res = {};
  active_keys.forEach(key => {
    let sub = lookup(key);
    if (res[sub]) {
      res[sub].push(key);
    } else {
      res[sub] = [key];
    }
    // res[sub] = key;
  });
  return res;
  // return Object.keys(res);
  // return Object.keys(res);
}

// prettier-ignore
function lookup_page_type(k){
  if (k.startsWith("master_clear_")){ return 'ALT Frontmatter'}
  else if (k.startsWith("master_frontmatter")){ return 'Frontmatter'}
  else if (k.startsWith("master_chapter_")){ return 'Chapter'}
  else if (k.startsWith("master_part_")){ return 'Part'}
  else if (k.startsWith("master_backmatter_")){ return 'Backmatter'}
}

// https://stackoverflow.com/a/32184094
// pick('color', 'height')(elmo);
// const pick = (...props) => o =>
//   props.reduce((a, e) => ({ ...a, [e]: o[e] }), {});
//
// function clone_and_prefix(obj, keys, prefix, drop) {
//   let res = {};
//   for (let k in obj) {
//     // console.log(k)
//     if (keys.includes(k)) {
//       res[`${prefix}${k.replace(drop, "")}`] = obj[k];
//     }
//   }
//   return res;
// }

function MarginWidthsAndAreasInput(props) {
  // console.log(props.initialPageMasterKind);
  // let [pageMasterKind, setPageMasterKind] = useState("master_chapter_");
  let [pageMasterKind, setPageMasterKind] = useState(
    props.initialPageMasterKind
  );
  const ui_hierarchy = GetHierarchy(props.project);

  useEffect(
    () => {
      setPageMasterKind(props.initialPageMasterKind);
    },
    [props.initialPageMasterKind]
  );

  let { top_level_ui, second_level_ui } = props;
  let subs = ui_hierarchy[top_level_ui]["data"][second_level_ui]["sub"];

  return (
    <Pane>
      {/*<Code>{start_key}</Code>*/}

      <Pane 
        display="flex" 
        alignItems="center" 
        justifyContent="center" 
        backgroundColor="#5D597A"
        paddingRight={16}
        paddingLeft={12}
        className="pageTemplates"
      >
        {/*<Text marginX={4}>Kind:</Text>*/}
        {/* This seems like a confusing label to me*/}
        <Text marginX={4} color="#ffffff" size={300}>Current page template:</Text>
        <Select
          marginY={8}
          marginLeft={4}
          value={pageMasterKind}
          flex={32}
          width={"100%"}
          onChange={event => setPageMasterKind(event.target.value)}
        >
          {subs.map(k => {
            let label = k.split("_")[1];
            // prettier-ignore
            return (<option key={k} value={k}>{capitalizeFirstLetter(label)}</option> );
          })}
        </Select>
      </Pane>
      <ExpandableStatePane
        label={`${lookup_page_type(pageMasterKind)} Margin Widths`}
      >
        <Pane paddingBottom={16}>
          {/* the S in margins is significant - without it it's the areas (below)*/}
          {props.methods.make_input(`${pageMasterKind}verso_margins`)}
          {props.methods.make_input(`${pageMasterKind}recto_margins`)}
          {props.methods.make_input(`${pageMasterKind}footnoteMargin`)}
        </Pane>
      </ExpandableStatePane>
      <ExpandableStatePane label="Margin Area Contents" initialExpand={true}>
        <MarginAreaInput {...props} pageMasterKind={pageMasterKind} myClass="pageTemplates" />
      </ExpandableStatePane>
    </Pane>
  );
}
// changed to textAlign to match generate_json
// prettier-ignore
let margin_area_suffixes = ["font", "fontSize", "fontWeight", "fontStyle", "textColor", "padding", "backgroundColor", "borderTop", "borderRight", "borderBottom", "borderLeft", "folioStyle", "content", "textAlign", "width", "openType"]
// let margin_area_suffixes = ["font", "fontSize", "fontWeight", "fontStyle", "textColor", "spaceBelow", "backgroundColor", "borderTop", "borderRight", "borderBottom", "borderLeft", "content", "alignment", "width"]

// let margin_area_suffixes = ["font", "fontSize", "fontStyle", "textColor", "spaceBelow", "backgroundColor", "borderTop", "borderRight", "borderBottom", "borderLeft", "content", "alignment"]

function MarginAreaInput(props) {
  let [pageType, setPageType] = useState("verso");
  let [areaType, setAreaType] = useState("top-left");
  // doing this part slightly  differently than the rest of the creator
  // prettier-ignore
  let prefix = `${props.pageMasterKind}${pageType}_margin${capitalizeFirstLetter(dashToCamelCase(areaType))}`;
  let ot_arr = [];
  let key_arr = margin_area_suffixes.map(suf => {
    if (suf === "openType") {
      ot_arr.push(`${prefix}_${suf}`);
      return;
    } else {
      return `${prefix}_${suf}`;
    }
  });
  key_arr = key_arr.filter(key => key != undefined);
  let inputs = key_arr.map(props.methods.make_input);
  let opentype_inputs = ot_arr.map(props.methods.make_input);
  // console.log("✅", key_arr[0]);
  return (
    <Pane display="flex" flexDirection="column" className="pageTemplates">
      <Select
        marginY={8}
        marginLeft={4}
        value={pageType}
        flexBasis={32}
        width={"100%"}
        onChange={event => setPageType(event.target.value)}
      >
        {["verso", "recto", "first", "blank"].map(k => {
          // prettier-ignore
          return (<option key={k} value={k}>{capitalizeFirstLetter(k)}</option> );
        })}
      </Select>
      <AreaPicker areaType={areaType} setAreaType={setAreaType} />
      <Pane display="flex" alignItems="center" justifyContent="center">
        <Code color="#ffffff">{areaType}</Code>
      </Pane>
      <Pane>{inputs}</Pane>
      <ExpandableStatePane label="OpenType Options" initialExpand={false}>
        {opentype_inputs}
      </ExpandableStatePane>
    </Pane>
  );
}

// just for getting the margin area ui right - it's flex so these are relative on the axis
let corner_styles = { width: 42, height: 30 };
let mid_styles = { width: 80, height: 30 };
let side_styles = { width: 30, height: 100 };

function SingleArea(props) {
  let cn = `single_margin_area `;
  cn += props.area === props.areaType ? `selected` : ``;
  return (
    <div
      onClick={() => props.setAreaType(props.area)}
      className={cn}
      style={props.style}
    />
  );
}

// prettier-ignore
// let AREA_LIST = ["top-left-corner", "top-left", "top-center", "top-right", "top-right-corner", "left-top", "left-middle", "left-bottom", "right-top", "right-middle", "right-bottom", "bottom-left-corner", "bottom-left", "bottom-center", "bottom-right", "bottom-right-corner"]

// prettier-ignore
function AreaPicker(props) {
  return (
    <Pane display="flex" justifyContent="center" height={360} marginBottom={24}  >
      <Pane
        width={240}
        height={370}
        display="inline-block"
        background="greenTint"
        border="orange 1px solid"
        flexShrink={0}
        margin={2}
      >
        <Pane display="flex">
          <SingleArea {...props} area="top-left-corner" style={corner_styles} />
          <SingleArea {...props} area="top-left" style={mid_styles} />
          <SingleArea {...props} area="top-center" style={mid_styles} />
          <SingleArea {...props} area="top-right" style={mid_styles} />
          <SingleArea {...props} area="top-right-corner" style={corner_styles} />
        </Pane>
        {/* the two sides, and center area */}
        <Pane display="flex" justifyContent="space-between">
          {/*left side */}
          <Pane display="flex" flexDirection="column">
            <SingleArea {...props} area="left-top" style={side_styles} />
            <SingleArea {...props} area="left-middle" style={side_styles} />
            <SingleArea {...props} area="left-bottom" style={side_styles} />
          </Pane>
          {/* right side*/}
          <Pane display="flex" flexDirection="column">
            <SingleArea {...props} area="right-top" style={side_styles} />
            <SingleArea {...props} area="right-middle" style={side_styles} />
            <SingleArea {...props} area="right-bottom" style={side_styles} />
          </Pane>
        </Pane>
        <Pane display="flex">
          <SingleArea {...props} area="bottom-left-corner" style={corner_styles} />
          <SingleArea {...props} area="bottom-left" style={mid_styles} />
          <SingleArea {...props} area="bottom-center" style={mid_styles} />
          <SingleArea {...props} area="bottom-right" style={mid_styles} />
          <SingleArea {...props} area="bottom-right-corner" style={corner_styles} />
        </Pane>
      </Pane>
    </Pane>
  );
}

function CustomSubselector(props) {
  const [first, setFirst] = useState(props.cur_type);
  const [second, setSecond] = useState(false);
  const [kind, setKind] = useState("within_parent");

  let nav_choices = props.nav_choices;

  const getTag = val => {
    if (val.match("^hsp")) {
      return "span";
    } else if (val.match("^hblk")) {
      return "p";
    } else if (val.match("^hwpr")) {
      return "div";
    } else if (val.match("^hsec")) {
      return "section";
    }
  }

  const makeSelector = (thisKind, val) => {
    let thisFirst = thisKind == "first" 
      ? val
      : first;

    let thisSecond = thisKind == "second" 
      ? val
      : second;

    if (thisFirst && thisSecond) {
      let f_tag = "p";
      let s_tag = "p";

      if (ALL_STRUCTURE.hasOwnProperty(thisFirst)) {
        f_tag = ALL_STRUCTURE[thisFirst];
      } else {
        f_tag = getTag(thisFirst);
      }
      if (ALL_STRUCTURE.hasOwnProperty(thisSecond)) {
        s_tag = ALL_STRUCTURE[thisSecond];
      } else {
        s_tag = getTag(thisSecond);
      }

      props.onViewChange({
        selection_path: [`${f_tag}.${thisFirst}[data-hederis-type="${thisFirst}"]`, `${s_tag}.${thisSecond}[data-hederis-type="${thisSecond}"]`],
        selection_mode: kind,
        content_editing: false,
      });
    }
  }

  return (
    <Pane>
      <Select
        marginY={8}
        marginLeft={4}
        value={first}
        flexBasis={32}
        width={"100%"}
        onChange={event => {
          setFirst(event.target.value);
          makeSelector("first", event.target.value);
        }}
      >
        {nav_choices.map(k => {
          // prettier-ignore
          return (<option key={k} value={k}>{ALL_TYPES[k] || "---"+k}</option> );
        })}
      </Select>
      <Select
        marginY={8}
        marginLeft={4}
        value={kind}
        flexBasis={32}
        width={"100%"}
        onChange={event => setKind(event.target.value)}
      >
        {[{val: "within_parent", label: "anywhere inside"},{val: "direct_child", label: "directly inside"}].map(k => {
          // prettier-ignore
          return (<option key={k.val} value={k.val}>{k.label}</option> );
        })}
      </Select>
      <Select
        marginY={8}
        marginLeft={4}
        value={second}
        flexBasis={32}
        width={"100%"}
        onChange={event => {
          setSecond(event.target.value);
          makeSelector("second", event.target.value);
        }}
      >
        {nav_choices.map(k => {
          // prettier-ignore
          return (<option key={k} value={k}>{ALL_TYPES[k] || "---"+k}</option> );
        })}
      </Select>
    </Pane>
  );
}
