import React, { useState } from "react";
import ReactDOM from "react-dom";
import Foco from "react-foco";

// prettier-ignore
import { Pane, Text, Avatar, Heading, Button, TabNavigation, Tab, Icon, IconButton, Spinner, toaster, Badge, RefreshIcon} from "evergreen-ui";

import { LoadingPane, ProjectTabs } from "../shared/common.js";
import HedButton from "../shared/HedButton.js";
import relativeDate from "tiny-relative-date";

export function ActivityWidget(props) {
  const [expanded, setExpanded] = useState(false);

  const runReload = async () => {
    await props.load_config_and_content(props.project);
    toaster.success("Successfully Loaded Latest Edits!");
  };

  const first_feed_item = props.feed_list ? props.feed_list[0] : false;
  // do we either not have a feed list at all, or haven't finished loading content and config yet
  if (
    !first_feed_item ||
    !props.last_loaded_html_hash ||
    !props.last_loaded_conf_hash
  ) {
    return <></>;
  }

  const date = first_feed_item.ts.seconds * 1000;

  const latest_with_hash = props.feed_list.find(a => a.hash);

  // it exists, is not the current user, and is in the set of activites we want to do a big notification about
  const may_need_notif =
    latest_with_hash &&
    latest_with_hash.user.uid !== props.hederis_user.uid &&
    ["saved text", "saved design"].includes(latest_with_hash.kind);

  const curHashes = [props.last_loaded_html_hash, props.last_loaded_conf_hash];

  if (may_need_notif) {
    if (!curHashes.includes(latest_with_hash.hash)) {
      return (
        <Pane
          marginLeft={8}
          alignSelf="center"
          backgroundColor={"#B3B0C6"}
          paddingY={5}
          paddingX={16}
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <Pane align="center" display="flex">
            <Text align="center" size={300} color={"#ffffff"}>
              {latest_with_hash.user.displayName} made changes
            </Text>
          </Pane>
          <HedButton
            height={24}
            iconBefore={RefreshIcon}
            appearance="hedsecondary"
            boxShadow="none"
            onClick={runReload}
          >
            Load Latest Edits?
          </HedButton>
        </Pane>
      );
    }
  }

  return (
    <>
      <Pane
        display="flex"
        flexDirection="row"
        marginLeft={12}
        cursor="pointer"
        onClick={() => {
          if (!expanded) {
            setExpanded(true);
          }
        }}
      >
        <Text size={300} color="#ffffff" textDecoration="underline">
          Last {first_feed_item.kind}
        </Text>
        <Avatar src={first_feed_item.user.photoURL} size={18} marginX={2} />
        <Text size={300} color="#ffffff" textDecoration="underline">
          ~{relativeDate(date)}
        </Text>
      </Pane>
      <Foco
        onClickOutside={() => {
          setExpanded(false);
        }}
      >
        <ActivityFeedList {...props} expanded={expanded} />
      </Foco>
    </>
  );
}

export function ActivityFeedItem(props) {
  const { item } = props;
  // XXX when it's local, ts is null evidently, hence server time stamp I guess
  const date = item.ts ? item.ts.seconds * 1000 : new Date();

  return (
    <Pane display="flex" width={400}>
      <Pane marginRight={8} display="flex" alignItems="center">
        <Avatar src={item.user.photoURL} size={18} marginRight={2} />
        <Text>{item.user.displayName}</Text>
      </Pane>
      <Pane>
        <Text>{item.kind}</Text>
        <Text> ~ {relativeDate(date)}</Text>
      </Pane>
    </Pane>
  );
}

export function ActivityFeedList(props) {
  // prettier-ignore
  const expanded = props.expanded;
  if (!props.feed_list) {
    return null;
  }
  return (
    <Pane
      display="flex"
      flexDirection="column"
      height={expanded ? "auto" : 0}
      border={expanded ? "default" : "none"}
      padding={expanded ? 3 : 0}
      userSelect={"none"}
      overflow={"hidden"}
      position="absolute"
      top={32}
      left={64}
      zIndex={5}
      backgroundColor="white"
    >
      {props.feed_list.map(item => (
        <ActivityFeedItem item={item} key={item.id} />
      ))}
    </Pane>
  );
}

// this state and the effect below simply cause this comp to rerender ever two min to update the relative dates
// const update_interval = 120;
// const [now, setNow] = useState(new Date());
// // console.log("render");
// useEffect(
//   () => {
//     let updater = () => setNow(new Date());
//     let cancel_code = window.setInterval(updater, update_interval * 1000);
//     return () => {
//       window.clearInterval(cancel_code);
//     };
//   },
//   [output]
// );
