import React from "react";
import ReactDOM from "react-dom";
import { Pane, Text, Link, IconButton, Heading, toaster } from "evergreen-ui";

import { ProjectInfoView, ProjectMetaView } from "../shared/info.js";

import ProjectSettingsView from "../shared/settings.js";
// prettier-ignore
import { section_parse, cleanup_content, fix_self_closing_tags } from "../../../api/app_shared/process/section_parse.js";
import { apply_changes, digestMessage, sanitizeHtml } from "../../helpers/util.js";
import { config_parse } from "../../helpers/config_parse.js";
import generate_json from "../../helpers/generate_json.js";

import { NotFound } from "../shared/common.js";
import DesignEditor from "./design_editor.js";
// import StructureView from "./structure_view.js";
import StructureEditorView from "../hederis_comp_structure_editor/structure_editor.js";
import FileDifferView from "../shared/diff.js";

import { firebase, db, storage } from "../../fire.js";
import APIClient from "../../helpers/api_client";
import { FireHelpers } from "../../../api/app_shared/fire_helpers.js";
const API = new APIClient(firebase);
const FH = new FireHelpers(db, firebase);

import AdvancedDashboard from "./dash.js";

const AreaCompMap = {
  dash: AdvancedDashboard,
  settings: ProjectSettingsView,
  meta: ProjectMetaView,
  design: DesignEditor,
  compare: FileDifferView,
  edit: StructureEditorView,
};

const SECTION_MARKER_STRING = `\n<!-- *HED_SECTION_MARKER*-->\n`;

export default class AdvancedApp extends React.Component {
  constructor(props) {
    super(props);
    window.debug_app_advanced = this;
    this.get_font_warnings = this.get_font_warnings.bind(this);
    this.state = {
      full_config: false,
      section_list: false,
      external_preview_page_count: false,
      changes: [],
      user_fonts: [],
      bill_for_project: false,
      font_warnings: null,
      custom_css: false,
      saving: false,
      docx_html: "",
    };
  }
  // when the user edits the config json, update it here
  onDesignConfigEdit({ input_key, value }) {
    let { full_config } = this.state;
    full_config[input_key] = value;
    this.setState({ full_config });
  }
  // when the user updates the html, set it here, by section_index
  // afterset is essentially a callback, used in two cases:
  // 1. callFrameFunc, for insert and remove commands, because we have to rebake it
  // 2. when we're saving, we use this to bake it before we actually save
  // prettier-ignore
  onDesignHtmlEdit({ section_index, sect_html, baked_sect_html }, afterSet = ()=>{} ) {
    let { section_list } = this.state;
    let cur_sect = section_list[section_index];
    // replace these if we have them, we send an empty string in cases they shouldn't be updated
    if (sect_html){
      cur_sect.content = sect_html;
    }
    if (baked_sect_html){
      cur_sect.baked_sect_html = baked_sect_html;
    }
    // put it back in
    section_list[section_index] = cur_sect;
    // set the stat
    this.setState({ section_list }, afterSet);
  }
  // called from dash
  async getHtmlAfterDocxUpload() {
    // this will just return an empty string if we call it before the output is ready!
    let { unbaked_book_content, baked_book_html } = await fetchAdvHtml(
      this.props.project
    );
    unbaked_book_content = fix_self_closing_tags(unbaked_book_content);
    let section_list = section_parse(unbaked_book_content);
    // console.log("getHtmlAfterDocxUpload section_list:", section_list);
    // *now* generate the book meta - and update the project in the db with it, see maybeGenerateMetaForOldProjects for handling of docx's uploaded before this change
    // XXX this will overwrite the existing,
    // which is ok for now because they're reuploading the docx
    let section_meta = generate_book_meta(section_list);
    this.props.updateProjectMeta({ section_meta });
    this.setState({ section_list });
    // and also requery the bill
    if (!this.state.bill_for_project) {
      const project_id = this.props.id;
      const bill_for_project = await FH.get_bill_for_project(project_id);
      this.setState({ bill_for_project });
    }
    this.props.addActivity("uploaded doc");
  }
  get_project_path() {
    let { project } = this.props;
    return `${project.group}/${this.props.id}/`;
  }
  // hacky temp solution to style_list problem XXX -
  async onDesignConfigSave(change) {
    toaster.warning("Please wait...");
    let { section_index, sect_html, baked_sect_html, is_loading } = change;
    this.setState({saving: true});
    if (is_loading || !this.state.section_list) {
      return;
    }
    this.onDesignHtmlEdit(
      { section_index, sect_html, baked_sect_html },
      async () => {
        await this.doDesignSaveAllConfig();
        this.setState({saving: false});
        toaster.closeAll();
        toaster.success("Design Saved!");
      }
    );
  }
  async doDesignSaveAllConfig() {
    let { full_config, section_list } = this.state;
    // prettier-ignore
    if (!full_config ) { return }
    let { project } = this.props;
    let project_path = this.get_project_path();
    let { advanced_config_path } = project;
    let advanced_config_version = ADV_CONFIG_VERSION;

    // set it to this if we don't have it already, if we're using a template it should be set
    //  XXX test that !
    // also a new project
    if (!advanced_config_path) {
      advanced_config_path = `${project_path}advanced_config/config.json`;
      this.props.updateProjectMeta({
        advanced_config_version,
        advanced_config_path,
      });
    }

    let { style_list, advanced_config } = reconcile_content_and_config(
      section_list,
      full_config,
      this.props.project
    );

    let storageRef = storage.ref();
    let configRef = storageRef.child(advanced_config_path);
    // let snapshot = await configRef.putString(JSON.stringify(full_config));
    let snapshot = await configRef.putString(JSON.stringify(advanced_config));

    this.props.updateProjectMeta({ style_list });
    const hash = await digestMessage(JSON.stringify(advanced_config));
    this.props.addActivity("saved design", hash);
  }

  async onDesignHtmlSave(change) {
    toaster.warning("Please wait...");
    // prettier-ignore
    let { section_index, sect_html, baked_sect_html, is_loading } = change;
    this.setState({saving: true});

    // addresses #541
    if (is_loading || !this.state.section_list) {
      return;
    }
    // the second arg below is effectively a callback for once onDesignHtmlEdit has run
    // so we can be sure to deal with baking..oh hm.
    this.onDesignHtmlEdit(
      { section_index, sect_html, baked_sect_html },
      async () => {
        await this.doDesignSaveAllHtml();
        this.setState({saving: false});
        toaster.closeAll();
        toaster.success("Text Saved!");
      }
    );
  }

  async doDesignSaveAllHtml() {
    try {
      let { full_config, section_list, changes } = this.state;

      // get the unpaged html from the section_list
      // prettier-ignore
      let {html_content, baked_html, style_list} = reconcile_content_and_config(section_list, full_config, this.props.project);

      let project_path = this.get_project_path();

      // apply any text changes made in the locked html, to the unlocked html
      [html_content, baked_html, changes] = this.apply_and_save_changes(
        project_path,
        html_content,
        baked_html
      );

      let { html_content_path, baked_html_path } = await saveFromDesignEditor(
        { html_content, baked_html, style_list, project_path },
        this.props.updateProjectMeta
      );
      // for ui/ux reasons, this isn't await, i.e. we're done saving before the docx is done
      let bake_docx_response = API.run_bake_docx({
        project: this.props.project,
        project_id: this.props.id,
        html_path: html_content_path,
        hederis_user: this.props.hederis_user,
      });
      const { sanitized_config, sanitized_html_content } = prep_html_for_digest_hash({section_list: section_list, full_config: full_config, project: this.props.project});
      const hash = await digestMessage(sanitized_html_content);
      this.setState({ changes });
      this.props.addActivity("saved text", hash);
    } catch (error) {
      toaster.danger("An unknown error occurred. Please try again.");
      console.error(error);
    }
  }

  async onSaveCustomCss(custom_css_string) {
    let project_path = this.get_project_path();
    let custom_css_path = await saveCustomCss(
      project_path,
      custom_css_string,
      this.props.updateProjectMeta
    );
    this.setState({ custom_css: custom_css_string });
    toaster.success("Custom CSS Saved!");
  }

  async componentDidMount() {
    const project_id = this.props.id;
    if (!this.props.area) {
      let loc = `#/projects/${project_id}/dash`;
      let url = window.location.toString().split("#")[0];
      // using replace prevents this from going on the history stack, preventing a bug when the user hits back
      window.location.replace(`${url}${loc}`);
    }

    this.load_config_and_content(this.props.project);
    let custom_css = await fetchCustomCss(this.props.project);
    this.load_fonts();
    this.get_font_warnings(project_id);
    const discount_obj_for_project = await FH.get_disco(
      this.props.project.project_discount_code
    );
    // TODO want to requery this bill when we're building the first time
    const bill_for_project = await FH.get_bill_for_project(project_id);
    // console.log(bill_for_project);
    // console.log(discount_obj_for_project);
    this.setState({ bill_for_project, discount_obj_for_project, custom_css });
    // console.log("TODO uncomment activity open");
    this.props.addActivity("opened");
  }
  // we also call this to refresh them after adding a P or G
  async load_fonts() {
    const project_id = this.props.id;
    const group = this.props.project.group;
    const user_uid = this.props.hederis_user.uid;
    const user_fonts = await FH.get_appropriate_user_fonts({
      project_id,
      group,
      user_uid,
    });
    // console.log("loaded list of appropriate user fonts:");
    // console.log(user_fonts);
    this.setState({ user_fonts });
  }
  // moving forward, this will be generated on docx upload, this just keeps us backwards compatible
  maybeGenerateMetaForOldProjects(section_list) {
    if (!this.props.project["section_meta"] && section_list.length) {
      console.log("generating meta for a book that lacked it");
      let section_meta = generate_book_meta(section_list);
      this.props.updateProjectMeta({ section_meta });
    }
  }
  apply_and_save_changes(project_path, html_content, baked_html) {
    let changes_made = [];
    let changes_not_made = [];
    if (this.state.changes.length > 0) {
      [
        html_content,
        baked_html,
        changes_made,
        changes_not_made,
      ] = apply_changes(html_content, baked_html, this.state.changes);
      if (changes_made.length > 0) {
        let saved = saveAppliedChanges(project_path, changes_made);
        // for now, we'll go ahead and reload the section list from the unlocked HTML
        let section_list = section_parse(html_content);
        // prettier-ignore
        section_list = this.reconcileSectionList(section_list, html_content, baked_html);

        this.setState({ section_list });
      }
      if (changes_not_made.length > 0) {
        let section_meta = this.props.project.section_meta;
        changes_not_made.map(change => {
          let arr = [];
          // for each change, get the section ID
          // then add to the "pending changes" obj in the meta
          if (
            section_meta.hasOwnProperty(change.section) &&
            section_meta[change.section].hasOwnProperty("pending_changes")
          ) {
            arr = section_meta[change.section]["pending_changes"];
          }
          arr.push(change);
          if (section_meta.hasOwnProperty(change.section)) {
            section_meta[change.section]["pending_changes"] = arr;
            this.props.updateProjectMeta({ section_meta });
          }
        });
      }
    }
    return [html_content, baked_html, changes_not_made];
  }
  async load_config_and_content(project) {
    // prettier-ignore
    let { full_config, unbaked_book_content, baked_book_html } = await fetchAdvContentAndConfig(project);
    // console.log(unbaked_book_content);
    unbaked_book_content = fix_self_closing_tags(unbaked_book_content);
    let section_list = section_parse(unbaked_book_content);

    this.maybeGenerateMetaForOldProjects(section_list);
    // prettier-ignore
    section_list = this.reconcileSectionList(section_list, unbaked_book_content, baked_book_html);
    const { sanitized_config, sanitized_html_content } = prep_html_for_digest_hash({section_list: section_list, full_config: full_config, project: project});
    const last_loaded_html_hash = await digestMessage(sanitized_html_content);
    const last_loaded_conf_hash = await digestMessage(
      JSON.stringify(sanitized_config)
    );
    this.props.on_load_hashes({ last_loaded_html_hash, last_loaded_conf_hash });

    this.setState({
      full_config,
      section_list,
    });
  }
  reconcileSectionList(section_list, unbaked_book_content, baked_book_html) {
    let maybe_baked_section_arr = baked_book_html.split(SECTION_MARKER_STRING);
    section_list = section_list.map((sect_obj, i) => {
      sect_obj["baked_sect_html"] = maybe_baked_section_arr[i];
      if (
        this.props.project["section_meta"] &&
        this.props.project["section_meta"].hasOwnProperty(sect_obj.id)
      ) {
        sect_obj["is_locked"] = this.props.project["section_meta"][sect_obj.id][
          "is_locked"
        ];
      } else {
        sect_obj["is_locked"] = false;
      }
      return sect_obj;
    });
    return section_list;
  }
  // for canceling/reverting
  // for use in the children - pass the path so we don't have to worry about a race condition with when the project obj gets updated
  async reloadConfig(advanced_config_path) {
    let full_config = await fetchAdvConfig(advanced_config_path);
    this.setState({ full_config });
  }
  async onFetchDocxHtml(project) {
    let myDocxHtml = await fetchDocxHtml(project);
    console.log(myDocxHtml);
    this.setState({ docx_html: myDocxHtml.docx_html_content });
  }
  componentWillUnmount() {
    console.log("app advanced unmount");
  }
  async onStructureHtmlSave() {
    toaster.warning("Saving book text - please stay on this page", {duration: 10,});
    // console.log("save clicked", this.state.section_list);

    let project_path = this.get_project_path();
    let html_content = this.state.section_list.map(x => x.content).join("");
    let baked_html = html_content;
    let storageRef = storage.ref();

    let html_content_path = `${project_path}html/book.html`;
    let baked_html_path = `${project_path}baked_html/book.html`;

    this.props.updateProjectMeta({
      "output.html": {
        path: html_content_path,
        ts: firebase.firestore.FieldValue.serverTimestamp(),
        from_client: true,
      },
      "output.baked_html": {
        path: baked_html_path,
        ts: firebase.firestore.FieldValue.serverTimestamp(),
        from_client: true,
      },
    });
    let bakedHtmlRef = storageRef.child(baked_html_path);
    await bakedHtmlRef.putString(baked_html);

    let htmlContentRef = storageRef.child(html_content_path);
    await htmlContentRef.putString(html_content);

    toaster.closeAll();
    toaster.success("Book Text Saved!", { duration: 10 });
    const { sanitized_config, sanitized_html_content } = prep_html_for_digest_hash({section_list: this.state.section_list, full_config: this.state.full_config, project: this.props.project});
    const hash = await digestMessage(sanitized_html_content);
    this.props.addActivity("saved text", hash);
    // for ui/ux reasons, this isn't await, i.e. we're done saving before the docx is done
    let bake_docx_response = API.run_bake_docx({
      project: this.props.project,
      project_id: this.props.id,
      html_path: html_content_path,
      hederis_user: this.props.hederis_user,
    });
  }
  // counterpart to onDesignHtmlEdit, except the logic is in the structure_editor
  onStructureHtmlEdit(section_list) {
    // console.log("on edit");
    this.setState({ section_list });
  }

  all_object_values_match(obj1Original, obj2Original) {
    
    if ( ! ( obj1Original instanceof Object ) || ! ( obj2Original instanceof Object ) ) return false;

    let obj1 = JSON.parse(JSON.stringify(obj1Original));
    let obj2 = JSON.parse(JSON.stringify(obj2Original));

    if ( obj1 === obj2 ) return true;

    for ( var k in obj1 ) {
      if ( !obj2.hasOwnProperty(k) ) {
        return false
      } else {
        if ( typeof( obj1[k] ) == "object" ) {
          return this.all_object_values_match(obj1[k], obj2[k]);
        } else if ( obj1[k] !== obj2[k] ) {
          return false;
        } else {
          delete obj2[k];
        }
      }
    }
    if (Object.keys(obj2).length > 0) {
      return false;
    } else {
      return true;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    update_font_warnings_on_project_update(
      prevProps.project,
      this.props.project,
      this.props.id,
      this.get_font_warnings
    );
    run_toasters_on_project_update(prevProps.project, this.props.project);
    //   if the html path is updated, not from client, grab it
    // trigger a reload (or initial load) of our html in app_advanced
    let project = this.props.project;
    let prevProject = prevProps.project;
    // const ingredientsListDeepCopy = JSON.parse(JSON.stringify(ingredientsList));
    if (project.output.html && !project.output.html.from_client) {
      let project_and_prevproject_match = this.all_object_values_match(
        prevProject.output.html, 
        project.output.html
      );
      if (!project_and_prevproject_match) {
        console.log("fetching updated html");
        this.getHtmlAfterDocxUpload();
      }
    }
  }

  get_font_warnings(project_id) {
    let docRef = db
      .collection("projects")
      .doc(project_id)
      .collection("fontWarnings")
      .doc("latest");

    docRef
      .get()
      .then(doc => {
        if (doc.exists) {
          this.setState({ font_warnings: doc.data().warnings });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(error => {
        console.error("Error getting document:", error);
      });
  }

  async update_change_list(newChanges) {
    try {
      if (newChanges.length > 0) {
        this.setState({
          changes: [...this.state.changes, ...newChanges],
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async update_section_lock_status(
    section_id,
    section_index,
    should_lock,
    afterSet
  ) {
    // console.log(" TODO called update_section_lock_status stub");

    let { section_list } = this.state;
    let cur_sect = section_list[section_index];
    cur_sect.is_locked = should_lock;
    // cur_sect.baked_sect_html = baked_sect_html;
    // put it back in
    section_list[section_index] = cur_sect;
    // set the stat
    this.setState({ section_list }, afterSet);

    // this updates in fb
    let dot_key = `section_meta.${section_id}.is_locked`;
    this.props.updateProjectMeta({
      [dot_key]: should_lock,
    });
  }

  async trigger_rebuild() {
    // toaster.closeAll();
    toaster.notify("Rebuilding Book!", { duration: 900 });
    // console.log("trigger rebuild");
    let project_id = this.props.id;
    // or possibly the receivers get the project from the db.
    // we really only need to pass project_id, user displayname and uid, and docx path above
    let { project, hederis_user } = this.props;
    // use the baked html path if we have it, use the normal if we do not
    let html_path = project.output.baked_html
      ? project.output.baked_html.path
      : project.output.html.path;

    // we only use this html to generate the JSON
    let html_content = this.state.section_list.map(x => x.content).join("");

    let alt_text = this.props.alt_text ? this.props.alt_text : false;

    let payload = {
      html_path,
      hederis_user,
      project_id,
      project,
      alt_text,
    };

    let res = await API.trigger_rebuild(payload);

    if (!res) {
      toaster.closeAll();
      toaster.warning("There was an error rebuilding", { duration: 10 });
      return;
    }
  }
  processClone(clone_data) {
    console.log("processClone");
    console.log(Object.keys(clone_data).length);
    // todo generate
    let html_content = this.state.section_list.map(x => x.content).join("");
    // console.log(html_content.length);
    let advanced_config_version = 0;

    if (this.props.project.advanced_config_version) {
      advanced_config_version = this.props.project.advanced_config_version;
    }

    let config_and_style_list = generate_json(
      html_content,
      clone_data,
      advanced_config_version
    );
    let advanced_config = config_and_style_list[0];
    let style_list = config_and_style_list[1];
    this.props.updateProjectMeta({ style_list });
    // console.log(advanced_config, style_list);
    console.log(Object.keys(advanced_config).length);

    return advanced_config;
    // todo, update style list
  }
  onExternalDone({ page_count }) {
    let external_preview_page_count = page_count;
    this.setState({ external_preview_page_count });
  }
  render() {
    // basically a simple router
    let Component = AreaCompMap[this.props.area] || AdvancedDashboard;
    // don't load the design view /area if section list has not yet loaded
    if (
      ["design", "edit"].includes(this.props.area) &&
      !this.state.section_list
    ) {
      // console.log("na");
      return ``;
    }
    // extra_props are functions in this wrapper that we want to allow our children (in the dash, design, etc, views) to use
    // prettier-ignore
    let extra_props = {onFetchDocxHtml:this.onFetchDocxHtml.bind(this), onDesignConfigEdit:this.onDesignConfigEdit.bind(this), onDesignHtmlSave:this.onDesignHtmlSave.bind(this), onDesignConfigSave:this.onDesignConfigSave.bind(this), onDesignHtmlEdit:this.onDesignHtmlEdit.bind(this), trigger_rebuild: this.trigger_rebuild.bind(this), update_change_list: this.update_change_list.bind(this), reloadConfig:this.reloadConfig.bind(this), processClone:this.processClone.bind(this), load_config_and_content:this.load_config_and_content.bind(this), update_section_lock_status:this.update_section_lock_status.bind(this), onExternalDone:this.onExternalDone.bind(this),   load_fonts: this.load_fonts.bind(this), onStructureHtmlEdit:this.onStructureHtmlEdit.bind(this), onStructureHtmlSave:this.onStructureHtmlSave.bind(this), onSaveCustomCss:this.onSaveCustomCss.bind(this)}
    return <Component {...this.props} {...this.state} {...extra_props} />;
  }
}

async function fetchAdvConfig(advanced_config_path) {
  let url = await storage.ref(advanced_config_path).getDownloadURL();
  let res = await fetch(url);
  let data = await res.text();
  let advanced_config = JSON.parse(data);
  return advanced_config;
}
// prettier-ignore
async function fetchDocxHtml(project) {
  let has_html_path = project.output && project.output.docx_html && project.output.docx_html.path
  let docx_html_content = has_html_path ? await fetchBookContent(project.output.docx_html.path) : "";
  return { docx_html_content }
  // project.output.baked_html.path
}
// prettier-ignore
async function fetchAdvHtml(project) {
  let has_html_path = project.output && project.output.html && project.output.html.path
  let unbaked_book_content = has_html_path ? await fetchBookContent(project.output.html.path) : "";
  let has_baked_path = has_html_path && project.output.baked_html && project.output.baked_html.path
  let baked_book_html = has_baked_path ? await fetchBookContent(project.output.baked_html.path) : "";
  return { unbaked_book_content, baked_book_html }
  // project.output.baked_html.path
}
async function fetchBookContent(path) {
  let url = await storage.ref(path).getDownloadURL();
  let res = await fetch(url);
  return await res.text();
}

async function fetchAdvContentAndConfig(project) {
  let full_config = project.advanced_config_path
    ? await fetchAdvConfig(project.advanced_config_path)
    : {};

  let { unbaked_book_content, baked_book_html } = await fetchAdvHtml(project);
  // let has_html_path = project.output && project.output.html && project.output.html.path
  // let unbaked_book_content = has_html_path ? await fetchAdvHtml(project) : "";

  return { full_config, unbaked_book_content, baked_book_html };
}

async function fetchCustomCss(project) {
  let custom_css = false;
  if (project.custom_css && project.custom_css.path) {
    let url = await storage.ref(project.custom_css.path).getDownloadURL();
    let res = await fetch(url);
    custom_css = await res.text();
  }
  return custom_css;
}

async function saveCustomCss(
  project_path,
  custom_css_string,
  updateProjectMeta
) {
  let storageRef = storage.ref();
  let custom_css_path = `${project_path}advanced_config/custom.css`;
  updateProjectMeta({
    custom_css: {
      path: custom_css_path,
      ts: firebase.firestore.FieldValue.serverTimestamp(),
      from_client: true,
    },
  });
  let customCssRef = storageRef.child(custom_css_path);
  await customCssRef.putString(custom_css_string);

  return custom_css_path;
}

// hacky repurposing from when this was in design_editor
// prettier-ignore
async function saveFromDesignEditor( {project_path, html_content, baked_html, style_list}, updateProjectMeta) {
// async function saveFromDesignEditor( {project_path, html_content, baked_html, style_list, bake}, updateProjectMeta) {
  let storageRef = storage.ref();
  // console.log('save from design editor called')
  let baked_html_path = `${project_path}baked_html/book.html`;
  let html_content_path = `${project_path}html/book.html`;
  // tell state and firestore database about all this
  updateProjectMeta({
    style_list: style_list,
    "output.html": {
      path: html_content_path,
      ts: firebase.firestore.FieldValue.serverTimestamp(),
      from_client: true,
    },
    "output.baked_html": {
      path: baked_html_path,
      ts: firebase.firestore.FieldValue.serverTimestamp(),
      from_client: true,
    },
  });
  let bakedHtmlRef = storageRef.child(baked_html_path);
  await bakedHtmlRef.putString(baked_html);

  let htmlContentRef = storageRef.child(html_content_path);
  await htmlContentRef.putString(html_content);

  return { html_content_path, baked_html_path };
}

async function saveAppliedChanges(project_path, changes_made) {
  let changes_path = "";
  try {
    let storageRef = storage.ref();
    let ts = Date.now();
    changes_path = `${project_path}changes_done/${ts}.json`;
    let changesRef = storageRef.child(changes_path);
    await changesRef.putString(JSON.stringify(changes_made));
  } catch (error) {
    console.error(error);
  }
  return changes_path;
}

// just using this as a hacky way to notify that builds are done
// this is an inelegant way to check if it's changed and exists,
function run_toasters_on_project_update(prevProject, project) {
  if (
    project.ingest_messages.length &&
    project.ingest_messages[0]["type"] === "error" &&
    !prevProject.ingest_messages.length
  ) {
    toaster.closeAll();
  }
  if (
    project.output.html &&
    !project.output.html.from_client &&
    prevProject.output.html && 
    JSON.stringify(prevProject.output.html.ts) !==
      JSON.stringify(project.output.html.ts)
  ) {
    toaster.closeAll();
    toaster.success("Conversion Complete!", { duration: 30 });
    // every convert will have a build, and we want to keep a notification up while we're working
    toaster.success("Building the book...", { duration: 900 });
  }
  if (
    project.output.pdf &&
    prevProject.output.pdf &&
    JSON.stringify(prevProject.output.pdf.ts) !==
      JSON.stringify(project.output.pdf.ts)
  ) {
    toaster.closeAll();
    toaster.success("Build Complete!");
  }
}

// prettier-ignore
function update_font_warnings_on_project_update(prevProject, project, project_id, get_font_warnings) {
  if (
    project.output.pdf &&
    prevProject.output.pdf &&
    JSON.stringify(prevProject.output.pdf) !==
      JSON.stringify(project.output.pdf)
  ) {
    get_font_warnings(project_id);
  }
}

function generate_book_meta(section_list, section_meta = {}) {
  // console.log("called generate_book_meta");
  // console.log("section_list:", section_list);

  // only needs to update if there is a new upload,
  // or sections move around, or an individual field is
  // updated (like locked).
  // IF obj already exists, need to try to map
  // old data (locked, pagestart, etc.) to new
  // object.
  // EXCEPT when uploading a new MS,
  // the obj will always get overwritten.
  // We'll assume that we are ALWAYS updating
  // all sections at the same time.

  let timestamp = new Date().getTime();

  let myid = undefined;
  let allIds = [];

  let section_item = section_list.map((sect_obj, i) => {
    // look in the actual section HTML to find the ID
    if (sect_obj.hasOwnProperty("content")) {
      let myRegexp = /(^<[^>]*id=["'])(\S+)/g;
      let match = myRegexp.exec(sect_obj["content"]);
      myid = match[2].replace(/["']*/g, "");
      if (!section_meta.hasOwnProperty(myid)) {
        // create the fields
        section_meta[myid] = {};
        section_meta[myid]["created"] = timestamp;
      }
      section_meta[myid]["index"] = i;
      section_meta[myid]["updated"] = timestamp;
      allIds.push(myid);
    }
    if (myid && sect_obj.hasOwnProperty("title")) {
      section_meta[myid]["title"] = sect_obj["title"];
    }
  });
  for (const sectid in section_meta) {
    if (allIds.indexOf(sectid) < 0) {
      console.log("removing index for old sect metadata: ", sectid);
      delete section_meta[sectid]["index"];
    }
  }
  // console.log("section meta:");
  // console.log(section_meta);
  return section_meta;
}

// prettier-ignore
export function reconcile_content_and_config(section_list, full_config, project) {
  let html_content = section_list.map(x => x.content).join("");
  // could use locked ness hrm
  // prettier-ignore
  let baked_html = section_list.map(x=>x.is_locked ? x.baked_sect_html : x.content).join(SECTION_MARKER_STRING);
  // let baked_html = section_list.map(x=>x.baked_sect_html || x.content).join(SECTION_MARKER_STRING);
  let advanced_config_version = 0;

  if (project.advanced_config_version) {
    advanced_config_version = project.advanced_config_version;
  }

  let config_and_style_list = generate_json(
    html_content,
    full_config,
    advanced_config_version
  );
  let advanced_config = config_and_style_list[0];
  let style_list = config_and_style_list[1];
  let id_subselectors = config_parse(advanced_config);
  html_content = cleanup_content(html_content, id_subselectors);
  html_content = html_content.replace(
    /(<img)(((?![\/>]).)*)(>)((?!<\/))/g,
    "$1$2/$4$5"
  );
  html_content =
    "<!DOCTYPE html><html><head><meta charset='utf-8'/></head><body>" +
    html_content +
    "</body></html>";
  baked_html = cleanup_content(baked_html, id_subselectors);
  baked_html = baked_html.replace(
    /(<img)(((?![\/>]).)*)(>)((?!<\/))/g,
    "$1$2/$4$5"
  );

  return { html_content, baked_html, style_list, advanced_config };
}

export function prep_html_for_digest_hash(props) {
  let { advanced_config, html_content } = reconcile_content_and_config(
    props.section_list,
    props.full_config,
    props.project
  );
  let sanitized_config = advanced_config;
  let sanitized_html_content = sanitizeHtml(html_content);
  return { sanitized_config, sanitized_html_content };
}
