import React from "react";
import ReactDOM from "react-dom";

import { Pane, Text, Heading, Button, Code, toaster, CloudUploadIcon } from "evergreen-ui";

export default class UploadPrompt extends React.Component {
  constructor(props) {
    super(props);
    this.hiddenInputRef = React.createRef();
  }
  onChangeFile(event) {
    event.stopPropagation();
    event.preventDefault();
    let files = event.target.files;
    this.props.onFileDrop(files);
  }
  render() {
    let { label, accept = "*" } = this.props;
    return (
      <Pane display="flex" flexDirection="column" alignItems="center">
        <input
          type="file"
          accept={accept}
          ref={this.hiddenInputRef}
          style={{ display: "none" }}
          onChange={this.onChangeFile.bind(this)}
        />
        <Button
          margin={0}
          appearance="default"
          height={56}
          paddingX={24}
          onClick={e => this.hiddenInputRef.current.click()}
          iconBefore={CloudUploadIcon}
        >
          <Text size={500}> {label || "Add a file!"}</Text>
        </Button>
        {this.props.children || (
          <Pane paddingBottom={16}>
            <Text>Drag a file from your computer or click above to upload</Text>
          </Pane>
        )}
      </Pane>
    );
  }
}

// <Text>
// Click here or drag a <Code>.docx</Code> Word file from your computer
// </Text>
