import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
// prettier-ignore
import { Pane, Code, Tooltip, Popover, SelectMenu, Button, Text, IconButton, CaretDownIcon, PlusIcon, TrashIcon, PropertyIcon, RemoveColumnIcon, CircleArrowUpIcon, CircleArrowDownIcon } from "evergreen-ui";

// prettier-ignore
import { AttributePopoverSection, AttributePopoverElement } from './structure_attribute_editors.js'

// NOTE: This group of imports come from files that
// are created automatically from the hederis core project;
// if you need to make any edits, please edit in that repo 
// and then redistribute as appropriate.
// BEGINGROUP--------
import ALL_TYPES from "./shared/types.json";
import srules from "./shared/structure.json";
// ENDGROUP--------

export function ItemChangeTypeMenu(props) {
  // prettier-ignore
  const { wrapperMode, item, isSelected, operations, section_id, parent_type } = props;
  const { hederis_type } = item;
  const text_label = wrapperMode ? (
    <Text textDecoration="underline" size={300}>
      {hederis_type_to_user(hederis_type)}
    </Text>
  ) : (
    <Text size={300}>{hederis_type_to_user(hederis_type)}</Text>
  );
  if (!isSelected) {
    return text_label;
  } else {
    const changeOptions = get_appropriate_type_options({
      hederis_type,
      parent_type,
    });

    return (
      <SelectMenu
        closeOnSelect={true}
        title={`Change Type`}
        selected={item.hederis_type}
        options={changeOptions.map(entry => ({
          label: entry[1],
          value: entry[0],
        }))}
        onSelect={choice =>
          operations.elementChangeType(section_id, item.id, choice.value)
        }
      >
        <Button iconBefore={CaretDownIcon} height={24}>
          {text_label}
        </Button>
      </SelectMenu>
    );
  }
}

// onKeyDown
export function preventArrowKeys(e) {
  // console.log(e.keyCode);
  // XXX this is a hacky fix around a bug wherein pressing the up or down
  // keys while one of the below selectmenus is open causes the selectmenu items
  // to get selected. It's because we should be using Select or Menu, but they lack the filter, so we're using SelectMenu uncontrolled
  if (e.keyCode === 38 || e.keyCode === 40) {
    e.preventDefault();
    e.stopPropagation();
  }
}

export function InsertSectionAbove(props) {
  const { section, operations } = props;
  return (
    <>
      <Pane onKeyDown={preventArrowKeys} display="flex">
        <SelectMenu
          closeOnSelect={true}
          title="Insert Section Here"
          options={SECT_TYPE_ARR.map(entry => ({
            label: entry[1],
            value: entry[0],
          }))}
          onSelect={choice =>
            operations.sectionInsert(section.id, choice.value, 0)
          }
        >
          <IconButton
            icon={PlusIcon}
            intent="none"
            marginRight="auto"
            marginLeft="auto"
            appearance="default"
            height={24}
            borderRadius="12px"
            onMouseOver={e => props.setHoveringAbove(true)}
            onMouseOut={e => props.setHoveringAbove(false)}
          />
        </SelectMenu>
      </Pane>
    </>
  );
}

export function InsertSectionBelow(props) {
  const { section, operations } = props;
  return (
    <>
      <Pane onKeyDown={preventArrowKeys} display="flex">
        <SelectMenu
          closeOnSelect={true}
          title="Insert Section Here"
          options={SECT_TYPE_ARR.map(entry => ({
            label: entry[1],
            value: entry[0],
          }))}
          onSelect={choice =>
            operations.sectionInsert(section.id, choice.value, 1)
          }
        >
          <IconButton
            icon={PlusIcon}
            intent="none"
            marginRight="auto"
            marginLeft="auto"
            appearance="default"
            height={24}
            borderRadius="12px"
            onMouseOver={e => props.setHoveringBelow(true)}
            onMouseOut={e => props.setHoveringBelow(false)}
          />
        </SelectMenu>
      </Pane>
    </>
  );
}

export function SectionControl(props) {
  const { section, operations } = props;
  return (
    <>
      <SelectMenu
        closeOnSelect={true}
        title="Change Type"
        selected={section.hederis_type}
        options={SECT_TYPE_ARR.map(entry => ({
          label: entry[1],
          value: entry[0],
        }))}
        onSelect={choice =>
          operations.sectionChangeType(section.id, choice.value)
        }
      >
        <Button iconBefore={CaretDownIcon} height={24}>
          Type: {hederis_type_to_user(section.hederis_type)}
        </Button>
      </SelectMenu>
      <AttributePopoverSection {...props}>
        <Tooltip content="Attributes">
          <IconButton icon={PropertyIcon} height={24} marginRight={4} />
        </Tooltip>
      </AttributePopoverSection>
      <Tooltip content="Move section up">
        <IconButton
          icon={CircleArrowUpIcon}
          onClick={() => {
            operations.sectionMoveUp(section.id);
          }}
          height={24}
          marginRight={4}
        />
      </Tooltip>
      <Tooltip content="Move section down">
        <IconButton
          icon={CircleArrowDownIcon}
          onClick={() => {
            operations.sectionMoveDown(section.id);
          }}
          height={24}
          marginRight={4}
        />
      </Tooltip>
      <Tooltip content="Delete">
        <IconButton
          icon={TrashIcon}
          intent="danger"
          onClick={() => {
            operations.sectionRemove(section.id);
          }}
          height={24}
          marginRight={4}
        />
      </Tooltip>
    </>
  );
}

export function InsertItemAbove(props) {
  const { item, isSelected, operations, section_id, parent_type } = props;
  const { hederis_type } = item;
  const isWrapper = item.hederis_type && item.hederis_type.startsWith("hwpr");

  const insertOptions = get_appropriate_type_options({
    hederis_type,
    parent_type,
  });

  return (
    <Pane onKeyDown={preventArrowKeys} display="flex" marginBottom="-12px">
      <SelectMenu
        closeOnSelect={true}
        title={`Insert Here`}
        options={insertOptions.map(entry => ({
          label: entry[1],
          value: entry[0],
        }))}
        onSelect={choice =>
          operations.elementInsert(section_id, item.id, choice.value, 0)
        }
      >
        <IconButton
          icon={PlusIcon}
          intent="none"
          marginRight="auto"
          marginLeft="auto"
          appearance="default"
          height={24}
          borderRadius="12px"
          onMouseOver={e => props.setHoveringAbove(true)}
          onMouseOut={e => props.setHoveringAbove(false)}
        />
      </SelectMenu>
    </Pane>
  );
}

export function InsertItemBelow(props) {
  const { item, isSelected, operations, section_id, parent_type } = props;
  const { hederis_type } = item;
  const isWrapper = item.hederis_type && item.hederis_type.startsWith("hwpr");

  const insertOptions = get_appropriate_type_options({
    hederis_type,
    parent_type,
  });

  return (
    <Pane onKeyDown={preventArrowKeys} display="flex" marginTop="-12px">
      <SelectMenu
        closeOnSelect={true}
        title={`Insert Here`}
        options={insertOptions.map(entry => ({
          label: entry[1],
          value: entry[0],
        }))}
        onSelect={choice =>
          operations.elementInsert(section_id, item.id, choice.value, 1)
        }
      >
        <IconButton
          icon={PlusIcon}
          intent="none"
          marginRight="auto"
          marginLeft="auto"
          appearance="default"
          height={24}
          borderRadius="12px"
          onMouseOver={e => props.setHoveringBelow(true)}
          onMouseOut={e => props.setHoveringBelow(false)}
        />
      </SelectMenu>
    </Pane>
  );
}

// For everything that's not a top level section, including the first element of wrappers with wrapperMode
export function ItemControl(props) {
  const { item, isSelected, operations, section_id, parent_type } = props;
  const { hederis_type } = item;
  const isWrapper = item.hederis_type && item.hederis_type.startsWith("hwpr");

  const insertOptions = get_appropriate_type_options({
    hederis_type,
    parent_type,
  });

  if (!isSelected) {
    return (
      <Pane
        marginLeft={8}
        display="flex"
        justifySelf="stretch"
        flex={1}
        justifyContent="flex-end"
      >
        {!isWrapper ? (
          ``
        ) : (
          <Text color="muted" marginRight={8} cursor="pointer">
            {hederis_type_to_user(item.hederis_type)}
          </Text>
        )}
      </Pane>
    );
  }

  return (
    <Pane
      marginLeft={8}
      display="flex"
      justifySelf="stretch"
      flex={1}
      justifyContent="flex-end"
      width={120}
    >
      <Tooltip content="Attributes">
        <AttributePopoverElement {...props}>
          <IconButton icon={PropertyIcon} height={24} marginLeft={8} />
        </AttributePopoverElement>
      </Tooltip>
      {isWrapper ? (
        <Tooltip content="Delete Wrapper Only">
          <IconButton
            icon={RemoveColumnIcon}
            height={24}
            marginLeft={4}
            onClick={() =>
              operations.elementDeleteWrapOnly(section_id, item.id)
            }
          />
        </Tooltip>
      ) : (
        ``
      )}
      <Tooltip content="Delete">
        <IconButton
          icon={TrashIcon}
          onClick={() => operations.elementDelete(section_id, item.id)}
          height={24}
          marginLeft={4}
          marginRight={4}
        />
      </Tooltip>
    </Pane>
  );
}

// HELPERS

function get_appropriate_type_options({ hederis_type, parent_type }) {
  const skey = get_srule_kind(hederis_type);
  const rule = srules[skey][hederis_type] || srules[skey]["default"];
  // start with parent
  const parent_rule = srules.special_handling[parent_type];
  // const special_rule = srules.special_handling[hederis_type];
  // console.log(rule);
  if (parent_rule) {
    // console.log("parent_type rule!");
    // console.log({ hederis_type, parent_type });
    // console.log(parent_rule);
    const allowed = h_struct_option_helper(parent_rule.allowed_children);
    return allowed;
  }

  return NON_SPAN_NON_SECT_TYPE_ARR;
}

export function h_struct_option_helper(arr) {
  if (arr === "any") {
    return NON_SPAN_NON_SECT_TYPE_ARR;
  }
  return arr
    .map(hederis_type_or_cat => {
      // is it a category ('sections', "wrappers", etc) or hederis type string ('hwprnumlist')
      return srules[hederis_type_or_cat]
        ? Object.keys(srules[hederis_type_or_cat])
        : hederis_type_or_cat;
    })
    .flat()
    .map(hederis_type => {
      return [hederis_type, ALL_TYPES[hederis_type]];
    });
}

export function get_srule_kind(hederis_type) {
  if (hederis_type.startsWith("hwpr")) {
    return "wrappers";
  } else if (hederis_type.startsWith("hblk")) {
    return "blocks";
  } else if (hederis_type.startsWith("hsec")) {
    return "sections";
  } else if (hederis_type.startsWith("hsp")) {
    return "inlines";
  } else {
    // console.log("no rule found for", hederis_type);
  }
}

export function hederis_type_to_user(kind) {
  if (!kind) {
    return "***";
  } else if (ALL_TYPES[kind]) {
    return ALL_TYPES[kind];
  } else if (kind.endsWith("start")) {
    let k = kind.replace(/start$/, "");
    return `${ALL_TYPES[k]} Start`;
  } else if (kind.endsWith("end")) {
    let k = kind.replace(/end$/, "");
    return `${ALL_TYPES[k]} End`;
  } else {
    return kind;
  }
  return kind;
}

export const SECT_TYPE_ARR = Object.entries(ALL_TYPES).filter(s => {
  return s[0].startsWith(`hsec`);
});

// what about hspnspan ? XXX
export const NON_SPAN_NON_SECT_TYPE_ARR = Object.entries(ALL_TYPES).filter(
  s => {
    return !s[0].startsWith(`hsec`) && !s[0].startsWith(`hsp`);
  }
);

export const SPAN_TYPE_ARR = Object.entries(ALL_TYPES).filter(s => {
  //prettier-ignore
  let tool_s= ["hspaninlinepi"] //, "hspanbrafter", "hspanpagebrafter"]
  return s[0].startsWith(`hsp`) && !tool_s.includes(s[0]);
});
