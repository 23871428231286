import React from "react";
import ReactDOM from "react-dom";
import { useState, useEffect, useRef } from "react";

// prettier-ignore
import { Pane, Text, Icon, Heading, Button, IconButton, Popover, Position, Menu, Paragraph, Avatar, toaster, Alert, Strong, Code, Dialog, TextInputField, Checkbox} from "evergreen-ui";

import { validate_font_style } from "../../../api/app_shared/process/fonts.js";

import { firebase, db, storage } from "../../fire.js";
import { FireHelpers } from "../../../api/app_shared/fire_helpers.js";
import APIClient from "../../helpers/api_client";
const API = new APIClient(firebase);
const FH = new FireHelpers(db, firebase);

export default class Fonts extends React.Component {
  constructor(props) {
    super(props);
    this.state = { user_fonts: [] };
    this.deleteFontFile = this.deleteFontFile.bind(this);
    this.load_fonts = this.load_fonts.bind(this);
    this.handleDeleteFont = this.handleDeleteFont.bind(this);
    this.handleEditFont = this.handleEditFont.bind(this);
  }

  componentDidMount() {
    this.load_fonts();
  }

  async load_fonts() {
    // all fonts created by this user
    const user_uid = this.props.hederis_user.uid;
    const user_fonts = await FH.get_all_user_fonts({
      user_uid,
    });
    this.setState({ user_fonts });
  }

  async deleteFontFile(font) {
    let storageRef = storage.ref();
    let fontRef = storageRef.child(font.cloud_path);
    await fontRef.delete();
  }

  async handleDeleteFont(font) {
    for (let i=0; i < font.files.length; i++) {
      await this.deleteFontFile(font.files[i]);
    }
    await db
      .collection("user_fonts")
      .doc(font.id)
      .delete();
    this.load_fonts();
  }  

  async handleEditFont(font) {
    await db
      .collection("user_fonts")
      .doc(font.id)
      .update(font);
  }                

  render() {
    let props = this.props;
    return (
      <Pane display="flex" flexDirection="column" alignItems="center">
        <Pane width={600} marginTop={16}>
          <Heading size={800} marginLeft="85px" marginBottom={32}>Your Fonts</Heading>
          {this.state.user_fonts.length ? (
            <Pane>
              {this.state.user_fonts.map((f,i) => (
                <FontFile 
                  f={f} 
                  i={i} 
                  handleEditFont={this.handleEditFont} 
                  handleDeleteFont={this.handleDeleteFont} 
                  key={f.id}
                />
              ))}
            </Pane>
          ) : (
            <Paragraph>
              You do not have any custom fonts.
            </Paragraph>
          )}
        </Pane>
      </Pane>
    );
  }
}

function FontFile(props) {
  let { f, i } = props;
  let [show, setShow] = useState(false);
  let [confirm, setConfirm] = useState(false);

  return (
    <Pane 
      marginBottom={16} 
      display="flex" 
      flexDirection="row" 
      borderTop={i % 2 ? "none" : "1px solid #d3d3d3"}
      borderBottom={i % 2 ? "none" : "1px solid #d3d3d3"}
      background={i % 2 ? "none" : "tint2"}
      paddingTop={i % 2 ? 0 : 16}
      paddingBottom={i % 2 ? 0 : 16}
      paddingLeft={16}
      paddingRight={16}
    >
      <Pane width="85px" >
        <Button
          intent="none"
          appearance="primary"
          onClick={() => setShow(!show)}
          width="75px"
          justifyContent="center"
          marginBottom={8}
        >
          Edit 
        </Button>
        <Button
          intent="danger"
          appearance="primary"
          onClick={() => setConfirm(!confirm)}
          width="75px"
          justifyContent="center"
        >
          Delete 
        </Button>
      </Pane>
      <Pane>
        <Heading size={600} marginBottom={8}>{f.displayName}</Heading>
        <Heading size={500}>Files:</Heading>
        {f.files.map((myfile,i) => (
          <Pane marginBottom={8} key={`${myfile.fontFamily}${i}`}>
            <Paragraph><Strong>Filename:</Strong> {myfile.f_name}</Paragraph>
            <Paragraph><Strong>Style:</Strong> {myfile.fontSubfamily}</Paragraph>
            <Paragraph><Strong>Weight:</Strong> {myfile.weightClass}</Paragraph>
          </Pane>
        ))}
      </Pane>
      {show ? (
        <FontEditView
          f={f}
          show={show}
          setShow={setShow}
          handleEditFont={props.handleEditFont}
        />
      ) : (
        ``
      )}
      {confirm ? (
        <FontDeleteView
          f={f}
          confirm={confirm}
          setConfirm={setConfirm}
          handleDeleteFont={props.handleDeleteFont}
        />
      ) : (
        ``
      )}
    </Pane>
  )
}

function FontDeleteView(props) {
  const { f } = props;

  return (
    <Pane>
      <Dialog
        isShown={props.confirm}
        title="Delete Font"
        onCloseComplete={ () => props.setConfirm(false) }
        confirmLabel="DELETE"
        onConfirm={async close => {
          props.handleDeleteFont(f);
          toaster.success(`Font Deleted!`);
          props.setConfirm(false);
        }}
      >
        <Paragraph>
          Are you sure you want to delete this font?
        </Paragraph>
        <Heading marginTop={16}>{f.displayName}</Heading>
        <Paragraph marginTop={16}>
          It will also be deleted for all projects, groups, and users
          who currently have access to it. Once deleted, any book designs 
          where this font was in use will use the default system font 
          instead.
        </Paragraph>
        <Paragraph marginTop={16}>
          This operation can not be undone.
        </Paragraph>
      </Dialog>
    </Pane>
  );
}

function FontEditView(props) {
  const { f } = props;
  const [displayName, setDisplayName] = useState(f.displayName);

  return (
    <Pane>
      <Dialog
        isShown={props.show}
        title="Edit Font"
        onCloseComplete={ () => props.setShow(false) }
        confirmLabel="Save Changes"
        onConfirm={async close => {
          props.handleEditFont(f);
          toaster.success(`Font Successfully Updated!`);
          props.setShow(false);
        }}
      >
        <Pane width={80}>
          <TextInputField
            label="Font Name:"
            value={displayName}
            name="displayName"
            height={40}
            width={400}
            placeholder={f.displayName}
            onChange={e => {
              f.displayName = e.target.value;
              setDisplayName(e.target.value);
            }}
          />
          <Paragraph width={400} size={300}>
            Note that changing the font name could disrupt designs 
            that currently use this font.
          </Paragraph>
          <Heading 
            size={400} 
            marginTop={32} 
            marginBottom={16}
          >
            Files:
          </Heading>
          {f.files.map((myfile,i) => (
            <FontFileEditView f={myfile} i={i} key={`${myfile.fontFamily}${i}`} />
          ))}
        </Pane>
      </Dialog>
    </Pane>
  );
}

function FontFileEditView(props) {
  const { f, i } = props;
  const [subFamily, setSubFamily] = useState(f.fontSubfamily);
  const [weightClass, setWeightClass] = useState(f.weightClass);

  function handleSetSubFamily(checked) {
    let data = validate_font_style(checked);
    setSubFamily(data);
    return data;
  }

  return (
    <Pane 
      display="flex" 
      flexDirection="column" 
      marginBottom={16}
      borderTop={i % 2 ? "none" : "1px solid #d3d3d3"}
      borderBottom={i % 2 ? "none" : "1px solid #d3d3d3"}
      background={i % 2 ? "none" : "tint2"}
      paddingTop={i % 2 ? 0 : 16}
      paddingBottom={i % 2 ? 0 : 16}
      paddingLeft={16}
      paddingRight={16}
      width={460}
    >
      <Paragraph width={400} marginBottom={8}>
        <Strong>Filename:</Strong> {f.f_name}
      </Paragraph>
      <Checkbox
        label="Italic?"
        checked={subFamily === "Italic" ? true : false}
        onChange={e => {
          f.fontSubfamily = handleSetSubFamily(e.target.checked);
        }}
      />
      <TextInputField
        label="Font Weight:"
        value={weightClass.toString()}
        name="weightClass"
        height={40}
        width={400}
        placeholder={f.weightClass.toString()}
        onChange={e => {
          f.weightClass = e.target.value;
          setWeightClass(e.target.value);
        }}
      />
    </Pane>
  )
}
