import React from "react";
import ReactDOM from "react-dom";
// prettier-ignore
import { Pane, Text, Heading, Button, TextInputField, SelectField, Small, toaster, Link } from "evergreen-ui";

import { firebase, db } from "../../fire.js";
// import { FireHelpers } from "../../../api/app_shared/fire_helpers.js";
import { FireHelpers } from "../../../api/app_shared/fire_helpers.js";
const FH = new FireHelpers(db, firebase);
import discount_logic from "../../../api/app_shared/process/discount_logic.js";
import HedButton from "./HedButton.js";

export class ProjectInfoView extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    // is it really a new project or an exisiting ?
    if (!props.initial_state) {
      let all_groups = this.getGroups();
      // assume the first group if they're in one or more groups
      let group = all_groups.length ? all_groups[0] : false;
      // console.log(group);
      let project_discount_code = props.discount_code || "";
      // prettier-ignore
      this.state = { pisbn: "", eisbn: "", title: "", author: "", group, draft: false , project_discount_code};
    } else {
      // we're actually editing a project
      this.state = props.initial_state;
    }

    this.handleInputChange = this.handleInputChange.bind(this);
  }
  // just combine owned and invited groups
  getGroups() {
    let uniq = [...new Set(this.props.owned_groups.concat(this.props.groups))];
    let remove = uniq.indexOf("public");
    if (remove != -1 && !this.props.hederis_user.can_create_public_templates) {
      uniq.splice(remove, 1);
    }
    return uniq;
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    // console.log(name, value);
    this.setState({
      [name]: value,
    });
  }

  async submitClicked() {
    let state = this.state;
    // lets say no editing project discount codes for existing projects
    // so only run this on new ones
    if (!this.props.initial_state && state.project_discount_code) {
      if (await should_accept_discount(state, this.props)) {
        toaster.notify("Discount Code Found!");
      } else {
        return;
      }
    }
    // otherwise
    // todo, more validation
    if (!state.title || !state.title.length) {
      toaster.warning("Needs a title!");
    } else if (!state.author || !state.author.length) {
      toaster.warning("Needs an author!");
    } else {
      state.group = state.group ? state.group : false;
      state.draft = state.group === "public" ? true : false;
      this.props.onSubmit(state);
    }
  }
  render() {
    return (
      <Pane width={600} marginLeft="auto" marginRight="auto" paddingTop={16} marginBottom={64}>
        <Heading textAlign="center" size={600} marginBottom={24}>
          {this.props.initial_state ? "Edit Project Information" : "Create a New Project"}
        </Heading>
        <TextInputField
          label="Title"
          name="title"
          required
          inputHeight={48}
          placeholder="My Book"
          value={this.state.title}
          onChange={this.handleInputChange}
        />
        <TextInputField
          label="Author"
          required
          name="author"
          inputHeight={48}
          placeholder="Jane Doe"
          value={this.state.author}
          onChange={this.handleInputChange}
        />
        <TextInputField
          label="Print ISBN"
          name="pisbn"
          onChange={this.handleInputChange}
          value={this.state.pisbn}
          placeholder="1234567890"
        />
        <TextInputField
          label="E-ISBN"
          name="eisbn"
          onChange={this.handleInputChange}
          placeholder="1234567890"
          value={this.state.eisbn}
        />
        <TextInputField
          label="Discount Code"
          name="project_discount_code"
          onChange={this.handleInputChange}
          placeholder=""
          value={this.state.project_discount_code}
          disabled={this.props.isTemplate == true ? true : !!this.props.initial_state}
        />
        {/*<TextInputField label="Group" name="group" value={this.props.groups} />*/}
        {!this.getGroups().length ? (
          <Pane marginBottom={32}>
            <TextInputField
              disabled
              value={`Individual: ${this.props.hederis_user.email}`}
              label="Organization"
              marginBottom={4}
            />
            <Text>
              <Small>You don't have any organizations</Small>
              <br />
              <Small>
                With organizations you can manage and allow other users to
                create projects. <a href="#/groups">Learn More.</a>
              </Small>
            </Text>
          </Pane>
        ) : this.props.initial_state ? (
          <TextInputField
            disabled
            value={
              this.props.initial_state.group.startsWith("user_")
                ? `Individual: ${this.props.hederis_user.email}`
                : this.props.initial_state.group
            }
            label="Organization"
            marginBottom={4}
          />
        ) : (
          <SelectField
            onChange={this.handleInputChange}
            name="group"
            label="Organization"
            value={this.state.group}
          >
            {this.getGroups().map(g => (
              <option value={g} key={g}>
                {g}
              </option>
            ))}
            <option value={""} key={"fnone"}>
              Individual: {this.props.hederis_user.email}
            </option>
          </SelectField>
        )}
        <Pane textAlign="center">
          <HedButton
            onClick={this.submitClicked.bind(this)}
            height={48}
            appearance="hedprimary"
            marginTop={16}
            isLoading={this.props.is_loading}
          >
            {this.props.initial_state
              ? "Save Project Changes"
              : "Create New Project"}
          </HedButton>
        </Pane>
        {!this.props.initial_state && (
          <Pane textAlign="center">
            <Text>
              <Small>
                By clicking "Create New Project" you are agreeing to our
                <a href="https://www.hederis.com/terms.html">
                  {" "}
                  Terms of Service.
                </a>
              </Small>
            </Text>
          </Pane>
        )}
      </Pane>
    );
  }
}

// to edit an existing  project's meta
export function ProjectMetaView(props) {
  // console.log(props.project);
  return (
    <ProjectInfoView
      groups={props.groups}
      initial_state={props.project}
      {...props}
      onSubmit={data => {
        props.updateProjectMeta(data);
        props.addActivity("edited project info");
        window.location = `#/projects/${props.params.id}/dash`;
      }}
    />
  );
}

// just a helper, with toasters, for logic that is also used on the backend, i.e discount_logic.check_discoun
async function should_accept_discount(state, props) {
  toaster.notify("Looking Up Discount_code...");
  let { project_discount_code, group } = state;
  group = group || `user_${props.hederis_user.uid}`;
  const res = await discount_logic.check_discount({
    project_discount_code,
    group,
    use_gte: true,
    FH,
  });
  toaster.closeAll();
  // now do some tests, if it fails, return false
  if (res.kind === "not-found") {
    toaster.warning("Discount Code Not Found");
    return false;
  }

  if (res.kind === "expired") {
    toaster.warning("Discount Code Expired");
    return false;
  }
  if (res.kind === "used") {
    toaster.warning("Discount Code Already Used Maximum Times");
    return false;
  }

  // finally return true otherwise
  return true;
}
