import React from "react";
import ReactDOM from "react-dom";
import { useState, useEffect } from "react";

// prettier-ignore
import { Pane, Code, Text, Paragraph, Dialog, Alert, toaster } from "evergreen-ui";
import { LoadingPane } from "./common.js";

import { firebase, db } from "../../fire.js";
import { validate_email } from "../../helpers/util";
// import { Accept_Invite } from "../../helpers/api";
import APIClient from "../../helpers/api_client";
import { FireHelpers } from "../../../api/app_shared/fire_helpers.js";
const API = new APIClient(firebase);
const FH = new FireHelpers(db, firebase);

export default class InviteAcceptor extends React.Component {
  constructor(props) {
    super(props);
    this.state = { is_loading: false, not_found: false };
  }
  async componentDidMount() {
    // TODO a reload/redirect hack
    let { invite_code, id, group_id } = this.props;
    // let p_or_g = group_id ? "group" : "project";
    // console.log(this.props)
    // don't worry about dealing with redirecting for groups for now
    if (group_id) {
      return;
    }
    let { uid } = this.props.hederis_user;
    let shared_projects = await FH.get_project_invites_to(uid);
    // console.log(shared_projects);
    let matching = shared_projects.filter(p => {
      return p["target"] === id && p["has_accepted"];
    });
    // console.log("matching:");
    // console.log(matching);
    // if they already accepted the invite, redirect
    if (matching.length) {
      // console.log("redirect");
      let loc = `#/projects/${id}/dash`;
      let url = window.location.toString().split("#")[0];
      window.location.replace(`${url}${loc}`);
    }

    // console.log(maybe_project)
  }
  render() {
    // console.log("INVITE ACCEPTOR", this.props);
    let { invite_code, id, group_id } = this.props;
    let p_or_g = group_id ? "group" : "project";
    let uid = this.props.hederis_user.uid;
    return (
      <Pane
        padding={16}
        display="flex"
        flexDirection="column"
        id="project_settings"
      >
        <Dialog
          isShown={true}
          title={`Join ${p_or_g}?`}
          onCloseComplete={() => {
            window.location.hash = `#/`;
          }}
          isConfirmDisabled={this.state.not_found}
          isConfirmLoading={this.state.is_loading}
          onConfirm={async close => {
            this.setState({ is_loading: true });
            toaster.notify("Accepting Invite....");
            // it seems like this didn't propagate fast enough - race condition even with async
            // this sees if it exists, and sets a claim  if it does, but does not actually accept it
            let res = await API.set_claims(invite_code);
            if (res.message !== "ok") {
              // console.log(res);
              toaster.closeAll();
              let description =
                "That invite does not exist or has already been accepted. Please check your link or check with the person who invited you.";
              toaster.danger("Problem Accepting Invite", {
                duration: 15,
                description,
              });
              this.setState({ is_loading: false, not_found: true });
              return;
            }
            // console.log(res);
            console.log("claims done!");
            await FH.accept_invite(uid, invite_code);
            toaster.closeAll();
            toaster.success(`Joined ${p_or_g}!`, { duration: 20 });
            // do we even need to close it?
            // close();
            if (!group_id) {
              console.log("redirecting to project");
              let loc = `#/projects/${id}/dash`;
              let url = window.location.toString().split("#")[0];
              window.location.replace(`${url}${loc}`);
            } else {
              // kludge to refresh so this group shows up in our groups top level state in with_auth.js
              let hash = `#/`;
              window.location.replace(`${window.location.origin}/${hash}`);
              window.location.reload();
            }
          }}
          confirmLabel={`Join the ${p_or_g}`}
        >
          <Pane
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Paragraph>Are you sure you want to join this {p_or_g}?</Paragraph>
          </Pane>
        </Dialog>
      </Pane>
    );
  }
}

function oldInviteAcceptor(props) {
  let { project } = props;
  let project_id = props.id;
  let invite_code = props.invite_code;

  const [invite, setInvite] = useState(false);

  useEffect(
    () => {
      // console.log("use eff");
      db.collection("invites")
        .doc(invite_code)
        .get()
        .then(doc => {
          if (doc.exists) {
            setInvite(doc.data());
          } else {
            setInvite(0);
          }
        });
      // get_details_from_uid_arr(users).then(setUsersDetail);
      // get_group_emails(group).then(setGroupEmails);
    },
    [invite_code]
  );

  if (invite === false) {
    return <LoadingPane />;
  }
  if (invite === 0) {
    return (
      <Pane padding={32}>
        <Alert intent="danger" title="No Invite Found!">
          Please make sure the link is correct and complete
        </Alert>
      </Pane>
    );
  }
  let problem = () => {
    // prettier-ignore
    toaster.danger("Joining the project didn't work. Please retry the link and make sure it's correct and complete.", { duration: 30 });
  };
  // console.log("possibleAddDetails", possibleAddDetails);
  // Our actual render method !
}
