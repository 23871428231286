import React from "react";
import ReactDOM from "react-dom";
// our interface for the server side lambdas
import { toaster } from "evergreen-ui";

export default class APIClient {
  constructor(firebase) {
    this.firebase = firebase;
  }
  // internal helpers
  async token() {
    // it appears this doesn't cost anything, and doing it everytime simplifies things
    return await this.firebase.auth().currentUser.getIdToken(true);
  }
  async tokenResult() {
    // not sure if we need to force refresh true as we are here and above XXX
    return await this.firebase.auth().currentUser.getIdTokenResult(true);
  }
  async req(path, data, method = "post") {
    // let url = `http://localhost:3000${path}`;
    let url = `${HEDERIS_API_PATH_WITH_STAGE}${path}`;
    data["user_id_token"] = await this.token();
    let res = await fetch(url, {
      method,
      mode: "cors",
      cache: "no-cache",
      body: JSON.stringify(data),
    });
    // XXX just returns false if we don't get a valid status / response from the server
    if (res.status !== 200) {
      // console.log(res);
      return false;
    } else {
      return await res.json();
    }
  }
  // actual public facing interface
  //
  async send_email(email_kind, to_email, details) {
    let data = { to_email, email_kind, details };
    return this.req("/send_email", data);
  }
  async set_default_claim() {
    // console.log("setting default claim!");
    // return this.req("/set_claims", {});
    let r = await this.req("/set_claims", {});
    // console.log(await this.tokenResult());
    let t = await this.token();
    return r;
  }
  async set_new_group_claim(new_group_id) {
    // console.log("setting default claim!");
    // return this.req("/set_claims", {});
    let r = await this.req("/set_claims", { new_group_id });
    // console.log(await this.tokenResult());
    let t = await this.token();
    return r;
  }
  async set_new_project_owner_claim(new_project_owner) {
    // return this.req("/set_claims", {});
    let r = await this.req("/set_claims", { new_project_owner });
    // console.log(await this.tokenResult());
    let t = await this.token();
    return r;
  }
  async set_claims(invite_code, remove=false) {
    // todo, we don't need to run this if they're already a member of the group! can just do it in client
    // return this.req("/set_claims", { invite_code });
    let r = await this.req("/set_claims", { invite_code, remove });
    // console.log(await this.tokenResult());
    let t = await this.token();
    return r;
  }
  async create_stripe_customer(token, hederis_user_uid, user_email) {
    let token_id = token["id"];
    let { last4, brand } = token["card"];
    let data = { token_id, hederis_user_uid, user_email, last4, brand };
    return this.req("/create_stripe_customer", data);
  }
  async trigger_convert(payload) {
    payload["project"] = clean_project(payload["project"]);
    return this.req("/trigger_convert", payload);
  }
  async trigger_rebuild(payload) {
    payload["project"] = clean_project(payload["project"]);
    return this.req("/trigger_rebuild", payload);
  }
  async run_diff_text(payload) {
    try {
      payload["project"] = clean_project(payload["project"]);
      return this.req("/diff_text", payload);
    } catch (error) {
      toaster.danger("An unknown error occurred. Please try again.");
      console.log(error);
    }
  }
  async run_bake_docx(payload) {
    try {
      payload["project"] = clean_project(payload["project"]);
      return this.req("/bake_docx", payload);
    } catch (error) {
      toaster.danger("An unknown error occurred. Please try again.");
      console.log(error);
    }
  }
  async run_unflatten(payload) {
    payload["project"] = clean_project(payload["project"]);
    return this.req("/unflatten", payload);
  }
}

// we don't need to send everything to the server, and the SNS is restricted in size XXX
function clean_project(project) {
  let {
    ingest_messages,
    build_messages,
    section_meta,
    saved_basic_configs,
    ...clean_p
  } = project;
  return clean_p;
}
