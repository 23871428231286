import React from "react";
import ReactDOM from "react-dom";
// prettier-ignore
import { Alert, Code, Pane, Text, Icon, Heading, Button, IconButton, Popover, Position, Menu, Avatar, ConsoleIcon, BanCircleIcon, InfoSignIcon} from "evergreen-ui";

// import TopWidget from "./top_widget.js";
import { firebase, db, storage } from "../../fire.js";

export default class EpubCheckView extends React.Component {
  constructor(props) {
    super(props);
    this.state = { check_data: false, isLoading: false };
  }
  async checkEpub() {
    this.setState({ isLoading: true, check_data: false });
    let { project } = this.props;
    let { output } = project;
    let api_url =
      process.env.EPUB_CHECK_API_URL ||
      "https://063gmpuvqi.execute-api.us-west-2.amazonaws.com/prod/check";
    let method = "POST";
    let epub_uri = await storage.ref(output.epub.path).getDownloadURL();
    // console.log(epub_uri);
    let data = { epub_uri };
    let res = await fetch(api_url, {
      method,
      mode: "cors",
      cache: "no-cache",
      body: JSON.stringify(data),
    });
    // XXX just returns false if we don't get a valid status / response from the server
    if (res.status !== 200) {
      // console.log(res);
      // return false;
      console.log("Epubcheck API is broken!");
    }
    let check_data = await res.json();
    // console.log(check_data);
    let isLoading = false;
    this.setState({ check_data, isLoading });
  }
  render() {
    let { project } = this.props;
    let { output } = project;
    // if there's no epub path, display nothing
    if (!output || !output["epub"]) {
      return (
        <Alert
          intent="none"
          title="You must build your book before validating it"
        >
          Please upload a docx first
        </Alert>
      );
    }
    let { check_data, isLoading } = this.state;
    // console.log(check_data.publication);
    // prettier-ignore
    let verb = isLoading ? "Validating" : check_data ? "Revalidate":"Validate"
    // use this directly so people can log out even if they're not fully logged in, ie email not verified
    return (
      <Pane padding={8}>
        <Button
          onClick={() => this.checkEpub()}
          marginY={8}
          isLoading={isLoading}
          iconBefore={ConsoleIcon}
        >
          {verb} with EPUBCheck
        </Button>
        {/* once it's been loaded*/}
        {check_data ? (
          <Pane>
            {check_data.messages.length ? (
              <Alert
                intent="none"
                title="Your EPUB has the following errors, warnings, or notes"
              />
            ) : (
              <Alert
                intent="success"
                title="Your EPUB is valid! No errors or warnings"
              />
            )}
            <EpubMessagesView messages={check_data.messages} />
            <PubView publication={check_data.publication} />
          </Pane>
        ) : (
          ""
        )}
      </Pane>
    );
  }
}

function EpubMessagesView(props) {
  let { messages } = props;
  console.log(messages);
  return (
    <Pane marginY={8}>
      {messages.length ? <Heading size={400}>Messages & Errors</Heading> : ``}
      {messages.map((m_obj, i) => {
        let locs = m_obj.locations.map((lo, j) => (
          <Pane key={j} paddingTop={4}>
            <Text>Path: </Text>
            <Code marginRight={16}>{lo.path}</Code>
            <Text>Line: </Text>
            <Code marginRight={16}>{lo.line}</Code>
            <Text>Column: </Text>
            <Code marginRight={16}>{lo.column}</Code>
          </Pane>
        ));
        return (
          <Pane key={i} paddingY={4} marginY={8} borderBottom>
            <Icon
              marginRight={8}
              size={16}
              icon={m_obj.severity == "ERROR" ? BanCircleIcon : InfoSignIcon}
              color={m_obj.severity == "ERROR" ? "danger" : "info"}
            />
            <Text>{m_obj.message} at</Text> {locs}
          </Pane>
        );
      })}
    </Pane>
  );
}
function PubView(props) {
  let { publication } = props;
  let els = Object.keys(publication).map((k, i) => {
    let val = publication[k];
    // prettier-ignore
    if (val===null){ return ``}
    return (
      <Pane key={i} borderBottom paddingY={4}>
        <Pane width={175} display="inline-block">
          <Text fontFamily="monospace">{k}:</Text>
        </Pane>
        {Array.isArray(val) ? (
          val.map((z, j) => {
            return (
              <Pane display="inline" key={j}>
                <Text key={j} fontFamily="monospace">
                  {z}
                </Text>{" "}
              </Pane>
            );
          })
        ) : (
          <Code>{val === false ? "false" : val}</Code>
        )}
      </Pane>
    );
  });
  return (
    <Pane marginY={8}>
      <Heading size={400}>Publication Info:</Heading>
      {els}
    </Pane>
  );
}
