import React from "react";
import ReactDOM from "react-dom";

import {
  Pane,
  Text,
  Icon,
  Heading,
  Button,
  toaster,
  Paragraph,
  Strong,
  TickIcon,
} from "evergreen-ui";

// it'd be cool to use this for coloring the group badges
// import hashCode from "evergreen-ui/esm/avatar/src/utils/hash.js";

import { LoadingPane } from "./common";

import { parseQueryString } from "../../helpers/util";

import { firebase, db } from "../../fire.js";

export default class Unsubscribe extends React.Component {
  constructor(props) {
    super(props);

    this.state = { done: false };
  }
  componentDidMount() {}

  async createUnsubscribe() {
    gtag("event", "unsubscribe");
    let { email, invite_code, project_id } = parseQueryString(
      window.location.search
    );
    // only send the others if they exist, otherwise fb error
    let unsub_data = { email };
    // prettier-ignore
    if (invite_code){ unsub_data["invite_code"] = invite_code; }
    // prettier-ignore
    if (project_id) { unsub_data["project_id"]  = project_id;  }
    if (email) {
      await db
        .collection("unsubscribes")
        .doc(email)
        .set(unsub_data);
      toaster.success("You have successfully unsubscribed!", { duration: 60 });
      this.setState({ done: true });
    } else {
      toaster.warning("No email! Make sure the link you're using is correct", {
        duration: 60,
      });
    }
  }
  render() {
    let qs = parseQueryString(window.location.search);

    return (
      <Pane display="flex" flexDirection="column" alignItems="center">
        <Pane>
          <Heading paddingBottom={24} size={700} paddingTop={48}>
            Unsubscribe from Hederis Emails ?
          </Heading>
        </Pane>
        <Paragraph>
          Are you sure you want to unsubscribe <Strong>{qs.email}</Strong> ?
        </Paragraph>
        <Paragraph>
          This means you won't get any emails, even when colleagues invite you
          to collaborate.
        </Paragraph>
        {/*<Paragraph>If you have an account you can change your notification settings instead.</Paragraph>*/}
        <Pane marginTop={32}>
          <Button
            appearance="primary"
            size={400}
            iconBefore={this.state.done ? TickIcon : "none"}
            disabled={this.state.done}
            onClick={() => {
              this.createUnsubscribe();
              // window.location = "#/new/";
            }}
          >
            {!this.state.done ? "Unsubscribe All" : "Unsubscribed!"}
          </Button>
        </Pane>
      </Pane>
    );
  }
}
