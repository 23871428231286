import React from "react";
import ReactDOM from "react-dom";
import { Route, Router } from "react-enroute";
// prettier-ignore
import { Pane, Text, Heading, Button, TabNavigation, Tab, Icon, IconButton, Spinner, toaster, EditIcon, CogIcon, InfoSignIcon, ComparisonIcon, StyleIcon, HomeIcon, IssueIcon} from "evergreen-ui";

import { firebase, db, storage } from "../fire.js";
import { FireHelpers } from "../../api/app_shared/fire_helpers.js";

import { LoadingPane, NotFound } from "./shared/common.js";
import BasicApp from "./basic/app_basic.js";
import AdvancedApp from "./advanced/app_advanced.js";
// import { section_parse } from "../../api/app_shared/process/section_parse.js";
// let section_map = section_parse(adv_full_book_html);

import default_basic_config from "../../api/app_shared/defaults/basic_project_defaults.json";

const FH = new FireHelpers(db, firebase);

const basic_tab_list = [
  { key: "meta", label: <IconButton icon={EditIcon} appearance="minimal" /> },
  { key: "dash", label: "Dashboard" },
  { key: "settings", label: <IconButton icon={CogIcon} appearance="minimal" /> },
];
// prettier-ignore
const advanced_tab_list = [
  { key: "meta",   label: [<Text key="b" size={400} marginTop={2}>Edit Info</Text>]  },
  { key: "settings",   label: [<Text key="b" size={400} marginTop={2}>Settings & Invites</Text>]  },
  { key: "edit",   label: [<Text key="b" size={400} marginTop={2}>Edit Text</Text>]  },
  { key: "compare",   label: [<Text key="b" size={400} marginTop={2}>Compare Text</Text>]  },
  { key: "design",   label: [<Text key="b" size={400} marginTop={2}>Design</Text>]  },
  { key: "dash",   label: [<Text key="b" size={400} marginTop={2}>Dashboard</Text>]  },
];

// a data layer for both kinds of project, as well as ui side 404/5
export default class MainContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // an object - loaded from the project in the firestore realtime db
      project: false,
      feed_list: false,
      alt_text: false,
      // flags, for the ui
      is_writing: false,
      has_error: false,
      last_loaded_html_hash: false,
      last_loaded_conf_hash: false,
    };
    // so we can access the app from outside, an anti pattern, avoid
    this.app_ref = React.createRef();
  }
  componentWillUnmount() {
    // prettier-ignore
    if (this.unsub_project_meta){ this.unsub_project_meta() }
    // prettier-ignore
    if (this.unsub_feed_listener){ this.unsub_feed_listener() }
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { has_error: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log("error");
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }
  // the listener to update our project on first load as well as live updates from the server
  // and locally, XXX which means we don't need to set state
  // subscribed in componentDidMount
  async onSnapshot(doc) {
    // console.log("SNAP");
    var source = doc.metadata.hasPendingWrites ? "local" : "server";
    if (!doc.exists) {
      this.setState({ not_found: true });
      return;
    }
    // extract the data and patch it to be backwards compatible /
    // account for our changes to defaults etc
    let project = patch_project(doc.data());
    if (!this.state.feed_list && !this.unsub_feed_listener) {
      this.setup_feed_listener(project);
    }

    // and finally set the state
    this.setState({ project, last_write_source: source });
  }
  setup_feed_listener(project) {
    this.unsub_feed_listener = db
      .collection("projects")
      .doc(this.props.id)
      .collection("feed_items")
      .where("group", "==", project.group)
      .orderBy("ts", "desc")
      .limit(50)
      .onSnapshot(this.onActivitySnap.bind(this), e => {
        console.log("something went wrong with subcol listener", e);
      });
  }
  async addActivity(kind, hash = "") {
    const { uid, photoURL = "", displayName } = this.props.hederis_user;
    const { group } = this.state.project;
    // const ts = firebase.firestore.FieldValue.serverTimestamp();
    const ts = firebase.firestore.Timestamp.fromDate(new Date());

    const item = {
      kind,
      group,
      ts,
      hash,
      user: { uid, photoURL, displayName },
    };

    await db
      .collection("projects")
      .doc(this.props.id)
      .collection("feed_items")
      .add(item);

    if (kind === "saved text") {
      this.setState({ last_loaded_html_hash: hash });
    } else if (kind === "saved design") {
      this.setState({ last_loaded_conf_hash: hash });
    }
  }
  onActivitySnap(querySnap) {
    const feed_list = get_arr(querySnap, true);
    // console.log(feed_list);
    this.setState({ feed_list });
  }
  async componentDidMount() {
    // for errors below
    let on_error = e => {
      toaster.warning(
        "This project doesn't exist or you don't have access to it."
      );
      this.setState({ not_found: true });
      console.log(e);
    };
    // subscribe to real time updates for project entry in firestore
    let unsub = db
      .collection("projects")
      .doc(this.props.id)
      // bind to real time updates, which we use for user notifications from the backend
      // the second argument is if the call errors
      .onSnapshot(this.onSnapshot.bind(this), on_error);
    // to unsubscribe on unmount
    this.unsub_project_meta = unsub;

    this.get_alt_text(this.props.id);
  }
  // responsible for updating the fields in the project object, {k:v, k2:v2....}
  // only changed fields rather than the whole object, more like setState
  // use dot notation to do sub updates 'output.html.path' etc
  updateProjectMeta(change) {
    this.setState({ is_writing: true });
    // console.log("update Meta", change);
    if (!change.hasOwnProperty("last_updated")) {
      change["last_updated"] = firebase.firestore.FieldValue.serverTimestamp();
    }
    // we don't actually need to call setState, realtime firestore will automatically treat this sort of local change the same as a remote one
    db.collection("projects")
      .doc(this.props.id)
      .update(change)
      .then(e => this.setState({ is_writing: false }));
  }

  get_alt_text(project_id) {
    let docRef = db
      .collection("projects")
      .doc(project_id)
      .collection("imageMetadata")
      .doc("altText");

    docRef
      .get()
      .then(doc => {
        if (doc.exists) {
          this.setState({ alt_text: doc.data().metadata });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(error => {
        if (error.code === "permission-denied") {
          console.log("problem getting imageMetadata");
        } else {
          console.log("Error getting document:", error);
        }
      });
  }

  // expects an object like this:
  // metadata: {"image.jpg": {"alt_text": "text here", "isDecorative": false }}

  updateImageMeta(change) {
    this.setState({ is_writing: true });

    let docRef = db
      .collection("projects")
      .doc(this.props.id)
      .collection("imageMetadata")
      .doc("altText");

    docRef
      .set({ group: this.state.project.group, metadata: change })
      .then(e => this.setState({ is_writing: false, alt_text: change }));
  }

  render() {
    if (this.state.not_found === true) {
      // prettier-ignore
      return (
        <Pane display="flex" height="30vw" width="100vw" justifyContent="center" alignItems="center" flexDirection="column">
          <Icon icon={IssueIcon} color="muted" size={40} />
          <Text marginTop={16}>Not Found</Text>
        </Pane>
      );
    }
    if (this.state.project === false) {
      return <LoadingPane pad={40} />;
    }
    let { is_advanced } = this.state.project;
    let tab_list = is_advanced ? advanced_tab_list : basic_tab_list;
    let p_props = {
      project: this.state.project,
      last_write_source: this.state.last_write_source,
      updateProjectMeta: this.updateProjectMeta.bind(this),
      updateImageMeta: this.updateImageMeta.bind(this),
      addActivity: this.addActivity.bind(this),
      ...this.props,
      feed_list: this.state.feed_list,
      alt_text: this.state.alt_text,
      last_loaded_html_hash: this.state.last_loaded_html_hash,
      last_loaded_conf_hash: this.state.last_loaded_conf_hash,
      on_load_hashes: ({ last_loaded_html_hash, last_loaded_conf_hash }) =>
        this.setState({ last_loaded_html_hash, last_loaded_conf_hash }),
    };

    // XXX this is where we split into basic (convert) and advanced (typeset)
    let one_app = is_advanced ? (
      <AdvancedApp {...p_props} ref={this.app_ref} />
    ) : (
      <BasicApp {...p_props} />
    );
    one_app = this.state.has_error ? <ErrorPane /> : one_app;
    return (
      <Pane>
        {one_app}
      </Pane>
    );
  }
}

function ErrorPane() {
  return (
    <Pane
      display="flex"
      height="30vw"
      width="100vw"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Icon icon={IssueIcon} color="muted" size={40} />
      <Heading size={600}>There was an error, sorry!</Heading>
      <Heading>Please reload the page and try again.</Heading>
    </Pane>
  );
}

function get_arr(qs, include_id = true) {
  let res = [];
  qs.forEach(doc => {
    let p = doc.data();
    // prettier-ignore
    if (include_id) { p["id"] = doc.id; }
    res.push(p);
  });
  return res;
}
// Use this to be backwards compatible - writing an actual migration would be better but this works

function patch_project(project) {
  if (!project.basic_config.export_settings_style["bleed"]) {
    project.basic_config.export_settings_style["bleed"] =
      default_basic_config["export_settings_style"]["bleed"];
  }

  return project;
}
