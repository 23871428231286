// NOTE: Functions from this file are shared in other repos; 
// If you make updates here, be sure to run `npm run distro-shared` 
// which will distribute your changes as appropriate 
// (be sure to clone any missing repos as prompted).

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

module.exports = { asyncForEach };
