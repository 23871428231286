import React from "react";
import ReactDOM from "react-dom";
import HedButton from "../shared/HedButton.js";
// prettier-ignore
import {Pane, Text, Heading, Code, Button, TextInputField, Spinner, TabNavigation, Tab, SidebarTab, Tablist, Link, toaster, IconButton, Icon, Small, TextInput, RadioGroup, Select, Strong, Checkbox, Combobox, TagInput, Portal, RefreshIcon} from "evergreen-ui";

export default function LayoutControl(props) {

  let { is_loading, is_saving, onRunLayoutClick, show_spinner } = props;

  return (
    <React.Fragment>
      <HedButton
        onClick={() => onRunLayoutClick()}
        appearance="hedprimary"
        disabled={is_loading || is_saving}
      >
        Reflow Pages
      </HedButton>

      {(is_loading && show_spinner) ? <Spinner marginX={4} /> : ``}
    </React.Fragment>
  );
}
