export function view_options_css(opt, uioverridecss=false) {
  // the default, so you can see the pages
  let res = `  .pagedjs_page {
      background-color: #fdfdfd;
      margin: 32px 8px;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
    }`;
  // slightly different style to help indicate epub_mode
  res += opt.epub_mode
    ? `.pagedjs_page {
    box-shadow: none;
    border: 2px solid rgba(0, 0, 0, 0.4);
    background-color: #f8fafa;
    border-radius: 5px;
  }`
    : ``;
  // single or double (spread) view
  // this is hacky, to really do it we may need to deal with the page dims directly from the template options ugh
  res += !opt.spread_view
    ? `.pagedjs_pages { width:100%;    transform: scale(1); transform-origin: 0 0; flex-direction: column;   align-items: center; display: flex;}`
    : ".pagedjs_pages { width: 125%; display: flex ; flex-direction: row;   justify-content: space-around;  flex-wrap: wrap; transform: scale(0.8); transform-origin: 0 0;}";

  res += !opt.show_guides
    ? `  [class^="pagedjs_margin-"] {
        border: none;
      }`
    : `    [class^="pagedjs_margin-"] {
        border: 0.5px solid rgb(200, 90, 90);
      }
 `;

  res += !opt.show_grid
    ? `.pagedjs_page {
       background-image: none;
    }`
    : `    .pagedjs_pagebox {
       background-image: url("https://s3-us-west-2.amazonaws.com/hederis-assets-extra/grid.jpg");
       background-repeat: repeat;
       background-position: top left;
     }`;

  res += !opt.show_manip_spans
    ? ``
    : `.textmanipulation, .hspanbrafter {
        background-color: rgba(255, 165, 0, 0.4);
  }`;

  // for use with https://github.com/Hederis/hederis/issues/711
  res += opt.epub_mode
    ? ``
    : `body.HED_LOCKED_UI .pagedjs_page_content *[data-hederis-type^=hsec] *[data-hederis-type^=hblk]:not(.baked)  { background-color: rgba(255, 165, 0, 0.4); }`;

  res += uioverridecss
    ? uioverridecss
    : ``;
  
  return res;
}
