import React from "react";
import ReactDOM from "react-dom";
// prettier-ignore
import { Pane, Text, Icon, Heading, Button, IconButton, Popover, Position, Menu, Avatar, ManualIcon, FontIcon, PlusIcon, UserIcon, CircleArrowRightIcon, CaretDownIcon} from "evergreen-ui";

export default class TopWidget extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let props = this.props;
    // the onSel bit is a kludge to maintain accessibility while also making them links
    return (
      <Popover
        position={Position.BOTTOM_LEFT}
        content={({ close }) => {
          let onSel = (e, z) => {
            close();
            if (e.type == "keypress") {
              let hash = e.target.parentNode.hash;
              window.location.hash = hash;
            }
          };
          return (
            <Menu>
              <Menu.Group>
                <a href="/#/" onClick={close}>
                  <Menu.Item icon={ManualIcon} onSelect={onSel}>
                    Projects
                  </Menu.Item>
                </a>
                <a href="/#/fonts" onClick={close}>
                  <Menu.Item icon={FontIcon} onSelect={onSel}>
                    Fonts
                  </Menu.Item>
                </a>
                {props.owned_groups.length === 0 ? (
                  ``
                ) : (
                  <Menu.Group title="Groups You Own">
                    {props.owned_groups.map(g => (
                      <a href={`/#/group/${g}`} key={g} onClick={close}>
                        <Menu.Item> {g} </Menu.Item>
                      </a>
                    ))}
                  </Menu.Group>
                )}
                <a href="/#/create_group">
                  <Menu.Item icon={PlusIcon}>
                    New Group
                  </Menu.Item>
                </a>
                <Menu.Divider />
                <a href="/#/account" onClick={close}>
                  <Menu.Item icon={UserIcon} onSelect={onSel}>
                    Account
                  </Menu.Item>
                </a>
                <Menu.Divider />
                <a href="/#/logout" onClick={close}>
                  <Menu.Item
                    icon={CircleArrowRightIcon}
                    onSelect={onSel}
                    intent="danger"
                  >
                    Log Out
                  </Menu.Item>
                </a>
              </Menu.Group>
            </Menu>
          );
        }}
      >
        <Button marginLeft={16} iconBefore={CaretDownIcon} id="AccountMenu">
          <Avatar
            src={this.props.auth_user.photoURL}
            name={this.props.auth_user.displayName}
            size={24}
          />
        </Button>
      </Popover>
    );
  }
}

 
