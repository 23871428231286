import React from "react";
import ReactDOM from "react-dom";

import {
  Pane,
  Text,
  Heading,
  Button,
  TextInputField,
  TabNavigation,
  Tab,
  Link,
  toaster,
  IconButton,
  Icon,
  Alert,
  CloudUploadIcon
} from "evergreen-ui";
// import relativeDate from "tiny-relative-date";

import { firebase, db, storage } from "../../fire.js";
import {
  CloudImage,
  Loader,
  ImageList,
  IngestMessages,
} from "../shared/common.js";

// import CoverImageView from "./cover_image.js";
// import UploadPrompt from "./upload_prompt.js";
// import { OutputButtons } from "./output.js";

import Dropzone from "react-dropzone";

import {
  get_unique_filename,
  slugify,
  split_filename_from_ext,
} from "../../helpers/util";
import { get_resized_if_needed } from "../../helpers/resize";

export default class UploadWrapper extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    // console.log(this.props);
  }
  // when the user selects an image, or removes the current one
  // setCoverImage(cover_image = "") {
  //   this.props.updateProjectMeta({ cover_image });
  // }
  // todo just move these to dash sigh
  // no just use a REF !screw it
  async imageDrop(file) {
    let { name } = file;
    // name = slugify(get_unique_filename(name), true);
    name = slugify(name, true);
    toaster.notify("Uploading image(s)", { duration: 60 });

    let image_metadata = {};
    // now we resize it if needed
    let epub_resize = await get_resized_if_needed(file, 800);
    if (epub_resize) {
      let [fname, ext] = split_filename_from_ext(name);
      let epub_resize_path = `${this.get_group()}/${
        this.props.id
      }/images/${fname}_HEDERIS_EPUB.${ext}`;
      // console.log(epub_resize_path);
      let resizeImageRef = storage.ref().child(epub_resize_path);
      let snapshot = await resizeImageRef.put(epub_resize, {
        is_resized: true,
      });
      // set it for the full size one so we know later!
      image_metadata["epub_resize_path"] = epub_resize_path;
    }

    // upload the original in any case, and pass the meta info, which possibly contains the path to the smaller image
    let path = `${this.get_group()}/${this.props.id}/images/${name}`;
    let storageRef = storage.ref();
    let imageRef = storageRef.child(path);
    let snapshot = await imageRef.put(file, image_metadata);
    this.props.afterImageUpload(path);
  }
  // guh, these need to be at the same level as the upload_prompts...
  async docxDrop(file) {
    // this.clearIngestMessages();
    let { name } = file;
    // XXX should we keep the unique thing ?
    name = slugify(get_unique_filename(name), true);
    toaster.notify("Uploading document", { duration: 10 });
    // let { group } = this.props.project;
    let project_id = this.props.id;
    let docx_path = `${this.get_group()}/${project_id}/input_docx/${name}`;
    let storageRef = storage.ref();
    let docxRef = storageRef.child(docx_path);
    // console.log("about to upload to docx_path ", docx_path);
    let snapshot = await docxRef.put(file); //.catch(console.log);
    this.props.afterDocxUpload(docx_path);
  }
  get_group() {
    let { group } = this.props.project;
    group = group ? group : `user_${this.props.hederis_user.uid}`;
    return group;
  }
  onFileDrop(files) {
    if (files.length == 0) {
      toaster.warning("Sorry, an error occurred");
      return;
    } else {
      for (var i = 0; files.length > i; i++) {
        let file = files[i];
        let name = file.name.toLowerCase();
        if (name.endsWith(".docx") || name.endsWith(".doc")) {
          this.docxDrop(file);
        } else if (
          name.endsWith(".jpg") ||
          name.endsWith(".jpeg") ||
          name.endsWith(".png")
        ) {
          this.imageDrop(file);
        } else {
          toaster.warning("You can only upload .docx and image files");
        }
      }
    }
  }

  render() {
    // console.log("dash render");
    // console.log(this.props);
    return (
      <Dropzone
        onDrop={this.onFileDrop.bind(this)}
        className="drop"
        multiple={true}
        disableClick
        activeClassName="drop_active"
        acceptClassName="drop_accept"
      >
        <Pane
          display="flex"
          flexDirection="row"
          justifyContent="center"
          marginTop={32}
          marginLeft={96}
          marginRight={96}
        >
          {/* this is hidden by actual css except when they are actively dragging a file */}
          <div id="upload_overlay">
            <Pane padding={18} background="greenTint" borderRadius={4}>
              <Heading size={800}>
                <Icon
                  icon={CloudUploadIcon}
                  size={24}
                  marginRight={8}
                  color="success"
                />{" "}
                Drop File(s) Anywhere
              </Heading>
            </Pane>
          </div>
        </Pane>
        {this.props.children}
      </Dropzone>
    );
  }
}
