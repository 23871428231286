"use strict";

module.exports = function(template_builder_advanced) {
  // @import url("${template_builder_advanced.body_bodyFont[0]}");
  // @import url("${template_builder_advanced.body_headingFont[0]}");
  // @import url("${template_builder_advanced.runhead_font[0]}");
  // @import url("${template_builder_advanced.runfoot_font[0]}");

  // prettier-ignore
  return `
  @import url("${template_builder_advanced.body_bodyFontUrl}");
  @import url("${template_builder_advanced.body_headingFontUrl}");
  @import url("${template_builder_advanced.runhead_fontUrl}");
  @import url("${template_builder_advanced.runfoot_fontUrl}");

  @font-face {
    font-family: "FreeMono";
    font-weight: normal;
    font-style: normal;
    src: url("https://s3-us-west-2.amazonaws.com/hederis-assets-extra/FreeMono.otf");
  }

  @font-face {
    font-family: "FreeMono";
    font-weight: normal;
    font-style: italic;
    src: url("https://s3-us-west-2.amazonaws.com/hederis-assets-extra/FreeMonoOblique.otf");
  }

  @font-face {
    font-family: "FreeMono";
    font-weight: bold;
    font-style: normal;
    src: url("https://s3-us-west-2.amazonaws.com/hederis-assets-extra/FreeMonoBold.otf");
  }

  @font-face {
    font-family: "FreeMono";
    font-weight: bold;
    font-style: italic;
    src: url("https://s3-us-west-2.amazonaws.com/hederis-assets-extra/FreeMonoBoldOblique.otf");
  }

  @font-face {
    font-family: "FreeSans";
    font-weight: normal;
    font-style: normal;
    src: url("https://s3-us-west-2.amazonaws.com/hederis-assets-extra/FreeSans.otf");
  }

  @font-face {
    font-family: "FreeSans";
    font-weight: normal;
    font-style: italic;
    src: url("https://s3-us-west-2.amazonaws.com/hederis-assets-extra/FreeSansOblique.otf");
  }

  @font-face {
    font-family: "FreeSans";
    font-weight: bold;
    font-style: normal;
    src: url("https://s3-us-west-2.amazonaws.com/hederis-assets-extra/FreeSansBold.otf");
  }

  @font-face {
    font-family: "FreeSans";
    font-weight: bold;
    font-style: italic;
    src: url("https://s3-us-west-2.amazonaws.com/hederis-assets-extra/FreeSansBoldOblique.otf");
  }

  @font-face {
    font-family: "FreeSerif";
    font-weight: normal;
    font-style: normal;
    src: url("https://s3-us-west-2.amazonaws.com/hederis-assets-extra/FreeSerif.otf");
  }

  @font-face {
    font-family: "FreeSerif";
    font-weight: normal;
    font-style: italic;
    src: url("https://s3-us-west-2.amazonaws.com/hederis-assets-extra/FreeSerifItalic.otf");
  }

  @font-face {
    font-family: "FreeSerif";
    font-weight: bold;
    font-style: normal;
    src: url("https://s3-us-west-2.amazonaws.com/hederis-assets-extra/FreeSerifBold.otf");
  }

  @font-face {
    font-family: "FreeSerif";
    font-weight: bold;
    font-style: italic;
    src: url("https://s3-us-west-2.amazonaws.com/hederis-assets-extra/FreeSerifBoldItalic.otf");
  }

  @page {
    size: ${template_builder_advanced.page_width} ${template_builder_advanced.page_height};
    padding: 0in;
  }

  @page {
      @top-right {
        font-family: ${template_builder_advanced.master__runhead_font}, "FreeSerif";
        font-size: ${template_builder_advanced.master__runhead_fontSize};
        font-weight: ${template_builder_advanced.master__runhead_fontWeight};
        font-style: ${template_builder_advanced.master__runhead_fontStyle};
        color: ${template_builder_advanced.master__runhead_textColor};
        padding-bottom: ${template_builder_advanced.master__runhead_spaceBelow};
        background-color: ${template_builder_advanced.master__runhead_backgroundColor};
        border-top: ${template_builder_advanced.master__runhead_borderTop.join(' ')};
        border-right: ${template_builder_advanced.master__runhead_borderRight.join(' ')};
        border-bottom: ${template_builder_advanced.master__runhead_borderBottom.join(' ')};
        border-left: ${template_builder_advanced.master__runhead_borderLeft.join(' ')};
      }
      @top-center {
        font-family: ${template_builder_advanced.master__runhead_font}, "FreeSerif";
        font-size: ${template_builder_advanced.master__runhead_fontSize};
        font-weight: ${template_builder_advanced.master__runhead_fontWeight};
        font-style: ${template_builder_advanced.master__runhead_fontStyle};
        color: ${template_builder_advanced.master__runhead_textColor};
        padding-bottom: ${template_builder_advanced.master__runhead_spaceBelow};
        background-color: ${template_builder_advanced.master__runhead_backgroundColor};
        border-top: ${template_builder_advanced.master__runhead_borderTop.join(' ')};
        border-right: ${template_builder_advanced.master__runhead_borderRight.join(' ')};
        border-bottom: ${template_builder_advanced.master__runhead_borderBottom.join(' ')};
        border-left: ${template_builder_advanced.master__runhead_borderLeft.join(' ')};
      }
      @top-left {
        font-family: ${template_builder_advanced.master__runhead_font}, "FreeSerif";
        font-size: ${template_builder_advanced.master__runhead_fontSize};
        font-weight: ${template_builder_advanced.master__runhead_fontWeight};
        font-style: ${template_builder_advanced.master__runhead_fontStyle};
        color: ${template_builder_advanced.master__runhead_textColor};
        padding-bottom: ${template_builder_advanced.master__runhead_spaceBelow};
        background-color: ${template_builder_advanced.master__runhead_backgroundColor};
        border-top: ${template_builder_advanced.master__runhead_borderTop.join(' ')};
        border-right: ${template_builder_advanced.master__runhead_borderRight.join(' ')};
        border-bottom: ${template_builder_advanced.master__runhead_borderBottom.join(' ')};
        border-left: ${template_builder_advanced.master__runhead_borderLeft.join(' ')};
      }
      @bottom-right {
        font-family: ${template_builder_advanced.master__runfoot_font}, "FreeSerif";
        font-size: ${template_builder_advanced.master__runfoot_fontSize};
        font-weight: ${template_builder_advanced.master__runfoot_fontWeight};
        font-style: ${template_builder_advanced.master__runfoot_fontStyle};
        color: ${template_builder_advanced.master__runfoot_textColor};
        padding-bottom: ${template_builder_advanced.master__runfoot_spaceBelow};
        background-color: ${template_builder_advanced.master__runfoot_backgroundColor};
        border-top: ${template_builder_advanced.master__runfoot_borderTop.join(' ')};
        border-right: ${template_builder_advanced.master__runfoot_borderRight.join(' ')};
        border-bottom: ${template_builder_advanced.master__runfoot_borderBottom.join(' ')};
        border-left: ${template_builder_advanced.master__runfoot_borderLeft.join(' ')};
      }
      @bottom-center {
        font-family: ${template_builder_advanced.master__runfoot_font}, "FreeSerif";
        font-size: ${template_builder_advanced.master__runfoot_fontSize};
        font-weight: ${template_builder_advanced.master__runfoot_fontWeight};
        font-style: ${template_builder_advanced.master__runfoot_fontStyle};
        color: ${template_builder_advanced.master__runfoot_textColor};
        padding-bottom: ${template_builder_advanced.master__runfoot_spaceBelow};
        background-color: ${template_builder_advanced.master__runfoot_backgroundColor};
        border-top: ${template_builder_advanced.master__runfoot_borderTop.join(' ')};
        border-right: ${template_builder_advanced.master__runfoot_borderRight.join(' ')};
        border-bottom: ${template_builder_advanced.master__runfoot_borderBottom.join(' ')};
        border-left: ${template_builder_advanced.master__runfoot_borderLeft.join(' ')};
      }
      @bottom-left {
        font-family: ${template_builder_advanced.master__runfoot_font}, "FreeSerif";
        font-size: ${template_builder_advanced.master__runfoot_fontSize};
        font-weight: ${template_builder_advanced.master__runfoot_fontWeight};
        font-style: ${template_builder_advanced.master__runfoot_fontStyle};
        color: ${template_builder_advanced.master__runfoot_textColor};
        padding-bottom: ${template_builder_advanced.master__runfoot_spaceBelow};
        background-color: ${template_builder_advanced.master__runfoot_backgroundColor};
        border-top: ${template_builder_advanced.master__runfoot_borderTop.join(' ')};
        border-right: ${template_builder_advanced.master__runfoot_borderRight.join(' ')};
        border-bottom: ${template_builder_advanced.master__runfoot_borderBottom.join(' ')};
        border-left: ${template_builder_advanced.master__runfoot_borderLeft.join(' ')};
      }
    }
    @page :right {
      margin: ${template_builder_advanced.master__recto_margins.join(' ')};
      @top-${template_builder_advanced.master__recto_runheadAlignment} {
        content: ${template_builder_advanced.master__recto_runheadContent};
        text-align: ${template_builder_advanced.master__recto_runheadAlignment};
        text-align-last: auto;
      }
      @bottom-${template_builder_advanced.master__recto_runfootAlignment} {
        content: ${template_builder_advanced.master__recto_runfootContent};
        text-align: ${template_builder_advanced.master__recto_runfootAlignment};
        text-align-last: auto;
      }
    }
    @page :left {
      margin: ${template_builder_advanced.master__verso_margins.join(' ')};
      @top-${template_builder_advanced.master__verso_runheadAlignment} {
        content: ${template_builder_advanced.master__verso_runheadContent};
        text-align: ${template_builder_advanced.master__verso_runheadAlignment};
        text-align-last: auto;
      }
      @bottom-${template_builder_advanced.master__verso_runfootAlignment} {
        content: ${template_builder_advanced.master__verso_runfootContent};
        text-align: ${template_builder_advanced.master__verso_runfootAlignment};
        text-align-last: auto;
      }
    }
    @page :first {
      margin-top: ${template_builder_advanced.master__first_marginTop};
      margin-bottom: ${template_builder_advanced.master__first_marginBottom};
      @top-${template_builder_advanced.master__recto_runheadAlignment} {
        content: ${template_builder_advanced.master__first_runheadContent};
      }
      @top-${template_builder_advanced.master__verso_runheadAlignment} {
        content: ${template_builder_advanced.master__first_runheadContent};
      }
      @bottom-${template_builder_advanced.master__recto_runfootAlignment} {
        content: ${template_builder_advanced.master__first_runfootContent};
      }
      @bottom-${template_builder_advanced.master__verso_runfootAlignment} {
        content: ${template_builder_advanced.master__first_runfootContent};
      }
    }
    @page :blank {
      @top-${template_builder_advanced.master__recto_runheadAlignment} {
        content: ${template_builder_advanced.master__blank_runheadContent};
      }
      @top-${template_builder_advanced.master__verso_runheadAlignment} {
        content: ${template_builder_advanced.master__blank_runheadContent};
      }
      @bottom-${template_builder_advanced.master__recto_runfootAlignment} {
        content: ${template_builder_advanced.master__blank_runfootContent};
      }
      @bottom-${template_builder_advanced.master__verso_runfootAlignment} {
        content: ${template_builder_advanced.master__blank_runfootContent};
      }
    }
  @page chapter{
      @top-right {
        font-family: ${template_builder_advanced.master_chapter_runhead_font}, "FreeSerif";
        font-size: ${template_builder_advanced.master_chapter_runhead_fontSize};
        font-weight: ${template_builder_advanced.master_chapter_runhead_fontWeight};
        font-style: ${template_builder_advanced.master_chapter_runhead_fontStyle};
        color: ${template_builder_advanced.master_chapter_runhead_textColor};
        padding-bottom: ${template_builder_advanced.master_chapter_runhead_spaceBelow};
        background-color: ${template_builder_advanced.master_chapter_runhead_backgroundColor};
        border-top: ${template_builder_advanced.master_chapter_runhead_borderTop.join(' ')};
        border-right: ${template_builder_advanced.master_chapter_runhead_borderRight.join(' ')};
        border-bottom: ${template_builder_advanced.master_chapter_runhead_borderBottom.join(' ')};
        border-left: ${template_builder_advanced.master_chapter_runhead_borderLeft.join(' ')};
      }
      @top-center {
        font-family: ${template_builder_advanced.master_chapter_runhead_font}, "FreeSerif";
        font-size: ${template_builder_advanced.master_chapter_runhead_fontSize};
        font-weight: ${template_builder_advanced.master_chapter_runhead_fontWeight};
        font-style: ${template_builder_advanced.master_chapter_runhead_fontStyle};
        color: ${template_builder_advanced.master_chapter_runhead_textColor};
        padding-bottom: ${template_builder_advanced.master_chapter_runhead_spaceBelow};
        background-color: ${template_builder_advanced.master_chapter_runhead_backgroundColor};
        border-top: ${template_builder_advanced.master_chapter_runhead_borderTop.join(' ')};
        border-right: ${template_builder_advanced.master_chapter_runhead_borderRight.join(' ')};
        border-bottom: ${template_builder_advanced.master_chapter_runhead_borderBottom.join(' ')};
        border-left: ${template_builder_advanced.master_chapter_runhead_borderLeft.join(' ')};
      }
      @top-left {
        font-family: ${template_builder_advanced.master_chapter_runhead_font}, "FreeSerif";
        font-size: ${template_builder_advanced.master_chapter_runhead_fontSize};
        font-weight: ${template_builder_advanced.master_chapter_runhead_fontWeight};
        font-style: ${template_builder_advanced.master_chapter_runhead_fontStyle};
        color: ${template_builder_advanced.master_chapter_runhead_textColor};
        padding-bottom: ${template_builder_advanced.master_chapter_runhead_spaceBelow};
        background-color: ${template_builder_advanced.master_chapter_runhead_backgroundColor};
        border-top: ${template_builder_advanced.master_chapter_runhead_borderTop.join(' ')};
        border-right: ${template_builder_advanced.master_chapter_runhead_borderRight.join(' ')};
        border-bottom: ${template_builder_advanced.master_chapter_runhead_borderBottom.join(' ')};
        border-left: ${template_builder_advanced.master_chapter_runhead_borderLeft.join(' ')};
      }
      @bottom-right {
        font-family: ${template_builder_advanced.master_chapter_runfoot_font}, "FreeSerif";
        font-size: ${template_builder_advanced.master_chapter_runfoot_fontSize};
        font-weight: ${template_builder_advanced.master_chapter_runfoot_fontWeight};
        font-style: ${template_builder_advanced.master_chapter_runfoot_fontStyle};
        color: ${template_builder_advanced.master_chapter_runfoot_textColor};
        padding-bottom: ${template_builder_advanced.master_chapter_runfoot_spaceBelow};
        background-color: ${template_builder_advanced.master_chapter_runfoot_backgroundColor};
        border-top: ${template_builder_advanced.master_chapter_runfoot_borderTop.join(' ')};
        border-right: ${template_builder_advanced.master_chapter_runfoot_borderRight.join(' ')};
        border-bottom: ${template_builder_advanced.master_chapter_runfoot_borderBottom.join(' ')};
        border-left: ${template_builder_advanced.master_chapter_runfoot_borderLeft.join(' ')};
      }
      @bottom-center {
        font-family: ${template_builder_advanced.master_chapter_runfoot_font}, "FreeSerif";
        font-size: ${template_builder_advanced.master_chapter_runfoot_fontSize};
        font-weight: ${template_builder_advanced.master_chapter_runfoot_fontWeight};
        font-style: ${template_builder_advanced.master_chapter_runfoot_fontStyle};
        color: ${template_builder_advanced.master_chapter_runfoot_textColor};
        padding-bottom: ${template_builder_advanced.master_chapter_runfoot_spaceBelow};
        background-color: ${template_builder_advanced.master_chapter_runfoot_backgroundColor};
        border-top: ${template_builder_advanced.master_chapter_runfoot_borderTop.join(' ')};
        border-right: ${template_builder_advanced.master_chapter_runfoot_borderRight.join(' ')};
        border-bottom: ${template_builder_advanced.master_chapter_runfoot_borderBottom.join(' ')};
        border-left: ${template_builder_advanced.master_chapter_runfoot_borderLeft.join(' ')};
      }
      @bottom-left {
        font-family: ${template_builder_advanced.master_chapter_runfoot_font}, "FreeSerif";
        font-size: ${template_builder_advanced.master_chapter_runfoot_fontSize};
        font-weight: ${template_builder_advanced.master_chapter_runfoot_fontWeight};
        font-style: ${template_builder_advanced.master_chapter_runfoot_fontStyle};
        color: ${template_builder_advanced.master_chapter_runfoot_textColor};
        padding-bottom: ${template_builder_advanced.master_chapter_runfoot_spaceBelow};
        background-color: ${template_builder_advanced.master_chapter_runfoot_backgroundColor};
        border-top: ${template_builder_advanced.master_chapter_runfoot_borderTop.join(' ')};
        border-right: ${template_builder_advanced.master_chapter_runfoot_borderRight.join(' ')};
        border-bottom: ${template_builder_advanced.master_chapter_runfoot_borderBottom.join(' ')};
        border-left: ${template_builder_advanced.master_chapter_runfoot_borderLeft.join(' ')};
      }
    }
    @page chapter:right {
      margin: ${template_builder_advanced.master_chapter_recto_margins.join(' ')};
      @top-${template_builder_advanced.master_chapter_recto_runheadAlignment} {
        content: ${template_builder_advanced.master_chapter_recto_runheadContent};
        text-align: ${template_builder_advanced.master_chapter_recto_runheadAlignment};
        text-align-last: auto;
      }
      @bottom-${template_builder_advanced.master_chapter_recto_runfootAlignment} {
        content: ${template_builder_advanced.master_chapter_recto_runfootContent};
        text-align: ${template_builder_advanced.master_chapter_recto_runfootAlignment};
        text-align-last: auto;
      }
    }
    @page chapter:left {
      margin: ${template_builder_advanced.master_chapter_verso_margins.join(' ')};
      @top-${template_builder_advanced.master_chapter_verso_runheadAlignment} {
        content: ${template_builder_advanced.master_chapter_verso_runheadContent};
        text-align: ${template_builder_advanced.master_chapter_verso_runheadAlignment};
        text-align-last: auto;
      }
      @bottom-${template_builder_advanced.master_chapter_verso_runfootAlignment} {
        content: ${template_builder_advanced.master_chapter_verso_runfootContent};
        text-align: ${template_builder_advanced.master_chapter_verso_runfootAlignment};
        text-align-last: auto;
      }
    }
    @page chapter:first {
      margin-top: ${template_builder_advanced.master_chapter_first_marginTop};
      margin-bottom: ${template_builder_advanced.master_chapter_first_marginBottom};
      @top-${template_builder_advanced.master_chapter_recto_runheadAlignment} {
        content: ${template_builder_advanced.master_chapter_first_runheadContent};
      }
      @top-${template_builder_advanced.master_chapter_verso_runheadAlignment} {
        content: ${template_builder_advanced.master_chapter_first_runheadContent};
      }
      @bottom-${template_builder_advanced.master_chapter_recto_runfootAlignment} {
        content: ${template_builder_advanced.master_chapter_first_runfootContent};
      }
      @bottom-${template_builder_advanced.master_chapter_verso_runfootAlignment} {
        content: ${template_builder_advanced.master_chapter_first_runfootContent};
      }
    }
    @page chapter:blank {
      @top-${template_builder_advanced.master_chapter_recto_runheadAlignment} {
        content: ${template_builder_advanced.master_chapter_blank_runheadContent};
      }
      @top-${template_builder_advanced.master_chapter_verso_runheadAlignment} {
        content: ${template_builder_advanced.master_chapter_blank_runheadContent};
      }
      @bottom-${template_builder_advanced.master_chapter_recto_runfootAlignment} {
        content: ${template_builder_advanced.master_chapter_blank_runfootContent};
      }
      @bottom-${template_builder_advanced.master_chapter_verso_runfootAlignment} {
        content: ${template_builder_advanced.master_chapter_blank_runfootContent};
      }
    }
  @page frontmatter{
      @top-right {
        font-family: ${template_builder_advanced.master_frontmatter_runhead_font}, "FreeSerif";
        font-size: ${template_builder_advanced.master_frontmatter_runhead_fontSize};
        font-weight: ${template_builder_advanced.master_frontmatter_runhead_fontWeight};
        font-style: ${template_builder_advanced.master_frontmatter_runhead_fontStyle};
        color: ${template_builder_advanced.master_frontmatter_runhead_textColor};
        padding-bottom: ${template_builder_advanced.master_frontmatter_runhead_spaceBelow};
        background-color: ${template_builder_advanced.master_frontmatter_runhead_backgroundColor};
        border-top: ${template_builder_advanced.master_frontmatter_runhead_borderTop.join(' ')};
        border-right: ${template_builder_advanced.master_frontmatter_runhead_borderRight.join(' ')};
        border-bottom: ${template_builder_advanced.master_frontmatter_runhead_borderBottom.join(' ')};
        border-left: ${template_builder_advanced.master_frontmatter_runhead_borderLeft.join(' ')};
      }
      @top-center {
        font-family: ${template_builder_advanced.master_frontmatter_runhead_font}, "FreeSerif";
        font-size: ${template_builder_advanced.master_frontmatter_runhead_fontSize};
        font-weight: ${template_builder_advanced.master_frontmatter_runhead_fontWeight};
        font-style: ${template_builder_advanced.master_frontmatter_runhead_fontStyle};
        color: ${template_builder_advanced.master_frontmatter_runhead_textColor};
        padding-bottom: ${template_builder_advanced.master_frontmatter_runhead_spaceBelow};
        background-color: ${template_builder_advanced.master_frontmatter_runhead_backgroundColor};
        border-top: ${template_builder_advanced.master_frontmatter_runhead_borderTop.join(' ')};
        border-right: ${template_builder_advanced.master_frontmatter_runhead_borderRight.join(' ')};
        border-bottom: ${template_builder_advanced.master_frontmatter_runhead_borderBottom.join(' ')};
        border-left: ${template_builder_advanced.master_frontmatter_runhead_borderLeft.join(' ')};
      }
      @top-left {
        font-family: ${template_builder_advanced.master_frontmatter_runhead_font}, "FreeSerif";
        font-size: ${template_builder_advanced.master_frontmatter_runhead_fontSize};
        font-weight: ${template_builder_advanced.master_frontmatter_runhead_fontWeight};
        font-style: ${template_builder_advanced.master_frontmatter_runhead_fontStyle};
        color: ${template_builder_advanced.master_frontmatter_runhead_textColor};
        padding-bottom: ${template_builder_advanced.master_frontmatter_runhead_spaceBelow};
        background-color: ${template_builder_advanced.master_frontmatter_runhead_backgroundColor};
        border-top: ${template_builder_advanced.master_frontmatter_runhead_borderTop.join(' ')};
        border-right: ${template_builder_advanced.master_frontmatter_runhead_borderRight.join(' ')};
        border-bottom: ${template_builder_advanced.master_frontmatter_runhead_borderBottom.join(' ')};
        border-left: ${template_builder_advanced.master_frontmatter_runhead_borderLeft.join(' ')};
      }
      @bottom-right {
        font-family: ${template_builder_advanced.master_frontmatter_runfoot_font}, "FreeSerif";
        font-size: ${template_builder_advanced.master_frontmatter_runfoot_fontSize};
        font-weight: ${template_builder_advanced.master_frontmatter_runfoot_fontWeight};
        font-style: ${template_builder_advanced.master_frontmatter_runfoot_fontStyle};
        color: ${template_builder_advanced.master_frontmatter_runfoot_textColor};
        padding-bottom: ${template_builder_advanced.master_frontmatter_runfoot_spaceBelow};
        background-color: ${template_builder_advanced.master_frontmatter_runfoot_backgroundColor};
        border-top: ${template_builder_advanced.master_frontmatter_runfoot_borderTop.join(' ')};
        border-right: ${template_builder_advanced.master_frontmatter_runfoot_borderRight.join(' ')};
        border-bottom: ${template_builder_advanced.master_frontmatter_runfoot_borderBottom.join(' ')};
        border-left: ${template_builder_advanced.master_frontmatter_runfoot_borderLeft.join(' ')};
      }
      @bottom-center {
        font-family: ${template_builder_advanced.master_frontmatter_runfoot_font}, "FreeSerif";
        font-size: ${template_builder_advanced.master_frontmatter_runfoot_fontSize};
        font-weight: ${template_builder_advanced.master_frontmatter_runfoot_fontWeight};
        font-style: ${template_builder_advanced.master_frontmatter_runfoot_fontStyle};
        color: ${template_builder_advanced.master_frontmatter_runfoot_textColor};
        padding-bottom: ${template_builder_advanced.master_frontmatter_runfoot_spaceBelow};
        background-color: ${template_builder_advanced.master_frontmatter_runfoot_backgroundColor};
        border-top: ${template_builder_advanced.master_frontmatter_runfoot_borderTop.join(' ')};
        border-right: ${template_builder_advanced.master_frontmatter_runfoot_borderRight.join(' ')};
        border-bottom: ${template_builder_advanced.master_frontmatter_runfoot_borderBottom.join(' ')};
        border-left: ${template_builder_advanced.master_frontmatter_runfoot_borderLeft.join(' ')};
      }
      @bottom-left {
        font-family: ${template_builder_advanced.master_frontmatter_runfoot_font}, "FreeSerif";
        font-size: ${template_builder_advanced.master_frontmatter_runfoot_fontSize};
        font-weight: ${template_builder_advanced.master_frontmatter_runfoot_fontWeight};
        font-style: ${template_builder_advanced.master_frontmatter_runfoot_fontStyle};
        color: ${template_builder_advanced.master_frontmatter_runfoot_textColor};
        padding-bottom: ${template_builder_advanced.master_frontmatter_runfoot_spaceBelow};
        background-color: ${template_builder_advanced.master_frontmatter_runfoot_backgroundColor};
        border-top: ${template_builder_advanced.master_frontmatter_runfoot_borderTop.join(' ')};
        border-right: ${template_builder_advanced.master_frontmatter_runfoot_borderRight.join(' ')};
        border-bottom: ${template_builder_advanced.master_frontmatter_runfoot_borderBottom.join(' ')};
        border-left: ${template_builder_advanced.master_frontmatter_runfoot_borderLeft.join(' ')};
      }
    }
    @page frontmatter:right {
      margin: ${template_builder_advanced.master_frontmatter_recto_margins.join(' ')};
      @top-${template_builder_advanced.master_frontmatter_recto_runheadAlignment} {
        content: ${template_builder_advanced.master_frontmatter_recto_runheadContent};
        text-align: ${template_builder_advanced.master_frontmatter_recto_runheadAlignment};
        text-align-last: auto;
      }
      @bottom-${template_builder_advanced.master_frontmatter_recto_runfootAlignment} {
        content: ${template_builder_advanced.master_frontmatter_recto_runfootContent};
        text-align: ${template_builder_advanced.master_frontmatter_recto_runfootAlignment};
        text-align-last: auto;
      }
    }
    @page frontmatter:left {
      margin: ${template_builder_advanced.master_frontmatter_verso_margins.join(' ')};
      @top-${template_builder_advanced.master_frontmatter_verso_runheadAlignment} {
        content: ${template_builder_advanced.master_frontmatter_verso_runheadContent};
        text-align: ${template_builder_advanced.master_frontmatter_verso_runheadAlignment};
        text-align-last: auto;
      }
      @bottom-${template_builder_advanced.master_frontmatter_verso_runfootAlignment} {
        content: ${template_builder_advanced.master_frontmatter_verso_runfootContent};
        text-align: ${template_builder_advanced.master_frontmatter_verso_runfootAlignment};
        text-align-last: auto;
      }
    }
    @page frontmatter:first {
      margin-top: ${template_builder_advanced.master_frontmatter_first_marginTop};
      margin-bottom: ${template_builder_advanced.master_frontmatter_first_marginBottom};
      @top-${template_builder_advanced.master_frontmatter_recto_runheadAlignment} {
        content: ${template_builder_advanced.master_frontmatter_first_runheadContent};
      }
      @top-${template_builder_advanced.master_frontmatter_verso_runheadAlignment} {
        content: ${template_builder_advanced.master_frontmatter_first_runheadContent};
      }
      @bottom-${template_builder_advanced.master_frontmatter_recto_runfootAlignment} {
        content: ${template_builder_advanced.master_frontmatter_first_runfootContent};
      }
      @bottom-${template_builder_advanced.master_frontmatter_verso_runfootAlignment} {
        content: ${template_builder_advanced.master_frontmatter_first_runfootContent};
      }
    }
    @page frontmatter:blank {
      @top-${template_builder_advanced.master_frontmatter_recto_runheadAlignment} {
        content: ${template_builder_advanced.master_frontmatter_blank_runheadContent};
      }
      @top-${template_builder_advanced.master_frontmatter_verso_runheadAlignment} {
        content: ${template_builder_advanced.master_frontmatter_blank_runheadContent};
      }
      @bottom-${template_builder_advanced.master_frontmatter_recto_runfootAlignment} {
        content: ${template_builder_advanced.master_frontmatter_blank_runfootContent};
      }
      @bottom-${template_builder_advanced.master_frontmatter_verso_runfootAlignment} {
        content: ${template_builder_advanced.master_frontmatter_blank_runfootContent};
      }
    }
  @page backmatter{
      @top-right {
        font-family: ${template_builder_advanced.master_backmatter_runhead_font}, "FreeSerif";
        font-size: ${template_builder_advanced.master_backmatter_runhead_fontSize};
        font-weight: ${template_builder_advanced.master_backmatter_runhead_fontWeight};
        font-style: ${template_builder_advanced.master_backmatter_runhead_fontStyle};
        color: ${template_builder_advanced.master_backmatter_runhead_textColor};
        padding-bottom: ${template_builder_advanced.master_backmatter_runhead_spaceBelow};
        background-color: ${template_builder_advanced.master_backmatter_runhead_backgroundColor};
        border-top: ${template_builder_advanced.master_backmatter_runhead_borderTop.join(' ')};
        border-right: ${template_builder_advanced.master_backmatter_runhead_borderRight.join(' ')};
        border-bottom: ${template_builder_advanced.master_backmatter_runhead_borderBottom.join(' ')};
        border-left: ${template_builder_advanced.master_backmatter_runhead_borderLeft.join(' ')};
      }
      @top-center {
        font-family: ${template_builder_advanced.master_backmatter_runhead_font}, "FreeSerif";
        font-size: ${template_builder_advanced.master_backmatter_runhead_fontSize};
        font-weight: ${template_builder_advanced.master_backmatter_runhead_fontWeight};
        font-style: ${template_builder_advanced.master_backmatter_runhead_fontStyle};
        color: ${template_builder_advanced.master_backmatter_runhead_textColor};
        padding-bottom: ${template_builder_advanced.master_backmatter_runhead_spaceBelow};
        background-color: ${template_builder_advanced.master_backmatter_runhead_backgroundColor};
        border-top: ${template_builder_advanced.master_backmatter_runhead_borderTop.join(' ')};
        border-right: ${template_builder_advanced.master_backmatter_runhead_borderRight.join(' ')};
        border-bottom: ${template_builder_advanced.master_backmatter_runhead_borderBottom.join(' ')};
        border-left: ${template_builder_advanced.master_backmatter_runhead_borderLeft.join(' ')};
      }
      @top-left {
        font-family: ${template_builder_advanced.master_backmatter_runhead_font}, "FreeSerif";
        font-size: ${template_builder_advanced.master_backmatter_runhead_fontSize};
        font-weight: ${template_builder_advanced.master_backmatter_runhead_fontWeight};
        font-style: ${template_builder_advanced.master_backmatter_runhead_fontStyle};
        color: ${template_builder_advanced.master_backmatter_runhead_textColor};
        padding-bottom: ${template_builder_advanced.master_backmatter_runhead_spaceBelow};
        background-color: ${template_builder_advanced.master_backmatter_runhead_backgroundColor};
        border-top: ${template_builder_advanced.master_backmatter_runhead_borderTop.join(' ')};
        border-right: ${template_builder_advanced.master_backmatter_runhead_borderRight.join(' ')};
        border-bottom: ${template_builder_advanced.master_backmatter_runhead_borderBottom.join(' ')};
        border-left: ${template_builder_advanced.master_backmatter_runhead_borderLeft.join(' ')};
      }
      @bottom-right {
        font-family: ${template_builder_advanced.master_backmatter_runfoot_font}, "FreeSerif";
        font-size: ${template_builder_advanced.master_backmatter_runfoot_fontSize};
        font-weight: ${template_builder_advanced.master_backmatter_runfoot_fontWeight};
        font-style: ${template_builder_advanced.master_backmatter_runfoot_fontStyle};
        color: ${template_builder_advanced.master_backmatter_runfoot_textColor};
        padding-bottom: ${template_builder_advanced.master_backmatter_runfoot_spaceBelow};
        background-color: ${template_builder_advanced.master_backmatter_runfoot_backgroundColor};
        border-top: ${template_builder_advanced.master_backmatter_runfoot_borderTop.join(' ')};
        border-right: ${template_builder_advanced.master_backmatter_runfoot_borderRight.join(' ')};
        border-bottom: ${template_builder_advanced.master_backmatter_runfoot_borderBottom.join(' ')};
        border-left: ${template_builder_advanced.master_backmatter_runfoot_borderLeft.join(' ')};
      }
      @bottom-center {
        font-family: ${template_builder_advanced.master_backmatter_runfoot_font}, "FreeSerif";
        font-size: ${template_builder_advanced.master_backmatter_runfoot_fontSize};
        font-weight: ${template_builder_advanced.master_backmatter_runfoot_fontWeight};
        font-style: ${template_builder_advanced.master_backmatter_runfoot_fontStyle};
        color: ${template_builder_advanced.master_backmatter_runfoot_textColor};
        padding-bottom: ${template_builder_advanced.master_backmatter_runfoot_spaceBelow};
        background-color: ${template_builder_advanced.master_backmatter_runfoot_backgroundColor};
        border-top: ${template_builder_advanced.master_backmatter_runfoot_borderTop.join(' ')};
        border-right: ${template_builder_advanced.master_backmatter_runfoot_borderRight.join(' ')};
        border-bottom: ${template_builder_advanced.master_backmatter_runfoot_borderBottom.join(' ')};
        border-left: ${template_builder_advanced.master_backmatter_runfoot_borderLeft.join(' ')};
      }
      @bottom-left {
        font-family: ${template_builder_advanced.master_backmatter_runfoot_font}, "FreeSerif";
        font-size: ${template_builder_advanced.master_backmatter_runfoot_fontSize};
        font-weight: ${template_builder_advanced.master_backmatter_runfoot_fontWeight};
        font-style: ${template_builder_advanced.master_backmatter_runfoot_fontStyle};
        color: ${template_builder_advanced.master_backmatter_runfoot_textColor};
        padding-bottom: ${template_builder_advanced.master_backmatter_runfoot_spaceBelow};
        background-color: ${template_builder_advanced.master_backmatter_runfoot_backgroundColor};
        border-top: ${template_builder_advanced.master_backmatter_runfoot_borderTop.join(' ')};
        border-right: ${template_builder_advanced.master_backmatter_runfoot_borderRight.join(' ')};
        border-bottom: ${template_builder_advanced.master_backmatter_runfoot_borderBottom.join(' ')};
        border-left: ${template_builder_advanced.master_backmatter_runfoot_borderLeft.join(' ')};
      }
    }
    @page backmatter:right {
      margin: ${template_builder_advanced.master_backmatter_recto_margins.join(' ')};
      @top-${template_builder_advanced.master_backmatter_recto_runheadAlignment} {
        content: ${template_builder_advanced.master_backmatter_recto_runheadContent};
        text-align: ${template_builder_advanced.master_backmatter_recto_runheadAlignment};
        text-align-last: auto;
      }
      @bottom-${template_builder_advanced.master_backmatter_recto_runfootAlignment} {
        content: ${template_builder_advanced.master_backmatter_recto_runfootContent};
        text-align: ${template_builder_advanced.master_backmatter_recto_runfootAlignment};
        text-align-last: auto;
      }
    }
    @page backmatter:left {
      margin: ${template_builder_advanced.master_backmatter_verso_margins.join(' ')};
      @top-${template_builder_advanced.master_backmatter_verso_runheadAlignment} {
        content: ${template_builder_advanced.master_backmatter_verso_runheadContent};
        text-align: ${template_builder_advanced.master_backmatter_verso_runheadAlignment};
        text-align-last: auto;
      }
      @bottom-${template_builder_advanced.master_backmatter_verso_runfootAlignment} {
        content: ${template_builder_advanced.master_backmatter_verso_runfootContent};
        text-align: ${template_builder_advanced.master_backmatter_verso_runfootAlignment};
        text-align-last: auto;
      }
    }
    @page backmatter:first {
      margin-top: ${template_builder_advanced.master_backmatter_first_marginTop};
      margin-bottom: ${template_builder_advanced.master_backmatter_first_marginBottom};
      @top-${template_builder_advanced.master_backmatter_recto_runheadAlignment} {
        content: ${template_builder_advanced.master_backmatter_first_runheadContent};
      }
      @top-${template_builder_advanced.master_backmatter_verso_runheadAlignment} {
        content: ${template_builder_advanced.master_backmatter_first_runheadContent};
      }
      @bottom-${template_builder_advanced.master_backmatter_recto_runfootAlignment} {
        content: ${template_builder_advanced.master_backmatter_first_runfootContent};
      }
      @bottom-${template_builder_advanced.master_backmatter_verso_runfootAlignment} {
        content: ${template_builder_advanced.master_backmatter_first_runfootContent};
      }
    }
    @page backmatter:blank {
      @top-${template_builder_advanced.master_backmatter_recto_runheadAlignment} {
        content: ${template_builder_advanced.master_backmatter_blank_runheadContent};
      }
      @top-${template_builder_advanced.master_backmatter_verso_runheadAlignment} {
        content: ${template_builder_advanced.master_backmatter_blank_runheadContent};
      }
      @bottom-${template_builder_advanced.master_backmatter_recto_runfootAlignment} {
        content: ${template_builder_advanced.master_backmatter_blank_runfootContent};
      }
      @bottom-${template_builder_advanced.master_backmatter_verso_runfootAlignment} {
        content: ${template_builder_advanced.master_backmatter_blank_runfootContent};
      }
    }
  @page part{
      @top-right {
        font-family: ${template_builder_advanced.master_part_runhead_font}, "FreeSerif";
        font-size: ${template_builder_advanced.master_part_runhead_fontSize};
        font-weight: ${template_builder_advanced.master_part_runhead_fontWeight};
        font-style: ${template_builder_advanced.master_part_runhead_fontStyle};
        color: ${template_builder_advanced.master_part_runhead_textColor};
        padding-bottom: ${template_builder_advanced.master_part_runhead_spaceBelow};
        background-color: ${template_builder_advanced.master_part_runhead_backgroundColor};
        border-top: ${template_builder_advanced.master_part_runhead_borderTop.join(' ')};
        border-right: ${template_builder_advanced.master_part_runhead_borderRight.join(' ')};
        border-bottom: ${template_builder_advanced.master_part_runhead_borderBottom.join(' ')};
        border-left: ${template_builder_advanced.master_part_runhead_borderLeft.join(' ')};
      }
      @top-center {
        font-family: ${template_builder_advanced.master_part_runhead_font}, "FreeSerif";
        font-size: ${template_builder_advanced.master_part_runhead_fontSize};
        font-weight: ${template_builder_advanced.master_part_runhead_fontWeight};
        font-style: ${template_builder_advanced.master_part_runhead_fontStyle};
        color: ${template_builder_advanced.master_part_runhead_textColor};
        padding-bottom: ${template_builder_advanced.master_part_runhead_spaceBelow};
        background-color: ${template_builder_advanced.master_part_runhead_backgroundColor};
        border-top: ${template_builder_advanced.master_part_runhead_borderTop.join(' ')};
        border-right: ${template_builder_advanced.master_part_runhead_borderRight.join(' ')};
        border-bottom: ${template_builder_advanced.master_part_runhead_borderBottom.join(' ')};
        border-left: ${template_builder_advanced.master_part_runhead_borderLeft.join(' ')};
      }
      @top-left {
        font-family: ${template_builder_advanced.master_part_runhead_font}, "FreeSerif";
        font-size: ${template_builder_advanced.master_part_runhead_fontSize};
        font-weight: ${template_builder_advanced.master_part_runhead_fontWeight};
        font-style: ${template_builder_advanced.master_part_runhead_fontStyle};
        color: ${template_builder_advanced.master_part_runhead_textColor};
        padding-bottom: ${template_builder_advanced.master_part_runhead_spaceBelow};
        background-color: ${template_builder_advanced.master_part_runhead_backgroundColor};
        border-top: ${template_builder_advanced.master_part_runhead_borderTop.join(' ')};
        border-right: ${template_builder_advanced.master_part_runhead_borderRight.join(' ')};
        border-bottom: ${template_builder_advanced.master_part_runhead_borderBottom.join(' ')};
        border-left: ${template_builder_advanced.master_part_runhead_borderLeft.join(' ')};
      }
      @bottom-right {
        font-family: ${template_builder_advanced.master_part_runfoot_font}, "FreeSerif";
        font-size: ${template_builder_advanced.master_part_runfoot_fontSize};
        font-weight: ${template_builder_advanced.master_part_runfoot_fontWeight};
        font-style: ${template_builder_advanced.master_part_runfoot_fontStyle};
        color: ${template_builder_advanced.master_part_runfoot_textColor};
        padding-bottom: ${template_builder_advanced.master_part_runfoot_spaceBelow};
        background-color: ${template_builder_advanced.master_part_runfoot_backgroundColor};
        border-top: ${template_builder_advanced.master_part_runfoot_borderTop.join(' ')};
        border-right: ${template_builder_advanced.master_part_runfoot_borderRight.join(' ')};
        border-bottom: ${template_builder_advanced.master_part_runfoot_borderBottom.join(' ')};
        border-left: ${template_builder_advanced.master_part_runfoot_borderLeft.join(' ')};
      }
      @bottom-center {
        font-family: ${template_builder_advanced.master_part_runfoot_font}, "FreeSerif";
        font-size: ${template_builder_advanced.master_part_runfoot_fontSize};
        font-weight: ${template_builder_advanced.master_part_runfoot_fontWeight};
        font-style: ${template_builder_advanced.master_part_runfoot_fontStyle};
        color: ${template_builder_advanced.master_part_runfoot_textColor};
        padding-bottom: ${template_builder_advanced.master_part_runfoot_spaceBelow};
        background-color: ${template_builder_advanced.master_part_runfoot_backgroundColor};
        border-top: ${template_builder_advanced.master_part_runfoot_borderTop.join(' ')};
        border-right: ${template_builder_advanced.master_part_runfoot_borderRight.join(' ')};
        border-bottom: ${template_builder_advanced.master_part_runfoot_borderBottom.join(' ')};
        border-left: ${template_builder_advanced.master_part_runfoot_borderLeft.join(' ')};
      }
      @bottom-left {
        font-family: ${template_builder_advanced.master_part_runfoot_font}, "FreeSerif";
        font-size: ${template_builder_advanced.master_part_runfoot_fontSize};
        font-weight: ${template_builder_advanced.master_part_runfoot_fontWeight};
        font-style: ${template_builder_advanced.master_part_runfoot_fontStyle};
        color: ${template_builder_advanced.master_part_runfoot_textColor};
        padding-bottom: ${template_builder_advanced.master_part_runfoot_spaceBelow};
        background-color: ${template_builder_advanced.master_part_runfoot_backgroundColor};
        border-top: ${template_builder_advanced.master_part_runfoot_borderTop.join(' ')};
        border-right: ${template_builder_advanced.master_part_runfoot_borderRight.join(' ')};
        border-bottom: ${template_builder_advanced.master_part_runfoot_borderBottom.join(' ')};
        border-left: ${template_builder_advanced.master_part_runfoot_borderLeft.join(' ')};
      }
    }
    @page part:right {
      margin: ${template_builder_advanced.master_part_recto_margins.join(' ')};
      @top-${template_builder_advanced.master_part_recto_runheadAlignment} {
        content: ${template_builder_advanced.master_part_recto_runheadContent};
        text-align: ${template_builder_advanced.master_part_recto_runheadAlignment};
        text-align-last: auto;
      }
      @bottom-${template_builder_advanced.master_part_recto_runfootAlignment} {
        content: ${template_builder_advanced.master_part_recto_runfootContent};
        text-align: ${template_builder_advanced.master_part_recto_runfootAlignment};
        text-align-last: auto;
      }
    }
    @page part:left {
      margin: ${template_builder_advanced.master_part_verso_margins.join(' ')};
      @top-${template_builder_advanced.master_part_verso_runheadAlignment} {
        content: ${template_builder_advanced.master_part_verso_runheadContent};
        text-align: ${template_builder_advanced.master_part_verso_runheadAlignment};
        text-align-last: auto;
      }
      @bottom-${template_builder_advanced.master_part_verso_runfootAlignment} {
        content: ${template_builder_advanced.master_part_verso_runfootContent};
        text-align: ${template_builder_advanced.master_part_verso_runfootAlignment};
        text-align-last: auto;
      }
    }
    @page part:first {
      margin-top: ${template_builder_advanced.master_part_first_marginTop};
      margin-bottom: ${template_builder_advanced.master_part_first_marginBottom};
      @top-${template_builder_advanced.master_part_recto_runheadAlignment} {
        content: ${template_builder_advanced.master_part_first_runheadContent};
      }
      @top-${template_builder_advanced.master_part_verso_runheadAlignment} {
        content: ${template_builder_advanced.master_part_first_runheadContent};
      }
      @bottom-${template_builder_advanced.master_part_recto_runfootAlignment} {
        content: ${template_builder_advanced.master_part_first_runfootContent};
      }
      @bottom-${template_builder_advanced.master_part_verso_runfootAlignment} {
        content: ${template_builder_advanced.master_part_first_runfootContent};
      }
    }
    @page part:blank {
      @top-${template_builder_advanced.master_part_recto_runheadAlignment} {
        content: ${template_builder_advanced.master_part_blank_runheadContent};
      }
      @top-${template_builder_advanced.master_part_verso_runheadAlignment} {
        content: ${template_builder_advanced.master_part_blank_runheadContent};
      }
      @bottom-${template_builder_advanced.master_part_recto_runfootAlignment} {
        content: ${template_builder_advanced.master_part_blank_runfootContent};
      }
      @bottom-${template_builder_advanced.master_part_verso_runfootAlignment} {
        content: ${template_builder_advanced.master_part_blank_runfootContent};
      }
    }
  @page clear{
      @top-right {
        font-family: ${template_builder_advanced.master_clear_runhead_font}, "FreeSerif";
        font-size: ${template_builder_advanced.master_clear_runhead_fontSize};
        font-weight: ${template_builder_advanced.master_clear_runhead_fontWeight};
        font-style: ${template_builder_advanced.master_clear_runhead_fontStyle};
        color: ${template_builder_advanced.master_clear_runhead_textColor};
        padding-bottom: ${template_builder_advanced.master_clear_runhead_spaceBelow};
        background-color: ${template_builder_advanced.master_clear_runhead_backgroundColor};
        border-top: ${template_builder_advanced.master_clear_runhead_borderTop.join(' ')};
        border-right: ${template_builder_advanced.master_clear_runhead_borderRight.join(' ')};
        border-bottom: ${template_builder_advanced.master_clear_runhead_borderBottom.join(' ')};
        border-left: ${template_builder_advanced.master_clear_runhead_borderLeft.join(' ')};
      }
      @top-center {
        font-family: ${template_builder_advanced.master_clear_runhead_font}, "FreeSerif";
        font-size: ${template_builder_advanced.master_clear_runhead_fontSize};
        font-weight: ${template_builder_advanced.master_clear_runhead_fontWeight};
        font-style: ${template_builder_advanced.master_clear_runhead_fontStyle};
        color: ${template_builder_advanced.master_clear_runhead_textColor};
        padding-bottom: ${template_builder_advanced.master_clear_runhead_spaceBelow};
        background-color: ${template_builder_advanced.master_clear_runhead_backgroundColor};
        border-top: ${template_builder_advanced.master_clear_runhead_borderTop.join(' ')};
        border-right: ${template_builder_advanced.master_clear_runhead_borderRight.join(' ')};
        border-bottom: ${template_builder_advanced.master_clear_runhead_borderBottom.join(' ')};
        border-left: ${template_builder_advanced.master_clear_runhead_borderLeft.join(' ')};
      }
      @top-left {
        font-family: ${template_builder_advanced.master_clear_runhead_font}, "FreeSerif";
        font-size: ${template_builder_advanced.master_clear_runhead_fontSize};
        font-weight: ${template_builder_advanced.master_clear_runhead_fontWeight};
        font-style: ${template_builder_advanced.master_clear_runhead_fontStyle};
        color: ${template_builder_advanced.master_clear_runhead_textColor};
        padding-bottom: ${template_builder_advanced.master_clear_runhead_spaceBelow};
        background-color: ${template_builder_advanced.master_clear_runhead_backgroundColor};
        border-top: ${template_builder_advanced.master_clear_runhead_borderTop.join(' ')};
        border-right: ${template_builder_advanced.master_clear_runhead_borderRight.join(' ')};
        border-bottom: ${template_builder_advanced.master_clear_runhead_borderBottom.join(' ')};
        border-left: ${template_builder_advanced.master_clear_runhead_borderLeft.join(' ')};
      }
      @bottom-right {
        font-family: ${template_builder_advanced.master_clear_runfoot_font}, "FreeSerif";
        font-size: ${template_builder_advanced.master_clear_runfoot_fontSize};
        font-weight: ${template_builder_advanced.master_clear_runfoot_fontWeight};
        font-style: ${template_builder_advanced.master_clear_runfoot_fontStyle};
        color: ${template_builder_advanced.master_clear_runfoot_textColor};
        padding-bottom: ${template_builder_advanced.master_clear_runfoot_spaceBelow};
        background-color: ${template_builder_advanced.master_clear_runfoot_backgroundColor};
        border-top: ${template_builder_advanced.master_clear_runfoot_borderTop.join(' ')};
        border-right: ${template_builder_advanced.master_clear_runfoot_borderRight.join(' ')};
        border-bottom: ${template_builder_advanced.master_clear_runfoot_borderBottom.join(' ')};
        border-left: ${template_builder_advanced.master_clear_runfoot_borderLeft.join(' ')};
      }
      @bottom-center {
        font-family: ${template_builder_advanced.master_clear_runfoot_font}, "FreeSerif";
        font-size: ${template_builder_advanced.master_clear_runfoot_fontSize};
        font-weight: ${template_builder_advanced.master_clear_runfoot_fontWeight};
        font-style: ${template_builder_advanced.master_clear_runfoot_fontStyle};
        color: ${template_builder_advanced.master_clear_runfoot_textColor};
        padding-bottom: ${template_builder_advanced.master_clear_runfoot_spaceBelow};
        background-color: ${template_builder_advanced.master_clear_runfoot_backgroundColor};
        border-top: ${template_builder_advanced.master_clear_runfoot_borderTop.join(' ')};
        border-right: ${template_builder_advanced.master_clear_runfoot_borderRight.join(' ')};
        border-bottom: ${template_builder_advanced.master_clear_runfoot_borderBottom.join(' ')};
        border-left: ${template_builder_advanced.master_clear_runfoot_borderLeft.join(' ')};
      }
      @bottom-left {
        font-family: ${template_builder_advanced.master_clear_runfoot_font}, "FreeSerif";
        font-size: ${template_builder_advanced.master_clear_runfoot_fontSize};
        font-weight: ${template_builder_advanced.master_clear_runfoot_fontWeight};
        font-style: ${template_builder_advanced.master_clear_runfoot_fontStyle};
        color: ${template_builder_advanced.master_clear_runfoot_textColor};
        padding-bottom: ${template_builder_advanced.master_clear_runfoot_spaceBelow};
        background-color: ${template_builder_advanced.master_clear_runfoot_backgroundColor};
        border-top: ${template_builder_advanced.master_clear_runfoot_borderTop.join(' ')};
        border-right: ${template_builder_advanced.master_clear_runfoot_borderRight.join(' ')};
        border-bottom: ${template_builder_advanced.master_clear_runfoot_borderBottom.join(' ')};
        border-left: ${template_builder_advanced.master_clear_runfoot_borderLeft.join(' ')};
      }
    }
    @page clear:right {
      margin: ${template_builder_advanced.master_clear_recto_margins.join(' ')};
      @top-${template_builder_advanced.master_clear_recto_runheadAlignment} {
        content: ${template_builder_advanced.master_clear_recto_runheadContent};
        text-align: ${template_builder_advanced.master_clear_recto_runheadAlignment};
        text-align-last: auto;
      }
      @bottom-${template_builder_advanced.master_clear_recto_runfootAlignment} {
        content: ${template_builder_advanced.master_clear_recto_runfootContent};
        text-align: ${template_builder_advanced.master_clear_recto_runfootAlignment};
        text-align-last: auto;
      }
    }
    @page clear:left {
      margin: ${template_builder_advanced.master_clear_verso_margins.join(' ')};
      @top-${template_builder_advanced.master_clear_verso_runheadAlignment} {
        content: ${template_builder_advanced.master_clear_verso_runheadContent};
        text-align: ${template_builder_advanced.master_clear_verso_runheadAlignment};
        text-align-last: auto;
      }
      @bottom-${template_builder_advanced.master_clear_verso_runfootAlignment} {
        content: ${template_builder_advanced.master_clear_verso_runfootContent};
        text-align: ${template_builder_advanced.master_clear_verso_runfootAlignment};
        text-align-last: auto;
      }
    }
    @page clear:first {
      margin-top: ${template_builder_advanced.master_clear_first_marginTop};
      margin-bottom: ${template_builder_advanced.master_clear_first_marginBottom};
      @top-${template_builder_advanced.master_clear_recto_runheadAlignment} {
        content: ${template_builder_advanced.master_clear_first_runheadContent};
      }
      @top-${template_builder_advanced.master_clear_verso_runheadAlignment} {
        content: ${template_builder_advanced.master_clear_first_runheadContent};
      }
      @bottom-${template_builder_advanced.master_clear_recto_runfootAlignment} {
        content: ${template_builder_advanced.master_clear_first_runfootContent};
      }
      @bottom-${template_builder_advanced.master_clear_verso_runfootAlignment} {
        content: ${template_builder_advanced.master_clear_first_runfootContent};
      }
    }
    @page clear:blank {
      @top-${template_builder_advanced.master_clear_recto_runheadAlignment} {
        content: ${template_builder_advanced.master_clear_blank_runheadContent};
      }
      @top-${template_builder_advanced.master_clear_verso_runheadAlignment} {
        content: ${template_builder_advanced.master_clear_blank_runheadContent};
      }
      @bottom-${template_builder_advanced.master_clear_recto_runfootAlignment} {
        content: ${template_builder_advanced.master_clear_blank_runfootContent};
      }
      @bottom-${template_builder_advanced.master_clear_verso_runfootAlignment} {
        content: ${template_builder_advanced.master_clear_blank_runfootContent};
      }
    }
* {
    box-sizing: border-box;
  }

  body {
    font-family: ${template_builder_advanced.body_bodyFont}, "FreeSerif";
    font-size: ${template_builder_advanced.body_bodySize};
    line-height: ${template_builder_advanced.body_lineHeight};
    background-repeat: repeat;
    background-position: top left;
    background-size: auto ${template_builder_advanced.body_lineHeight};
    counter-reset: page 1;
  }

  p {
    font-family: ${template_builder_advanced.body_bodyFont}, "FreeSerif";
    font-size: ${template_builder_advanced.body_bodySize};
    line-height: ${template_builder_advanced.body_lineHeight};
  }

  section {
    display: block;
  }
  `
};
// prettier-ignore
function FAKEFUNC(){
    return `
  [data-hederis-type=hsecchapter] {
    page: ${template_builder_advanced.hsecchapter_page};
    page-break-before: ${template_builder_advanced.hsecchapter_pageStart};
    counter-reset: ${template_builder_advanced.hsecchapter_folioStart};
    string-set: authorname attr(data-author-name), booktitle attr(data-book-title), chaptitle attr(title), chaptitle attr(data-chap-display-title);
  }
  [data-hederis-type=hsechalftitlepage] {
    page: ${template_builder_advanced.hsechalftitlepage_page};
    page-break-before: ${template_builder_advanced.hsechalftitlepage_pageStart};
    counter-reset: ${template_builder_advanced.hsechalftitlepage_folioStart};
    string-set: authorname attr(data-author-name), booktitle attr(data-book-title), chaptitle attr(title), chaptitle attr(data-chap-display-title);
  }
  [data-hederis-type=hsectitlepage] {
    page: ${template_builder_advanced.hsectitlepage_page};
    page-break-before: ${template_builder_advanced.hsectitlepage_pageStart};
    counter-reset: ${template_builder_advanced.hsectitlepage_folioStart};
    string-set: authorname attr(data-author-name), booktitle attr(data-book-title), chaptitle attr(title), chaptitle attr(data-chap-display-title);
  }
  [data-hederis-type=hseccopyright-page] {
    page: ${template_builder_advanced.hseccopyright_page_page};
    page-break-before: ${template_builder_advanced.hseccopyright_page_pageStart};
    counter-reset: ${template_builder_advanced.hseccopyright_page_folioStart};
    string-set: authorname attr(data-author-name), booktitle attr(data-book-title), chaptitle attr(title), chaptitle attr(data-chap-display-title);
  }
  [data-hederis-type=hsecdedication] {
    page: ${template_builder_advanced.hsecdedication_page};
    page-break-before: ${template_builder_advanced.hsecdedication_pageStart};
    counter-reset: ${template_builder_advanced.hsecdedication_folioStart};
    string-set: authorname attr(data-author-name), booktitle attr(data-book-title), chaptitle attr(title), chaptitle attr(data-chap-display-title);
  }
  [data-hederis-type=hsecepigraph] {
    page: ${template_builder_advanced.hsecepigraph_page};
    page-break-before: ${template_builder_advanced.hsecepigraph_pageStart};
    counter-reset: ${template_builder_advanced.hsecepigraph_folioStart};
    string-set: authorname attr(data-author-name), booktitle attr(data-book-title), chaptitle attr(title), chaptitle attr(data-chap-display-title);
  }
  [data-hederis-type=hsecforeword] {
    page: ${template_builder_advanced.hsecforeword_page};
    page-break-before: ${template_builder_advanced.hsecforeword_pageStart};
    counter-reset: ${template_builder_advanced.hsecforeword_folioStart};
    string-set: authorname attr(data-author-name), booktitle attr(data-book-title), chaptitle attr(title), chaptitle attr(data-chap-display-title);
  }
  [data-hederis-type=hsecpreface] {
    page: ${template_builder_advanced.hsecpreface_page};
    page-break-before: ${template_builder_advanced.hsecpreface_pageStart};
    counter-reset: ${template_builder_advanced.hsecpreface_folioStart};
    string-set: authorname attr(data-author-name), booktitle attr(data-book-title), chaptitle attr(title), chaptitle attr(data-chap-display-title);
  }
  [data-hederis-type=hsectoc] {
    page: ${template_builder_advanced.hsectoc_page};
    page-break-before: ${template_builder_advanced.hsectoc_pageStart};
    counter-reset: ${template_builder_advanced.hsectoc_folioStart};
    string-set: authorname attr(data-author-name), booktitle attr(data-book-title), chaptitle attr(title), chaptitle attr(data-chap-display-title);
  }
  [data-hederis-type=hsecintroduction] {
    page: ${template_builder_advanced.hsecintroduction_page};
    page-break-before: ${template_builder_advanced.hsecintroduction_pageStart};
    counter-reset: ${template_builder_advanced.hsecintroduction_folioStart};
    string-set: authorname attr(data-author-name), booktitle attr(data-book-title), chaptitle attr(title), chaptitle attr(data-chap-display-title);
  }
  [data-hederis-type=hsecpart] {
    page: ${template_builder_advanced.hsecpart_page};
    page-break-before: ${template_builder_advanced.hsecpart_pageStart};
    counter-reset: ${template_builder_advanced.hsecpart_folioStart};
    string-set: authorname attr(data-author-name), booktitle attr(data-book-title), chaptitle attr(title), chaptitle attr(data-chap-display-title);
  }
  [data-hederis-type=hsecinterlude] {
    page: ${template_builder_advanced.hsecinterlude_page};
    page-break-before: ${template_builder_advanced.hsecinterlude_pageStart};
    counter-reset: ${template_builder_advanced.hsecinterlude_folioStart};
    string-set: authorname attr(data-author-name), booktitle attr(data-book-title), chaptitle attr(title), chaptitle attr(data-chap-display-title);
  }
  [data-hederis-type=hsecappendix] {
    page: ${template_builder_advanced.hsecappendix_page};
    page-break-before: ${template_builder_advanced.hsecappendix_pageStart};
    counter-reset: ${template_builder_advanced.hsecappendix_folioStart};
    string-set: authorname attr(data-author-name), booktitle attr(data-book-title), chaptitle attr(title), chaptitle attr(data-chap-display-title);
  }
  [data-hederis-type=hseccolophon] {
    page: ${template_builder_advanced.hseccolophon_page};
    page-break-before: ${template_builder_advanced.hseccolophon_pageStart};
    counter-reset: ${template_builder_advanced.hseccolophon_folioStart};
    string-set: authorname attr(data-author-name), booktitle attr(data-book-title), chaptitle attr(title), chaptitle attr(data-chap-display-title);
  }
  [data-hederis-type=hsecacknowledgments] {
    page: ${template_builder_advanced.hsecacknowledgments_page};
    page-break-before: ${template_builder_advanced.hsecacknowledgments_pageStart};
    counter-reset: ${template_builder_advanced.hsecacknowledgments_folioStart};
    string-set: authorname attr(data-author-name), booktitle attr(data-book-title), chaptitle attr(title), chaptitle attr(data-chap-display-title);
  }
  [data-hederis-type=hsecafterword] {
    page: ${template_builder_advanced.hsecafterword_page};
    page-break-before: ${template_builder_advanced.hsecafterword_pageStart};
    counter-reset: ${template_builder_advanced.hsecafterword_folioStart};
    string-set: authorname attr(data-author-name), booktitle attr(data-book-title), chaptitle attr(title), chaptitle attr(data-chap-display-title);
  }
  [data-hederis-type=hsecconclusion] {
    page: ${template_builder_advanced.hsecconclusion_page};
    page-break-before: ${template_builder_advanced.hsecconclusion_pageStart};
    counter-reset: ${template_builder_advanced.hsecconclusion_folioStart};
    string-set: authorname attr(data-author-name), booktitle attr(data-book-title), chaptitle attr(title), chaptitle attr(data-chap-display-title);
  }
  [data-hederis-type=hsecglossary] {
    page: ${template_builder_advanced.hsecglossary_page};
    page-break-before: ${template_builder_advanced.hsecglossary_pageStart};
    counter-reset: ${template_builder_advanced.hsecglossary_folioStart};
    string-set: authorname attr(data-author-name), booktitle attr(data-book-title), chaptitle attr(title), chaptitle attr(data-chap-display-title);
  }
  [data-hederis-type=hsecbibliography] {
    page: ${template_builder_advanced.hsecbibliography_page};
    page-break-before: ${template_builder_advanced.hsecbibliography_pageStart};
    counter-reset: ${template_builder_advanced.hsecbibliography_folioStart};
    string-set: authorname attr(data-author-name), booktitle attr(data-book-title), chaptitle attr(title), chaptitle attr(data-chap-display-title);
  }
  [data-hederis-type=hsecabout-the-author] {
    page: ${template_builder_advanced.hsecabout_the_author_page};
    page-break-before: ${template_builder_advanced.hsecabout_the_author_pageStart};
    counter-reset: ${template_builder_advanced.hsecabout_the_author_folioStart};
    string-set: authorname attr(data-author-name), booktitle attr(data-book-title), chaptitle attr(title), chaptitle attr(data-chap-display-title);
  }
  [data-hederis-type=hsecindex] {
    page: ${template_builder_advanced.hsecindex_page};
    page-break-before: ${template_builder_advanced.hsecindex_pageStart};
    counter-reset: ${template_builder_advanced.hsecindex_folioStart};
    string-set: authorname attr(data-author-name), booktitle attr(data-book-title), chaptitle attr(title), chaptitle attr(data-chap-display-title);
  }
  [data-hederis-type=hsecendnotes] {
    page: ${template_builder_advanced.hsecendnotes_page};
    page-break-before: ${template_builder_advanced.hsecendnotes_pageStart};
    counter-reset: ${template_builder_advanced.hsecendnotes_folioStart};
    string-set: authorname attr(data-author-name), booktitle attr(data-book-title), chaptitle attr(title), chaptitle attr(data-chap-display-title);
  }
  [data-hederis-type=hwprext] {
    font-family: ${template_builder_advanced.hwprext_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hwprext_fontSize};
    font-weight: ${template_builder_advanced.hwprext_fontWeight};
    font-style: ${template_builder_advanced.hwprext_fontStyle};
    color: ${template_builder_advanced.hwprext_textColor};
    text-indent: ${template_builder_advanced.hwprext_textIndent};
    line-height: ${template_builder_advanced.hwprext_lineHeight};
    text-align: ${template_builder_advanced.hwprext_textAlign};
    text-align-last: ${template_builder_advanced.hwprext_textAlignLast};
    font-variant: ${template_builder_advanced.hwprext_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hwprext_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hwprext_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hwprext_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hwprext_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hwprext_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hwprext_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hwprext_margins.join(' ')};
    padding: ${template_builder_advanced.hwprext_padding.join(' ')};
    background-color: ${template_builder_advanced.hwprext_backgroundColor};
    border-top: ${template_builder_advanced.hwprext_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hwprext_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hwprext_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hwprext_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hwprext_borderRadius.join(' ')};
    width: ${template_builder_advanced.hwprext_width};
    height: ${template_builder_advanced.hwprext_height};
    string-set: ${template_builder_advanced.hwprext_stringSet};
  }

  [data-hederis-type=hwprext]::before {
    content: ${template_builder_advanced.hwprext_before_content};
  }

  [data-hederis-type=hwprext]::after {
    content: ${template_builder_advanced.hwprext_after_content};
  }
  [data-hederis-type=hwprfig] {
    font-family: ${template_builder_advanced.hwprfig_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hwprfig_fontSize};
    font-weight: ${template_builder_advanced.hwprfig_fontWeight};
    font-style: ${template_builder_advanced.hwprfig_fontStyle};
    color: ${template_builder_advanced.hwprfig_textColor};
    text-indent: ${template_builder_advanced.hwprfig_textIndent};
    line-height: ${template_builder_advanced.hwprfig_lineHeight};
    text-align: ${template_builder_advanced.hwprfig_textAlign};
    text-align-last: ${template_builder_advanced.hwprfig_textAlignLast};
    font-variant: ${template_builder_advanced.hwprfig_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hwprfig_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hwprfig_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hwprfig_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hwprfig_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hwprfig_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hwprfig_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hwprfig_margins.join(' ')};
    padding: ${template_builder_advanced.hwprfig_padding.join(' ')};
    background-color: ${template_builder_advanced.hwprfig_backgroundColor};
    border-top: ${template_builder_advanced.hwprfig_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hwprfig_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hwprfig_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hwprfig_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hwprfig_borderRadius.join(' ')};
    width: ${template_builder_advanced.hwprfig_width};
    height: ${template_builder_advanced.hwprfig_height};
    string-set: ${template_builder_advanced.hwprfig_stringSet};
  }

  [data-hederis-type=hwprfig]::before {
    content: ${template_builder_advanced.hwprfig_before_content};
  }

  [data-hederis-type=hwprfig]::after {
    content: ${template_builder_advanced.hwprfig_after_content};
  }
  [data-hederis-type=hwprbullet-list] {
    font-family: ${template_builder_advanced.hwprbullet_list_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hwprbullet_list_fontSize};
    font-weight: ${template_builder_advanced.hwprbullet_list_fontWeight};
    font-style: ${template_builder_advanced.hwprbullet_list_fontStyle};
    color: ${template_builder_advanced.hwprbullet_list_textColor};
    text-indent: ${template_builder_advanced.hwprbullet_list_textIndent};
    line-height: ${template_builder_advanced.hwprbullet_list_lineHeight};
    text-align: ${template_builder_advanced.hwprbullet_list_textAlign};
    text-align-last: ${template_builder_advanced.hwprbullet_list_textAlignLast};
    font-variant: ${template_builder_advanced.hwprbullet_list_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hwprbullet_list_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hwprbullet_list_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hwprbullet_list_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hwprbullet_list_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hwprbullet_list_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hwprbullet_list_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hwprbullet_list_margins.join(' ')};
    padding: ${template_builder_advanced.hwprbullet_list_padding.join(' ')};
    background-color: ${template_builder_advanced.hwprbullet_list_backgroundColor};
    border-top: ${template_builder_advanced.hwprbullet_list_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hwprbullet_list_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hwprbullet_list_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hwprbullet_list_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hwprbullet_list_borderRadius.join(' ')};
    width: ${template_builder_advanced.hwprbullet_list_width};
    height: ${template_builder_advanced.hwprbullet_list_height};
    string-set: ${template_builder_advanced.hwprbullet_list_stringSet};
  }

  [data-hederis-type=hwprbullet-list]::before {
    content: ${template_builder_advanced.hwprbullet_list_before_content};
  }

  [data-hederis-type=hwprbullet-list]::after {
    content: ${template_builder_advanced.hwprbullet_list_after_content};
  }
  [data-hederis-type=hwprnum-list] {
    font-family: ${template_builder_advanced.hwprnum_list_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hwprnum_list_fontSize};
    font-weight: ${template_builder_advanced.hwprnum_list_fontWeight};
    font-style: ${template_builder_advanced.hwprnum_list_fontStyle};
    color: ${template_builder_advanced.hwprnum_list_textColor};
    text-indent: ${template_builder_advanced.hwprnum_list_textIndent};
    line-height: ${template_builder_advanced.hwprnum_list_lineHeight};
    text-align: ${template_builder_advanced.hwprnum_list_textAlign};
    text-align-last: ${template_builder_advanced.hwprnum_list_textAlignLast};
    font-variant: ${template_builder_advanced.hwprnum_list_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hwprnum_list_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hwprnum_list_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hwprnum_list_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hwprnum_list_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hwprnum_list_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hwprnum_list_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hwprnum_list_margins.join(' ')};
    padding: ${template_builder_advanced.hwprnum_list_padding.join(' ')};
    background-color: ${template_builder_advanced.hwprnum_list_backgroundColor};
    border-top: ${template_builder_advanced.hwprnum_list_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hwprnum_list_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hwprnum_list_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hwprnum_list_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hwprnum_list_borderRadius.join(' ')};
    width: ${template_builder_advanced.hwprnum_list_width};
    height: ${template_builder_advanced.hwprnum_list_height};
    string-set: ${template_builder_advanced.hwprnum_list_stringSet};
  }

  [data-hederis-type=hwprnum-list]::before {
    content: ${template_builder_advanced.hwprnum_list_before_content};
  }

  [data-hederis-type=hwprnum-list]::after {
    content: ${template_builder_advanced.hwprnum_list_after_content};
  }
  [data-hederis-type=hwprsimple-list] {
    font-family: ${template_builder_advanced.hwprsimple_list_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hwprsimple_list_fontSize};
    font-weight: ${template_builder_advanced.hwprsimple_list_fontWeight};
    font-style: ${template_builder_advanced.hwprsimple_list_fontStyle};
    color: ${template_builder_advanced.hwprsimple_list_textColor};
    text-indent: ${template_builder_advanced.hwprsimple_list_textIndent};
    line-height: ${template_builder_advanced.hwprsimple_list_lineHeight};
    text-align: ${template_builder_advanced.hwprsimple_list_textAlign};
    text-align-last: ${template_builder_advanced.hwprsimple_list_textAlignLast};
    font-variant: ${template_builder_advanced.hwprsimple_list_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hwprsimple_list_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hwprsimple_list_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hwprsimple_list_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hwprsimple_list_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hwprsimple_list_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hwprsimple_list_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hwprsimple_list_margins.join(' ')};
    padding: ${template_builder_advanced.hwprsimple_list_padding.join(' ')};
    background-color: ${template_builder_advanced.hwprsimple_list_backgroundColor};
    border-top: ${template_builder_advanced.hwprsimple_list_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hwprsimple_list_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hwprsimple_list_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hwprsimple_list_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hwprsimple_list_borderRadius.join(' ')};
    width: ${template_builder_advanced.hwprsimple_list_width};
    height: ${template_builder_advanced.hwprsimple_list_height};
    string-set: ${template_builder_advanced.hwprsimple_list_stringSet};
  }

  [data-hederis-type=hwprsimple-list]::before {
    content: ${template_builder_advanced.hwprsimple_list_before_content};
  }

  [data-hederis-type=hwprsimple-list]::after {
    content: ${template_builder_advanced.hwprsimple_list_after_content};
  }
  [data-hederis-type=hwprdef-list] {
    font-family: ${template_builder_advanced.hwprdef_list_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hwprdef_list_fontSize};
    font-weight: ${template_builder_advanced.hwprdef_list_fontWeight};
    font-style: ${template_builder_advanced.hwprdef_list_fontStyle};
    color: ${template_builder_advanced.hwprdef_list_textColor};
    text-indent: ${template_builder_advanced.hwprdef_list_textIndent};
    line-height: ${template_builder_advanced.hwprdef_list_lineHeight};
    text-align: ${template_builder_advanced.hwprdef_list_textAlign};
    text-align-last: ${template_builder_advanced.hwprdef_list_textAlignLast};
    font-variant: ${template_builder_advanced.hwprdef_list_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hwprdef_list_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hwprdef_list_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hwprdef_list_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hwprdef_list_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hwprdef_list_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hwprdef_list_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hwprdef_list_margins.join(' ')};
    padding: ${template_builder_advanced.hwprdef_list_padding.join(' ')};
    background-color: ${template_builder_advanced.hwprdef_list_backgroundColor};
    border-top: ${template_builder_advanced.hwprdef_list_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hwprdef_list_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hwprdef_list_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hwprdef_list_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hwprdef_list_borderRadius.join(' ')};
    width: ${template_builder_advanced.hwprdef_list_width};
    height: ${template_builder_advanced.hwprdef_list_height};
    string-set: ${template_builder_advanced.hwprdef_list_stringSet};
  }

  [data-hederis-type=hwprdef-list]::before {
    content: ${template_builder_advanced.hwprdef_list_before_content};
  }

  [data-hederis-type=hwprdef-list]::after {
    content: ${template_builder_advanced.hwprdef_list_after_content};
  }
  [data-hederis-type=hwprepi] {
    font-family: ${template_builder_advanced.hwprepi_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hwprepi_fontSize};
    font-weight: ${template_builder_advanced.hwprepi_fontWeight};
    font-style: ${template_builder_advanced.hwprepi_fontStyle};
    color: ${template_builder_advanced.hwprepi_textColor};
    text-indent: ${template_builder_advanced.hwprepi_textIndent};
    line-height: ${template_builder_advanced.hwprepi_lineHeight};
    text-align: ${template_builder_advanced.hwprepi_textAlign};
    text-align-last: ${template_builder_advanced.hwprepi_textAlignLast};
    font-variant: ${template_builder_advanced.hwprepi_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hwprepi_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hwprepi_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hwprepi_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hwprepi_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hwprepi_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hwprepi_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hwprepi_margins.join(' ')};
    padding: ${template_builder_advanced.hwprepi_padding.join(' ')};
    background-color: ${template_builder_advanced.hwprepi_backgroundColor};
    border-top: ${template_builder_advanced.hwprepi_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hwprepi_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hwprepi_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hwprepi_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hwprepi_borderRadius.join(' ')};
    width: ${template_builder_advanced.hwprepi_width};
    height: ${template_builder_advanced.hwprepi_height};
    string-set: ${template_builder_advanced.hwprepi_stringSet};
  }

  [data-hederis-type=hwprepi]::before {
    content: ${template_builder_advanced.hwprepi_before_content};
  }

  [data-hederis-type=hwprepi]::after {
    content: ${template_builder_advanced.hwprepi_after_content};
  }
  [data-hederis-type=hwprletter] {
    font-family: ${template_builder_advanced.hwprletter_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hwprletter_fontSize};
    font-weight: ${template_builder_advanced.hwprletter_fontWeight};
    font-style: ${template_builder_advanced.hwprletter_fontStyle};
    color: ${template_builder_advanced.hwprletter_textColor};
    text-indent: ${template_builder_advanced.hwprletter_textIndent};
    line-height: ${template_builder_advanced.hwprletter_lineHeight};
    text-align: ${template_builder_advanced.hwprletter_textAlign};
    text-align-last: ${template_builder_advanced.hwprletter_textAlignLast};
    font-variant: ${template_builder_advanced.hwprletter_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hwprletter_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hwprletter_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hwprletter_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hwprletter_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hwprletter_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hwprletter_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hwprletter_margins.join(' ')};
    padding: ${template_builder_advanced.hwprletter_padding.join(' ')};
    background-color: ${template_builder_advanced.hwprletter_backgroundColor};
    border-top: ${template_builder_advanced.hwprletter_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hwprletter_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hwprletter_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hwprletter_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hwprletter_borderRadius.join(' ')};
    width: ${template_builder_advanced.hwprletter_width};
    height: ${template_builder_advanced.hwprletter_height};
    string-set: ${template_builder_advanced.hwprletter_stringSet};
  }

  [data-hederis-type=hwprletter]::before {
    content: ${template_builder_advanced.hwprletter_before_content};
  }

  [data-hederis-type=hwprletter]::after {
    content: ${template_builder_advanced.hwprletter_after_content};
  }
  [data-hederis-type=hwprsubsection] {
    font-family: ${template_builder_advanced.hwprsubsection_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hwprsubsection_fontSize};
    font-weight: ${template_builder_advanced.hwprsubsection_fontWeight};
    font-style: ${template_builder_advanced.hwprsubsection_fontStyle};
    color: ${template_builder_advanced.hwprsubsection_textColor};
    text-indent: ${template_builder_advanced.hwprsubsection_textIndent};
    line-height: ${template_builder_advanced.hwprsubsection_lineHeight};
    text-align: ${template_builder_advanced.hwprsubsection_textAlign};
    text-align-last: ${template_builder_advanced.hwprsubsection_textAlignLast};
    font-variant: ${template_builder_advanced.hwprsubsection_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hwprsubsection_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hwprsubsection_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hwprsubsection_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hwprsubsection_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hwprsubsection_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hwprsubsection_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hwprsubsection_margins.join(' ')};
    padding: ${template_builder_advanced.hwprsubsection_padding.join(' ')};
    background-color: ${template_builder_advanced.hwprsubsection_backgroundColor};
    border-top: ${template_builder_advanced.hwprsubsection_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hwprsubsection_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hwprsubsection_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hwprsubsection_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hwprsubsection_borderRadius.join(' ')};
    width: ${template_builder_advanced.hwprsubsection_width};
    height: ${template_builder_advanced.hwprsubsection_height};
    string-set: ${template_builder_advanced.hwprsubsection_stringSet};
  }

  [data-hederis-type=hwprsubsection]::before {
    content: ${template_builder_advanced.hwprsubsection_before_content};
  }

  [data-hederis-type=hwprsubsection]::after {
    content: ${template_builder_advanced.hwprsubsection_after_content};
  }
  [data-hederis-type=hwprsidebar] {
    font-family: ${template_builder_advanced.hwprsidebar_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hwprsidebar_fontSize};
    font-weight: ${template_builder_advanced.hwprsidebar_fontWeight};
    font-style: ${template_builder_advanced.hwprsidebar_fontStyle};
    color: ${template_builder_advanced.hwprsidebar_textColor};
    text-indent: ${template_builder_advanced.hwprsidebar_textIndent};
    line-height: ${template_builder_advanced.hwprsidebar_lineHeight};
    text-align: ${template_builder_advanced.hwprsidebar_textAlign};
    text-align-last: ${template_builder_advanced.hwprsidebar_textAlignLast};
    font-variant: ${template_builder_advanced.hwprsidebar_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hwprsidebar_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hwprsidebar_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hwprsidebar_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hwprsidebar_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hwprsidebar_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hwprsidebar_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hwprsidebar_margins.join(' ')};
    padding: ${template_builder_advanced.hwprsidebar_padding.join(' ')};
    background-color: ${template_builder_advanced.hwprsidebar_backgroundColor};
    border-top: ${template_builder_advanced.hwprsidebar_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hwprsidebar_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hwprsidebar_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hwprsidebar_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hwprsidebar_borderRadius.join(' ')};
    width: ${template_builder_advanced.hwprsidebar_width};
    height: ${template_builder_advanced.hwprsidebar_height};
    string-set: ${template_builder_advanced.hwprsidebar_stringSet};
  }

  [data-hederis-type=hwprsidebar]::before {
    content: ${template_builder_advanced.hwprsidebar_before_content};
  }

  [data-hederis-type=hwprsidebar]::after {
    content: ${template_builder_advanced.hwprsidebar_after_content};
  }
  [data-hederis-type=hwprbox] {
    font-family: ${template_builder_advanced.hwprbox_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hwprbox_fontSize};
    font-weight: ${template_builder_advanced.hwprbox_fontWeight};
    font-style: ${template_builder_advanced.hwprbox_fontStyle};
    color: ${template_builder_advanced.hwprbox_textColor};
    text-indent: ${template_builder_advanced.hwprbox_textIndent};
    line-height: ${template_builder_advanced.hwprbox_lineHeight};
    text-align: ${template_builder_advanced.hwprbox_textAlign};
    text-align-last: ${template_builder_advanced.hwprbox_textAlignLast};
    font-variant: ${template_builder_advanced.hwprbox_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hwprbox_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hwprbox_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hwprbox_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hwprbox_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hwprbox_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hwprbox_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hwprbox_margins.join(' ')};
    padding: ${template_builder_advanced.hwprbox_padding.join(' ')};
    background-color: ${template_builder_advanced.hwprbox_backgroundColor};
    border-top: ${template_builder_advanced.hwprbox_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hwprbox_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hwprbox_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hwprbox_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hwprbox_borderRadius.join(' ')};
    width: ${template_builder_advanced.hwprbox_width};
    height: ${template_builder_advanced.hwprbox_height};
    string-set: ${template_builder_advanced.hwprbox_stringSet};
  }

  [data-hederis-type=hwprbox]::before {
    content: ${template_builder_advanced.hwprbox_before_content};
  }

  [data-hederis-type=hwprbox]::after {
    content: ${template_builder_advanced.hwprbox_after_content};
  }
  [data-hederis-type=hwprpoetry] {
    font-family: ${template_builder_advanced.hwprpoetry_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hwprpoetry_fontSize};
    font-weight: ${template_builder_advanced.hwprpoetry_fontWeight};
    font-style: ${template_builder_advanced.hwprpoetry_fontStyle};
    color: ${template_builder_advanced.hwprpoetry_textColor};
    text-indent: ${template_builder_advanced.hwprpoetry_textIndent};
    line-height: ${template_builder_advanced.hwprpoetry_lineHeight};
    text-align: ${template_builder_advanced.hwprpoetry_textAlign};
    text-align-last: ${template_builder_advanced.hwprpoetry_textAlignLast};
    font-variant: ${template_builder_advanced.hwprpoetry_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hwprpoetry_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hwprpoetry_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hwprpoetry_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hwprpoetry_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hwprpoetry_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hwprpoetry_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hwprpoetry_margins.join(' ')};
    padding: ${template_builder_advanced.hwprpoetry_padding.join(' ')};
    background-color: ${template_builder_advanced.hwprpoetry_backgroundColor};
    border-top: ${template_builder_advanced.hwprpoetry_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hwprpoetry_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hwprpoetry_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hwprpoetry_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hwprpoetry_borderRadius.join(' ')};
    width: ${template_builder_advanced.hwprpoetry_width};
    height: ${template_builder_advanced.hwprpoetry_height};
    string-set: ${template_builder_advanced.hwprpoetry_stringSet};
  }

  [data-hederis-type=hwprpoetry]::before {
    content: ${template_builder_advanced.hwprpoetry_before_content};
  }

  [data-hederis-type=hwprpoetry]::after {
    content: ${template_builder_advanced.hwprpoetry_after_content};
  }
  [data-hederis-type=hwprnote] {
    font-family: ${template_builder_advanced.hwprnote_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hwprnote_fontSize};
    font-weight: ${template_builder_advanced.hwprnote_fontWeight};
    font-style: ${template_builder_advanced.hwprnote_fontStyle};
    color: ${template_builder_advanced.hwprnote_textColor};
    text-indent: ${template_builder_advanced.hwprnote_textIndent};
    line-height: ${template_builder_advanced.hwprnote_lineHeight};
    text-align: ${template_builder_advanced.hwprnote_textAlign};
    text-align-last: ${template_builder_advanced.hwprnote_textAlignLast};
    font-variant: ${template_builder_advanced.hwprnote_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hwprnote_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hwprnote_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hwprnote_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hwprnote_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hwprnote_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hwprnote_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hwprnote_margins.join(' ')};
    padding: ${template_builder_advanced.hwprnote_padding.join(' ')};
    background-color: ${template_builder_advanced.hwprnote_backgroundColor};
    border-top: ${template_builder_advanced.hwprnote_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hwprnote_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hwprnote_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hwprnote_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hwprnote_borderRadius.join(' ')};
    width: ${template_builder_advanced.hwprnote_width};
    height: ${template_builder_advanced.hwprnote_height};
    string-set: ${template_builder_advanced.hwprnote_stringSet};
  }

  [data-hederis-type=hwprnote]::before {
    content: ${template_builder_advanced.hwprnote_before_content};
  }

  [data-hederis-type=hwprnote]::after {
    content: ${template_builder_advanced.hwprnote_after_content};
  }
  [data-hederis-type=hwprfootnote] {
    font-family: ${template_builder_advanced.hwprfootnote_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hwprfootnote_fontSize};
    font-weight: ${template_builder_advanced.hwprfootnote_fontWeight};
    font-style: ${template_builder_advanced.hwprfootnote_fontStyle};
    color: ${template_builder_advanced.hwprfootnote_textColor};
    text-indent: ${template_builder_advanced.hwprfootnote_textIndent};
    line-height: ${template_builder_advanced.hwprfootnote_lineHeight};
    text-align: ${template_builder_advanced.hwprfootnote_textAlign};
    text-align-last: ${template_builder_advanced.hwprfootnote_textAlignLast};
    font-variant: ${template_builder_advanced.hwprfootnote_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hwprfootnote_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hwprfootnote_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hwprfootnote_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hwprfootnote_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hwprfootnote_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hwprfootnote_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hwprfootnote_margins.join(' ')};
    padding: ${template_builder_advanced.hwprfootnote_padding.join(' ')};
    background-color: ${template_builder_advanced.hwprfootnote_backgroundColor};
    border-top: ${template_builder_advanced.hwprfootnote_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hwprfootnote_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hwprfootnote_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hwprfootnote_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hwprfootnote_borderRadius.join(' ')};
    width: ${template_builder_advanced.hwprfootnote_width};
    height: ${template_builder_advanced.hwprfootnote_height};
    string-set: ${template_builder_advanced.hwprfootnote_stringSet};
  }

  [data-hederis-type=hwprfootnote]::before {
    content: ${template_builder_advanced.hwprfootnote_before_content};
  }

  [data-hederis-type=hwprfootnote]::after {
    content: ${template_builder_advanced.hwprfootnote_after_content};
  }
  [data-hederis-type=hwprendnote] {
    font-family: ${template_builder_advanced.hwprendnote_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hwprendnote_fontSize};
    font-weight: ${template_builder_advanced.hwprendnote_fontWeight};
    font-style: ${template_builder_advanced.hwprendnote_fontStyle};
    color: ${template_builder_advanced.hwprendnote_textColor};
    text-indent: ${template_builder_advanced.hwprendnote_textIndent};
    line-height: ${template_builder_advanced.hwprendnote_lineHeight};
    text-align: ${template_builder_advanced.hwprendnote_textAlign};
    text-align-last: ${template_builder_advanced.hwprendnote_textAlignLast};
    font-variant: ${template_builder_advanced.hwprendnote_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hwprendnote_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hwprendnote_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hwprendnote_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hwprendnote_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hwprendnote_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hwprendnote_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hwprendnote_margins.join(' ')};
    padding: ${template_builder_advanced.hwprendnote_padding.join(' ')};
    background-color: ${template_builder_advanced.hwprendnote_backgroundColor};
    border-top: ${template_builder_advanced.hwprendnote_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hwprendnote_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hwprendnote_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hwprendnote_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hwprendnote_borderRadius.join(' ')};
    width: ${template_builder_advanced.hwprendnote_width};
    height: ${template_builder_advanced.hwprendnote_height};
    string-set: ${template_builder_advanced.hwprendnote_stringSet};
  }

  [data-hederis-type=hwprendnote]::before {
    content: ${template_builder_advanced.hwprendnote_before_content};
  }

  [data-hederis-type=hwprendnote]::after {
    content: ${template_builder_advanced.hwprendnote_after_content};
  }
  [data-hederis-type=hwprliteral] {
    font-family: ${template_builder_advanced.hwprliteral_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hwprliteral_fontSize};
    font-weight: ${template_builder_advanced.hwprliteral_fontWeight};
    font-style: ${template_builder_advanced.hwprliteral_fontStyle};
    color: ${template_builder_advanced.hwprliteral_textColor};
    text-indent: ${template_builder_advanced.hwprliteral_textIndent};
    line-height: ${template_builder_advanced.hwprliteral_lineHeight};
    text-align: ${template_builder_advanced.hwprliteral_textAlign};
    text-align-last: ${template_builder_advanced.hwprliteral_textAlignLast};
    font-variant: ${template_builder_advanced.hwprliteral_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hwprliteral_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hwprliteral_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hwprliteral_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hwprliteral_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hwprliteral_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hwprliteral_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hwprliteral_margins.join(' ')};
    padding: ${template_builder_advanced.hwprliteral_padding.join(' ')};
    background-color: ${template_builder_advanced.hwprliteral_backgroundColor};
    border-top: ${template_builder_advanced.hwprliteral_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hwprliteral_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hwprliteral_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hwprliteral_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hwprliteral_borderRadius.join(' ')};
    width: ${template_builder_advanced.hwprliteral_width};
    height: ${template_builder_advanced.hwprliteral_height};
    string-set: ${template_builder_advanced.hwprliteral_stringSet};
  }

  [data-hederis-type=hwprliteral]::before {
    content: ${template_builder_advanced.hwprliteral_before_content};
  }

  [data-hederis-type=hwprliteral]::after {
    content: ${template_builder_advanced.hwprliteral_after_content};
  }
  [data-hederis-type=hwprexample] {
    font-family: ${template_builder_advanced.hwprexample_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hwprexample_fontSize};
    font-weight: ${template_builder_advanced.hwprexample_fontWeight};
    font-style: ${template_builder_advanced.hwprexample_fontStyle};
    color: ${template_builder_advanced.hwprexample_textColor};
    text-indent: ${template_builder_advanced.hwprexample_textIndent};
    line-height: ${template_builder_advanced.hwprexample_lineHeight};
    text-align: ${template_builder_advanced.hwprexample_textAlign};
    text-align-last: ${template_builder_advanced.hwprexample_textAlignLast};
    font-variant: ${template_builder_advanced.hwprexample_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hwprexample_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hwprexample_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hwprexample_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hwprexample_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hwprexample_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hwprexample_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hwprexample_margins.join(' ')};
    padding: ${template_builder_advanced.hwprexample_padding.join(' ')};
    background-color: ${template_builder_advanced.hwprexample_backgroundColor};
    border-top: ${template_builder_advanced.hwprexample_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hwprexample_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hwprexample_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hwprexample_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hwprexample_borderRadius.join(' ')};
    width: ${template_builder_advanced.hwprexample_width};
    height: ${template_builder_advanced.hwprexample_height};
    string-set: ${template_builder_advanced.hwprexample_stringSet};
  }

  [data-hederis-type=hwprexample]::before {
    content: ${template_builder_advanced.hwprexample_before_content};
  }

  [data-hederis-type=hwprexample]::after {
    content: ${template_builder_advanced.hwprexample_after_content};
  }
  [data-hederis-type=hwprtable] {
    font-family: ${template_builder_advanced.hwprtable_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hwprtable_fontSize};
    font-weight: ${template_builder_advanced.hwprtable_fontWeight};
    font-style: ${template_builder_advanced.hwprtable_fontStyle};
    color: ${template_builder_advanced.hwprtable_textColor};
    text-indent: ${template_builder_advanced.hwprtable_textIndent};
    line-height: ${template_builder_advanced.hwprtable_lineHeight};
    text-align: ${template_builder_advanced.hwprtable_textAlign};
    text-align-last: ${template_builder_advanced.hwprtable_textAlignLast};
    font-variant: ${template_builder_advanced.hwprtable_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hwprtable_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hwprtable_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hwprtable_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hwprtable_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hwprtable_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hwprtable_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hwprtable_margins.join(' ')};
    padding: ${template_builder_advanced.hwprtable_padding.join(' ')};
    background-color: ${template_builder_advanced.hwprtable_backgroundColor};
    border-top: ${template_builder_advanced.hwprtable_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hwprtable_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hwprtable_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hwprtable_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hwprtable_borderRadius.join(' ')};
    width: ${template_builder_advanced.hwprtable_width};
    height: ${template_builder_advanced.hwprtable_height};
    string-set: ${template_builder_advanced.hwprtable_stringSet};
  }

  [data-hederis-type=hwprtable]::before {
    content: ${template_builder_advanced.hwprtable_before_content};
  }

  [data-hederis-type=hwprtable]::after {
    content: ${template_builder_advanced.hwprtable_after_content};
  }
  [data-hederis-type=hblkchaptitle] {
    font-family: ${template_builder_advanced.hblkchaptitle_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblkchaptitle_fontSize};
    font-weight: ${template_builder_advanced.hblkchaptitle_fontWeight};
    font-style: ${template_builder_advanced.hblkchaptitle_fontStyle};
    color: ${template_builder_advanced.hblkchaptitle_textColor};
    text-indent: ${template_builder_advanced.hblkchaptitle_textIndent};
    line-height: ${template_builder_advanced.hblkchaptitle_lineHeight};
    text-align: ${template_builder_advanced.hblkchaptitle_textAlign};
    text-align-last: ${template_builder_advanced.hblkchaptitle_textAlignLast};
    font-variant: ${template_builder_advanced.hblkchaptitle_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblkchaptitle_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblkchaptitle_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblkchaptitle_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblkchaptitle_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblkchaptitle_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblkchaptitle_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblkchaptitle_margins.join(' ')};
    padding: ${template_builder_advanced.hblkchaptitle_padding.join(' ')};
    background-color: ${template_builder_advanced.hblkchaptitle_backgroundColor};
    border-top: ${template_builder_advanced.hblkchaptitle_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblkchaptitle_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblkchaptitle_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblkchaptitle_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblkchaptitle_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblkchaptitle_width};
    height: ${template_builder_advanced.hblkchaptitle_height};
    string-set: ${template_builder_advanced.hblkchaptitle_stringSet};
  }

  [data-hederis-type=hblkchaptitle]::before {
    content: ${template_builder_advanced.hblkchaptitle_before_content};
  }

  [data-hederis-type=hblkchaptitle]::after {
    content: ${template_builder_advanced.hblkchaptitle_after_content};
  }
  [data-hederis-type=hblktitle] {
    font-family: ${template_builder_advanced.hblktitle_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblktitle_fontSize};
    font-weight: ${template_builder_advanced.hblktitle_fontWeight};
    font-style: ${template_builder_advanced.hblktitle_fontStyle};
    color: ${template_builder_advanced.hblktitle_textColor};
    text-indent: ${template_builder_advanced.hblktitle_textIndent};
    line-height: ${template_builder_advanced.hblktitle_lineHeight};
    text-align: ${template_builder_advanced.hblktitle_textAlign};
    text-align-last: ${template_builder_advanced.hblktitle_textAlignLast};
    font-variant: ${template_builder_advanced.hblktitle_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblktitle_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblktitle_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblktitle_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblktitle_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblktitle_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblktitle_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblktitle_margins.join(' ')};
    padding: ${template_builder_advanced.hblktitle_padding.join(' ')};
    background-color: ${template_builder_advanced.hblktitle_backgroundColor};
    border-top: ${template_builder_advanced.hblktitle_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblktitle_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblktitle_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblktitle_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblktitle_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblktitle_width};
    height: ${template_builder_advanced.hblktitle_height};
    string-set: ${template_builder_advanced.hblktitle_stringSet};
  }

  [data-hederis-type=hblktitle]::before {
    content: ${template_builder_advanced.hblktitle_before_content};
  }

  [data-hederis-type=hblktitle]::after {
    content: ${template_builder_advanced.hblktitle_after_content};
  }
  [data-hederis-type=hblknumber] {
    font-family: ${template_builder_advanced.hblknumber_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblknumber_fontSize};
    font-weight: ${template_builder_advanced.hblknumber_fontWeight};
    font-style: ${template_builder_advanced.hblknumber_fontStyle};
    color: ${template_builder_advanced.hblknumber_textColor};
    text-indent: ${template_builder_advanced.hblknumber_textIndent};
    line-height: ${template_builder_advanced.hblknumber_lineHeight};
    text-align: ${template_builder_advanced.hblknumber_textAlign};
    text-align-last: ${template_builder_advanced.hblknumber_textAlignLast};
    font-variant: ${template_builder_advanced.hblknumber_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblknumber_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblknumber_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblknumber_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblknumber_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblknumber_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblknumber_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblknumber_margins.join(' ')};
    padding: ${template_builder_advanced.hblknumber_padding.join(' ')};
    background-color: ${template_builder_advanced.hblknumber_backgroundColor};
    border-top: ${template_builder_advanced.hblknumber_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblknumber_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblknumber_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblknumber_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblknumber_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblknumber_width};
    height: ${template_builder_advanced.hblknumber_height};
    string-set: ${template_builder_advanced.hblknumber_stringSet};
  }

  [data-hederis-type=hblknumber]::before {
    content: ${template_builder_advanced.hblknumber_before_content};
  }

  [data-hederis-type=hblknumber]::after {
    content: ${template_builder_advanced.hblknumber_after_content};
  }
  [data-hederis-type=hblksubtitle] {
    font-family: ${template_builder_advanced.hblksubtitle_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblksubtitle_fontSize};
    font-weight: ${template_builder_advanced.hblksubtitle_fontWeight};
    font-style: ${template_builder_advanced.hblksubtitle_fontStyle};
    color: ${template_builder_advanced.hblksubtitle_textColor};
    text-indent: ${template_builder_advanced.hblksubtitle_textIndent};
    line-height: ${template_builder_advanced.hblksubtitle_lineHeight};
    text-align: ${template_builder_advanced.hblksubtitle_textAlign};
    text-align-last: ${template_builder_advanced.hblksubtitle_textAlignLast};
    font-variant: ${template_builder_advanced.hblksubtitle_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblksubtitle_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblksubtitle_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblksubtitle_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblksubtitle_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblksubtitle_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblksubtitle_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblksubtitle_margins.join(' ')};
    padding: ${template_builder_advanced.hblksubtitle_padding.join(' ')};
    background-color: ${template_builder_advanced.hblksubtitle_backgroundColor};
    border-top: ${template_builder_advanced.hblksubtitle_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblksubtitle_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblksubtitle_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblksubtitle_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblksubtitle_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblksubtitle_width};
    height: ${template_builder_advanced.hblksubtitle_height};
    string-set: ${template_builder_advanced.hblksubtitle_stringSet};
  }

  [data-hederis-type=hblksubtitle]::before {
    content: ${template_builder_advanced.hblksubtitle_before_content};
  }

  [data-hederis-type=hblksubtitle]::after {
    content: ${template_builder_advanced.hblksubtitle_after_content};
  }
  [data-hederis-type=hblkp] {
    font-family: ${template_builder_advanced.hblkp_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblkp_fontSize};
    font-weight: ${template_builder_advanced.hblkp_fontWeight};
    font-style: ${template_builder_advanced.hblkp_fontStyle};
    color: ${template_builder_advanced.hblkp_textColor};
    text-indent: ${template_builder_advanced.hblkp_textIndent};
    line-height: ${template_builder_advanced.hblkp_lineHeight};
    text-align: ${template_builder_advanced.hblkp_textAlign};
    text-align-last: ${template_builder_advanced.hblkp_textAlignLast};
    font-variant: ${template_builder_advanced.hblkp_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblkp_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblkp_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblkp_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblkp_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblkp_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblkp_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblkp_margins.join(' ')};
    padding: ${template_builder_advanced.hblkp_padding.join(' ')};
    background-color: ${template_builder_advanced.hblkp_backgroundColor};
    border-top: ${template_builder_advanced.hblkp_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblkp_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblkp_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblkp_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblkp_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblkp_width};
    height: ${template_builder_advanced.hblkp_height};
    string-set: ${template_builder_advanced.hblkp_stringSet};
  }

  [data-hederis-type=hblkp]::before {
    content: ${template_builder_advanced.hblkp_before_content};
  }

  [data-hederis-type=hblkp]::after {
    content: ${template_builder_advanced.hblkp_after_content};
  }
  [data-hederis-type=hblkp-cont] {
    font-family: ${template_builder_advanced.hblkp_cont_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblkp_cont_fontSize};
    font-weight: ${template_builder_advanced.hblkp_cont_fontWeight};
    font-style: ${template_builder_advanced.hblkp_cont_fontStyle};
    color: ${template_builder_advanced.hblkp_cont_textColor};
    text-indent: ${template_builder_advanced.hblkp_cont_textIndent};
    line-height: ${template_builder_advanced.hblkp_cont_lineHeight};
    text-align: ${template_builder_advanced.hblkp_cont_textAlign};
    text-align-last: ${template_builder_advanced.hblkp_cont_textAlignLast};
    font-variant: ${template_builder_advanced.hblkp_cont_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblkp_cont_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblkp_cont_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblkp_cont_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblkp_cont_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblkp_cont_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblkp_cont_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblkp_cont_margins.join(' ')};
    padding: ${template_builder_advanced.hblkp_cont_padding.join(' ')};
    background-color: ${template_builder_advanced.hblkp_cont_backgroundColor};
    border-top: ${template_builder_advanced.hblkp_cont_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblkp_cont_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblkp_cont_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblkp_cont_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblkp_cont_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblkp_cont_width};
    height: ${template_builder_advanced.hblkp_cont_height};
    string-set: ${template_builder_advanced.hblkp_cont_stringSet};
  }

  [data-hederis-type=hblkp-cont]::before {
    content: ${template_builder_advanced.hblkp_cont_before_content};
  }

  [data-hederis-type=hblkp-cont]::after {
    content: ${template_builder_advanced.hblkp_cont_after_content};
  }
  [data-hederis-type=hblkv] {
    font-family: ${template_builder_advanced.hblkv_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblkv_fontSize};
    font-weight: ${template_builder_advanced.hblkv_fontWeight};
    font-style: ${template_builder_advanced.hblkv_fontStyle};
    color: ${template_builder_advanced.hblkv_textColor};
    text-indent: ${template_builder_advanced.hblkv_textIndent};
    line-height: ${template_builder_advanced.hblkv_lineHeight};
    text-align: ${template_builder_advanced.hblkv_textAlign};
    text-align-last: ${template_builder_advanced.hblkv_textAlignLast};
    font-variant: ${template_builder_advanced.hblkv_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblkv_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblkv_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblkv_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblkv_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblkv_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblkv_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblkv_margins.join(' ')};
    padding: ${template_builder_advanced.hblkv_padding.join(' ')};
    background-color: ${template_builder_advanced.hblkv_backgroundColor};
    border-top: ${template_builder_advanced.hblkv_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblkv_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblkv_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblkv_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblkv_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblkv_width};
    height: ${template_builder_advanced.hblkv_height};
    string-set: ${template_builder_advanced.hblkv_stringSet};
  }

  [data-hederis-type=hblkv]::before {
    content: ${template_builder_advanced.hblkv_before_content};
  }

  [data-hederis-type=hblkv]::after {
    content: ${template_builder_advanced.hblkv_after_content};
  }
  [data-hederis-type=hblkau] {
    font-family: ${template_builder_advanced.hblkau_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblkau_fontSize};
    font-weight: ${template_builder_advanced.hblkau_fontWeight};
    font-style: ${template_builder_advanced.hblkau_fontStyle};
    color: ${template_builder_advanced.hblkau_textColor};
    text-indent: ${template_builder_advanced.hblkau_textIndent};
    line-height: ${template_builder_advanced.hblkau_lineHeight};
    text-align: ${template_builder_advanced.hblkau_textAlign};
    text-align-last: ${template_builder_advanced.hblkau_textAlignLast};
    font-variant: ${template_builder_advanced.hblkau_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblkau_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblkau_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblkau_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblkau_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblkau_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblkau_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblkau_margins.join(' ')};
    padding: ${template_builder_advanced.hblkau_padding.join(' ')};
    background-color: ${template_builder_advanced.hblkau_backgroundColor};
    border-top: ${template_builder_advanced.hblkau_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblkau_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblkau_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblkau_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblkau_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblkau_width};
    height: ${template_builder_advanced.hblkau_height};
    string-set: ${template_builder_advanced.hblkau_stringSet};
  }

  [data-hederis-type=hblkau]::before {
    content: ${template_builder_advanced.hblkau_before_content};
  }

  [data-hederis-type=hblkau]::after {
    content: ${template_builder_advanced.hblkau_after_content};
  }
  [data-hederis-type=hblkdateline] {
    font-family: ${template_builder_advanced.hblkdateline_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblkdateline_fontSize};
    font-weight: ${template_builder_advanced.hblkdateline_fontWeight};
    font-style: ${template_builder_advanced.hblkdateline_fontStyle};
    color: ${template_builder_advanced.hblkdateline_textColor};
    text-indent: ${template_builder_advanced.hblkdateline_textIndent};
    line-height: ${template_builder_advanced.hblkdateline_lineHeight};
    text-align: ${template_builder_advanced.hblkdateline_textAlign};
    text-align-last: ${template_builder_advanced.hblkdateline_textAlignLast};
    font-variant: ${template_builder_advanced.hblkdateline_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblkdateline_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblkdateline_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblkdateline_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblkdateline_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblkdateline_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblkdateline_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblkdateline_margins.join(' ')};
    padding: ${template_builder_advanced.hblkdateline_padding.join(' ')};
    background-color: ${template_builder_advanced.hblkdateline_backgroundColor};
    border-top: ${template_builder_advanced.hblkdateline_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblkdateline_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblkdateline_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblkdateline_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblkdateline_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblkdateline_width};
    height: ${template_builder_advanced.hblkdateline_height};
    string-set: ${template_builder_advanced.hblkdateline_stringSet};
  }

  [data-hederis-type=hblkdateline]::before {
    content: ${template_builder_advanced.hblkdateline_before_content};
  }

  [data-hederis-type=hblkdateline]::after {
    content: ${template_builder_advanced.hblkdateline_after_content};
  }
  [data-hederis-type=hblksalutation] {
    font-family: ${template_builder_advanced.hblksalutation_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblksalutation_fontSize};
    font-weight: ${template_builder_advanced.hblksalutation_fontWeight};
    font-style: ${template_builder_advanced.hblksalutation_fontStyle};
    color: ${template_builder_advanced.hblksalutation_textColor};
    text-indent: ${template_builder_advanced.hblksalutation_textIndent};
    line-height: ${template_builder_advanced.hblksalutation_lineHeight};
    text-align: ${template_builder_advanced.hblksalutation_textAlign};
    text-align-last: ${template_builder_advanced.hblksalutation_textAlignLast};
    font-variant: ${template_builder_advanced.hblksalutation_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblksalutation_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblksalutation_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblksalutation_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblksalutation_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblksalutation_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblksalutation_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblksalutation_margins.join(' ')};
    padding: ${template_builder_advanced.hblksalutation_padding.join(' ')};
    background-color: ${template_builder_advanced.hblksalutation_backgroundColor};
    border-top: ${template_builder_advanced.hblksalutation_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblksalutation_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblksalutation_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblksalutation_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblksalutation_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblksalutation_width};
    height: ${template_builder_advanced.hblksalutation_height};
    string-set: ${template_builder_advanced.hblksalutation_stringSet};
  }

  [data-hederis-type=hblksalutation]::before {
    content: ${template_builder_advanced.hblksalutation_before_content};
  }

  [data-hederis-type=hblksalutation]::after {
    content: ${template_builder_advanced.hblksalutation_after_content};
  }
  [data-hederis-type=hblklocation] {
    font-family: ${template_builder_advanced.hblklocation_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblklocation_fontSize};
    font-weight: ${template_builder_advanced.hblklocation_fontWeight};
    font-style: ${template_builder_advanced.hblklocation_fontStyle};
    color: ${template_builder_advanced.hblklocation_textColor};
    text-indent: ${template_builder_advanced.hblklocation_textIndent};
    line-height: ${template_builder_advanced.hblklocation_lineHeight};
    text-align: ${template_builder_advanced.hblklocation_textAlign};
    text-align-last: ${template_builder_advanced.hblklocation_textAlignLast};
    font-variant: ${template_builder_advanced.hblklocation_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblklocation_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblklocation_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblklocation_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblklocation_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblklocation_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblklocation_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblklocation_margins.join(' ')};
    padding: ${template_builder_advanced.hblklocation_padding.join(' ')};
    background-color: ${template_builder_advanced.hblklocation_backgroundColor};
    border-top: ${template_builder_advanced.hblklocation_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblklocation_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblklocation_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblklocation_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblklocation_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblklocation_width};
    height: ${template_builder_advanced.hblklocation_height};
    string-set: ${template_builder_advanced.hblklocation_stringSet};
  }

  [data-hederis-type=hblklocation]::before {
    content: ${template_builder_advanced.hblklocation_before_content};
  }

  [data-hederis-type=hblklocation]::after {
    content: ${template_builder_advanced.hblklocation_after_content};
  }
  [data-hederis-type=hblksig] {
    font-family: ${template_builder_advanced.hblksig_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblksig_fontSize};
    font-weight: ${template_builder_advanced.hblksig_fontWeight};
    font-style: ${template_builder_advanced.hblksig_fontStyle};
    color: ${template_builder_advanced.hblksig_textColor};
    text-indent: ${template_builder_advanced.hblksig_textIndent};
    line-height: ${template_builder_advanced.hblksig_lineHeight};
    text-align: ${template_builder_advanced.hblksig_textAlign};
    text-align-last: ${template_builder_advanced.hblksig_textAlignLast};
    font-variant: ${template_builder_advanced.hblksig_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblksig_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblksig_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblksig_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblksig_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblksig_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblksig_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblksig_margins.join(' ')};
    padding: ${template_builder_advanced.hblksig_padding.join(' ')};
    background-color: ${template_builder_advanced.hblksig_backgroundColor};
    border-top: ${template_builder_advanced.hblksig_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblksig_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblksig_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblksig_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblksig_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblksig_width};
    height: ${template_builder_advanced.hblksig_height};
    string-set: ${template_builder_advanced.hblksig_stringSet};
  }

  [data-hederis-type=hblksig]::before {
    content: ${template_builder_advanced.hblksig_before_content};
  }

  [data-hederis-type=hblksig]::after {
    content: ${template_builder_advanced.hblksig_after_content};
  }
  [data-hederis-type=hblkcode] {
    font-family: ${template_builder_advanced.hblkcode_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblkcode_fontSize};
    font-weight: ${template_builder_advanced.hblkcode_fontWeight};
    font-style: ${template_builder_advanced.hblkcode_fontStyle};
    color: ${template_builder_advanced.hblkcode_textColor};
    text-indent: ${template_builder_advanced.hblkcode_textIndent};
    line-height: ${template_builder_advanced.hblkcode_lineHeight};
    text-align: ${template_builder_advanced.hblkcode_textAlign};
    text-align-last: ${template_builder_advanced.hblkcode_textAlignLast};
    font-variant: ${template_builder_advanced.hblkcode_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblkcode_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblkcode_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblkcode_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblkcode_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblkcode_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblkcode_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblkcode_margins.join(' ')};
    padding: ${template_builder_advanced.hblkcode_padding.join(' ')};
    background-color: ${template_builder_advanced.hblkcode_backgroundColor};
    border-top: ${template_builder_advanced.hblkcode_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblkcode_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblkcode_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblkcode_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblkcode_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblkcode_width};
    height: ${template_builder_advanced.hblkcode_height};
    string-set: ${template_builder_advanced.hblkcode_stringSet};
  }

  [data-hederis-type=hblkcode]::before {
    content: ${template_builder_advanced.hblkcode_before_content};
  }

  [data-hederis-type=hblkcode]::after {
    content: ${template_builder_advanced.hblkcode_after_content};
  }
  [data-hederis-type=hblkendnote] {
    font-family: ${template_builder_advanced.hblkendnote_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblkendnote_fontSize};
    font-weight: ${template_builder_advanced.hblkendnote_fontWeight};
    font-style: ${template_builder_advanced.hblkendnote_fontStyle};
    color: ${template_builder_advanced.hblkendnote_textColor};
    text-indent: ${template_builder_advanced.hblkendnote_textIndent};
    line-height: ${template_builder_advanced.hblkendnote_lineHeight};
    text-align: ${template_builder_advanced.hblkendnote_textAlign};
    text-align-last: ${template_builder_advanced.hblkendnote_textAlignLast};
    font-variant: ${template_builder_advanced.hblkendnote_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblkendnote_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblkendnote_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblkendnote_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblkendnote_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblkendnote_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblkendnote_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblkendnote_margins.join(' ')};
    padding: ${template_builder_advanced.hblkendnote_padding.join(' ')};
    background-color: ${template_builder_advanced.hblkendnote_backgroundColor};
    border-top: ${template_builder_advanced.hblkendnote_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblkendnote_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblkendnote_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblkendnote_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblkendnote_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblkendnote_width};
    height: ${template_builder_advanced.hblkendnote_height};
    string-set: ${template_builder_advanced.hblkendnote_stringSet};
  }

  [data-hederis-type=hblkendnote]::before {
    content: ${template_builder_advanced.hblkendnote_before_content};
  }

  [data-hederis-type=hblkendnote]::after {
    content: ${template_builder_advanced.hblkendnote_after_content};
  }
  [data-hederis-type=hblkfootnote] {
    font-family: ${template_builder_advanced.hblkfootnote_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblkfootnote_fontSize};
    font-weight: ${template_builder_advanced.hblkfootnote_fontWeight};
    font-style: ${template_builder_advanced.hblkfootnote_fontStyle};
    color: ${template_builder_advanced.hblkfootnote_textColor};
    text-indent: ${template_builder_advanced.hblkfootnote_textIndent};
    line-height: ${template_builder_advanced.hblkfootnote_lineHeight};
    text-align: ${template_builder_advanced.hblkfootnote_textAlign};
    text-align-last: ${template_builder_advanced.hblkfootnote_textAlignLast};
    font-variant: ${template_builder_advanced.hblkfootnote_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblkfootnote_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblkfootnote_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblkfootnote_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblkfootnote_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblkfootnote_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblkfootnote_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblkfootnote_margins.join(' ')};
    padding: ${template_builder_advanced.hblkfootnote_padding.join(' ')};
    background-color: ${template_builder_advanced.hblkfootnote_backgroundColor};
    border-top: ${template_builder_advanced.hblkfootnote_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblkfootnote_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblkfootnote_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblkfootnote_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblkfootnote_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblkfootnote_width};
    height: ${template_builder_advanced.hblkfootnote_height};
    string-set: ${template_builder_advanced.hblkfootnote_stringSet};
  }

  [data-hederis-type=hblkfootnote]::before {
    content: ${template_builder_advanced.hblkfootnote_before_content};
  }

  [data-hederis-type=hblkfootnote]::after {
    content: ${template_builder_advanced.hblkfootnote_after_content};
  }
  [data-hederis-type=hblkoli] {
    font-family: ${template_builder_advanced.hblkoli_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblkoli_fontSize};
    font-weight: ${template_builder_advanced.hblkoli_fontWeight};
    font-style: ${template_builder_advanced.hblkoli_fontStyle};
    color: ${template_builder_advanced.hblkoli_textColor};
    text-indent: ${template_builder_advanced.hblkoli_textIndent};
    line-height: ${template_builder_advanced.hblkoli_lineHeight};
    text-align: ${template_builder_advanced.hblkoli_textAlign};
    text-align-last: ${template_builder_advanced.hblkoli_textAlignLast};
    font-variant: ${template_builder_advanced.hblkoli_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblkoli_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblkoli_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblkoli_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblkoli_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblkoli_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblkoli_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblkoli_margins.join(' ')};
    padding: ${template_builder_advanced.hblkoli_padding.join(' ')};
    background-color: ${template_builder_advanced.hblkoli_backgroundColor};
    border-top: ${template_builder_advanced.hblkoli_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblkoli_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblkoli_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblkoli_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblkoli_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblkoli_width};
    height: ${template_builder_advanced.hblkoli_height};
    string-set: ${template_builder_advanced.hblkoli_stringSet};
  }

  [data-hederis-type=hblkoli]::before {
    content: ${template_builder_advanced.hblkoli_before_content};
  }

  [data-hederis-type=hblkoli]::after {
    content: ${template_builder_advanced.hblkoli_after_content};
  }
  [data-hederis-type=hblkuli] {
    font-family: ${template_builder_advanced.hblkuli_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblkuli_fontSize};
    font-weight: ${template_builder_advanced.hblkuli_fontWeight};
    font-style: ${template_builder_advanced.hblkuli_fontStyle};
    color: ${template_builder_advanced.hblkuli_textColor};
    text-indent: ${template_builder_advanced.hblkuli_textIndent};
    line-height: ${template_builder_advanced.hblkuli_lineHeight};
    text-align: ${template_builder_advanced.hblkuli_textAlign};
    text-align-last: ${template_builder_advanced.hblkuli_textAlignLast};
    font-variant: ${template_builder_advanced.hblkuli_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblkuli_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblkuli_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblkuli_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblkuli_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblkuli_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblkuli_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblkuli_margins.join(' ')};
    padding: ${template_builder_advanced.hblkuli_padding.join(' ')};
    background-color: ${template_builder_advanced.hblkuli_backgroundColor};
    border-top: ${template_builder_advanced.hblkuli_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblkuli_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblkuli_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblkuli_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblkuli_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblkuli_width};
    height: ${template_builder_advanced.hblkuli_height};
    string-set: ${template_builder_advanced.hblkuli_stringSet};
  }

  [data-hederis-type=hblkuli]::before {
    content: ${template_builder_advanced.hblkuli_before_content};
  }

  [data-hederis-type=hblkuli]::after {
    content: ${template_builder_advanced.hblkuli_after_content};
  }
  [data-hederis-type=hblkli-cont] {
    font-family: ${template_builder_advanced.hblkli_cont_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblkli_cont_fontSize};
    font-weight: ${template_builder_advanced.hblkli_cont_fontWeight};
    font-style: ${template_builder_advanced.hblkli_cont_fontStyle};
    color: ${template_builder_advanced.hblkli_cont_textColor};
    text-indent: ${template_builder_advanced.hblkli_cont_textIndent};
    line-height: ${template_builder_advanced.hblkli_cont_lineHeight};
    text-align: ${template_builder_advanced.hblkli_cont_textAlign};
    text-align-last: ${template_builder_advanced.hblkli_cont_textAlignLast};
    font-variant: ${template_builder_advanced.hblkli_cont_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblkli_cont_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblkli_cont_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblkli_cont_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblkli_cont_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblkli_cont_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblkli_cont_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblkli_cont_margins.join(' ')};
    padding: ${template_builder_advanced.hblkli_cont_padding.join(' ')};
    background-color: ${template_builder_advanced.hblkli_cont_backgroundColor};
    border-top: ${template_builder_advanced.hblkli_cont_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblkli_cont_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblkli_cont_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblkli_cont_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblkli_cont_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblkli_cont_width};
    height: ${template_builder_advanced.hblkli_cont_height};
    string-set: ${template_builder_advanced.hblkli_cont_stringSet};
  }

  [data-hederis-type=hblkli-cont]::before {
    content: ${template_builder_advanced.hblkli_cont_before_content};
  }

  [data-hederis-type=hblkli-cont]::after {
    content: ${template_builder_advanced.hblkli_cont_after_content};
  }
  [data-hederis-type=hblkimg] {
    font-family: ${template_builder_advanced.hblkimg_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblkimg_fontSize};
    font-weight: ${template_builder_advanced.hblkimg_fontWeight};
    font-style: ${template_builder_advanced.hblkimg_fontStyle};
    color: ${template_builder_advanced.hblkimg_textColor};
    text-indent: ${template_builder_advanced.hblkimg_textIndent};
    line-height: ${template_builder_advanced.hblkimg_lineHeight};
    text-align: ${template_builder_advanced.hblkimg_textAlign};
    text-align-last: ${template_builder_advanced.hblkimg_textAlignLast};
    font-variant: ${template_builder_advanced.hblkimg_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblkimg_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblkimg_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblkimg_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblkimg_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblkimg_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblkimg_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblkimg_margins.join(' ')};
    padding: ${template_builder_advanced.hblkimg_padding.join(' ')};
    background-color: ${template_builder_advanced.hblkimg_backgroundColor};
    border-top: ${template_builder_advanced.hblkimg_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblkimg_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblkimg_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblkimg_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblkimg_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblkimg_width};
    height: ${template_builder_advanced.hblkimg_height};
    string-set: ${template_builder_advanced.hblkimg_stringSet};
  }

  [data-hederis-type=hblkimg]::before {
    content: ${template_builder_advanced.hblkimg_before_content};
  }

  [data-hederis-type=hblkimg]::after {
    content: ${template_builder_advanced.hblkimg_after_content};
  }
  [data-hederis-type=hblksource] {
    font-family: ${template_builder_advanced.hblksource_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblksource_fontSize};
    font-weight: ${template_builder_advanced.hblksource_fontWeight};
    font-style: ${template_builder_advanced.hblksource_fontStyle};
    color: ${template_builder_advanced.hblksource_textColor};
    text-indent: ${template_builder_advanced.hblksource_textIndent};
    line-height: ${template_builder_advanced.hblksource_lineHeight};
    text-align: ${template_builder_advanced.hblksource_textAlign};
    text-align-last: ${template_builder_advanced.hblksource_textAlignLast};
    font-variant: ${template_builder_advanced.hblksource_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblksource_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblksource_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblksource_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblksource_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblksource_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblksource_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblksource_margins.join(' ')};
    padding: ${template_builder_advanced.hblksource_padding.join(' ')};
    background-color: ${template_builder_advanced.hblksource_backgroundColor};
    border-top: ${template_builder_advanced.hblksource_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblksource_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblksource_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblksource_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblksource_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblksource_width};
    height: ${template_builder_advanced.hblksource_height};
    string-set: ${template_builder_advanced.hblksource_stringSet};
  }

  [data-hederis-type=hblksource]::before {
    content: ${template_builder_advanced.hblksource_before_content};
  }

  [data-hederis-type=hblksource]::after {
    content: ${template_builder_advanced.hblksource_after_content};
  }
  [data-hederis-type=hblkcaption] {
    font-family: ${template_builder_advanced.hblkcaption_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblkcaption_fontSize};
    font-weight: ${template_builder_advanced.hblkcaption_fontWeight};
    font-style: ${template_builder_advanced.hblkcaption_fontStyle};
    color: ${template_builder_advanced.hblkcaption_textColor};
    text-indent: ${template_builder_advanced.hblkcaption_textIndent};
    line-height: ${template_builder_advanced.hblkcaption_lineHeight};
    text-align: ${template_builder_advanced.hblkcaption_textAlign};
    text-align-last: ${template_builder_advanced.hblkcaption_textAlignLast};
    font-variant: ${template_builder_advanced.hblkcaption_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblkcaption_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblkcaption_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblkcaption_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblkcaption_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblkcaption_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblkcaption_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblkcaption_margins.join(' ')};
    padding: ${template_builder_advanced.hblkcaption_padding.join(' ')};
    background-color: ${template_builder_advanced.hblkcaption_backgroundColor};
    border-top: ${template_builder_advanced.hblkcaption_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblkcaption_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblkcaption_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblkcaption_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblkcaption_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblkcaption_width};
    height: ${template_builder_advanced.hblkcaption_height};
    string-set: ${template_builder_advanced.hblkcaption_stringSet};
  }

  [data-hederis-type=hblkcaption]::before {
    content: ${template_builder_advanced.hblkcaption_before_content};
  }

  [data-hederis-type=hblkcaption]::after {
    content: ${template_builder_advanced.hblkcaption_after_content};
  }
  [data-hederis-type=hblkdialogue] {
    font-family: ${template_builder_advanced.hblkdialogue_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblkdialogue_fontSize};
    font-weight: ${template_builder_advanced.hblkdialogue_fontWeight};
    font-style: ${template_builder_advanced.hblkdialogue_fontStyle};
    color: ${template_builder_advanced.hblkdialogue_textColor};
    text-indent: ${template_builder_advanced.hblkdialogue_textIndent};
    line-height: ${template_builder_advanced.hblkdialogue_lineHeight};
    text-align: ${template_builder_advanced.hblkdialogue_textAlign};
    text-align-last: ${template_builder_advanced.hblkdialogue_textAlignLast};
    font-variant: ${template_builder_advanced.hblkdialogue_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblkdialogue_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblkdialogue_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblkdialogue_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblkdialogue_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblkdialogue_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblkdialogue_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblkdialogue_margins.join(' ')};
    padding: ${template_builder_advanced.hblkdialogue_padding.join(' ')};
    background-color: ${template_builder_advanced.hblkdialogue_backgroundColor};
    border-top: ${template_builder_advanced.hblkdialogue_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblkdialogue_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblkdialogue_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblkdialogue_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblkdialogue_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblkdialogue_width};
    height: ${template_builder_advanced.hblkdialogue_height};
    string-set: ${template_builder_advanced.hblkdialogue_stringSet};
  }

  [data-hederis-type=hblkdialogue]::before {
    content: ${template_builder_advanced.hblkdialogue_before_content};
  }

  [data-hederis-type=hblkdialogue]::after {
    content: ${template_builder_advanced.hblkdialogue_after_content};
  }
  [data-hederis-type=hblkspeaker] {
    font-family: ${template_builder_advanced.hblkspeaker_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblkspeaker_fontSize};
    font-weight: ${template_builder_advanced.hblkspeaker_fontWeight};
    font-style: ${template_builder_advanced.hblkspeaker_fontStyle};
    color: ${template_builder_advanced.hblkspeaker_textColor};
    text-indent: ${template_builder_advanced.hblkspeaker_textIndent};
    line-height: ${template_builder_advanced.hblkspeaker_lineHeight};
    text-align: ${template_builder_advanced.hblkspeaker_textAlign};
    text-align-last: ${template_builder_advanced.hblkspeaker_textAlignLast};
    font-variant: ${template_builder_advanced.hblkspeaker_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblkspeaker_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblkspeaker_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblkspeaker_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblkspeaker_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblkspeaker_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblkspeaker_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblkspeaker_margins.join(' ')};
    padding: ${template_builder_advanced.hblkspeaker_padding.join(' ')};
    background-color: ${template_builder_advanced.hblkspeaker_backgroundColor};
    border-top: ${template_builder_advanced.hblkspeaker_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblkspeaker_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblkspeaker_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblkspeaker_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblkspeaker_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblkspeaker_width};
    height: ${template_builder_advanced.hblkspeaker_height};
    string-set: ${template_builder_advanced.hblkspeaker_stringSet};
  }

  [data-hederis-type=hblkspeaker]::before {
    content: ${template_builder_advanced.hblkspeaker_before_content};
  }

  [data-hederis-type=hblkspeaker]::after {
    content: ${template_builder_advanced.hblkspeaker_after_content};
  }
  [data-hederis-type=hblkquote] {
    font-family: ${template_builder_advanced.hblkquote_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblkquote_fontSize};
    font-weight: ${template_builder_advanced.hblkquote_fontWeight};
    font-style: ${template_builder_advanced.hblkquote_fontStyle};
    color: ${template_builder_advanced.hblkquote_textColor};
    text-indent: ${template_builder_advanced.hblkquote_textIndent};
    line-height: ${template_builder_advanced.hblkquote_lineHeight};
    text-align: ${template_builder_advanced.hblkquote_textAlign};
    text-align-last: ${template_builder_advanced.hblkquote_textAlignLast};
    font-variant: ${template_builder_advanced.hblkquote_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblkquote_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblkquote_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblkquote_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblkquote_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblkquote_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblkquote_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblkquote_margins.join(' ')};
    padding: ${template_builder_advanced.hblkquote_padding.join(' ')};
    background-color: ${template_builder_advanced.hblkquote_backgroundColor};
    border-top: ${template_builder_advanced.hblkquote_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblkquote_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblkquote_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblkquote_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblkquote_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblkquote_width};
    height: ${template_builder_advanced.hblkquote_height};
    string-set: ${template_builder_advanced.hblkquote_stringSet};
  }

  [data-hederis-type=hblkquote]::before {
    content: ${template_builder_advanced.hblkquote_before_content};
  }

  [data-hederis-type=hblkquote]::after {
    content: ${template_builder_advanced.hblkquote_after_content};
  }
  [data-hederis-type=hblkline-space] {
    font-family: ${template_builder_advanced.hblkline_space_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblkline_space_fontSize};
    font-weight: ${template_builder_advanced.hblkline_space_fontWeight};
    font-style: ${template_builder_advanced.hblkline_space_fontStyle};
    color: ${template_builder_advanced.hblkline_space_textColor};
    text-indent: ${template_builder_advanced.hblkline_space_textIndent};
    line-height: ${template_builder_advanced.hblkline_space_lineHeight};
    text-align: ${template_builder_advanced.hblkline_space_textAlign};
    text-align-last: ${template_builder_advanced.hblkline_space_textAlignLast};
    font-variant: ${template_builder_advanced.hblkline_space_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblkline_space_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblkline_space_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblkline_space_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblkline_space_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblkline_space_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblkline_space_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblkline_space_margins.join(' ')};
    padding: ${template_builder_advanced.hblkline_space_padding.join(' ')};
    background-color: ${template_builder_advanced.hblkline_space_backgroundColor};
    border-top: ${template_builder_advanced.hblkline_space_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblkline_space_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblkline_space_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblkline_space_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblkline_space_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblkline_space_width};
    height: ${template_builder_advanced.hblkline_space_height};
    string-set: ${template_builder_advanced.hblkline_space_stringSet};
  }

  [data-hederis-type=hblkline-space]::before {
    content: ${template_builder_advanced.hblkline_space_before_content};
  }

  [data-hederis-type=hblkline-space]::after {
    content: ${template_builder_advanced.hblkline_space_after_content};
  }
  [data-hederis-type=hblkorn-space] {
    font-family: ${template_builder_advanced.hblkorn_space_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblkorn_space_fontSize};
    font-weight: ${template_builder_advanced.hblkorn_space_fontWeight};
    font-style: ${template_builder_advanced.hblkorn_space_fontStyle};
    color: ${template_builder_advanced.hblkorn_space_textColor};
    text-indent: ${template_builder_advanced.hblkorn_space_textIndent};
    line-height: ${template_builder_advanced.hblkorn_space_lineHeight};
    text-align: ${template_builder_advanced.hblkorn_space_textAlign};
    text-align-last: ${template_builder_advanced.hblkorn_space_textAlignLast};
    font-variant: ${template_builder_advanced.hblkorn_space_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblkorn_space_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblkorn_space_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblkorn_space_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblkorn_space_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblkorn_space_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblkorn_space_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblkorn_space_margins.join(' ')};
    padding: ${template_builder_advanced.hblkorn_space_padding.join(' ')};
    background-color: ${template_builder_advanced.hblkorn_space_backgroundColor};
    border-top: ${template_builder_advanced.hblkorn_space_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblkorn_space_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblkorn_space_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblkorn_space_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblkorn_space_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblkorn_space_width};
    height: ${template_builder_advanced.hblkorn_space_height};
    string-set: ${template_builder_advanced.hblkorn_space_stringSet};
  }

  [data-hederis-type=hblkorn-space]::before {
    content: ${template_builder_advanced.hblkorn_space_before_content};
  }

  [data-hederis-type=hblkorn-space]::after {
    content: ${template_builder_advanced.hblkorn_space_after_content};
  }
  [data-hederis-type=hblkseparator] {
    font-family: ${template_builder_advanced.hblkseparator_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblkseparator_fontSize};
    font-weight: ${template_builder_advanced.hblkseparator_fontWeight};
    font-style: ${template_builder_advanced.hblkseparator_fontStyle};
    color: ${template_builder_advanced.hblkseparator_textColor};
    text-indent: ${template_builder_advanced.hblkseparator_textIndent};
    line-height: ${template_builder_advanced.hblkseparator_lineHeight};
    text-align: ${template_builder_advanced.hblkseparator_textAlign};
    text-align-last: ${template_builder_advanced.hblkseparator_textAlignLast};
    font-variant: ${template_builder_advanced.hblkseparator_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblkseparator_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblkseparator_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblkseparator_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblkseparator_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblkseparator_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblkseparator_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblkseparator_margins.join(' ')};
    padding: ${template_builder_advanced.hblkseparator_padding.join(' ')};
    background-color: ${template_builder_advanced.hblkseparator_backgroundColor};
    border-top: ${template_builder_advanced.hblkseparator_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblkseparator_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblkseparator_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblkseparator_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblkseparator_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblkseparator_width};
    height: ${template_builder_advanced.hblkseparator_height};
    string-set: ${template_builder_advanced.hblkseparator_stringSet};
  }

  [data-hederis-type=hblkseparator]::before {
    content: ${template_builder_advanced.hblkseparator_before_content};
  }

  [data-hederis-type=hblkseparator]::after {
    content: ${template_builder_advanced.hblkseparator_after_content};
  }
  [data-hederis-type=hblkcontributor] {
    font-family: ${template_builder_advanced.hblkcontributor_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblkcontributor_fontSize};
    font-weight: ${template_builder_advanced.hblkcontributor_fontWeight};
    font-style: ${template_builder_advanced.hblkcontributor_fontStyle};
    color: ${template_builder_advanced.hblkcontributor_textColor};
    text-indent: ${template_builder_advanced.hblkcontributor_textIndent};
    line-height: ${template_builder_advanced.hblkcontributor_lineHeight};
    text-align: ${template_builder_advanced.hblkcontributor_textAlign};
    text-align-last: ${template_builder_advanced.hblkcontributor_textAlignLast};
    font-variant: ${template_builder_advanced.hblkcontributor_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblkcontributor_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblkcontributor_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblkcontributor_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblkcontributor_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblkcontributor_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblkcontributor_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblkcontributor_margins.join(' ')};
    padding: ${template_builder_advanced.hblkcontributor_padding.join(' ')};
    background-color: ${template_builder_advanced.hblkcontributor_backgroundColor};
    border-top: ${template_builder_advanced.hblkcontributor_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblkcontributor_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblkcontributor_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblkcontributor_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblkcontributor_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblkcontributor_width};
    height: ${template_builder_advanced.hblkcontributor_height};
    string-set: ${template_builder_advanced.hblkcontributor_stringSet};
  }

  [data-hederis-type=hblkcontributor]::before {
    content: ${template_builder_advanced.hblkcontributor_before_content};
  }

  [data-hederis-type=hblkcontributor]::after {
    content: ${template_builder_advanced.hblkcontributor_after_content};
  }
  [data-hederis-type=hblktitlepage-imprint] {
    font-family: ${template_builder_advanced.hblktitlepage_imprint_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblktitlepage_imprint_fontSize};
    font-weight: ${template_builder_advanced.hblktitlepage_imprint_fontWeight};
    font-style: ${template_builder_advanced.hblktitlepage_imprint_fontStyle};
    color: ${template_builder_advanced.hblktitlepage_imprint_textColor};
    text-indent: ${template_builder_advanced.hblktitlepage_imprint_textIndent};
    line-height: ${template_builder_advanced.hblktitlepage_imprint_lineHeight};
    text-align: ${template_builder_advanced.hblktitlepage_imprint_textAlign};
    text-align-last: ${template_builder_advanced.hblktitlepage_imprint_textAlignLast};
    font-variant: ${template_builder_advanced.hblktitlepage_imprint_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblktitlepage_imprint_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblktitlepage_imprint_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblktitlepage_imprint_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblktitlepage_imprint_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblktitlepage_imprint_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblktitlepage_imprint_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblktitlepage_imprint_margins.join(' ')};
    padding: ${template_builder_advanced.hblktitlepage_imprint_padding.join(' ')};
    background-color: ${template_builder_advanced.hblktitlepage_imprint_backgroundColor};
    border-top: ${template_builder_advanced.hblktitlepage_imprint_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblktitlepage_imprint_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblktitlepage_imprint_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblktitlepage_imprint_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblktitlepage_imprint_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblktitlepage_imprint_width};
    height: ${template_builder_advanced.hblktitlepage_imprint_height};
    string-set: ${template_builder_advanced.hblktitlepage_imprint_stringSet};
  }

  [data-hederis-type=hblktitlepage-imprint]::before {
    content: ${template_builder_advanced.hblktitlepage_imprint_before_content};
  }

  [data-hederis-type=hblktitlepage-imprint]::after {
    content: ${template_builder_advanced.hblktitlepage_imprint_after_content};
  }
  [data-hederis-type=hblktitlepage-publisher] {
    font-family: ${template_builder_advanced.hblktitlepage_publisher_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblktitlepage_publisher_fontSize};
    font-weight: ${template_builder_advanced.hblktitlepage_publisher_fontWeight};
    font-style: ${template_builder_advanced.hblktitlepage_publisher_fontStyle};
    color: ${template_builder_advanced.hblktitlepage_publisher_textColor};
    text-indent: ${template_builder_advanced.hblktitlepage_publisher_textIndent};
    line-height: ${template_builder_advanced.hblktitlepage_publisher_lineHeight};
    text-align: ${template_builder_advanced.hblktitlepage_publisher_textAlign};
    text-align-last: ${template_builder_advanced.hblktitlepage_publisher_textAlignLast};
    font-variant: ${template_builder_advanced.hblktitlepage_publisher_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblktitlepage_publisher_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblktitlepage_publisher_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblktitlepage_publisher_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblktitlepage_publisher_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblktitlepage_publisher_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblktitlepage_publisher_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblktitlepage_publisher_margins.join(' ')};
    padding: ${template_builder_advanced.hblktitlepage_publisher_padding.join(' ')};
    background-color: ${template_builder_advanced.hblktitlepage_publisher_backgroundColor};
    border-top: ${template_builder_advanced.hblktitlepage_publisher_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblktitlepage_publisher_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblktitlepage_publisher_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblktitlepage_publisher_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblktitlepage_publisher_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblktitlepage_publisher_width};
    height: ${template_builder_advanced.hblktitlepage_publisher_height};
    string-set: ${template_builder_advanced.hblktitlepage_publisher_stringSet};
  }

  [data-hederis-type=hblktitlepage-publisher]::before {
    content: ${template_builder_advanced.hblktitlepage_publisher_before_content};
  }

  [data-hederis-type=hblktitlepage-publisher]::after {
    content: ${template_builder_advanced.hblktitlepage_publisher_after_content};
  }
  [data-hederis-type=hblkattribution] {
    font-family: ${template_builder_advanced.hblkattribution_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblkattribution_fontSize};
    font-weight: ${template_builder_advanced.hblkattribution_fontWeight};
    font-style: ${template_builder_advanced.hblkattribution_fontStyle};
    color: ${template_builder_advanced.hblkattribution_textColor};
    text-indent: ${template_builder_advanced.hblkattribution_textIndent};
    line-height: ${template_builder_advanced.hblkattribution_lineHeight};
    text-align: ${template_builder_advanced.hblkattribution_textAlign};
    text-align-last: ${template_builder_advanced.hblkattribution_textAlignLast};
    font-variant: ${template_builder_advanced.hblkattribution_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblkattribution_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblkattribution_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblkattribution_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblkattribution_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblkattribution_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblkattribution_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblkattribution_margins.join(' ')};
    padding: ${template_builder_advanced.hblkattribution_padding.join(' ')};
    background-color: ${template_builder_advanced.hblkattribution_backgroundColor};
    border-top: ${template_builder_advanced.hblkattribution_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblkattribution_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblkattribution_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblkattribution_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblkattribution_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblkattribution_width};
    height: ${template_builder_advanced.hblkattribution_height};
    string-set: ${template_builder_advanced.hblkattribution_stringSet};
  }

  [data-hederis-type=hblkattribution]::before {
    content: ${template_builder_advanced.hblkattribution_before_content};
  }

  [data-hederis-type=hblkattribution]::after {
    content: ${template_builder_advanced.hblkattribution_after_content};
  }
  [data-hederis-type=hblkattribution-cont] {
    font-family: ${template_builder_advanced.hblkattribution_cont_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblkattribution_cont_fontSize};
    font-weight: ${template_builder_advanced.hblkattribution_cont_fontWeight};
    font-style: ${template_builder_advanced.hblkattribution_cont_fontStyle};
    color: ${template_builder_advanced.hblkattribution_cont_textColor};
    text-indent: ${template_builder_advanced.hblkattribution_cont_textIndent};
    line-height: ${template_builder_advanced.hblkattribution_cont_lineHeight};
    text-align: ${template_builder_advanced.hblkattribution_cont_textAlign};
    text-align-last: ${template_builder_advanced.hblkattribution_cont_textAlignLast};
    font-variant: ${template_builder_advanced.hblkattribution_cont_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblkattribution_cont_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblkattribution_cont_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblkattribution_cont_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblkattribution_cont_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblkattribution_cont_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblkattribution_cont_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblkattribution_cont_margins.join(' ')};
    padding: ${template_builder_advanced.hblkattribution_cont_padding.join(' ')};
    background-color: ${template_builder_advanced.hblkattribution_cont_backgroundColor};
    border-top: ${template_builder_advanced.hblkattribution_cont_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblkattribution_cont_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblkattribution_cont_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblkattribution_cont_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblkattribution_cont_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblkattribution_cont_width};
    height: ${template_builder_advanced.hblkattribution_cont_height};
    string-set: ${template_builder_advanced.hblkattribution_cont_stringSet};
  }

  [data-hederis-type=hblkattribution-cont]::before {
    content: ${template_builder_advanced.hblkattribution_cont_before_content};
  }

  [data-hederis-type=hblkattribution-cont]::after {
    content: ${template_builder_advanced.hblkattribution_cont_after_content};
  }
  [data-hederis-type=hblkaddress] {
    font-family: ${template_builder_advanced.hblkaddress_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblkaddress_fontSize};
    font-weight: ${template_builder_advanced.hblkaddress_fontWeight};
    font-style: ${template_builder_advanced.hblkaddress_fontStyle};
    color: ${template_builder_advanced.hblkaddress_textColor};
    text-indent: ${template_builder_advanced.hblkaddress_textIndent};
    line-height: ${template_builder_advanced.hblkaddress_lineHeight};
    text-align: ${template_builder_advanced.hblkaddress_textAlign};
    text-align-last: ${template_builder_advanced.hblkaddress_textAlignLast};
    font-variant: ${template_builder_advanced.hblkaddress_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblkaddress_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblkaddress_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblkaddress_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblkaddress_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblkaddress_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblkaddress_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblkaddress_margins.join(' ')};
    padding: ${template_builder_advanced.hblkaddress_padding.join(' ')};
    background-color: ${template_builder_advanced.hblkaddress_backgroundColor};
    border-top: ${template_builder_advanced.hblkaddress_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblkaddress_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblkaddress_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblkaddress_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblkaddress_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblkaddress_width};
    height: ${template_builder_advanced.hblkaddress_height};
    string-set: ${template_builder_advanced.hblkaddress_stringSet};
  }

  [data-hederis-type=hblkaddress]::before {
    content: ${template_builder_advanced.hblkaddress_before_content};
  }

  [data-hederis-type=hblkaddress]::after {
    content: ${template_builder_advanced.hblkaddress_after_content};
  }
  [data-hederis-type=hblksalutation] {
    font-family: ${template_builder_advanced.hblksalutation_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblksalutation_fontSize};
    font-weight: ${template_builder_advanced.hblksalutation_fontWeight};
    font-style: ${template_builder_advanced.hblksalutation_fontStyle};
    color: ${template_builder_advanced.hblksalutation_textColor};
    text-indent: ${template_builder_advanced.hblksalutation_textIndent};
    line-height: ${template_builder_advanced.hblksalutation_lineHeight};
    text-align: ${template_builder_advanced.hblksalutation_textAlign};
    text-align-last: ${template_builder_advanced.hblksalutation_textAlignLast};
    font-variant: ${template_builder_advanced.hblksalutation_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblksalutation_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblksalutation_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblksalutation_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblksalutation_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblksalutation_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblksalutation_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblksalutation_margins.join(' ')};
    padding: ${template_builder_advanced.hblksalutation_padding.join(' ')};
    background-color: ${template_builder_advanced.hblksalutation_backgroundColor};
    border-top: ${template_builder_advanced.hblksalutation_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblksalutation_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblksalutation_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblksalutation_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblksalutation_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblksalutation_width};
    height: ${template_builder_advanced.hblksalutation_height};
    string-set: ${template_builder_advanced.hblksalutation_stringSet};
  }

  [data-hederis-type=hblksalutation]::before {
    content: ${template_builder_advanced.hblksalutation_before_content};
  }

  [data-hederis-type=hblksalutation]::after {
    content: ${template_builder_advanced.hblksalutation_after_content};
  }
  [data-hederis-type=hblkclosing] {
    font-family: ${template_builder_advanced.hblkclosing_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblkclosing_fontSize};
    font-weight: ${template_builder_advanced.hblkclosing_fontWeight};
    font-style: ${template_builder_advanced.hblkclosing_fontStyle};
    color: ${template_builder_advanced.hblkclosing_textColor};
    text-indent: ${template_builder_advanced.hblkclosing_textIndent};
    line-height: ${template_builder_advanced.hblkclosing_lineHeight};
    text-align: ${template_builder_advanced.hblkclosing_textAlign};
    text-align-last: ${template_builder_advanced.hblkclosing_textAlignLast};
    font-variant: ${template_builder_advanced.hblkclosing_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblkclosing_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblkclosing_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblkclosing_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblkclosing_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblkclosing_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblkclosing_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblkclosing_margins.join(' ')};
    padding: ${template_builder_advanced.hblkclosing_padding.join(' ')};
    background-color: ${template_builder_advanced.hblkclosing_backgroundColor};
    border-top: ${template_builder_advanced.hblkclosing_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblkclosing_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblkclosing_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblkclosing_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblkclosing_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblkclosing_width};
    height: ${template_builder_advanced.hblkclosing_height};
    string-set: ${template_builder_advanced.hblkclosing_stringSet};
  }

  [data-hederis-type=hblkclosing]::before {
    content: ${template_builder_advanced.hblkclosing_before_content};
  }

  [data-hederis-type=hblkclosing]::after {
    content: ${template_builder_advanced.hblkclosing_after_content};
  }
  [data-hederis-type=hblkpostscript] {
    font-family: ${template_builder_advanced.hblkpostscript_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblkpostscript_fontSize};
    font-weight: ${template_builder_advanced.hblkpostscript_fontWeight};
    font-style: ${template_builder_advanced.hblkpostscript_fontStyle};
    color: ${template_builder_advanced.hblkpostscript_textColor};
    text-indent: ${template_builder_advanced.hblkpostscript_textIndent};
    line-height: ${template_builder_advanced.hblkpostscript_lineHeight};
    text-align: ${template_builder_advanced.hblkpostscript_textAlign};
    text-align-last: ${template_builder_advanced.hblkpostscript_textAlignLast};
    font-variant: ${template_builder_advanced.hblkpostscript_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblkpostscript_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblkpostscript_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblkpostscript_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblkpostscript_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblkpostscript_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblkpostscript_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblkpostscript_margins.join(' ')};
    padding: ${template_builder_advanced.hblkpostscript_padding.join(' ')};
    background-color: ${template_builder_advanced.hblkpostscript_backgroundColor};
    border-top: ${template_builder_advanced.hblkpostscript_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblkpostscript_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblkpostscript_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblkpostscript_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblkpostscript_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblkpostscript_width};
    height: ${template_builder_advanced.hblkpostscript_height};
    string-set: ${template_builder_advanced.hblkpostscript_stringSet};
  }

  [data-hederis-type=hblkpostscript]::before {
    content: ${template_builder_advanced.hblkpostscript_before_content};
  }

  [data-hederis-type=hblkpostscript]::after {
    content: ${template_builder_advanced.hblkpostscript_after_content};
  }
  [data-hederis-type=hblkh1] {
    font-family: ${template_builder_advanced.hblkh1_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblkh1_fontSize};
    font-weight: ${template_builder_advanced.hblkh1_fontWeight};
    font-style: ${template_builder_advanced.hblkh1_fontStyle};
    color: ${template_builder_advanced.hblkh1_textColor};
    text-indent: ${template_builder_advanced.hblkh1_textIndent};
    line-height: ${template_builder_advanced.hblkh1_lineHeight};
    text-align: ${template_builder_advanced.hblkh1_textAlign};
    text-align-last: ${template_builder_advanced.hblkh1_textAlignLast};
    font-variant: ${template_builder_advanced.hblkh1_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblkh1_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblkh1_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblkh1_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblkh1_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblkh1_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblkh1_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblkh1_margins.join(' ')};
    padding: ${template_builder_advanced.hblkh1_padding.join(' ')};
    background-color: ${template_builder_advanced.hblkh1_backgroundColor};
    border-top: ${template_builder_advanced.hblkh1_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblkh1_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblkh1_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblkh1_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblkh1_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblkh1_width};
    height: ${template_builder_advanced.hblkh1_height};
    string-set: ${template_builder_advanced.hblkh1_stringSet};
  }

  [data-hederis-type=hblkh1]::before {
    content: ${template_builder_advanced.hblkh1_before_content};
  }

  [data-hederis-type=hblkh1]::after {
    content: ${template_builder_advanced.hblkh1_after_content};
  }
  [data-hederis-type=hblksubhead] {
    font-family: ${template_builder_advanced.hblksubhead_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblksubhead_fontSize};
    font-weight: ${template_builder_advanced.hblksubhead_fontWeight};
    font-style: ${template_builder_advanced.hblksubhead_fontStyle};
    color: ${template_builder_advanced.hblksubhead_textColor};
    text-indent: ${template_builder_advanced.hblksubhead_textIndent};
    line-height: ${template_builder_advanced.hblksubhead_lineHeight};
    text-align: ${template_builder_advanced.hblksubhead_textAlign};
    text-align-last: ${template_builder_advanced.hblksubhead_textAlignLast};
    font-variant: ${template_builder_advanced.hblksubhead_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblksubhead_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblksubhead_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblksubhead_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblksubhead_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblksubhead_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblksubhead_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblksubhead_margins.join(' ')};
    padding: ${template_builder_advanced.hblksubhead_padding.join(' ')};
    background-color: ${template_builder_advanced.hblksubhead_backgroundColor};
    border-top: ${template_builder_advanced.hblksubhead_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblksubhead_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblksubhead_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblksubhead_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblksubhead_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblksubhead_width};
    height: ${template_builder_advanced.hblksubhead_height};
    string-set: ${template_builder_advanced.hblksubhead_stringSet};
  }

  [data-hederis-type=hblksubhead]::before {
    content: ${template_builder_advanced.hblksubhead_before_content};
  }

  [data-hederis-type=hblksubhead]::after {
    content: ${template_builder_advanced.hblksubhead_after_content};
  }
  [data-hederis-type=hblkdefterm] {
    font-family: ${template_builder_advanced.hblkdefterm_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblkdefterm_fontSize};
    font-weight: ${template_builder_advanced.hblkdefterm_fontWeight};
    font-style: ${template_builder_advanced.hblkdefterm_fontStyle};
    color: ${template_builder_advanced.hblkdefterm_textColor};
    text-indent: ${template_builder_advanced.hblkdefterm_textIndent};
    line-height: ${template_builder_advanced.hblkdefterm_lineHeight};
    text-align: ${template_builder_advanced.hblkdefterm_textAlign};
    text-align-last: ${template_builder_advanced.hblkdefterm_textAlignLast};
    font-variant: ${template_builder_advanced.hblkdefterm_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblkdefterm_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblkdefterm_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblkdefterm_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblkdefterm_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblkdefterm_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblkdefterm_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblkdefterm_margins.join(' ')};
    padding: ${template_builder_advanced.hblkdefterm_padding.join(' ')};
    background-color: ${template_builder_advanced.hblkdefterm_backgroundColor};
    border-top: ${template_builder_advanced.hblkdefterm_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblkdefterm_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblkdefterm_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblkdefterm_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblkdefterm_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblkdefterm_width};
    height: ${template_builder_advanced.hblkdefterm_height};
    string-set: ${template_builder_advanced.hblkdefterm_stringSet};
  }

  [data-hederis-type=hblkdefterm]::before {
    content: ${template_builder_advanced.hblkdefterm_before_content};
  }

  [data-hederis-type=hblkdefterm]::after {
    content: ${template_builder_advanced.hblkdefterm_after_content};
  }
  [data-hederis-type=hblkdefinition] {
    font-family: ${template_builder_advanced.hblkdefinition_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblkdefinition_fontSize};
    font-weight: ${template_builder_advanced.hblkdefinition_fontWeight};
    font-style: ${template_builder_advanced.hblkdefinition_fontStyle};
    color: ${template_builder_advanced.hblkdefinition_textColor};
    text-indent: ${template_builder_advanced.hblkdefinition_textIndent};
    line-height: ${template_builder_advanced.hblkdefinition_lineHeight};
    text-align: ${template_builder_advanced.hblkdefinition_textAlign};
    text-align-last: ${template_builder_advanced.hblkdefinition_textAlignLast};
    font-variant: ${template_builder_advanced.hblkdefinition_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblkdefinition_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblkdefinition_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblkdefinition_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblkdefinition_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblkdefinition_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblkdefinition_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblkdefinition_margins.join(' ')};
    padding: ${template_builder_advanced.hblkdefinition_padding.join(' ')};
    background-color: ${template_builder_advanced.hblkdefinition_backgroundColor};
    border-top: ${template_builder_advanced.hblkdefinition_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblkdefinition_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblkdefinition_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblkdefinition_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblkdefinition_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblkdefinition_width};
    height: ${template_builder_advanced.hblkdefinition_height};
    string-set: ${template_builder_advanced.hblkdefinition_stringSet};
  }

  [data-hederis-type=hblkdefinition]::before {
    content: ${template_builder_advanced.hblkdefinition_before_content};
  }

  [data-hederis-type=hblkdefinition]::after {
    content: ${template_builder_advanced.hblkdefinition_after_content};
  }
  [data-hederis-type=hblkdefinition-cont] {
    font-family: ${template_builder_advanced.hblkdefinition_cont_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblkdefinition_cont_fontSize};
    font-weight: ${template_builder_advanced.hblkdefinition_cont_fontWeight};
    font-style: ${template_builder_advanced.hblkdefinition_cont_fontStyle};
    color: ${template_builder_advanced.hblkdefinition_cont_textColor};
    text-indent: ${template_builder_advanced.hblkdefinition_cont_textIndent};
    line-height: ${template_builder_advanced.hblkdefinition_cont_lineHeight};
    text-align: ${template_builder_advanced.hblkdefinition_cont_textAlign};
    text-align-last: ${template_builder_advanced.hblkdefinition_cont_textAlignLast};
    font-variant: ${template_builder_advanced.hblkdefinition_cont_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblkdefinition_cont_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblkdefinition_cont_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblkdefinition_cont_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblkdefinition_cont_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblkdefinition_cont_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblkdefinition_cont_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblkdefinition_cont_margins.join(' ')};
    padding: ${template_builder_advanced.hblkdefinition_cont_padding.join(' ')};
    background-color: ${template_builder_advanced.hblkdefinition_cont_backgroundColor};
    border-top: ${template_builder_advanced.hblkdefinition_cont_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblkdefinition_cont_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblkdefinition_cont_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblkdefinition_cont_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblkdefinition_cont_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblkdefinition_cont_width};
    height: ${template_builder_advanced.hblkdefinition_cont_height};
    string-set: ${template_builder_advanced.hblkdefinition_cont_stringSet};
  }

  [data-hederis-type=hblkdefinition-cont]::before {
    content: ${template_builder_advanced.hblkdefinition_cont_before_content};
  }

  [data-hederis-type=hblkdefinition-cont]::after {
    content: ${template_builder_advanced.hblkdefinition_cont_after_content};
  }
  [data-hederis-type=hblktype] {
    font-family: ${template_builder_advanced.hblktype_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblktype_fontSize};
    font-weight: ${template_builder_advanced.hblktype_fontWeight};
    font-style: ${template_builder_advanced.hblktype_fontStyle};
    color: ${template_builder_advanced.hblktype_textColor};
    text-indent: ${template_builder_advanced.hblktype_textIndent};
    line-height: ${template_builder_advanced.hblktype_lineHeight};
    text-align: ${template_builder_advanced.hblktype_textAlign};
    text-align-last: ${template_builder_advanced.hblktype_textAlignLast};
    font-variant: ${template_builder_advanced.hblktype_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblktype_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblktype_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblktype_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblktype_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblktype_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblktype_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblktype_margins.join(' ')};
    padding: ${template_builder_advanced.hblktype_padding.join(' ')};
    background-color: ${template_builder_advanced.hblktype_backgroundColor};
    border-top: ${template_builder_advanced.hblktype_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblktype_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblktype_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblktype_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblktype_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblktype_width};
    height: ${template_builder_advanced.hblktype_height};
    string-set: ${template_builder_advanced.hblktype_stringSet};
  }

  [data-hederis-type=hblktype]::before {
    content: ${template_builder_advanced.hblktype_before_content};
  }

  [data-hederis-type=hblktype]::after {
    content: ${template_builder_advanced.hblktype_after_content};
  }
  [data-hederis-type=hblktocFM] {
    font-family: ${template_builder_advanced.hblktocFM_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblktocFM_fontSize};
    font-weight: ${template_builder_advanced.hblktocFM_fontWeight};
    font-style: ${template_builder_advanced.hblktocFM_fontStyle};
    color: ${template_builder_advanced.hblktocFM_textColor};
    text-indent: ${template_builder_advanced.hblktocFM_textIndent};
    line-height: ${template_builder_advanced.hblktocFM_lineHeight};
    text-align: ${template_builder_advanced.hblktocFM_textAlign};
    text-align-last: ${template_builder_advanced.hblktocFM_textAlignLast};
    font-variant: ${template_builder_advanced.hblktocFM_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblktocFM_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblktocFM_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblktocFM_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblktocFM_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblktocFM_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblktocFM_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblktocFM_margins.join(' ')};
    padding: ${template_builder_advanced.hblktocFM_padding.join(' ')};
    background-color: ${template_builder_advanced.hblktocFM_backgroundColor};
    border-top: ${template_builder_advanced.hblktocFM_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblktocFM_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblktocFM_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblktocFM_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblktocFM_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblktocFM_width};
    height: ${template_builder_advanced.hblktocFM_height};
    string-set: ${template_builder_advanced.hblktocFM_stringSet};
  }

  [data-hederis-type=hblktocFM]::before {
    content: ${template_builder_advanced.hblktocFM_before_content};
  }

  [data-hederis-type=hblktocFM]::after {
    content: ${template_builder_advanced.hblktocFM_after_content};
  }
  [data-hederis-type=hblktocChap] {
    font-family: ${template_builder_advanced.hblktocChap_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblktocChap_fontSize};
    font-weight: ${template_builder_advanced.hblktocChap_fontWeight};
    font-style: ${template_builder_advanced.hblktocChap_fontStyle};
    color: ${template_builder_advanced.hblktocChap_textColor};
    text-indent: ${template_builder_advanced.hblktocChap_textIndent};
    line-height: ${template_builder_advanced.hblktocChap_lineHeight};
    text-align: ${template_builder_advanced.hblktocChap_textAlign};
    text-align-last: ${template_builder_advanced.hblktocChap_textAlignLast};
    font-variant: ${template_builder_advanced.hblktocChap_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblktocChap_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblktocChap_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblktocChap_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblktocChap_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblktocChap_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblktocChap_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblktocChap_margins.join(' ')};
    padding: ${template_builder_advanced.hblktocChap_padding.join(' ')};
    background-color: ${template_builder_advanced.hblktocChap_backgroundColor};
    border-top: ${template_builder_advanced.hblktocChap_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblktocChap_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblktocChap_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblktocChap_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblktocChap_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblktocChap_width};
    height: ${template_builder_advanced.hblktocChap_height};
    string-set: ${template_builder_advanced.hblktocChap_stringSet};
  }

  [data-hederis-type=hblktocChap]::before {
    content: ${template_builder_advanced.hblktocChap_before_content};
  }

  [data-hederis-type=hblktocChap]::after {
    content: ${template_builder_advanced.hblktocChap_after_content};
  }
  [data-hederis-type=hblktocPart] {
    font-family: ${template_builder_advanced.hblktocPart_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblktocPart_fontSize};
    font-weight: ${template_builder_advanced.hblktocPart_fontWeight};
    font-style: ${template_builder_advanced.hblktocPart_fontStyle};
    color: ${template_builder_advanced.hblktocPart_textColor};
    text-indent: ${template_builder_advanced.hblktocPart_textIndent};
    line-height: ${template_builder_advanced.hblktocPart_lineHeight};
    text-align: ${template_builder_advanced.hblktocPart_textAlign};
    text-align-last: ${template_builder_advanced.hblktocPart_textAlignLast};
    font-variant: ${template_builder_advanced.hblktocPart_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblktocPart_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblktocPart_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblktocPart_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblktocPart_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblktocPart_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblktocPart_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblktocPart_margins.join(' ')};
    padding: ${template_builder_advanced.hblktocPart_padding.join(' ')};
    background-color: ${template_builder_advanced.hblktocPart_backgroundColor};
    border-top: ${template_builder_advanced.hblktocPart_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblktocPart_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblktocPart_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblktocPart_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblktocPart_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblktocPart_width};
    height: ${template_builder_advanced.hblktocPart_height};
    string-set: ${template_builder_advanced.hblktocPart_stringSet};
  }

  [data-hederis-type=hblktocPart]::before {
    content: ${template_builder_advanced.hblktocPart_before_content};
  }

  [data-hederis-type=hblktocPart]::after {
    content: ${template_builder_advanced.hblktocPart_after_content};
  }
  [data-hederis-type=hblktocBM] {
    font-family: ${template_builder_advanced.hblktocBM_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblktocBM_fontSize};
    font-weight: ${template_builder_advanced.hblktocBM_fontWeight};
    font-style: ${template_builder_advanced.hblktocBM_fontStyle};
    color: ${template_builder_advanced.hblktocBM_textColor};
    text-indent: ${template_builder_advanced.hblktocBM_textIndent};
    line-height: ${template_builder_advanced.hblktocBM_lineHeight};
    text-align: ${template_builder_advanced.hblktocBM_textAlign};
    text-align-last: ${template_builder_advanced.hblktocBM_textAlignLast};
    font-variant: ${template_builder_advanced.hblktocBM_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblktocBM_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblktocBM_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblktocBM_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblktocBM_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblktocBM_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblktocBM_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblktocBM_margins.join(' ')};
    padding: ${template_builder_advanced.hblktocBM_padding.join(' ')};
    background-color: ${template_builder_advanced.hblktocBM_backgroundColor};
    border-top: ${template_builder_advanced.hblktocBM_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblktocBM_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblktocBM_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblktocBM_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblktocBM_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblktocBM_width};
    height: ${template_builder_advanced.hblktocBM_height};
    string-set: ${template_builder_advanced.hblktocBM_stringSet};
  }

  [data-hederis-type=hblktocBM]::before {
    content: ${template_builder_advanced.hblktocBM_before_content};
  }

  [data-hederis-type=hblktocBM]::after {
    content: ${template_builder_advanced.hblktocBM_after_content};
  }
  [data-hederis-type=hblkdelete] {
    font-family: ${template_builder_advanced.hblkdelete_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblkdelete_fontSize};
    font-weight: ${template_builder_advanced.hblkdelete_fontWeight};
    font-style: ${template_builder_advanced.hblkdelete_fontStyle};
    color: ${template_builder_advanced.hblkdelete_textColor};
    text-indent: ${template_builder_advanced.hblkdelete_textIndent};
    line-height: ${template_builder_advanced.hblkdelete_lineHeight};
    text-align: ${template_builder_advanced.hblkdelete_textAlign};
    text-align-last: ${template_builder_advanced.hblkdelete_textAlignLast};
    font-variant: ${template_builder_advanced.hblkdelete_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblkdelete_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblkdelete_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblkdelete_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblkdelete_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblkdelete_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblkdelete_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblkdelete_margins.join(' ')};
    padding: ${template_builder_advanced.hblkdelete_padding.join(' ')};
    background-color: ${template_builder_advanced.hblkdelete_backgroundColor};
    border-top: ${template_builder_advanced.hblkdelete_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblkdelete_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblkdelete_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblkdelete_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblkdelete_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblkdelete_width};
    height: ${template_builder_advanced.hblkdelete_height};
    string-set: ${template_builder_advanced.hblkdelete_stringSet};
  }

  [data-hederis-type=hblkdelete]::before {
    content: ${template_builder_advanced.hblkdelete_before_content};
  }

  [data-hederis-type=hblkdelete]::after {
    content: ${template_builder_advanced.hblkdelete_after_content};
  }
  [data-hederis-type=hblkproc] {
    font-family: ${template_builder_advanced.hblkproc_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hblkproc_fontSize};
    font-weight: ${template_builder_advanced.hblkproc_fontWeight};
    font-style: ${template_builder_advanced.hblkproc_fontStyle};
    color: ${template_builder_advanced.hblkproc_textColor};
    text-indent: ${template_builder_advanced.hblkproc_textIndent};
    line-height: ${template_builder_advanced.hblkproc_lineHeight};
    text-align: ${template_builder_advanced.hblkproc_textAlign};
    text-align-last: ${template_builder_advanced.hblkproc_textAlignLast};
    font-variant: ${template_builder_advanced.hblkproc_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hblkproc_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hblkproc_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hblkproc_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hblkproc_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hblkproc_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hblkproc_OTfontFeatureSettings};
    margin: ${template_builder_advanced.hblkproc_margins.join(' ')};
    padding: ${template_builder_advanced.hblkproc_padding.join(' ')};
    background-color: ${template_builder_advanced.hblkproc_backgroundColor};
    border-top: ${template_builder_advanced.hblkproc_borderTop.join(' ')};
    border-right: ${template_builder_advanced.hblkproc_borderRight.join(' ')};
    border-bottom: ${template_builder_advanced.hblkproc_borderBottom.join(' ')};
    border-left: ${template_builder_advanced.hblkproc_borderLeft.join(' ')};
    border-radius: ${template_builder_advanced.hblkproc_borderRadius.join(' ')};
    width: ${template_builder_advanced.hblkproc_width};
    height: ${template_builder_advanced.hblkproc_height};
    string-set: ${template_builder_advanced.hblkproc_stringSet};
  }

  [data-hederis-type=hblkproc]::before {
    content: ${template_builder_advanced.hblkproc_before_content};
  }

  [data-hederis-type=hblkproc]::after {
    content: ${template_builder_advanced.hblkproc_after_content};
  }
  [data-hederis-type=hspansmallcaps] {
    font-family: ${template_builder_advanced.hspansmallcaps_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hspansmallcaps_fontSize};
    font-weight: ${template_builder_advanced.hspansmallcaps_fontWeight};
    font-style: ${template_builder_advanced.hspansmallcaps_fontStyle};
    color: ${template_builder_advanced.hspansmallcaps_textColor};
    font-variant: ${template_builder_advanced.hspansmallcaps_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hspansmallcaps_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hspansmallcaps_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hspansmallcaps_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hspansmallcaps_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hspansmallcaps_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hspansmallcaps_OTfontFeatureSettings};
    text-decoration: ${template_builder_advanced.hspansmallcaps_underline};
  }
  [data-hederis-type=hspanem] {
    font-family: ${template_builder_advanced.hspanem_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hspanem_fontSize};
    font-weight: ${template_builder_advanced.hspanem_fontWeight};
    font-style: ${template_builder_advanced.hspanem_fontStyle};
    color: ${template_builder_advanced.hspanem_textColor};
    font-variant: ${template_builder_advanced.hspanem_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hspanem_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hspanem_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hspanem_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hspanem_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hspanem_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hspanem_OTfontFeatureSettings};
    text-decoration: ${template_builder_advanced.hspanem_underline};
  }
  [data-hederis-type=hspanstrong] {
    font-family: ${template_builder_advanced.hspanstrong_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hspanstrong_fontSize};
    font-weight: ${template_builder_advanced.hspanstrong_fontWeight};
    font-style: ${template_builder_advanced.hspanstrong_fontStyle};
    color: ${template_builder_advanced.hspanstrong_textColor};
    font-variant: ${template_builder_advanced.hspanstrong_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hspanstrong_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hspanstrong_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hspanstrong_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hspanstrong_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hspanstrong_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hspanstrong_OTfontFeatureSettings};
    text-decoration: ${template_builder_advanced.hspanstrong_underline};
  }
  [data-hederis-type=hspansup] {
    font-family: ${template_builder_advanced.hspansup_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hspansup_fontSize};
    font-weight: ${template_builder_advanced.hspansup_fontWeight};
    font-style: ${template_builder_advanced.hspansup_fontStyle};
    color: ${template_builder_advanced.hspansup_textColor};
    font-variant: ${template_builder_advanced.hspansup_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hspansup_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hspansup_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hspansup_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hspansup_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hspansup_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hspansup_OTfontFeatureSettings};
    text-decoration: ${template_builder_advanced.hspansup_underline};
  }
  [data-hederis-type=hspansub] {
    font-family: ${template_builder_advanced.hspansub_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hspansub_fontSize};
    font-weight: ${template_builder_advanced.hspansub_fontWeight};
    font-style: ${template_builder_advanced.hspansub_fontStyle};
    color: ${template_builder_advanced.hspansub_textColor};
    font-variant: ${template_builder_advanced.hspansub_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hspansub_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hspansub_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hspansub_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hspansub_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hspansub_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hspansub_OTfontFeatureSettings};
    text-decoration: ${template_builder_advanced.hspansub_underline};
  }
  [data-hederis-type=hspana] {
    font-family: ${template_builder_advanced.hspana_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hspana_fontSize};
    font-weight: ${template_builder_advanced.hspana_fontWeight};
    font-style: ${template_builder_advanced.hspana_fontStyle};
    color: ${template_builder_advanced.hspana_textColor};
    font-variant: ${template_builder_advanced.hspana_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hspana_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hspana_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hspana_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hspana_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hspana_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hspana_OTfontFeatureSettings};
    text-decoration: ${template_builder_advanced.hspana_underline};
  }
  [data-hederis-type=hspanabbr] {
    font-family: ${template_builder_advanced.hspanabbr_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hspanabbr_fontSize};
    font-weight: ${template_builder_advanced.hspanabbr_fontWeight};
    font-style: ${template_builder_advanced.hspanabbr_fontStyle};
    color: ${template_builder_advanced.hspanabbr_textColor};
    font-variant: ${template_builder_advanced.hspanabbr_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hspanabbr_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hspanabbr_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hspanabbr_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hspanabbr_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hspanabbr_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hspanabbr_OTfontFeatureSettings};
    text-decoration: ${template_builder_advanced.hspanabbr_underline};
  }
  [data-hederis-type=hspanloosen] {
    font-family: ${template_builder_advanced.hspanloosen_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hspanloosen_fontSize};
    font-weight: ${template_builder_advanced.hspanloosen_fontWeight};
    font-style: ${template_builder_advanced.hspanloosen_fontStyle};
    color: ${template_builder_advanced.hspanloosen_textColor};
    font-variant: ${template_builder_advanced.hspanloosen_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hspanloosen_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hspanloosen_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hspanloosen_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hspanloosen_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hspanloosen_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hspanloosen_OTfontFeatureSettings};
    text-decoration: ${template_builder_advanced.hspanloosen_underline};
  }
  [data-hederis-type=hspantighten] {
    font-family: ${template_builder_advanced.hspantighten_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hspantighten_fontSize};
    font-weight: ${template_builder_advanced.hspantighten_fontWeight};
    font-style: ${template_builder_advanced.hspantighten_fontStyle};
    color: ${template_builder_advanced.hspantighten_textColor};
    font-variant: ${template_builder_advanced.hspantighten_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hspantighten_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hspantighten_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hspantighten_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hspantighten_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hspantighten_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hspantighten_OTfontFeatureSettings};
    text-decoration: ${template_builder_advanced.hspantighten_underline};
  }
  [data-hederis-type=hspanbrafter] {
    font-family: ${template_builder_advanced.hspanbrafter_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hspanbrafter_fontSize};
    font-weight: ${template_builder_advanced.hspanbrafter_fontWeight};
    font-style: ${template_builder_advanced.hspanbrafter_fontStyle};
    color: ${template_builder_advanced.hspanbrafter_textColor};
    font-variant: ${template_builder_advanced.hspanbrafter_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hspanbrafter_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hspanbrafter_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hspanbrafter_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hspanbrafter_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hspanbrafter_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hspanbrafter_OTfontFeatureSettings};
    text-decoration: ${template_builder_advanced.hspanbrafter_underline};
  }
  [data-hederis-type=hspanpagebrafter] {
    font-family: ${template_builder_advanced.hspanpagebrafter_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hspanpagebrafter_fontSize};
    font-weight: ${template_builder_advanced.hspanpagebrafter_fontWeight};
    font-style: ${template_builder_advanced.hspanpagebrafter_fontStyle};
    color: ${template_builder_advanced.hspanpagebrafter_textColor};
    font-variant: ${template_builder_advanced.hspanpagebrafter_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hspanpagebrafter_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hspanpagebrafter_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hspanpagebrafter_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hspanpagebrafter_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hspanpagebrafter_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hspanpagebrafter_OTfontFeatureSettings};
    text-decoration: ${template_builder_advanced.hspanpagebrafter_underline};
  }
  [data-hederis-type=hspnspan] {
    font-family: ${template_builder_advanced.hspnspan_font}, 'FreeSerif';
    font-size: ${template_builder_advanced.hspnspan_fontSize};
    font-weight: ${template_builder_advanced.hspnspan_fontWeight};
    font-style: ${template_builder_advanced.hspnspan_fontStyle};
    color: ${template_builder_advanced.hspnspan_textColor};
    font-variant: ${template_builder_advanced.hspnspan_OTfontVariant};
    font-variant-caps: ${template_builder_advanced.hspnspan_OTfontVariantCaps};
    font-variant-ligatures: ${template_builder_advanced.hspnspan_OTfontVariantLigatures};
    font-variant-numeric: ${template_builder_advanced.hspnspan_OTfontVariantNumeric};
    -moz-font-feature-settings: ${template_builder_advanced.hspnspan_OTmozFontFeatureSettings};
    -webkit-font-feature-settings: ${template_builder_advanced.hspnspan_OTwebkitFontFeatureSettings};
    font-feature-settings: ${template_builder_advanced.hspnspan_OTfontFeatureSettings};
    text-decoration: ${template_builder_advanced.hspnspan_underline};
  }
`;
}
