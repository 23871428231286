import React from "react";

import {
  Pane,
  Text,
  Heading,
  Button,
  TextInputField,
  TabNavigation,
  Tab,
  Link,
  toaster,
  IconButton,
  Icon,
  Checkbox,
} from "evergreen-ui";

import { firebase, db, storage } from "../../fire.js";
import {
  CloudImage,
  Loader,
  ImageList,
  IngestMessages,
} from "../shared/common.js";

export default function BasicOptions(props) {
  // console.log("dash render");
  // console.log(this.props);
  let { options } = props;
  // function onChange (e){

  // props.onChange({ strip_empty_ps: e.target.checked }
  // }
  return (
    <Pane padding={0} textAlign="center" marginBottom={8} display="flex" justifyContent="center">
      <Checkbox
        label="Strip empty paragraphs"
        margin={0}
        checked={options["strip_empty_ps"]}
        onChange={e => {
          options["strip_empty_ps"] = e.target.checked;
          props.onChange({ options });
        }}
      />
    </Pane>
  );
}
