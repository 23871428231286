import React from "react";
import { useState, useEffect } from "react";
import ReactDOM from "react-dom";

import PagesWidget from "./pages_widget.js";
import { SectionSelect } from "./view_options.js";
import ExternalPreview from "./external.js";
import HedTab from "../shared/HedTab.js";
import HedButton from "../shared/HedButton.js";
// prettier-ignore
import {Pane, Text, Heading, Code, Button, Spinner, Paragraph, Tab, Tablist, IconButton, Icon, RefreshIcon, AlignCenterIcon, ChevronRightIcon, ChevronLeftIcon} from "evergreen-ui";

// For when we are ready to implement outline:

// <Pane 
//             backgroundColor="#E8E7EE"
//             width="100%"
//             borderTop="3px solid #201E30"
//             paddingTop={16}
//             key={"Outline"}
//             id="panel-Outline"
//             role="tabpanel"
//             aria-labelledby="Outline"
//             aria-hidden={0 !== currentTab}
//             display={0 === currentTab ? 'block' : 'none'}
//           >
            
//           </Pane>

export default function DesignRightPanel(props) {
  let [currentTab, setCurrentTab] = useState(0);
  // let tabs = ["Outline", "Pages", "Sections"];
  let tabs = ["Pages", "Sections"];
  return (
    <Pane 
      display="flex" 
      flexDirection="column" 
      alignItems="center" 
      backgroundColor="#E8E7EE" 
      minWidth={300}
    >
      <Pane width="100%" backgroundColor="#B3B0C6">
        <HedButton 
          title="Collapse Sidebar" 
          appearance="hedflat"
          marginX={0}
          height={40}
          padding={0}
          onClick={e => props.showRightPanel()} 
        >
          <ChevronRightIcon size={32} color="muted" />
        </HedButton>
      </Pane>
      {!props.loading && !props.unpaging && !props.saving && (
        <Pane width="100%">
          <Pane width="100%" backgroundColor="#B3B0C6">
            <Tablist marginBottom={0} flexBasis={240} marginRight={24}>
              {tabs.map((tab, index) => (
                <HedTab
                  key={tab}
                  height={40}
                  id={tab}
                  onSelect={() => setCurrentTab(index)}
                  isSelected={index === currentTab}
                  aria-controls={`panel-${tab}`}
                  appearance={index === currentTab ? "hedlightest" : "hedlight"}
                >
                  {tab}
                </HedTab>
              ))}
            </Tablist>
          </Pane>
          <Pane 
            backgroundColor="#E8E7EE"
            width="100%"
            borderTop="3px solid #201E30"
            paddingTop={16}
            key={"Pages"}
            id="panel-Pages"
            role="tabpanel"
            aria-labelledby="Pages"
            aria-hidden={0 !== currentTab}
            display={0 === currentTab ? 'flex' : 'none'}
            flexDirection="column" 
            alignItems="center"
          >
            <PagesWidget
              is_loading={props.loading_status_for_layout_control}
              is_saving={props.saving}
              page_count={props.page_count}
              scrollToPage={props.scrollToPage}
            />
          </Pane>
          <Pane 
            backgroundColor="#E8E7EE"
            width="100%"
            borderTop="3px solid #201E30"
            padding={16}
            key={"Sections"}
            id="panel-Sections"
            role="tabpanel"
            aria-labelledby="Sections"
            aria-hidden={1 !== currentTab}
            display={1 === currentTab ? 'flex' : 'none'}
            flexDirection="column" 
            alignItems="center"
          >
            <Text marginLeft={4}>
              Last Build: {props.total_page_count} Pages{" "}
            </Text>
            <ExternalPreview
              {...props}
              fonts_arr={props.fonts_arr}
              keep_open={!props.view_options.close_external_preview}
              onExternalDone={props.onExternalDone}
            />
            <Pane display="flex" flexDirection="row" justifyContent="space-between" marginY={16}>
              <IconButton
                icon={ChevronLeftIcon}
                disabled={props.view_options.section_index === 0 || props.saving || props.loading_status_for_layout_control}
                appearance="minimal"
                display="inline"
                title="Previous Section"
                onClick={() => props.navToPrevSection()}
              />
              <IconButton
                icon={ChevronRightIcon}
                title="Next Section"
                disabled={props.view_options.section_index === props.section_list.length - 1 || props.saving || props.loading_status_for_layout_control}
                appearance="minimal"
                display="inline"
                onClick={() => props.navToNextSection()}
              />
            </Pane>
            <Pane display="flex" flexDirection="column" alignItems="flex-start">
              {!props.saving && !props.loading_status_for_layout_control && props.section_list.map((s, i) => {
                return(<SectionItem s={s} i={i} setOpt={props.setOpt} current_section={props.view_options.section_index} key={`sectnav-${i}`} />);
              })}
            </Pane>
          </Pane>
        </Pane>
      )}
    </Pane>
  );
}

function SectionItem(props) {
  let {i, s} = props;
  return (
    <Paragraph 
      className={i === props.current_section ? "sectionNavItem current" : "sectionNavItem"} 
      paddingLeft={i === props.current_section ? 0 : 18}
      onClick={e => props.setOpt({ section_index: i })}
    >
      {i+1}: {s.title} {s["is_locked"] ? "(LOCKED) " : ""}
    </Paragraph>
  );
}