import { firebase, db, storage } from "../fire.js";

export function make_snapshot(new_name, props) {
  let storageRef = storage.ref();

  // copy all html, config, pdf, epubs, output_docx to a new snapshot folder
  let child_data;

  let sources = [
    "advanced_config",
    "baked_html",
    "html",
    "output_docx",
    "pdf",
    "epub"
  ];

  let project_path = `${props.project.group}/${props.id}/`;

  sources.forEach(async function(src) {
    let subfolder = `${project_path}${src}/`;
    // get a list of all the files in the folder
    let myref = storageRef.child(subfolder);
    let all_files = await myref.listAll();

    // copy all the files to the snapshot folder
    all_files.items.forEach(async function({ fullPath, name }) {
      let src_path = `${project_path}${src}/${name}`;
      let new_path = `${project_path}snapshots/${new_name}/${src}/${name}`;

      let url = await storage.ref(src_path).getDownloadURL();
      let res = await fetch(url);

      if (name.match(/.+\.pdf$/)) {
        child_data = await res.blob();
        storage
          .ref()
          .child(new_path)
          .put(child_data, {contentType: "application/pdf"});
      } else if (name.match(/.+\.epub$/) || name.match(/.+\.docx$/)) {
        child_data = await res.blob();
        storage
          .ref()
          .child(new_path)
          .put(child_data, {contentType: "application/octet-stream"});
      } else {
        child_data = await res.text();
        storage
          .ref()
          .child(new_path)
          .putString(child_data);
      }

    });
  });

  // save the current section_meta
  let meta_path = `${project_path}snapshots/${new_name}/section_meta.json`;
  storage
    .ref()
    .child(meta_path)
    .putString(JSON.stringify(props.project.section_meta));

  // save the current style_list
  let style_path = `${project_path}snapshots/${new_name}/style_list.json`;
  storage
    .ref()
    .child(style_path)
    .putString(JSON.stringify(props.project.style_list));

  return "done";
}
