import React from "react";
// prettier-ignore
import { Pane, Text, Heading, Button, TextInputField, TabNavigation, Tab, Link, toaster, IconButton, Icon, Checkbox } from "evergreen-ui";

import { firebase, db, storage } from "../../fire.js";
import { TemplateBox } from "../shared/common.js";

import APIClient from "../../helpers/api_client";
import { FireHelpers } from "../../../api/app_shared/fire_helpers.js";
const API = new APIClient(firebase);
const FH = new FireHelpers(db, firebase);

import static_book_style_templates from "../../../api/app_shared/defaults/static_book_style_templates.json";

export default class TemplatePicker extends React.Component {
  constructor(props) {
    super(props);
    // not really state as such, we just set it the once
    this.state = { template_choices: [] };
  }
  async componentDidMount() {
    let template_choices = Object.values(static_book_style_templates);
    // let template_choices = Object.values(static_book_style_templates);
    template_choices = template_choices.concat(
      await FH.get_basic_templates_by_group(this.props.group)
    );
    // a stub for creating new custom template builder templates
    // template_choices.push({ group: true });
    template_choices.push({ template_builder_config: true });
    this.setState({ template_choices });
  }

  render() {
    let { template } = this.props;
    let { template_choices } = this.state;

    // setup our thumbnail and checkboxes acting as radios
    let template_choice_panes = template_choices.map(
      ({ thumbnail, id, display_name, group, template_builder_config }) => {
        let onClick = () => {
          // prettier-ignore
          if (id) { this.props.onChange({ template: id }) }
        };
        return (
          <Pane
            key={id || "custom"}
            margin={8}
            padding={16}
            background="tint1"
            textTransform="capitalize"
          >
            {thumbnail ? (
              <img src={thumbnail} onClick={onClick} cursor="pointer" />
            ) : (
              <TemplateBox
                display_name={display_name}
                onClick={id ? onClick : () => this.props.onEditSelect(false)}
              />
            )}
            {id ? (
              <Checkbox
                label={display_name ? display_name.replace(/_/g, " ") : ""}
                textTransform="capitalize"
                checked={id === template}
                onClick={onClick}
              />
            ) : (
              <Pane marginY={16}>
                <Text size={300}>Create New </Text>
              </Pane>
            )}
            {template_builder_config ? (
              <Button
                appearance="primary"
                intent={id ? "success" : "none"}
                onClick={() => this.props.onEditSelect(id || false)}
              >
                {id ? "Edit Template" : "Create Template"}
              </Button>
            ) : (
              ``
            )}
          </Pane>
        );
      }
    );

    return (
      <Pane marginTop={8} padding={8} textAlign="center">
        <Heading>Pick a Template</Heading>

        <Pane display="flex" flexDirection="row" justifyContent="center">
          {template_choice_panes}
        </Pane>
      </Pane>
    );
  }
}
